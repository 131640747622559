import type { FunctionComponent } from "react";
import { itemContext } from "@/react/OrderView/OrderView.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";

export type ConfirmMergeSeatBeforeMove = {
  className?: string;
  onClose?: () => void;
};

const ConfirmMergeSeatBeforeMove: FunctionComponent<ConfirmMergeSeatBeforeMove> = ({
  className = "",
  onClose,
}) => {
  return (
    <div
      className={`w-full py-[24px] relative bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center px-8 box-border min-w-[150px] max-w-[524px] h-full max-h-full text-center text-base text-black-solid-black-880-1d1d26 font-mulish ${className}`}
    >
      <div className="gap-y-[16px] pb-[24px] px-5">
        <div className="text-[16px] whitespace-normal self-stretch relative leading-[28px] font-normal">
          {LL0().dialogs.moveItem.mergeSeat()}
        </div>
        <div className="text-[16px] whitespace-normal self-stretch relative leading-[28px] font-bold">
          {LL0().dialogs.moveItem.noteMergeSeat()}
        </div>
      </div>
      <div className="pb-3 self-stretch flex flex-col items-center justify-center text-sm text-blue-solid-blue-420-2979ff">
        <div
          className="w-[160px] rounded-md bg-white-solid-white-100-ffffff border-blue-solid-blue-420-2979ff border-[1px] border-solid box-border overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 cursor-pointer"
          onClick={() => {
            onClose?.();
            itemContext.onMoveToggle();
          }}
        >
          <b className="text-[16px] relative">{LL0().dialogs.moveItem.confirmMergeSeat()}</b>
        </div>
      </div>
      <div
        className="w-6 absolute !m-[0] top-[12px] right-[12px] h-6 overflow-hidden shrink-0 cursor-pointer z-[1]"
        onClick={onClose}
      >
        <img
          className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
          alt=""
          src="/icong-close-popup-icon--grey12@2x.png"
        />
      </div>
    </div>
  );
};

export default ConfirmMergeSeatBeforeMove;
