import type { FunctionComponent } from "react";
import {LL0} from "@/react/core/I18nService.tsx";

type TimeManagementClockTimeType = {
  time?: string;
  isClockIn?: boolean;
  isClockOut?: boolean;

};

const TimeManagementClockTime: FunctionComponent<
  TimeManagementClockTimeType
> = ({
  time = "09:09",
  isClockIn = true,
  isClockOut = true,
}) => {


  return (
    <div
      className="rounded-md flex flex-row items-center justify-center relative text-center text-base text-white-solid-white-100-ffffff font-mulish"
    >
      {time && (
        <div className="my-0 mx-[!important] absolute h-[calc(100%_-_8px)] w-[92.94%] top-[4px] right-[3.53%] bottom-[4px] left-[3.53%] rounded-md [background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)] overflow-hidden flex flex-row items-center justify-center py-[5px] px-[3px] box-border z-[0]">
          <div className="flex-1 relative sm:text-sm">{time}</div>
        </div>
      )}
      {isClockIn && (
        <div className="my-0 mx-[!important] absolute h-[calc(100%_-_8px)] w-[92.94%] top-[4px] right-[3.53%] bottom-[4px] left-[3.53%] rounded-md [background:linear-gradient(180deg,_rgba(255,_158,_137,_0.9),_rgba(216,_39,_0,_0.9))] shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)] overflow-hidden flex flex-row items-center justify-center py-[5px] px-[3px] box-border z-[1]">
          <div className="relative sm:text-sm">{LL0().monthlyReport.clockIn()}</div>
        </div>
      )}
      {isClockOut && (
        <div className="my-0 mx-[!important] absolute h-[calc(100%_-_8px)] w-[92.94%] top-[4px] right-[3.53%] bottom-[4px] left-[3.53%] rounded-md [background:linear-gradient(180deg,_rgba(255,_158,_137,_0.4),_rgba(216,_39,_0,_0.4))] shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)] overflow-hidden flex flex-row items-center justify-center py-[5px] px-[3px] box-border z-[2]">
          <div className="relative sm:text-sm">{LL0().monthlyReport.clockOut()}</div>
        </div>
      )}
    </div>
  );
};

export default TimeManagementClockTime;
