import { computed } from "@/react/core/reactive.ts";
import { posFeature0, posSetting0 } from "@/data/PosSettingsSignal.ts";
import type { PosFeature } from "@/data/PosSetting.ts";
import _ from "lodash";

export const isShowPendingOrder = computed(() => posFeature0()?.showPendingOrder ?? true)
export const isShowNewDeliveryOrder = computed(() => posFeature0()?.showNewDelivery ?? true)
export const isShowOnlineMenu = computed(() => posFeature0()?.showOnlineMenu ?? true)
export const isShowReservation = computed(() => posFeature0()?.showReservation ?? true)
export const isShowCustomerInfo = computed(() => posFeature0()?.showCustomerInfo ?? true)

export const updatePosFeature = (newFeature: Partial<PosFeature>) => {
  if (!posFeature0()) {
    _.assign(posSetting0(), { posFeature: {...newFeature} });
  } else {
    _.assign(posFeature0(), newFeature);
  }
};