import type { FunctionComponent } from "react";
import HappyHourHappyHourItem from "@happy-hour/HappyHourHappyHourItem";
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { createNewHappyHour, onBack } from '@/react/HappyHourView/HappyHourView.tsx';
import { happyHours0 } from '@/data/HappyHourHub.ts';
import {LL0} from "@/react/core/I18nService.tsx";

const HappyHourLeftPath: FunctionComponent = () => {
  return (
    <div className="self-stretch bg-basic-color-white-solid-white-100-ffffff shadow-[0px_4px_8px_rgba(0,_0,_0,_0.25)] w-[225px] overflow-y-auto shrink-0 flex flex-col items-center justify-between pt-0 px-0 pb-3.5 box-border text-left text-smi text-red-solid-red-800-ff0022 font-mulish Tablet_768:w-[180px]">
      <div className="self-stretch flex flex-col items-center justify-center">
        <div className="self-stretch bg-basic-color-white-solid-white-100-ffffff flex flex-row items-center justify-start py-3 px-[21px] gap-[9px]"
            ref={makeRipple}
             onPointerDown={createNewHappyHour}
        >
          <img
            className="relative w-10 h-10 object-cover"
            alt=""
            src="/iconplus-icon-grey@2x.png"
          />
          <b className="relative leading-[24px]">{LL0().settings.happyHour.newHappyHour()}</b>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start">
          {happyHours0().map(happyHour =>
            <HappyHourHappyHourItem
              happyHourData={happyHour}
              key={happyHour._id}
          />)}
        </div>
      </div>
      <div className="!text-white rounded-81xl bg-blue-solid-blue-450-1271ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] text-center text-sm text-basic-color-white-solid-white-100-ffffff"
          ref={makeRipple}
           onClick={onBack}
      >
        <img
          className="relative w-[30px] h-[30px] object-cover"
          alt=""
          src="/iconback-icon@2x.png"
        />
        <b className="relative leading-[17px] Tablet_768:text-xs">{LL0().ui.back()}</b>
      </div>
    </div>
  );
};

export default HappyHourLeftPath;
