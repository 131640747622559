import PortalPopup from '@eod/PortalPopup.tsx'
import { createTheme, Tab, Tabs, ThemeProvider } from '@mui/material'
import _ from 'lodash'
import type React from 'react'
import { memo, useMemo, useState } from 'react'

import { Button } from '@/components/ui/button.tsx'
import { posSettingV, setPosSettingV } from '@/data/PosSettingHub.ts'
import { companyInfo0, mainScreen } from '@/data/PosSettingsSignal.ts'
import { posSync0 } from '@/data/PosSyncState.ts'
import { server } from '@/data/Server.ts'
import { router } from '@/pos/PosRouter.ts'
import { deepSignal, onMount } from '@/react/core/reactive.ts'
import { processing } from '@/react/Developer/DataMenu.logic.ts'
import DeleteAllPopup from '@/react/Developer/DeleteAllPopup.tsx'
import DeveloperDataTab from '@/react/Developer/DeveloperDataTab.tsx'
import DeveloperErudaTab from '@/react/Developer/DeveloperErudaTab.tsx'
import DeveloperImportExportTab from '@/react/Developer/DeveloperImportExportTab.tsx'
import DeveloperOtherTab from '@/react/Developer/DeveloperOtherTab.tsx'
import DeveloperTSETab from '@/react/Developer/DeveloperTSETab.tsx'
import RegisterStore from '@/react/Developer/RegisterStore.tsx'
import UpdateNowPopup from '@/react/Developer/UpdateNowPopup.tsx'
import DeveloperSrmTab from '@/srm/components/developer/DeveloperSrmTab'
import { deregisterTse } from '@/tse/tse-communicate.ts'
import DeveloperPosFeaturesTab from "@/react/Developer/DeveloperPosFeaturesTab.tsx";

const muiTheme = createTheme()

export function TabPanel(props: { children: React.ReactNode; value: string; index: string }) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      className="h-[calc(100%-50px)] overflow-x-hidden"
      {...other}
    >
      {value === index && <div className="p-4 no-scrollbar">{children}</div>}
    </div>
  )
}

export function setReplicateServerUserNamePassword(storeId: number, password: string) {
  if (posSync0() && !posSync0()?.replicateServer) {
    posSync0()!.replicateServer = deepSignal({} as { username: string; password: string })
  }
  _.assign(posSync0()?.replicateServer, { username: `user_n${storeId}`, password })
}

export async function deregisterTSE() {
  await deregisterTse()
}

const Developer = () => {
  const [currentTab, setCurrentTab] = useState(localStorage.getItem('srm_runningTestcase') ? '1' : '0')

  onMount(() => {
    if (posSettingV() === 0) {
      setPosSettingV(1)
    }
  })

  const handleChangeTab = (_: unknown, newValue: string) => setCurrentTab(newValue)
  const tabs = useMemo(
    () => [
      { key: 'registerStore', value: 'Register Store', Comp: () => <RegisterStore type={'Developer'} /> },
      ...(companyInfo0()?.country !== 'de' ? [{ key: 'srm', value: 'Quebec SRM', Comp: DeveloperSrmTab }] : []),
      ...(server() !== 'canada1' ? [{ key: 'tse', value: 'TSE', Comp: DeveloperTSETab }] : []),
      { key: 'data', value: 'Data', Comp: DeveloperDataTab },
      { key: 'importExport', value: 'Import/ Export', Comp: DeveloperImportExportTab },
      { key: 'eruda', value: 'Eruda', Comp: DeveloperErudaTab },
      { key: 'others', value: 'Others', Comp: DeveloperOtherTab },
      { key: 'posFeatures', value: 'Pos Features', Comp: DeveloperPosFeaturesTab },
    ],
    [companyInfo0()?.country, server()]
  )

  return (
    <ThemeProvider theme={muiTheme}>
      <div className="w-full h-full no-scrollbar p-[10px] overflow-y-auto">
        <div className="relative border-b border-b-slate-200 grid grid-cols-[1fr_max-content] gap-2 items-center">
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons={false}
          >
            {tabs.map(({ key, value }, i) => (
              <Tab
                key={key}
                value={`${i}`}
                label={value}
              />
            ))}
          </Tabs>
          <Button
            data-testid={'developer.back'}
            onClick={() => (router.screen = mainScreen())}
          >
            Back
          </Button>
        </div>
        {tabs.map(({ key, Comp }, i) => (
          <TabPanel
            key={key}
            value={currentTab}
            index={`${i}`}
          >
            <Comp />
          </TabPanel>
        ))}
      </div>
      {processing() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
        >
          <button
            type="button"
            className="bg-gray-solid-gray-750-696969 text-white p-4 rounded-xl ..."
            disabled
          >
            Processing...
          </button>
        </PortalPopup>
      )}
      <UpdateNowPopup />
      <DeleteAllPopup />
    </ThemeProvider>
  )
}

export default memo(Developer)
