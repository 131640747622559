import { useEffect } from 'react'
import { map, Subscription, tap } from 'rxjs'

import { convertDocuments, type DocDeepSignal } from '@/data/data-utils'
import { dataLock } from '@/data/DataUtils.ts'
import { Product } from '@/data/Product'
import { signal } from '@/react/core/reactive'
import appHook from '@/shared/app-hook'

export const [products0, setProducts0] = signal<Array<DocDeepSignal<Product>>>([])

export const makeProductsAvailable = () => {
  useEffect(() => {
    let sub = new Subscription()

    dataLock.acquireAsync().then(() => {
      sub.add(
        Product.find()
          .$.pipe(
            map(a => convertDocuments(a, true, [], {
              onSaved: (doc: any) => appHook.emit('product:updated', doc)
            })),
            tap(a => setProducts0(a))
          )
          .subscribe()
      )
    })

    return () => sub.unsubscribe()
  }, [])
}

// @ts-ignore
window.products0 = products0
