import PasscodePlugin from '@passcode/PasscodePlugin'
import dayjs from 'dayjs'
import debug from 'debug'
import _ from 'lodash'
import type { RxDocument } from 'rxdb'
import uuid from 'time-uuid'

import { dataLock } from '@/data/DataUtils.ts'
import { ShiftStatus, TimeClock } from '@/data/TimeClock.ts'
import { onEnter, PosScreen } from '@/pos/PosRouter.ts'
import { effectOn, signal } from '@/react/core/reactive.ts'
import { handleUpdateAPK } from '@/react/Developer/UpdateNowPopup.logic.ts'
import { rnHost } from '@/shared/webview/rnwebview.ts'
import { beginHour } from "@/data/PosSettingsSignal.ts";
import { loginUser, users0 } from "@/data/UserSignal.ts";

const log = debug('data:passcode')

const blockRunClockIn: any[] = [];

/*******************[ States ]*********************/
const [updateAvailable, setUpdateAvailable] = signal(false)
const [lastCheck, setLastCheck] = signal<Date | undefined>(undefined)

/*******************[ Exported States ]*********************/
export {
  // States
  updateAvailable,
  lastCheck,
}

/*******************[ Handlers ]*********************/
export const HANDLERS = {
  checkUpdate: async () => {
    try {
      const l = lastCheck()
      const now = new Date()
      // Only check again after 5 min
      if (!l || dayjs(now).diff(dayjs(l), 'minute') > 5) {
        // sendPosMessage({ type: 'check-update' })
        setLastCheck(now)
        const version = await rnHost.checkUpdate('store')
        if (version) {
          log('💡 New version available!', version)
          setUpdateAvailable(true)
        }
      }
    } catch (e) {
      log('🛑 Failed to check for update!', e)
    }
  },
  updateNow: handleUpdateAPK,
}

/*******************[ Debug ]*********************/
// effect(() => log('🪲 updateAvailable', updateAvailable()))

// timecClock handlers
export const [clockedInMap, setClockedInMap] = signal<Record<string, boolean>>({})
export const [lastAction, setLastAction] = signal<RxDocument<TimeClock, {}> | null>()

async function getLastAction(username: string, time = new Date()) {
  const lastClockInAction = await TimeClock.findOne({
    selector: {
      username,
      status: ShiftStatus.CLOCK_IN,
      $or: [{ clockOutTime: { $exists: false } }, { clockOutTime: { $exists: true, $gte: time } }],
    },
  }).exec()
  if (lastClockInAction) return lastClockInAction
  else {
    return await TimeClock.findOne({ selector: { username } }).sort({ clockOutTime: 'desc' }).exec()
  }
}

export const onClockOut = async (username: string, time = dayjs().unix()) => {
  const currentShift = await TimeClock.findOne({
    selector: {
      username,
      status: ShiftStatus.CLOCK_IN,
      $or: [{ clockOutTime: { $exists: false } }, { clockOutTime: { $exists: true, $gte: time } }],
    },
  }).exec()
  if (!currentShift) return
  await TimeClock.upsert({
    ..._.pick(currentShift, ['_id', 'clockInTime', 'username', 'vDate']),
    status: ShiftStatus.CLOCK_OUT,
    clockOutTime: time,
  })
  log(`[user:clockOut] clockOutTime: ${time}, clockInTime: ${currentShift._data.clockInTime}, _id: ${currentShift._data._id}`,{userName: username})
  await fetchClockedInState([username])
}

export const onClockIn = async (username: string, autoClockOutTime?: number, clockInTime = dayjs().unix()) => {
  if (blockRunClockIn?.[username!]) {
    return;
  }

  blockRunClockIn[username!] = true;

  const vDate = dayjs.unix(clockInTime).clone().subtract(beginHour(), 'hour').startOf('day').unix();
  const lastClockInAction = await TimeClock.findOne({
    selector: {
      username,
      status: ShiftStatus.CLOCK_IN,
    },
  }).exec();
  //check if any clock-in shift exists
  if (lastClockInAction) {
    log(`[user:reClockIn] autoClockOutTime:${autoClockOutTime}, clockInTime: ${clockInTime}`, {alert: true, userName: username})
    return
  }
  await TimeClock.insert({ _id: uuid(), username, status: ShiftStatus.CLOCK_IN, clockInTime, vDate, clockOutTime: autoClockOutTime || undefined })
  log(`[user:clockIn] autoClockOutTime:${autoClockOutTime}, clockInTime: ${clockInTime}`,{userName: username})

  setLastAction(await getLastAction(username))
  await fetchClockedInState([username])

  //allow run clockIn after 10s
  setTimeout(() => {
    blockRunClockIn[username!] = false;
  }, 10000);
}

export const fetchClockedInState = async (fetchedUsernames?: string[], time = 0) => {
  await dataLock.acquireAsync()
  for (const username of fetchedUsernames || users0().map(user => user.name)) {
    clockedInMap()[username || ''] = !!(await TimeClock.findOne({
      selector: {
        username,
        status: ShiftStatus.CLOCK_IN,
        $or: [{ clockOutTime: { $exists: false } }, { clockOutTime: { $exists: true, $gt: time } }],
      },
    }).exec())
  }
  setClockedInMap(prev => ({ ...prev }))
}

effectOn([loginUser], async () => {
  const loginUserName = loginUser()?.name
  if (!loginUserName) return
  await fetchClockedInState([loginUser()?.name || ''])
  if (clockedInMap()[loginUser()?.name || '']) {
    setLastAction(await getLastAction(loginUserName))
  }
})

const PasscodeView = () => {
  onEnter(PosScreen.PASSCODE, fetchClockedInState)

  return <PasscodePlugin />
}

export default PasscodeView
