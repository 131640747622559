import type React from "react";
import { useCallback, useEffect, useMemo } from "react";
import type {InputKeyboardProps, ShiftState, SpecialKey} from "@/react/core/InputKeyboard.tsx";
import {makeRipple} from "@/react/core/ripple-utils.ts";
import _ from "lodash";


const InventoryKeyboard: React.FC<InputKeyboardProps> = (props) => {

  let backspaceInterval: ReturnType<typeof setInterval>;

  const specialChars: Array<SpecialKey> = [{
    id: "special"
  }, {
    id: "enter",
    down: () => {
      props.onEnter?.();
    }
  }, {
    id: "backspace",
    down: () => {
      props.inputController?.()?.setFocus(true);
      props.inputController?.()?.setFocusLock(true);
      props.inputController?.()?.backspaceHandle();
      backspaceInterval = setInterval(() => {
        props.inputController?.()?.setFocus(true);
        props.inputController?.()?.setFocusLock(true);
        props.inputController?.()?.backspaceHandle();
      }, 200);
    },
    up() {
      clearInterval(backspaceInterval);
    }
  }, {
    id: ",",
    down: () => {
      handleButton(",");
    }
  }, {
    id: ".",
    down: () => {
      handleButton(".");
    }
  }];
  const handleButton = (char: string) => {
    setTimeout(() => {
      props.inputController?.()?.setFocus(true);
      props.inputController?.()?.setFocusLock(true);
      props.inputController?.()?.addKey(char);
    }, 20);
  };
  const map = useMemo(() => new WeakMap<HTMLDivElement, boolean>(), []);
  const refHandler = useCallback((ref: HTMLDivElement, char?: string) => {
    if (ref && !map.has(ref)) {
      makeRipple(ref);
      if (!char) char = ref.children[0]?.textContent!;
      ref.addEventListener('pointerdown', () => {
         if (char === 'backspace') {
          _.find(specialChars, { id: 'backspace' })?.down?.();
        } else if (char === 'enter') {
          _.find(specialChars, { id: 'enter' })?.down?.();
        } else {
          if (char){
            handleButton(char);
          }
        }
      })
      ref.addEventListener('pointerup', () => {
         if (char === 'backspace') {
          _.find(specialChars, { id: 'backspace' })?.up?.();
        }
      });
      map.set(ref, true);
    }
  }, [])



  return (
    <div
      className="rounded bg-cyan-solid-cyan-150-edeff1 [backdrop-filter:blur(30px)] w-full h-[203px] flex flex-col items-center justify-center p-2 box-border min-w-[320px] text-center text-3xl text-black-solid-black-1000-000000 font-mulish self-stretch"

    >
      <div className="self-stretch flex-1 flex flex-row items-start justify-center gap-[4px]">
        <div className="self-stretch flex-1 flex flex-col items-center justify-center gap-[5px]">
          <div ref={refHandler} className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">7</div>
          </div>
          <div ref={refHandler} className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">4</div>
          </div>
          <div ref={refHandler} className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">1</div>
          </div>
          <div ref={r => refHandler(r!, '.')} className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">.</div>
          </div>
        </div>
        <div className="self-stretch flex-1 flex flex-col items-center justify-center gap-[5px]">
          <div ref={refHandler} className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">8</div>
          </div>
          <div ref={refHandler} className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">5</div>
          </div>
          <div ref={refHandler} className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">2</div>
          </div>
          <div ref={refHandler} className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">0</div>
          </div>
        </div>
        <div className="self-stretch flex-1 flex flex-col items-center justify-center gap-[5px]">
          <div ref={refHandler} className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">9</div>
          </div>
          <div ref={refHandler} className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">6</div>
          </div>
          <div ref={refHandler} className="self-stretch flex-1 rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center">
            <div className="relative">3</div>
          </div>
          <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[5px] text-base text-white-solid-white-100-ffffff">
            <div ref={r => refHandler(r!, 'backspace')} className="self-stretch flex-1 rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center h-auto gap-[10px] hover:[background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] hover:flex hover:flex-1 hover:self-stretch hover:h-auto hover:flex-col hover:gap-[10px] hover:items-center hover:justify-center hover:rounded-8xs-6 hover:shadow-[0px_1px_0px_#898a8d]">
              <img
                className="relative w-[22.7px] h-[17px]"
                alt=""
                src="/delete-button@2x.png"
              />
            </div>
            <div ref={r => refHandler(r!, 'enter')} className="self-stretch flex-1 rounded-8xs-6 bg-blue-solid-blue-350-2196f3 shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center min-w-[42px]">
              <div className="relative">Save</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryKeyboard;
