import uuid from 'time-uuid'

import type { Order } from '@/data/Order'
import { OrderStatus } from '@/pos/OrderType'
import { signalSyncedWithLocalStorage } from '@/react/core/reactive'

import { refundOrder } from '../order-mutations'
import { srmTransactionLogic } from '../transaction.logic'
import { execTestcase, getCaTaxInfo } from './libs'

type O = Readonly<Order>

const [order01, setOrder01] = signalSyncedWithLocalStorage<O | null>('TESTCASE_006_ORDER_01', null)
const [order03, setOrder03] = signalSyncedWithLocalStorage<O | null>('TESTCASE_006_ORDER_03', null)
const [order05, setOrder05] = signalSyncedWithLocalStorage<O | null>('TESTCASE_006_ORDER_05', null)
const [order07, setOrder07] = signalSyncedWithLocalStorage<O | null>('TESTCASE_006_ORDER_07', null)

const getBaseOrder = (): O => {
  const taxInfo = getCaTaxInfo()
  const order: O = {
    _id: uuid(),
    table: '1',
    status: OrderStatus.COMPLETED,
    payments: [{ type: 'cash', value: 0 }],
    tip: 0,
    discount: 0,
    multiplePriceMenu: '',
    items: [
      { name: 'Samosa', price: 20, quantity: 2, modifiers: [], ...taxInfo },
      { name: 'Garlic Bread', price: 10, quantity: 1, modifiers: [], ...taxInfo },
    ],
  }
  return order
}

export const testRunner006: Record<string, () => Promise<boolean>> = {
  '006.001': () =>
    execTestcase({
      num: 6,
      step: 1,
      device: 'A',
      run: async () => {
        const order = getBaseOrder()
        await srmTransactionLogic.recordClosingReceipt(order, { print: true })
        setOrder01(order)
      },
    }),
  '006.002': () =>
    execTestcase({
      num: 6,
      step: 2,
      device: 'A',
      run: async () => {
        const o = order01()
        if (!o) throw new Error('Please run step 1 first')
        await srmTransactionLogic.recordRefund(
          refundOrder(o, i => i.name === 'Garlic Bread'),
          { print: true }
        )
      },
    }),
  '006.003': () =>
    execTestcase({
      num: 6,
      step: 3,
      device: 'A',
      run: async () => {
        const order: O = { ...getBaseOrder(), _id: uuid(), table: '3' }
        await srmTransactionLogic.recordClosingReceipt(order, { print: true })
        setOrder03(order)
      },
    }),
  '006.004': () =>
    execTestcase({
      num: 6,
      step: 4,
      device: 'A',
      run: async () => {
        const o = order03()
        if (!o) throw new Error('Please run step 3 first')
        await srmTransactionLogic.recordRefund(refundOrder(o), { print: true })
      },
    }),
  '006.005': () =>
    execTestcase({
      num: 6,
      step: 5,
      device: 'C',
      run: async () => {
        const order: O = { ...getBaseOrder(), _id: uuid(), table: undefined }
        await srmTransactionLogic.recordClosingReceipt(order, { print: true })
        setOrder05(order)
      },
    }),
  '006.006': () =>
    execTestcase({
      num: 6,
      step: 6,
      device: 'C',
      run: async () => {
        const o = order05()
        if (!o) throw new Error('Please run step 5 first')
        await srmTransactionLogic.recordRefund(
          refundOrder(o, i => i.name === 'Garlic Bread'),
          { print: true }
        )
      },
    }),
  '006.007': () =>
    execTestcase({
      num: 6,
      step: 7,
      device: 'C',
      run: async () => {
        const order: O = { ...getBaseOrder(), _id: uuid(), table: undefined }
        await srmTransactionLogic.recordClosingReceipt(order, { print: true })
        setOrder07(order)
      },
    }),
  '006.008': () =>
    execTestcase({
      num: 6,
      step: 8,
      device: 'C',
      run: async () => {
        const o = order07()
        if (!o) throw new Error('Please run step 7 first')
        await srmTransactionLogic.recordRefund(refundOrder(o), { print: true })
      },
    }),
}
