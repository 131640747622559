import hexToHsl from "hex-to-hsl";
import hslToHex from "hsl-to-hex";
import { type Dispatch, type FC, type ReactElement, type SetStateAction, useEffect, useMemo, useState } from "react";

//region ColorDarken
type ColorDarkenProps = {
	color: string
	activeColor?: string
	noTimeout?: boolean
	active?: boolean
	children: (color: string, setClicked: Dispatch<SetStateAction<boolean>>) => ReactElement
};
// endregion
export const ColorDarken: FC<ColorDarkenProps> = (props) => {
	const [clicked, setClicked] = useState<boolean>(false);
	useEffect(() => {
		clicked && !props.noTimeout && setTimeout(() => setClicked(false), 200);
	});
	const color = useMemo(() => {
		if (clicked || props.active) {
			if (props.activeColor) {
				return props.activeColor;
			}
			const [h, s, l] = hexToHsl(props.color);
			const color2 = hslToHex(h, s - 5, l - 15);
			return color2;
		}
		return props.color;
	}, [clicked, props.active, props.color]);
	return props.children(color, setClicked);
};
