import {type PropsWithChildren, useEffect} from "react";
import {useSignal} from "@/react/core/reactive.ts";

const LazyRender = (props: PropsWithChildren & {
	show: boolean
}) => {
	const [showPermanent, setShowPermanent] = useSignal<boolean>(props.show);

	useEffect(() => {
		if (props.show) {
			setShowPermanent(true);
		}
	}, [props.show]);

	if (!showPermanent()) {
		return null;
	}
	return props.children;
}

export default LazyRender;