import { type FunctionComponent, useState } from "react";
import NewDeliveryMissedCallPopup from "./Popups/NewDeliveryMissedCallPopup";
import PortalPopup from "./PortalPopup";
import NewDeliveryBurgerMenuBut from "./NewDeliveryBurgerMenuBut";
import { order0 } from "@/react/OrderView/OrderViewShare.ts";
import { CommitAction, OrderType } from "@/pos/OrderType.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { onPrintDeliveryOrder } from "@/react/NewDeliveryView/NewDeliveryView.tsx";
import { router } from "@/pos/PosRouter.ts";
import {LL0} from "@/react/core/I18nService.tsx";
import { mainScreen } from "@/data/PosSettingsSignal.ts";
import { isShowPrice } from "@/react/OrderView/OrderView.tsx";
import { clsx } from "clsx";
import { isSmallSidebar } from "@/data/DeviceSettingHub.ts";
import { LL3 } from "@/react/core/I18nCurrency.tsx";

const NewDeliveryTopNav: FunctionComponent = () => {
  const [isNewDeliveryMissedCallPopupOpen, setNewDeliveryMissedCallPopupOpen] =
    useState(false);
  return (
    <div className="self-stretch [background:linear-gradient(180deg,_#fbfbfb,_#c1c1c1)] h-[50px] flex flex-row items-center justify-between pt-[7px] px-1 pb-[3px] box-border z-[1] text-center text-sm text-white-solid-white-100-ffffff font-mulish sm:h-[42px] sm:pl-1 sm:pr-1 sm:box-border Tablet_768:h-[42px] Tablet_600:h-[42px] Tablet_600:pl-1 Tablet_600:pr-1 Tablet_600:box-border height_414:h-[42px]">
      <div className="flex flex-row items-center justify-start gap-[0px_4px] Tablet_768:gap-[0px_4px] Tablet_600:gap-[0px_4px] Mobile_480:gap-[0px_4px] height_414:gap-[0px_4px]">
        <NewDeliveryBurgerMenuBut
          showExpand={false}
          showPickupStatus={false}
          showMenuIcon
          showDeliveryStatus={false}
          isSelected={false}
          isDefault
          showButtonText={false}
        />
        <div className="flex flex-col items-start justify-start gap-[2px_0px]">
          { order0().type === OrderType.Delivery &&
          <div className="rounded-81xl bg-orange-solid-orange-520-f39221 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 pr-1 pl-2 box-border gap-[0px_2px] Tablet_768:gap-[0px_2px] Tablet_600:gap-[0px_2px] Mobile_480:gap-[0px_2px] height_414:gap-[0px_2px]"
               onClick={() => {
                 order0().commits?.push(({
                   action: CommitAction.SET_ORDER_TYPE,
                   orderType: OrderType.PickUp,
                 }));
               }}
          >
            <b className="relative leading-[17px] sm:text-xs Tablet_768:text-2xs Tablet_600:text-2xs Mobile_480:text-2xs height_414:text-2xs">
              {LL0().editOnlineMenu.delivery()}
            </b>
            <img
              className="w-5 relative h-5 object-cover Tablet_768:w-[18px] Tablet_768:h-[18px] Tablet_600:w-[18px] Tablet_600:h-[18px] Mobile_480:w-[18px] Mobile_480:h-[18px] height_414:w-[18px] height_414:h-[18px]"
              alt=""
              src="/icongeneralarrow-down-icon--white@2x.png"
            />
          </div>
          }
          { order0().type === OrderType.PickUp &&
          <div className="!flex rounded-81xl bg-orange-solid-orange-520-f39221 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden shrink-0 hidden flex-row items-center justify-center py-0 pr-1 pl-2 box-border gap-[0px_2px] Tablet_768:gap-[0px_2px] Tablet_600:gap-[0px_2px] Mobile_480:gap-[0px_2px] height_414:gap-[0px_2px]"
               onClick={() => order0().commits?.push(({
                 action: CommitAction.SET_ORDER_TYPE,
                 orderType: OrderType.Delivery,
               }))}
          >
            <b className="relative leading-[17px] sm:text-xs Tablet_768:text-2xs Tablet_600:text-2xs Mobile_480:text-2xs height_414:text-2xs">
              {LL0().editOnlineMenu.pickup()}
            </b>
            <img
              className="w-5 relative h-5 object-cover Tablet_768:w-[18px] Tablet_768:h-[18px] Tablet_600:w-[18px] Tablet_600:h-[18px] Mobile_480:w-[18px] Mobile_480:h-[18px] height_414:w-[18px] height_414:h-[18px]"
              alt=""
              src="/icongeneralarrow-down-icon--white@2x.png"
            />
          </div>
          }
        </div>
      </div>
      <div className="h-[34px] flex flex-row items-start justify-start gap-[0px_4px] text-smi sm:h-8 sm:gap-[0px_4px] Tablet_768:h-8 Tablet_768:gap-[0px_4px] Tablet_768:pl-0 Tablet_768:box-border Tablet_600:h-8 Tablet_600:gap-[0px_4px] Mobile_480:gap-[0px_4px] height_414:h-8 height_414:gap-[0px_4px]">
        <div className="self-stretch rounded-81xl bg-orange-solid-orange-800-f25936 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] overflow-hidden flex flex-row items-center justify-center py-0 pr-2 pl-1.5 gap-[0px_3px] sm:pl-2.5 sm:pr-2.5 sm:box-border Tablet_768:pl-2.5 Tablet_768:pr-2.5 Tablet_768:box-border Tablet_600:pl-2.5 Tablet_600:pr-2.5 Tablet_600:box-border Mobile_480:pl-2.5 Mobile_480:pr-2.5 Mobile_480:box-border height_414:pl-2.5 height_414:pr-2.5 height_414:box-border mq320:pl-2.5 mq320:pr-2.5 mq320:box-border"
             ref={makeRipple}
             onClick={onPrintDeliveryOrder}
        >
          <img
            className="w-7 relative h-7 object-cover sm:hidden Tablet_768:hidden Tablet_768:w-6 Tablet_768:h-6 Tablet_600:hidden height_414:hidden"
            alt=""
            src="/iconprinter-icon-3@2x.png"
          />
          <div className="flex flex-col items-center justify-center">
            {!isShowPrice() ?
              <b
                className={clsx("!flex relative leading-[17px] hidden sm:text-2xs Tablet_768:text-2xs Tablet_600:text-2xs height_414:text-2xs",
                  isSmallSidebar() && '!text-[12px]'
                )}>
                {LL0().ui.print()}
              </b>
              :
              <b className={clsx("relative sm:text-2xs Tablet_768:text-2xs Tablet_600:text-2xs height_414:text-2xs",
                isSmallSidebar() && '!text-[12px]'
              )}>
                {LL3().format.currency(order0?.()?.vSum || 0)}
              </b>}
          </div>
        </div>
        <div className="self-stretch rounded-81xl bg-blue-solid-blue-450-1271ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 gap-[0px_3px] text-sm"
             onClick={() => router.screen = mainScreen()}
             ref={makeRipple}
        >
          <img
            className="w-[30px] relative h-[30px] object-cover"
            alt=""
            src="/iconback-icon@2x.png"
          />
          <b className="w-[45px] relative leading-[17px] hidden">Button</b>
        </div>
      </div>
    </div>
  );
};

export default NewDeliveryTopNav;
