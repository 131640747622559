import { getReplicates } from "@/data/ReplicateEffect.ts";
import { collectionsMap } from "@/data/CollectionsMap.ts";
import delay from "delay";
import { deviceSettingLock } from "@/data/DeviceSettingHub.ts";
import { toast } from "react-toastify";
import { signal } from "@/react/core/reactive.ts";
import { ModeConnect, type PosUser } from "@/react/Developer/DataMenu.logic.ts";
import { setDeviceAsMaster } from "@/react/MasterMachineView/MasterMachineView.tsx";
import { DATA_INITED_2 } from '@/data/data-const'
import { firstTimeConnect } from "@/data/ReplicateState.ts";
import { setIsMaster } from "@/lib/fetch-master.ts";
import { RecordId } from "surrealdb.js";
import SurrealClient from "@/shared/SurrealClient.ts";
import { getDeviceId } from '@/shared/getDeviceId'
import { clearIndexedDb, setIsDisabledConnect } from "@/react/Developer/RegisterStore.tsx";
import dialogService from "@/react/SystemService/dialogService.tsx";
import axios from "axios";
import { getApiUrl } from "@/shared/utils.ts";
import msgBox from "@/react/SystemService/msgBox.tsx";
import _ from "lodash";
import { posSetting0, posSettingLock } from "@/data/PosSettingsSignal.ts";
import { server } from "@/data/Server.ts";
import { posSync0, posSyncLock } from "@/data/PosSyncState.ts";
import semver from "semver";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { zipTextToBlob } from "@/shared/zipUtils.ts";
import { uploadFile } from "@/shared/FileUploader.ts";
import { sendEmail } from "@/shared/EmailSender.ts";
import dayjs from "dayjs";
import debug from 'debug'
import { srmLogic } from "@/srm/logic"

const log = debug('connect')
const initialMode: ModeConnect = (import.meta.env.MODE === 'production') ? ModeConnect.DEFAULT : ModeConnect.DEVELOPER;
export const [currentMode, setCurrentMode] = signal<ModeConnect>(initialMode)

export function replicateFactory() {
  async function waitForReplicate () {
    // const closeLoadingDialog = dialogService.progress({ title: "Loading data" });
    const t = toast("Loading data..., please wait !!!", {
      autoClose: false,
      progress: 100,
    })
    while (Object.keys(getReplicates()).length < collectionsMap('sync').length) {
      await delay(1000)
    }
    for (const { collection, name } of collectionsMap('sync')) {
      await getReplicates()[name]?.awaitInSync()
    }
    toast.done(t)
    // closeLoadingDialog()
  }

  async function connectAsDevelopver () {
    await waitForReplicate()
    localStorage.setItem("change-master-device", "true");
    await onDisconnect();
    await srmLogic.resetSrmSettingsToDefault()
  }

  async function connectAsMaster () {
    await waitForReplicate()
    localStorage.setItem('clear-print-cache', 'true');
    await posSettingLock.acquireAsync()
    await deviceSettingLock.acquireAsync()
    if (deviceSetting0()) {
      await setDeviceAsMaster(deviceSetting0()!)
    }
  }

  async function connectAsClient () {
    await waitForReplicate()
    // clearPrintCache();
  }

  return {
    connectAsDevelopver,
    connectAsMaster,
    connectAsClient
  }
}

export const backupCollections = async (email: string) => {
  if (!email) return toast.error('Please enter email!')
  if (!validateEmail(email)) return toast.error('Email invalid!')
  const t = toast.info('Processing, please wait!', { autoClose: false })
  const collections = collectionsMap('backup')
  const collectionsData = [];
  const csvFiles = [];

  for (const item of collections) {
    const data = await item.collection.find().exec();
    if (data.length > 0)
      collectionsData.push({ data: data.map(d => d.toJSON()), collectionName: item.name });
  }

  for (const { data, collectionName } of collectionsData) {
    const jsonData = JSON.stringify(data, null, 4);
    csvFiles.push([`${collectionName}.json`, jsonData]);
  }

  const blob = await zipTextToBlob(...csvFiles);
  const blobUrl = await uploadFile('srmBackup', blob, progress => console.log('progress', progress))
  console.log('💾 Data uploaded', blobUrl)
  await sendEmail(email, `Exported data ${dayjs().format()}`, `Your backup data is: ${blobUrl}`)
  toast.done(t)
  toast.success('Backup success, please check your email!')
}

export function validateEmail(email: string): boolean {
  const regex = /^[\p{L}\p{N}._%+-]+@[\p{L}\p{N}.-]+\.\p{L}+$/u;
  return regex.test(email);
}

export async function onDisconnect(reload?: boolean) {
  localStorage.removeItem("init-device-setting");
  const ds = deviceSetting0()
  if (ds && ds.srm) {
    const srm = _.cloneDeep(ds.srm)
    await ds.doc.remove()
    await ds.doc.incrementalUpdate({ $set: { srm } })
  }
  await delay(1000)
  posSync0().id = undefined;
  posSync0().replicationIdentifierPrefix = posSync0().replicationIdentifierPrefix ? posSync0().replicationIdentifierPrefix! + 1 : 1;
  toast("Disconnected to the store", { type: "info" });
  await delay(2000)
  if (!reload) {
    location.reload();
  }
}

export async function onConnect(mode: 'master' | 'client' | 'support' , store?: string, isReconnect?: boolean) {
  log(`connect as ${mode} to store: ${store}`)
  await posSettingLock.acquireAsync();
  const deviceId = getDeviceId()
  localStorage.setItem(DATA_INITED_2, 'true');
  if (!store) {
    toast.error("You have not entered Database ID")
    return
  }
  if (!deviceSetting0()) {
    toast.error("Something went wrong")
    return
  }
  const db = await SurrealClient.getSurrealClient('cloud')
  if (!db) return
  const closeRegisterDialog = dialogService.progress({ title: 'Connect' })
  setIsDisabledConnect(true)

  const existedUser = await db.select<PosUser>(new RecordId('PosUser', parseInt(store)));
  if (!existedUser) {
    toast.error("The database does not exist")
    closeRegisterDialog();
    setIsDisabledConnect(false)
    return
  }
  const [existedDevice] = await db.query(`select * from only Device:${deviceId}`)
  if (!existedDevice && !isReconnect && mode !== 'support') {
    try {
      const deviceRes = await axios.post(
        `${getApiUrl()}/api/registerDevice`,
        { store: parseInt(store), deviceId: deviceId, name: deviceSetting0()?.name, show: true }
      )
      deviceSetting0()!.cloudRegister = true
      closeRegisterDialog()
    } catch (e: any) {
      setIsDisabledConnect(false)
      closeRegisterDialog()
      await msgBox.show('Error', 'Failed to connect database: ' + e.message, msgBox.Buttons.OK, msgBox.Icons.Error)
    }
  } else {
    console.log('existedDevice')
    deviceSetting0()!.cloudRegister = true
  }
  firstTimeConnect.v = true;
  setIsMaster(false);

  localStorage.setItem("password", existedUser.password)
  localStorage.setItem("posDatabase", store);
  localStorage.setItem("tempServer", server()!);
  // localStorage.setItem("needInitStoreStep", "1");
  localStorage.setItem("connectAsMaster", mode === 'master' ? 'true': 'false');

  await clearIndexedDb();
  //trigger shouldInitStore
  localStorage.setItem("needInitStoreStep", "2");

  posSync0()!.id = undefined;
  await delay(100);
  closeRegisterDialog();
  setIsDisabledConnect(false)
  location.reload();
  return;

}

export async function onReconnect() {
  await posSyncLock.acquireAsync()
  const closeRegisterDialog = dialogService.progress({ title: 'Reconnect' })
  try {
    localStorage.setItem('modeConnect', '1')
    const storeId = posSync0().id
    posSync0().replicationIdentifierPrefix = posSync0().replicationIdentifierPrefix ? posSync0().replicationIdentifierPrefix! + 1 : 1;
    posSync0().id = undefined;
    const isMaster = deviceSetting0()?._id === posSetting0()?.masterDevice
    await onConnect(isMaster ? 'master' : 'client', storeId!.toString(), true)
  } catch (e) {
    console.log(e)
    toast.error('Failed to reconnect')
  } finally {
    closeRegisterDialog();
  }
}

export async function onForceUpdate() {
  toast.info('Updating...')
  try {
    const db = await SurrealClient.getSurrealClient('cloud')
    if (!db || !posSync0()?.id) return toast('Failed to update version, need connect to store first!')
    const [latestV] = await db.query<Array<any>>('SELECT * FROM app_releases ORDER BY releaseDate DESC LIMIT 1')
    const [currentV] = await db.query<Array<any>>(`SELECT * FROM StoreVersion:${posSync0().id}`)
    if (!latestV[0]?.version || !currentV[0]?.version) return toast('Failed to update version')
    if (semver.gt(latestV[0].version, currentV[0].version)) {
      await db.query(`UPDATE StoreVersion:${posSync0().id} SET version = '${latestV[0].version}'`)
      toast.success(`Update success, new version is ${latestV[0].version}`)
      await db.query(`INSERT INTO Request CONTENT { "storeId" = ${posSync0().id}, "command": "update" }`)
      return
    }
    toast.success('This version is up to date')
  } catch (err) {
    toast.error('Failed to update version ' + err)
  }
}