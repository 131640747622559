import debug from 'debug'
import _ from 'lodash'
import { toast } from 'react-toastify'

import type { DeviceSrmSetting } from '@/data/DeviceSetting'
import { deviceSetting0 } from '@/data/DeviceSettingSignal.ts'
import { posSetting0 } from '@/data/PosSettingsSignal.ts'
import { computed, type Accessor } from '@/react/core/reactive'
import defaultTaxesByCity from '@/react/PaymentSettingView/defaultTaxesByCity.json'
import dialogService from '@/react/SystemService/dialogService'
import { bypassConfirmation } from '@/shared/decorators'
import { CONSTANT_VALUES, REGISTERED_VALUES } from '@/srm/lib/constants'

import { srmTransactionLogic } from '../transaction.logic'
import { testRunner001 } from './001'
import { testRunner002 } from './002'
import { testRunner003 } from './003'
import { testRunner004 } from './004'
import { testRunner005 } from './005'
import { testRunner006 } from './006'
import { testRunner007 } from './007'
import { testRunner008 } from './008'
import { testRunner009 } from './009'
import { testRunner010 } from './010'
import { testRunner011 } from './011'
import { testRunner019 } from './019'
import { testRunner020 } from './020'
import { testRunner021 } from './021'
import { testRunner022 } from './022'
import { testRunner024 } from './024'
import { testRunner026 } from './026'
import { testRunner029 } from './029'
import { testRunner030 } from './030'
import { testRunner031 } from './031'
import { testRunner036 } from './036'
import { testRunner037 } from './037'
import { testRunner103 } from './103'
import { REQUIRED_TESTCASES, TESTCASE_VARS } from './constants'
import { nextTestcase, setLastSucceededTestcase, setNextTestcase, setTestDevices, testDevices } from './state'

const log = debug('data:srm')

export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms))

const activeVersion: Accessor<string | undefined> = computed(() => Object.entries(REGISTERED_VALUES).find(([_, v]) => v.IDVERSI === posSetting0()?.srm?.productVersionId)?.[0])
const requiredTestcases: Accessor<Record<string, string[]> | undefined> = computed(() => REQUIRED_TESTCASES[activeVersion() as keyof typeof REGISTERED_VALUES])
const flattenedRequiredTestcases = computed(() =>
  Object.entries(requiredTestcases() ?? {})
    .sort()
    .flatMap(([num, steps]) => steps.map(step => [num, step].map(i => i.toString().padStart(3, '0')).join('.')))
)

export const runProgress = computed(() => {
  const cases = flattenedRequiredTestcases()
  const passed = cases.indexOf(nextTestcase()) + 1
  const total = cases.length
  return `${passed}/${total}`
})

export async function advanceToTestcase(testcaseNumber: string) {
  await posSetting0()?.doc?.incrementalUpdate({
    $set: { 'srm.testcaseNumber': testcaseNumber },
  })
}
const colors = {
  blue: ['color:DodgerBlue', 'color:initial'],
  fuchsia: ['color:Fuchsia', 'color:initial'],
}

export function getCaTaxInfo() {
  const tax = defaultTaxesByCity['Canada-Quebec'][0]
  const taxVal = tax.components.reduce((acc, curr) => acc + curr.value, 0)
  return {
    tax: taxVal,
    taxCategory: tax.name,
    taxComponents: tax.components,
  }
}

export function getCaAdditionalTaxInfo() {
  const tax = defaultTaxesByCity['Canada-Quebec'][1]
  const taxVal = tax.components.reduce((acc, curr) => acc + curr.value, 0)
  return {
    tax: taxVal,
    taxCategory: tax.name,
    taxComponents: tax.components,
  }
}

export function clearTestDevices() {
  setTestDevices(null)
  setNextTestcase('001.001')
}

export async function useTestDevice(name: 'A' | 'B' | 'C') {
  const config = testDevices()?.[name]

  await deviceSetting0()?.doc?.incrementalUpdate({
    $set: {
      'srm.deviceId': config?.deviceId,
      'srm.cert': config?.cert,
      'srm.certPSI': config?.certPSI,
      'srm.privateKey': config?.privateKey,
      'srm.publicKey': config?.publicKey,
      'srm.lastTransSig': config?.lastTransSig,
    },
  })
}
export async function saveTestDevice(name: 'A' | 'B' | 'C') {
  const config = deviceSetting0()?.srm
  const val = config ? _.cloneDeep(config) : undefined
  setTestDevices(pre => ({ ...pre, [name]: val } as Record<'A' | 'B' | 'C', DeviceSrmSetting>))
}

interface ExecTestcaseOptions {
  num: number
  step: number
  device: 'A' | 'B' | 'C'
  impersonate?: string
  run: () => Promise<void>
}

export async function execTestcase({ num, device, run, step, impersonate }: ExecTestcaseOptions): Promise<boolean> {
  const testcase = [num, step].map(i => i.toString().padStart(3, '0')).join('.')
  const closeDialog = dialogService.progress({ title: `Running ${testcase}...` })
  console.groupCollapsed(`[SRM] 🧪 ${testcase}`)
  bypassConfirmation('always')
  srmTransactionLogic.options.impersonate = impersonate ?? TESTCASE_VARS.userName
  try {
    await advanceToTestcase(testcase)
    await useTestDevice(device)
    await sleep(200) // Take a breath
    log(`ℹ️ [%c${testcase}%c] starting...`, ...colors.blue)
    await run()
    setLastSucceededTestcase(testcase)
    await saveTestDevice(device)
    return true
  } catch (e) {
    console.warn(e)
    return false
  } finally {
    srmTransactionLogic.options.impersonate = undefined
    await advanceToTestcase(CONSTANT_VALUES.CASESSAI_EMPTY)
    bypassConfirmation(false)
    console.groupEnd()
    closeDialog()
  }
}

const runnerMap: Record<string, Record<string, () => Promise<boolean>>> = {
  '001': testRunner001,
  '002': testRunner002,
  '003': testRunner003,
  '004': testRunner004,
  '005': testRunner005,
  '006': testRunner006,
  '007': testRunner007,
  '008': testRunner008,
  '009': testRunner009,
  '010': testRunner010,
  '011': testRunner011,
  '019': testRunner019,
  '020': testRunner020,
  '021': testRunner021,
  '022': testRunner022,
  '024': testRunner024,
  '026': testRunner026,
  '029': testRunner029,
  '030': testRunner030,
  '031': testRunner031,
  '036': testRunner036,
  '037': testRunner037,
  '103': testRunner103,
}

export async function execAllTestcase() {
  const cases = Object.entries(requiredTestcases() ?? {}).sort()
  for (const [num, steps] of cases)
    for (const step of steps) {
      const testcase = [num, step].map(i => i.toString().padStart(3, '0')).join('.')
      if (testcase !== nextTestcase()) continue
      const fn = runnerMap[num]?.[testcase]
      if (!fn) return toast.warn(`Testcase ${testcase} not implemented!`)
      if ((await fn()) === false) return toast.error(`Testcase ${testcase} failed!`)
    }

  toast.success('All testcases passed!', { autoClose: false })
}

export async function resetExecTestcase() {
  setLastSucceededTestcase('')
  setNextTestcase('001.001')

  const cases = Object.entries(requiredTestcases() ?? {}).sort()
  for (const [num] of cases) await runnerMap[num]?.cleanup?.()
}
