import debug from 'debug'

import { PosSetting } from '@/data/PosSetting'
import { isQuebecSrmEnabled, posSetting0 } from '@/data/PosSettingsSignal.ts'
import { computed } from '@/react/core/reactive'

const log = debug('data:srm')

export enum PosFeatures {
  /** The Cancel button in the OrderHistory screen */
  CancelCompletedOrder,
  /** The Void button in the dropdown menu in OrderHistory screen  */
  VoidOrder,
  PendingOrder,
  DeliveryOrder,
  SplitBill,
  Discount,
  RedBill,
  /** Move Table button in FastCheckout screen */
  MoveTable,
  MultiplePayment,
  ChangeTipWhenSwitchPaymentMethod,
  CustomDish,
  Reactivate,
}

export const isPendingOrderFeatureEnabled = computed(() => posSetting0()?.srm?.features?.[PosFeatures.PendingOrder] ?? true)
export const isDeliveryOrderFeatureEnabled = computed(() => posSetting0()?.srm?.features?.[PosFeatures.DeliveryOrder] ?? true)
export const isSplitBillFeatureEnabled = computed(() => posSetting0()?.srm?.features?.[PosFeatures.SplitBill] ?? true)
export const isMoveTableFeatureEnabled = computed(() => posSetting0()?.srm?.features?.[PosFeatures.MoveTable] ?? true)
export const isMultiplePaymentFeatureEnabled = computed(() => posSetting0()?.srm?.features?.[PosFeatures.MultiplePayment] ?? true)

/** Check for feature that we should disable */
export function isFeatureEnabled(feat: PosFeatures): boolean {
  if (!isQuebecSrmEnabled()) return true

  const features = posSetting0()?.srm?.features || ({} as Record<PosFeatures, boolean>)
  return features[feat] ?? true
}

export async function toggleFeature(feat: PosFeatures, enabled: boolean) {
  log(`Toggle feature ${feat} enabled:${enabled}`)
  await PosSetting.findOne().update({ $set: { ['srm.features.' + feat]: enabled } })
}
