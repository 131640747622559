import { type FunctionComponent, useCallback } from 'react';
import {
  activeRoomId,
  EditorMode,
  setActiveRoomId,
  setMode0,
  setRoomObject0
} from '@/react/EditRoomView/EditRoomView.tsx';
import { useSelector } from '@/react/core/reactive.ts';
import { makeRipple } from '@/react/core/ripple-utils.ts';

type ManuelTableFloorButtonType = {
  floorText?: string;
  showSelected?: boolean;
  showRedDot?: boolean;
  _id: string
};

const ManuelTableFloorButton: FunctionComponent<ManuelTableFloorButtonType> = ({
  floorText = "Main Area",
  showSelected = true,
  showRedDot = true,
  _id
}) => {
  const isSelected = useSelector(activeRoomId);
  const onClick = useCallback(() => {
    setActiveRoomId(_id)
    setRoomObject0()
    setMode0(EditorMode.Room)
  }, [_id]);
  showSelected = isSelected(_id)

  return (
    <div className="rounded-10xs bg-steelblue flex flex-row items-center justify-center py-[11px] px-2 box-border relative gap-[8px] text-center text-sm text-basic-color-white font-mulish"
         onPointerDown={onClick}
         ref={makeRipple}
    >
      {showSelected && (
        <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-orange z-[0]" />
      )}
      <b className="flex-1 relative z-[1] Tablet_600:text-smi Mobile_480:text-xs">
        {floorText}
      </b>
      {showRedDot && (
        <div className="absolute my-0 mx-[!important] top-[4px] right-[4px] rounded-[50%] bg-crimson-200 w-2 h-2 z-[2]" />
      )}
    </div>
  );
};

export default ManuelTableFloorButton;
