import type { Order } from "@/data/Order";
import { type OrderItem, type OrderPayment, OrderStatus, TseMethod } from "@/pos/OrderType";
import _ from "lodash";
import { VorgangArt } from "@/data/TseTransaction.ts";
import { getTaxTotal, getTotal } from "@/tse/tse-utils.ts";
import dayjs from "dayjs";
import type { TransactionContent } from "@/tse/tse-init.ts";
import { formatCurrency } from "@/shared/utils.ts";
import { Buffer } from "buffer/";
import { LL2 } from "@/react/core/I18nBackend.tsx";
import { PaymentType } from "@/data/Payment"

export function getPayment(payment: OrderPayment) {
  if (payment.extraType === PaymentType.Cash) return 'Bar';
  if (payment.type === 'cash') return 'Bar';
  if (payment.type === 'bar' || payment.type === 'Bar') return 'Bar';
  return 'Unbar'
}

function getIsoPayment(payment: OrderPayment) {
  if (payment.extraType === PaymentType.Cash) return 'cash';
  if (payment.type === 'cash') return 'cash';
  if (payment.type === 'bar' || payment.type === 'cash') return 'cash';
  return 'cashless'
}

export enum vorgangstypConstant {
  Beleg = 'Beleg',
  AVTransfer = 'AVTransfer',
  AVBestellung = 'AVBestellung',
  AVTraining = 'AVTraining',
  AVBelegstorno = 'AVBelegstorno',
  AVBelegabbruch = 'AVBelegabbruch',
  AVSachbezug = 'AVSachbezug',
  AVSonstige = 'AVSonstige',
  AVRechnung = 'AVRechnung'
}

export function makeKassenBelegFinish(order: Order) {
  //Beleg^75.33_7.99_0.00_0.00_0.00^ 10.00:Bar_5.00:Bar:CHF_5.00:Bar:USD_64.30:Unbar
  const processType = VorgangArt.KassenbelegV1;

  let Vorgangstyp = order.status === OrderStatus.CANCELLATION_REFERENCE ? vorgangstypConstant.AVBelegstorno : vorgangstypConstant.Beleg;
  const betrag = getTotal(order);
  //
  let zahlungen = [];
  const payments = _.orderBy(order.payments, p => getIsoPayment(p) === 'cash' ? 0 : 1);
  for (const payment of payments) {
    let total = _.round(payment.value, 2);
    if (getIsoPayment(payment) === 'cash') {
      total = total - order.cashback!;
    } else if (payment.type === 'card') {
      total = total - order.tip!;
    }
    zahlungen.push(`${formatCurrency(total)}:${getPayment(payment)}`)
  }
  ///const
  //let zahlungen = `${betrag}_${getPayment(order)}`;

  const transactionData = `${Vorgangstyp}^${getTaxTotal(order)}^${zahlungen.join('_')}`;
  return {
    processType,
    transactionData
  }
}

export function makeKassenBestellung(items: OrderItem[], cancellationsItems: OrderItem[], allowPassthrough?: boolean) {
  const result: TransactionContent = {
    processType: VorgangArt.BestellungV1,
    transactionData: ''
  }
  const transactionDataArr = [];
  const lineBreaker = '\r';

  for (const item of items) {
    const _item = _.cloneDeep(item);
    if (_item.tseMethod === 'passthrough' && !allowPassthrough) {
      continue;
    }
    if (_item.tseMethod === TseMethod.applyPart && _item.quantity > -1 && !allowPassthrough) {
      _item.quantity = 1;
    } else if (_item.tseMethod === TseMethod.applyPart && _item.quantity <= -1 && !allowPassthrough) {
      _item.quantity = -1;
    }
    const _price = _item.vPrice! + _.sumBy(item.modifiers, m => m.vPrice! * m.quantity);
    const price = formatCurrency(_price);
    transactionDataArr.push(`${_item.quantity};${_item.name};${price}`);
  }

  for (const item of (cancellationsItems || [])) {
    const _item = _.cloneDeep(item);
    if (_item.tseMethod === 'passthrough' && !allowPassthrough) {
      continue;
    }
    // apply:1 ??
    const price = formatCurrency(item.price);
    transactionDataArr.push(`-${item.quantity};${item.name};${price}`)
  }

  result.transactionData = transactionDataArr.join(lineBreaker);
  return result;
}

interface qrParams {
  qrCodeVersion?: string;
  kassenSeriennummer: string;
  processType?: string;
  processData: string;
  transactionNumber: number;
  signatureCounter: number;
  startTime: number;
  logTime: number;
  signature: string;
  sigAlg?: string;
  logTimeFormat?: string;
  publicKey: string;
}

export function makeQr({
  qrCodeVersion = 'V0',
  kassenSeriennummer = '00001',
  processType = 'Kassenbeleg-V1',
  processData,
  transactionNumber,
  signatureCounter,
  startTime,
  logTime,
  signature,
  sigAlg = 'ecdsa-plain-SHA256',
  logTimeFormat = 'unixTime',
  publicKey
}: qrParams) {
  const _start = dayjs.unix(startTime).toDate().toISOString();
  const _logTime = dayjs.unix(logTime).toDate().toISOString();
  const _signature = Buffer.from(signature, "hex").toString('base64')
  const _publicKey = Buffer.from(publicKey, "base64").toString('base64');
  return `${qrCodeVersion};${kassenSeriennummer};${processType};${processData};${transactionNumber};${signatureCounter};${_start};${_logTime};${sigAlg};${logTimeFormat};${_signature};${_publicKey}`
}

export function hexToBytes(hex: string) {
  let bytes = [];
  for (let c = 0; c < hex.length; c += 2)
    bytes.push(parseInt(hex.substr(c, 2), 16));
  return bytes;
}

// Convert a byte array to a hex string
export function bytesToHex(bytes: number[]) {
  let hex = [];
  for (let i = 0; i < bytes.length; i++) {
    const current = bytes[i] < 0 ? bytes[i] + 256 : bytes[i];
    hex.push((current >>> 4).toString(16));
    hex.push((current & 0xF).toString(16));
  }
  return hex.join("");
}

export function extractRawQr(qrCode: string) {
  const [qrCodeVersion, kassenSeriennummer, processType, processData, transactionNumber,
    signatureCounter, startTime, logTime, sigAlg, logTimeFormat, signature, publicKey] = qrCode.split(';');
  return {
    qrCodeVersion,
    kassenSeriennummer,
    processType,
    processData,
    transactionNumber: parseInt(transactionNumber),
    signatureCounter: parseInt(signatureCounter),
    startTime: parseInt(startTime),
    logTime: parseInt(logTime),
    sigAlg,
    logTimeFormat,
    signature,
    publicKey
  }
}

export function extractQr(qrCode: string) {
  const [qrCodeVersion, kassenSeriennummer, processType, processData, transactionNumber,
    signatureCounter, startTime, logTime, sigAlg, logTimeFormat, signature, publicKey] = qrCode.split(';');
  return {
    qrCodeVersion,
    kassenSeriennummer,
    processType,
    processData,
    transactionNumber,
    signatureCounter,
    startTime: dayjs(startTime).format(LL2().dates.fullTime()),
    logTime: dayjs(logTime).format(LL2().dates.fullTime()),
    sigAlg,
    logTimeFormat,
    signature,
    publicKey
  }
}