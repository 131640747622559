import type { RxDatabase } from 'rxdb'

import { TAX_CATEGORY_COLLECTION_NAME, TaxCategory, taxCategorySchema } from './TaxCategory'
import { createCollection } from './utils/migration'

export const createTaxCategoryCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: TaxCategory,
    collectionName: TAX_CATEGORY_COLLECTION_NAME,
    version: taxCategorySchema.version,
    schema: taxCategorySchema,
  })
}
