import { srmUserLogic } from '../user.logic'
import { TESTCASE_VARS } from './constants'
import { execTestcase } from './libs'
import { testcaseLogic } from './testcase.logic'

export const testRunner002: Record<string, () => Promise<boolean>> = {
  '002.001': () =>
    execTestcase({
      num: 2,
      step: 1,
      device: 'A',
      run: async () => {
        await testcaseLogic.createTestUsers()
        await srmUserLogic.createUser({ name: 'Michel Untel', passcode: '1111', noTPS: TESTCASE_VARS.gstNumber, noTVQ: TESTCASE_VARS.qstNumber })
      },
    }),
  '002.002': () =>
    execTestcase({
      num: 2,
      step: 2,
      device: 'A',
      run: () => srmUserLogic.deleteUser('Michel Untel'),
    }),
  '002.003': () =>
    execTestcase({
      num: 2,
      step: 3,
      device: 'A',
      run: () => srmUserLogic.createUser({ name: 'Michel Untel', passcode: '1111', noTPS: TESTCASE_VARS.gstNumber, noTVQ: TESTCASE_VARS.qstNumber }),
    }),
  '002.004': () =>
    execTestcase({
      num: 2,
      step: 4,
      device: 'A',
      run: () => srmUserLogic.createUser({ name: 'John Smith', passcode: '2222', noTPS: TESTCASE_VARS.gstNumber, noTVQ: TESTCASE_VARS.qstNumber }),
    }),
}
