import { InvoiceTypes } from '@/pos/OrderType.ts'
import { order0 } from '@/react/PaymentView/PaymentView.tsx'
import {
  printFooterTextWithCache,
  printHeader0WithCache,
  printInvoiceRasterFactory,
  printPublicKeyWithCache,
  printRedInvoiceWithCache,
  printSerialNumberWithCache,
  printSignatureAlgorithmWithCache,
  printTimeFormatWithCache,
  printUserNameWithCache
} from '@/react/Printer/print-invoice-api.ts'
import type { Raster } from '@/shared/printer/pure-image-printer-parallel'
import { type Order, PaidOrder } from "@/data/Order.ts";
import { printQrCode } from "@/react/Printer/print-invoice.ts";
import { getInvoicePrinter } from "@/react/Printer/print-utils.ts";
import uuid from "time-uuid";
import dayjs from "dayjs";
import { Buffer } from "buffer/";
import { tseConfig0 } from "@/data/TseConfigHub.ts";
import _ from "lodash";
import { VPrinter } from "@/react/Printer/VPrinter.ts";
import { recheckInvoiceGroupPrinters0 } from "@/data/GroupPrinterHub.ts";
import { companyInfo0, generalSetting0, posSetting0 } from "@/data/PosSettingsSignal.ts";
import { markFinished, markStarted, printDuration } from '@/shared/performance-utils'
import type { ScriptedRaster } from '@/shared/printer/types'
import { assert } from '@/shared/assert'
import { PrintScripts } from '@/data/PrintScripts'
import { printDetailFLow } from "@/shared/logger.ts";

export const PrintStack = {
  _id: undefined as string | undefined,
  header0: { raster: undefined as ScriptedRaster | undefined },
  body0: { raster: undefined as ScriptedRaster | undefined },
  payment0: { raster: undefined as ScriptedRaster | undefined },
  footer0: { raster: undefined as ScriptedRaster | undefined },
  qrCode: { raster: undefined as ScriptedRaster | undefined },
}

export async function printHeader0(): Promise<ScriptedRaster> {
  const raster = await printHeader0WithCache()
  // await printImageToConsole('PrintStack - Header 0', raster);
  return raster;
}

export async function printBody0(order?: Order, invoiceType?: InvoiceTypes) {
  const _order = _.cloneDeep(order || order0());
  _order.items = _order.items.filter(item => item?.quantity !== 0);
  const api = await printInvoiceRasterFactory(_order, invoiceType || InvoiceTypes.INVOICE);
  await api.printBody0();
  PrintStack.body0.raster = await api.getRaster();
  return PrintStack.body0.raster;
  // await printImageToConsole('PrintStack - Body', PrintStack.body0.raster)
}

//write printPayment0
export async function printPayment0(order?: Order, invoiceType?: InvoiceTypes) {
  const api = await printInvoiceRasterFactory(order || order0(), invoiceType || InvoiceTypes.INVOICE);
  await api.printPayment?.();
  PrintStack.payment0.raster = await api.getRaster?.();
  return PrintStack.payment0.raster;
  // await printImageToConsole('PrintStack - Payment', PrintStack.payment0.raster)
}

// DEBUG
// @ts-expect-error debug only
window.test_printInvoiceAsStack = async () => {
  const _order = await PaidOrder.findOne({ selector: { id: 8 } }).exec();
  if (!_order) return
  await printInvoiceStackStep1(_order, InvoiceTypes.RED_INVOICE)
  // await printInvoiceStackStep2(_order, InvoiceTypes.RED_INVOICE);
}

export async function printInvoiceStackStep1(order: Order, invoiceType: InvoiceTypes): Promise<ScriptedRaster> {
  markStarted('printInvoiceStackStep1')
  const stack: ScriptedRaster[] = [];
  printDetailFLow(`printHeader0`, {orderId: order._id});
  const header0 = await printHeader0();
  stack.push(header0);

  async function getTimeRaster() {
    const api = await printInvoiceRasterFactory(order || order0(), invoiceType || InvoiceTypes.INVOICE);
    await api.printTime();
    return await api.getRaster();
  }

  printDetailFLow(`getTimeRaster`, {orderId: order._id});
  const time0 = await getTimeRaster();
  stack.push(time0);

  printDetailFLow(`printId`, {orderId: order._id});
  const id0 = await printId();
  stack.push(id0);

  async function getBodyRaster() {
    if (PrintStack._id === order._id && PrintStack.body0.raster) return PrintStack.body0.raster;
    return await printBody0(order, invoiceType);
  }

// await printImageToConsole('Id', id0!);
  printDetailFLow(`getBodyRaster`, {orderId: order._id});
  const body0 = await getBodyRaster();
  stack.push(body0);

  async function getPaymentRaster() {
    const isOnlineOrder = !!order?.provider;
    const isNotPrint = companyInfo0().country === 'de' && !isOnlineOrder && order.customerRaw;

    if (PrintStack._id === order._id && PrintStack.payment0.raster && !isNotPrint) {
      return PrintStack.payment0.raster;
    }
    return await printPayment0(order, invoiceType);
  }

// await printImageToConsole('Body', body0!);
  printDetailFLow(`getPaymentRaster`, {orderId: order._id});
  const payment0 = await getPaymentRaster();
  stack.push(payment0);
  // await printImageToConsole('Payment', payment0!);
  printDetailFLow(`printFooterTextWithCache`, {orderId: order._id});
  const footer0 = await printFooterTextWithCache();
  stack.push(footer0);
  // await printImageToConsole('Footer', footer0);
  //todo: red bill
  if (invoiceType === InvoiceTypes.RED_INVOICE) {
    printDetailFLow(`printRedInvoiceWithCache`, {orderId: order._id});
    const redBillText0 = await printRedInvoiceWithCache();
    stack.push(redBillText0);
  }
  // await printImageToConsole('Red Bill Text', redBillText0);

  const hint = JSON.stringify(stack.map(r => ({...r,esc: r.esc.length, scripts: r.scripts.length, data: r.data ? r.data.length: null})))
  printDetailFLow(`printInvoiceStackStep1 mergeRasters ${hint}`, {orderId: order._id});
  const r = mergeRasters(stack);
  // await printImageToConsole('Invoice Stack', r);
  markFinished('printInvoiceStackStep1')
  printDuration('printInvoiceStackStep1')
  return r;

  //region printInvoiceStackStep1 utils
  async function printId() {
    const { printId, getRaster } = await printInvoiceRasterFactory(order, invoiceType);
    await printId?.();
    return await getRaster?.();
  }

  async function printRedBillText() {
    const api = await printInvoiceRasterFactory(order, invoiceType);
    await printId?.();
    return await api.getRaster?.();
  }

  //endregion
}

export function mergeRasters(rasters: (ScriptedRaster | undefined)[]): ScriptedRaster | undefined {
  if (rasters.length === 0) {
    return undefined;
  }
  // assert(rasters.length > 0, 'No raster to merge');
  let index = 0;
  const combined = _.compact(rasters).reduce((accRaster, raster): ScriptedRaster => {
    if (!accRaster) return raster;
    if (!raster || (raster.height === 0 && !raster.esc?.length)) return accRaster;
    const { data, width, height , esc, scripts } = raster;
    const { data: accData, width: accWidth, height: accHeight, esc: accEsc, scripts: accScripts } = accRaster;
    if (!accData || !data)
      printDetailFLow(`mergeRasters: data is null ${index}`);
    index++;
    const getData = () => {
      if (!data) return undefined;
      if (!accData) return undefined;
      return Buffer.concat([accData, data]);
    }
    // @ts-expect-error TODO: fix typings
    return <ScriptedRaster>{ data: getData(), width: width, height: height + accHeight, esc: Buffer.concat([accEsc, esc]), scripts: [...accScripts, ...scripts] };
  }, undefined as ScriptedRaster | undefined)
  assert(combined, 'Failed to merge rasters')
  return combined
}

export async function printInvoiceStackStep2(order: Order, invoiceType: InvoiceTypes) {
  markStarted('printInvoiceStackStep2')
  const stack: ScriptedRaster[] = [];
  printDetailFLow(`mPrintTseHeader`, {orderId: order._id});

  if (tseConfig0().tseEnable && order.qrCode) {
    const tseHeader = await mPrintTseHeader();
    stack.push(tseHeader);
  }
  // await printImageToConsole('Tse Header', tseHeader);

  if (tseConfig0().tseEnable && order.qrCode) {
    printDetailFLow(`printSerialNumberWithCache`, {orderId: order._id});
    const serialNumber0 = await printSerialNumberWithCache();
    stack.push(serialNumber0);
  }
  // await printImageToConsole('Serial Number', serialNumber0);

  if (tseConfig0().tseEnable && order.qrCode) {
    printDetailFLow(`mPrintTseSignature`, {orderId: order._id});
    const tseSignature = await mPrintTseSignature();
    stack.push(tseSignature);
  }
  // await printImageToConsole('Tse Signature', tseSignature);

  if (tseConfig0().tseEnable && order.qrCode) {
    printDetailFLow(`printTimeFormatWithCache`, {orderId: order._id});
    const timeFormat = await printTimeFormatWithCache();
    stack.push(timeFormat);
  }
  // await printImageToConsole('Time Format', timeFormat);

  if (tseConfig0().tseEnable && order.qrCode) {
    const signatureAlgorithm = await printSignatureAlgorithmWithCache();
    stack.push(signatureAlgorithm);
  }
  // await printImageToConsole('Signature Algorithm', signatureAlgorithm);

  if (tseConfig0().tseEnable && order.qrCode) {
    const publicKey = await printPublicKeyWithCache();
    stack.push(publicKey);
  }
  // await printImageToConsole('Public Key', publicKey);

  if (tseConfig0().tseEnable && order.qrCode) {
    printDetailFLow(`printUserNameWithCache`, {orderId: order._id});
    const userName = await printUserNameWithCache();
    stack.push(userName);
  }
  // await printImageToConsole('User Name', userName);


  if (tseConfig0().tseEnable && order.qrCode) {
    printDetailFLow(`mPrintTseQr`, {orderId: order._id});
    const tseQr = await mPrintTseQr();
    stack.push(tseQr);
  }
  // await printImageToConsole('Tse QR', tseQr);

  const hint = JSON.stringify(stack.map(r => ({...r,esc: r.esc.length, scripts: r.scripts.length, data: r.data ? r.data.length: null})))
  printDetailFLow(`printInvoiceStackStep2 mergeRasters ${hint}`, {orderId: order._id});

  const r = mergeRasters(stack);
  markFinished('printInvoiceStackStep2')
  printDuration('printInvoiceStackStep2')
  return r;

  //region utils
  async function mPrintTseHeader() {
    const api = printQrCode(new VPrinter(getInvoicePrinter()), order);
    await api.printTseHeader();
    const tseHeader = await api.getRaster();
    return tseHeader;
  }

  async function mPrintTseSignature() {
    const api = printQrCode(new VPrinter(getInvoicePrinter()), order);
    await api.printSignature?.();
    const tseSignature = await api.getRaster();
    return tseSignature;
  }

  async function mPrintTseQr() {
    const api = printQrCode(new VPrinter(getInvoicePrinter()), order);
    printDetailFLow(`mPrintTseQr printTseQr`, {orderId: order._id});
    await api.printTseQr();
    printDetailFLow(`mPrintTseQr getRaster`, {orderId: order._id});
    const tseQr = await api.getRaster();
    printDetailFLow(`mPrintTseQr getRaster finish`, {orderId: order._id});
    return tseQr;
  }

  //endregion
}

export function markPrintInvoice(order: Order, invoiceType: InvoiceTypes) {
  if (invoiceType === InvoiceTypes.RED_INVOICE || invoiceType === InvoiceTypes.INVOICE) {
    order.printInvoice = true;
  }
}

/**
 * Print an invoice using raster data
 */
export async function printInvoiceFromRaster2(raster: Raster | ScriptedRaster,
  opts: {
    cut: boolean
    openCashDrawer?: boolean
    prependRaster?: Raster
    virtualPrinter?: boolean
  },
  printImageOptions?: {
    orderId?: string
    invoiceType?: InvoiceTypes
  }) {
  markStarted('printInvoiceFromRaster2')
  const invoicePrinter = getInvoicePrinter()
  if (!invoicePrinter) return
  if (opts.virtualPrinter && !!(raster as ScriptedRaster).scripts) {
    setTimeout(async () => {
      if (!generalSetting0()?.virtualPrinter) {
        await PrintScripts.insert({
          _id: uuid(),
          date: dayjs().unix(),
          scripts: _.cloneDeep((raster as ScriptedRaster).scripts),
          ...printImageOptions ? { metadata: _.cloneDeep(printImageOptions) } : {}
        })
      }
    }, 500)
  }
  const _printers = [...recheckInvoiceGroupPrinters0().map(r => r.printers[0]), invoicePrinter]
  for (const printer0 of _printers) {
    const printer = new VPrinter(printer0)
    await printer.printRaster(raster)

    // if (opts.openCashDrawer) {
    //   try {
    //     if (invoicePrinter.printerType !== 'integrate') {
    //       await printer.openCashDrawer();
    //     } else {
    //       await openCashDrawer();
    //     }
    //   } catch (e) {
    //     captureException(e);
    //   }
    // }
    await printer.print(opts.cut, false, false)
  }
  markFinished('printInvoiceFromRaster2')
  printDuration('printInvoiceFromRaster2')
}

// @ts-expect-error debug only
window.clearPrintCache = () => {
  // @ts-expect-error debug only
  posSetting0()!.printerCache = {};
}
