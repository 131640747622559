import type { FunctionComponent } from "react";
import type { OrderItem as TOrderItem, TOrder } from '@/pos/OrderType.ts';
import { paymentContext0 } from '@/react/PaymentView/PaymentView.tsx';
import { useSwipeable } from 'react-swipeable';

type StartSplittingBillVerticalType = {
  itemName?: string;
  oldPrice?: string;
  price?: string;
  modifierS?: string;
  quantity?: string;
  showOldPrice?: boolean;
  showModifierS?: boolean;
  item: TOrderItem;
  order: TOrder;
};

const StartSplittingBillVertical: FunctionComponent<
  StartSplittingBillVerticalType
> = ({
  itemName = "1.  Roast Chicken Salad",
  oldPrice = "$90.99",
  price = "$99.99",
  modifierS = "medium size, tomato sauce",
  quantity = "1",
  showOldPrice = true,
  showModifierS = true,
  item,
  order
}) => {
  const { splitEnable, onItemClick } = paymentContext0;
  if (!item) return;
  itemName = item.name;
  oldPrice = item.price;
  price = item.vPrice;
  showOldPrice = item.discount !== 0;
  modifierS = item.modifiers.map(modifier => modifier.name).join(", ");
  showModifierS = item.modifiers.length > 0

  quantity = splitEnable() ? (item.quantity - (item.movedQuantity || 0)) : item.quantity;

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      console.log('swipe left');

      if (item.separate) return;
      if (item.course! > 0) {
        item.course!--;
      } else if (item.course === 0) {
        item.course = -1;
      }
    },
    onSwipedRight: () => {
      if (item.separate) {
        item.course = 0;
      } else {
        item.course!++;
      }
    },
    onTap: () => {
      onItemClick(item, order);
    },
    trackMouse: true,
    trackTouch: true,
  });

  return (
    <div className="flex flex-row items-center justify-between py-0 px-4 box-border text-left text-sm text-white-solid-white-100-ffffff font-mulish-button-name-bold self-stretch Mobile_480:pl-2.5 Mobile_480:pr-2.5 Mobile_480:box-border"
         {...handlers}
    >
      <div className="flex-1 flex flex-col items-start justify-start gap-[5px]">
        <b className="self-stretch relative Mobile_480:text-smi">{itemName}</b>
        <div className="flex flex-row items-start justify-start gap-[10px] text-smi text-gray-solid-gray-355-c6c6c6">
          {showOldPrice && (
            <div className="relative [text-decoration:line-through] tracking-[-0.38px] font-medium Mobile_480:text-xs">
              {oldPrice}
            </div>
          )}
          <div className="relative tracking-[-0.38px] font-medium text-yellow-solid-yellow-295-ffc82b Mobile_480:text-xs">
            {price}
          </div>
        </div>
        {showModifierS && (
          <i className="self-stretch relative tracking-[-0.38px] font-medium text-yellow-solid-yellow-600-ff9f00 Mobile_480:text-smi">
            {modifierS}
          </i>
        )}
      </div>
      <div className="relative font-semibold text-right Mobile_480:text-smi">
        {quantity}
      </div>
    </div>
  );
};

export default StartSplittingBillVertical;
