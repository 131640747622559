import { type FunctionComponent, useState, useCallback } from "react";
import PortalPopup from "./PortalPopup";
import TaxAndPaymentDeleteConfi from '@tax-payment/Popups/TaxAndPaymentDeleteConfi.tsx';

export type TaxPaymentTipServiceTagType = {
  tagLabel?: string;
  onClickDelete?: () => void;
};

const TaxPaymentTipServiceTag: FunctionComponent<
  TaxPaymentTipServiceTagType
> = ({ tagLabel = "11%", onClickDelete }) => {
  const [isTaxAndPaymentDeleteConfiOpen, setTaxAndPaymentDeleteConfiOpen] =
    useState(false);

  const openTaxAndPaymentDeleteConfi = useCallback(() => {
    setTaxAndPaymentDeleteConfiOpen(true);
  }, []);

  const closeTaxAndPaymentDeleteConfi = useCallback(() => {
    setTaxAndPaymentDeleteConfiOpen(false);
  }, []);

  return (
    <>
      <div className="rounded-md bg-gray-solid-gray-e8ecef overflow-hidden flex flex-row items-center justify-center py-[9px] pr-[15px] pl-[13px] gap-[0px_7px] text-left text-lg text-black-solid-black-1000-000000 font-mulish">
        <div className="relative">{tagLabel}</div>
        <img
          className="w-5 relative h-5 object-cover cursor-pointer"
          alt=""
          src="/iconred-close-icon1@2x.png"
          onClick={openTaxAndPaymentDeleteConfi}
        />
      </div>
      {isTaxAndPaymentDeleteConfiOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeTaxAndPaymentDeleteConfi}
        >
          <TaxAndPaymentDeleteConfi onClose={closeTaxAndPaymentDeleteConfi} onComplete={onClickDelete} />
        </PortalPopup>
      )}
    </>
  );
};

export default TaxPaymentTipServiceTag;
