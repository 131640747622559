import { Fragment, type FunctionComponent } from "react";
import { LL0 } from "@/react/core/I18nService.tsx";
import { orderPricingLevelFactory } from "@/react/OrderView/OrderPricingLevel.logic.ts";
import { useSignal } from "@/react/core/reactive.ts";
import _ from "lodash";
import { findProductFromDisplayText } from "@/data/ReservationHub.ts";
import type { Modifier, ModifierItem } from "@/data/Modifier.ts";
import { modifiers0 } from "@/data/ModifierHub.ts";
import type { DocDeepSignal } from "@/data/data-utils.ts";
import { LL3 } from "@/react/core/I18nCurrency.tsx";


export type SelectModifiersPopupType = {
  onClose?: () => void;
  onConfirm?: (modifiers: any) => void;
  displayText?: string
};

const SelectModifiersPopup: FunctionComponent<SelectModifiersPopupType> = ({
  onClose,
  onConfirm,
  displayText = ""
}) => {
  const {getPricingModifierItem} = orderPricingLevelFactory();
  const product = findProductFromDisplayText(displayText)
  const modifiers = product?.activePopupModifierGroup!.map(m => modifiers0().find(_m => _m._id === m)) as Array<DocDeepSignal<Modifier>> || [];

  const [result, setResult] = useSignal<{ [k: string]: { gid: string; name: string; price: number; _id: string; }; }>({});

  return (
    <div className="relative bg-white w-full h-full overflow-hidden flex flex-col items-start justify-start pt-8 px-6 pb-6 box-border gap-[24px] min-w-[520px] max-w-full text-left text-base text-blue-solid-blue-500-0051c1 font-mulish">
      <div className="overflow-y-auto no-scrollbar self-stretch flex-1 flex flex-col items-start justify-start gap-[16px] z-[0]">
        {/*<b className="self-stretch relative">{LL0().article.drink()}</b>*/}
        {/*<div className="self-stretch relative text-smi text-black-solid-black-1000-000000">*/}
        {/*  {LL0().order.chooseOneOfTheFollowingOptions()}:*/}
        {/*</div>*/}
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[12px] text-center text-sm text-black-solid-black-880-1d1d26">
          {_.compact(modifiers).map((modifier, index) => (
            <Fragment key={index}>
              <span className={`text-[#0051C1]`}>{modifier?.name}</span>
              <div className={`flex flex-wrap w-full gap-[12px]`}>
                {modifier?.items?.map((item, i) => (
                  <div className="text-black-solid-black-1000-000000 rounded-10xs bg-gray-solid-gray-130-f4f4f4-200 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-[34px] flex flex-row items-center justify-center py-0 px-2.5 relative gap-[2px] min-w-[40px] min-h-[34px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1" key={i}
                       onPointerDown={() => {
                         if (modifier.selectOne) {
                           if (!modifier.mandatory && result()[item._id!]) {
                             delete result()[item._id!];
                             setResult({ ...result() });
                             return;
                           }
                           const _id = _.keys(result()).find(i => result()[i].gid === modifier._id);
                           if (_id) {
                             delete result()[_id];
                             setResult({ ...result() });
                           }
                           setResult({
                             ...result(), ...{
                               [item._id!]: {
                                 _id: item._id!,
                                 name: item.name!,
                                 gid: modifier._id!,
                                 price: getPricingModifierItem(item!),
                               }
                             }
                           });
                         } else {
                           if (!modifier.mandatory && result()[item._id!]) {
                             delete result()[item._id!];
                             setResult({ ...result() });
                             return;
                           }
                           setResult({
                             ...result(), ...{
                               [item._id!]: {
                                 _id: item._id!,
                                 name: item.name!,
                                 gid: modifier._id!,
                                 price: getPricingModifierItem(item!)
                               }
                             }
                           });
                         }
                       }}>
                    {!!result()[item._id!] &&
                      <div className="absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2px)] top-[-1px] right-[-1px] bottom-[-1px] left-[-1px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />
                    }
                    <div className="z-[1] relative">{item.name} - {LL3().format.currency(getPricingModifierItem(item) || 0)}</div>
                  </div>
                ))}
              </div>
            </Fragment>
          ))}
        </div>
      </div>
      <div className="self-stretch flex flex-col items-end justify-start pt-3 px-0 pb-0 z-[1] text-center text-sm text-white">
        <div className="rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8"
             onClick={() => {
               onClose?.();
               const modifiers = _.values(result()) as ModifierItem[];
               setResult({});
               onConfirm?.(modifiers);
             }}>
          <b className="relative">{LL0().ui.save()}</b>
        </div>
      </div>
      <img
        className="w-6 absolute !m-[0] top-[12px] right-[12px] h-6 overflow-hidden shrink-0 object-cover cursor-pointer z-[2]"
        alt=""
        src="/icong-close-popup-icon--grey12@2x.png"
        onClick={onClose}
      />
    </div>
  );
};

export default SelectModifiersPopup;