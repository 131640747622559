import { type FunctionComponent, useCallback, useState } from "react";
import PrinterSelectButton from "./PrinterSelectButton";
import _ from "lodash";
import {createFileUploader} from "@solid-primitives/upload";
import {LL0} from "@/react/core/I18nService.tsx";
import {
  handleChangeLogo,
  initDefaultRedInvoiceContent
} from '@/react/CompanyInformationView/CompanyInformationView.tsx';
import TextFieldArea from "@/react/core/TextFieldArea.tsx";
import PortalPopup from "@edit-room/PortalPopup.tsx";
import DeleteConfirmationPopup from "@edit-room/DeleteConfirmationPopup.tsx";
import clsx from "clsx";
import { logo0 } from "@/data/ImageHub.ts";
import { rnHost } from "@/shared/webview/rnwebview.ts";
import { companyInfo0, posSetting0 } from "@/data/PosSettingsSignal.ts";
import { getUrlParam } from "@/shared/utils2.ts";


const CompanyInformationRightForm: FunctionComponent = () => {
  const {files, selectFiles} = createFileUploader();
  const [isDeleteConfirmationPopupOpen, setDeleteConfirmationPopupOpen] = useState(false);
  const openDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupOpen(true);
  }, []);

  const closeDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupOpen(false);
  }, []);

  async function chooseImage(){
    if (getUrlParam('os')) {
      const logo = await rnHost.chooseImage()
      await handleChangeLogo(undefined, logo)
      return
    }
    selectFiles(async ([file]) => {
      await handleChangeLogo(file)
    });
  }
  return (
    <div
      className="no-scrollbar self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start pt-0 px-6 pb-4 gap-[12px] text-left text-sm text-black-solid-black-880-1d1d26 font-mulish Tablet_600:pl-4 Tablet_600:pr-4 Tablet_600:box-border">
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
        <i className="relative">{LL0().settings.footerText()}:</i>
        {/*<TextField*/}
        {/*  className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"*/}
        {/*  color="primary"*/}
        {/*  fullWidth={true}*/}
        {/*  variant="outlined"*/}
        {/*  multiline*/}
        {/*  onChange={e => _.assign(companyInfo0(), {footerText: e.target.value})}*/}
        {/*  value={companyInfo0()?.footerText}*/}
        {/*/>*/}
        {/*<TextArea*/}
        {/*  className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26 h-auto"*/}
        {/*  variant="outlined"*/}
        {/*  multiline*/}
        {/*  inputProps={{ className: '!h-[116px] !overflow-auto' }}*/}
        {/*  onChange={e => _.assign(companyInfo0(), {footerText: e.target.value})}*/}
        {/*  value={companyInfo0()?.footerText}*/}
        {/*/>*/}
        <TextFieldArea
          className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26 !h-[116px] !w-full"
          value={companyInfo0()?.footerText}
          onChange={e => _.assign(companyInfo0(), {footerText: e.target.value})}
        />
      </div>
      {companyInfo0()?.country === "de" && <>
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px] h-auto">
        <i className="relative">{LL0().settings.redInvoiceContent()}:</i>
        {/*<TextField*/}
        {/*  className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"*/}
        {/*  color="primary"*/}
        {/*  fullWidth={true}*/}
        {/*  variant="outlined"*/}
        {/*  multiline*/}
        {/*  onChange={e => _.assign(companyInfo0(), {redInvoiceContent: e.target.value})}*/}
        {/*  value={companyInfo0()?.redInvoiceContent}*/}
        {/*/>*/}
        <TextFieldArea
          className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26 !h-[116px] !w-full"
          variant="outlined"
          multiline
          inputProps={{ className: '!h-[116px] !overflow-auto' }}
          onChange={e => _.assign(companyInfo0(), {redInvoiceContent: e.target.value})}
          value={companyInfo0()?.redInvoiceContent}
        />
      </div>
      <div className="flex flex-row items-center justify-start gap-[16px]"
           onClick={initDefaultRedInvoiceContent}
      >
        <div className="relative w-[33px] h-[33px]">
          <img
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/triple-chevron-right-icon@2x.png"
          />
        </div>
        <i className="relative [text-decoration:underline] font-semibold">
          {LL0().settings.autoFillRedInvoice()}
        </i>
      </div>
      </>
      }
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
        <div className="self-stretch flex justify-between items-center">
          <i className="relative">{LL0().companyInfo.companyLogo()}:</i>
          <i
            onClick={openDeleteConfirmationPopup}
            className={clsx("relative text-red-500 underline", { 'hidden': !logo0()?.data })}>Delete</i>
        </div>
        <div
          className="self-stretch rounded-10xs bg-gray-solid-gray-130-f4f4f4 shadow-[-0.5px_-0.5px_2px_rgba(0,_0,_0,_0.25)_inset,_0.5px_0.5px_2px_rgba(0,_0,_0,_0.25)_inset] flex flex-row items-center justify-start p-2 gap-[4px] text-gray-solid-gray-420-b3b3b3 border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1"
          onClick={chooseImage}
        >
          <img
            className="w-6 relative h-6 object-cover"
            alt=""
            src="/iconupload-icon@2x.png"
          />
          <div className="relative font-extrabold">{LL0().companyInfo.upload()}</div>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
        <i className="relative">{LL0().settings.logoSize()}:</i>
        <div className="flex flex-row items-center justify-start gap-[8px]">
          {[1, 2, 3, 4, 5, 6].map((size, index) => (
            <PrinterSelectButton buttonNumber={size} isSelected={companyInfo0()?.logoSize === size} key={index}
             onClick={() => {
               _.assign(companyInfo0(), {logoSize: size});
             }}
            />
          ))}

        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
        <i className="relative">{LL0().settings.logoPreview()}:</i>
        <div
          className="self-stretch rounded-sm bg-gray-solid-gray-130-f4f4f4 shadow-[-0.5px_-0.5px_2px_rgba(0,_0,_0,_0.25)_inset,_0.5px_0.5px_2px_rgba(0,_0,_0,_0.25)_inset] box-border h-[228px] overflow-hidden shrink-0 flex flex-row items-center justify-center p-2 border-[0.5px] border-solid border-gray-solid-gray-600-979797">
          {logo0() && logo0()?.data ?
            <img src={logo0()!.data} className={'w-full h-auto'} alt={''}/>
            : <img
              className="relative w-10 h-10 object-cover"
              alt=""
              src="/iconlogo-preview-icon@2x.png"
            />
          }
        </div>
      </div>
      {isDeleteConfirmationPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeDeleteConfirmationPopup}
        >
          <DeleteConfirmationPopup onClose={closeDeleteConfirmationPopup} onComplete={() => _.assign(logo0(), { data: undefined })} />
        </PortalPopup>
      )}
    </div>
  );
};

export default CompanyInformationRightForm;
