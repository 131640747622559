import type { User } from '@/data/User.ts';
import {LL0} from "@/react/core/I18nService.tsx";
import { clockedInMap, onClockOut } from "@/react/PasscodeView/PasscodeView.tsx";

type PasscodeUserItemProps = {
  userData: User;
}

const PasscodeUserItem = ({ userData }: PasscodeUserItemProps) => {
  return (
    <div className="flex flex-col items-center justify-center text-left text-base text-white-solid-white-100-ffffff font-mulish self-stretch">
      <div className="self-stretch flex flex-row items-center justify-between">
        <div className="flex-1 flex flex-row items-center justify-start py-0 pr-1 pl-0 gap-[8px]">
          <img
            className="w-10 relative rounded-981xl h-10 object-cover"
            alt=""
            src={userData?.avatar ?? "/hansel@2x.png"}
          />
          <div className="flex-1 flex flex-row items-center justify-start gap-[8px]">
            <div data-testid={`passcode.users.${userData?.name}`} className="relative mq1440:text-xl">{userData?.name}</div>
            <div className="flex flex-row items-center justify-start">
              <div className="w-[9px] relative rounded-[50%] [background:linear-gradient(175.65deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#3dec00] h-[9px]" />
            </div>
          </div>
        </div>
        {
          clockedInMap()[userData.name || ""] &&
            <div className="rounded-41xl [background:linear-gradient(170.9deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#005fed] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[38px] overflow-hidden flex flex-row items-center justify-center py-0 px-5 box-border text-center text-lg md:w-auto md:[align-self:unset] md:pl-3 md:pr-3 md:box-border sm:w-auto sm:[align-self:unset] mq768:pl-3 mq768:pr-3 mq768:box-border height_414:pl-3 height_414:pr-3 height_414:box-border height_340:pl-3 height_340:pr-3 height_340:box-border"
                 onClick={() => onClockOut(userData.name ?? '')}
            >
                <div className="relative font-semibold md:text-base sm:text-mini mq1440:text-xl mq768:text-mini height_414:text-mini height_340:text-mini">
                  {LL0().monthlyReport.clockOut()}
                </div>
            </div>
        }
      </div>
    </div>
  );
};

export default PasscodeUserItem;