import { type FunctionComponent, useState } from "react";
import DashboardMenuButton from "../DashboardMenuButton";
import { setEditMenuChangeMenuPopupOpen } from "@order-view/OrderScreenBottomBar.tsx";
import { multipleMenus0 } from "@/react/EditMenuView/EditPricingLevel.logic.ts";
import { orderPricingLevelFactory } from "@/react/OrderView/OrderPricingLevel.logic.ts";


export type EditMenuChangeMenuPopupType = {
  className?: string;
  onClose?: () => void;
};

const EditMenuChangeMenuPopup: FunctionComponent<
  EditMenuChangeMenuPopupType
> = ({ className = "", onClose }) => {

  const [priceLevelMenu, setPriceLevelMenu]  = useState('');

  const {handleChangeMenu, handleSetSelected} = orderPricingLevelFactory()

  return (
    <div
      className={`w-full relative [backdrop-filter:blur(100px)] rounded-md [background:linear-gradient(180deg,_#fbfbfb,_#c1c1c1)] flex flex-col items-center justify-start py-9 px-8 box-border gap-[20px] h-full max-w-full max-h-full overflow-auto sm:h-full sm:w-full Tablet_600:pl-6 Tablet_600:pr-6 Tablet_600:box-border Mobile_480:pl-4 Mobile_480:pr-4 Mobile_480:box-border ${className}`}
    >
      <div className="self-stretch overflow-y-auto flex flex-row flex-wrap items-start justify-start py-0.5 px-0.5 gap-[12px] z-[0] Tablet_600:gap-[8px]">
        {multipleMenus0()?.map((menu, index) => (
          <DashboardMenuButton
            showSelected = {index === handleSetSelected()}
            cashRegisterCopy={menu.name}
            key={index}
            onClick={() => {
              // const isProductAdded = order0().commits?.find(c => c.action === CommitAction.ADD_PRODUCT)
              // if (!isProductAdded) {
                handleChangeMenu(menu._id as string);
                handleSetSelected();
                setEditMenuChangeMenuPopupOpen(false);
              // } else {
              //   setIsShowChangePriceLv(true)
              //   setPriceLevelMenu(menu._id as string)
              // }
            }}
          />
        ))}
      </div>
      <img
        className="w-[25px] absolute !m-[0] top-[7px] right-[7px] h-[25.4px] cursor-pointer z-[1]"
        alt=""
        src="/order-view-close-tab.svg"
        onClick={onClose}
      />
      {/*{isShowChangePriceLv() && (*/}
      {/*  <PortalPopup*/}
      {/*    overlayColor="rgba(0, 0, 0, 0.2)"*/}
      {/*    placement="Centered"*/}
      {/*    onOutsideClick={() =>  setIsShowChangePriceLv(false)}*/}
      {/*  >*/}
      {/*    <ChangePriceLevelPopUp*/}
      {/*      onClose={() =>  setIsShowChangePriceLv(false)}*/}
      {/*      message={`If you change the menu, the prices of the added products will be adjusted accordingly. \n Do you want to continue with changing this menu?`}*/}
      {/*      onConfirmed={changePriceLevel}*/}
      {/*    />*/}
      {/*  </PortalPopup>*/}
      {/*)}*/}
    </div>
  );
};

export default EditMenuChangeMenuPopup;
