import {signal} from "@/react/core/reactive";
import { toast } from "react-toastify";
import debug from "debug";
const log = debug('choose-image:resize')

export const toBase64 = (file: Blob) => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result);
	reader.onerror = reject;
});

export const [now, setNow] = signal<Date>(new Date())
setInterval(() => setNow(() => new Date()), 1000)

export const convertBase64ToPngBase64 = (jpegBase64: any) => {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img')
    img.src = jpegBase64;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      const pngBase64 = canvas.toDataURL('image/png');
      resolve(pngBase64);
    };
    img.onerror = (error) => reject(error);
  });
};

export async function imageUrlToBase64(imageUrl: any) {
  const response = await fetch(imageUrl);
  const blob = await response.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}
export async function fileToBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

const minAspectRatio = 1;
const maxAspectRatio = 6 / 4; // 1.5
const maxWidth = 1500;
const minWidth = 550;
const minHeight = 450;
const allowedTypes = ['image/jpeg', 'image/png'];


export const resizeBase64AndConvertToFile = (
  base64Image: string,
): Promise<File | null> => {
  log(`resizeBase64AndConvertToFile ${base64Image?.length}`)
  return new Promise((resolve, reject) => {
    const matches = base64Image.match(/^data:(.+);base64,(.*)$/);
    if (!matches) return null
    const mimeType = matches[1];
    const base64Data = matches[2];
    if (!allowedTypes.includes(mimeType)) {
      log(`Invalid file type ${mimeType}`)

      toast.error('Only JPG and PNG files are allowed');
      reject(new Error('Invalid file type')); // Đẩy lỗi để dừng quá trình
      return;
    }
    // Tạo một đối tượng Image
    const img = document.createElement("img");
    img.src = `data:${mimeType};base64,${base64Data}`;

    img.onload = () => {
      const originalWidth = img.width;
      const originalHeight = img.height;
      const imgRatio = originalWidth / originalHeight;

      // Điều chỉnh tỷ lệ ảnh sao cho nằm trong phạm vi cho phép
      let newWidth = originalWidth;
      let newHeight = originalHeight;

      // Nếu tỷ lệ ảnh không hợp lệ, điều chỉnh tỷ lệ
      if (imgRatio < minAspectRatio || imgRatio > maxAspectRatio) {
        if (imgRatio < minAspectRatio) {
          // Điều chỉnh theo chiều cao và tỷ lệ minAspectRatio
          newHeight = Math.max(minHeight, originalHeight);
          newWidth = newHeight * minAspectRatio;
        } else if (imgRatio > maxAspectRatio) {
          // Điều chỉnh theo chiều cao và tỷ lệ maxAspectRatio
          newHeight = Math.max(minHeight, originalHeight);
          newWidth = newHeight * maxAspectRatio;
        }
      } else {
        // Tỷ lệ hợp lệ, tính toán kích thước mới
        if (originalWidth > maxWidth) {
          newWidth = maxWidth;
          newHeight = (newWidth / originalWidth) * originalHeight;
        } else if (originalWidth < minWidth) {
          newWidth = minWidth;
          newHeight = (newWidth / originalWidth) * originalHeight;
        } else {
          newWidth = originalWidth;
          newHeight = originalHeight;
        }
      }

      // Đảm bảo kích thước không nhỏ hơn giới hạn
      newWidth = Math.min(Math.max(newWidth, minWidth), maxWidth);
      newHeight = Math.max(newHeight, minHeight);

      // Kiểm tra lại tỷ lệ và điều chỉnh kích thước nếu cần
      const finalAspectRatio = newWidth / newHeight;
      if (finalAspectRatio < minAspectRatio) {
        newHeight = newWidth / minAspectRatio;
      } else if (finalAspectRatio > maxAspectRatio) {
        newHeight = newWidth / maxAspectRatio;
      }

      console.log("Resized dimensions:", { newWidth, newHeight });

      // Tạo canvas để resize ảnh
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        reject(new Error("Canvas context not found"));
        return;
      }

      canvas.width = newWidth;
      canvas.height = newHeight;

      // Vẽ ảnh vào canvas với kích thước mới
      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      // Chuyển canvas thành Blob
      const resizedBase64 = canvas.toDataURL(mimeType);
      const matchesResized = resizedBase64.match(/^data:(.+);base64,(.*)$/);
      if (!matchesResized) return null

      const resizedMimeType = matchesResized[1];
      const resizedBase64Data = matchesResized[2];

      const byteCharacters = atob(resizedBase64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: resizedMimeType });
      const fileName = `${crypto.randomUUID()}.${resizedMimeType.split("/")[1]}`;
      const file = new File([blob], fileName, { type: resizedMimeType });

      resolve(file);
    };

    img.onerror = (err) => {
      reject(err); // Nếu có lỗi trong quá trình load ảnh
    };
  });
};


export function resizeImageFile(file: File): Promise<File> {
  log(`resizeImageFile ${file?.type}`)
  return new Promise((resolve, reject) => {
    if (!allowedTypes.includes(file.type)) {
      toast.error('Only JPG and PNG files are allowed');
      reject(new Error('Invalid file type')); // Đẩy lỗi để dừng quá trình
      return;
    }
    const img = document.createElement("img");
    const reader = new FileReader();

    reader.onload = (e) => {
      img.onload = () => {
        // Kích thước gốc và tỷ lệ
        const originalWidth = img.width;
        const originalHeight = img.height;
        const imgRatio = originalWidth / originalHeight;

        // Điều chỉnh tỷ lệ ảnh sao cho nằm trong phạm vi cho phép
        let newWidth = originalWidth;
        let newHeight = originalHeight;

        // Nếu tỷ lệ ảnh không hợp lệ, điều chỉnh tỷ lệ
        if (imgRatio < minAspectRatio || imgRatio > maxAspectRatio) {
          if (imgRatio < minAspectRatio) {
            // Điều chỉnh theo chiều cao và tỷ lệ minAspectRatio
            newHeight = Math.max(minHeight, originalHeight);
            newWidth = newHeight * minAspectRatio;
          } else if (imgRatio > maxAspectRatio) {
            // Điều chỉnh theo chiều cao và tỷ lệ maxAspectRatio
            newHeight = Math.max(minHeight, originalHeight);
            newWidth = newHeight * maxAspectRatio;
          }
        } else {
          // Tỷ lệ hợp lệ, tính toán kích thước mới
          if (originalWidth > maxWidth) {
            newWidth = maxWidth;
            newHeight = (newWidth / originalWidth) * originalHeight;
          } else if (originalWidth < minWidth) {
            newWidth = minWidth;
            newHeight = (newWidth / originalWidth) * originalHeight;
          } else {
            newWidth = originalWidth;
            newHeight = originalHeight;
          }
        }

        // Đảm bảo kích thước không nhỏ hơn giới hạn
        newWidth = Math.min(Math.max(newWidth, minWidth), maxWidth);
        newHeight = Math.max(newHeight, minHeight);

        // Kiểm tra lại tỷ lệ và điều chỉnh kích thước nếu cần
        const finalAspectRatio = newWidth / newHeight;
        if (finalAspectRatio < minAspectRatio) {
          newHeight = newWidth / minAspectRatio;
        } else if (finalAspectRatio > maxAspectRatio) {
          newHeight = newWidth / maxAspectRatio;
        }

        console.log("Resized dimensions:", { newWidth, newHeight });

        // Tạo canvas để resize ảnh
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (!ctx) {
          reject(new Error("Canvas context not found"));
          return;
        }

        canvas.width = newWidth;
        canvas.height = newHeight;

        // Vẽ ảnh vào canvas với kích thước mới
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        // Chuyển canvas thành Blob
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              reject(new Error("Blob creation failed"));
              return;
            }

            // Tạo file mới từ Blob
            const resizedFile = new File([blob], file.name, { type: file.type });
            resolve(resizedFile);
          },
          file.type
        );
      };

      img.onerror = (err) => {
        reject(err); // Xử lý lỗi load ảnh
      };

      // Đọc ảnh và set src cho `img`
      img.src = e.target?.result as string;
    };

    reader.onerror = (err) => {
      reject(err); // Xử lý lỗi đọc file
    };

    // Đọc file dưới dạng base64
    reader.readAsDataURL(file);
  });
}
