import { LL0 } from '@/react/core/I18nService'
import type { FunctionComponent } from 'react'
import { LL3 } from "@/react/core/I18nCurrency.tsx";

type Prop = { paymentDetailCredit?: number }

const StaffReportPaymentDetailCredit: FunctionComponent<Prop> = ({ paymentDetailCredit }) => {
  const LL = LL0()
  return (
    <div className="self-stretch flex flex-row items-center justify-between text-left text-sm text-gray font-mulish">
      <div className="flex-1 flex flex-row items-center justify-start gap-[12px]">
        <div className="relative w-[30px] h-[30px]">
          <img
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/iconcard-icon@2x.png"
          />
        </div>
        <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">{LL0().report.credit()}</div>
      </div>
      <div className="relative text-base text-right Tablet_768:text-smi Tablet_600:text-smi">{paymentDetailCredit ? LL3().format.currency(paymentDetailCredit) : '-'}</div>
    </div>
  )
}

export default StaffReportPaymentDetailCredit
