import dayjs from 'dayjs'
import debug from 'debug'
import _ from 'lodash'

import { getTransactionId } from '@/data/MaxIdHub'
import type { Order } from '@/data/Order'
import { companyInfo0, posSetting0 } from '@/data/PosSettingsSignal.ts'
import { taxCategories0 } from '@/data/TaxCategoryHub'
import { loginUser } from '@/data/UserSignal.ts'
import { createOrder } from '@/pos/logic/order-reactive'
import { getOrderItemOriginalSum, getOrderNet, getOrderOriginalSum, isRefundOrder } from '@/pos/logic/order-utils.ts'
import { OrderStatus, type OrderItem, type OrderPayment, type OrderStrip } from '@/pos/OrderType'
import { LL0 } from '@/react/core/I18nService'
import { assert } from '@/shared/assert'
import { roundNumber } from '@/shared/order/order-config.ts'
import { getOsFromParam, stringifyObj } from '@/shared/utils2.ts'
import { SPECIAL_ITEM_DESCR } from '@/srm/lib/constants'
import { formatCustomerNumber, formatMoney, formatQuantity, formatTableNumber, formatTransactionNumber } from '@/srm/lib/formatters'
import type { TransactionData, TransactionItem, TransactionItemDetail } from '@/srm/lib/types'

import { ActivitySectors, ActivitySubSectors, OperationModes, PaymentMethods, PrintModes, PrintOptions, ServiceTypes, TransactionTypes } from './lib/enum'
import { formatDateForJson, getUtcInfo } from './lib/timezone'
import { ensureValidAsciiStandard, getStreetNumber, getValidZipcode } from './lib/utils'
import { assertTransaction } from './transaction.assert'

const log = debug('data:srm')

interface BaseItem {
  name: string
  quantity: number
  price?: number
  total: number
  modifiers?: Array<{
    name: string
    quantity: number
    price?: number
    total: number
  }>
}
interface TransactionAmounts {
  net: number
  qst: number
  gst: number
  gross: number
  tip: number
}

const emptyItem: TransactionItem = {
  qte: formatQuantity(1),
  descr: 'SOB',
  tax: 'SOB',
  acti: ActivitySubSectors.notAvailable,
  prix: formatMoney(0),
}

export interface TransactionConvertingOptions {
  transactionType?: TransactionTypes
  printMode?: PrintModes
  printOption?: PrintOptions
  /** Cancellation/Refund reason */
  cancelReason?: string
  /**
   * Add a cancellation item to make the bill sum to $0.
   *
   * Used in case of cancellation for already transmitted transaction
   */
  addCancellationItem?: boolean
  /** Change the user that making transaction. If not specified, default to current login user */
  impersonate?: string
  /** Skip generate transaction number. Should be used for temporary transaction */
  skipGenNoTrains?: boolean
}

/** Convert Order to Quebec's SRM Transaction Data */
export async function order2Trans(orderInput: OrderStrip | Order, option: TransactionConvertingOptions = {}): Promise<TransactionData> {
  // Required config
  const { billingNumber, gstNumber, qstNumber, productId, productVersionId, partnerId, certificateCode, version, previousVersion } = posSetting0()?.srm ?? {}

  const genericMsg = ' ' + LL0().srm.messages.checkConfig()
  if (!billingNumber) throw new Error(LL0().srm.errors.missingBillingNumber() + genericMsg)
  if (!gstNumber) throw new Error(LL0().srm.errors.missingGstNumber() + genericMsg)
  if (!qstNumber) throw new Error(LL0().srm.errors.missingQstNumber() + genericMsg)
  if (!productId) throw new Error(LL0().srm.errors.missingProductCode() + genericMsg)
  if (!productVersionId) throw new Error(LL0().srm.errors.missingProductVersionCode() + genericMsg)
  if (!partnerId) throw new Error(LL0().srm.errors.missingPartnerCode() + genericMsg)
  if (!certificateCode) throw new Error(LL0().srm.errors.missingAuthorizationCode() + genericMsg)
  if (!version) throw new Error(LL0().srm.errors.missingProductVersion() + genericMsg)
  if (!previousVersion) throw new Error(LL0().srm.errors.missingPreviousProductVersion() + genericMsg)

  // Following info is optional
  const { name: companyName = '', address: companyAddr = '', zipCode: companyZipcode = '' } = companyInfo0() ?? {}
  const userName = option.impersonate ?? loginUser()?.name ?? ''
  const validZipCode = getValidZipcode(companyZipcode)
  const streetNumber = getStreetNumber(companyAddr)

  const order = sanitizeOrderInput(orderInput, option)

  const amounts = getOrderAmounts(order)
  const validItems = getTransactionItems({
    items: order.items,
    serviceFee: order.serviceFee,
    isRefunding: isRefundOrder(order),
    discount:
      `${order.discount ?? '0'}` !== '0' && order.vDiscount
        ? {
            percent: `${order.discount}`,
            amount: getOrderOriginalSum(order) - (order.vSubTotal ?? 0),
          }
        : undefined,
  })

  log('ℹ️ Order amounts: ' + stringifyObj(amounts))

  // In Android version we do not have `bar` subsector
  const subSector = getOsFromParam() === 'android' || order.table ? ActivitySubSectors.restaurant : ActivitySubSectors.bar
  const paymentMethod =
    // If customer fail to pay, the payment method should be "No payment" (AUC)
    option.printMode === PrintModes.failureToPay
      ? PaymentMethods.noPayment
      : // For temporaryBill, estimate, quote or occasionalThirdParty the value of the modPai field must be none ("SOB")
      [TransactionTypes.temporaryBill, TransactionTypes.estimate, TransactionTypes.quote, TransactionTypes.occasionalThirdParty].includes(option.transactionType as TransactionTypes)
      ? PaymentMethods.notAvailable
      : // if `apresTax` field is "$0.00", the value of the `modPai` field must be noPayment ("AUC")
      option.addCancellationItem
      ? PaymentMethods.noPayment
      : // else we take from order's payments field
        getPaymentMethodFromOrderPaymentType(order.payments ?? [])

  const trans: TransactionData = {
    sectActi: {
      //restaurant
      abrvt: ActivitySectors.restaurant,
      typServ: getServiceType(order),
      noTabl: formatTableNumber(order.table),
      nbClint: formatCustomerNumber(Math.max(1, order.seatMap?.length ?? 0)),
    },
    noTrans: option.skipGenNoTrains ? '-' : formatTransactionNumber((await getTransactionId(dayjs())).transactionId),
    nomMandt: companyName.trim(),
    nomUtil: userName.trim(),
    docAdr: { docNoCiviq: streetNumber, docCp: validZipCode },
    relaCommer: 'B2C',
    datTrans: formatDateForJson(new Date()),
    utc: getUtcInfo() as TransactionData['utc'],
    items: validItems.length ? validItems.map(orderItem2TransItemMapper(subSector, option)) : [emptyItem],
    mont: {
      avantTax: formatMoney(amounts.net), // ?
      TPS: formatMoney(amounts.gst),
      TVQ: formatMoney(amounts.qst),
      apresTax: formatMoney(amounts.gross),

      // 3 fields below is skipped because we not support installments
      // versActu: formatMoney(0.0),
      // versAnt: formatMoney(0.0),
      // sold: formatMoney(0.0),

      // 2 field below is skipped because we do not support feature "Determine the amount due"
      // ajus: amounts.discount !== 0 ? formatMoney(amounts.discount) : undefined,
      // mtdu: amounts.discount !== 0 ? formatMoney(amounts.gross - amounts.discount) : undefined,

      // Only sent tip if there is value
      pourb: amounts.tip ? formatMoney(amounts.tip) : undefined,
    },
    noDossFO: billingNumber,
    noTax: { noTPS: gstNumber, noTVQ: qstNumber },
    commerElectr: 'N',
    typTrans: option.transactionType ?? TransactionTypes.notAvailable,
    modPai: paymentMethod,
    modImpr: option.printMode ?? PrintModes.bill,
    formImpr: option.printOption ?? PrintOptions.notPrinted,
    modTrans: order.trainingMode ? OperationModes.training : OperationModes.operating,
    refs: undefined, // This will be calculated separately
    SEV: {
      idSEV: productId,
      idVersi: productVersionId,
      codCertif: certificateCode,
      idPartn: partnerId,
      versi: version,
      versiParn: previousVersion,
    },
  }

  assertTransaction(trans, order.externalId?.toString() ?? order._id ?? 'unknown')

  log('✅ Converted ' + stringifyObj(trans))
  return trans
}

/**
 * Sanitize order input before converting to SRM transaction
 * The reason we need to sanitize the order input is because we need to handle
 * the case where the order is cancelled, and we need to add a "cancellation item"
 * to the order to make the sum of the bill = 0.
 *
 * @param orderInput - The order input to be sanitized
 * @param option - The options for converting the order to SRM transaction
 * @returns The sanitized order
 */
function sanitizeOrderInput(orderInput: Order | OrderStrip, option: TransactionConvertingOptions) {
  const clonedOrder = _.cloneDeep(orderInput)
  delete clonedOrder.commits

  log('🔐 Generating Transaction for Order', clonedOrder)

  const isCancelling = option.printMode === PrintModes.cancellation || clonedOrder.status === OrderStatus.CANCELLED_BEFORE_PAID

  // FIXME: This is a workaround for shipping data, we need to handle it properly in `order-reactive.ts` later
  const { shippingData } = clonedOrder
  if (shippingData) {
    // Append tip to main order
    if (shippingData.tip) clonedOrder.tip = (clonedOrder.tip ?? 0) + shippingData.tip
    // Append shipping fee & shipping service fee to main order's service fee
    if (shippingData.fee) clonedOrder.serviceFee = (clonedOrder.serviceFee ?? 0) + shippingData.fee
    if (shippingData.serviceFee) clonedOrder.serviceFee = (clonedOrder.serviceFee ?? 0) + shippingData.serviceFee
    delete clonedOrder.shippingData
  }

  const order = createOrder({
    ...clonedOrder,
    ...(isCancelling
      ? {
          // If order is cancelled, we must clear all the discount
          // This is because we don't want to send the discount to SRM
          // when the order is cancelled
          discount: '0',
          discountLabel: '',
          items: [
            ...(clonedOrder.items ?? []),
            ...(option.addCancellationItem
              ? [
                  // Must included origin cancelled items, also we need to add
                  // a "cancellation item", that cancel out all items and make the sum of the bill = 0
                  // As requirement in 4.4.1.5.2, document SW-73-V
                  ...(clonedOrder.cancellationItems ?? []),
                  ...(clonedOrder.directCancellationItems ?? []),
                  genCancellationItem(getOrderOriginalSum(clonedOrder, true)),
                ]
              : []),
          ],
        }
      : {}),
  })
  return order
}

function orderItem2TransItemMapper(subSector: ActivitySubSectors, option: TransactionConvertingOptions) {
  return (item: BaseItem): TransactionItem => {
    // This is a cancellation item. We must also add reason for cancel, in the `preci` field
    if (item.name === SPECIAL_ITEM_DESCR.cancellation)
      return {
        qte: formatQuantity(1),
        descr: item.name,
        tax: 'FP',
        acti: item.total === 0 ? ActivitySubSectors.notAvailable : subSector,
        prix: formatMoney(item.total),
        preci: [
          {
            descr: ensureValidAsciiStandard(option.cancelReason ?? 'commande annulée'),
            acti: ActivitySubSectors.notAvailable,
          },
        ],
      }

    // Special item: Service fees / Package
    if (item.name === SPECIAL_ITEM_DESCR.serviceFees || item.name === SPECIAL_ITEM_DESCR.package)
      return {
        qte: formatQuantity(1),
        descr: item.name,
        tax: 'FP',
        acti: item.total === 0 ? ActivitySubSectors.notAvailable : subSector,
        prix: formatMoney(item.total),
      }

    return {
      descr: ensureValidAsciiStandard(item.name ?? ''),
      qte: formatQuantity(item.quantity),
      unitr: typeof item.price !== 'undefined' ? formatMoney(item.price) : undefined,
      prix: formatMoney(item.total),
      tax: 'FP',
      acti: item.total === 0 ? ActivitySubSectors.notAvailable : subSector,
      preci: item.modifiers?.length
        ? item.modifiers.map(
            (m): TransactionItemDetail => ({
              descr: ensureValidAsciiStandard(m.name),
              qte: formatQuantity(m.quantity),
              unitr: typeof m.price !== 'undefined' ? formatMoney(m.price) : undefined,
              prix: formatMoney(m.total),
              tax: 'FP',
              acti: m.total === 0 ? ActivitySubSectors.notAvailable : subSector,
            })
          )
        : undefined,
    }
  }
}

type WithRequired<T, K extends keyof T> = T & Required<Pick<T, K>>

interface GetItemsOpts {
  items: OrderItem[]
  serviceFee: number | undefined
  discount?: { percent: string; amount: number } | undefined
  isRefunding?: boolean
}

function getTransactionItems({ items, serviceFee, isRefunding, discount }: GetItemsOpts): BaseItem[] {
  if (!items) return []
  return [
    ...items
      .filter((i): i is WithRequired<OrderItem, 'name' | 'quantity'> => !!i.name && i.quantity !== 0)
      .map(
        (i): BaseItem => ({
          name: i.name,
          quantity: i.quantity,
          price: i.price,
          total: i.price * i.quantity,
          modifiers: [
            ...i.modifiers.map(m => ({
              name: m.name,
              price: m.price,
              quantity: i.quantity * m.quantity,
              total: i.quantity * m.price * m.quantity,
            })),
            // 1. When the order is refunding, we must clear the discount
            // 2. If the order already has discount, we must use it instead of the item's discount
            ...(!isRefunding && !discount && +(i.discount ?? '0') !== 0
              ? [
                  {
                    name: SPECIAL_ITEM_DESCR.discount + getDiscountInfo(i.discount),
                    quantity: 1,
                    total: (i.vSubTotal ?? 0) - getOrderItemOriginalSum(i),
                  },
                ]
              : []),
          ],
        })
      ),
    ...(serviceFee ? [{ name: SPECIAL_ITEM_DESCR.serviceFees, quantity: 1, total: serviceFee }] : []),
    ...(!isRefunding && discount
      ? [
          {
            name: SPECIAL_ITEM_DESCR.discount + getDiscountInfo(discount.percent),
            quantity: 1,
            total: -+discount.amount,
          },
        ]
      : []),
  ]
}

function getDiscountInfo(discount: OrderItem['discount']) {
  if (typeof discount !== 'string') return ''
  if (discount.indexOf('%') === -1) return ''

  return `@${discount}`
}

function getOrderAmounts(o: Order | OrderStrip): TransactionAmounts {
  const net = roundNumber(o.vSubTotal ?? getOrderNet(o))
  if (net === 0) return { net, qst: 0, gst: 0, gross: 0, tip: 0 }
  const gst = roundNumber(o.vTaxComponents?.TPS || 0)
  const qst = roundNumber(o.vTaxComponents?.TVQ || 0)
  const gross = roundNumber(o.vSum ?? 0)
  const tip = roundNumber((o.tip ?? 0) + (o.shippingData?.tip ?? 0))
  const result: TransactionAmounts = { net, qst, gst, gross, tip }

  verifyAmounts(result)

  return result
}

function genCancellationItem(total: number): OrderItem {
  return {
    name: SPECIAL_ITEM_DESCR.cancellation,
    quantity: 1,
    price: -total,
    modifiers: [],
  }
}
function getServiceType(order: Order): ServiceTypes {
  if (order.externalId !== undefined || order.externalStoreId !== undefined || order.provider !== undefined) return ServiceTypes.digitalPlatform
  if (order.table) return ServiceTypes.tableService
  return ServiceTypes.counterService
}

function getPaymentMethodFromOrderPaymentType(orderPayments: OrderPayment[]): PaymentMethods {
  if (orderPayments.length > 1) return PaymentMethods.mixed
  const [orderPayment] = orderPayments
  if (!orderPayment) return PaymentMethods.noPayment

  // HACK: since we only support cash and debit card, consider all other payment methods as debit card
  // TODO: support more payment methods
  if (orderPayment.type.match(/cash/i)) return PaymentMethods.cash
  return PaymentMethods.debitCard
}

function verifyAmounts(amounts: TransactionAmounts): void {
  const { net, gst, qst, gross } = amounts
  if (net === 0) assert(gst === 0 && qst === 0 && gross === 0, 'Something wrong when calculating order amount: NET = 0 but GST, QST or GROSS are not 0')

  if (roundNumber(net + gst + qst) !== roundNumber(gross)) {
    // Trying to fix the tax errors, by recalculating the tax & gross
    const defaultTax = taxCategories0().find(tax => tax.type === 'combo' && tax.name === 'Quebec')
    const gstRate = defaultTax?.components?.find(c => c.printLabel === 'TPS')?.value
    const qstRate = defaultTax?.components?.find(c => c.printLabel === 'TVQ')?.value
    const newAmounts = {
      gst: roundNumber(net * (gstRate ?? 0.05)),
      qst: roundNumber(net * (qstRate ?? 0.09975)),
      get gross() {
        return roundNumber(this.gst + this.qst)
      },
    }
    if (roundNumber(Math.abs(gross - newAmounts.gross)) <= 0.01) {
      log('⚡️ Gross value differ by 0.01 or less, take the new gross: ' + stringifyObj({ gross, newAmounts }))
      amounts.gst = newAmounts.gst
      amounts.qst = newAmounts.qst
      amounts.gross = newAmounts.gross
    }
    // If the fix is not working, log it with alert
    if (roundNumber(amounts.net + amounts.gst + amounts.qst) !== amounts.gross) {
      log('⚠️ Failed to calculate amounts ' + stringifyObj(amounts), { alert: true })
    }
  }

  log('💰 Verified Amounts for order ' + stringifyObj(amounts))
}

// Object.assign(window, { order2Trans }) // For debugging purpose
