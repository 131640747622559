import { convertToBase64Png, createPrinter } from "@/react/Printer/print-kitchen-utils.ts";
import type { Raster } from "@/shared/printer/pure-image-printer-parallel";
import { printIntegrate } from "@/shared/printer/intergrateManager.ts";

import { Buffer } from "buffer";

export const testIntegratePrint = async () => {
    const printer = createPrinter();
    await printer.println("TEST");
    const raster: Raster = (await printer.print())!;
    const base64 = await convertToBase64Png(raster, false);
    printIntegrate({data:Buffer.from(base64, 'base64')});
  }