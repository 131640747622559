import {type FunctionComponent, useCallback} from "react";
import {useSignal} from "@/react/core/reactive.ts";
import {
  currentItem, itemContext,
  setInputControllers,
  setShowDiscountPopupItem
} from "@/react/OrderView/OrderView.tsx";
import {CommitAction} from "@/pos/OrderType.ts";
import uuid from "time-uuid";
import {LL0} from "@/react/core/I18nService.tsx";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import Input, {inputController0} from "@/react/core/Input.tsx";
import {order0} from "@/react/OrderView/OrderViewShare.ts";
import { recordUserActionHistory, UserAction } from "@/data/UserActionHistory.ts";
import { PosScreen, router } from "@/pos/PosRouter.ts";
import {order0 as _order0} from "@/react/PaymentView/PaymentView.tsx";

type ModifiersFormType = {
  showModifiersForm?: boolean;
};

const ModifiersForm: FunctionComponent<ModifiersFormType> = (
  {
    showModifiersForm,
  }) => {

  const [modifier, setModifier] = useSignal<{ name: string, price: string }>({name: "", price: '0'})

  const handleAddModifierOrderItem = useCallback(() => {
    const order = [PosScreen.ORDER, PosScreen.NEW_DELIVERY].includes(router.screen) ? order0() : _order0();
    const price = Number(modifier().price)
    if (modifier().name && modifier().price && !isNaN(price)) {
      order.commits?.push({
        action: CommitAction.ADD_MODIFIER,
        quantity: 1,
        ref: currentItem()?._id,
        _id: uuid(),
        productRef: {name: modifier().name, price: price},
        commitId: currentItem()?.commitRefs?.[0] ?? '',
        ...itemContext.addSeat()
      });
    }

    const data = {
      ...itemContext.addSeat(),
      productRef: {
        modifierName: modifier()?.name,
        modifierPrice: price,
        productId: currentItem()?.commitRefs?.[0],
        name: currentItem()?.name
      },
      quantity: 1,
    }

    setModifier({name: "", price: "0"})
    setShowDiscountPopupItem(false)

    const targetOrder = order?.seatMode ? order?.seatMap?.[data?.seat!] : order;
    recordUserActionHistory(targetOrder, UserAction.ADD_MODIFIER, data).then();
  }, []);

  return (
    showModifiersForm && (
      <div className="edit-modifier-popup !flex self-stretch flex-1 flex-col items-end justify-between pt-4 pb-8 pr-6 pl-1 z-[1] text-left text-smi text-black-solid-black-1000-000000 font-mulish">
        <div className="self-stretch flex flex-col items-start justify-start pt-0 pb-6 pr-0 pl-2 gap-[16px]">
          <div className="self-stretch flex flex-row items-center justify-start gap-[16px] w-full">
            <div className="relative w-[64px]">{LL0().restaurant.modifier()}:</div>
            <div className="flex-1 flex flex-row items-center justify-start">
              <Input
                className={'w-full'} value={modifier().name}
                refInputController={i => {
                  i.setFocus(true)
                  return setInputControllers?.([i]);
                }}
                onChange={value => setModifier(prev => ({...prev, name: value}))}
              />
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-start gap-[16px] w-full">
            <div className="relative w-[64px]">{LL0().fnBtn.price()}:</div>
            <div className="flex-1 flex flex-row items-center justify-start">
              <Input
                className={'w-full'}
                refInputController={i => {
                  return setInputControllers?.([i]);
                }}
                value={modifier().price}
                onChange={value => setModifier(prev => ({...prev, price: value}))}
              />
            </div>
          </div>
        </div>
        <TableManualKeyboard
          value={''}
          inputController={inputController0}
          onEnter={handleAddModifierOrderItem}
        />
      </div>
    )
  );
};

export default ModifiersForm;