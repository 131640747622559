import type { RxCollection, RxDatabase, RxDocument } from "rxdb";
import { createCollection } from "@/data/utils/migration.ts";

export const TSE_CERTIFICATE_COLLECTION_NAME = 'tse_certificates'

export const VERSION = 2
const tseCertificateSchema = {
  title: 'tseCertificate schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: {type: 'string', maxLength: 24},
    certificates: {
      type: 'array',
      items: {
        type: 'string',
      }
    }
  },
  indexes: []
} as const;

export interface TseCertificate {
  _id: string;
  certificates: string[];
}

export type TseCertificateDocument = RxDocument<TseCertificate>;
export type TseCertificateCollection = RxCollection<TseCertificate>;

export const TseCertificateCollection: TseCertificateCollection = {} as TseCertificateCollection;
export const TseCertificate = TseCertificateCollection;

export const createTseCertificateCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: TseCertificate,
    collectionName: TSE_CERTIFICATE_COLLECTION_NAME,
    version: VERSION,
    schema: tseCertificateSchema,
  });
}
