import dialogService from "@/react/SystemService/dialogService.tsx";
import { emptyDatabase, importDemoOrders, initNewDatabase } from "@/data/ImportData.ts";
import { clearAllOrder } from "@/data/OrderHub.ts";
import { generalSetting0, posSetting0 } from "@/data/PosSettingsSignal.ts";
import type { PrinterCache } from "@/data/PosSetting.ts";
import GenerateNewOrdersButton from "@/react/Developer/GenerateNewOrdersButton.tsx";
import GenerateNewCustomersButton from "@/react/Developer/GenerateNewCustomersButton.tsx";
import { rnHost } from "@/shared/webview/rnwebview.ts";
import { toast } from "react-toastify";
import { setOpenUpdateNowPopup } from "@/react/Developer/UpdateNowPopup.logic.ts";
import { onForceUpdate } from "@/react/Developer/developer.logic.ts";
import { setOpenDeleteAllPopup } from "@/react/Developer/DeleteAllPopup.logic.ts";
import type React from "react";
import { useState } from "react";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import _ from "lodash";
import { LL0 } from "@/react/core/I18nService.tsx";
import TextField from "@/react/core/TextField.tsx";
import { useSignal } from "@/react/core/reactive.ts";
import ClientCaller from "@/lib/master-caller/client-caller.ts";
import { getMasterHandler } from "@/lib/master-caller/master-handler.ts";

const DeveloperDataTab = () => {

  const [showErrorBeginHour, setShowErrorBeginHour] = useState<boolean>(false);
  const [time, setTime] = useSignal<string>(generalSetting0()?.beginHour);

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTime(event.target.value);
    setShowErrorBeginHour(false);
  };

  const handleSave = () => {
    const regex = /^(?:[01]?[0-9]|2[0-3]):([0-5][0-9])$/;

    if (regex.test(time())) {
      _.assign(generalSetting0(), { beginHour: time() });
      toast.success("Saved");
      setShowErrorBeginHour(false);
    } else {
      setShowErrorBeginHour(true);
      setTime(generalSetting0()?.beginHour || '06:00');
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div
        className='w-auto text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[320px] font-bold'
        ref={makeRipple}
        onClick={async () => {
          const emptyDemoDataDialog = ({ onClose }: { onClose: () => void }) => (
            <dialogService.BaseDialog
              title={''}
              onClose={onClose}
            >
              <div
                className="w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center pt-2 px-6 pb-6 box-border gap-[32px_0px] min-w-[360px] h-full max-w-full max-h-full text-center text-[20px] text-firebrick font-mulish">
                <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                  <b className="self-stretch relative leading-[28px]">Do you really want to empty demo data?</b>
                </div>
                <div
                  className="w-full flex flex-row items-center justify-center gap-[12px] max-w-[312px] text-sm text-materialize-blue-accent-3">
                  <button
                    className="flex-1 rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center py-[9px] px-0 cursor-pointer border-[1px] border-solid border-materialize-blue-accent-3"
                    onClick={onClose}
                    type="button"
                  >
                    <b className="relative">Cancel</b>
                  </button>
                  <button
                    className="flex-1 rounded-md bg-materialize-blue-accent-3 overflow-hidden flex flex-row items-center justify-center py-[9px] px-0 text-white-solid-white-100-ffffff"
                    onClick={async () => {
                      await emptyDatabase()
                      location.reload()
                    }}
                    type="button"
                  >
                    <b className="relative uppercase">OK</b>
                  </button>
                </div>
              </div>
            </dialogService.BaseDialog>
          )
          await dialogService.show({ component: emptyDemoDataDialog })
        }}
      >
        Empty Demo Data
      </div>
      <div
        className='w-auto text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[320px] font-bold'
        ref={makeRipple}
        onClick={async () => {
          const clearAllOrderDialog = ({ onClose }: { onClose: () => void }) => (
            <dialogService.BaseDialog
              title={''}
              onClose={onClose}
            >
              <div
                className="w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center pt-2 px-6 pb-6 box-border gap-[32px_0px] min-w-[360px] h-full max-w-full max-h-full text-center text-[20px] text-firebrick font-mulish">
                <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                  <b className="self-stretch relative leading-[28px]">Do you really want to clear all orders?</b>
                </div>
                <div
                  className="w-full flex flex-row items-center justify-center gap-[12px] max-w-[312px] text-sm text-materialize-blue-accent-3">
                  <button
                    className="flex-1 rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center py-[9px] px-0 cursor-pointer border-[1px] border-solid border-materialize-blue-accent-3"
                    onClick={onClose}
                    type="button"
                  >
                    <b className="relative">Cancel</b>
                  </button>
                  <button
                    className="flex-1 rounded-md bg-materialize-blue-accent-3 overflow-hidden flex flex-row items-center justify-center py-[9px] px-0 text-white-solid-white-100-ffffff"
                    onClick={async () => {
                      await clearAllOrder(true)
                    }}
                    type="button"
                  >
                    <b className="relative uppercase">OK</b>
                  </button>
                </div>
              </div>
            </dialogService.BaseDialog>
          )
          await dialogService.show({ component: clearAllOrderDialog })
        }}
      >
        Clear All Order
      </div>
      {import.meta.env.MODE === 'development' && (
        <>
          <div
            className='w-auto text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[320px] font-bold'
            ref={makeRipple}
            onClick={async () => {
              await initNewDatabase()
              location.reload()
            }}
          >
            Init New Data
          </div>
          <div
            className='w-auto text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[320px] font-bold'
            ref={makeRipple}
            onClick={async () => {
              await importDemoOrders()
            }}
          >
            Create Large Data
          </div>

          <GenerateNewOrdersButton />
          <GenerateNewCustomersButton />
        </>
      )}
      <div
        className='w-auto text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[320px] font-bold'
        ref={makeRipple}
        onClick={() => {
          posSetting0()!.printerCache = {} as PrinterCache
        }}
      >
        Clear print cache
      </div>
      <div
        className='w-auto text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[320px] font-bold'
        ref={makeRipple}
        onClick={async () => {
          const result = await rnHost.add(1, 2)
          toast('add: ' + result, { type: 'info' })
        }}
      >
        RnHost test add
      </div>
      <div
        className="w-auto text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[320px] font-bold"
        ref={makeRipple}
        onClick={async () => {
          const result = await ClientCaller.callMasterCommand('add', [1, 2])
          toast('add: ' + result, { type: 'info' })
        }}
      >
        Client caller test add
      </div>
      <div
        className="w-auto text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[320px] font-bold"
        ref={makeRipple}
        onClick={async () => {
          const result = await ClientCaller.callMasterCommand('add', [1, 2], { onlyOnline: true })
          toast('add: ' + result, { type: 'info' })
        }}
      >
        Client caller test add online
      </div>
      <div
        className="w-auto text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[320px] font-bold"
        ref={makeRipple}
        onClick={async () => {
          const masterHandler = getMasterHandler()
          masterHandler.registerCommand('add', (a: number, b: number) => (a + b))
          toast('Registered test function')
        }}
      >
        Master register add
      </div>
      <div
        className="w-auto text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[320px] font-bold"
        ref={makeRipple}
        onClick={() => setOpenUpdateNowPopup(true)}
      >
        Update now
      </div>
      <div
        className='w-auto text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[320px] font-bold'
        ref={makeRipple}
        onClick={onForceUpdate}
      >
        Force update
      </div>
      <div
        className='w-auto text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[320px] font-bold'
        ref={makeRipple}
        onClick={() => setOpenDeleteAllPopup(true)}
      >
        Delete All
      </div>
      <fieldset className='border boder-[#c3c3c3] p-4 max-w-[500px] flex flex-col gap-2'>
        <legend>Begin Hour:</legend>
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <i className="relative">
            <span>{LL0().companyInfo.aNewDayBeginsAt()}</span>
            <span className="text-red">({LL0().companyInfo.required()})</span>
            <span>:</span>
          </i>
          <div className="self-stretch">
          </div>
          <TextField
            className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"
            value={time()}
            onChange={handleTimeChange}
          />

        </div>
        {showErrorBeginHour && <p className='text-red'>{LL0().settings.invalidTimeFormat()}</p>}
        <div className='w-[150px] text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[300px]'
             ref={makeRipple}
             onClick={handleSave}
        >
          {LL0().ui.save()}
        </div>
      </fieldset>
    </div>
  )
}

export default DeveloperDataTab