import type {FunctionComponent} from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {LL0} from "@/react/core/I18nService.tsx";
import dayjs from "dayjs";
import {
  handleFilterOrderHistory,
  setDatePickerFilter
} from "@/react/OrderHistoryView/OrderHistoryView.logic.tsx";
import {signal, useSignal} from "@/react/core/reactive.ts";
import clsx from "clsx";
import {makeRipple} from "@/react/core/ripple-utils.ts";
import { LL2 } from "@/react/core/I18nBackend.tsx";

type DateChoosingPopupType = {
  onClose?: () => void;
};
export enum FilterDate {
  TODAY,
  YESTERDAY,
  THIS_WEEK,
  THIS_MONTH,
  CUSTOM
}
export const [chooseDateFilter, setChooseDateFilter] = signal<FilterDate>()

const DateChoosingPopup: FunctionComponent<DateChoosingPopupType> = ({
  onClose,
}) => {


  const startOfDay = dayjs().startOf("day").valueOf()
  const endOfDay = dayjs().endOf("day").valueOf()
  const yesterdayStart = dayjs().subtract(1, "day").startOf("day").valueOf()
  const yesterdayEnd = dayjs().subtract(1, "day").endOf("day").valueOf()
  const weekStart = dayjs().startOf("week").valueOf()
  const weekEnd = dayjs().endOf("week").valueOf()
  const monthStart = dayjs().startOf("month").valueOf()
  const monthEnd = dayjs().endOf("month").valueOf()
  const [date, setDate] = useSignal<{from: number, to: number, choose?: FilterDate}>({from: startOfDay, to: endOfDay})

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="relative bg-basic-color-white min-w-[550px] w-fit overflow-hidden flex flex-col items-start justify-start p-6 box-border gap-[24px] h-full text-left text-base text-royalblue-300 font-mulish Mobile_480:h-full">
        <div
          className="absolute my-0 mx-[!important] top-[12px] right-[12px] w-6 h-6 overflow-hidden shrink-0 cursor-pointer z-[0]"
          onClick={onClose}
        >
          <img
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/order-history-close-popup-icon--grey@2x.png"
          />
        </div>
        <div className="self-stretch overflow-hidden flex flex-col items-start justify-start gap-[24px] z-[1]">
          <div className={clsx("self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[16px] border-solid border-gray-solid-gray-350-ced1d7",
            date()?.choose === FilterDate.CUSTOM && "border-b-[1px]"
            )}>
            <b className="relative">{LL0().orderHistory.quickSelection()}:</b>
            <div className="self-stretch gap-[12px] grid grid-cols-[repeat(5,minmax(98px,auto))] md:grid-cols-[repeat(3,minmax(98px,auto))] [grid-row-gap:12px] [grid-column-gap:12px] text-center text-sm text-blue-solid-blue-420-2979ff">
              <div className={clsx("rounded-md bg-white-solid-white-100-ffffff box-border h-[37px] flex flex-row items-center justify-center py-[9px] px-4 relative gap-[3px] border-[1px] border-solid border-blue-solid-blue-420-2979ff",
                    date()?.choose === FilterDate.TODAY && "text-white-solid-white-100-ffffff"
                )}
                   onClick={() => {
                     setDate({from: startOfDay, to: endOfDay, choose: FilterDate.TODAY})
                   }}
              >
                {date()?.choose === FilterDate.TODAY && <div
                  className="!flex absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2.4px)] top-[-1px] right-[-1.4px] bottom-[-1px] left-[-1px] rounded-md bg-blue-solid-blue-420-2979ff box-border z-[0] border-[1px] border-solid border-blue-solid-blue-420-2979ff"/>}
                <b className="relative z-[1]">{LL0().orderHistory.dateFilterDialog.today().toUpperCase()}</b>
              </div>
              <div className={clsx("rounded-md bg-white-solid-white-100-ffffff box-border h-[37px] flex flex-row items-center justify-center py-[9px] px-4 relative gap-[3px] border-[1px] border-solid border-blue-solid-blue-420-2979ff",
                   date()?.choose === FilterDate.YESTERDAY && "text-white-solid-white-100-ffffff"
                )}
                   onClick={() => {
                     setDate({from: yesterdayStart, to: yesterdayEnd, choose: FilterDate.YESTERDAY})
                   }}
              >
                {date()?.choose === FilterDate.YESTERDAY && <div
                  className="!flex absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2.4px)] top-[-1px] right-[-1.4px] bottom-[-1px] left-[-1px] rounded-md bg-blue-solid-blue-420-2979ff box-border hidden z-[0] border-[1px] border-solid border-blue-solid-blue-420-2979ff"/>}
                <b className="relative z-[1]">{LL0().orderHistory.dateFilterDialog.yesterday().toUpperCase()}</b>
              </div>
              <div className={clsx("rounded-md bg-white-solid-white-100-ffffff box-border h-[37px] flex flex-row items-center justify-center py-[9px] px-4 relative gap-[3px] border-[1px] border-solid border-blue-solid-blue-420-2979ff",
                    date()?.choose === FilterDate.THIS_WEEK && "text-white-solid-white-100-ffffff"
                )}
                   onClick={() => {
                     setDate({from: weekStart, to: weekEnd, choose: FilterDate.THIS_WEEK})
                   }}
              >
                {date()?.choose === FilterDate.THIS_WEEK && <div
                  className="!flex absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2.4px)] top-[-1px] right-[-1.4px] bottom-[-1px] left-[-1px] rounded-md bg-blue-solid-blue-420-2979ff box-border hidden z-[0] border-[1px] border-solid border-blue-solid-blue-420-2979ff"/>}
                <b className="relative z-[1]">{LL0().orderHistory.dateFilterDialog.thisWeek().toUpperCase()}</b>
              </div>
              <div className={clsx("rounded-md bg-white-solid-white-100-ffffff box-border h-[37px] flex flex-row items-center justify-center py-[9px] px-4 relative gap-[3px] border-[1px] border-solid border-blue-solid-blue-420-2979ff",
                  date()?.choose === FilterDate.THIS_MONTH && "text-white-solid-white-100-ffffff"
                )}
                   onClick={() => {
                     setDate({from: monthStart, to: monthEnd, choose: FilterDate.THIS_MONTH})
                   }}
              >
                {date()?.choose === FilterDate.THIS_MONTH && <div
                  className="!flex absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2.4px)] top-[-1px] right-[-1.4px] bottom-[-1px] left-[-1px] rounded-md bg-blue-solid-blue-420-2979ff box-border hidden z-[0] border-[1px] border-solid border-blue-solid-blue-420-2979ff"/>}
                <b className="relative z-[1]">{LL0().orderHistory.dateFilterDialog.thisMonth().toUpperCase()}</b>
              </div>
              <div className={clsx("rounded-md bg-white-solid-white-100-ffffff box-border h-[37px] flex flex-row items-center justify-center py-[9px] px-4 relative gap-[3px] border-[1px] border-solid border-blue-solid-blue-420-2979ff",
                    date()?.choose === FilterDate.CUSTOM && "text-white-solid-white-100-ffffff"
                )}
                  onClick={() => {
                    setDate(prev => ({...prev, choose: FilterDate.CUSTOM}))
                  }}
              >
                {date()?.choose === FilterDate.CUSTOM && <div
                  className="!flex absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2.4px)] top-[-1px] right-[-1.4px] bottom-[-1px] left-[-1px] rounded-md bg-blue-solid-blue-420-2979ff box-border hidden z-[0] border-[1px] border-solid border-blue-solid-blue-420-2979ff"/>}
                <b className="relative z-[1]">{LL0().orderHistory.dateFilterDialog.custom().toUpperCase()}</b>
              </div>
            </div>
          </div>

          {date()?.choose === FilterDate.CUSTOM && <div
            className="self-stretch flex flex-col items-start justify-start gap-[16px] text-gray-solid-gray-800-777777">
            <b className="relative text-blue-solid-blue-500-0051c1">
              Custome Your Date:
            </b>
            <div
              className="self-stretch flex flex-row items-center justify-start gap-[32px] Mobile_480:flex-col Mobile_480:gap-[8px] Mobile_480:items-start Mobile_480:justify-start">
              <div className="relative w-24">{LL0().settings.licenseSetting.startDate()}:</div>
              <div
                className="flex-1 shrink-0 flex flex-row items-start justify-start Mobile_480:flex-[unset] Mobile_480:self-stretch">
                <div className="flex-1">
                  <DatePicker
                    format={LL2().dates.dateFormat()}
                    label={LL0().settings.licenseSetting.startDate()}
                    value={dayjs(date()?.from)}
                    onChange={(newValue: any) => {
                      const startDay = dayjs(newValue).valueOf()
                      setDate(prev => ({...prev, from: startDay, choose: FilterDate.CUSTOM}))
                    }}
                    slotProps={{
                      textField: {
                        variant: "outlined",
                        size: "small",
                        color: "primary",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="self-stretch flex flex-row items-center justify-start gap-[32px] Mobile_480:flex-col Mobile_480:gap-[8px] Mobile_480:items-start Mobile_480:justify-start">
              <div className="relative w-24">{LL0().settings.licenseSetting.endDate()}:</div>
              <div
                className="flex-1 shrink-0 flex flex-row items-start justify-start Mobile_480:flex-[unset] Mobile_480:self-stretch">
                <div className="flex-1">
                  <DatePicker
                    format={LL2().dates.dateFormat()}
                    label={LL0().settings.licenseSetting.endDate()}
                    value={dayjs(date()?.to)}
                    onChange={(newValue: any) => {
                      const endDay = dayjs(newValue).valueOf()
                      if (endDay < date()?.from) return;
                      setDate(prev => ({...prev, to: endDay, choose: FilterDate.CUSTOM}))
                    }}
                    slotProps={{
                      textField: {
                        variant: "outlined",
                        size: "small",
                        color: "primary",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>}
        </div>
        <div className="self-stretch flex flex-row items-center justify-end gap-[12px] z-[2] text-center text-sm text-blue-solid-blue-420-2979ff">
          <div
            className="rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
            onClick={onClose}
            ref={makeRipple}
          >
            <b className="relative">{LL0().orderHistory.cancel()}</b>
          </div>
          <div className="rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8 text-white-solid-white-100-ffffff"
               onClick={() => {
                 setDatePickerFilter(date())
                 handleFilterOrderHistory()
                 onClose?.()
               }}
               ref={makeRipple}
          >
            <b className="relative">{LL0().ui.ok().toUpperCase()}</b>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default DateChoosingPopup;
