import {type FunctionComponent, memo} from "react";
import OnlineOrderSettingHeader from "./OnlineOrderSettingHeader";
import OnlineOrderSettingContainer from "./OnlineOrderSettingContainer";

const OnlineOrderSetting: FunctionComponent = () => {
  return (
    <div className="online-order-setting-screen bg-white-solid-white-100-ffffff w-full h-full overflow-hidden flex flex-col items-start justify-start gap-[16px]">
      <OnlineOrderSettingHeader />
      <OnlineOrderSettingContainer />
    </div>
  );
};

export default memo(OnlineOrderSetting);
