import type { FunctionComponent } from 'react';
import UserTab from './UserTab';
import { selectedUserId, staffReportUsers } from '@/react/StaffReportView/StaffReportView';

const UserListContainer: FunctionComponent = () => {
  return (
    <div className="no-scrollbar self-stretch bg-white-solid-white-230-f3f4f4 shadow-[-1px_0px_6px_rgba(0,_0,_0,_0.25)] w-[239px] overflow-y-auto shrink-0 flex flex-col items-stretch justify-start pt-0 px-0 pb-[50px] box-border Tablet_768:w-[180px] Tablet_600:w-[180px] Mobile_480:w-40">
      {staffReportUsers().map(u => (
        <UserTab
          key={u._id}
          id={u._id}
          imageId={u.avatar}
          userName={u.name}
          active={u._id === selectedUserId()}
        />
      ))}
    </div>
  )
}

export default UserListContainer
