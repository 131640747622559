import uuid from 'time-uuid'

import type { Order } from '@/data/Order'
import { OrderStatus } from '@/pos/OrderType'
import { signalSyncedWithLocalStorage } from '@/react/core/reactive'

import { addItemToOrder, completeOrder } from '../order-mutations'
import { srmTransactionLogic } from '../transaction.logic'
import { execTestcase, getCaTaxInfo } from './libs'

type O = Readonly<Order>

const [order01, setOrder01] = signalSyncedWithLocalStorage<O | null>('TESTCASE_037_ORDER_01', null)
const [order02, setOrder02] = signalSyncedWithLocalStorage<O | null>('TESTCASE_037_ORDER_02', null)
const [order03, setOrder03] = signalSyncedWithLocalStorage<O | null>('TESTCASE_037_ORDER_03', null)

const getBaseOrder = (): O => {
  const taxInfo = getCaTaxInfo()
  return {
    _id: uuid(),
    table: '000',
    status: OrderStatus.IN_PROGRESS,
    payments: [],
    multiplePriceMenu: '',
    items: [
      // Main Course
      { name: 'Bouillabaisse', price: 22, quantity: 2, modifiers: [], ...taxInfo },
      // Drink
      { name: 'Bordeaux', price: 18, quantity: 2, modifiers: [], ...taxInfo },
    ],
  }
}

export const testRunner037: Record<string, () => Promise<boolean>> = {
  '037.001': () =>
    execTestcase({
      num: 37,
      step: 1,
      device: 'A',
      run: async () => {
        const order01 = { ...getBaseOrder(), table: '100' }
        await srmTransactionLogic.recordTemporaryBill(order01)
        setOrder01(order01)
      },
    }),
  '037.002': () =>
    execTestcase({
      num: 37,
      step: 2,
      device: 'A',
      run: async () => {
        const o1 = order01()
        if (!o1) throw new Error('Please run step 1 first')
        const o2 = addItemToOrder(o1, { name: 'Crème Brûlée', price: 6.5, quantity: 1, modifiers: [], ...getCaTaxInfo() })
        await srmTransactionLogic.recordTemporaryBill(o2)
        setOrder02(o2)
      },
    }),
  '037.003': () =>
    execTestcase({
      num: 37,
      step: 3,
      device: 'A',
      run: async () => {
        const o2 = order02()
        if (!o2) throw new Error('Please run step 2 first')
        const o3 = addItemToOrder(o2, { name: 'Tarte Tatin', price: 8, quantity: 1, modifiers: [], ...getCaTaxInfo() })
        await srmTransactionLogic.recordTemporaryBill(o3, { print: true })
        setOrder03(o3)
      },
    }),
  '037.004': () =>
    execTestcase({
      num: 37,
      step: 4,
      device: 'A',
      run: async () => {
        const o3 = order03()
        if (!o3) throw new Error('Please run step 3 first')
        await srmTransactionLogic.recordClosingReceipt(completeOrder(o3), { print: true })
      },
    }),
}
