import uuid from 'time-uuid'

import type { Order } from '@/data/Order'
import { MarketPlaceProvider, OrderStatus } from '@/pos/OrderType'
import { signalSyncedWithLocalStorage } from '@/react/core/reactive'

import { completeOrder } from '../order-mutations'
import { srmTransactionLogic } from '../transaction.logic'
import { execTestcase, getCaTaxInfo } from './libs'

type O = Readonly<Order>

const [order08, setOrder08] = signalSyncedWithLocalStorage<O | null>('TESTCASE_010_ORDER_08', null)
const [order10, setOrder10] = signalSyncedWithLocalStorage<O | null>('TESTCASE_010_ORDER_10', null)
const [order16, setOrder16] = signalSyncedWithLocalStorage<O | null>('TESTCASE_010_ORDER_16', null)
const [order18, setOrder18] = signalSyncedWithLocalStorage<O | null>('TESTCASE_010_ORDER_18', null)

const getBaseOrder = (): O => {
  const taxInfo = getCaTaxInfo()
  const order: O = {
    _id: uuid(),
    table: '800',
    status: OrderStatus.IN_PROGRESS,
    payments: [],
    items: [
      // Main Course
      { name: 'Bouillabaisse', price: 22, quantity: 2, modifiers: [], ...taxInfo },
      // Drink
      { name: 'Bordeaux', price: 18, quantity: 2, modifiers: [], ...taxInfo },
      // Dessert
      { name: 'Crème Brûlée', price: 6.5, quantity: 1, modifiers: [], ...taxInfo },
      { name: 'Tarte Tatin', price: 8, quantity: 1, modifiers: [], ...taxInfo },
    ],
  }
  return order
}

export const testRunner010: Record<string, () => Promise<boolean>> = {
  '010.008': () =>
    execTestcase({
      num: 10,
      step: 8,
      device: 'A',
      run: async () => {
        const order = getBaseOrder()
        await srmTransactionLogic.recordTemporaryBill(order, { print: true })
        setOrder08(order)
      },
    }),
  '010.009': () =>
    execTestcase({
      num: 10,
      step: 9,
      device: 'B',
      run: async () => {
        const o = order08()
        if (!o) throw new Error('Please run step 8 first')
        await srmTransactionLogic.recordClosingReceipt(completeOrder(o), { print: true })
      },
    }),
  '010.010': () =>
    execTestcase({
      num: 10,
      step: 10,
      device: 'A',
      run: async () => {
        const order = { ...getBaseOrder(), _id: uuid(), table: '110' }
        await srmTransactionLogic.recordTemporaryBill(order, { print: true })
        setOrder10(order)
      },
    }),
  '010.011': () =>
    execTestcase({
      num: 10,
      step: 11,
      device: 'A',
      run: async () => {
        const o = order10()
        if (!o) throw new Error('Please run step 10 first')
        await srmTransactionLogic.recordClosingReceipt(completeOrder(o), { print: true })
      },
    }),
  '010.016': () =>
    execTestcase({
      num: 10,
      step: 16,
      device: 'A',
      run: async () => {
        const order = { ...getBaseOrder(), _id: uuid(), table: undefined, provider: MarketPlaceProvider.DELIVERECT }
        await srmTransactionLogic.recordTemporaryBill(order, { print: true })
        setOrder16(order)
      },
    }),
  '010.017': () =>
    execTestcase({
      num: 10,
      step: 17,
      device: 'A',
      run: async () => {
        const o = order16()
        if (!o) throw new Error('Please run step 16 first')
        await srmTransactionLogic.recordClosingReceipt(completeOrder(o), { print: true })
      },
    }),
  '010.018': () =>
    execTestcase({
      num: 10,
      step: 18,
      device: 'C',
      run: async () => {
        const order = { ...getBaseOrder(), _id: uuid(), table: undefined }
        await srmTransactionLogic.recordTemporaryBill(order, { print: true })
        setOrder18(order)
      },
    }),
  '010.019': () =>
    execTestcase({
      num: 10,
      step: 19,
      device: 'C',
      run: async () => {
        const o = order18()
        if (!o) throw new Error('Please run step 18 first')
        await srmTransactionLogic.recordClosingReceipt(completeOrder(o), { print: true })
      },
    }),
}
