import { PaidOrder } from "@/data/Order.ts";
import { convertDocument, convertDocuments } from "@/data/data-utils.ts";
import _ from "lodash";
import { loginSurrealDbCloud } from "@/pos/loginSurrealDbCloud.ts";
import SurrealClient from "@/shared/SurrealClient.ts";
import { posSync0 } from "@/data/PosSyncState.ts";
import delay from "delay";
import { createOrder, stripPaidOrder } from "@/pos/logic/order-reactive.ts";
import { removeOrder } from "@/data/OrderHub.ts";
import { PaymentType } from "../Payment"



export async function fixTaxAndItem() {
  return {
    findStartOrder,
    fixCashType,
    fixPaymentUnbar,
    getLackItemOrder,
    loginSurrealAndGetData,
    fixWrongTakeAway,
    getWrongTakeAwayOrder,
    fixWrongvSum,
    fixId,
    fixRefId,
  }
  async function findStartOrder() {
    const _order = await PaidOrder.find(
      {
        selector:
          {
            vDate: {
              $gte: 1722470400
            }
          }
      }).exec();
    if (_order) {
      // console.log('order number', _order?.length)
      return convertDocuments(_order, true);
    }
  }

  async function fixCashType() {
    const order = await findStartOrder();
    if (order) {
      for (const xOrder of order) {
        if (xOrder.payments?.length === 0) continue
        if (xOrder.payments?.[0].type === 'cash') {
          _.assign(xOrder.payments?.[0], {type: 'bar', extraType: PaymentType.Cash})
        }
        if (xOrder.payments?.[0].type === 'bar' && !xOrder.payments?.[0]?.extraType) {
          _.assign(xOrder.payments?.[0], {extraType: PaymentType.Cash})
        }
      }
    }
  }

  async function fixPaymentUnbar() {
    const order = await findStartOrder();
    if (order) {
      for (const xOrder of order) {
        if (xOrder.payments?.length === 0) continue
        if (!xOrder.payments?.[0].extraType) {
          _.assign(xOrder.payments?.[0], {extraType: PaymentType.None})
        }
      }
    }
  }

  async function getLackItemOrder() {
    const order = await findStartOrder();
    const lackItem = order?.filter(o => o.items?.length === 0 || o.tseMethod === 'apply' || o.vSum === 0)
    if (lackItem) {
      console.log('lackItem', lackItem.length)
    }
    const _ids = lackItem?.map(i => i._id)
    return {
      ids: _ids || [],
      order: order
    }
  }

  async function getWrongTakeAwayOrder() {
    const order = await findStartOrder();
    const filterKeywords = ['Lieferando', 'Uber', 'Wolt', 'Mitnehmen'];
    const filteredOrders = order.filter(order =>
      (!order.table || filterKeywords.some(keyword => order.table?.includes(keyword))) &&
      order.takeAway === false
    );

    const _ids = filteredOrders?.map(i => i._id)
    return {
      _ids: _ids || [],
      order: order
    }
  }


  async function fixWrongTakeAway() {
    const info = await getWrongTakeAwayOrder();
    const lackTakeAwayIds = info._ids;
    const order = info.order
    console.log('lackTakeAwayIds', lackTakeAwayIds);
    if (lackTakeAwayIds?.length > 0) {
      for (const id of lackTakeAwayIds) {
        const foundOrder = order.find(o => o._id === id);
        if (foundOrder) {
          const targetOrder = createOrder(foundOrder);
          targetOrder.takeAway = true;
          await delay(1000);
          console.log('targetOrder', targetOrder)


          //create stripOrder0
          let stripOrder0 = stripPaidOrder(targetOrder);
          //remove targetOrder in OrderCollection
          await removeOrder(targetOrder);
          //update order in PaidOrder
          await PaidOrder.upsert(stripOrder0)
        }
      }
    }
  }

  async function loginSurrealAndGetData() {
    const db = await SurrealClient.getSurrealClient('n36');
    const info = await getLackItemOrder();
    const order = info.order;
    if (db) {
      const idsArray = info.ids;
      // console.log('id array length', idsArray?.length)

      //fixme: replace item

      if (idsArray?.length > 0) {
        const [lackItemDoc] = await db.query('SELECT * FROM n36_order_logs WHERE doc._id IN $ids', { ids: idsArray });
        //get avalableDoc
        const availableDoc = lackItemDoc?.filter(d => d.doc._deleted === false);


        if (!availableDoc || availableDoc?.length === 0) return;

        const latestDocsMap = new Map<string, any>();

        //filter by Map - get the lastest doc by created_at
        for (const { created_at, doc } of availableDoc) {
          const { _id } = doc;

          // check if value is exist in Map
          if (!latestDocsMap.has(_id) || created_at > latestDocsMap.get(_id).created_at) {
            latestDocsMap.set(_id, { ...doc, created_at }); // Lưu lại doc và create_at
          }
        }

        //convert Map to array
        const latestDocs = Array.from(latestDocsMap.values());
        let count = 0

        if (latestDocs) {
          for (const doc of latestDocs) {
            if (doc.items.length === 0) continue;
            const findId = doc._id;
            if (findId) {
              const targetOrder = order.find(o => o._id === findId);
              const items = _.cloneDeep(doc.items);

              if (targetOrder && targetOrder.items.length < items.length) {
                targetOrder.items = items || [];
                count++;
              }
            }
          }
          console.log('count', count)
        }
      }
    }
    if (!db) return;
    await delay(1000)
    await db.close();
  }

  async function fixWrongvSum() {
    const orders = await findStartOrder();
    let count = 0
    for (const order of orders) {
      const vSumItems = _.sumBy(order.items, 'vSum') || 0;
      if (order.vSum == null || Math.abs(vSumItems - order.vSum) > 0.02) {
        console.log('order', order)
        const targetOrder = createOrder(order);
        await delay(200)
        // console.log('targetOrder', targetOrder)
        //create stripOrder0
        let stripOrder0 = stripPaidOrder(targetOrder);
        //fixme: fix payment after fix vSum
        // stripOrder0.payments[0].value = stripOrder0.vSum
        //remove targetOrder in OrderCollection
        await removeOrder(targetOrder);
        //update order in PaidOrder
        await PaidOrder.upsert(stripOrder0)
        count++;
      }
    }
  }

  async function fixId() {
    const _orders = await PaidOrder.find({
      selector: {
        vDate: {
          $gte: 1722470400
        }
      }
    }).sort({ date: 'asc' }).exec();

    if (_orders) {
      const orders = convertDocuments(_orders, true);
      for (const [index, order] of orders.entries()) {
        order.id = 2013 + index;
      }
    }
    console.log('finish')
  }

  async function fixRefId() {
    const _orders = await PaidOrder.find({
      selector: {
        vDate: {
          $gte: 1722470400
        },
        refOrder: {
          $exists: true
        }
      }
    }).exec();

    if (_orders) {
      const orders = convertDocuments(_orders, true);
      for (const order of orders) {
        const foundOrder = await PaidOrder.findOne({selector: {_id: order.refOrder}}).exec();
        if (foundOrder) {
          const foundOrderX = convertDocument(foundOrder, false);
          order.refOrderId = foundOrderX.id
        }
      }
    }
    console.log('finish')
  }
}


async function fix6() {
  let api = await fixTaxAndItem();
  // step1: add extraType for cash
  // await api.fixCashType();
  // step2: add extraType for unbar
  // await api.fixPaymentUnbar();
  // step 3: fix lost item order
  // await api.loginSurrealAndGetData();
  // step 4: fix takeAway
  // await api.fixWrongTakeAway()
  // step5: fix wrong vSum
  // await api.fixWrongvSum()
  // step6: fix id
  // await api.fixId()
  // step7: fix refId
  // await api.fixRefId()
}

window.fix6 = fix6;