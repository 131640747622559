import type { FunctionComponent } from "react";

export type MonthtlyReportItemType = {
  itemQuantity?: string;
  itemName?: string;
  itemPrice?: string;
};

const MonthtlyReportItem: FunctionComponent<MonthtlyReportItemType> = ({
  itemQuantity = "10",
  itemName = "Baijiu",
  itemPrice = "(€ 60.00)",
}) => {
  return (
    <div className="max-w-full flex flex-row items-start justify-start gap-[4px] text-left text-sm text-gray font-mulish self-stretch">
      <div className="relative Tablet_768:text-smi Tablet_600:text-xs">
        {itemQuantity}
      </div>
      <div className="relative Tablet_768:text-smi Tablet_600:text-xs">x</div>
      <div className="relative Tablet_768:text-smi Tablet_600:text-xs">
        {itemName}
      </div>
      <div className="relative Tablet_768:text-smi Tablet_600:text-xs">
        {itemPrice}
      </div>
    </div>
  );
};

export default MonthtlyReportItem;
