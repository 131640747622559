import type { OrderStrip } from '@/pos/OrderType.ts'
import { signal } from '@/react/core/reactive.ts'
import { printImageToConsole } from '@/shared/printImageToConsole.ts'
import { downloadPrintedImage } from '@/shared/utils.ts'
import { getSdk } from '@/srm/getSdk.ts'
import { getSignableSdkWithHeadersAndTaxNum } from '@/srm/getSdkWithHeadersAndTaxNum.ts'
import { generateRasterInvoice } from '@/srm/invoice.logic.ts'
import { CONSTANT_VALUES } from '@/srm/lib/constants'
import { REGISTERED_VALUES } from '@/srm/lib/constants.ts'
import type { CertificateRequestBody, RequestHeaders, SrsCsrContent } from '@/srm/lib/types'
import { generateCsrJsrsasign, genKeyPair } from '@/srm/lib/utils.cert.ts'
import { order2Trans } from '@/srm/order2Trans.ts'
import { TESTCASE_VARS } from '@/srm/testcase/constants.ts'
import { srmTransactionLogic } from '@/srm/transaction.logic.ts'

import deliverect from '../assets/data/online-order/deliverect.json'
import pikaDelivery from '../assets/data/online-order/pika-delivery.json'
import pikaPickup from '../assets/data/online-order/pika-pickup.json'
import vega from '../assets/data/online-order/vega.json'
import { OnlineOrder } from './OnlineOrder.ts'
import { PaidOrder } from './Order.ts'

export const normalMode = {
  pause: false,
  initDemoData: true,
  initedPosId: undefined,
}

export const initMode = {
  pause: false,
  initDemoData: false,
  initedPosId: 514,
}

export const pauseMode = {
  pause: true,
  initDemoData: false,
  initedPosId: 489,
}

const mode = normalMode
// let step = pauseStep;

export const [pause, setPause] = signal(mode.pause)
export const [initDemoData, setInitDemoData] = signal(mode.initDemoData)
export const [initedPosId, setInitedPosId] = signal<number>(mode.initedPosId)

const createOOMock = () => dev.mock.onlineOrder.pika.pickup()

const dev = {
  mock: {
    onlineOrder: {
      vega: {
        pickup() {
          OnlineOrder.upsert({ ...vega, _id: Date.now().toString() } as unknown as OrderStrip).then(() => console.log('mock vega pickup completed'))
        },
        delivery() {},
      },
      pika: {
        pickup() {
          OnlineOrder.upsert({ ...pikaPickup, _id: Date.now().toString() } as unknown as OrderStrip).then(() => console.log('mock pika pickup completed'))
        },
        delivery() {
          OnlineOrder.upsert({ ...pikaDelivery, _id: Date.now().toString() } as unknown as OrderStrip).then(() => console.log('mock pika delivery completed'))
        },
      },
      deliverect: {
        delivery() {
          OnlineOrder.upsert({ ...deliverect, _id: Date.now().toString() } as unknown as OrderStrip).then(() => console.log('mock pika delivery completed'))
        },
      },
    },
    async genSrmCert() {
      const sdk = getSdk()
      const content: SrsCsrContent = {
        CN: TESTCASE_VARS.identificationNumber,
        O: `RBC-${TESTCASE_VARS.authCode}`,
        OU: TESTCASE_VARS.qstNumber,
        SN: 'Test cert 01',
        GN: TESTCASE_VARS.billingNumber,
        L: '-04:00',
        ST: 'QC',
        C: 'CA',
      }

      //===========================================================================//
      // Generate Keypair and CSR
      //===========================================================================//
      const { privateKey, publicKey } = genKeyPair()
      console.log(`ℹ️ privateKey:`, privateKey)
      console.log(`ℹ️ publicKey:`, publicKey)
      console.log(`ℹ️ content:`, content)
      const csr = generateCsrJsrsasign(content, privateKey, publicKey)
      console.log(`ℹ️ csr:`, csr)
      //===========================================================================//
      // Request for certificate
      //===========================================================================//

      const headers: RequestHeaders = {
        ENVIRN: 'DEV',
        CASESSAI: CONSTANT_VALUES.CASESSAI_EMPTY,
        APPRLINIT: 'SEV',
        IDAPPRL: CONSTANT_VALUES.IDAPPRL_EMPTY,
        IDSEV: REGISTERED_VALUES.android_v1.IDSEV,
        IDVERSI: REGISTERED_VALUES.android_v1.IDVERSI,
        CODCERTIF: CONSTANT_VALUES.CODCERTIF_EMPTY,
        IDPARTN: REGISTERED_VALUES.android_v1.IDPARTN,
        VERSI: REGISTERED_VALUES.android_v1.version,
        VERSIPARN: REGISTERED_VALUES.android_v1.previousVersion,
      }
      const body: CertificateRequestBody = {
        reqCertif: { modif: 'AJO', csr: csr },
      }
      console.log(`ℹ️ headers:`, headers)
      console.log(`ℹ️ body:`, body)
      const response = await sdk.certificate(headers, body)
      console.log(`✅ response:`, response)

      const result = {
        srm_privateKey: privateKey,
        srm_publicKey: publicKey,
        srm_cert: response.retourCertif.certif,
        srm_certPSI: response.retourCertif.certifPSI!,
        srm_deviceId: response.retourCertif.idApprl!,
      }

      //===========================================================================//
      // Saving
      //===========================================================================//
      localStorage.setItem('srm_privateKey', result.srm_privateKey)
      localStorage.setItem('srm_publicKey', result.srm_publicKey)
      localStorage.setItem('srm_cert', result.srm_cert)
      localStorage.setItem('srm_certPSI', result.srm_certPSI)
      localStorage.setItem('srm_deviceId', result.srm_deviceId)

      console.log('💾 Result saved to local storage!')
      console.table(result)
    },
    srm: {
      async genBillForOrder(orderId: string, download = false) {
        const { sdk, deviceId } = getSignableSdkWithHeadersAndTaxNum()
        const rows = await PaidOrder.find({ selector: { _id: orderId } }).exec()
        for (const order of rows) {
          console.log(`⚡️ Printing [${order.id}]...`)
          const trans = await order2Trans(order.toMutableJSON())
          const signed = await srmTransactionLogic.signTransaction(trans)
          const raster = await generateRasterInvoice(signed, {
            qrcodeData: await sdk.generateQRCode(signed),
            deviceId,
            printer: { escPOS: false },
          })
          await printImageToConsole(orderId, raster)
          if (download) await downloadPrintedImage(raster, signed.noTrans.slice(-4))
        }
      },
    },
  },
}

Object.assign(window, { dev, createOOMock })
