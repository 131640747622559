import {makeRipple} from "@/react/core/ripple-utils";
import {type PosScreen, router} from "@/pos/PosRouter";
import type {Setter} from "solid-js";
import PopupHintUrl from "@/assets/popup_hint.png";
import * as Dialog from "@radix-ui/react-dialog";
import {useSignal} from "@/react/core/reactive";
import type { Room } from "@/data/Room";

export const styleDialogContainerGradient = (w?: number, h?: number) => {
	return {
		className: `fixed z-50 left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[6px] px-[45px] py-[41px] space-y-[32px] backdrop-blur-[10px]
    duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95
    data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2
    data-[state=open]:slide-in-from-top-[48%]
    bg-gradient-to-b from-[rgba(247,247,247,0.42)] to-[rgba(170,169,169,0.42)] flex flex-col absolute`,
		...w && h && {
			style: {
				width: `${w}px`,
				height: `${h}px`
			}
		}
	}
}

export const styleDialogContainer = () => {
	return `fixed z-50 left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[6px] px-[45px] py-[41px] space-y-[32px] backdrop-blur-[10px]
    duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95
    data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2
    data-[state=open]:slide-in-from-top-[48%]
    flex flex-col absolute`;
}

const POPUP_BUTTON = {
	className: "text-[12px] flex-1 text-center p-[11px] h-full bg-white center flex gap-[10px] flex-grow rounded-[6px] flex-col"
};

export const renderDialogItem = (text: string, img: string, setShow: Setter<boolean>, screen?: PosScreen) => {
	return <div {...POPUP_BUTTON}
	            ref={makeRipple}
	            onPointerDown={() => {
		            setShow(false);
		            if (screen) router.screen = screen;
	            }}
	>
		<img src={img} style={{height: "41px"}}/>
		{text}
	</div>
}

const DASHBOARD_BUTTON = {
	style: {
		color: "#101010",
		"boxShadow": "0px 0.5px 1.5px rgba(0, 0, 0, 0.5)",
		"textAlign": "center",
		"height": `${(240 - 14) / 2}px`,
	} as const,
	className: "select-none flex-1 text-[14px] center relative rounded-[4px] px-[5px] gap-[11px] bg-gradient-to-b from-[#FFFFFF] to-[#F8F7F7]",
};

type DashboardButtonProps = {
	onClick?: () => any;
	screen?: PosScreen;
	img: string;
	text: string;
	class?: string;
	popup?: Function
};

export const DashboardButton = ({onClick, img, text, screen, class: class0}: DashboardButtonProps) => {
	return (
		<div
			// ref={makeRipple}
			className={`${class0} ${DASHBOARD_BUTTON.className}`}
			style={{...DASHBOARD_BUTTON.style, padding: "11px", display: "flex", 'flexDirection': "column", 'justifyContent': "center"}}
			onClick={() => {
				if (screen) router.screen = screen;
				onClick?.();
			}}
		>
			<img src={img} style={{height: "41px"}}/>
			{text}
		</div>
	);
};

export const DashboardButtonModal = ({text, img, popup}: DashboardButtonProps) => {
	const [show, setShow] = useSignal(false);
	return (
		<Dialog.Root open={show()} onOpenChange={setShow}>
			<Dialog.Trigger asChild>
				<div
					ref={makeRipple}
					style={{
						...DASHBOARD_BUTTON.style,
						padding: "11px",
						display: "flex",
						'flexDirection': "column",
						'justifyContent': "center"
					}}
					className={DASHBOARD_BUTTON.className}>
					<img src={img} style={{height: "40px"}}/>
					<img src={PopupHintUrl} style={{height: "13px", position: "absolute", top: "6px", right: "5px"}}/>
					{text}
				</div>
			</Dialog.Trigger>
			<Dialog.Portal>
				<Dialog.Overlay/>
				<Dialog.DialogContent>
					{popup?.(show, setShow)}
				</Dialog.DialogContent>
			</Dialog.Portal>
		</Dialog.Root>
	)
};

const ROOM_EDGE_PADDING = 30
export function getRoomWidth(room?: Room) {
  return ROOM_EDGE_PADDING + Math.max(0, ...(room?.roomObjects?.map(o => o.location.x + o.size.width) ?? []))
}
export function getRoomHeight(room?: Room) {
  return ROOM_EDGE_PADDING + Math.max(0, ...(room?.roomObjects?.map(o => o.location.y + o.size.height) ?? []))
}

const MAX_ZOOM = 1.2
const MIN_ZOOM = 0.4
export function getValidZoom(zoomLevel: number){
  if (isNaN(zoomLevel)) return 1
  zoomLevel = Math.max(MIN_ZOOM, zoomLevel)
  zoomLevel = Math.min(MAX_ZOOM, zoomLevel)
  return zoomLevel
}