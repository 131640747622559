import uuid from 'time-uuid'

import type { Order } from '@/data/Order'
import { OrderStatus } from '@/pos/OrderType'
import { signalSyncedWithLocalStorage } from '@/react/core/reactive'

import { completeOrder } from '../order-mutations'
import { srmTransactionLogic } from '../transaction.logic'
import { execTestcase, getCaTaxInfo } from './libs'

type O = Readonly<Order>

const [order01, setOrder01] = signalSyncedWithLocalStorage<O | null>('TESTCASE_026_ORDER_01', null)

export const testRunner026: Record<string, () => Promise<boolean>> = {
  cleanup: async () => {
    setOrder01(null)
    return true
  },
  '026.001': () =>
    execTestcase({
      num: 26,
      step: 1,
      device: 'A',
      run: async () => {
        const taxInfo = getCaTaxInfo()
        const o: O = {
          _id: uuid(),
          table: '001',
          status: OrderStatus.IN_PROGRESS,
          payments: [],
          items: [
            // Main Course
            { name: 'Bouillabaisse', price: 22, quantity: 2, modifiers: [], ...taxInfo },
            // Drink
            { name: 'Bordeaux', price: 18, quantity: 2, modifiers: [], ...taxInfo },
            // Dessert
            { name: 'Crème Brûlée', price: 6.5, quantity: 1, modifiers: [], ...taxInfo },
            { name: 'Tarte Tatin', price: 8, quantity: 1, modifiers: [], ...taxInfo },
          ],
        }
        await srmTransactionLogic.recordTemporaryBill(o, { print: true })
        setOrder01(o)
      },
    }),
  '026.002': () =>
    execTestcase({
      num: 26,
      step: 2,
      device: 'A',
      run: async () => {
        const o = order01()
        if (!o) throw new Error('Please run step 1 first')
        await srmTransactionLogic.recordClosingReceipt(completeOrder(o), { print: true })
      },
    }),
}
