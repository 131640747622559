import { type FunctionComponent, useCallback, useState } from "react";
import PopupDeleteConfirmation from "../Popup/PopupDeleteConfirmation";
import PortalPopup from "../Popup/PortalPopup";
import type { Modifier } from "@/data/Modifier";
import { LL0 } from "@/react/core/I18nService";
import type { DocDeepSignal } from "@/data/data-utils";
import { LL3 } from "@/react/core/I18nCurrency";

type ModifierListCardType = {
  showIsQuantity?: boolean;
  showIsMultiple?: boolean;
  showIsOnlyOne?: boolean;
  showFreeTopping?: boolean;
  isSelect?: boolean;
  isOnlyOneMultipleQuantity?: boolean;
  isRequired?: boolean;
  showQuantity?: boolean;

  model: DocDeepSignal<Modifier>
  onDelete: () => void
  onEdit: () => void
};

const ModifierListCard: FunctionComponent<ModifierListCardType> = ({
  showIsQuantity = true,
  showIsMultiple = true,
  showIsOnlyOne = true,
  showFreeTopping = true,
  isSelect = true,
  isOnlyOneMultipleQuantity = true,
  isRequired = true,
  showQuantity,
  model,
  onDelete,
  onEdit,
}) => {
  const [isEditItemDeleteConfirmPoOpen, setEditItemDeleteConfirmPoOpen] =
    useState(false);

  const openEditItemDeleteConfirmPo = useCallback(() => {
    setEditItemDeleteConfirmPoOpen(true);
  }, []);

  const closeEditItemDeleteConfirmPo = useCallback(() => {
    setEditItemDeleteConfirmPoOpen(false);
  }, []);

  const LL = LL0()

  return (
    <>
      <div className="flex flex-row items-center justify-start gap-[12px] text-left text-lg text-blue-solid-blue-900-1c1f37 font-mulish-button-name-bold self-stretch">
        <div className="flex-1 rounded-2xl bg-gray-solid-gray-80-fafafa shadow-[0px_5px_10px_rgba(228,_230,_242,_0.5)] overflow-hidden flex flex-row items-center justify-start py-4 pr-4 pl-5 gap-[12px] border-[1px] border-solid border-gray-solid-gray-300-e1e1e1-200 lg:gap-[10px] md:gap-[8px] sm:gap-[4px] mq768:gap-[2px] height_414:py-3 height_414:pr-4 height_414:pl-3 height_414:box-border">
          <div className="flex-1 flex flex-col items-start justify-start gap-[16px] lg:gap-[14px] md:gap-[12px] sm:gap-[10px] mq768:gap-[8px]">
            <div onClick={onEdit} className="cursor-pointer flex flex-row items-center justify-start gap-[8px] text-xl sm:gap-[4px]">
              <b className="relative lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                {model.name}
              </b>
              {model.mandatory && (
                <div className="relative w-6 h-6 overflow-hidden shrink-0 sm:w-5 sm:h-5">
                  <img
                    className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                    alt=""
                    src="/iconimportant-mark-icon@2x.png"
                  />
                </div>
              )}
            </div>
            {(model.type === 'onlyOne' || model.type === 'multiple' || model.type === 'quantity') && (
              <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[10px] text-blue-solid-blue-200-017efa">
                {model.items?.map(item => (
                <div key={item._id} className="rounded-10xs bg-blue-solid-blue-100-e6f2fe shadow-[0px_5px_10px_#f1f2fa] h-8 flex flex-row items-center justify-center py-0 px-2 box-border gap-[8px] w-auto [align-self:unset]              ">
                  <b className="relative lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                    {item.name}
                  </b>
                  <div className="relative bg-blue-solid-blue-200-017efa w-[1.5px] h-5" />
                  {model.type === 'quantity' && (
                  <div className="flex flex-row items-center justify-center gap-[8px] text-purple-solid-purple-100-5d5fef">
                    <b className="relative lg:text-12xs md:text-sm sm:text-smi mq768:text-xs">
                      {item.typeQuantity_itemCount}
                    </b>
                    <div className="relative bg-blue-solid-blue-200-017efa w-[1.5px] h-5" />
                  </div>
                  )}
                  <b className="relative text-orange-solid-orange-200-ef9400 lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                    {item.price ? LL3().format.currency(item.price) : '-'}
                  </b>
                </div>
                ))}
                {/*
                <div className="rounded-10xs bg-blue-solid-blue-100-e6f2fe shadow-[0px_5px_10px_#f1f2fa] h-8 flex flex-row items-center justify-center py-0 px-2 box-border gap-[8px] w-auto [align-self:unset]              ">
                  <b className="relative lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                    Fried pork
                  </b>
                  <div className="relative bg-blue-solid-blue-200-017efa w-[1.5px] h-5" />
                  <div className="flex flex-row items-center justify-center gap-[8px] text-purple-solid-purple-100-5d5fef">
                    <b className="relative lg:text-12xs md:text-sm sm:text-smi mq768:text-xs">
                      1
                    </b>
                    <div className="relative bg-blue-solid-blue-200-017efa w-[1.5px] h-5" />
                  </div>
                  <b className="relative text-orange-solid-orange-200-ef9400 lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                    $10
                  </b>
                </div>
                <div className="rounded-10xs bg-blue-solid-blue-100-e6f2fe shadow-[0px_5px_10px_#f1f2fa] h-8 flex flex-row items-center justify-center py-0 px-2 box-border gap-[8px] w-auto [align-self:unset]              ">
                  <b className="relative lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                    Fried chicken
                  </b>
                  <div className="relative bg-blue-solid-blue-200-017efa w-[1.5px] h-5" />
                  <div className="flex flex-row items-center justify-center gap-[8px] text-purple-solid-purple-100-5d5fef">
                    <b className="relative lg:text-12xs md:text-sm sm:text-smi mq768:text-xs">
                      1
                    </b>
                    <div className="relative bg-blue-solid-blue-200-017efa w-[1.5px] h-5" />
                  </div>
                  <b className="relative text-orange-solid-orange-200-ef9400 lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                    $15
                  </b>
                </div>
                */}
              </div>
            )}
            {model.type === 'select' && (
              <div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-orange-solid-orange-600-bf710f lg:gap-[12px] md:gap-[10px] sm:gap-[8px] mq768:gap-[6px]">
                {model.freeSelected && (
                  <div className="flex flex-row items-start justify-start gap-[4px] text-base text-blue-solid-blue-900-1c1f37">
                    <i className="relative [display:-webkit-inline-box] font-semibold overflow-hidden text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical] md:text-xs">
                      {LL0().editOnlineMenu.freeTopping()}:
                    </i>
                    <i className="relative [display:-webkit-inline-box] font-semibold overflow-hidden text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical] md:text-xs">
                      {model.freeSelected}
                    </i>
                  </div>
                )}
                {model.itemGroups?.map(g => (
                <div key={g._id} className="self-stretch flex flex-col items-start justify-start gap-[16px] lg:gap-[14px] md:gap-[12px] sm:gap-[10px] mq768:gap-[8px]">
                  <div className="rounded-10xs bg-orange-solid-orange-200-fbe5c9 shadow-[0px_5px_10px_#f1f2fa] h-8 flex flex-row items-center justify-center py-0 px-2 box-border w-auto [align-self:unset] gap-[8px]              ">
                    <b className="relative lg:text-2xs md:text-2xs">{g.name}</b>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-5 gap-[16px] text-blue-solid-blue-200-017efa">
                    <div className="h-8 flex flex-row items-center justify-center">
                      <div className="relative w-6 h-6 overflow-hidden shrink-0">
                        <img
                          className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                          alt=""
                          src="/iconmenudouble-arrow-right-icon@2x.png"
                        />
                      </div>
                    </div>
                    <div className="flex-1 shrink-0 flex flex-row flex-wrap items-start justify-start gap-[10px]">
                      {model.items?.filter(a => a.typeSelect_group === g._id).map(item => (
                      <div key={item._id} className="rounded-10xs bg-blue-solid-blue-100-e6f2fe shadow-[0px_5px_10px_#f1f2fa] h-8 flex flex-row items-center justify-center py-0 px-2 box-border gap-[8px] w-auto [align-self:unset]              ">
                        <b className="relative lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                          {item.name}
                        </b>
                        <div className="relative bg-blue-solid-blue-200-017efa w-[1.5px] h-5" />
                        <div className="flex flex-row items-center justify-center gap-[8px] text-purple-solid-purple-100-5d5fef">
                          <b className="relative lg:text-12xs md:text-sm sm:text-smi mq768:text-xs">
                            {item.typeQuantity_itemCount}
                          </b>
                          <div className="relative bg-blue-solid-blue-200-017efa w-[1.5px] h-5" />
                        </div>
                        <b className="relative text-orange-solid-orange-200-ef9400 lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                          {item.price ? LL3().format.currency(item.price) : '-'}
                        </b>
                      </div>
                      ))}
                      {/*
                      <div className="rounded-10xs bg-blue-solid-blue-100-e6f2fe shadow-[0px_5px_10px_#f1f2fa] h-8 flex flex-row items-center justify-center py-0 px-2 box-border gap-[8px] w-auto [align-self:unset]              ">
                        <b className="relative lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                          Fried pork
                        </b>
                        <div className="relative bg-blue-solid-blue-200-017efa w-[1.5px] h-5" />
                        <div className="flex flex-row items-center justify-center gap-[8px] text-purple-solid-purple-100-5d5fef">
                          <b className="relative lg:text-12xs md:text-sm sm:text-smi mq768:text-xs">
                            1
                          </b>
                          <div className="relative bg-blue-solid-blue-200-017efa w-[1.5px] h-5" />
                        </div>
                        <b className="relative text-orange-solid-orange-200-ef9400 lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                          $10
                        </b>
                      </div>
                      <div className="rounded-10xs bg-blue-solid-blue-100-e6f2fe shadow-[0px_5px_10px_#f1f2fa] h-8 flex flex-row items-center justify-center py-0 px-2 box-border gap-[8px] w-auto [align-self:unset]              ">
                        <b className="relative lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                          Fried chicken
                        </b>
                        <div className="relative bg-blue-solid-blue-200-017efa w-[1.5px] h-5" />
                        <div className="flex flex-row items-center justify-center gap-[8px] text-purple-solid-purple-100-5d5fef">
                          <b className="relative lg:text-12xs md:text-sm sm:text-smi mq768:text-xs">
                            1
                          </b>
                          <div className="relative bg-blue-solid-blue-200-017efa w-[1.5px] h-5" />
                        </div>
                        <b className="relative text-orange-solid-orange-200-ef9400 lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                          $15
                        </b>
                      </div>
                      */}
                    </div>
                  </div>
                </div>
                ))}
                {/*
                <div className="self-stretch flex flex-col items-start justify-start gap-[16px] lg:gap-[14px] md:gap-[12px] sm:gap-[10px] mq768:gap-[8px]">
                  <div className="rounded-10xs bg-orange-solid-orange-200-fbe5c9 shadow-[0px_5px_10px_#f1f2fa] h-8 flex flex-row items-center justify-center py-0 px-2 box-border w-auto [align-self:unset] gap-[8px]              ">
                    <b className="relative lg:text-2xs md:text-2xs">Starch</b>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-5 gap-[16px] text-blue-solid-blue-200-017efa">
                    <div className="h-8 flex flex-row items-center justify-center">
                      <div className="relative w-6 h-6 overflow-hidden shrink-0">
                        <img
                          className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                          alt=""
                          src="/iconmenudouble-arrow-right-icon@2x.png"
                        />
                      </div>
                    </div>
                    <div className="flex-1 shrink-0 flex flex-row flex-wrap items-start justify-start gap-[10px]">
                      <div className="rounded-10xs bg-blue-solid-blue-100-e6f2fe shadow-[0px_5px_10px_#f1f2fa] h-8 flex flex-row items-center justify-center py-0 px-2 box-border gap-[8px] w-auto [align-self:unset]              ">
                        <b className="relative lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                          Beef
                        </b>
                        <div className="relative bg-blue-solid-blue-200-017efa w-[1.5px] h-5" />
                        <div className="flex flex-row items-center justify-center gap-[8px] text-purple-solid-purple-100-5d5fef">
                          <b className="relative lg:text-12xs md:text-sm sm:text-smi mq768:text-xs">
                            1
                          </b>
                          <div className="relative bg-blue-solid-blue-200-017efa w-[1.5px] h-5" />
                        </div>
                        <b className="relative text-orange-solid-orange-200-ef9400 lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                          $5
                        </b>
                      </div>
                      <div className="rounded-10xs bg-blue-solid-blue-100-e6f2fe shadow-[0px_5px_10px_#f1f2fa] h-8 flex flex-row items-center justify-center py-0 px-2 box-border gap-[8px] w-auto [align-self:unset]              ">
                        <b className="relative lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                          Fried pork
                        </b>
                        <div className="relative bg-blue-solid-blue-200-017efa w-[1.5px] h-5" />
                        <div className="flex flex-row items-center justify-center gap-[8px] text-purple-solid-purple-100-5d5fef">
                          <b className="relative lg:text-12xs md:text-sm sm:text-smi mq768:text-xs">
                            1
                          </b>
                          <div className="relative bg-blue-solid-blue-200-017efa w-[1.5px] h-5" />
                        </div>
                        <b className="relative text-orange-solid-orange-200-ef9400 lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                          $10
                        </b>
                      </div>
                      <div className="rounded-10xs bg-blue-solid-blue-100-e6f2fe shadow-[0px_5px_10px_#f1f2fa] h-8 flex flex-row items-center justify-center py-0 px-2 box-border gap-[8px] w-auto [align-self:unset]              ">
                        <b className="relative lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                          Fried chicken
                        </b>
                        <div className="relative bg-blue-solid-blue-200-017efa w-[1.5px] h-5" />
                        <div className="flex flex-row items-center justify-center gap-[8px] text-purple-solid-purple-100-5d5fef">
                          <b className="relative lg:text-12xs md:text-sm sm:text-smi mq768:text-xs">
                            1
                          </b>
                          <div className="relative bg-blue-solid-blue-200-017efa w-[1.5px] h-5" />
                        </div>
                        <b className="relative text-orange-solid-orange-200-ef9400 lg:text-mini md:text-sm sm:text-smi mq768:text-xs">
                          $15
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
                */}
              </div>
            )}
          </div>
          <div className="self-stretch w-[100px] flex flex-col items-center justify-center gap-[4px] text-base md:min-w-[40px] sm:w-16 mq768:w-12 mq600:max-w-[50px]">
            <div className="flex flex-col items-center justify-center gap-[4px]">
              {model.type === 'onlyOne' && (
                <div className="relative font-semibold lg:text-sm md:text-smi sm:text-xs mq768:text-2xs">
                  {LL0().editOnlineMenu.onlyOne()}
                </div>
              )}
              {model.type === 'multiple' && (
                <div className="relative font-semibold lg:text-sm md:text-smi sm:text-xs mq768:text-2xs">
                  {LL0().editOnlineMenu.multiple()}
                </div>
              )}
              {model.type === 'quantity' && (
                <div className="relative font-semibold lg:text-sm md:text-smi sm:text-xs mq768:text-2xs">
                  {LL0().product.quantity()}
                </div>
              )}
            </div>
            {model.type === 'select' && (
            <div className="relative font-semibold lg:text-sm md:text-smi sm:text-xs mq768:text-2xs">
              {LL0().fnBtn.select()}
            </div>
            )}
          </div>
          <div className="self-stretch w-[70px] flex flex-row items-center justify-center h-auto gap-[8px]         lg:w-[50px] md:flex md:w-12 mq768:w-10">
            <img
              className="cursor-pointer relative w-10 h-10 object-cover sm:w-9 sm:h-9 mq768:w-8 mq768:h-8 mq600:w-[26px] mq600:h-[26px]"
              alt=""
              src="/iconmenuedit-icon@2x.png"
              onClick={onEdit}
            />
          </div>
          <div className="self-stretch w-[70px] flex flex-row items-center justify-center lg:w-[50px] md:flex md:w-12 mq768:w-10">
            <img
              className="relative w-10 h-10 object-cover cursor-pointer sm:w-9 sm:h-9 mq768:w-8 mq768:h-8 mq600:w-[26px] mq600:h-[26px]"
              alt=""
              src="/iconcell-bin-icon1@2x.png"
              onClick={openEditItemDeleteConfirmPo}
            />
          </div>
        </div>
        <img
          className="relative w-6 h-6 object-cover sm:w-[22px] sm:h-[22px] mq768:w-5 mq768:h-5 mq600:w-[18px] mq600:h-[18px]"
          alt=""
          src="/iconreplace-position-icon@2x.png"
        />
      </div>
      {isEditItemDeleteConfirmPoOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeEditItemDeleteConfirmPo}
        >
          <PopupDeleteConfirmation onClose={closeEditItemDeleteConfirmPo}
            message={`${LL0().editOnlineMenu.doYouWantToDelete()} "${model.name}"?`}
            onConfirmed={() => (closeEditItemDeleteConfirmPo(),onDelete())}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default ModifierListCard;
