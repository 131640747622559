import type { RxDocument, RxQuery } from "rxdb";
import { deepSignal } from "deepsignal/react";
import _ from "lodash";
import { clone } from "json-fn";
import { effectOn, signal } from "@/react/core/reactive.ts";
import { debounceTime, type Observable, Subject } from "rxjs";
import type { Accessor, Setter, Signal } from "solid-js";
import { getDeviceId } from '@/shared/getDeviceId'


/*
Feature List:
1.Wrapper docs with auto replace from replication
2.Builder structure
3.AutoSave
4.Protocols
 */

export interface DocObservable<T extends { _id: string }> {
  set: Setter<T>;
  get: Accessor<T>;
  _id: string;
  signal: Signal<T>
}

function convertToDS<T>(doc: RxDocument<T>) {
  return deepSignal({
    ...(doc.toMutableJSON?.() || _.cloneDeep(doc))
  }) as T;
}

export function MakeObservableDocument<T extends { _id: string }>(doc: RxDocument<T>, omitList: string[] = []) {
  const _doc = convertToDS(doc);
  const collectionName = doc.collection.name;

  const sub = new Subject<T>()
  let lastSub: Observable<T> = sub;

  let get: Accessor<T>, set: Setter<T>;
  let ignoreOneTime = false;

  const builder = {
    createSignal,
    addSignal,
    makeAutoObserve,
    makeAutoSave,
    makeDebounce,
    makeAutoReplace,
    exec,
    tap,
    get sub() {
      return lastSub;
    }
  }
  return builder;
  
  function createSignal() {
    ([get, set] = signal<T>(_doc))
    return builder
  }

  function addSignal(_get: Accessor<T>, _set: Setter<T>) {
    ([get, set] = [_get, _set]);
    set(_doc as any)
    return builder
  }

  function makeAutoObserve() {
    effectOn(
      [() => JSON.stringify(_.omit(get() as any, [...omitList, 'updatedAt', 'updatedOn']))],
      () => {
        if (ignoreOneTime) return ignoreOneTime = false;
        sub.next(get());
      },
      { defer: true }
    );
    return builder;
  }

  function makeDebounce(_debounceTime: number) {
    lastSub = sub.pipe(
      debounceTime(_debounceTime)
    );
    return builder;
  }

  function addPreSaved() {

  }

  function tap(fn: (lastSub: Observable<T>) => void) {
    fn(lastSub);
    return builder;
  }

  function makeAutoSave() {
    lastSub.subscribe(async (data) => {
      let _clone = clone(_.omit(get() as any, ["doc", ...omitList]), false) as any;
      addIgnoreUpdate(_clone._id, collectionName);
      await doc.incrementalPatch(_clone);
    })
    return builder;
  }

  function makeAutoReplace() {
    doc.$.subscribe((data) => {
      if ((data.toMutableJSON() as any).updatedOn !== getDeviceId() || !checkIgnoreUpdate(data._id, collectionName)) {
        set(convertToDS(data) as any);
      }
    })
    return builder;
  }

  function exec(): DocObservable<T> {
    const setter: any = (...args: any) => {
      ignoreOneTime = true;
      // @ts-ignore
      set(...args);
    }
    return { _id: (doc as any)._id, get, set: setter , signal: [get, set]};
  }
}

export function MakeObservableList<T extends { _id: string }>(
  query: RxQuery<T>,
  filter: (doc: RxDocument<T>) => boolean,
  map: (doc: RxDocument<T>) => DocObservable<T>
) {
  const collection = query.collection;
  let list: Accessor<DocObservable<T>[]>, setList: Setter<DocObservable<T>[]>;

  const builder = {
    addListSignal,
    pull,
    makeAutoPull
  }

  return builder;

  function addListSignal(get: Accessor<DocObservable<T>[]>, set: Setter<DocObservable<T>[]>) {
    list = get;
    setList = set;
    return builder;
  }

  async function pull() {
    const _list: RxDocument<T>[] = await query.exec();
    setList(_list.map(doc => map(doc)));
    return builder;
  }

  function makeAutoPull() {
    collection.$.subscribe(async change => {
      if (change.operation === "INSERT") {
        const doc = (await collection.findOne({ selector: { _id: change.documentData._id } as any }).exec())!;
        if (filter(doc)) {
          setList([...list(), map(doc)]);
        }
      } else if (change.operation === "DELETE") {
        const doc = change.documentData;
        setList(list().filter(item => item._id !== doc._id));
      } else if (change.operation === "UPDATE") {
        const doc = (await collection.findOne({ selector: { _id: change.documentData._id } as any }).exec())!;
        if (filter(doc)) {
          for (const docObservable of list()) {
            if (docObservable._id === doc._id) {
              if ((doc.toMutableJSON() as any).updatedOn !== getDeviceId() || !checkIgnoreUpdate(doc._id, collection.name)) {
                docObservable.set(convertToDS(doc) as any);
              }
            }
          }
        }
      }
    })
    return builder;
  }

  //1. Fetch
  //2.
}


const ignoreSave : {[k: string]: boolean} = {
}

export function addIgnoreSave(_id: string, colName: string) {
  ignoreSave[`${colName}/${_id}`] = true;
  setTimeout(() => {
    delete ignoreSave[`${colName}/${_id}`]
  }, 1000);
}

export function checkIgnoreSave(_id: string, colName: string) {
  const result =  ignoreSave[`${colName}/${_id}`];
  delete ignoreSave[`${colName}/${_id}`];
  return result;
}

const ignoreUpdate : {[k: string]: boolean} = {
}

export function addIgnoreUpdate(_id: string, colName: string) {
  ignoreUpdate[`${colName}/${_id}`] = true;
  setTimeout(() => {
    delete ignoreUpdate[`${colName}/${_id}`]
  }, 1000);
}

export function checkIgnoreUpdate(_id: string, colName: string) {
  const result =   ignoreUpdate[`${colName}/${_id}`];
  // delete ignoreUpdate[`${colName}/${_id}`];
  console.log(`ignoreUpdate ${colName}`, result);
  return !!result;
}