import { computed, deepSignal, signal } from "@/react/core/reactive.ts";
import type { DocDeepSignal } from "@/data/data-utils.ts";
import type {
  CompanyInfo,
  GeneralSetting,
  KeyboardConfig, PosFeature,
  PosSetting,
  PosSrmSetting,
  PrinterGeneralSetting
} from "@/data/PosSetting.ts";
import MultiAwaitLock from "@/shared/MultiAwaitLock.ts";
import { syncBooleanLocalstorage } from "@/lib/utils.ts";
import dayjs from "dayjs";
import { now } from "@/pos/logic/time-provider.ts";
import { PosScreen } from "@/pos/PosRouter.ts";
import { dataLock } from "@/data/DataUtils.ts";
import { CONSTANT_VALUES } from "@/srm/lib/constants.ts";

export const posSettingsSignal = signal<Array<DocDeepSignal<PosSetting>>>([]);
export const posSettings0 = posSettingsSignal[0];
export const setPosSettings0 = posSettingsSignal[1];
export const posSetting0 = computed(() => posSettings0().length ? posSettings0()[0] : undefined);

export function extractBeginHour(beginHour: string = '06:00') {
  const [hour, minute] = beginHour.split(':')
  return parseInt(hour)
}

export const beginHour = computed<number>(() => extractBeginHour(posSetting0()?.generalSetting?.beginHour))
export const posSettingLock = new MultiAwaitLock(true)
export const generalSetting0 = computed<GeneralSetting>(() => posSettings0()[0]?.generalSetting!)
export const keyboardConfig0 = computed<KeyboardConfig>(() => posSettings0()[0]?.keyboardConfig!)
export const companyInfo0 = computed<CompanyInfo>(() => posSettings0()[0]?.companyInfo!)
export const printerGeneralSetting0 = computed<PrinterGeneralSetting>(() => posSettings0()[0]?.printerGeneralSetting!)
export const printOverMaster0 = computed<boolean>(() => !!posSetting0()?.printerGeneralSetting?.printOverMasterDevice)
export const srmSetting0 = computed<PosSrmSetting>(() => {
  const _posSettings = posSettings0()[0]
  if (!_posSettings.srm) _posSettings.srm = deepSignal({} as PosSrmSetting)
  return _posSettings.srm
})
export const isQuebecSrmEnabled = computed(() => posSetting0()?.generalSetting?.quebecSrmEnable ?? false)
export const isRunningTestcase = computed(() => isQuebecSrmEnabled() && posSetting0()?.srm?.testcaseNumber && posSetting0()?.srm?.testcaseNumber !== CONSTANT_VALUES.CASESSAI_EMPTY)
export const isAutoPrintSrmBill = computed(() => posSetting0()?.printerGeneralSetting?.autoPrintInvoiceBeforePaying)
export const isCashbookEnabled = computed(() => posSetting0()?.generalSetting?.cashbookEnable ?? false)
export const isAllowMultiplePayments = computed(() => generalSetting0()?.allowMultiplePayments ?? true)
export const posFeature0 = computed<PosFeature>(() => posSettings0()[0]?.posFeature!)
export const beginDate = computed<number>(() => {
  return dayjs(now()).subtract(beginHour(), 'hour').startOf('day').unix()
})
export const mainScreen = computed<PosScreen>(() => {
  posSetting0()
  if (dataLock._acquired) return (localStorage.getItem('mainScreen') as PosScreen) || PosScreen.FLOOR_PLAN
  const screen = posSetting0()?.generalSetting?.hasTablePlan ? PosScreen.FLOOR_PLAN : PosScreen.ORDER
  localStorage.setItem('mainScreen', screen)
  return screen
})
export const menuScrollbar = computed<boolean>(() => {
  if (!posSetting0()) return localStorage.getItem('menuScrollbar') === 'true'
  syncBooleanLocalstorage(posSetting0()?.menuScrollbar, 'menuScrollbar')
  return posSetting0()?.menuScrollbar === true
})
export const tempServerSignal = signal<string | undefined>(undefined)
export const tempServer = tempServerSignal[0]
export const setTempServer = tempServerSignal[1]