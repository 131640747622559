import type { RxCollection, RxDatabase, RxJsonSchema } from "rxdb";
import { createCollection } from "@/data/utils/migration.ts";

export const ONLINE_ORDER_PRODUCT_MAPPING_COLLECTION_NAME = 'online_order_product_mapping'

const VERSION = 1;

const schema: RxJsonSchema<OnlineOrderProductMapping> = {
  title: 'online_order_product_mapping schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    externalProductKey: { type: 'string', maxLength: 50 },
    original: { type: 'object' },
    productId: { type: 'string', maxLength: 50 },
    provider: { type: 'string', maxLength: 50 }
  }
}

export interface OnlineOrderProductMapping {
  _id: string
  externalProductKey: string
  original: any
  productId: string
  provider: string
}

export type TOnlineOrderProductMappingCollection = RxCollection<OnlineOrderProductMapping>

export const OnlineOrderProductMappingCollection = {} as TOnlineOrderProductMappingCollection;

export const createOnlineOrderProductMappingCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: OnlineOrderProductMappingCollection,
    collectionName: ONLINE_ORDER_PRODUCT_MAPPING_COLLECTION_NAME,
    version: VERSION,
    schema: schema,
  });
}


// @ts-ignore
window.OnlineOrderProductMapping = OnlineOrderProductMappingCollection;