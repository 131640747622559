import {type FunctionComponent, memo, useEffect} from "react";
import SyncMenuLeft from "./SyncMenuLeft";
import SyncMenuRight from "./SyncMenuRight";

const SyncOnlineMenuPlugin: FunctionComponent = () => {
  console.log('SyncOnlineMenuPlugin')
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div
      className="!opacity-100 w-full h-full flex flex-row items-start justify-start bg-cover bg-no-repeat bg-[top] [&.animate]:animate-[1s_ease_0s_1_normal_forwards_fade-in] opacity-[0]"
      data-animate-on-scroll
    >
      <SyncMenuLeft />
      <div className="no-scrollbar self-stretch bg-white-solid-white-100-ffffff w-[316px] overflow-y-auto shrink-0 flex flex-col items-start justify-start sm:w-[280px] Tablet_768:w-[260px]">
        <SyncMenuRight />
      </div>
    </div>
  );
};

export default memo(SyncOnlineMenuPlugin);
