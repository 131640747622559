import { type FunctionComponent, useState, useCallback } from "react";
import SyncOMenuConfirmationPopup from "./SyncOMenuConfirmationPopup";
import PortalPopup from "./PortalPopup";
import RightItemList from "./RightItemList";
import { importProductLayouts, isSelectingAll, onCompleteImport, onSelectAllProducts } from '@/react/SyncMenuView/SyncMenuView.tsx';
import { PosScreen, router } from '@/pos/PosRouter.ts';
import {LL0} from "@/react/core/I18nService.tsx";
import { makeRipple } from "@/react/core/ripple-utils";

const SyncMenuRight: FunctionComponent = () => {
  const [isSyncOMenuConfirmationPopupOpen, setSyncOMenuConfirmationPopupOpen] =
    useState(false);

  const openSyncOMenuConfirmationPopup = useCallback(() => {
    setSyncOMenuConfirmationPopupOpen(true);
  }, []);

  const closeSyncOMenuConfirmationPopup = useCallback(() => {
    setSyncOMenuConfirmationPopupOpen(false);
  }, []);

  return (
    <>
      <div className="self-stretch bg-[#ecedee] flex-1 bg-black-opacity-black-10-384352 flex flex-col items-end justify-start py-[13px] px-2.5 gap-[12px] z-[0] text-center text-mini text-white-solid-white-100-ffffff font-mulish">
        <div className="self-stretch flex flex-row items-start justify-between">
        <div className="shrink-0 flex flex-row items-center justify-start gap-[6px]">
            <button className="rounded-11xl bg-[#559ad1] h-[35px] flex flex-row items-center justify-center py-0 px-3.5 box-border"
               onClick={() => router.screen = PosScreen.EDIT_ONLINE_MENU}
            >
              <b className="relative">{LL0().ui.cancel()}</b>
            </button>
          { !isSelectingAll() ?
            <button className="!flex rounded-11xl bg-[#ef7cba] h-[35px] hidden flex-row items-center justify-center py-0 px-3 box-border"
              ref={makeRipple} onPointerDown={onSelectAllProducts}
            >
              <b className="relative">{LL0().syncMenu.selectAll()}</b>
            </button>
              :
            <button className="!flex rounded-11xl bg-[#9e5cfb] h-[35px] flex flex-row items-center justify-center py-0 px-3 box-border"
              ref={makeRipple} onPointerDown={onSelectAllProducts}
            >
              <b className="relative">{LL0().syncMenu.deselect()}</b>
            </button>
          }
          </div>
          <button
            ref={makeRipple}
            disabled={!importProductLayouts().length}
            className="disabled:opacity-30 disabled:cursor-not-allowed rounded-11xl bg-[#f7a03a] h-[35px] flex flex-row items-center justify-center py-0 px-[18px] box-border cursor-pointer"
            onClick={openSyncOMenuConfirmationPopup}
          >
            <b className="relative">{LL0().syncMenu.import()}</b>
          </button>
        </div>
        <RightItemList />

      </div>
      {isSyncOMenuConfirmationPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeSyncOMenuConfirmationPopup}
        >
          <SyncOMenuConfirmationPopup
            onClose={closeSyncOMenuConfirmationPopup}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default SyncMenuRight;
