import { captureException } from '@sentry/react'
import debug from 'debug'
import _ from 'lodash'
import Queue from 'queue'
import { toast } from 'react-toastify'
import type { KeyFunctionMap, MigrationStrategies, RxCollection, RxConflictHandler, RxDatabase, RxJsonSchema } from 'rxdb'

import { keepOldGenerator } from '@/data/DataUtils.ts'
import { patchCollection } from '@/lib/open-telemetry.ts'
import MultiAwaitLock from '@/shared/MultiAwaitLock.ts'

//todo: add queue for migration
const queue = new Queue({ concurrency: 1, autostart: true })

const log = debug('data:migration')

export const migrationPromise: Record<string, Promise<void>> = {}

export async function handleMigration<T, O>(col: RxCollection<T, O>) {
  const lock = new MultiAwaitLock(true)
  queue.push(async () => {
    if (await col.migrationNeeded()) {
      const toastId = toast(`${col.name} Migrating`)

      const migrationState = col.getMigrationState()

      migrationPromise[col.name] = new Promise(resolve => {
        migrationState.$.subscribe({
          next: state => {
            if (state.status === 'DONE') {
              resolve()
              lock.release()
            }
          },
        })
      })

      // 'start' the observable
      migrationState.$.subscribe({
        next: state => {
          if (state.status === 'DONE') {
            toast.update(toastId, {
              render: `${col.name} Migration done`,
              type: toast.TYPE.SUCCESS,
              autoClose: 500,
            })
          } else if (state.status === 'RUNNING') {
            toast.update(toastId, {
              render: `${col.name} Migration ${isNaN(state.count.percent) ? 100 : state.count.percent} %`,
              type: toast.TYPE.INFO,
            })
          } else {
            log(`Migration ${JSON.stringify(state.error)}`)
            toast.update(toastId, {
              render: `${col.name} Migration Error`,
              type: toast.TYPE.INFO,
            })
          }
        },
        error: error => {
          console.log(`Error when migrate ${col.name}`, error)
          log(`Migration ${JSON.stringify(error)}`)
          captureException(new Error(`Error when migrate ${col.name}`, error))
        },
      })

      col.startMigration(100).then()
    } else {
      lock.release().then()
    }
  })
  await lock.acquireAsync()
}

const SchemaVersionFactory = (collectionName: string, staticV: number) => {
  function getKey(name: string) {
    return `collection-${name}-version`
  }

  function getVersion() {
    const localV = localStorage.getItem(getKey(collectionName))
    if (!localV || parseInt(localV) < staticV) {
      localStorage.setItem(getKey(collectionName), staticV.toString())
      return staticV
    }
    return parseInt(localV)
  }

  function increaseVersion() {
    const localV = localStorage.getItem(getKey(collectionName))
    const parseLocalV = parseInt(localV || '1')
    if (parseLocalV > staticV) return parseLocalV
    localStorage.setItem(getKey(collectionName), (staticV + 1).toString())
    return staticV + 1
  }

  return {
    getVersion,
    increaseVersion,
  }
}

export async function createCollection<T, V, O>(options: {
  database: RxDatabase<V>
  collection: RxCollection<T, O>
  collectionName: string
  version: number
  schema: RxJsonSchema<T>
  extraStrategy?: MigrationStrategies
  methods?: KeyFunctionMap
  conflictHandler?: RxConflictHandler<T>
}): Promise<RxCollection<T, O>> {
  const { database, collectionName, version, collection, schema, extraStrategy, methods, conflictHandler } = options
  const schemaVersionFactory = SchemaVersionFactory(collectionName, version)
  const _version = schemaVersionFactory.getVersion()
  try {
    return await addCollection(_version, schema)
  } catch {
    captureException(new Error(`Failed to add collection ${collectionName} version is ${version}`))
    const newVersion = schemaVersionFactory.increaseVersion()
    _.assign(schema, { version: newVersion })
    return await addCollection(newVersion, schema)
  }

  async function addCollection(version: number, schema: RxJsonSchema<T>): Promise<RxCollection<T, O>> {
    const db = await database.addCollections({
      [collectionName]: {
        autoMigrate: false,
        schema: { ...schema, version },
        migrationStrategies: {
          ...keepOldGenerator(version, extraStrategy),
        },
        methods: methods,
        conflictHandler: conflictHandler,
      },
    })
    patchCollection(db[collectionName])
    Object.setPrototypeOf(collection, db[collectionName])
    await handleMigration<T, O>(collection)
    return db[collectionName] as RxCollection<T, O>
  }
}
