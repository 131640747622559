import uuid from 'time-uuid'

import type { Order } from '@/data/Order'
import { OrderStatus } from '@/pos/OrderType'

import { srmTransactionLogic } from '../transaction.logic'
import { execTestcase, getCaTaxInfo } from './libs'

type O = Readonly<Order>

const getBaseOrder = (): O => {
  const taxInfo = getCaTaxInfo()
  return {
    _id: '',
    status: OrderStatus.COMPLETED,
    payments: [{ type: 'cash', value: 0 }],
    items: [
      // Main Course
      { name: 'Bouillabaisse', price: 22, quantity: 2, modifiers: [], ...taxInfo },
      // Drink
      { name: 'Bordeaux', price: 18, quantity: 2, modifiers: [], ...taxInfo },
      // Dessert
      { name: 'Crème Brûlée', price: 6.5, quantity: 1, modifiers: [], ...taxInfo },
      { name: 'Tarte Tatin', price: 8, quantity: 1, modifiers: [], ...taxInfo },
    ],
  }
}

export const testRunner011: Record<string, () => Promise<boolean>> = {
  '011.001': () =>
    execTestcase({
      num: 11,
      step: 1,
      device: 'A',
      run: async () => {
        const order = { ...getBaseOrder(), _id: uuid(), tip: 5 }
        await srmTransactionLogic.recordClosingReceipt(order, { print: true })
      },
    }),
  '011.002': () =>
    execTestcase({
      num: 11,
      step: 2,
      device: 'A',
      run: async () => {
        const order = { ...getBaseOrder(), _id: uuid(), serviceFee: 5 }
        await srmTransactionLogic.recordClosingReceipt(order, { print: true })
      },
    }),
}
