import type { FunctionComponent } from "react";
import { payment0 } from '@/react/PaymentSettingView/PaymentSettingView.tsx';
import _ from 'lodash';
import { LL0 } from '@/react/core/I18nService.tsx'
import { getPaymentIcon, type PaymentMap } from "@/react/PaymentSettingView/PaymentMap"

export type TaxPaymentPaymentIconItemType = {
  model: typeof PaymentMap[number]
};

const TaxPaymentPaymentIconItem: FunctionComponent<
  TaxPaymentPaymentIconItemType
> = ({ model }) => {
  return (
    <div className="h-10 flex flex-row items-center justify-between text-left text-lg text-black font-mulish self-stretch">
      <label className="flex flex-row items-center justify-start gap-[0px_12px]">
        <input
          className="cursor-pointer relative w-5 h-5"
          type="radio"
          name="icon"
          checked={payment0()?.icon === model.icon}
          onChange={e => e.target.checked && _.assign(payment0(), { icon: model.icon })}
        />
        <div className="relative">{_.get(LL0().payment, model.icon as string)?.() || model.name}</div>
      </label>
      <img
        className="relative w-8 h-8 object-contain"
        alt={model.icon}
        src={getPaymentIcon(model.icon)}
      />
    </div>
  );
};

export default TaxPaymentPaymentIconItem;
