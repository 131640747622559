import type { FunctionComponent } from "react";
import SetHourItem from "./SetHourItem";
import { LL0 } from "@/react/core/I18nService.tsx";
import {addOpenHour, openHours0} from '@/data/OpenHourHub.ts';

export type OnlineProviderSettingsOpenType = {
  className?: string;
  onClose?: () => void;
};

const OnlineProviderSettingsOpen: FunctionComponent<
  OnlineProviderSettingsOpenType
> = ({ className = "", onClose }) => {
  return (
    <div
      className={`w-full relative rounded bg-white-solid-white-100-ffffff flex flex-col items-start justify-start py-2.5 px-3 box-border gap-[10px] min-w-[580px] min-h-[310px] h-full max-w-full max-h-full overflow-auto text-left text-base text-black-solid-black-900-1e1e23 font-mulish-button-name-bold Tablet_768:h-full Tablet_768:w-full Tablet_768:min-w-[550px] ${className}`}
    >
      <div className="self-stretch flex flex-row items-start justify-between z-[2]">
        <b className="flex-1 relative">{LL0().onlineProdiverSetting.openDeliveryHours()}</b>
        <div className="flex flex-row items-start justify-start gap-[32px] text-mini text-blue-solid-blue-500-0051c1">
          <div className="relative [text-decoration:underline] font-semibold sm:text-sm Tablet_600:text-smi"
            onClick={addOpenHour}>
            +{LL0().editOnlineMenu.addNew()}
          </div>
          <img
            className="w-[21px] relative h-[21px] object-cover cursor-pointer"
            alt=""
            src="/iconclose-popup-icon@2x.png"
            onClick={onClose}
          />
        </div>
      </div>
      <div className="self-stretch overflow-y-auto flex flex-col items-center justify-start gap-[8px] z-[1]">
        {openHours0().map((oh) => <SetHourItem key={oh._id} oh={oh}/>)}
      </div>
      <div className="self-stretch flex flex-row items-center justify-start gap-[8px] z-[0] text-sm text-black-solid-black-880-1d1d26">
        <label className="cursor-pointer h-[18px] flex-1 relative font-medium inline-block mq1440:text-base">
          {LL0().onlineProdiverSetting.noteHours()}
        </label>
      </div>
    </div>
  );
};

export default OnlineProviderSettingsOpen;