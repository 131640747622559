import { type FunctionComponent, useEffect } from 'react'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, } from '@mui/material'
import {
  handleInputPopup,
  product0,
  PRODUCT_COLORS,
  productLayout0,
  pushData
} from '@/react/EditMenuView/EditMenuView.tsx'
import { LL0 } from '@/react/core/I18nService.tsx'
import _ from 'lodash'
import { AccessPort, getAccessibility } from '@/react/DecentralizationPort.ts'
import Input3KeyboardPopUp from '@/react/core/Input3KeyboardPopUp2.tsx'
import { Keyboard } from '@mui/icons-material'
import Input from '@/react/core/Input.tsx'
import PortalPopup from "@edit-menu/Popups/PortalPopup.tsx";
import EditMenuProviderMenuName from "@edit-menu/EditMenuProviderMenuName.tsx";
import {
  closeEditMenuProviderMenuName,
  dineIn,
  handleSelectMenu,
  isEditMenuProviderMenuNameOpen,
  menuSelected,
  multipleMenus0,
  openEditMenuProviderMenuName,
  pricingLevelFactory,
  setMenuSelected
} from "@/react/EditMenuView/EditPricingLevel.logic.ts";
import TextField from "@/react/core/TextField.tsx";


const EditModifierForm: FunctionComponent = () => {
  const {computedPrice,
    updateMenuSelection,
    changeProductPrice,
    getInfo,} = pricingLevelFactory()

  const {multipleEnable} = getInfo()

  useEffect(() => {
    setMenuSelected(dineIn() as string);
  }, [productLayout0()])

  return (
    <div
      className="!flex self-stretch hidden flex-col items-start justify-start py-4 px-3 gap-[16px] text-left text-sm text-blue-solid-blue-500-0051c1 font-mulish">
      { multipleEnable && (
        <div className="self-stretch flex flex-row items-center justify-start gap-[10px]">
          <b className="w-[50px] relative inline-block shrink-0 md:text-xs md:w-[43px]">
            {LL0().editMenu.menu()}:
          </b>
          <div className="flex-1 flex flex-row items-center justify-start gap-[10px]">
            <FormControl
              className="flex-1 font-mulish text-sm text-black-solid-black-600-424242"
              variant="outlined"
            >
              <InputLabel color="primary"/>
              <Select
                color="primary"
                size="small"
                value={menuSelected() || dineIn()}
                onChange={(e) => {
                  updateMenuSelection(e);
                }}
              >
                {multipleMenus0()?.map((menu, index) => (
                    <MenuItem value= {menu._id} key = {index}> {menu.name} </MenuItem>
                  )
                )}
              </Select>
              <FormHelperText/>
            </FormControl>
            <div
              className="h-[36.7px] self-stretch rounded [background:linear-gradient(180deg,_#82e1ff,_#2aacf5)] overflow-hidden flex flex-row items-center justify-start py-2.5 px-[5px] cursor-pointer"
              onClick={openEditMenuProviderMenuName}
            >
              <img
                className="w-[16.4px] relative h-[16.4px] object-cover"
                alt=""
                src="/iconeditmenuaddmenu@2x.png"
              />
            </div>
          </div>
        </div>
      )}
      <div className="self-stretch flex flex-row items-center justify-start gap-[12px]">
        <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">
          {LL0().article.type()}:
        </b>
        <FormControl
          className="flex-1 font-mulish text-sm text-black-solid-black-600-424242"
          variant="outlined"
        >
          <InputLabel color="primary"/>
          <Select color="primary" size="small"
                  value={productLayout0()?.type || 'Text'}
                  onChange={(e) => {
                    const _productLayout = productLayout0()
                    if (_productLayout) _productLayout.type = e.target.value as any
                  }}
          >
            <MenuItem value="Text">{LL0().article.types.titleName()}</MenuItem>
            <MenuItem value="Article">{LL0().article.types.normalItem()}</MenuItem>
            <MenuItem value="NonFixed">{LL0().article.types.nonFixedItem()}</MenuItem>
            <MenuItem value="Extra">{LL0().article.types.extra()}</MenuItem>
          </Select>
          <FormHelperText/>
        </FormControl>
      </div>

      <Input3KeyboardPopUp
        value={''}
        onSave={(name, id, price) => {
          if (!product0()) return;
          product0()!.name = name
          product0()!.id = id
          if (multipleEnable && menuSelected() !== dineIn()) {
            product0()!.menuPrices!.find(m => m.menu === menuSelected())!.value = parseFloat(price || '0')
          } else {
            product0()!.price = parseFloat(price || '0')
          }
        }}>
        {(show, setShow, inputControllers) => (
          <>
            <div className="self-stretch flex flex-row items-center justify-start gap-[12px] text-sm">
              <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">
                <span>{LL0().article.name()}</span>
                <span className="text-firebrick">*</span>
                <span>:</span>
              </b>
              <TextField
                className="pl-2 flex justify-center overflow-hidden w-full font-mulish text-sm text-black-solid-black-600-424242 md:box-border"
                value={product0()?.name || ''}
                onChange={(e) => {
                  const _product = product0()
                  if (_product) _product.name = e.target.value
                }}
                onKeyboardIconClick={() => {
                  handleInputPopup(setShow, inputControllers, pushData, 0)
                }}
              />
            </div>
            <div className="self-stretch flex flex-row items-center justify-start gap-[12px]">
              <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">
                {LL0().article.id()}:
              </b>
              <TextField
                className="pl-2 flex justify-center overflow-hidden w-full font-mulish text-sm text-black-solid-black-600-424242 md:box-border"
                value={product0()?.id || ''}
                onChange={(e) => {
                  const _product = product0()
                  if (_product) _product.id = e.target.value
                }}
                onKeyboardIconClick={() => {
                  handleInputPopup(setShow, inputControllers, pushData, 1)
                }}
              />
            </div>
            <div className="self-stretch flex flex-row items-center justify-start gap-[12px] text-sm">
              <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">
                <span>{LL0().article.price()}</span>
              </b>
              <TextField
                className="pl-2 flex justify-center overflow-hidden w-full font-mulish text-sm text-black-solid-black-600-424242 md:box-border"
                value={computedPrice() || ''}
                onChange={(e) => {
                  changeProductPrice(e.target.value)
                }}
                onKeyboardIconClick={() => {
                  if (!getAccessibility(AccessPort.EDIT_ITEM_PRICE)) return;
                  handleInputPopup(setShow, inputControllers, pushData, 2)
                }}
              />
            </div>
          </>
        )}
      </Input3KeyboardPopUp>
      <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
        <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">
          {LL0().ui.color()}:
        </b>
        <div className="flex-1 flex flex-row flex-wrap items-start justify-start gap-[12px]">
          {PRODUCT_COLORS.map((color, index) =>
            <div className="rounded-981xl flex flex-row flex-wrap items-start justify-start relative"
                 key={index}
                 style={{ backgroundColor: color }}
                 onClick={() => {
                   _.assign(productLayout0(), { color })
                 }}
            >
              <div
                className="relative rounded-981xl box-border w-6 h-6 z-[0] border-[1px] border-solid border-gray-solid-gray-320-d2d2d2"/>
              {productLayout0()?.color === color &&
                <>
                  <div
                    className="absolute my-0 mx-[!important] top-[-0.1px] left-[0px] rounded-981xl box-border w-6 h-6 z-[1] border-[1px] border-solid border-blue-500"/>
                  <img
                    className="absolute my-0 mx-[!important] top-[0.1px] right-[-4px] w-3 h-3 object-contain z-[2]"
                    alt=""
                    src="/iconchecked-icon1@2x.png"
                  />
                </>
              }
            </div>
          )}
        </div>
      </div>
      {/*<div className="self-stretch flex flex-col items-start justify-start gap-[10px] text-mini">*/}
      {/*  <div*/}
      {/*    className="flex flex-row items-center justify-start gap-[4px] cursor-pointer"*/}
      {/*    onClick={() => setEditorMode0(ITEM_MODE.INGREDIENT)}*/}
      {/*  >*/}
      {/*    <img*/}
      {/*      className="relative w-6 h-6 object-cover sm:w-[22px] sm:h-[22px] Tablet_600:w-5 Tablet_600:h-5"*/}
      {/*      alt=""*/}
      {/*      src="/iconshift-icon@2x.png"*/}
      {/*    />*/}
      {/*    <b className="relative [text-decoration:underline] sm:text-sm Tablet_600:text-smi">{`Ingredients: `}</b>*/}
      {/*  </div>*/}
      {/*  <div className="self-stretch flex flex-row flex-wrap items-start justify-start text-black-solid-black-880-1d1d26">*/}
      {/*    <div className="rounded-10xs bg-white-solid-white-100-ffffff shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-6 flex flex-col items-center justify-center py-0 px-3 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1">*/}
      {/*      <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />*/}
      {/*      <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-gray-solid-gray-150-eeeeee box-border z-[1] border-[2px] border-solid border-gray-solid-gray-150-eeeeee" />*/}
      {/*      <div className="relative z-[2]">Chicken: 100g</div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {isEditMenuProviderMenuNameOpen() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeEditMenuProviderMenuName}
        >
          <EditMenuProviderMenuName onClose={closeEditMenuProviderMenuName}/>
        </PortalPopup>
      )}
    </div>
  )
}

export default EditModifierForm