import type { FunctionComponent } from "react";
import TipServiceSection from "./TipServiceSection";
import PaymentMethodsSection from "./PaymentMethodsSection";
import TerminalSection from "./TerminalSection";
import { LL0 } from '@/react/core/I18nService.tsx';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { router } from '@/pos/PosRouter.ts';
import { mainScreen } from "@/data/PosSettingsSignal.ts";

const TaxPaymentRightPath: FunctionComponent = () => {
  return (
    <div className="self-stretch flex-1 flex flex-col items-center justify-start text-left text-mini text-gray-solid-gray-850-767676 font-mulish">
      <div className="self-stretch bg-gray-solid-gray-130-f4f4f4 [backdrop-filter:blur(8px)] h-[50px] flex flex-row items-center justify-between py-0 pr-2 pl-6 box-border sm:h-[42px] Tablet_768:pl-4 Tablet_768:box-border Tablet_600:pl-2 Tablet_600:box-border height_414:h-[42px]">
        <b className="relative sm:text-sm height_414:text-sm">{LL0().order.payment().toUpperCase()}:</b>
        <div className="rounded-81xl bg-blue-solid-blue-450-1271ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-9 overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[0px_3px] min-w-[72px] text-center text-sm text-white-solid-white-100-ffffff sm:h-[30px] sm:min-w-[68px] Tablet_600:h-[26px] Tablet_600:pl-2.5 Tablet_600:box-border Tablet_600:min-w-[64px]"
             ref={makeRipple}
             onClick={() => router.screen = mainScreen()}
        >
          <img
            className="w-[30px] relative h-[30px] object-cover"
            alt=""
            src="/iconback-icon@2x.png"
          />
          <b className="w-[45px] relative leading-[17px] hidden">Button</b>
        </div>
      </div>
      <div className="no-scrollbar self-stretch flex-1 overflow-y-auto flex flex-col items-center justify-start pt-4 px-5 pb-0 gap-[16px_0px] text-sm text-gray Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border Tablet_600:pl-2 Tablet_600:pr-2 Tablet_600:box-border">
        <TipServiceSection />
        <PaymentMethodsSection />
        <TerminalSection />
      </div>
    </div>
  );
};

export default TaxPaymentRightPath;
