import dayjs from 'dayjs'
import {useComputed} from "@/react/core/reactive";
import React, {type FunctionComponent} from "react";
import Notice from "./Notice";
import OrdersInfo from "./OrdersInfo";
import OrderList from './OrderList';
import PendingOrdersOrderTime from "./PendingOrdersOrderTime";
import {DeliveryService, OrderStatus, OrderType, type TOrder} from "@/pos/OrderType";
import {now, smartFormatShortDateTime} from "@/shared/dateTimeUtils";
import {LL0} from "@/react/core/I18nService.tsx";
import _ from "lodash";
import {monthKeyMap} from "@pending-order/PendingOrdersPlugin.tsx";

import { companyInfo0 } from "@/data/PosSettingsSignal.ts";

type PendingOrderAtRightType = {
  order: TOrder,
  onShowOrderDetail: Function
};

const PendingOrderAtRight: FunctionComponent<PendingOrderAtRightType> = ({
  order,
  onShowOrderDetail
}) => {
  const isPickupOrder = order.type === OrderType.PickUp
  const isDeliveryOrder = order.type === OrderType.Delivery

  const showReady = order.status === OrderStatus.READY
  const showRunningOutOfTime = useComputed(() => dayjs(order.pickupDate).diff(dayjs(now()), 'minute') < 10)
  const deliveryIsOnTheWay = false
  const delivered = false

  const showNoticeText = showReady || showRunningOutOfTime()
  const noticeText = showReady ? LL0().pendingOrder.orderReady() : LL0().pendingOrder.orderOutOfTime();

  const customerAddress = order.customerRaw?.address
  const orderTime = useComputed(() => {
    if (order.pickupDate === 'asap') return 'asap'
    if (companyInfo0()?.backendLanguage === 'de')
      return smartFormatShortDateTime(order.pickupDate) || ''
    return smartFormatShortDateTime(order.pickupDate, 'hh:mm A') || ''
  })

  const convertTime = (time:string) => {
    const timeArr = time.split(' ')
    if (timeArr.length > 1) {
      return `${_.get(LL0().dateTime.monthShort , monthKeyMap[timeArr[0].split('-')[0]])?.()}-${timeArr[0].split('-')[1]} ${timeArr[1]}`
    } else {
      return time
    }
  }
  // console.log('convertTime', convertTime('Jan-01 17:34'))
  return (
    <div className="rounded flex flex-col items-start justify-start" onClick={() => onShowOrderDetail()}>
      <div className="self-stretch rounded bg-white flex flex-col items-start justify-start py-3 px-4 relative gap-[6px]">
        {showRunningOutOfTime() && (
          <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded bg-white shadow-[-2px_-2px_6px_rgba(255,_0,_0,_0.32)_inset,_3px_2px_6px_rgba(255,_0,_0,_0.32)_inset] z-[0]" />
        )}
        {showReady && (
          <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded bg-white shadow-[-2px_-2px_6px_rgba(31,_178,_94,_0.55)_inset,_3px_2px_6px_rgba(31,_178,_94,_0.55)_inset] z-[1]" />
        )}
        {deliveryIsOnTheWay && <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded bg-white shadow-[-2px_-2px_6px_rgba(255,_138,_0,_0.55)_inset,_3px_2px_6px_rgba(255,_138,_0,_0.55)_inset] z-[2]" />}
        {delivered && <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded bg-white shadow-[-2px_-2px_6px_rgba(144,_75,_255,_0.55)_inset,_3px_2px_6px_rgba(144,_75,_255,_0.55)_inset] z-[3]" />}
        <Notice
          showNotice={showNoticeText}
          noticeOrderIsReady={showReady}
          deliveryIsOnTheWay={deliveryIsOnTheWay}
          delivered={delivered}
          noticeText={noticeText}
        />
        <OrdersInfo order={order} showOrderTypeIcon={false}/>
        {isDeliveryOrder && (<>
            {
              customerAddress ? <div className="self-stretch flex flex-row items-start justify-start text-black z-[8]">
                <img
                  className="relative w-[18px] h-[18px]"
                  alt=""
                  src="/pin-icon.svg"
                />
                <div className="flex-1 relative leading-[117.4%] sm:text-smi">
                  {customerAddress}
                </div>
              </div> : null
            }
            {
              order?.shippingData?.comment && <div className="text-black z-[1]">
                {LL0().onlineOrder.deliveryNote()}: {order?.shippingData?.comment}
              </div>
            }
            {
              order?.shippingData?.service && <div className="text-black z-[1]">
                {LL0().onlineOrder.deliveryService()}: {order?.shippingData?.service === DeliveryService.PIKADRIVE ? 'inhouse' : order?.shippingData?.service}
              </div>
            }
          </>
        )}
        {order.note && <div className="self-stretch flex flex-row items-start justify-start gap-[12px] text-black z-[1]">
          {order.note}
        </div>}
        <OrderList {...order} showPrice={false} showIcon/>
        <div className="my-0 mx-[!important] absolute top-[7px] right-[7px] flex flex-row items-center justify-end gap-[10px] z-[8]">
          <img
            className="hidden relative w-6 h-6 object-cover sm:w-[22px] sm:h-[22px] Tablet_600:w-5 Tablet_600:h-5"
            alt=""
            src="/iconshift-icon1@2x.png"
          />
          <PendingOrdersOrderTime
            showTakeawayIcon={isPickupOrder}
            showDeliverIcon={isDeliveryOrder}
            orderTime={orderTime()}
          />
        </div>
      </div>
    </div>
  );
};

export default PendingOrderAtRight;
