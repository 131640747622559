import type { FunctionComponent } from "react";

export type CashbookReprintConfirmType = {
  className?: string;
  onClose?: () => void;
};

const CashbookReprintConfirm: FunctionComponent<CashbookReprintConfirmType> = ({
  className = "",
  onClose,
}) => {
  return (
    <div
      className={`w-[428px] relative rounded-10xs bg-white-solid-white-100-ffffff flex flex-col items-center justify-center pt-[19px] px-[45px] pb-[26px] box-border gap-4 max-w-full max-h-full overflow-auto text-center text-xl text-black-solid-black-880-1d1d26 font-mulish ${className}`}
    >
      <div className="self-stretch flex flex-col items-center justify-center gap-0.5">
        <b className="relative leading-[28px]">Reprint</b>
        <div className="self-stretch relative text-base leading-[28px] font-medium">
          The closing report will be printed again. Please confirm the action.
        </div>
      </div>
      <div className="w-[283px] flex flex-row items-center justify-center gap-3 text-mini text-gray-solid-gray-600-979797">
        <div
          className="w-32 rounded-md border-gray-solid-gray-600-979797 border-[1px] border-solid box-border h-9 flex flex-row items-center justify-center p-2.5 cursor-pointer"
          onClick={onClose}
        >
          <b className="relative">Cancel</b>
        </div>
        <div className="w-32 rounded-md border-blue-solid-blue-420-2979ff border-[1px] border-solid box-border h-9 flex flex-row items-center justify-center p-2.5 text-blue-solid-blue-500-0051c1">
          <b className="relative">OK</b>
        </div>
      </div>
    </div>
  );
};

export default CashbookReprintConfirm;