import debug from 'debug'

import { taxCategories0 } from '@/data/TaxCategoryHub'
import type { TOrder } from '@/pos/OrderType'
import { roundNumber } from '@/shared/order/order-config'

const log = debug('dev:srm-fix')

/**
 * Fix discount.
 *
 * This will convert the discount value to back percentage, so it can be recalculated later.
 */
export function recalculateDiscount(order: TOrder) {
  if (order.discount) {
    const { discount = 0 } = order
    const total = Number(order.metadata?.subTotal) // subTotal is the total before tax & discount
    if (typeof discount === 'number' && discount > 0) {
      const discountPercent = roundNumber((discount / total) * 100)
      order.discount = `${discountPercent}%`
    } else {
      log('⚠️ cannot fix discount', discount)
    }
  }
}

/**
 * Fix tax. Add default tax components for Canada-Quebec. Will modify the order in place.
 */
export function applyQuebecTaxComponents(order: TOrder) {
  const itemsWithoutTaxComponent = order.items.filter(item => !item.taxComponents)
  if (!itemsWithoutTaxComponent.length) {
    log('ℹ️ All items already have tax components')
    return
  }
  log(`🔍 Found ${itemsWithoutTaxComponent.length} items without tax components`)
  const defaultTax = taxCategories0().find(tax => tax.type === 'combo' && tax.name === 'Quebec')
  if (!defaultTax) {
    log('⚠️ Cannot find default tax for Quebec')
    return
  }
  for (const item of itemsWithoutTaxComponent) {
    log(`🔧 Adding tax components to ${item.name}`)
    item.taxComponents = defaultTax.components
    item.taxComponents2 = defaultTax.components
  }
  log(`✅ Tax components added to ${itemsWithoutTaxComponent.length} items`)
  delete order.taxTotal // remove taxTotal to recalculate later
}

/** Check and fix payment. If it differs by 0.01, then it's okay. */
export function checkPaymentConsistency(order: TOrder) {
  const payment = order.payments[0]
  const total = order.vSum
  if (payment && total) {
    const diff = Math.abs(payment.value - total)
    if (diff === 0) {
      log('✅ Payment value matched')
    } else if (diff <= 0.01) {
      log(`ℹ️ Payment value differ by ${diff}: ${payment.value} vs ${total}`)
    } else {
      log(`⚠️ Payment value not matched: ${payment.value} vs ${total}`)
    }
  }
}
