import type { FunctionComponent } from "react";
import ManuelTableFloorButton from "@dashboard/ManuelTableFloorButton";
import {busyRoomMapper, rooms0} from '@/react/FloorPlanView/FloorPlanView.tsx';
import {LL0} from "@/react/core/I18nService.tsx";

const Rooms: FunctionComponent = () => {
  const LL= LL0()
  return (
    <div className="self-stretch bg-whitesmoke-300 flex flex-col items-center justify-center py-2 px-1">
      <div className="self-stretch relative grid grid-cols-[repeat(2,1fr)] [grid-row-gap:4px] [grid-column-gap:4px]">
        {rooms0().map((room, index) =>
          <ManuelTableFloorButton
            floorText={room.name}
            showSelected={false}
            showRedDot={!!busyRoomMapper()?.[room._id]}
            manuelTableFloorButtonHeight="40px"
            manuelTableFloorButtonMinWidth="0px"
            key={index}
            _id={room._id}
        />)}
        {!rooms0().length &&
        <div className="font-semibold font-mulish col-span-2 text-center">{LL.dashboard.noRoomShow()}</div>
        }
      </div>
    </div>
  );
};

export default Rooms;
