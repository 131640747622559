import type { ExtractDocumentTypeFromTypedRxJsonSchema, RxCollection, RxDatabase, RxJsonSchema } from 'rxdb'
import { createCollection } from "@/data/utils/migration.ts";

export const CUSTOMER_COLLECTION_NAME = 'customers'

export const VERSION = 11

const customerSchema = {
  title: 'customer schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    id: {type: "integer", multipleOf: 1, minimum: 0, maximum: 10 ** 10},
    email: {type: 'string', maxLength: 24},
    phoneNumber: {type: 'string', maxLength: 24},
    name: { type: 'string', maxLength: 64 },
    note: { type: 'string', maxLength: 64 },
    defaultAddressIndex: {type: 'number'},
    addresses: {
      type: "array",
      items: { type: 'object',
        properties: {
          street: { type: 'string', maxLength: 64 },
          home: { type: 'string', maxLength: 24 },
          placeId: { type: 'string', maxLength: 315 },
          zipcode: { type: 'string', maxLength: 20 },
          city: {type: 'string', maxLength: 24},
        }
      }
    },
    taxNo: {type: 'string'},
    company: {type: 'string'},
    ustId: {type: 'string'},
    cardNo: {type: 'string'},
  },
  indexes: ['id', 'name'],
  required: ['_id'],
} as const satisfies RxJsonSchema<any>

export type Customer = ExtractDocumentTypeFromTypedRxJsonSchema<typeof customerSchema> & {
  numberOfOrders?: number;
  spending?: number;
}

export const Customer = {} as RxCollection<Customer>

export const createCustomerCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: Customer,
    collectionName: CUSTOMER_COLLECTION_NAME,
    version: VERSION,
    schema: customerSchema,
  });
}
