import type { Order } from '@/data/Order';
import _, { capitalize } from 'lodash';
import { invoiceGroupPrinters0 } from '@/data/GroupPrinterHub';
import dayjs from 'dayjs';
import { CommitAction, InvoiceTypes, MarketPlaceProvider, OrderStatus } from '@/pos/OrderType';
import type { ColItemMetaData, TableColumnData } from '@/shared/printer/pure-image-printer';
import { taxCategories0 } from '@/data/TaxCategoryHub';
import { getInvoicePrinter, getMappedPrinter } from '@/react/Printer/print-utils.ts';
import uuid from 'time-uuid';
import { getLL2, LL2 } from "@/react/core/I18nBackend.tsx";
import { onPrintInvoiceComplete } from "@/react/PaymentView/PaymentView.tsx";
import { extractQr } from "@/tse/makeProcessDataLib.ts";
import { tseConfig0 } from "@/data/TseConfigHub.ts";
import { isValidSrmUser } from '@/data/UserHub';
import { toast } from 'react-toastify';
import { openCashDrawer } from "@/pos/cashdrawer.ts";
import { captureException } from "@sentry/react";
import { srmTransactionLogic } from '@/srm/transaction.logic';
import { TxLogCollection } from '@/data/TxLog';
import { maskCardNumber } from '../utils/text';
import { shouldWarnBecauseOffline } from "@/data/ReplicateEffect.ts";
import { VPrinter } from "@/react/Printer/VPrinter.ts";
import { logo0 } from "@/data/ImageHub.ts";
import {
  companyInfo0,
  isQuebecSrmEnabled,
  posSetting0,
  posSettings0,
  printOverMaster0
} from "@/data/PosSettingsSignal.ts";

import { findCustomerByCompanyName } from '@/react/CustomerInfoView/customer-utils.ts'
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { setCanGoBack } from '../OrderView/OrderView'
import { PrintScripts } from '@/data/PrintScripts'
import type { PrinterAddress, ScriptedRaster } from '@/shared/printer/types'
import { invoiceFLow } from "@/shared/logger.ts";
import { deviceSetting0 } from '@/data/DeviceSettingSignal'
import { LL0 } from "@/react/core/I18nService.tsx";


export async function handlePrintInvoice(order: Order, invoiceType: InvoiceTypes = InvoiceTypes.INVOICE) {
  if (shouldWarnBecauseOffline()) return toast.error('Master is not available', {autoClose: 2000})
  if(isQuebecSrmEnabled() && !isValidSrmUser())
    return toast.error(LL0().srm.errors.unauthorized())

  if (printOverMaster0()) {
    await onPrintInvoiceComplete({ invoiceType })
  } else {
    await printInvoice(order, invoiceType)
  }
}

export async function printInvoice(order: Order, invoiceType: InvoiceTypes = InvoiceTypes.INVOICE, parentOrder?: Order) {
  invoiceFLow('print Invoice', {orderId: order._id});
  if (isQuebecSrmEnabled() && !isValidSrmUser()) return toast.error(LL0().srm.errors.unauthorized())

    //todo: save in virtual printer
  if (!order._id) return;
  // order.items = order.items.filter(item => item?.quantity !== 0);

  if (isQuebecSrmEnabled()) {
    switch (invoiceType) {
      case InvoiceTypes.GUEST_CHECK:
        order.commits?.push({ action: CommitAction.PRINT_UNPAID_BILL })
        if (await srmTransactionLogic.shouldPrintReproduce(order)) await srmTransactionLogic.reproduceBill(order)
        else await srmTransactionLogic.recordTemporaryBill(order, { print: true, parentOrder })
        setCanGoBack(false) // Prevent user to cancel, so the srm_transaction can be saved
        return
      case InvoiceTypes.INVOICE:
        await srmTransactionLogic.recordClosingReceipt(order, { print: true })
        order.printInvoice = true
        return
    }
  }

  const raster = await getPrintInvoiceRaster(order, invoiceType);
  if (!raster) throw new Error(`Failed to generate raster invoice for order ${order._id}!`);

  await printInvoiceFromRaster(raster, { metadata: { orderId: order._id, invoiceType } });
  if (invoiceType === InvoiceTypes.RED_INVOICE || invoiceType === InvoiceTypes.INVOICE) {
    order.printInvoice = true;
  }
}

interface PrintInvoiceOptions {
  /** Skip open cash drawer */
  skipOpenCashDrawer?: boolean
  printer?: PrinterAddress
  metadata?: {
    orderId?: string
    invoiceType?: InvoiceTypes
    /** Other options will be saved as metadata */
    [key:string]: unknown
  }
}

/**
 * Print an invoice  save data to PrintImage using raster.
 * 
 * Will also open cash drawer, unless `skipOpenCashDrawer` is set to true.
 */
export const printInvoiceFromRaster = async (raster: ScriptedRaster, opt: PrintInvoiceOptions = {}): Promise<void> => {
  const { skipOpenCashDrawer, printer, metadata } = opt
  const invoicePrinter = printer || getInvoicePrinter();
  if (!invoicePrinter) return;
  if (!raster) return;
  const imagePrinter = new VPrinter(invoicePrinter);
  await imagePrinter.printRaster(raster);

  if (!skipOpenCashDrawer && deviceSetting0()?.autoOpenCashDrawer) {
    try {
      if (invoicePrinter.printerType !== 'integrate') {
        await imagePrinter.openCashDrawer();
      } else {
        await openCashDrawer();
      }
    } catch (e) {
      captureException(e);
    }
  }
  await imagePrinter.print(true, false, false);

    // Save printed image as scripts
    setTimeout(async () => {
      await PrintScripts.insert({
        _id: uuid(),
        date: dayjs().unix(),
        scripts: _.cloneDeep(raster.scripts),
        metadata: _.cloneDeep(metadata)
      })
    }, 500)
}

//them dau vao -> if else -> in theo dau vao
//cat ra thanh 3 ham, truyen dau vao

//kien truc phan mem
//class
//factory

/**
 * Create raster bitmap for invoice
 * @param order
 * @param invoiceType
 */
export async function getPrintInvoiceRaster(order: Order, invoiceType: InvoiceTypes): Promise<ScriptedRaster | undefined> {
  const provider = order.provider
  const isOnlineOrder = !!provider
  const isDeliveryOrder = order.type === 'delivery'
  const groupPrinter = invoiceGroupPrinters0()[0];
  const invoicePrinter = getMappedPrinter(groupPrinter);
  if (!invoicePrinter) return
  const posSetting = posSetting0();
  const logo = logo0()?.data
  if (!posSetting) return
  const { companyInfo } = posSetting;
  const {
    name: companyName, address: companyAddress, telephone: companyTel,
    taxLabel, taxNumber: companyVatNumber
  } = companyInfo!;
  const {
    hideUnitPrice,
    merge,
    printDelivery,
    printCustomerInfo,
    printTipLine,
    marginTop,
    escPOS,
    fontSize
  } = invoicePrinter!;
  const LL = getLL2();
  const printing = LL().printing;
  const _fontSize = 20 + ((fontSize || 1) - 1) * 8;
  //add
  const customerPrint = LL().customer;
  const deliveryPrint = LL().delivery;
  const settingPrint = LL().settings;
  //
  const orderDate = dayjs.unix(order.date || 0).format(LL().dates.dateFormat());
  const orderTime = dayjs.unix(order.date || 0).format(LL().dates.timeFormat());
  const printer = new VPrinter(getInvoicePrinter());
  await printer.marginTop(marginTop || 0)
  await printer.alignCenter();
  //fixme: logo
  if (logo && logo.length > 100) {
    const _logo = logo.replace(/^data:image\/\w+;base64,/, '')
    await printer.printImage(_logo, 'base64', 0.25 + ((posSetting.companyInfo?.logoSize || 1) - 1) * 0.15)
  }
  await printer.newLine()

  await printer.setTextQuadArea()
  await printer.bold(true);
  if (companyName!.trim()) {
    await printer.setFontSize(_.round(_fontSize * 0.9, 0))
    await printer.println(companyName!);
  }
  await printer.newLine(4);
  await printer.bold(false);
  await printer.setFontSize(_.round(_fontSize * 0.5, 0));
  await printer.println(companyAddress!);
  await printer.println(companyTel ? `${printing.tel()}: ${companyTel}` : '');
  // await printer.println((taxLabel && companyVatNumber) ? `${taxLabel || ''}: ${companyVatNumber}` : '');
  await printer.println((/*taxLabel &&*/ companyVatNumber) ? `${taxLabel || 'St.-Nr'}: ${companyVatNumber || ''}` : '');

  await printer.newLine(10);
  await printer.bold(true);
  await printer.setFontSize(_.round(_fontSize * 0.9, 0));
  await printer.println(printing.invoice());

  if (order.status === OrderStatus.CANCELLED) {
    await printer.println(LL().orderHistory.canceled());
  }

  await printer.setTextNormal()
  if (isOnlineOrder) {
    if (isDeliveryOrder) {
      await printer.println(printing.delivery())
    } else {
      await printer.println(printing.pickup())
    }
  } else {
    if (isDeliveryOrder && printDelivery) {
      await printer.println(printing.delivery())
    }
  }
  await printer.newLine(10);
  await printer.bold(false);

  await printer.setFontSize(_.round(_fontSize * 0.7, 0));
  await printer.alignLeft();
  await printer.println(`${printing.date()}: ${orderDate} ${orderTime}`);
  if (isOnlineOrder) {
    if (isDeliveryOrder) {
      await printer.println(`${printing.deliveryDate()}: ${dayjs(order.dropOffDate).format(printing.dateFormat())}`);
      await printer.println(`${printing.deliveryService()}: ${order.shippingData?.service}`);
      if (order.shippingData?.comment)
        await printer.println(`${printing.deliveryNote()}: ${order.shippingData?.comment}`)
    } else {
      await printer.println(`${printing.pickupDate()}: ${dayjs(order.pickupDate).format(printing.dateFormat())}`);
    }
    if (order.externalId) {
      if (order.provider === MarketPlaceProvider.UBER_EATS) {
        await printer.println(`${printing.invoiceNo()}: #${order.externalId.substring(order.externalId.length - 6)}`);
      } else {
        await printer.println(`${printing.invoiceNo()}: #${order.externalId}`);
      }
    }
    if (order.ticketNumber) {
      await printer.bold(true)
      await printer.println(`${printing.ticketNumber()}: ${order.ticketNumber}`);
      await printer.bold(false)
    }

    if (order.provider) {
      await printer.println(`${printing.fromSource()}: ${order.provider}`);
    }
  }
  if (!order.externalId && order.id && order.id !== 0)
    await printer.println(`${printing.invoiceNo()}: ${order.id}`);
  if (order.table) await printer.println(`${printing.table()}: ${order.table}`)
  const user = order.users?.[0];
  if (user) {
    await printer.println(`${printing.cashier()}: ${user}`)
  }

  if (order.pickUpNumber) {
    await printer.bold(true)
    await printer.println(`${printing.pickUpNumber()}: ${order.pickUpNumber}`)
    await printer.setTextNormal()
  }

  if (isOnlineOrder) {
    const metadata = order.metadata;
    if (metadata) {
      if (order.provider === MarketPlaceProvider.DELIVERECT) {
        if (metadata.channel) await printer.println(`Channel: ${metadata.channel}`);
        if (metadata.channelOrderDisplayId) await printer.println(`Channel Order Display Id: ${metadata.channelOrderDisplayId}`)
      }
    }
  }

    const customer = order.customerRaw;
    if (customer && companyInfo0()?.country !== 'de') {
      if (customer.name) await printer.println(`${printing.customerName()}: ${customer.name}`);
      if (customer.phone) await printer.println(`${printing.customerPhone()}: ${customer.phone}`);
      if (customer.address) await printer.println(`${printing.customerAddress()}: ${customer.extraAddressInfo ? customer.extraAddressInfo + ', ' : ''}${customer.address}`);
    }

    const hasNote = order.note || customer?.note
    if (hasNote) {
      await printer.println(`${printing.note()}:`);
      if (order.note) await printer.println(`- ${order.note}`);
      if (customer?.note) await printer.println(`- ${customer.note}`);
    }
  await printer.newLine(16);

  function createColMetaData() {
    const res: ColItemMetaData[] = [{ align: 'LEFT' }]
    if (!merge) res.push({ align: 'RIGHT', /*priority: 'HIGH',*/ padding: escPOS ? 0.05 : 0.03 })
    if (!hideUnitPrice) res.push({ align: 'RIGHT', /*priority: 'HIGH',*/ padding: escPOS ? 0.05 : 0.03 })
    res.push({ align: 'RIGHT', priority: 'HIGH' })
    return res
  }

  function createOrderDetailTableHeader(name: string, quantity: string, unitPrice: string, totalPrice: string): TableColumnData[] {
    const res: TableColumnData[] = [{ text: name, bold: true }]
    if (!merge) res.push({ text: quantity, bold: true })
    if (!hideUnitPrice) res.push({ text: unitPrice, bold: true })
    res.push({ text: totalPrice, bold: true })
    return res
  }

  function createItemRowData(name: string, quantity: string, unitPrice: string, totalPrice: string, isBold?: boolean) {
    const res = []
    if (merge) res.push({ text: `${quantity} x ${name}`, ...(isBold && { bold: true })})
    else res.push({ text: name, ...(isBold && { bold: true }) })
    if (!merge) res.push({ text: `${quantity}`, ...(isBold && { bold: true })})
    if (!hideUnitPrice) res.push({ text: `${unitPrice}`, ...(isBold && { bold: true }) })
    res.push({ text: `${totalPrice}`, ...(isBold && { bold: true }) })
    return res
  }


  const metaData = {
    colMetaData: createColMetaData(),
    rowMetaData: [
      { borderBottom: true }
    ]
  }
  await printer.setFontSize(_.round(_fontSize * 0.65, 0))
  const data: TableColumnData[][] = [createOrderDetailTableHeader(printing.product(), printing.quantity(), printing.price(), printing.sum())]
  for (const item of order.items) {
    // if (item.quantity < 1) continue
    if (item.quantity === 0) continue
    data.push(createItemRowData((item.isVoucher ? `${item.name} - ${item.code || ''}` : `${item.id ? item.id + '.': ''}${item.name}`),
      `${item.quantity}`,
      LL3().format.currency(item.price),
      LL3().format.currency(item.price * item.quantity), true))

    if (item.note) {
      data.push([{ text: `  - ${item.note}` }, { text: '' }, { text: '' }, { text: '' }])
    }

    for (const modifier of (item.modifiers || [])) {
      data.push(
        createItemRowData(` * ${modifier.name} (${LL3().format.currency(modifier.price)})`,
          `\u200B${modifier.quantity}`,
          /*LL3().format.currency(modifier.price)*/ '',
          LL3().format.currency(modifier.price * modifier.quantity * item.quantity))
      )
    }
  }
  await printer.advancedTableCustom({ metaData, data }, true)
  await printer.drawLine();
  const net = (
    isOnlineOrder
      ? order.vSubTotal!
      : _.sumBy(_.values(order.vTaxSum), 'net') - _.sumBy(_.values(order.shippingData?.vTaxSum), 'net')
  )

  async function printSubtotal() {
    await printer.leftRight(printing.subTotal(), LL3().format.currency(net));
  }

  let shippingFee = 0
  if (order.shippingData) {
    shippingFee = _.sumBy(_.values(order.shippingData.vTaxSum), 'net')
  }

  const taxType = posSettings0()[0].generalSetting?.taxType;
  const taxGroups = order.vTaxSum ? Object.keys(order.vTaxSum).map(taxAmount => {
    return ({
      taxAmount,
      tax: order!.vTaxSum![taxAmount].tax,
      taxName: (taxType === 'one' && taxCategories0().length > 0) ? taxCategories0()[0].name : printing.tax()
    });
  }).sort((a, b) => a.tax - b.tax) : []

  if (isOnlineOrder) {
    await printSubtotal()
    await printDiscount()
    await printer.leftRight(LL().pendingOrder.totalTax(), LL3().format.currency(order.taxTotal || 0))
    await printer.leftRight(printing.totalTip(), LL3().format.currency(order.tip || 0))
    // TODO: generic for all provider
    if (provider === MarketPlaceProvider.DELIVERECT) {
      if (order.shippingData?.fee) {
        await printer.leftRight(LL().payment.deliveryFee(), LL3().format.currency(order.shippingData?.fee || 0))
      }
    } else {
      await printShippingFee()
    }
    await printServiceFee()
    if (order.bagFee && order.bagFee > 0) {
      await printer.leftRight(LL().pendingOrder.bagFee(), LL3().format.currency(order.bagFee || 0))
    }
    await printTotal()
  } else {
    await printDiscount()
    await printSubtotal()
    await printServiceFee()
    await printShippingFee()
    await printTaxes()
    await printTotal()
  }

  await printPayment()
  await printer.newLine(8)

  //add
  if (!isOnlineOrder && customer && companyInfo0()?.country === 'de') {
    const customerInfo = findCustomerByCompanyName(customer.company);

    if (customer?.name) await printer.println(`${printing.customerName()}: ${customer.name}`);
    if (customer?.company) await printer.println(`${settingPrint.companyName()}: ${customer?.company}`);
    if (customer?.phone) await printer.println(`${printing.customerPhone()}: ${customer.phone}`);
    if (customer?.address) await printer.println(`${printing.customerAddress()}: ${customer.address}`);
    if (customer?.city) await printer.println(`${settingPrint.city()}: ${customer?.city}`);
    // if (customer.extraAddressInfo) await printer.println(customer.extraAddressInfo)
    if (customer?.zipCode) await printer.println(`${customerPrint.zipcode()}: ${customer.zipCode}`);
    if (customer?.email) await printer.println(`${deliveryPrint.customer.email()}: ${customer.email}`);
    if (customer?.ustId) await printer.println(`${settingPrint.ustId()}: ${customer.ustId}`);


    await printer.newLine(4)

    if (customerInfo?.id) await printer.println(`Kundennummer: ${customerInfo?.id}`);
    if (customer?.taxNo) await printer.println(`${settingPrint.taxNo()}: ${customer.taxNo}`);
    // if (customerInfo?.cardNo) await printer.println(`Kartennummer: ${customerInfo?.cardNo}`);
    if (customer?.note) await printer.println(`Weitere Informationen: ${customer?.note}`);
  }

  //
  await printer.alignCenter();
  await printer.println(posSetting.companyInfo?.footerText || '')

  if (invoiceType === InvoiceTypes.RED_INVOICE) {
    await printer.alignLeft()
    await printer.println(posSetting.companyInfo?.redInvoiceContent || '')
  }
  // if (process.env.NODE_ENV !== 'test' && invoiceType !== InvoiceTypes.GUEST_CHECK) await cms.emit('printQrCode', printer, order);
  await printQrCode(printer, order).printFull();

  const raster = await printer.getRaster()
  if (!raster) throw new Error(`Failed to get invoice raster for order [${order._id}]`)
  return raster

  //region print-invoice-utils
  async function printShippingFee() {
    if (shippingFee > 0)
      await printer.leftRight(printing.shippingFee(), LL3().format.currency(shippingFee))
  }

  async function printTotal() {
    await printer.bold(true);
    if (isOnlineOrder) {
      await printer.leftRight(printing.total(), `${LL3().format.currency(order.vTotal!)}`);
    } else {
      await printer.leftRight(printing.total(), `${LL3().format.currency(order.vSum!)}`);
    }
    await printer.bold(false)
  }

  async function printTaxes() {
    for (let i = 0; i < taxGroups.length; i++) {
      const { taxName, taxAmount, tax } = taxGroups[i];
      await printer.leftRight(`${taxAmount}%`, LL3().format.currency(tax));
    }
  }

  async function printDiscount() {
    if (order.vDiscount && !isNaN(order.vDiscount!) && order.vDiscount! > 0)
      await printer.leftRight(
        `${printing.discount()} ${(typeof order.discount === 'string' && order.discount.includes('%')) ? order.discount : ''}`,
        `-${LL3().format.currency(order.vDiscount)}`
      )
  }

  async function printPayment() {
    if (invoiceType === InvoiceTypes.GUEST_CHECK)
      return

    for (const payment of order.payments) {
      // TODO: (thinh) 'zvt', 'clover' not show in receipt
      await printer.leftRight(capitalize(payment.type), LL3().format.currency(payment.value))
    }
    if (printTipLine && !isOnlineOrder) {
      await printer.leftRight(printing.totalTip(), LL3().format.currency(order.tip || 0))
    }
    await printer.leftRight(printing.changeDue(), LL3().format.currency(order.cashback || 0))

    await printer.println('')
    await printer.println('')
    // print card info
    const txLog = await TxLogCollection.findOne({selector: { orderId: order._id }}).exec()
    if (txLog) {
      try {
        const {_data} = txLog;
        const {type, metadata} = _data;
        if (type === 'zvt') {
          // @ts-expect-error TODO: add type for metadata
          const {payment} = metadata
          // TODO: print all these field
          // TODO: more field from different ZVT brand
          const {
            TerminalIdentifier, TraceNumber, CardNumber, CardSequenceNumber,
            ReceiptNumber, AidAuthorisationAttribute,
            CardType /*Mastercard*/,
            CardName /*Masterc.*/,
            ExpiryDateMonth,
            ExpiryDateYear,
            AdditionalText,
            TurnoverRecordNumber,
            VuNumber,
          } = payment;
          if (TerminalIdentifier) {
            await printer.leftRight(`Terminal-ID`, "" + TerminalIdentifier)
          }
          if (ReceiptNumber) {
            await printer.leftRight(`TA-Nr: ${TurnoverRecordNumber}`, `Beleg-Nr: ${ReceiptNumber}`)
          }
          if (CardType) {
            await printer.leftRight(`Karte:`, CardType);
          }
          if (CardNumber) {
            await printer.leftRight(`Konto:`, maskCardNumber(CardNumber))
          }
          if (ExpiryDateMonth && ExpiryDateYear) {
            await printer.leftRight(`gültig bis (MM/JJ)`, `${ExpiryDateMonth}/${ExpiryDateYear}`)
          }
          if (VuNumber) {
            await printer.leftRight(`VU-Number`, VuNumber)
          }
          if (AdditionalText) {
            await printer.alignCenter();
            await printer.println(AdditionalText)
            await printer.alignLeft();
          }
        } else if (type === 'clover') {
          // TODO: print card info processed by clover
        }
        await printer.println('')
        await printer.println('')
      } catch (e) {
        captureException(new Error('Failed to print transaction', {cause: e}), { tags: { type: 'print' } })
      }
    }
  }

  async function printServiceFee() {
    if (order.serviceFee) {
      await printer.leftRight(printing.serviceFee(), LL3().format.currency(order.serviceFee || 0))
    }
  }

  //endregion
}

interface PrintQrCodeFactory {
  printTseHeader: () => Promise<void>
  printTseQr: () => Promise<void>
  printSignature: () => Promise<void>
  printSerialNumber: (printer: VPrinter) => Promise<void>
  printTimeFormat: (printer: VPrinter) => Promise<void>
  printSignatureAlgorithm: (printer: VPrinter) => Promise<void>
  printPublicKey: (printer: VPrinter) => Promise<void>
  printUserName: (printer: VPrinter) => Promise<void>
  getRaster: () => Promise<ScriptedRaster>
  printFull: () => Promise<void>
}

//todo: move to tse folder
export function printQrCode(printer: VPrinter, order: Order): PrintQrCodeFactory {
  const qrData = order && order.qrCode ? extractQr(order.qrCode!): undefined;
  // await printTseHeader();
  // await printSerialNumber(printer);
  // await printSignature();
  // await printTimeFormat(printer);
  // await printSignatureAlgorithm(printer);
  // await printPublicKey(printer);
  // await printUserName(printer);
  // await printTseQr();

  return {
    printTseHeader,
    printTseQr,
    printSignature,
    printSerialNumber,
    printTimeFormat,
    printSignatureAlgorithm,
    printPublicKey,
    printUserName,
    getRaster,
    printFull
  };

  async function printFull() {
    await printTseHeader();
    await printSerialNumber(printer);
    await printSignature();
    await printTimeFormat(printer);
    await printSignatureAlgorithm(printer);
    await printPublicKey(printer);
    await printUserName(printer);
    await printTseQr();
  }

  async function getRaster() {
    const raster = await printer.getRaster();
    return raster
  }

  async function printTseQr() {
    if (tseConfig0().hideTseQrCode) return;
    if (!qrData) return;
    await printer.newLine(8);
    await printer.alignCenter();
    await printer.printQrCode(order.qrCode!, 0.5);
  }

  async function printSignature() {
    if (!qrData) return;
    await print2Cols(printer, 'TSE-Signature:', qrData.signature);
  }

  async function printTseHeader() {
    if (!qrData) return;
    const firstOrderItemDate = _.orderBy(order.items, ['date'])[0].date;
    await print2Cols(printer, 'TSE-Transaktion:', qrData.transactionNumber);
    await print2Cols(printer, 'Erstbestellung:', dayjs.unix(firstOrderItemDate!).format(LL2().dates.fullTime()));
    await print2Cols(printer, 'TSE-Start:', qrData.startTime);
    await print2Cols(printer, 'TSE-Stop:', qrData.logTime);
  }
}



async function print2Cols(printer: VPrinter, text: string, text2: string) {
  await printer.tableCustom([
    { text, align: 'LEFT', width: 0.45 },
    { text: text2, align: 'LEFT', width: 0.55 },
  ]);
}

async function printSerialNumber(printer: VPrinter) {
  await print2Cols(printer, 'TSE-Seriennummer:', tseConfig0().serialNumber!);
}

async function printTimeFormat(printer: VPrinter) {
  await print2Cols(printer, 'TSE-Zeitformat:', tseConfig0().tseTimeFormat!);
}

async function printSignatureAlgorithm(printer: VPrinter) {
  await print2Cols(printer, 'TSE-Hashalgorithmus:', tseConfig0().signatureAlgorithm!);
}

async function printPublicKey(printer: VPrinter) {
  await print2Cols(printer, 'TSE-PublicKey:', tseConfig0().tsePublicKey!);
}

async function printUserName(printer: VPrinter) {
  await print2Cols(printer, 'KassenID:', tseConfig0().username!);
}

export function printQrCodeFactory() {
  const printer = new VPrinter(getInvoicePrinter());

  return {
    printSerialNumber: async () => {
      await printSerialNumber(printer);
      return await printer.getRaster();
    },
    printTimeFormat: async () => {
      await printTimeFormat(printer);
      return await printer.getRaster();
    },
    printSignatureAlgorithm: async () => {
      await printSignatureAlgorithm(printer);
      return await printer.getRaster();
    },
    printPublicKey: async () => {
      await printPublicKey(printer);
      return await printer.getRaster();
    },
    printUserName: async () => {
      await printUserName(printer);
      return await printer.getRaster();
    },
  }
}