import { LL0 } from '@/react/core/I18nService.tsx';
import { signal } from "@/react/core/reactive.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import dialogService from '@/react/SystemService/dialogService.tsx';

export const [isAlwaysHidePopup, setAlwaysHidePopup] = signal(false)

export const printerErrDialog = async (title:string, detail?: string, onConfirm: () => void, typePrinter?:string) => {
  const showDialog = ({ onClose }) => (
    <dialogService.BaseDialog
      title={''}
      onClose={onClose}
    >
      <div className="w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center pt-2 px-4 pb-4 box-border gap-[32px_0px] min-w-[320px] h-full max-w-full max-h-full text-center text-[18px] font-mulish">
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <b className="self-stretch relative leading-[28px]">{title}</b>
          {detail ? <div>- {detail}</div> : null}
          <div>
            - {LL0().printing.hint()}: {LL0().printing.restartPrinter()}
          </div>
          {typePrinter === 'NetworkPrinter' &&
            <div>- {LL0().printing.hint()}: {LL0().printing.networkNotBeStable()}</div>
          }
        </div>
        <div className="w-full flex flex-row items-center justify-center gap-[12px] max-w-[312px] text-sm text-materialize-blue-accent-3">
          <div
            className="!flex-1 rounded-md bg-materialize-blue-accent-3 overflow-hidden flex flex-row items-center justify-center py-[9px] px-0 text-white-solid-white-100-ffffff"
            onClick={async () => {
              await onConfirm()
              onClose()
            }}
            ref={makeRipple}
          >
            <b className="relative ">{LL0().settings.testPrinter()}</b>
          </div>

          <div
            className="flex-1 rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center py-[9px] px-0 cursor-pointer border-[1px] border-solid border-materialize-blue-accent-3"
            onClick={() => {
              setAlwaysHidePopup(true)
              onClose()
            }}
            ref={makeRipple}
          >
            <b className="relative text-black">{LL0().printing.alwaysHide()}</b>
          </div>
          <div
            className="flex-1 rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center py-[9px] px-0 cursor-pointer border-[1px] border-solid border-materialize-blue-accent-3"
            onClick={() => {
              onClose()
            }}
            ref={makeRipple}
          >
            <b className="relative text-black">{LL0().delivery.hide()}</b>
          </div>
        </div>
      </div>
    </dialogService.BaseDialog>
  )
  await dialogService.show({ component: showDialog })
}
