import { DialogOrderCustomDish } from '@order-view/DialogOrderCustomDish.tsx'
import OrderScreenPlugin from '@order-view/OrderScreenPlugin.tsx'
import debug from 'debug'
import delay from 'delay'
import { clone } from 'json-fn'
import _ from 'lodash'
import { memo } from 'react'
import { toast } from 'react-toastify'
import { createMutable } from 'solid-js/store'
import uuid from 'time-uuid'

import type { DemoData } from '@/assets/data/demoData/type.ts'
import type { DocDeepSignal } from '@/data/data-utils.ts'
import { dataLock } from '@/data/DataUtils.ts'
import type { ScreenConfigDynamicValue } from '@/data/DeviceSetting.ts'
import { deviceSetting0 } from '@/data/DeviceSettingSignal.ts'
import { Discount } from '@/data/Discount'
import { makeDiscountsAvailable } from '@/data/DiscountHub.ts'
import { groupPrinterV, makeGroupPrintersAvailable, setGroupPrinterV } from '@/data/GroupPrinterHub.ts'
import { happyHours0, makeHappyHoursAvailable } from '@/data/HappyHourHub.ts'
import { demoDataImportMap } from '@/data/ImportData.ts'
import { isExpiredLicense } from '@/data/LicenseHub.ts'
import { makeMaxIdsAvailable } from '@/data/MaxIdHub.ts'
import { MenuDirection } from '@/data/MenuDirection.ts'
import { type Modifier, type ModifierItem, ModifierType } from '@/data/Modifier.ts'
import { makeModifiersAvailable, modifiers0 } from '@/data/ModifierHub.ts'
import { Order } from '@/data/Order.ts'
import { makeOrderCommitsAvailable } from '@/data/OrderCommitsHub.ts'
import { removeOrder, runMasterHandler, runMasterHandlerPaidOrderFactory, updateOrder } from '@/data/OrderHub.ts'
import { OrderLayout } from '@/data/OrderLayout.ts'
import type { Payment } from '@/data/Payment.ts'
import { makePaymentsAvailable, payments0 } from '@/data/PaymentHub.ts'
import {
  generalSetting0,
  isQuebecSrmEnabled,
  mainScreen,
  posSetting0,
  printOverMaster0
} from '@/data/PosSettingsSignal.ts'
import { Product } from '@/data/Product.ts'
import { ProductLayout } from '@/data/ProductLayout.ts'
import { shouldWarnBecauseOffline } from '@/data/ReplicateEffect.ts'
import { StaffStatus } from '@/data/TableStaffStatus'
import { isTableHasStaffEditing, recordTableStaffStatus } from '@/data/TableStaffStatusHub'
import type { SubTax } from '@/data/TaxCategory.ts'
import { makeTaxCategoriesAvailable } from '@/data/TaxCategoryHub.ts'
import { isValidSrmUser, loginUsers, logOut } from '@/data/UserHub.ts'
import { Voucher, VoucherStatus } from '@/data/Voucher.ts'
import { makeVouchersAvailable } from '@/data/VoucherHub.ts'
import { createOrder, getTotalItems, resetOrder, stripOrder } from '@/pos/logic/order-reactive.ts'
import { generateIds } from '@/pos/logic/order-utils.ts'
import {
  CommitAction,
  type EquallySplit,
  type ItemCommit,
  type ItemCommitAddOrderDiscount,
  MasterHandlerCommand,
  type OrderItem,
  OrderStatus,
  type OrderStrip
} from '@/pos/OrderType.ts'
import {
  onEnter,
  onEnterScreens,
  onExit,
  onExitScreens,
  params,
  PosScreen,
  router,
  setParams
} from '@/pos/PosRouter.ts'
import { LL0 } from '@/react/core/I18nService.tsx'
import type { InputController } from '@/react/core/Input.tsx'
import { batch, computed, effect, effectOn, mutable, selector, signal, useSignalEffect } from '@/react/core/reactive.ts'
import { isManual } from '@/react/FloorPlanView/FloorPlanView.tsx'
import { removeDiscountBeforeSplit } from '@/react/OrderView/order-splitbill-utils.ts'
import { orderPricingLevelFactory } from '@/react/OrderView/OrderPricingLevel.logic.ts'
import { order0, OrderMode, setOrder0 } from '@/react/OrderView/OrderViewShare.ts'
import { ItemFactoryContext, useItemFactory } from '@/react/PaymentView/OrderItemsFactory2.tsx'
import {
  onPaymentClick,
  order0 as _order0,
  setOrder0 as _setOrder0,
  setValue0
} from '@/react/PaymentView/PaymentView.tsx'
import { printKitchen } from '@/react/Printer/print-kitchen.ts'
import { getTicketNumber, handlePrintLabel } from '@/react/Printer/print-label.ts'
import { isBetween } from '@/react/utils/date.ts'
import { getUrlParam } from '@/shared/utils2.ts'
import { displayVFD } from '@/shared/vfd/vfd-init.ts'
import { rnHost } from '@/shared/webview/rnwebview.ts'
import { srmTransactionLogic } from '@/srm/transaction.logic'

import { useOrderEditLocking } from '../hooks/useOrderEditLocking'
import { recordUserActionHistory, revertUserActions, UserAction } from "@/data/UserActionHistory.ts";
import { ensureValidItemNameLength } from "@/srm/lib/utils.ts";
import { kitchenFLow, userFLow } from "@/shared/logger.ts";
import { onClockOut } from "@/react/PasscodeView/PasscodeView.tsx";
import { loginUser } from "@/data/UserSignal.ts";
import { customers0, makeCustomersAvailable } from "@/data/CustomerHub.ts";
import { getAllCustomer } from "@/react/CustomerInfoView/CustomerInfoView.tsx";

let directCancelItemCommits = [];

const { getPrice, getMultipleTaxes, getMultipleTaxesComponent } = orderPricingLevelFactory()

export const [isShowChangePriceLv, setIsShowChangePriceLv] = signal<boolean>(false)

const log = debug('view:order')

export enum ScreenMode {
  ORDER,
  EDIT,
}
export let snapshot: Order | undefined;

export const [isFirstTimeGoToPayment, setIsFirstTimeGoToPayment] = signal<boolean>(false);

export const [screenMode, setScreenMode] = signal<ScreenMode>(ScreenMode.ORDER)

export const [categoryIndex, setCategoryIndex] = signal<number>(0)
export const isSelectedCategory = selector<number>(categoryIndex)
export const [orderLayout0, setOrderLayout0] = signal<OrderLayout>()
export const [products, setProducts] = signal<Array<Product>>([])
export const [productLayouts, setProductLayouts] = signal<Array<ProductLayout>>([])

export const [orderDiscount, setOrderDiscount] = signal<Discount>({ value: 0, type: 'percent', label: '' })
export const [orderDiscountItem, setOrderDiscountItem] = signal<Discount>({ value: 0, type: 'percent', label: '' })
export const [canGoBack, setCanGoBack] = signal<boolean>(false)
export const targetOrder = computed(()=>{
  if (order0()?.seatMode) return order0()?.seatMap?.[itemContext?.seat()]
  return order0()
})

Object.assign(window, { orderLayout0 }) // for debugging

export const layoutCategories0 = computed(() => {
  if (!orderLayout0()) return []
  const arr = []
  for (let i = 0; i < orderLayout0()?.rows || 0; i++) {
    for (let j = 0; j < orderLayout0()?.columns || 0; j++) {
      const category = orderLayout0().categories.find(p => p.top === i && p.left === j)
      if (category) {
        arr.push(category)
      }
    }
  }
  return arr
})

export const [v, setV] = signal<number>(0)
export const [dirty, setDirty] = signal(false)

export const [showPopupModifier, setShowPopupModifier] = signal<boolean>(false)
export const [keyboardProductQuantity, setKeyboardProductQuantity] = signal<number>(0)
export const [currentProduct, setCurrentProduct] = signal<Product>()
export const modifiers = computed<Array<DocDeepSignal<Modifier>>>(() => {
  if (!currentProduct()) return []
  return currentProduct()?.activePopupModifierGroup!.map(m => modifiers0().find(_m => _m._id === m)) as Array<DocDeepSignal<Modifier>>
})
export const isDiscountOrder = computed<boolean>(() => {
  return !(!order0().discount || ['0', '0%', 0].includes(order0()?.discount as string | number))
})

//@ts-ignore
window.modifiers = modifiers

export const itemContext = useItemFactory(order0, setOrder0, () => null)
export const [showTabSidebar, setShowTabSidebar] = signal<boolean>(true)
export const [currentItem, setCurrentItem] = signal<OrderItem>()
export const [showDiscountPopupItem, setShowDiscountPopupItem] = signal<boolean>(false)
export const [inputControllers, setInputControllers] = signal<Array<InputController>>([])
export const [numberOfParts, setNumberOfParts] = signal<number>(2)
export const [priceItems, setPriceItems] = signal<Array<{ price: string; quantity: string; seat: number }>>([])
export enum TabsDiscountPopup {
  MODIFIER,
  DISCOUNT,
  MOVE_SEAT,
  SPLIT_ITEM,
  EDIT_ITEM,
  SIZE,
  SAUCE,
}

export const [tabDiscountPopup, setTabDiscountPopup] = signal<TabsDiscountPopup>(TabsDiscountPopup.MODIFIER)

export const [orderMode0, setOrderMode0] = signal<OrderMode>(OrderMode.TABLE)

export const [menuDirection, setMenuDirection] = signal<MenuDirection>(MenuDirection.VERTICAL)

//effectOn for fetching data

effectOn(
  [v],
  _.debounce(
    async () => {
      log('on effect')
      await dataLock.acquireAsync()
      let orderLayout = await OrderLayout.findOne().exec()
      if (orderLayout) {
        const _orderLayout = createMutable<OrderLayout>(orderLayout.toMutableJSON())
        const _products = await Product.find().exec()
        const _productLayouts = await ProductLayout.find().exec()
        for (const category of _orderLayout.categories) {
          category.products = _productLayouts
            .filter(p => p.category === category._id)
            .map(p => ({
              ...p.toMutableJSON(),
              doc: p,
            }))
        }
        batch(() => {
          setOrderLayout0(_orderLayout)
          setProducts(_products.map(p => p.toMutableJSON()))
          setProductLayouts(_productLayouts)
          log('s1')
        })
      }
      //todo: dirty flag for better performance
      if (v() === 0) {
        const handle = () => {
          console.log('setDirty: ', true)
          setDirty(true)
        }
        ProductLayout.$.subscribe(handle)
        OrderLayout.$.subscribe(handle)
        Product.$.subscribe(handle)
      }
    },
    2000,
    { leading: true, trailing: true }
  )
)

//filtering product handlers

export const findProduct = (product: any) => products().find(p => p._id === product.product)

export const getText = (productLayout: any) => {
  if (productLayout.type === 'Text') return productLayout.text
  const foundProduct = findProduct(productLayout)
  return foundProduct?.id ? foundProduct.id + '. ' + foundProduct?.name : foundProduct?.name
}

//order handling functions

export const onPrint = _.debounce(
  async () => {
    // TODO: create cmd to validate srm user in master
    if (isQuebecSrmEnabled() && !isValidSrmUser()) return toast.error(LL0().srm.errors.unauthorized())

    router.screen = mainScreen()

    if (isExpiredLicense()) return toast.error('Your license has expired!')

    const _order = order0()

    getTicketNumber(_order)

    kitchenFLow(`onPrint trigger ${_order._id}`);
    if (isQuebecSrmEnabled()) {
      if (getTotalItems(_order) === 0) {
        log('ℹ️ Order cancellation detected, logging to SRM...')
        _order.status = OrderStatus.CANCELLED_BEFORE_PAID
        // In fast checkout mode (no table), always thread as making payment
        await srmTransactionLogic.recordCancellation(_order, { makingPayment: !_order.table })
      } else {
        const orders = _order.seatMode && _order.seatMap && !_order.isGroupBill ? _order.seatMap : [_order]
        for (const o of orders) {
          if (await srmTransactionLogic.isOrderUnchanged(o)) continue
          await srmTransactionLogic.recordTemporaryBill(o)
        }
      }
    }

    kitchenFLow(`onPrint after register srm ${_order._id}`);

    // await assignZ(order0());
    // await onPrintTse(order0());
    order0().commits?.push({ action: CommitAction.PRINT })
    if (getTotalItems(_order) === 0) {
      // await updateOrder(_order, true);
      if (printOverMaster0()) {
        const api = runMasterHandlerPaidOrderFactory(_order, [MasterHandlerCommand.onKitchenAndLabelPrint])
        await api.runFull(true)
      } else {
        await printKitchen(_order)
        await updateOrder(_order, true)
      }
    } else {
      if (printOverMaster0()) {
        await runMasterHandler(_order, [MasterHandlerCommand.onKitchenAndLabelPrint], true)
      } else {
        await printKitchen(_order)
        await runMasterHandler(_order, [], true)
      }
    }
  },
  3000,
  { leading: true, trailing: false }
)

export async function onPay() {
  // order0().payments.length = 0;
  // order0().payments.push({value: order0().vSum || 0, type: 'cash'});
  // order0().status = 'paid';
  // await Order.upsert(stripOrder(order0()));
  // router.screen = mainScreen();
  if (isExpiredLicense()) return toast.error('Your license has expired!')
  const _order = clone(stripOrder(order0()))
  if (isQuebecSrmEnabled() && (await isTableHasStaffEditing(_order.table))) {
    toast.error('Table is being edited by other staff, please wait for them to finish', { autoClose: 3000 })
    router.screen = mainScreen()
    return
  }
  if (isQuebecSrmEnabled() && _order.table) await recordTableStaffStatus(_order.table, StaffStatus.PROCESSING_PAYMENT, _order._id)
  setIsFirstTimeGoToPayment(true)

  if (_.last<ItemCommit>(order0().commits)?.action !== CommitAction.PRINT) {
    await Order.upsert(_order)
  }
  router.screen = PosScreen.PAYMENT
  let order1 = createOrder(resetOrder(_order))
  _setOrder0(order1)
  if (!order1.seatMode) {
    const paymentName = payments0().find(p => p.type === 'cash')?.name || 'cash'
    await onPaymentClick({ name: paymentName } as Payment)
  }
}

function addProduct(_product?: Product) {
  order0().commits?.push({
    action: CommitAction.ADD_PRODUCT,
    ref: currentProduct()!._id,
    _id: uuid(),
    productRef: {
      id: currentProduct()?.id,
      name: _product?.name ? _product.name : currentProduct()?.name!,
      price: _product?.price != null || _product?.price === 0 ? calculateProductPrice(_product) : calculateProductPrice(currentProduct()!),
      taxes: getMultipleTaxes(currentProduct()!)(),
      ingredients: currentProduct()?.ingredients,
      ...(currentProduct()?.categories && currentProduct()?.categories!.length > 0 && { categories: currentProduct()?.categories }),
      taxComponents: getMultipleTaxesComponent(currentProduct()!, 1)(),
      taxComponents2: getMultipleTaxesComponent(currentProduct()!, 2)(),
    },
    groupPrinter: currentProduct()!.groupPrinter,
    groupPrinter2: currentProduct()!.groupPrinter2,
    labelPrinter: currentProduct()?.labelPrinter,
    quantity: keyboardProductQuantity(),
    ...itemContext.addSeat(),
  })
  const data = {
    ...itemContext.addSeat(),
    productRef: {
      price: _product?.price != null || _product?.price === 0 ? calculateProductPrice(_product) : calculateProductPrice(currentProduct()!),
      name: _product?.name ? _product.name : currentProduct()?.name!
    },
    quantity: keyboardProductQuantity() || 1,
  }
  recordUserActionHistory(targetOrder(), UserAction.ADD_ITEM, data).then();
}

export const onConfirmModifiers = (modifiers: ModifierItem[]) => {
  const checkVariantProduct = modifiers.filter(p => p?.type === ModifierType.VARIANT)

  batch(() => {
    if (checkVariantProduct && checkVariantProduct.length > 0) {
      let _product: Product | undefined
      for (const product of checkVariantProduct) {
        _product = _.clone(currentProduct())
        _.assign(_product, { name: product?.name, price: product?.price })
        addProduct(_product)
      }
    } else {
      addProduct()
    }
    setKeyboardProductQuantity(1)
    for (const modifier of modifiers) {
      if (modifier?.type === ModifierType.VARIANT) continue
      order0().commits?.push({
        action: CommitAction.ADD_MODIFIER_TO_LAST_ITEM,
        quantity: 1,
        ref: modifier!._id,
        _id: uuid(),
        productRef: { name: modifier?.name!, price: modifier?.price! },
        ...itemContext.addSeat(),
      })
      const data = {
        ...itemContext.addSeat(),
        productRef: {
          modifierPrice: modifier?.price!,
          modifierName: modifier?.name!,
        },
        quantity: 1,
      }
      _.assign(data.productRef, { name: currentProduct()?.name })
      recordUserActionHistory(targetOrder(), UserAction.ADD_MODIFIER, data).then();
    }
  })
  displayVFD({
    sum: order0().vTotal?.toString() || '0',
    discount: order0().discount?.toString() || '0',
    item: _.last(order0().items)!,
  }).then()
}

// This is for Non-Fixed Item Pick
export const [currentNonFixedProduct, setCurrentNonFixedProduct] = signal<DocDeepSignal<Product> | null>(null)
export const [resultModifiers, setResultModifiers] = signal<{ [k: string]: { gid: string; name: string; price: number; _id: string } }>({})

export const calculateProductPrice = (p: Product) => {
  const now = new Date()
  const curTime = `${now.getHours()}:${now.getMinutes()}`
  const curDay = (now.getDay() + 6) % 7
  const happyHours = p.happyHours || []
  // let price = +p.price!
  let price = +getPrice(p)()
  for (const happyHourId of happyHours) {
    const happyHourItem = happyHours0().find(happyHour => happyHour._id === happyHourId)
    if (!happyHourItem) continue
    let { days, from, to, discountValue, discountType } = happyHourItem
    if (days?.includes(curDay) && isBetween(curTime, from!, to!)) {
      if (discountType === 'fixed') {
        price = Math.min(+p.price!, +discountValue!)
      } else if (discountType === 'amount') {
        price = Math.max(0, +p.price! - +discountValue!)
      } else {
        price = +p.price! * (1 - +discountValue! / 100)
      }
    }
  }
  return price
}

export function onProductClickHandle(product: Product | undefined, productLayoutType: string = '', quantity: number | undefined) {
  if (!product) return
  if (productLayoutType === 'NonFixed') {
    const _product = clone(product) as DocDeepSignal<Product>
    setCurrentNonFixedProduct(mutable(_product))
    return
  }
  if (productLayoutType === 'Extra') {
    const order = order0()?.seatMode ? order0()?.seatMap?.[itemContext.seat()] : order0();
    const lastItems = _.last(order?.items?.filter(i => i.quantity !== 0));
    if (lastItems && lastItems?.printed) return toast.error('Cannot add modifier to printed item!!!', {autoClose: 400})
    batch(() => {
      order0().commits?.push({
        action: CommitAction.ADD_MODIFIER_TO_LAST_ITEM,
        quantity: quantity ?? 1,
        ref: product!._id,
        _id: uuid(),
        productRef: { name: product?.name!, price: order0().multiplePriceMenu ? product?.menuPrices?.find(m => m.menu === order0().multiplePriceMenu)?.value : product?.price! },
        ...itemContext.addSeat(),
      })
    })

    const data = {
      ...itemContext.addSeat(),
      productRef: {
        modifierPrice: order0().multiplePriceMenu ? product?.menuPrices?.find(m => m.menu === order0().multiplePriceMenu)?.value : product?.price!,
        modifierName: product?.name!,
      },
      quantity: 1,
    }
    const lastItem = _.last(order?.items)
    _.assign(data.productRef, {productId: lastItem?.commitRefs?.[0], name: lastItem?.name})
    recordUserActionHistory(order, UserAction.ADD_MODIFIER, data).then();
    return
  }
  if (product?.activePopupModifierGroup?.length) {
    if (keyboardProductQuantity() > 1) {
      setKeyboardProductQuantity(1)
    }
    setShowPopupModifier(true)
    setCurrentProduct(product)
    return
  }

  setTimeout(() => {
    console.time('push item')
    order0().commits?.push({
      action: CommitAction.ADD_PRODUCT,
      ref: product!._id,
      _id: uuid(),
      productRef: {
        id: product?.id,
        name: product?.name!,
        price: calculateProductPrice(product),
        taxes: getMultipleTaxes(product)(),
        ingredients: product?.ingredients,
        ...(product?.categories && product?.categories!.length > 0 && { categories: product?.categories }),
        //fixme taxComponents later
        taxComponents: getMultipleTaxesComponent(product, 1)() /*product.taxComponents*/,
        taxComponents2: getMultipleTaxesComponent(product, 2)() /*product.taxComponents2*/,
      },
      ...itemContext.addSeat(),
      groupPrinter: product!.groupPrinter,
      groupPrinter2: product!.groupPrinter2,
      labelPrinter: product?.labelPrinter,
      quantity: quantity ?? 1,
    })
    displayVFD({
      sum: order0().vTotal!.toString(),
      discount: (order0().discount || 0).toString(),
      item: _.last(order0().items)!,
    }).then()
    console.timeEnd('push item')

    const data = {
      ...itemContext.addSeat(),
      productRef: {
        price: calculateProductPrice(product),
        name: product?.name!
      },
      quantity: quantity ?? 1,
    }
    recordUserActionHistory(targetOrder(), UserAction.ADD_ITEM, data).then();
  }, 0)
}

export const onProductClick = (productLayout: any, quantity?: number) => {
  // To check if a productLayout is category or not
  if (productLayout.type === 'Text') return
  // TODO: Review this
  const product = findProduct(productLayout)
  onProductClickHandle(product, productLayout.type, quantity)
}

export async function onPrintPay() {
  if (!ensureValidItemNameLength(order0()?.items)) return toast.error('Item with name or modifier name less than 2 digit is not allowed!!!')
  if (order0().payable || !order0().table) {
    if (order0()?.items?.length === 0 || getTotalItems(order0()) === 0) return toast.error(`${LL0().msgBox.addItem()}`, { autoClose: 2000 })
    userFLow('onPrintPay: onPay', { orderId: order0()._id });
    return await onPay()
  }
  if (shouldWarnBecauseOffline()) return toast.error('Master is not available', { autoClose: 2000 })
  userFLow('onPrintPay: onPrint', { orderId: order0()._id });
  return await onPrint()
}

// Voucher handlers

export function genVoucherCode() {
  return '' + Math.floor(Math.random() * 900000000 + 100000000)
}

export const [voucherStatus, setVoucherStatus] = signal<string>('none')

export function addVoucher({ price, code, _id }: { price: number | string; code?: string; _id?: string }) {
  const voucherItem = {
    name: 'Voucher',
    price: +price,
    isVoucher: true,
    tax: 0,
    taxes: [0, 0],
    code: code || genVoucherCode(),
    _id: _id || uuid(),
  }
  order0().commits?.push({
    action: CommitAction.ADD_PRODUCT,
    ref: voucherItem!._id,
    _id: uuid(),
    productRef: voucherItem,
    ...itemContext.addSeat(),
  })
  const data = {
    ...itemContext.addSeat(),
    productRef: {
      price: voucherItem?.price,
      name: voucherItem?.name
    },
    quantity: 1,
  }
  recordUserActionHistory(targetOrder(), UserAction.ADD_ITEM, data).then();
}

export const redeemVoucher = async (redeemCode: string) => {
  const isVoucherAlready =
    order0().items.some(item => item.code === redeemCode && item.quantity > 0) ||
    (
      await Voucher.find({
        selector: {
          code: redeemCode,
          status: VoucherStatus.REDEEMED,
        },
      }).exec()
    ).length !== 0
  const foundVoucher = await Voucher.findOne({
    selector: {
      code: redeemCode,
      status: {
        $not: 'redeemed',
      },
    },
  }).exec()
  if (foundVoucher && !isVoucherAlready) {
    const { price, code, _id } = foundVoucher
    addVoucher({
      price: -(price || 0),
      code,
      _id,
    })
    setVoucherStatus('valid')
  } else {
    if (!foundVoucher) setVoucherStatus('notFound')
    if (isVoucherAlready) setVoucherStatus('alreadyUsed')
  }
}

// discount handlers
// TODO: Move this to PosSetting
export const [isShowPrice, setIsShowPrice] = signal<boolean>(true)
export const [isShowToolbar, setIsShowToolbar] = signal<boolean>(true)

export const PERCENT_DISCOUNTS = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100]
export const AMOUNT_DISCOUNTS = [5, 10, 15, 20]
export const LABEL_DISCOUNTS: Discount[] = [
  {
    label: 'Lunch Sat & Sun',
    value: 10,
    type: 'amount',
  },
  {
    label: 'Valentine',
    value: 20,
    type: 'percent',
  },
]

export const createNewDiscount = async (discount: Omit<Discount, '_id'>) => {
  const { label, value, type } = discount
  if (!value || !type) return
  await Discount.insert({
    _id: uuid(),
    label,
    value,
    type,
  })
}

export const updateDiscount = async (discount: Discount) => {
  await Discount.upsert({
    _id: discount._id,
    label: discount.label,
    value: discount.value,
    type: discount.type,
  })
}

export const deleteDiscount = async (discount: Discount) => {
  await Discount.find({ selector: { _id: discount._id } }).remove()
}

export function handleOrderDiscount(discount: Discount) {
  const valueFrom = order0().discount || 0;
  const commit: ItemCommitAddOrderDiscount = {
    action: CommitAction.ADD_ORDER_DISCOUNT,
    type: discount.type ?? 'percent',
    value: (discount.value ?? 0) + '',
    label: discount.label ?? '',
  }

  if (order0()?.seatMode && discount?.type === 'amount') {
    if (typeof order0()?.discount === 'string' && (order0()?.discount as string)?.includes('%')) {
      order0().commits?.push({ action: CommitAction.CLEAR_DISCOUNT_ORDER })
    }
    commit.seat = itemContext.seat()
    order0().commits?.push(commit)
  } else {
    order0().commits?.push(commit)
  }

  recordUserActionHistory(order0(), UserAction.CHANGE_DISCOUNT_ORDER, {
    valueFrom,
    valueTo: discount.type === 'percent' ? `${discount.value}%` : `${discount.value}$`
  }).then();
}

export function handleClearDiscountOrder() {
  const order = [PosScreen.ORDER, PosScreen.NEW_DELIVERY].includes(router.screen) ? order0() : _order0()
  const seat = itemContext.seat()
  const valueFrom = order0().discount

  if (typeof seat !== 'undefined' && (order0()?.discountSeat?.length ?? 0) > 0) {
    const isExistDiscountSeatAmount = order0()?.discountSeat?.find(d => d.seat === seat)
    if (isExistDiscountSeatAmount) {
      order.commits?.push({ action: CommitAction.CLEAR_DISCOUNT_ORDER, seat: seat })
    }
  } else {
    order.commits?.push({ action: CommitAction.CLEAR_DISCOUNT_ORDER })
    const actionData = (valueFrom && valueFrom !== '0') ? {
      valueFrom,
    } : undefined
    recordUserActionHistory(targetOrder(), UserAction.CLEAR_DISCOUNT_ORDER, actionData).then();
  }
}

export function handleAddDiscountOrderItem(discount: Discount, commitId: string) {
  const order = [PosScreen.ORDER, PosScreen.NEW_DELIVERY].includes(router.screen) ? order0() : _order0();
  const item = order?.items?.find(i => i?.commitRefs?.[0] === commitId || i._id === commitId)
  const valueFrom = item?.discount
  const oldPrice = item?.vPrice
  order.commits?.push({
    action: CommitAction.ADD_DISCOUNT_ORDER_ITEM,
    commitId: commitId,
    type: discount.type ?? 'percent',
    value: discount.value ?? 0,
    label: discount.label ?? '',
  })

  setTimeout(() => {
    if (!item) return
    const data = {
      ...item?.seat != null && {seat: item?.seat},
      productRef: {
        productId: commitId,
        name: item?.name,
        oldPrice,
        price: item?.vPrice,
      },
      valueFrom,
      valueTo: discount.type === 'percent' ? `${discount.value}%` : `${discount.value}$`
    }
    const targetOrder = order?.seatMode ? order?.seatMap?.[data?.seat!] : order;
    recordUserActionHistory(targetOrder, UserAction.CHANGE_DISCOUNT_ITEM, data).then();
  }, 500);
}

export function handleClearDiscountOrderItem(item: OrderItem) {
  const order = [PosScreen.ORDER, PosScreen.NEW_DELIVERY].includes(router.screen) ? order0() : _order0()
  const valueFrom = item.discount
  const oldPrice = item?.vPrice
  order.commits?.push({
    action: CommitAction.CLEAR_DISCOUNT_ORDER_ITEM,
    commitId: item?.commitRefs?.[0]!,
  })
  recordDiscountAction(order, item, oldPrice, valueFrom)
}

function recordDiscountAction(order: Order, item: OrderItem, oldPrice: number, valueFrom?: number | string) {
  if (item.discount === valueFrom) return
  recordUserActionHistory(order, UserAction.CHANGE_DISCOUNT_ITEM, {
    productRef: {
      productId: item?.commitRefs?.[0]!,
      name: item.name,
      oldPrice,
      price: item.price
    },
    valueFrom,
    valueTo: item.discount
  }).then()
}
// custom dish handle functions

// Check this
export const CUSTOM_FOOD_TAXES = [7, 19]

const countryCode = getUrlParam('cn')
export const demoDataCustomDish = await demoDataImportMap(countryCode || import.meta.env.VITE_COUNTRY)

export const [dataCustomDish, setDataCustomDish] = signal<DemoData>(demoDataCustomDish)

export const addCustomDish = async (name?: string, price?: number | string, defaultName?: string, tax?: string | number, id?: string, taxComponents?: SubTax[] | undefined) => {
  if (!posSetting0()?.divArticleSettings?.drink?.groupPrinter || !posSetting0()?.divArticleSettings?.food?.groupPrinter) {
    await DialogOrderCustomDish(() => {})
    return
  }
  // TODO: Review this (taxes field)
  if (name === undefined || price === undefined) return
  const customDishTax = tax ? +tax : 0
  const customDish = {
    id,
    name: name,
    price: +price,
    tax: customDishTax,
    taxes: tax ? [customDishTax, customDishTax] : [0, 0],
    _id: uuid(),
    taxComponents: taxComponents,
  }
  if (defaultName == `${LL0().div.food()}`) {
    order0().commits?.push({
      action: CommitAction.ADD_PRODUCT,
      ref: customDish!._id,
      _id: uuid(),
      productRef: customDish,
      ...itemContext.addSeat(),
      groupPrinter: posSetting0()?.divArticleSettings?.food?.groupPrinter,
    })
  } else if (defaultName == `${LL0().div.drink()}`) {
    order0().commits?.push({
      action: CommitAction.ADD_PRODUCT,
      ref: customDish!._id,
      _id: uuid(),
      productRef: customDish,
      ...itemContext.addSeat(),
      groupPrinter: posSetting0()?.divArticleSettings?.drink?.groupPrinter,
    })
  } else {
    order0().commits?.push({
      action: CommitAction.ADD_PRODUCT,
      ref: customDish!._id,
      _id: uuid(),
      productRef: customDish,
      ...itemContext.addSeat(),
      groupPrinter: posSetting0()?.divArticleSettings?.food?.groupPrinter,
    })
  }
  const data = {
    ...itemContext.addSeat(),
    productRef: {
      price: customDish?.price,
      name: customDish?.name
    },
    quantity: 1,
  }
  recordUserActionHistory(targetOrder(), UserAction.ADD_ITEM, data).then();
}

// Take out function

export const onToggleTakeOut = () => {
  order0().commits?.push({
    action: CommitAction.CHANGE_ORDER_TAKE_AWAY,
    takeAway: !order0().takeAway || false,
  })
}

async function handleDirectCancellationOnBack(order: Order | OrderStrip, snapshot: Order | OrderStrip) {
  if (!order || !snapshot) return;
  const cancelItems = order.getRecent?.() || [];
  if (snapshot?.directCancellationItems && order?.directCancellationItems && snapshot?.directCancellationItems?.length < order?.directCancellationItems?.length) {
    const maxIndexSnapshot = snapshot?.directCancellationItems?.length - 1;
    const cancelDirectItems = order?.directCancellationItems?.filter((_, index) =>
      index > maxIndexSnapshot
    ) || [];
    if (cancelDirectItems?.length > 0) {
      cancelItems.push(...cancelDirectItems);
    }
  }
  if (!cancelItems || cancelItems?.length === 0) return;
  for (const item of cancelItems) {
    directCancelItemCommits?.push({_id: uuid(), action: CommitAction.ADD_DIRECT_CANCELLATION_ITEM, data: _.cloneDeep(item), orderId: order._id})
  }
}

async function updateDirectCancellationCommits(order) {
  const commits = directCancelItemCommits?.filter(c => c.orderId === order._id) || [];
  if (!commits || commits?.length === 0 || !isQuebecSrmEnabled()) return;
  for (const commit of commits) {
    // if (commit.orderId !== order._id) continue
    order.commits?.push(commit)
  }
  order.commits.push({action: CommitAction.PRINT})
  directCancelItemCommits = directCancelItemCommits?.filter(c => c.orderId !== order._id) || [];
  let stripOrder0 = stripOrder(order);
  await Order.upsert(stripOrder0)
}

export const onBack = async () => {
  if (!canGoBack()) return
  // if not has table view: flow would be like this: PASSCODE -> ORDER_VIEW
  //remove order0 ?
  const currentOrder = order0()
  const isPrinted = currentOrder.commits?.some(commit => commit.action === CommitAction.PRINT)
  const hasItem = currentOrder.items.some(item => item.quantity > 0)
  const hasDirectCancelledItem = (currentOrder.directCancellationItems?.length ?? 0) > 0
  const isFastCheckout = !currentOrder.table

  if (!isPrinted && isFastCheckout) {
    await removeOrder(currentOrder)
  }

  if (!isPrinted && !isFastCheckout) {
    // if (generalSetting0().multipleDineInMenusEnable) {
    //   handleChangeMenu(multipleMenus0()[0]._id as string)
    // }
  }
  revertUserActions(order0(), snapshot)
  if (!isFastCheckout) {
    await handleDirectCancellationOnBack(order0(), snapshot)
  }

  snapshot = undefined

  if (!generalSetting0()?.hasTablePlan) return await logOut()

  router.screen = mainScreen()

  if (isQuebecSrmEnabled() && isValidSrmUser()) {
    // In fast checkout mode, go back mean cancel order
    // In table mode, go back without print to kitchen is cancelling
    if ((isFastCheckout || !isPrinted) && (hasItem || hasDirectCancelledItem)) {
      log(`⚡️ ${isFastCheckout ? 'Closing receipt' : 'Temporary Bill'} cancelling detected, recording to SRM...`)
      currentOrder.status = OrderStatus.CANCELLED_BEFORE_PAID

      // In fast checkout mode (no table), always threat as making payment
      await srmTransactionLogic.recordCancellation(currentOrder, { makingPayment: !currentOrder.table })
      await updateOrder(currentOrder, true)
    }
  }
}



// For number keyboard: multiple id match case
// popup will be displayed when keyboardMatchProducts.length > 1
export const [keyboardMatchProducts, setKeyboardMatchProducts] = signal<ProductLayout[]>([])

// handle add Seat
export const handleAddSeat = () => {
  if (!order0().seatMode) {
    order0().commits!.push({ action: CommitAction.CHANGE_SEAT_MODE, seatMode: !order0().seatMode })
    itemContext.setSeat(0)
  }
  const delta = order0().seatMap!.length === 0 ? 2 : 1
  order0().commits!.push({ action: CommitAction.CHANGE_SEAT_QUANTITY, delta: delta, ids: [...generateIds(delta!)] })
}

//move table handlers
export const onMovedTablePrint = async (order?: Order) => {
  const _order = order ? order : order0()
  _order.commits?.push({ action: CommitAction.PRINT })
  let stripOrder0 = stripOrder(_order)
  await Order.upsert(stripOrder0)
}

//for rear display screen
effect(() => {
  order0().discount
  order0().items.length
  order0().items.map(item => item.quantity)
  order0().seatMap
  let o = order0()
  if (order0().seatMode) {
    const val = order0().seatMap![itemContext.seat()]
    if (val) {
      o = val
    } else {
      o = {
        _id: '',
        payments: [],
        lack: 0,
        vSum: 0,
        tip: 0,
        cashback: 0,
        lastPrintedRound: 0,
        items: [],
        status: OrderStatus.CREATED,
      }
    }
  }
  // if (o && !secondDisplayLock.acquired && router.screen === PosScreen.ORDER) wsSendOrder(o);
  rnHost.sendOrderToSD(o)
})

export function startFastCheckoutOrder() {
  setParams({ orderMode: OrderMode.FAST_CHECKOUT })
  router.screen = PosScreen.ORDER
}

export const onEditItem = (name: string, price: number) => {
  setShowDiscountPopupItem(false)
  const order = [PosScreen.ORDER, PosScreen.NEW_DELIVERY].includes(router.screen) ? order0() : _order0()
  const oldPrice = currentItem()?.price
  const oldName = currentItem()?.name
  order.commits?.push({
    action: CommitAction.EDIT_ITEM,
    commitId: currentItem()?.commitRefs?.[0] || '',
    name,
    price,
  })
  const targetOrder = order.seatMode ? order.seatMap?.[itemContext.seat()!] : order
  recordUserActionHistory(targetOrder, UserAction.CHANGE_ITEM_INFO, {
    productRef: { productId: currentItem()._id, oldPrice, oldName, name, price }
  }).then()
}
export const increaseParts = () => setNumberOfParts(numberOfParts() + 1)
export const decreaseParts = () => setNumberOfParts(Math.max(1, numberOfParts() - 1))
export const handleEnter = (isChangeQuantity?: boolean) => {
  const pricesAndSeats = priceItems().map(item => ({ price: item?.price, quantity: item?.quantity, seat: item.seat }))
  if (!isChangeQuantity) {
    // const totalSplitPrice = _.round(
    //   pricesAndSeats.reduce((sum, item) => sum + parseFloat(item.price), 0),
    //   0
    // )

    const totalSplitPrice = _.round(
      _.sumBy(pricesAndSeats, item => parseFloat(item.price)),
      0
    )
    const originalPrice = _.round(currentItem().price * currentItem().quantity, 0)

    if (totalSplitPrice !== originalPrice) {
      toast(`Sum price of the parts should equal to ${originalPrice}`, { type: 'error', autoClose: 500 })
      return
    }
  } else {
    const totalSplitQuantity = _.round(
      _.sumBy(pricesAndSeats, item => parseFloat(item.quantity)),
      4
    )

    if (Math.abs(totalSplitQuantity - currentItem().quantity) > 0.02) {
      toast(`Sum quantity of the parts should equal to ${currentItem().quantity}`, { type: 'error', autoClose: 500 })
      return
    }
  }

  const modifiers = currentItem().modifiers.map(item => ({
    name: item.name,
    price: isChangeQuantity ? item.price : _.round((currentItem().quantity * item.price) / numberOfParts(), 2),
    quantity: 1,
  }))

  const _id = currentItem()._id || ''

  if (modifiers.length > 0) {
    onSplitItem(_id, pricesAndSeats, modifiers, isChangeQuantity)
  } else {
    onSplitItem(_id, pricesAndSeats, [], isChangeQuantity)
  }

  setNumberOfParts(2)
}
export const handlePriceInput = (value: string, index: number, isChangeQuantity?: boolean) => {
  const currentPrices = priceItems()
  const updatedPrices = [...currentPrices]
  if (isChangeQuantity) {
    updatedPrices[index] = { ...updatedPrices[index], quantity: value }
  } else {
    updatedPrices[index] = { ...updatedPrices[index], price: value }
  }
  setPriceItems(updatedPrices)
}
export const onSplitItem = (
  _id: string,
  arrayItem: { price: string; seat: number; quantity: string }[],
  arrayModifier: {
    name: string
    price: number
  }[],
  isChangeQuantity = false
) => {
  const order = [PosScreen.ORDER, PosScreen.NEW_DELIVERY].includes(router.screen) ? order0() : _order0()
  setShowDiscountPopupItem(false)
  const _item = order.items.find(i => i.commitRefs!.includes(_id))
  if (!_item) return
  for (const item of arrayItem) {
    itemContext.setSeat(item.seat)
    order.commits?.push({
      action: CommitAction.SPLIT_ITEM,
      commitId: _id,
      ref: _id,
      _id: uuid(),
      productRef: {
        id: _item?.id,
        name: _item?.name!,
        price: parseFloat(item.price),
        taxes: [_item?.tax!, _item?.tax2!],
        ingredients: _item?.ingredients,
        taxComponents: _item?.taxComponents,
        taxComponents2: _item?.taxComponents2,
        ...(_item?.categories && _item?.categories!.length > 0 && { categories: _item?.categories }),
      },
      groupPrinter: _item?.groupPrinter,
      groupPrinter2: _item?.groupPrinter2,
      quantity: parseFloat(item?.quantity),
      printed: _item.printed,
      printedRound: _item.printedRound,
      ...itemContext.addSeat(),
      oldId: _item?.splitId ? _item?.originalInfo?.oldId : _item._id,
      oldPrice: _item?.splitId ? _item?.originalInfo?.oldPrice : _item.price,
      oldQuantity: _item?.splitId ? _item?.originalInfo?.oldQuantity : _item.quantity,
      ...(currentItem()?.seat != null && { originalSeat: currentItem()?.seat }),
      ...(currentItem()?.seat != null && { originalQuantity: currentItem()?.seat }),
      ...(currentItem()?.seat != null && { srm_originalTransactionId: _.last(order.seatMap?.[currentItem()!.seat!].srm_transactions)?._id }),
    })
    //add originalSeat and originalQuantity to handle same _id items in different seat
    if (arrayModifier.length > 0) {
      for (const modifier of arrayModifier) {
        order.commits?.push({
          action: CommitAction.ADD_MODIFIER_TO_LAST_ITEM,
          quantity: 1,
          ref: _id,
          _id: uuid(),
          productRef: { name: modifier?.name, price: modifier?.price },
          ...itemContext.addSeat(),
        })
      }
    }
  }
  recordSplitItemAction(order, _item, arrayItem, isChangeQuantity)
}

function recordSplitItemAction(order: Order, _item: OrderItem, arrayItem: any, isChangeQuantity: boolean) {
  recordUserActionHistory(order, UserAction.SPLIT_ITEM, {
    productRef: { name: _item.name,
      productId: _item._id,
      ...!isChangeQuantity && { price: Number(arrayItem[0].price!) },
    },
    ...isChangeQuantity && { quantity: Number(arrayItem[0].quantity!) },
    parts: arrayItem.length,
    ...!!order.seatMode && { seat: itemContext.seat() },
  }).then()

  if (order.seatMode) { //handle split to another seat
    const isSameSeat = arrayItem.every(item => item.seat === arrayItem[0].seat);
    if (isSameSeat) {
      const seatOrder = order.seatMap?.[itemContext.seat()]
      recordUserActionHistory(seatOrder, UserAction.SPLIT_ITEM, {
        productRef: {
          name: _item.name,
          productId: _item._id,
          ...!isChangeQuantity && { price: Number(arrayItem[0].price!) },
        },
        ...isChangeQuantity && { quantity: Number(arrayItem[0].quantity!) },
        parts: arrayItem.length,
        seat: itemContext.seat(),
      }).then()
    } else { // split item in seat A to seat B
      for (const itemSplit of arrayItem) {
        if (itemSplit.seat !== itemContext.seat()) {
          const seatOrder = order.seatMap?.[itemSplit.seat]
          if (!seatOrder) continue
          recordUserActionHistory(seatOrder, UserAction.SPLIT_ITEM, {
            productRef: {
              name: _item.name,
              productId: _item._id,
              ...!isChangeQuantity && { price: Number(arrayItem[0].price!) },
            },
            ...isChangeQuantity && { quantity: Number(arrayItem[0].quantity!) },
            parts: arrayItem.length,
            seat: itemContext.seat(),
          }).then()
        } else { // split item seat -> to another seat
          const seatOrder = order.seatMap?.[itemContext.seat()]
          if (!seatOrder) continue
          recordUserActionHistory(seatOrder, UserAction.ADD_ITEM, {
            productRef: {
              name: _item.name,
              productId: _item._id,
              price: Number(itemSplit.price!),
            },
            quantity: itemSplit.quantity,
            seat: itemContext.seat(),
          }).then()
        }
      }
    }
  }
}

export const increasePropertyValuesScreenConfig = (properties: keyof ScreenConfigDynamicValue, step: number = 1) => {
  const currentConfig = deviceSetting0()?.screenConfig
  const currentValue = currentConfig?.[properties]

  if (typeof currentValue === 'number') {
    return _.assign(deviceSetting0()?.screenConfig, { [properties]: currentValue + step })
  }
}

export const decreasePropertyValuesScreenConfig = (properties: keyof ScreenConfigDynamicValue, step: number = 1, min: number = 0) => {
  const currentConfig = deviceSetting0()?.screenConfig
  const currentValue = currentConfig?.[properties]

  if (typeof currentValue === 'number' && currentValue > min) {
    const newValue = Math.max(currentValue - step, min) // Đảm bảo giá trị không nhỏ hơn min
    return _.assign(deviceSetting0()?.screenConfig, { [properties]: newValue })
  }
}

export const onEquallySplit = async (parts: number, option: EquallySplit) => {
  const order = [PosScreen.ORDER, PosScreen.NEW_DELIVERY].includes(router.screen) ? order0() : _order0()

  if (order.seatMode) return toast.error('Cannot split order in seat mode !!!')

  order.commits?.push({
    action: CommitAction.CHANGE_SEAT_MODE,
    seatMode: true,
  })

  order.commits?.push({
    action: CommitAction.CHANGE_SEAT_QUANTITY,
    delta: parts,
    ids: [...generateIds(parts!)],
  })

  await delay(500)

  if (order.discount) {
    const commit = {
      action: CommitAction.ADD_ORDER_DISCOUNT,
      label: order.discountLabel,
    } as ItemCommitAddOrderDiscount

    if (typeof order.discount === 'string' && order.discount.includes('%')) {
      commit.type = 'percent'
      commit.value = order.discount.replace('%', '')
      order.commits?.push({ action: CommitAction.CLEAR_DISCOUNT_ORDER })
      order.commits?.push(commit)
    } else {
      const seats = order.seatMap?.map(i => i.seat)
      commit.value = (Number(order.discount) / parts).toString()
      commit.type = 'amount'
      order.commits?.push({ action: CommitAction.CLEAR_DISCOUNT_ORDER })
      for (const seat of seats!) {
        order.commits?.push({ ...commit, seat })
      }
    }
  }

  order.commits?.push({
    action: CommitAction.EQUALLY_SPLIT,
    parts: parts,
    option: option,
  })
}

//since order screen is also used for new delivery screen
onEnterScreens([PosScreen.ORDER, PosScreen.NEW_DELIVERY], () => {
  if (dirty()) {
    setV(v => v + 1)
  }
  if (isQuebecSrmEnabled()) rnHost.loadCert()
})

// set exit on those 2 screens to prevent strange behavior like this:
// setOrder in Payment -> clearOrder in Order
onExitScreens([PosScreen.ORDER, PosScreen.PAYMENT], async () => {
  // wsSendOrder();
  await rnHost.sendOrderToSD()
})

export const onMergeSeats = (order: Order) => {
  removeDiscountBeforeSplit(order)
  order.commits!.push({ action: CommitAction.MERGE_SEAT })
}

export const onGroupBill = (order: Order, value: boolean) => {
  order.commits!.push({ action: CommitAction.SET_ORDER_GROUP_BILLS, value })
}

const OrderView = () => {
  makeTaxCategoriesAvailable()
  makeHappyHoursAvailable()
  makeMaxIdsAvailable().then()
  useOrderEditLocking(order0()._id, PosScreen.ORDER)

  onEnter(PosScreen.ORDER, async () => {
    setCanGoBack(true)

    if (params()?.orderMode === OrderMode.FAST_CHECKOUT || !posSetting0()?.generalSetting?.hasTablePlan) {
      setOrderMode0(OrderMode.FAST_CHECKOUT)
      const order = createOrder({ users: loginUsers() })
      if (posSetting0()?.defaultTakeaway) _.assign(order, { takeAway: true })
      setOrder0(order)
    } else if (params()?.table) {
      setOrderMode0(OrderMode.TABLE)
      const order = createOrder({ table: params()?.table, users: loginUsers(), manual: isManual() })
      if (params()?.takeAway) _.assign(order, { takeAway: true })
      setOrder0(order)
      updateDirectCancellationCommits(order).then()
      snapshot = _.cloneDeep(order)
    } else if (params()?.order) {
      setOrderMode0(OrderMode.TABLE)
      const _order = params()?.order
      const order = createOrder(resetOrder(_order as Order))
      setOrder0(order)
      updateDirectCancellationCommits(order).then()
      snapshot = _.cloneDeep(order)
      if (params()?.isMoveTable) {
        onMovedTablePrint().then()
      }
    }
    if (order0().customer) {
      const customers = await getAllCustomer()
      const _customer = (customers || []).find(c => c._id === order0().customer)
      if (_customer) {
        const address = _customer?.addresses?.[_customer.defaultAddressIndex || 0]
        _.assign(order0(), {
          customerRaw: {
            ...order0().customerRaw,
            name: _customer?.name,
            address: address?.street,
            phone: _customer?.phoneNumber,
            extraAddressInfo: address?.home,

          },
        })
      }
    }
    if (order0().seatMode) {
      itemContext.setSeat(order0().seatMap!.findIndex(o => o.status !== OrderStatus.PAID))
    }
  })

  makeGroupPrintersAvailable()
  makeDiscountsAvailable()
  makePaymentsAvailable()
  makeModifiersAvailable()
  makeVouchersAvailable()
  makeOrderCommitsAvailable()

  useSignalEffect(() => {
    if (groupPrinterV() === 0) setGroupPrinterV(1)
  })

  onExit(PosScreen.ORDER, () => {
    itemContext.setSplitEnable(false)
  })
  return (
    <ItemFactoryContext.Provider value={itemContext}>
      <OrderScreenPlugin />
    </ItemFactoryContext.Provider>
  )
}

//@ts-ignore
window.onProductClickHandle = onProductClickHandle
//@ts-ignore
window.onPrintPay = onPrintPay
//@ts-ignore
//@ts-ignore
window.params = params
//@ts-ignore
window.onEquallySplit = onEquallySplit

export default memo(OrderView)
