import React, { type Ref, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import {LL0} from "@/react/core/I18nService.tsx";

export type Props<T> = {
  data: T[];
  increaseSize: () => void;
  isValidating?: boolean;
  isAutoInfinite?: boolean;
  dataWrapper?: React.ElementType;
  //used for table to prevent warning
  indicatorWrapper?: React.ElementType;
  children: React.ReactNode | ((item: T, index: number) => React.ReactNode);
  loadingIndicator?: React.ReactNode;
  endingIndicator?: React.ReactNode;
  isReachingEnd: boolean;
};

const useIntersection = <T extends HTMLElement>(): [boolean, Ref<T>] => {
  const [intersecting, setIntersecting] = useState<boolean>(false);
  const [element, setElement] = useState<HTMLElement>();
  useEffect(() => {
    if (!element) return;
    const observer = new IntersectionObserver((entries) => {
      setIntersecting(entries[0]?.isIntersecting);
    });
    observer.observe(element);
    return () => observer.unobserve(element);
  }, [element]);
  return [intersecting, (el) => el && setElement(el)];
};

const InfiniteScroll = <T,>(props: Props<T>): React.ReactElement<Props<T>> => {
  const {
    data,
    increaseSize,
    isValidating = false,
    children,
    endingIndicator = 'No items left',
    loadingIndicator = 'Loading',
    isReachingEnd,
    indicatorWrapper: IndicatorWrapper = React.Fragment,
    dataWrapper: DataWrapper = React.Fragment,
    isAutoInfinite = true,
  } = props;

  const [intersecting, ref] = useIntersection<HTMLDivElement>();

  useEffect(() => {
    if (intersecting && !isValidating && !isReachingEnd) {
      increaseSize();
    }
  }, [intersecting, isValidating, increaseSize, isReachingEnd]);

  return (
    <>
      <DataWrapper>
        {typeof children === 'function' ? data?.map((item, index) => children(item, index)) : children}

        {isAutoInfinite && !isValidating && (
          <IndicatorWrapper>
            <div className="relative w-full">
              <div ref={ref} className="absolute" />
              <div className="absolute mx-auto w-full">{isReachingEnd ? endingIndicator : loadingIndicator}</div>
            </div>
          </IndicatorWrapper>
        )}
      </DataWrapper>
      {!isReachingEnd && !isAutoInfinite && (
        <IndicatorWrapper>
          <div className="hidden sm:block">
            <Button onClick={increaseSize} className="self-center !mt-8 !px-16 w-full" disabled={isValidating}>
              {isValidating ? loadingIndicator : LL0().ui.loadMore()}
            </Button>
          </div>
        </IndicatorWrapper>
      )}
    </>
  );
};

export default InfiniteScroll;
