import { type FunctionComponent, useState } from "react";
import {onCompleteImport} from "@/react/SyncMenuView/SyncMenuView.tsx";
import {LL0} from "@/react/core/I18nService.tsx";

type SyncOMenuConfirmationPopupType = {
  onClose?: () => void;
};

const SyncOMenuConfirmationPopup: FunctionComponent<
  SyncOMenuConfirmationPopupType
> = ({ onClose }) => {
  const [ withSync, setWithSync ] = useState<boolean>(true);

  return (
    <div className="relative rounded-2xl bg-white-solid-white-100-ffffff shadow-[0px_0px_4px_rgba(0,_0,_0,_0.1)] [backdrop-filter:blur(30px)] w-[588px] overflow-hidden flex flex-col items-center justify-center py-[22px] px-6 box-border gap-[20px] min-w-[400px] max-w-full max-h-full text-left text-base text-royalblue font-mulish">
      <div className="self-stretch flex flex-col items-center justify-start gap-[10px]">
        <b className="self-stretch relative text-xl leading-[28px] text-red-solid-red-400-d93b5c text-center">
          {LL0().syncMenu.pleaseChooseOneOfTheFollowingTwoOptions()}:
        </b>
        <div className="self-stretch flex flex-row items-start justify-start gap-[10px]">
          <div className="shrink-0 flex flex-row items-start justify-center pt-1.5 px-0 pb-0">
            <input
              className="cursor-pointer m-0 relative w-5 h-5"
              id="option1"
              type="radio"
              checked={withSync}
              onChange={e => setWithSync(e.target.checked)}
            />
          </div>
          <label htmlFor='option1' className="flex-1 relative leading-[28px]">
            <span className="font-semibold">{`${LL0().syncMenu.option()} 1: `}</span>
            <span className="font-medium text-blue-solid-blue-900-081a51">
              {LL0().syncMenu.changePriceTaxMenuUpdateOnline()}.
            </span>
          </label>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[10px]">
          <div className="shrink-0 flex flex-row items-start justify-center pt-1.5 px-0 pb-0">
            <input
              className="cursor-pointer m-0 relative w-5 h-5"
              id="option2"
              type="radio"
              checked={!withSync}
              onChange={e => setWithSync(!e.target.checked)}
            />
          </div>
          <label htmlFor='option2' className="flex-1 relative leading-[28px]">
            <span className="font-semibold">{`${LL0().syncMenu.option()} 2: `}</span>
            <span className="font-medium text-blue-solid-blue-900-081a51">
              {LL0().syncMenu.copyMenuForOnlinePricesTaxesMayDiffer()}.
            </span>
          </label>
        </div>
      </div>
      <div className="w-full flex flex-row items-center justify-center gap-[12px] max-w-[312px] text-center text-sm text-blue-solid-blue-200-017efa">
        <div
          className="flex-1 rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 cursor-pointer border-[1px] border-solid border-blue-solid-blue-200-017efa"
          onClick={onClose}
        >
          <b className="flex-1 relative">{LL0().ui.cancel()}</b>
        </div>
        <div className="flex-1 rounded-md [background:linear-gradient(135deg,_#7ebcfe,_#0072eb)] overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 text-white-solid-white-100-ffffff"
          onClick={() => {
            onClose?.();
            onCompleteImport(withSync);
          }}
        >
          <b className="relative">{LL0().ui.ok()}</b>
        </div>
      </div>
    </div>
  );
};

export default SyncOMenuConfirmationPopup;
