import _ from 'lodash';
import type { OrderItem, Tax, VTaxSum } from "@/pos/OrderType.ts";
import type { Order } from "@/data/Order.ts";
import type { PaymentItemReport } from "@/pos/logic/ReportType.ts";

export function mergeVTaxGroup(objValue: Tax, itemValue: Tax): Tax {
  if (!objValue) return itemValue;
  if (!itemValue) return objValue;
  return {
    tax: objValue.tax + itemValue.tax,
    net: objValue.net + itemValue.net,
    gross: objValue.gross + itemValue.gross
  };
}

export let fromReducer = {
  label: "from",
  fn(_from: number, order: Order) {
    if (_from === undefined) return order.date;
    if (_from > order.date!) return order.date;
    if (_from == 0) return order.date;
    return _from;
  },
  initValue: undefined
};

export let fromIdReducer = {
  label: "fromId",
  fn(_from: number, order: Order) {
    if (_from === undefined) return order.id;
    if (_from > order.id!) return order.id;
    return _from;
  },
  initValue: undefined
};

export let toReducer = {
  label: "to",
  fn(_to: number, order: Order) {
    if (_to === undefined) return order.date;
    if (_to < order.date!) return order.date;
    return _to;
  },
  initValue: undefined
};

export let toIdReducer = {
  label: "toId",
  fn(_to: number, order: Order) {
    if (_to === undefined) return order.id;
    if (_to < order.id!) return order.id;
    return _to;
  },
  initValue: undefined
};

export const vTaxSumReducer = {
  label: "vTaxSum",
  fn(result: { vTaxSum: VTaxSum } & Tax, order: Order) {
    const { vTaxSum } = order;
    _.mergeWith(result.vTaxSum, vTaxSum, mergeVTaxGroup);
    _.assign(result, { gross: 0, net: 0, tax: 0 });

    for (const k of Object.keys(result.vTaxSum!)) {
      const { gross, net, tax } = result.vTaxSum![k];
      result.gross += gross;
      result.net += net;
      result.tax += tax;
    }

    result.gross = _.round(result.gross, 2);
    result.net = _.round(result.net, 2);
    result.tax = _.round(result.tax, 2);

    return result;
  },
  initValue: { vTaxSum: {} }
};

export let quantityReducer = {
  label: "quantity",
  fn: (q: number) => q + 1
}

export let soldOrderCntReducer = {
  label: 'soldOrderCnt',
  fn: (q: number) => q + 1
}

export let originalPriceReducer = {
  label: 'unitPrice',
  fn: (price: number, i: OrderItem) => _.defaultTo(i.price, i.vPrice!) //todo: xem xet truong hop item cung category, cung name nhung khac price?
}


export const vTaxSumGroupReducer = {
  label: "vTaxSumGroup",
  fn(result: string[], order: Order) {
    if (!order.vTaxSum) return;
    result.push(...Object.keys(order.vTaxSum));
    return _.uniq(result);
  },
  initValue: []
}


export let totalExcludeCashbackReducer = {
  label: "totalExcludeCashback",
  fn: (total: number, i: PaymentItemReport) => total + (i.excludeCashback || 0),
  initValue: 0
}


export let totalOtherFeeReducer = {
  label: "totalOtherFee",
  fn: (total: number, i: PaymentItemReport) => total + (i.otherFee || 0),
  initValue: 0
}


