import type { RxCollection, RxDatabase, RxDocument } from "rxdb";
import { DateType } from "@/data/DataUtils.ts";
import { createCollection } from "@/data/utils/migration.ts";

export const TSE_TRANSACTION_COLLECTION_NAME = 'tse_transactions'

export const VERSION = 7
const tseTransactionSchema = {
  title: 'tseTransaction schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    TSE_TANR: { type: 'number' },
    TSE_TA_START: DateType,
    TSE_TA_ENDE: DateType,
    TSE_TA_VORGANGSART: { type: 'string' },
    TSE_TA_SIGZ: { type: 'number' },
    TSE_TA_SIG: { type: 'string' },
    TSE_TA_VORGANGSDATEN: { type: 'string' },
    TSE_TA_VORGANGSDATEN2: { type: 'string' },
    TSE_TA_FEHLER: { type: 'string', maxLength: 100 },
    order: { type: 'string' , maxLength: 24},
    orders: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          pos: { type: 'number' },
          order: { type: 'string' },
          price: { type: 'number' }
        }
      }
    },
    needReassign: { type: 'boolean' },
    fake: { type: 'boolean' },
    z: { type: 'number'}
  },
  indexes: ['order', 'TSE_TA_FEHLER']
} as const;

export enum VorgangArt {
  BestellungV1 = 'Bestellung-V1',
  KassenbelegV1 = 'Kassenbeleg-V1',
}

export interface TseTransaction {
  _id: string;
  TSE_TANR?: number;
  TSE_TA_START?: number;
  TSE_TA_ENDE?: number;
  TSE_TA_VORGANGSART?: VorgangArt;
  TSE_TA_SIGZ?: number;
  TSE_TA_SIG?: string;
  TSE_TA_VORGANGSDATEN?: string;
  TSE_TA_VORGANGSDATEN2?: string;
  TSE_TA_FEHLER?: string;
  order?: string;
  orders?: { pos: number, order: string, price?: number }[]
  needReassign?: boolean;
  fake?: boolean
  z?: number
}

export type TseTransactionDocument = RxDocument<TseTransaction>;
export type TseTransactionCollection = RxCollection<TseTransaction>;

export const TseTransactionCollection: TseTransactionCollection = {} as TseTransactionCollection;
export const TseTransaction = TseTransactionCollection;

export const createTseTransactionCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: TseTransaction,
    collectionName: TSE_TRANSACTION_COLLECTION_NAME,
    version: VERSION,
    schema: tseTransactionSchema,
  });
}

// @ts-ignore
window.TseTransaction = TseTransaction;