import CharDeleteUrl from "@/assets/char_delete.png";
import ShiftUrl from "@/assets/shift.png";
import ShiftBlueUrl from "@/assets/shift_blue.png";
import ShiftRedUrl from "@/assets/shift_red.png";
import { ColorDarken } from "@/react/core/ColorDarken";
import GridElement from "@/react/core/GridElement";
import GridLayout from "@/react/core/GridLayout";
import type { InputController, InputProps } from "@/react/core/Input";
import { type Accessor, useComputed, useSignal } from "@/react/core/reactive";

export interface InputKeyboardProps extends Omit<InputProps, 'value'> {
	inputController?: Accessor<InputController | undefined>;
	onEnter?: () => void;
	enterText?: string
}

export type SpecialKey = {
	id: string,
	icon?: Accessor<string>,
	text?: string,
	color?: string,
	lineColor?: string,
	textColor?: string,
	x?: number,
	y?: number,
	cols?: number,
	down?: () => void,
	up?: () => void,
}

export enum ShiftState {
	NONE,
	SHIFT,
	CAPSLOCK
}

export const ShiftMap = {
	[ShiftState.NONE]: ShiftUrl,
	[ShiftState.SHIFT]: ShiftBlueUrl,
	[ShiftState.CAPSLOCK]: ShiftRedUrl
};

//todo: delete interval
//todo: shift
const InputKeyboard = (props: InputKeyboardProps) => {
	let backspaceInterval: ReturnType<typeof setInterval>;
	const [shiftState, setShiftState] = useSignal<ShiftState>(ShiftState.NONE);
	const shiftUrl = useComputed<string>(() => {
		return ShiftMap[shiftState()];
	});

	const convertChar = (char: string) => {
		if (shiftState() === ShiftState.SHIFT || shiftState() === ShiftState.CAPSLOCK) {
			return char.toUpperCase();
		}
		return char;
	};

	const rows0: Accessor<string>[] = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].map(c => useComputed(() => convertChar(c)));
	const rows1: Accessor<string>[] = ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"].map(c => useComputed(() => convertChar(c)));
	const rows2: Accessor<string>[] = ["a", "s", "d", "f", "g", "h", "j", "k", "l"].map(c => useComputed(() => convertChar(c)));
	const rows3: Accessor<string>[] = ["z", "x", "c", "v", "b", "n", "m"].map(c => useComputed(() => convertChar(c)));

	const downShift = () => {
		if (shiftState() === ShiftState.SHIFT) {
			setShiftState(ShiftState.NONE);
			return;
		}
	};

	const convertWithShift = (char: string) => {
		if (shiftState() === ShiftState.SHIFT || shiftState() === ShiftState.CAPSLOCK) {
			downShift();
			return char.toUpperCase();
		}
		return char;
	};
	let shiftTimeout: ReturnType<typeof setTimeout>;
	const specialChars: Array<SpecialKey> = [{
		icon: shiftUrl, id: "shift",
		x: 0, y: 3, color: "#E0E0E0", cols: 3, lineColor: "#979797",
		down() {
			console.log('down');
			if (shiftState() === ShiftState.SHIFT || shiftState() === ShiftState.CAPSLOCK) {
				setShiftState(ShiftState.NONE);
			} else {
				setShiftState(ShiftState.SHIFT);
			}
			shiftTimeout = setTimeout(() => {
				setShiftState(ShiftState.CAPSLOCK);
			}, 500);
		},
		up() {
			console.log('up');
			clearTimeout(shiftTimeout);
		}
	}, {
		text: "*?123", id: "special",
		x: 0, y: 4, color: "#E0E0E0", cols: 3, lineColor: "#979797"
	}, {
		text: "EN", id: "EN",
		x: 5, y: 4, color: "#E0E0E0", cols: 2, lineColor: "#979797"
	}, {
		text: "", id: "space",
		x: 5, y: 4, color: "#FFFFFF", cols: 10, lineColor: "#979797",
		down: () => {
			handleButton(" ");
		}
	}, {
		text: "Save", textColor: "#FFFFFF", id: "enter",
		x: 17, y: 4, color: "#2196F3", cols: 3, lineColor: "#2196F3",
		down: () => {
			props.onEnter?.();
		}
	}, {
		icon: useComputed(() => CharDeleteUrl), id: "backspace",
		x: 17, y: 3, color: "#E0E0E0", cols: 3, lineColor: "#979797",
		down: () => {
			props.inputController?.()?.setFocus(true);
			props.inputController?.()?.setFocusLock(true);
			props.inputController?.()?.backspaceHandle();
			backspaceInterval = setInterval(() => {
				props.inputController?.()?.setFocus(true);
				props.inputController?.()?.setFocusLock(true);
				props.inputController?.()?.backspaceHandle();
			}, 200);
		},
		up() {
			clearInterval(backspaceInterval);
		}
	}, {
		text: ",", id: ",",
		x: 3, y: 4, color: "#FFFFFF", cols: 2, lineColor: "#FFFFFF",
		down: () => {
			handleButton(",");
		}
	}, {
		text: ".", id: ".",
		x: 15, y: 4, color: "#FFFFFF", cols: 2, lineColor: "#FFFFFF",
		down: () => {
			handleButton(".");
		}
	}];
	const handleButton = (char: string) => {
		setTimeout(() => {
			props.inputController?.()?.setFocus(true);
			props.inputController?.()?.setFocusLock(true);
			props.inputController?.()?.addKey(convertWithShift(char));
		}, 20);
	};
	const renderRows = (rows: Accessor<string>[], y: number, offsetX: number = 0) => {
		return (
			rows.map((char, index) => (
				<ColorDarken color={"#FFFFFF"} activeColor={"#489bf8"} key={index} >
					{(color, setClicked) => (
						<GridElement x={index * 2 + offsetX} y={y} cols={2} rows={1}
							style={{
								display: 'flex',
								"alignItems": "center",
								"justifyContent": "center",
								background: color
							}}
							onPointerDown={() => {
								handleButton(char());
								setClicked(true);
							}}
						>
							<span>{char()}</span>
						</GridElement>
					)}
				</ColorDarken>
			))
		);
	};

	const renderSpecials = () => {
		return (
			specialChars.map((char, index) => (
				<ColorDarken color={char.color!} activeColor={"#489bf8"} key={index} >
					{(color, setClicked) => (
						<GridElement x={char.x!} y={char.y!} cols={char.cols!} rows={1}
							style={{
								display: 'flex',
								"alignItems": "center",
								"justifyContent": "center",
								border: `solid 1px ${char.lineColor}`,
								background: color,
								color: char.textColor || '#000000'
							}}
							onPointerDown={() => {
								char.down?.();
								setClicked(true);
							}}
							onPointerUp={char.up}
						// onpointerupoutside={char.up}
						>
							{char.text && <>
								{!char.textColor && <span>{char.text!}</span>}
								{char.textColor && <span style={{ color: char.textColor! }}>{char.text!}</span>}
							</>}
							{char.icon && <img src={char.icon!()} style={{ height: "10px" }} />}
						</GridElement>
					)}
				</ColorDarken>
			))

		);
	};

	return <>
		<GridLayout rows={5} cols={20} colGap={8} rowGap={8}
			className="w-full h-full select-none min-h-[180px]">
			{renderRows(rows0, 0, 0)}
			{renderRows(rows1, 1, 0)}
			{renderRows(rows2, 2, 1)}
			{renderRows(rows3, 3, 3)}
			{renderSpecials()}
		</GridLayout>
	</>;
};

export default InputKeyboard;
