import type { FunctionComponent } from "react";
import {LL0} from "@/react/core/I18nService.tsx";

export type PaymentSelectTerminalPopuType = {
  className?: string;
  onClose?: () => void;
};

const PaymentSelectTerminalPopu: FunctionComponent<
  PaymentSelectTerminalPopuType
> = ({ className = "", onClose }) => {
  return (
    <div
      className={`w-full h-full relative rounded bg-white-solid-white-100-ffffff flex flex-col items-center justify-start py-6 px-8 box-border gap-[24px] max-w-full max-h-full overflow-auto text-left text-base text-black-solid-black-900-1e1e23 font-mulish ${className}`}
    >
      <div className="self-stretch relative font-semibold">
        {LL0().payment.selectTheDefaultCardReader()}:
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[21px]">
        <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
          <div className="self-stretch relative font-extrabold">{LL0().payment.card()}:</div>
          <div className="flex flex-row flex-wrap items-center justify-start gap-[12px_16px] text-mini text-gray-solid-gray-920-4e4e4e">
            <div className="shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] rounded-10xs bg-gray-solid-gray-130-f4f4f4 overflow-hidden flex flex-row items-center justify-center py-[10.5px] px-[13.5px] relative gap-[10px] border-[0.5px] border-solid border-gray-solid-gray-400-c9cbcc">
              <div className="absolute !m-[0] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff z-[0]" />
              <div className="relative z-[1]">{LL0().payment.terminal()} A</div>
            </div>
            <div className="shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] rounded-10xs bg-gray-solid-gray-130-f4f4f4 overflow-hidden flex flex-row items-center justify-center py-[10.5px] px-[13.5px] relative gap-[10px] border-[0.5px] border-solid border-gray-solid-gray-400-c9cbcc">
              <div className="absolute !m-[0] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff hidden z-[0]" />
              <div className="relative z-[1]">{LL0().payment.terminal()} B</div>
            </div>
            <div className="shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] rounded-10xs bg-gray-solid-gray-130-f4f4f4 overflow-hidden flex flex-row items-center justify-center py-[10.5px] px-[13.5px] relative gap-[10px] border-[0.5px] border-solid border-gray-solid-gray-400-c9cbcc">
              <div className="absolute !m-[0] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff hidden z-[0]" />
              <div className="relative z-[1]">{LL0().payment.terminal()} C</div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
          <div className="self-stretch relative font-extrabold">{LL0().payment.visa()}:</div>
          <div className="flex flex-row flex-wrap items-center justify-start gap-[12px_16px] text-mini text-gray-solid-gray-920-4e4e4e">
            <div className="shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] rounded-10xs bg-gray-solid-gray-130-f4f4f4 overflow-hidden flex flex-row items-center justify-center py-[10.5px] px-[13.5px] relative gap-[10px] border-[0.5px] border-solid border-gray-solid-gray-400-c9cbcc">
              <div className="absolute !m-[0] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff z-[0]" />
              <div className="relative z-[1]">{LL0().payment.terminal()} A</div>
            </div>
            <div className="shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] rounded-10xs bg-gray-solid-gray-130-f4f4f4 overflow-hidden flex flex-row items-center justify-center py-[10.5px] px-[13.5px] relative gap-[10px] border-[0.5px] border-solid border-gray-solid-gray-400-c9cbcc">
              <div className="absolute !m-[0] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff hidden z-[0]" />
              <div className="relative z-[1]">{LL0().payment.terminal()} B</div>
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-end gap-[12px] text-center text-sm text-blue-solid-blue-420-2979ff">
        <div
          className="self-stretch rounded-md bg-white-solid-white-100-ffffff box-border w-auto overflow-hidden shrink-0 flex flex-row items-center justify-center py-[9px] px-4 cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
          onClick={onClose}
        >
          <b className="relative">{LL0().ui.cancel()}</b>
        </div>
        <div className="self-stretch rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden shrink-0 flex flex-row items-center justify-center py-[9px] px-8 box-border text-white-solid-white-100-ffffff">
          <b className="relative">{LL0().ui.save()}</b>
        </div>
      </div>
    </div>
  );
};

export default PaymentSelectTerminalPopu;