import uuid from 'time-uuid'

import type { Order } from '@/data/Order'
import { OrderStatus } from '@/pos/OrderType'

import { srmTransactionLogic } from '../transaction.logic'
import { execTestcase, getCaTaxInfo } from './libs'

type O = Readonly<Order>

export const testRunner029: Record<string, () => Promise<boolean>> = {
  '029.001': () =>
    execTestcase({
      num: 29,
      step: 1,
      device: 'A',
      run: async () => {
        const taxInfo = getCaTaxInfo()
        const o: O = {
          _id: uuid(),
          status: OrderStatus.COMPLETED,
          payments: [{ type: 'other', value: 0 }],
          items: [
            // Main Course
            { name: 'Bouillabaisse', price: 22, quantity: 2, modifiers: [], ...taxInfo },
            // Drink
            { name: 'Bordeaux', price: 18, quantity: 2, modifiers: [], ...taxInfo },
            // Dessert
            { name: 'Crème Brûlée', price: 6.5, quantity: 1, modifiers: [], ...taxInfo },
            { name: 'Tarte Tatin', price: 8, quantity: 1, modifiers: [], ...taxInfo },
          ],
        }

        await srmTransactionLogic.recordClosingReceipt(o, { print: true })
      },
    }),
}
