import type { Order } from "@/data/Order.ts";
import { CommitAction, type OrderStrip } from "@/pos/OrderType.ts";
import delay from "delay";
import { createOrder, resetOrder } from "@/pos/logic/order-reactive.ts";
import { toast } from "react-toastify";
import _ from "lodash";
import { params, setParams } from "@/pos/PosRouter.ts";
import { itemContext, onMovedTablePrint } from "@/react/OrderView/OrderView.tsx";
import { setChangeActiveV } from "@/react/FloorPlanView/FloorPlanView.tsx";
import { generateIds } from "@/pos/logic/order-utils.ts";
import { isQuebecSrmEnabled } from "@/data/PosSettingsSignal"
import { isValidSrmUser } from "@/data/UserHub"
import { LL0 } from "../core/I18nService"
import { srmTransactionLogic } from "@/srm/transaction.logic"

export async function handleGroupSeat(order: Order, order2: Order) {
  if (!order?.seatMode) {
    //change seatMode and push all items into seat 0
    order.commits?.push({ action: CommitAction.CHANGE_SEAT_MODE, seatMode: true })
    await delay(100);

    const seatQuantity = order2?.seatMap?.length
    order.commits?.push({ action: CommitAction.CHANGE_SEAT_QUANTITY, delta: (seatQuantity || 1) + 1, ids: [...generateIds((seatQuantity || 1) + 1)] })
    await delay(300);

    order.commits?.push({ action: CommitAction.HANDLE_ZERO_SEAT });
    await delay(200)
  } else {
    //add more seat
    const seatQuantity = order2?.seatMap?.length > 0 ? order2?.seatMap?.length : 1;
    order.commits?.push({ action: CommitAction.CHANGE_SEAT_QUANTITY, delta: seatQuantity, ids: [...generateIds(seatQuantity!)] });
    await delay(300)
  }
}

export async function handlePushCommit(targetOrder: OrderStrip, order: OrderStrip, isRemainSeat?: boolean)  {
  const seatOriginal = targetOrder?.seatMode ? targetOrder?.seatMap?.length : 0;
  if (itemContext.moveEnable()) {
    //handle move table
    await handleGroupSeat(targetOrder as Order, order as Order);
    const orderItems = order.getMoveOrder!()?.items.filter(i => i.quantity === 0);

    const removeIds = orderItems?.map(c => c._id);
    //filter add product !== 0 quantity
    const filterCommits = order.getMoveOrder!().commits?.filter(c => !removeIds.includes(c._id) && !removeIds.includes(c?.commitId) && c.action !== CommitAction.ASSIGN_MOVE_ORDER);

    filterCommits?.forEach(commit => {
      const { action, _id } = commit;
      const delta = seatOriginal || 1;

      if ([CommitAction.ADD_PRODUCT, CommitAction.ADD_MODIFIER, CommitAction.SPLIT_ITEM].includes(action)) {
        if (action === CommitAction.ADD_PRODUCT) {
          const quantity = order.getMoveOrder!()?.items.find(i => i._id === _id)?.quantity;
          if (quantity !== 1) {
            commit.quantity = quantity;
          }
        }
        _.assign(commit, {seat :delta});
      }

      targetOrder.commits?.push(commit);
    });

    //tìm tất cả item có moveQuantity = 0 chuyển sang moveQuantity = quantity và chuyển vào seat cuối cùng
    await delay(200);
    return
  }

  //handle swift table
  if (isRemainSeat) {
    //if table B is split or user choose to merged into seat
    await handleGroupSeat(targetOrder as Order, order as Order);
    order.getMoveOrder!()?.commits?.forEach(commit => {
      if ([CommitAction.ADD_PRODUCT, CommitAction.ADD_MODIFIER, CommitAction.SPLIT_ITEM].includes(commit.action)) {
        const delta = (seatOriginal === 0) ? (commit?.seat || 0) + 1 : seatOriginal + (commit?.seat || 0);
        _.assign(commit, { seat: delta })
      }
      targetOrder.commits?.push(commit)
    })
  } else {
    //if table B is unsplit and user choose to merge unsplit
    order.getMoveOrder!()?.commits?.forEach(commit => {
      if (commit?.seat != null) {
        delete commit?.seat;
      }
      targetOrder.commits?.push(commit)
    })
  }

  await delay(200)
  setParams({ order: targetOrder as Order, isMoveTable: true });
  order.commits?.push({ action: CommitAction.COMPLETE_MOVE_ORDER, isGroup: true })
}


export async function mergeTable(targetOrder: OrderStrip, currentOrder: OrderStrip, isRemainSeat: boolean) {
  if (isQuebecSrmEnabled() && !isValidSrmUser()) return toast.error(LL0().srm.errors.unauthorized()) 

  const newOrder = createOrder(currentOrder);
  toast.success('Merging table...', {autoClose: 300})

  //create move newOrder
  newOrder.commits!.push({ action: CommitAction.CREATE_MOVE_ORDER });
  newOrder.commits!.push({ action: CommitAction.GROUP_TABLE, seatMode: newOrder.seatMode });
  await delay(300)

  await handlePushCommit(targetOrder, newOrder, isRemainSeat);
  newOrder.commits?.push({ action: CommitAction.PRINT })

  //remove old order A
  currentOrder?.doc?.incrementalRemove();

  //handle state of grouped order
  const order2 = createOrder(resetOrder(params()?.order as Order))

  // Record SRM temporary bill
  if (isQuebecSrmEnabled()) {
    if (order2.seatMode)
      if(order2.isGroupBill) await srmTransactionLogic.recordTemporaryBill(order2)
      else for (const seat of order2.seatMap ?? []) await srmTransactionLogic.recordTemporaryBill(seat)
    else await srmTransactionLogic.recordTemporaryBill(order2)
  }

  onMovedTablePrint(order2).then()
  setChangeActiveV(v => v + 1)
  toast.success('Merged successful!', {autoClose: 300})
}