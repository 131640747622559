import {type FunctionComponent, useState} from "react";


const MultipleKitchenButton: FunctionComponent = ({
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selected, setSelected] = useState<string>("Kitchen 1");

  return (
    <div className="flex flex-col items-start justify-start text-center text-smi text-black-solid-black-855-212121 font-mulish">
      <div onClick={() => setIsOpen(!isOpen)} className="shadow-[1px_1px_6px_rgba(0,_0,_0,_0.08)] flex flex-col items-center justify-start relative">
        <div className="rounded-31xl bg-white-solid-white-100-ffffff shadow-[1px_1px_6px_rgba(0,_0,_0,_0.08)] w-[116px] h-[34px] overflow-hidden shrink-0 flex flex-row items-center justify-start py-0 px-2 box-border z-[0]">
          <img
            className="relative w-6 h-6 object-cover"
            alt=""
            src="/iconlist-icon@2x.png"
          />
          <div className="relative leading-[139.8%] flex items-center justify-center w-14 h-[19px] shrink-0">
            {selected}
          </div>
          {isOpen ?
          <img className="relative w-6 h-6 object-cover" alt="" src="/iconarrow-up-icon@2x.png"/>
            : <img className="relative w-6 h-6 object-cover" alt="" src="/icongeneralarrow-down-icon--black@2x.png"/>}
        </div>
        {isOpen &&
        <div className="my-0 mx-[!important] absolute w-full right-[0%] bottom-[-84px] left-[0%] rounded bg-blue-solid-blue-90-d0e5ff shadow-[0px_3px_4px_rgba(0,_0,_0,_0.3)] overflow-hidden flex flex-col items-center justify-start py-2.5 px-0 box-border gap-[4px] z-[1] text-left text-black-solid-black-700-364150">
          <div className="relative leading-[139.8%] font-semibold" onClick={() => setSelected("Kitchen 2")}>Kitchen 2</div>
          <div className="relative leading-[139.8%] font-semibold" onClick={() => setSelected("Bar 1")}>Bar 1</div>
          <div className="relative leading-[139.8%] font-semibold" onClick={() => setSelected("Bar 2")}>Bar 2</div>
        </div>}

      </div>
    </div>
  );
};

export default MultipleKitchenButton;
