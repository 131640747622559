import type { FunctionComponent } from "react";
import { inputController0 } from "@/react/core/Input.tsx";
import { useSignal, useComputed } from "@/react/core/reactive.ts";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import Autocomplete from "@/react/core/Autocomplete.tsx";
import { users0 } from "@/data/UserSignal.ts";

export type SelectStaffPopupType = {
  className?: string;
  onClose?: () => void;
  onConfirm?: (staffName: string) => void;
};

const SelectStaffPopup: FunctionComponent<SelectStaffPopupType> = ({
  className = "",
  onClose,
  onConfirm,
}) => {
  const [searchStaff, setSearchStaff] = useSignal<string>('');
  const [staffName, setStaffName] = useSignal<string>('');
  const staffs = useComputed(() => users0().filter(u => u.role === "user"))
  const filteredStaffs = useComputed(() => {
    return (
      searchStaff()
        ? staffs().filter(staff => staff.name?.includes(searchStaff()))
        : staffs()
    )
  })
  const filteredStaffNames = useComputed(() => filteredStaffs().map(staff => staff.name))

  function autoCompleteProps() {
    return {
      id: "combo-box-service",
      freeSolo: true,
      className: "flex-1",
      value: staffName(), // ?
      filterOptions: (opts: any) => opts,
      onChange: (e: any, v: any) => {
        // console.log('onChange', v);
        setSearchStaff('')
        setStaffName(v as string || '')
      },
      options: filteredStaffNames(),
      inputValue: searchStaff() || staffName(),
      inputProps: {
        label: 'Services',
        value: searchStaff(),
        onChange: (value: any) => {
          // console.log('inputProps.onChange', value)
          setSearchStaff(value || '')
          setStaffName(value || '')
        },
        className: "w-full font-mulish text-sm text-gray-100"
      },
      renderOption: ({style, className, ...restProps}, option, state) => {
        return (
          <li className="self-stretch box-border h-10 flex flex-row items-center justify-between py-1 px-2 relative border-b-[1px] border-solid border-gray-solid-gray-164-e1e1e1"
              key={option}
              {...restProps}
              onClick={(e) => {
                setStaffName(option)
                restProps.onClick?.(e);
              }}
          >
            { state.index % 2 === 0 &&
              <div className="!flex w-full absolute my-0 mx-[!important] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] bg-[#ddd] hidden z-[0]" />
            }
            <div className="text-left flex-1 relative text-black-solid-black-880-1d1d26 z-[1]">
              <span>{option}</span>
            </div>
          </li>
        )
      }
    }
  }

  return (
    <div
      className={`w-[600px] h-full relative bg-white-solid-white-100-ffffff flex flex-col items-start justify-start pt-8 px-6 pb-6 box-border gap-[24px] max-w-full max-h-full overflow-auto text-left text-base text-blue-solid-blue-500-0051c1 font-mulish ${className}`}
    >
      <div className="min-h-[180px] self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start gap-[11px] z-[0]">
        <b className="self-stretch relative">{LL0().reservation.selectStaff()}:</b>
        <div className="self-stretch flex flex-row items-start justify-start gap-[2px] text-sm text-black-solid-black-900-1e1e23">
          <Autocomplete {...autoCompleteProps()}/>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-end z-[1] text-center text-sm text-white-solid-white-100-ffffff">
        <div className="self-stretch rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8"
             ref={makeRipple}
             onClick={() => {
               onConfirm?.(staffName())
               onClose?.()
             }}
        >
          <b className="relative">{LL0().ui.ok()}</b>
        </div>
      </div>
      <img
        className="w-6 absolute !m-[0] top-[10px] right-[12px] h-6 object-cover cursor-pointer z-[2]"
        alt=""
        src="/icongeneralclose-popup-icon--grey@2x.png"
        onClick={onClose}
      />
      <TableManualKeyboard
        inputController={inputController0}
        onEnter={() => {
          onConfirm?.(staffName())
          onClose?.()
        }}
      />
    </div>
  );
};

export default SelectStaffPopup;
