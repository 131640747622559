import uuid from 'time-uuid'

import type { Order } from '@/data/Order'
import { OrderStatus } from '@/pos/OrderType'
import { signalSyncedWithLocalStorage } from '@/react/core/reactive'

import { completeOrder } from '../order-mutations'
import { srmTransactionLogic } from '../transaction.logic'
import { execTestcase, getCaTaxInfo } from './libs'

type O = Readonly<Order>

const [order01, setOrder01] = signalSyncedWithLocalStorage<O | null>('TESTCASE_024_ORDER_01', null)
const [order02, setOrder02] = signalSyncedWithLocalStorage<O | null>('TESTCASE_024_ORDER_02', null)

export const testRunner024: Record<string, () => Promise<boolean>> = {
  cleanup: async () => {
    setOrder01(null)
    setOrder02(null)
    return true
  },
  '024.001': () =>
    execTestcase({
      num: 24,
      step: 1,
      device: 'A',
      run: async () => {
        const taxInfo = getCaTaxInfo()
        const baseOrder: O = {
          _id: uuid(),
          table: '024',
          status: OrderStatus.IN_PROGRESS,
          payments: [],
          items: [
            // Main Course
            { name: 'Bouillabaisse', price: 22, quantity: 1, modifiers: [], ...taxInfo },
            { name: 'Poutine', price: 33, quantity: 1, modifiers: [], ...taxInfo },
            // Drink
            { name: 'Bordeaux', price: 18, quantity: 1, modifiers: [], ...taxInfo },
            { name: 'Sortilège', price: 28, quantity: 1, modifiers: [], ...taxInfo },
            // Dessert
            { name: 'Crème Brûlée', price: 6.5, quantity: 1, modifiers: [], ...taxInfo },
            { name: 'Tarte Tatin', price: 8, quantity: 1, modifiers: [], ...taxInfo },
          ],
        }
        const order01: O = {
          ...baseOrder,
          seatMap: [
            { ...baseOrder, _id: uuid(), seat: 0, items: [...baseOrder.items.filter((_, i) => i % 2 === 0)] },
            { ...baseOrder, _id: uuid(), seat: 1, items: [...baseOrder.items.filter((_, i) => i % 2 === 1)] },
          ],
        }

        await srmTransactionLogic.recordTemporaryBill(order01, { print: true })

        setOrder01(order01)
      },
    }),
  '024.002': () =>
    execTestcase({
      num: 24,
      step: 2,
      device: 'A',
      run: async () => {
        const taxInfo = getCaTaxInfo()
        const o = order01()
        if (!o) throw new Error('Please run step 1 first')
        const order02: O = { ...o, table: '025', items: [...o.items, { name: 'Tarte Tatin 2', price: 9, quantity: 1, modifiers: [], ...taxInfo }] }
        await srmTransactionLogic.recordTemporaryBill(order02, { print: true })
        setOrder02(order02)
      },
    }),
  '024.003': () =>
    execTestcase({
      num: 24,
      step: 3,
      device: 'A',
      run: async () => {
        const o = order02()
        if (!o) throw new Error('Please run step 2 first')
        await srmTransactionLogic.recordClosingReceipt(completeOrder(o), { print: true })
      },
    }),
}
