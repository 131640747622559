import { type FunctionComponent, useState } from 'react';
import SaleReportTabContent from "./SaleReportTabContent";
import IngredientReportTabContent from "./IngredientReportTabContent";
import { makeRipple } from '@/react/core/ripple-utils.ts';

type MonthlyReportIngredientPoType = {
  onClose?: () => void;
};

enum IngredientTab {
  SALES,
  INGREDIENTS
}

const MonthlyReportIngredientPo: FunctionComponent<
  MonthlyReportIngredientPoType
> = ({ onClose }) => {
  const [ingredientTab, setIngredientTab] = useState<IngredientTab>(IngredientTab.SALES);

  return (
    <div className="relative rounded bg-white-solid-white-100-ffffff w-full h-full overflow-hidden flex flex-col items-end justify-start pt-10 px-3 pb-6 box-border gap-[16px] min-w-[520px] max-w-full max-h-full text-left text-smi text-black-solid-black-1000-000000 font-mulish">
      <div className="self-stretch flex-1 overflow-y-auto flex flex-col items-end justify-start gap-[16px] z-[0]">
        <div className="self-stretch flex flex-row items-center justify-start gap-[16px]">
          <div className="box-border h-[34px] flex flex-row items-center justify-center py-0 px-2.5 relative gap-[8px] border-[1px] border-solid border-gray-solid-gray-300-dbdbdb"
               ref={makeRipple}
               onClick={() => setIngredientTab(IngredientTab.SALES)}
          >
            { ingredientTab === IngredientTab.SALES &&
            <div className="absolute my-0 mx-[!important] h-[calc(100%_+_1px)] w-[calc(100%_+_1px)] top-[-0.5px] right-[-0.5px] bottom-[-0.5px] left-[-0.5px] bg-blue-solid-blue-50-e6f2fc box-border z-[0] border-[1px] border-solid border-blue-solid-blue-170-85c7fe" />
            }
            <div className="relative font-semibold z-[2]">Sales Report</div>
          </div>
          <div className="box-border h-[34px] flex flex-row items-center justify-center py-0 px-2.5 relative gap-[8px] border-[1px] border-solid border-gray-solid-gray-300-dbdbdb"
               ref={makeRipple}
               onClick={() => setIngredientTab(IngredientTab.INGREDIENTS)}
          >
            { ingredientTab === IngredientTab.INGREDIENTS &&
              <div className="!flex absolute my-0 mx-[!important] h-[calc(100%_+_1px)] w-[calc(100%_+_1px)] top-[-0.5px] right-[-0.5px] bottom-[-0.5px] left-[-0.5px] bg-blue-solid-blue-50-e6f2fc box-border hidden z-[0] border-[1px] border-solid border-blue-solid-blue-170-85c7fe" />
            }
            <div className="relative font-semibold z-[2]">
              Ingredient Report
            </div>
          </div>
        </div>
        <div className="self-stretch flex-1 overflow-y-auto shrink-0 flex flex-col items-end justify-start gap-[24px]">
          { ingredientTab === IngredientTab.SALES ?
          <SaleReportTabContent />
            :
          <IngredientReportTabContent />
          }
        </div>
      </div>
      <img
        className="absolute my-0 mx-[!important] top-[12px] right-[12px] w-[21px] h-[21px] object-cover cursor-pointer z-[1]"
        alt=""
        src="/iconclose-popup-icon@2x.png"
        onClick={onClose}
      />
    </div>
  );
};

export default MonthlyReportIngredientPo;
