import type { FunctionComponent } from "react";
import { Switch, FormControlLabel } from "@mui/material";
import _ from 'lodash';
import {LL0} from "@/react/core/I18nService.tsx";

import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";

type EnableVirtualKeyboardPopuType = {
  onClose?: () => void;
};

const EnableVirtualKeyboardPopu: FunctionComponent<
  EnableVirtualKeyboardPopuType
> = ({ onClose }) => {
  const LL = LL0()
  return (
    <div className="w-full h-full relative rounded bg-white overflow-hidden flex flex-col items-center justify-center pt-7 pb-6 pr-6 pl-3 box-border gap-[24px] max-w-full max-h-full">
      <img
        className="absolute my-0 mx-[!important] top-[8px] right-[-242px] w-6 h-6 overflow-hidden shrink-0 object-cover hidden cursor-pointer z-[0]"
        alt=""
        src="/close-popup-icon--grey@2x.png"
        onClick={onClose}
      />
      <FormControlLabel
        className="z-[1]"
        label={LL.dashboard.enableVirtualKeyboard()}
        control={<Switch color="primary"
                         checked={deviceSetting0()?.useVirtualKeyboard || false}
                         onChange={e => _.assign(deviceSetting0(), {useVirtualKeyboard: e.target.checked})}
        />}
      />
      <div
        className="absolute my-0 mx-[!important] top-[11px] right-[13px] w-6 h-6 overflow-hidden shrink-0 cursor-pointer z-[2]"
        onClick={onClose}
      >
        <img
        className="absolute my-0 mx-[!important] cursor-pointer z-[2]"
          alt=""
        src="/close-tab1.svg"
        onClick={onClose}
        />
      </div>
    </div>
  );
};

export default EnableVirtualKeyboardPopu;
