import { type FunctionComponent, useState } from "react";
import { Switch, FormControlLabel, TextField, Icon } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LL0 } from "@/react/core/I18nService.tsx";

import { companyInfo0 } from "@/data/PosSettingsSignal.ts";

export type VoucherApplyTimeType = {
  className?: string;
};

const VoucherApplyTime: FunctionComponent<VoucherApplyTimeType> = ({
  className = "",
}) => {
  const [inputDateTimePicker1Value, setInputDateTimePicker1Value] =
    useState(null);
  const [inputDateTimePicker2Value, setInputDateTimePicker2Value] =
    useState(null);
  const [isApplySpecificTime, setIsApplySpecificTime] = useState(false);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        className={`self-stretch rounded-sm bg-white-solid-white-100-ffffff flex flex-col items-start justify-start pt-4 px-6 pb-5 gap-[16px] text-left text-base text-black-solid-black-1000-000000 font-mulish sm:py-4 sm:px-3 sm:box-border Tablet_768:py-3 Tablet_768:px-2 Tablet_768:box-border ${className}`}
      >
        <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
          <FormControlLabel
            className="w-10"
            control={
              <Switch
                color="primary"
                checked={isApplySpecificTime}
                onChange={(e, checked) => setIsApplySpecificTime(checked)}
                disabled
              />}
          />
          <div className="relative font-medium Tablet_768:text-sm">
            {LL0().voucher.applyToSpecificTimes()}
          </div>
        </div>

        {isApplySpecificTime &&
          <>
            <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
              <div className="self-stretch relative Tablet_768:text-sm">
                {LL0().voucher.daysOfWeek()}
              </div>
              <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[10px_11px] text-mini text-gray">
                <div className="w-32 rounded-10xs bg-gray-solid-gray-130-f4f4f4 box-border h-[30px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-[10.5px] px-5 relative gap-[10px] border-[0.5px] border-solid border-darkgray">
                  <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-blue-solid-blue-30-e3f2fd box-border z-[0] border-[1px] border-solid border-cyan-solid-cyan-450-90caf9" />
                  <div className="relative z-[1] Tablet_768:text-smi">Monday</div>
                </div>
                <div className="w-32 rounded-10xs bg-gray-solid-gray-130-f4f4f4 box-border h-[30px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-[10.5px] px-5 relative gap-[10px] border-[0.5px] border-solid border-darkgray">
                  <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-blue-solid-blue-30-e3f2fd box-border z-[0] border-[1px] border-solid border-cyan-solid-cyan-450-90caf9" />
                  <div className="relative z-[1] Tablet_768:text-smi">Tuesday</div>
                </div>
                <div className="w-32 rounded-10xs bg-gray-solid-gray-130-f4f4f4 box-border h-[30px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-[10.5px] px-5 relative gap-[10px] border-[0.5px] border-solid border-darkgray">
                  <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-blue-solid-blue-30-e3f2fd box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-450-90caf9" />
                  <div className="relative z-[1] Tablet_768:text-smi">
                    Wednesday
                  </div>
                </div>
                <div className="w-32 rounded-10xs bg-gray-solid-gray-130-f4f4f4 box-border h-[30px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-[10.5px] px-5 relative gap-[10px] border-[0.5px] border-solid border-darkgray">
                  <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-blue-solid-blue-30-e3f2fd box-border z-[0] border-[1px] border-solid border-cyan-solid-cyan-450-90caf9" />
                  <div className="relative z-[1] Tablet_768:text-smi">Thursday</div>
                </div>
                <div className="w-32 rounded-10xs bg-gray-solid-gray-130-f4f4f4 box-border h-[30px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-[10.5px] px-5 relative gap-[10px] border-[0.5px] border-solid border-darkgray">
                  <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-blue-solid-blue-30-e3f2fd box-border z-[0] border-[1px] border-solid border-cyan-solid-cyan-450-90caf9" />
                  <div className="relative z-[1] Tablet_768:text-smi">Friday</div>
                </div>
                <div className="w-32 rounded-10xs bg-gray-solid-gray-130-f4f4f4 box-border h-[30px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-[10.5px] px-5 relative gap-[10px] border-[0.5px] border-solid border-darkgray">
                  <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-blue-solid-blue-30-e3f2fd box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-450-90caf9" />
                  <div className="relative z-[1] Tablet_768:text-smi">Saturday</div>
                </div>
                <div className="w-32 rounded-10xs bg-gray-solid-gray-130-f4f4f4 box-border h-[30px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-[10.5px] px-5 relative gap-[10px] border-[0.5px] border-solid border-darkgray">
                  <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-blue-solid-blue-30-e3f2fd box-border z-[0] border-[1px] border-solid border-cyan-solid-cyan-450-90caf9" />
                  <div className="relative z-[1] Tablet_768:text-smi">Sunday</div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
              <div className="self-stretch relative Tablet_768:text-sm">
                {LL0().voucher.timeOfDay()}
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[16px]">
                <div className="flex-1 max-w-[180px]">
                  <TimePicker
                    ampm={companyInfo0()?.country !== 'de'}
                    value={inputDateTimePicker1Value}
                    onChange={(newValue: any) => {
                      setInputDateTimePicker1Value(newValue);
                    }}
                    sx={{}}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        required: false,
                        autoFocus: false,
                        error: false,
                        color: "primary",
                        helperText: "",
                      },
                      openPickerIcon: {
                        component: () => <></>,
                      },
                    }}
                  />
                </div>
                <div className="relative">{LL0().report.to()}</div>
                <div className="flex-1 max-w-[180px]">
                  <TimePicker
                    ampm={companyInfo0()?.country !== 'de'}
                    value={inputDateTimePicker2Value}
                    onChange={(newValue: any) => {
                      setInputDateTimePicker2Value(newValue);
                    }}
                    sx={{}}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        required: false,
                        autoFocus: false,
                        error: false,
                        color: "primary",
                        helperText: "",
                      },
                      openPickerIcon: {
                        component: () => <></>,
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        }
      </div>
    </LocalizationProvider>
  );
};

export default VoucherApplyTime;
