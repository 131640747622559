import { captureException } from '@sentry/react'
import axios, { AxiosError } from 'axios'
import debug from 'debug'
import _ from 'lodash'
import type { ChangeEvent } from 'react'
import { toast } from 'react-toastify'
import uuid from 'time-uuid'

import { Category } from '@/data/Category'
import { categories0 } from '@/data/CategoryHub'
import { groupPrinters0 } from '@/data/GroupPrinterHub'
import { Modifier, type ModifierItem } from '@/data/Modifier'
import { modifiers0 } from '@/data/ModifierHub'
import { ModifierSync } from '@/data/ModifierSync.ts'
import { onlineProviders0 } from '@/data/OnlineProviderHub.ts'
import { mainScreen } from '@/data/PosSettingsSignal.ts'
import { posSync0 } from '@/data/PosSyncState.ts'
import { type ORDER_RESTRICTIONS, Product, type RECURRING_WEEKDAYS, type ProductVariant } from '@/data/Product'
import { products0 } from '@/data/ProductHub'
import { ProductSync } from '@/data/ProductSync'
import type { TaxCategory } from '@/data/TaxCategory.ts'
import { applyDefaultTaxByCountry } from '@/data/utils/applyDefaultTaxByCountry.ts'
import { MarketPlaceProvider } from '@/pos/OrderType.ts'
import { PosScreen, router } from '@/pos/PosRouter'
import { computed, effect, signal } from '@/react/core/reactive'
import { menus, selectedMenuId } from '@/react/Dashboard.logic'
import { hasUberEatsProvider } from '@/react/OnlineOrderSettingView/OnlineProviderSettingView.logic.tsx'
import appHook from '@/shared/app-hook.ts'
import { bound } from '@/shared/decorators.ts'
import { uploadFile } from '@/shared/FileUploader.ts'
import { getDeviceId } from '@/shared/getDeviceId.ts'
import { getApiUrl } from '@/shared/utils.ts'
import { getUrlParam } from '@/shared/utils2.ts'

import { numberFromString, numberToString, toDataUrl } from '../core/utils'
import { orderLayout0 } from '../OrderView/OrderView.tsx'
import { setPreSelectedProducts } from '../SyncMenuView/SyncMenuView'
import dialogService from '../SystemService/dialogService.tsx'
import msgBox from '../SystemService/msgBox.tsx'
import { resizeImageFile } from "@/react/shared/utils.ts";

export enum Tabs {
  MenuList,
  OptionList,
  CreateDiscount,
  TaxSetting,
}

const log = debug('data:online-menu')

/*******************[ States ]*********************/
const [selectedTab, setSelectedTab] = signal(Tabs.MenuList)
const [editingCategoryId, setEditingCategoryId] = signal<string | undefined>()
const [editingModifierId, setEditingModifierId] = signal<string | undefined>()
const [editingProductId, setEditingProductId] = signal<string | undefined>()
const [expandedCategoryIds, setExpandedCategoryIds] = signal<string[]>([])
const [editingModifierGroupId, setEditingModifierGroupId] = signal<string | undefined>()
const [importingCategoryId, setImportingCategoryId] = signal<string | undefined>()

function resetStates() {
  setSelectedTab(Tabs.MenuList)
  setEditingCategoryId(undefined)
  setEditingModifierId(undefined)
  setEditingProductId(undefined)
  setExpandedCategoryIds([])
  setEditingModifierGroupId(undefined)
  setImportingCategoryId('')
}

/*******************[ Derived States ]*********************/

const _checkWithSelectedMenu = <T extends { menus?: string[] }>(a: T) => {
  const id = selectedMenuId()
  if (!id) return false // No Menu selected
  return a.menus?.includes(id) ?? false
}
export const getTax = (taxCategory: TaxCategory): number => {
  if (taxCategory.value) return taxCategory.value
  if (taxCategory.components) {
    return taxCategory?.components?.reduce((accumulator, currentValue) => {
      return accumulator + (currentValue?.value || 0)
    }, 0)
  }
  return 0
}
const categories = computed(() => _.sortBy(categories0().filter(_checkWithSelectedMenu), ['position']))
const products = computed(() => _.sortBy(products0().filter(_checkWithSelectedMenu), ['priority']))
const modifiers = computed(() => _.sortBy(modifiers0().filter(_checkWithSelectedMenu), ['position']))
const modifierIds = computed(() => modifiers().map(m => m._id))
const printers = computed(() => groupPrinters0().filter(a => a.type === 'kitchen' || a.type === 'label'))
// const rooms = computed(() => _.sortBy(rooms0(), ['order']))
// const options = computed(() => modifiers().map(a => ({ value: a._id ?? '', text: a.name ?? '' })))

const editingCategory = computed(() => categories().find(a => a._id === editingCategoryId()))
export const editingProduct = computed(() => products().find(a => a._id === editingProductId()))
const editingModifier = computed(() => modifiers().find(a => a._id === editingModifierId()))
const editingModifierGroup = computed(() => editingModifier()?.itemGroups?.find(a => a._id === editingModifierGroupId()))
const editingModifierType = computed(() => editingModifier()?.type)
const onlineProviders = computed(() => onlineProviders0().filter(a => a.name !== 'Sample Provider'))
const isSyncAvailable = computed(() => !!posSync0()?.id && onlineProviders().length > 0)

/*******************[ Views ]*********************/
let createCategoryCounter = 1
let createProductCounter = 1

export const STATES = {
  // states
  editingCategoryId,
  editingModifier,
  editingModifierId,
  editingModifierType,
  editingModifierGroup,
  editingModifierGroupId,
  editingProduct,
  expandedCategoryIds,
  categories,
  modifiers,
  printers,
  products,
  selectedTab,
  isSyncAvailable,
}

export const VIEW__TOP_NAV = {
  onGoBack: () => {
    resetStates() // Reset state when exit
    router.screen = mainScreen()
  },
  onMenuListClick: () => setSelectedTab(Tabs.MenuList),
  onOptionListClick: () => setSelectedTab(Tabs.OptionList),
}
export const VIEW__CATEGORY = {
  getPrinterName: (id?: string) => groupPrinters0().find(a => a._id === id)?.name,
  onPreview: () => {},
  onImport: async (categoryId: string) => {
    setImportingCategoryId(categoryId)

    const alreadySyncedIds = products()
      .filter(a => a.categories?.includes(categoryId))
      .filter(a => !!a.syncingHash)
      .map(a => a._id)

    if (alreadySyncedIds.length) {
      const syncSourceIds = await ProductSync.find({ selector: { sync_target: { $in: alreadySyncedIds } } })
        .exec()
        .then(a => a.map(b => b.sync_source))
      const syncSources = orderLayout0()
        .categories.flatMap(a => a.products)
        .filter(a => syncSourceIds.includes(a?.product ?? ''))
      setPreSelectedProducts(syncSources as any)
    } else {
      setPreSelectedProducts([])
    }
    router.screen = PosScreen.SYNC_MENU
  },
  onImportComplete: async (items: Product[], withSync?: boolean) => {
    router.screen = PosScreen.EDIT_ONLINE_MENU
    const categoryId = importingCategoryId()
    const menuId = selectedMenuId()
    const deviceId = getDeviceId()

    if (!items.length || !categoryId) return

    const alreadySyncedProductIds = products()
      .filter(product => product.categories?.includes(categoryId))
      .filter(product => !!product.syncingHash)
      .map(product => product._id)
    console.log('alreadySyncedProductIds', alreadySyncedProductIds)

    const alreadySyncedProductSourceIds = await ProductSync.find({ selector: { sync_target: { $in: alreadySyncedProductIds } } })
      .exec()
      .then(productSyncs => productSyncs.map(sync => sync.sync_source))
    console.log('alreadySyncedProductSourceIds', alreadySyncedProductSourceIds)

    const productsNotSyncYet = items.filter(product => !alreadySyncedProductSourceIds.includes(product._id))
    console.log('productsNotSyncYet', productsNotSyncYet)

    const alreadySyncModifierIds = modifiers().map(modifier => modifier._id)
    console.log('alreadySyncModifierIds', alreadySyncModifierIds)

    const alreadySyncedModifierSourceMap = await ModifierSync.find({ selector: { sync_target: { $in: alreadySyncModifierIds } } })
      .exec()
      .then(modifierSyncs =>
        modifierSyncs.reduce((cur, v) => {
          cur[v.sync_source] = v.sync_target
          return cur
        }, {} as Record<string, string>)
      )
    console.log('alreadySyncedModifierSourceMap', alreadySyncedModifierSourceMap)

    // import modifiers
    const modifiersInProductsNotSyncYet = _.uniq(_.flatten(productsNotSyncYet.map(product => product.activePopupModifierGroup || [])))
    console.log('modifiersInProductsNotSyncYet', modifiersInProductsNotSyncYet)

    const modifierTax: Record<string, number> = {}
    for (const product of productsNotSyncYet) {
      if (!product.activePopupModifierGroup) continue
      for (const modifier of product.activePopupModifierGroup) {
        modifierTax[modifier] = _.sumBy(product.taxComponents2, comp => comp.value || 0) || _.sumBy(product.taxComponents, comp => comp.value || 0) || 0
      }
    }
    console.log('modifierTax', modifierTax)

    // Collect all modifier not synced yet
    const modifiersNotSyncYet: Array<string> = []
    for (const modifier of modifiersInProductsNotSyncYet) {
      if (!alreadySyncedModifierSourceMap[modifier]) {
        const sync_target = uuid()
        modifiersNotSyncYet.push(modifier)
        alreadySyncedModifierSourceMap[modifier] = sync_target
      }
    }
    console.log('modifierNotSyncYet', modifiersNotSyncYet)

    // Clone modifier not synced yet
    const dineInModifiersNotSyncYet = (
      await Modifier.find({
        selector: { _id: { $in: modifiersNotSyncYet } },
      }).exec()
    ).map(doc => doc.toMutableJSON())
    console.log('dineInModifiersNotSyncYet', dineInModifiersNotSyncYet)

    let position = modifiers().length
    const onlineModifiersWillBeAdded = dineInModifiersNotSyncYet.map(modifier =>
      Object.assign({}, modifier, {
        _id: alreadySyncedModifierSourceMap[modifier._id],
        type: modifier.selectOne ? 'onlyOne' : 'multiple',
        menus: [menuId],
        mandatory: false,
        position: position++,
        items: _.map(modifier.items, (item, index) =>
          Object.assign({}, item, {
            position: index,
            isEnabled: true,
            typeQuantity_itemCount: 1,
            typeQuantity_selectLimit: 1,
            tax: modifierTax[modifier._id] || 0,
          })
        ),
      })
    )
    console.log('onlineModifierWillBeAdded', onlineModifiersWillBeAdded)

    await Modifier.bulkInsert(onlineModifiersWillBeAdded)
    await ModifierSync.bulkInsert(
      _.flatten(
        modifiersNotSyncYet.map(modifierId => [
          { _id: uuid(), deviceId, sync_source: modifierId, sync_target: alreadySyncedModifierSourceMap[modifierId] },
          { _id: uuid(), deviceId, sync_source: alreadySyncedModifierSourceMap[modifierId], sync_target: modifierId },
        ])
      )
    )

    // import products
    type ProductId = string
    type OnlineOrderProductId = string
    const productSyncMap: Record<ProductId, OnlineOrderProductId> = {}
    const newItems = productsNotSyncYet.map(product => {
      const newId = uuid()
      productSyncMap[product._id] = newId
      return <Product>{
        ..._.omit(product, ['doc']),
        _id: newId,
        categories: [categoryId],
        menus: [menuId],
        isEnabled: true,
        tax2: _.sumBy(product.taxComponents2, comp => comp.value || 0) || _.sumBy(product.taxComponents, comp => comp.value || 0) || 0,
        modifiers: _.map(product.activePopupModifierGroup, modifierId => alreadySyncedModifierSourceMap[modifierId]),
        // In sync mode, set new hash to indicate that this item is synced:
        ...(withSync ? { syncingHash: uuid() } : {}),
      }
    })
    const result = await Product.bulkInsert(newItems)
    if (result.error.length) return log('⚠️ Failed to import!', result.error)
    log('✅ Imported', result.success.length, 'products', result.success, withSync)
    if (withSync) {
      const productSyncs = Object.entries(productSyncMap).flatMap(([productId, onlineOrderProductId]) => [
        { _id: uuid(), deviceId, sync_source: productId, sync_target: onlineOrderProductId },
        { _id: uuid(), deviceId, sync_source: onlineOrderProductId, sync_target: productId },
      ])
      log('⚡️ Setup synchronization...', productSyncs)
      const result = await ProductSync.bulkInsert(productSyncs)
      if (result.error.length) return log('⚠️ Failed to setup Product synchronization!', result.error)
      log('✅ Setup sync for', result.success.length / 2, 'product(s)!')
    }
  },
  onSync: async () => {
    const posId = posSync0()?.id
    if (!posId) return

    const selectedMenu = menus().find(menu => menu._id === selectedMenuId())

    if (!selectedMenu) {
      const error = `[Sync menu] Invalid menu name with id ${selectedMenuId()}`
      toast.error(`${error}. Please contact us for support!`)
      captureException(new Error(error))
      return
    }
    const providers = onlineProviders().filter(p => selectedMenu.providers?.includes(p._id))

    if (!providers.length) {
      const error = `[Sync menu] Unable to find provider of menu ${selectedMenu.name}`
      toast.error(`${error}. Please contact us for support!`)
      captureException(new Error(error))
      return
    }

    for (const p of providers) {
      log('⚡️ Start syncing with provider', p)
      const closeProgress = dialogService.progress({ title: `Syncing "${p.$name}"...` })
      try {
        switch (p.name) {
          case MarketPlaceProvider.PIKAPOINT: {
            const { data } = await axios.post(`${getApiUrl()}/api/pika/sync`, { posId })
            toast(`"${p.name}" menu synchronized`)
            log(`✅ Sync "${p.name}" triggered!`, data)
            break
          }
          case MarketPlaceProvider.DELIVERECT: {
            const { data } = await axios.get(`${getApiUrl()}/api/deliverect/sync-product`, { params: { storeId: p.storeId } })
            toast(`"${p.name}" menu synchronized`)
            log(`✅ Sync "${p.name}" triggered!`, data)
            break
          }
          case MarketPlaceProvider.UBER_EATS: {
            const { data } = await axios.post(`${getApiUrl()}/api/uber-eats/sync`, { posId })
            toast(`"${p.name}" menu synchronized`)
            log(`✅ Sync "${p.name}" triggered!`, data)
            break
          }
          default:
            throw new Error(`Provider "${p.name}" is unsupported!`)
        }
      } catch (e) {
        log('🛑 Failed to sync', e)
        let errMsg = 'Unknown Error!'
        if (e instanceof AxiosError) errMsg = e.response?.data.error
        else if (e instanceof Error) errMsg = e.message
        await msgBox.show('Info', 'Sync failed: ' + errMsg, msgBox.Buttons.OK, msgBox.Icons.Error)
      } finally {
        closeProgress()
      }
    }
  },
  onExport: () => {
    log('⚡️ onExport')
  },
  onToggleExpand: (id: string) => setExpandedCategoryIds(ids => (ids.includes(id) ? ids.filter(_id => _id !== id) : [...ids, id])),
  onExpand: (id: string) => setExpandedCategoryIds(ids => (ids.includes(id) ? ids : [...ids, id])),
  onCreate: async () => {
    log('⚡️ Creating new category...')
    const menuId = selectedMenuId()
    if (!menuId) throw new Error('No menu selected!')
    const lastPos =
      (await Category.findOne({ sort: [{ position: 'desc' }] })
        .exec()
        .then(p => p?.position)) ?? 0
    const newItem = await Category.insert({
      _id: uuid(),
      name: `New Course ${createCategoryCounter++}`,
      position: lastPos + 1,
      menus: [menuId],
    })
    log('⚡️ New category created', newItem)
    VIEW__CATEGORY.onExpand(newItem._id)
  },
  onEdit: (id?: string) => setEditingCategoryId(id),
  onChanged: async (prop: string, val: unknown) => {
    const m = editingCategory()
    if (m) {
      log('⚡️ Updating Category', prop, val)
      await m.doc?.incrementalUpdate({ $set: { [prop]: val } })
    }
  },
  onRemove: async (id?: string) => {
    log('⚡️ Revoming category', id)
    await Category.find({ selector: { _id: id } }).remove()
  },
  onMoveUp: async (id?: string) => {
    log('⚡️ Moving category up...', id)
    const doc = await Category.findOne({ selector: { _id: id } }).exec()
    const preDoc = await Category.findOne({
      selector: { position: { $lt: doc?.position } },
      sort: [{ position: 'desc' }],
    }).exec()

    const pos = [doc?.position ?? 1, preDoc?.position ?? 1]
    await doc?.update({ $set: { position: pos[1] } })
    await preDoc?.update({ $set: { position: pos[0] } })
  },
  onMoveDown: async (id?: string) => {
    log('⚡️ Moving category down...', id)
    const doc = await Category.findOne({ selector: { _id: id } }).exec()
    const preDoc = await Category.findOne({
      selector: { position: { $gt: doc?.position } },
      sort: [{ position: 'asc' }],
    }).exec()

    const pos = [doc?.position ?? 1, preDoc?.position ?? 1]
    await doc?.update({ $set: { position: pos[1] } })
    await preDoc?.update({ $set: { position: pos[0] } })
  },
  onMoveProductUp: async (categoryId: string, id?: string) => {
    log('⚡️ Moving product up...', categoryId, id)
  },
  onMoveProductDown: async (categoryId: string, id?: string) => {
    log('⚡️ Moving product down...', categoryId, id)
  },
}

@bound()
class ProductViewLogic {
  async onCreate(categoryId?: string) {
    if (!categoryId) return
    log('⚡️ Creating new Product...')
    const menuId = selectedMenuId()
    if (!menuId) throw new Error('No menu selected!')
    const lastPos =
      (await Product.findOne({ sort: [{ position: 'desc' }] })
        .exec()
        .then(p => p?.position)) ?? 0

    const model: Product = {
      _id: uuid(),
      id: '000',
      name: `New Product ${createProductCounter++}`,
      price: 1,
      position: lastPos + 1,
      isEnabled: true,
      categories: [categoryId],
      menus: [menuId],
    }
    const countryCode = getUrlParam('cn') || import.meta.env.VITE_COUNTRY
    applyDefaultTaxByCountry(countryCode)(model)
    const newItem = await Product.insert(model)
    log('⚡️ new Product created', newItem)
    setEditingProductId(newItem._id)
    setEditingCategoryId(categoryId)
  }
  onCreateMore() {
    return this.onCreate(editingCategoryId())
  }
  async onDelete(id?: string) {
    log('⚡️ Deleting product...', id)
    await Product.find({ selector: { _id: id } }).remove()
    if (id === editingProductId()) {
      setEditingProductId(undefined)
      setEditingCategoryId(undefined)
    }
  }
  onDeleteCurrent() {
    return this.onDelete(editingProductId())
  }
  async onEdit(id: string, categoryId: string) {
    setEditingProductId(id)
    setEditingCategoryId(categoryId)
  }
  async onChanged<T extends keyof Product, V = Product[T]>(prop: T, val: V) {
    const p = editingProduct()
    if (p && val !== p[prop]) {
      if (prop === 'modifiers') {
        val = _.intersection(val as string[], modifierIds()) as V
      }
      log('⚡️ Updating Product', prop, val)
      await p.doc?.incrementalUpdate({ $set: { [prop]: val } })
    }
  }
  onEndEditing() {
    setEditingProductId(undefined)
    setEditingCategoryId(undefined)
  }
  async onSetProductEnabled(productId: string, val: boolean) {
    await Product.find({ selector: { _id: productId } }).update({ $set: { isEnabled: val } })
  }
  async onToogleOrderRestriction(key: string) {
    log('⚡️ onToogleOrderRestriction', key)
    const p = editingProduct()
    if (p) {
      const oldVal = p.orderRestrictions ?? []
      if (oldVal?.includes(key as ORDER_RESTRICTIONS)) await p.doc?.incrementalUpdate({ $pullAll: { orderRestrictions: [key] } })
      else await p.doc?.incrementalUpdate({ $addToSet: { orderRestrictions: key } })
    }
  }
  async onToogleValidityRecurringWeekDays(key: string) {
    log('⚡️ onToogleValidityRecurringWeekDays', key)
    const p = editingProduct()
    if (p) {
      const oldVal = p.validity_recurringWeekDays ?? []
      if (oldVal?.includes(key as RECURRING_WEEKDAYS)) await p.doc?.incrementalUpdate({ $pullAll: { validity_recurringWeekDays: [key] } })
      else await p.doc?.incrementalUpdate({ $addToSet: { validity_recurringWeekDays: key } })
    }
  }
  async onUploadImage(file: File, mode: 'attachment' | 'dataUrl' = 'dataUrl', base64Image?: string) {
    const p = editingProduct()
    if (!p) return
    // remove previous img
    if (p.image && p.image.startsWith('attachment:')) await p.doc?.getAttachment(p.image)?.remove()
    if (!file && !base64Image) return await p.doc?.incrementalUpdate({ $set: { image: null } })
    if (!base64Image) {
      file = await resizeImageFile(file)
    }
    switch (mode) {
      case 'dataUrl': {
        log('⚡️ Converting image to dataUrl', file)
        const url = base64Image || (await toDataUrl(file))
        log('> result', url)
        const imageUrl = await uploadFile('images', file, progress => console.log('progress', progress))
        await p.doc?.incrementalUpdate({ $set: { image: url, imageUrl } })
        break
      }
      case 'attachment': {
        log('⚡️ Uploading image ', file)
        const id = `attachment:${uuid()}`
        await p.doc?.putAttachment({ id, data: file, type: file.type })
        await p.doc?.incrementalUpdate({ $set: { image: id } })
        break
      }
      default:
        log('⚠️ Unknown mode!', mode)
    }
  }
  async onSetImageUrl(url: string | null) {
    const p = editingProduct()
    if (!p) return

    await p.doc?.incrementalUpdate({ $set: { image: url } })
  }
  async onCreateVariant() {
    const p = editingProduct()
    if (p) {
      const newId = uuid()
      await p.doc?.incrementalUpdate({
        $push: {
          variants: {
            _id: newId,
            name: 'New Variant',
            isEnabled: true,
          },
        },
      })
    }
  }
  async onVariantChanged<T extends keyof ProductVariant, V = ProductVariant[T]>(id: string, prop: T, val: V) {
    const p = editingProduct()
    const v = p?.variants?.find(a => a._id === id)
    if (p && v) {
      const index = p.variants?.indexOf(v)
      await p.doc?.incrementalUpdate({ $set: { [`variants.${index}.${prop}`]: val } })
    }
  }
  onDisableVariant(id: string) {
    return this.onVariantChanged(id, 'isEnabled', false)
  }
  async onDeleteVariant(id?: string) {
    const p = editingProduct()
    const v = p?.variants?.find(a => a._id === id)
    if (p && v) await p.doc?.incrementalUpdate({ $pullAll: { variants: [v] } })
  }
}
export const VIEW__PRODUCT = new ProductViewLogic()

export const VIEW__MODIFIER = {
  onCreate: async () => {
    log('⚡️ creating new modifier...')
    const menuId = selectedMenuId()
    if (!menuId) throw new Error('⚠️ No menu selected!')
    const lastPos =
      (await Modifier.findOne({ sort: [{ position: 'desc' }] })
        .exec()
        .then(p => p?.position)) ?? 0
    const newItem = await Modifier.insert({
      _id: uuid(),
      name: `New Option ${createCategoryCounter++}`,
      position: lastPos + 1,
      selectOne: true,
      type: 'onlyOne',
      menus: [menuId],
    })
    setEditingModifierId(newItem._id)
    log('⚡️ new modifier created', newItem)
  },
  onDelete: async (id?: string) => {
    log('⚡️ Deleting modifier...', id)
    await Modifier.find({ selector: { _id: id } }).remove()
    if (id === editingModifierId()) setEditingModifierId(undefined)
  },
  onDeleteCurrent: () => VIEW__MODIFIER.onDelete(editingModifierId()),
  onChanged: async <T extends keyof Modifier, V = Modifier[T]>(prop: T, val: V) => {
    log('⚡️ onChanged', prop, val)
    const p = editingModifier()
    if (!p) return log('⚠️ No editing Modifier!')
    if (val !== p[prop]) {
      log('⚡️ updating Modifier...', prop, val)
      await p.doc?.incrementalUpdate({ $set: { [prop]: val } })
    }
  },
  onEdit: async (id: string) => setEditingModifierId(id),
  onEndEditing: () => {
    setEditingModifierId(undefined)
    setEditingModifierGroupId(undefined)
  },
  onCreateItem: async () => {
    log("⚡️ creating new modifier's item...")
    const p = editingModifier()
    if (!p) return log('⚠️ No editing Modifier!')
    const lastPos =
      p.items
        ?.map(a => a.position ?? 0)
        .sort((a, b) => a - b)
        .pop() ?? 0
    const newItem = await p.doc?.incrementalUpdate({
      $push: {
        items: {
          _id: uuid(),
          name: 'New Item',
          position: lastPos + 1,
          isEnabled: true,

          typeQuantity_itemCount: 1,
          typeQuantity_selectLimit: 1,
          typeSelect_group: editingModifierGroupId(),
        },
      },
    })
    log("⚡️ new modifier's item created", newItem)
  },
  onCreateItemGroup: async () => {
    const p = editingModifier()
    if (!p) return log('⚠️ No editing Modifier!')
    const newId = uuid()
    await p.doc?.incrementalUpdate({
      $push: {
        itemGroups: {
          _id: newId,
          name: 'New Selection',
        },
      },
    })
    setEditingModifierGroupId(newId)
  },
  onItemChanged: async <T extends keyof ModifierItem, V = ModifierItem[T]>(itemId: string, prop: T, val: V) => {
    log('⚡️ onChanged', itemId, prop, val)
    const p = editingModifier()
    if (!p) return log('⚠️ No editing Modifier!')
    const item = p.items?.find(a => a._id === itemId)
    if (item && val !== item[prop]) {
      const index = p.items?.indexOf(item)
      log("⚡️ updating Modifier's item", index, prop, val)
      await p.doc?.incrementalUpdate({ $set: { [`items.${index}.${prop}`]: val } })
      appHook.emit('modifier:updated', p)
    }
  },
  onItemDelete: async (id?: string) => {
    log('⚡️ Deleting modifier item...', id)
    const p = editingModifier()
    if (!p) return log('⚠️ No editing Modifier!')
    const itemToRemove = p.items?.find(a => a._id === id)
    if (!itemToRemove) return log('⚠️ No item to remove!')
    log('⚡️ Deleting modifier item...', itemToRemove)
    await p.doc?.incrementalUpdate({ $pullAll: { items: [itemToRemove] } })
  },
  onItemGroupChanged: async <T extends keyof ModifierItem, V = ModifierItem[T]>(groupId: string | undefined, prop: T, val: V) => {
    const p = editingModifier()
    if (!p) return log('⚠️ No editing Modifier!')
    const g = p.itemGroups?.find(a => a._id === groupId)
    if (!g) return log('⚠️ No item group!')
    const index = p.itemGroups?.indexOf(g)
    await p.doc?.incrementalUpdate({ $set: { [`itemGroups.${index}.${prop}`]: val } })
  },
  onItemGroupNameChanged: (e: ChangeEvent<HTMLInputElement>) => VIEW__MODIFIER.onItemGroupChanged(editingModifierGroupId(), 'name', e.target.value),
  onItemGroupDelete: async (id?: string) => {
    const p = editingModifier()
    if (!p) return log('⚠️ No editing Modifier!')
    const g = p.itemGroups?.find(a => a._id === id)
    if (!g) return log('⚠️ No item group!')
    log('⚡️ Deleting modifier item group...', g)
    await p.doc?.incrementalUpdate({ $pullAll: { itemGroups: [g] } })
    // Clear selection if currently selected
    if (editingModifierGroupId() === id) setEditingModifierGroupId(undefined)
  },
  onCurrentItemGroupDelete: () => VIEW__MODIFIER.onItemGroupDelete(editingModifierGroupId()),
  onItemGroupEdit: async (id: string) => setEditingModifierGroupId(id),
  onItemGroupEndEditing: () => setEditingModifierGroupId(undefined),
}
export const VIEW__MODIFIER_FORM = {
  nameValue: computed(() => editingModifier()?.name),
  nameChanged: (e: ChangeEvent<HTMLInputElement>) => VIEW__MODIFIER.onChanged('name', e.target.value),
  mandatoryValue: computed(() => editingModifier()?.mandatory),
  mandatoryChanged: (_: ChangeEvent, checked: boolean) => VIEW__MODIFIER.onChanged('mandatory', checked),
  minSelectedValue: computed(() => numberToString(editingModifier()?.minSelected)),
  minSelectedChanged: (e: ChangeEvent<HTMLInputElement>) => VIEW__MODIFIER.onChanged('minSelected', numberFromString(e.target.value)),
  freeSelectedValue: computed(() => numberToString(editingModifier()?.freeSelected)),
  freeSelectedChanged: (e: ChangeEvent<HTMLInputElement>) => VIEW__MODIFIER.onChanged('freeSelected', numberFromString(e.target.value)),
}
/*******************[ Effects ]*********************/

// Expand the first category
let flag_expanded = false
effect(() => {
  if (flag_expanded) return
  const c = STATES.categories()
  if (c.length) {
    VIEW__CATEGORY.onExpand(c[0]._id)
    flag_expanded = true
  }
})

/*******************[ Debugs ]*********************/

// effect(() => log('🪲 categories', STATES.categories()))
// effect(() => log('🪲 editingProduct', STATES.editingProduct()))
// effect(() => log('🪲 editingModifier', STATES.editingModifier()))
// effect(() => log('🪲 modifiers', STATES.modifiers()))
// effect(() => log('🪲 editingModifierGroupId', STATES.editingModifierGroupId()))
// effect(() => {
//   const id = editingModifierId()
//   const item = editingModifier()
//   if (!!id && !item) log('⚠️ Warning Modifier not found', id)
// })
effect(() => log('🪲 isSyncAvailable', STATES.isSyncAvailable()))
Object.assign(window, { EDITONLINEMENU_STATES: STATES })

appHook.on('product:updated', async (doc: any) => {
  console.log('hook:product:updated')
  if (hasUberEatsProvider() && Array.isArray(doc.menus) && doc.menus.length) {
    const { data } = await axios.post(`${getApiUrl()}/api/uber-eats/update-item`, {
      posId: posSync0().id,
      itemId: doc._id,
      type: 'product',
    })
    console.log('resp', data)
  }
})

appHook.on('modifier:updated', async (doc: any) => {
  console.log('hook:modifier:updated')
  if (hasUberEatsProvider() && Array.isArray(doc.menus) && doc.menus.length) {
    const { data } = await axios.post(`${getApiUrl()}/api/uber-eats/update-item`, {
      posId: posSync0().id,
      itemId: doc._id,
      type: 'modifier',
    })
    console.log('resp', data)
  }
})
