import dayjs from 'dayjs'
import delay from 'delay'
import _ from 'lodash'
import { toast } from 'react-toastify'
import uuid from 'time-uuid'

import { collectionsMap } from '@/data/CollectionsMap.ts'
import { deleteMany } from '@/data/data-utils.ts'
import { dataLock } from '@/data/DataUtils.ts'
import { signal } from '@/react/core/reactive.ts'
import SurrealClient from '@/shared/SurrealClient.ts'
import { server } from "@/data/Server.ts";
import { posSync0 } from "@/data/PosSyncState.ts";
import pako from "pako";
import { Buffer } from "buffer";

export const [menuNameUpload, setMenuNameUpload] = signal<string>()
export const [menuNameDownload, setMenuNameDownload] = signal<string>()
export const [dbNameUpload, setDbNameUpload] = signal<string>()
export const [dbNameDownload, setDbNameDownload] = signal<string>()
export const [processing, setProcessing] = signal<boolean>(false)

type Menu = {
  _id: string
  id: string
  storeId: number
  collectionName: string
  data: string
  type?: number
}

type Result = {
  result: Array<Menu>
  length: number
  status: string
  time: string
}
export type PairingCode = {
  id: string
  code: string
  from: string
  until: string
  storeId: number
  used: boolean
  type?: string
}

export type PosUser = {
  _id: string
  id: string
  init: boolean
  name: string
  password: string
}

export enum ModeConnect {
  DEFAULT = 1,
  PAIRING_CODE = 2,
  DEVELOPER = 3,
}

async function onUploadData(type: string, name: string) {
  const db = await SurrealClient.getSurrealClient('cloud')
  if (!db) return

  try {
    const _doc = {
      id: uuid(),
      storeId: posSync0()?.id || 0,
      type: type,
      uploadDate: dayjs().unix(),
      name: name,
      server: server(),
    }
    const _result = await db.create('Upload', _doc)
  } catch (e) {
    toast.error('Error when upload data ' + e)
  }
}

export async function onUploadMenu() {
  setProcessing(true)
  if (!menuNameUpload()) {
    setProcessing(false)
    return toast('Please enter menu name!', { type: 'warning' })
  }
  const db = await SurrealClient.getSurrealClient('cloud')
  if (!db) {
    setProcessing(false)
    return toast('Error when connect surreal db')
  }
  try {
    await dataLock.acquireAsync()
    const [checkExists] = await db.query<[Menu[]]>(`SELECT * FROM Menu  WHERE _id = '${menuNameUpload()}' LIMIT 1;`)

    if (checkExists && checkExists[0]) {
      setProcessing(false)
      return toast('Existing menu name!', { type: 'warning' })
    }
    let arrayDoc = []
    for (const collection of collectionsMap()) {
      if (collection.db === 1 && collection.name !== 'max_id') {
        const data = handleDoc(await collection.collection.find().exec())
        if (!data) continue
        const doc = {
          _id: menuNameUpload(),
          id: uuid(),
          storeId: posSync0().id || 0,
          collectionName: collection.name,
          data: data,
          type: 2
        }
        arrayDoc.push(doc)
      }
    }
    console.log('Upload menu: ', arrayDoc.length)
    //@ts-ignore
    const resultMenu = await db.insert('Menu', arrayDoc)
    if (resultMenu) {
      await onUploadData('Menu', menuNameUpload())
    }
    setProcessing(false)
    return toast('Upload successful!', { type: 'success' })
  } catch (e) {
    setProcessing(false)
    console.log(e)
    return toast('Error when upload data: ' + e, { type: 'error' })
  } finally {
    setMenuNameUpload('')
  }
}

export async function onUploadDatabase() {
  setProcessing(true)
  if (!dbNameUpload()) {
    setProcessing(false)
    return toast('Please enter database name!', { type: 'warning' })
  }
  const db = await SurrealClient.getSurrealClient('cloud')
  if (!db) {
    setProcessing(false)
    return toast('Error when connect surreal db')
  }
  try {
    await dataLock.acquireAsync()
    const [checkExists] = await db.query<[Result[]]>(`SELECT * FROM Database  WHERE _id = '${dbNameUpload()}' LIMIT 1;`)

    if (checkExists && checkExists[0]) {
      setProcessing(false)
      return toast('Existing database name!', { type: 'warning' })
    }
    let arrayDoc = []
    const toastId = toast.info(`Uploading... 0`, { autoClose: false })
    for (const collection of collectionsMap()) {
      if (collection.name !== 'print_image') {
        const data = handleDoc(await collection.collection.find().exec())
        if (!data) continue
        const message = `Uploading... ${collection.name}`
        toast.update(toastId, { render: message })
        const doc = {
          _id: dbNameUpload(),
          id: uuid(),
          storeId: posSync0().id || 0,
          collectionName: collection.name,
          data: data,
          type: 2
        }
        arrayDoc.push(doc)
      }
    }
    console.log('Upload db', arrayDoc.length, length)

    // for (const doc of arrayDoc) {
    //   toast(`Upload by ${doc.collectionName} 30 docs`, { autoClose: 300 });
    //   await db.insert('Database', doc)
    // }
    //@ts-ignore
    const result = await db.insert('Database', arrayDoc)
    if (result) {
      await onUploadData('Database', dbNameUpload())
    }
    toast.done(toastId)
    setProcessing(false)
    return toast('Upload database successful!', { type: 'success' })
  } catch (e) {
    setProcessing(false)
    return toast('Error when upload database: ' + e, { type: 'error' })
  } finally {
    setDbNameUpload('')
  }
}

const handleDoc = (docs: any[]): string | undefined => {
  if (!docs || docs.length < 1) return

  const data = docs.map(item => {
    return item?.toMutableJSON()
  });
  const _data = pako.deflate(JSON.stringify(data))
  return Buffer.from(_data).toString('base64')
};

function recompressData(data: any) {
  const buffer = Buffer.from(data, 'base64')
  const inflated = pako.inflate(buffer, { to: 'string' })
  return JSON.parse(inflated)
}

export async function onDownloadMenu() {
  setProcessing(true)
  if (!menuNameDownload()) {
    setProcessing(false)
    return toast('Please enter menu name!', { type: 'warning' })
  }
  const db = await SurrealClient.getSurrealClient('cloud')
  if (!db) {
    setProcessing(false)
    return toast('Error when connect surreal db')
  }
  try {
    const [checkExists] = await db.query<[Menu[]]>(`SELECT * FROM Menu WHERE _id = '${menuNameDownload()}' LIMIT 1;`)
    if (!checkExists || checkExists.length === 0) {
      return toast('Menu does not exist!', { type: 'error' })
    }
    const [queryResult] = await db.query<[Menu[]]>(`SELECT * FROM Menu WHERE _id = '${menuNameDownload()}';`)
    if (queryResult && queryResult[0]) {
      for (const item of queryResult) {
        for (const itemCollection of collectionsMap('backup')) {
          if (itemCollection.name === item.collectionName) {
            await deleteMany(itemCollection.collection, {}, true)
            const data0 = item?.type === 2 ? recompressData(item.data) : JSON.parse(item.data)
            await itemCollection.collection.bulkInsert(data0)
          }
        }
      }
      toast('Download successful!', { type: 'success' })
    }
  } catch (e) {
    return toast('An error has occurred while downloading: ' + e, { type: 'error' })
  } finally {
    setProcessing(false)
    setMenuNameDownload('')
  }
}

export async function onDownloadDatabase() {
  setProcessing(true)
  if (!dbNameDownload()) {
    setProcessing(false)
    return toast('Please enter database name!', { type: 'warning' })
  }
  const db = await SurrealClient.getSurrealClient('cloud')
  if (!db) {
    setProcessing(false)
    return toast('Error when connect surreal db')
  }
  try {
    const [checkExists] = await db.query<[Menu[]]>(`SELECT * FROM Database WHERE _id = '${dbNameDownload()}' LIMIT 1;`)
    if (!checkExists || checkExists.length === 0) {
      return toast('Database does not exist!', { type: 'error' })
    }
    const toastId = toast.info(`Downloading... `, { autoClose: false })
    const [queryResult] = await db.query<[Menu[]]>(`SELECT * FROM Database WHERE _id = '${dbNameDownload()}';`)
    if (queryResult && queryResult[0]) {

      for (const item of queryResult) {
        for (const itemCollection of collectionsMap()) {
          if (itemCollection.name === item.collectionName) {
            const message = `Downloading... ${itemCollection.name}`
            toast.update(toastId, { render: message })
            await deleteMany(itemCollection.collection, {}, true)
            const data0 = item?.type === 2 ? recompressData(item.data) : JSON.parse(item.data)
            await itemCollection.collection.bulkInsert(data0)
          }
        }
      }
      toast.done(toastId)
      console.log('insert ', queryResult.length)
      localStorage.removeItem('deviceId')
      localStorage.removeItem('init-device-setting')
      toast('Download successful!', { type: 'success' })
      await delay(1000)
      window.location.reload()
    }
  } catch (e) {
    return toast('An error has occurred while downloading: ' + e, { type: 'error' })
  } finally {
    setProcessing(false)
    setDbNameDownload('')
  }
}
