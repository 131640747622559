import type { FunctionComponent } from "react";
import UserListContainer from "./UserListContainer";
import StaffReportContent from "./StaffReportContent";

const StaffReportMainContent: FunctionComponent = () => {
  return (
    <section className="self-stretch flex-1 overflow-y-auto flex flex-row items-start justify-start w-auto gap-[0px] text-left text-base text-white-solid-white-100-ffffff font-mulish hover:flex hover:self-stretch hover:w-auto hover:flex-1 hover:flex-row hover:gap-[0px] hover:items-start hover:justify-start lg:flex-row lg:gap-[0px] lg:items-start lg:justify-start md:flex-row md:gap-[0px] md:items-start md:justify-start sm:flex-row sm:gap-[0px] sm:items-start sm:justify-start">
      <UserListContainer />
      <StaffReportContent />
    </section>
  );
};

export default StaffReportMainContent;
