import type { FunctionComponent } from "react";

export type DashboardMenuButtonType = {
  className?: string;
  showSelected?: boolean;
  dashboardWebMenuIcon?: string;
  cashRegisterCopy?: string;
  onClick: () => void;
};

const DashboardMenuButton: FunctionComponent<DashboardMenuButtonType> = ({
  className = "",
  showSelected,
  dashboardWebMenuIcon,
  cashRegisterCopy,
  onClick,
}) => {
  return (
    <div
      className={`w-[136px] shadow-[0px_0.5px_1.5px_rgba(0,_0,_0,_0.5)] rounded-md [background:linear-gradient(180deg,_#fff,_#f8f7f7)] flex flex-col items-center justify-center py-4 px-3 box-border relative gap-[8px] text-center text-base text-blue-solid-blue-194480 font-mulish sm:w-32 sm:h-32 Tablet_768:w-[110px] Tablet_768:h-[110px] Tablet_768:gap-[4px] Tablet_600:w-[100px] Tablet_600:h-[100px] Tablet_600:gap-[2px] Tablet_600:p-2 Tablet_600:box-border ${className}`}
      onPointerDown = {onClick}
    >
      {showSelected && (
        <div className="w-[102.94%] absolute !m-[0] h-[105.56%] top-[-2.78%] right-[-1.47%] bottom-[-2.78%] left-[-1.47%] shadow-[-4px_-4px_4px_rgba(169,_18,_171,_0.25)_inset,_4px_4px_4px_rgba(168,_18,_171,_0.25)_inset] rounded-md [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.01),_rgba(248,_247,_247,_0.01))] box-border z-[0] border-[2px] border-solid border-pink-solid-pink-e790d9" />
      )}
      <img
        className="w-16 relative h-16 object-cover hidden z-[1] sm:w-[60px] sm:h-[60px] Tablet_768:w-14 Tablet_768:h-14 Tablet_600:w-[50px] Tablet_600:h-[50px]"
        alt=""
        src={dashboardWebMenuIcon}
      />
      <b className="self-stretch relative flex items-center justify-center h-10 shrink-0 z-[2] sm:text-mini sm:h-[38px] Tablet_768:text-smi Tablet_768:h-8 Tablet_600:text-xs Tablet_600:h-[30px]">
        {cashRegisterCopy}
      </b>
      <img
        className="w-6 absolute !m-[0] top-[8px] right-[8px] h-6 object-cover hidden z-[3]"
        alt=""
        src="/icon-info-icon@2x.png"
      />
      <div className="w-6 absolute !m-[0] top-[8px] right-[8px] h-6 hidden z-[4] text-sm text-gray-solid-gray-145-f9fafb">
        <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-red-solid-red-320-e63950 w-6 h-6" />
        <b className="absolute w-[91.67%] top-[calc(50%_-_7px)] left-[4.17%] tracking-[-0.28px] flex items-center justify-center h-3.5">
          20
        </b>
      </div>
    </div>
  );
};

export default DashboardMenuButton;
