import { type FunctionComponent, useMemo, type CSSProperties, useCallback } from 'react';
import clsx from 'clsx';
import { setCurrentPrinterIndex } from '@/react/Printer/PrinterSettingView.tsx';

export type FloorTabType = {
  tabsText?: string;
  isSelected?: boolean;

  /** Style props */
  tabTextColor?: CSSProperties["color"];
  onClick?: () => void;
};

const FloorTab: FunctionComponent<FloorTabType> = ({
  tabsText = "NO NAME",
  isSelected = true,
  tabTextColor,
  onClick
}) => {
  const tabTextStyle: CSSProperties = useMemo(() => {
    return {
      color: tabTextColor,
    };
  }, [tabTextColor]);

  return (
    <div className="flex flex-col items-center justify-start gap-[5px_0px] text-left text-sm text-black font-mulish"
          onPointerDown={onClick}
    >
      <div className="flex flex-row items-center justify-center py-0 px-2">
        <div className={clsx("relative font-medium", !isSelected && "text-[#979797]")}>
          {tabsText}
        </div>
      </div>
      {isSelected && <div className="self-stretch relative bg-black h-px" />}
    </div>
  );
};

export default FloorTab;
