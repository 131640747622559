import type { FunctionComponent } from "react";
import { deleteHappyHour, happyHour0 } from '@/react/HappyHourView/HappyHourView.tsx';
import {LL0} from "@/react/core/I18nService.tsx";

type HappyHourDeleteConfirmatiType = {
  onClose?: () => void;
};

const HappyHourDeleteConfirmati: FunctionComponent<
  HappyHourDeleteConfirmatiType
> = ({ onClose }) => {
  //implement delete function directly since we just have 1 modal in this screen

  return (
    <div className="relative bg-[#fff] w-full overflow-hidden flex flex-col items-center justify-center pt-8 px-6 pb-6 box-border gap-[32px] min-w-[360px] h-full max-w-full max-h-full text-center text-[20px] text-red-solid-red-550-cf2222 font-mulish">
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
        <b className="self-stretch relative leading-[28px]">
          {LL0().masterMachine.deleteConfirmation()}
        </b>
        <div className="self-stretch relative text-[16px] leading-[28px] font-medium text-black-solid-black-880-1d1d26">
          {LL0().masterMachine.doYouWantToDelete()}
        </div>
      </div>
      <div className="w-full flex flex-row items-center justify-center gap-[12px] max-w-[312px] text-sm text-blue-solid-blue-420-2979ff">
        <div
          className="flex-1 rounded-md bg-basic-color-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center py-[9px] cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
          onClick={onClose}
        >
          <b className="relative">{LL0().ui.cancel()}</b>
        </div>
        <div className="flex-1 rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] text-[#fff]"
          onClick={async () => {
            const _happyHour = happyHour0()
            if (!_happyHour) return;
            await deleteHappyHour(_happyHour);
            onClose?.();
          }}
        >
          <b className="relative uppercase">{LL0().ui.ok()}</b>
        </div>
      </div>
    </div>
  );
};

export default HappyHourDeleteConfirmati;
