import { type CSSProperties, type FunctionComponent, memo, useCallback, useMemo } from 'react';
import { date0, dateMap, setDate0 } from '@/react/EndOfDayView/EndOfDayView.tsx';
import { useSelector } from "@/react/core/reactive.ts";
import dayjs from "dayjs";

type EndOfDayCalendarItemType = {
  defaultDateNumber?: string;
  showDisabled?: boolean;
  showCurrent?: boolean;
  showAvailable?: boolean;
  showFocus?: boolean;

  /** Style props */
  defaultDateColor?: CSSProperties["color"];
  type: string
  iso: string
}

const EndOfDayCalendarItem: FunctionComponent<EndOfDayCalendarItemType> = memo(({
  type,
  iso,
  defaultDateNumber = "1",
  showDisabled = false,
  showCurrent = false,
  showAvailable = false,
  showFocus = false,
  defaultDateColor,
}) => {
  const isSelected = useSelector<string>(date0, undefined, [iso]);
  // console.log('render EndOfDayCalendarItem')
  const dateItemStyle= dateMap()[iso]

  defaultDateNumber = dayjs(iso).format('DD');
  showDisabled = type !== 'current';
  showCurrent = isSelected(iso)
  showAvailable = dateItemStyle === 'green';
  showFocus = dateItemStyle === 'red';

  const onClick = useCallback(() =>setDate0(iso), [iso])

  const defaultDateStyle: CSSProperties = useMemo(() => {
    return {
      color: defaultDateColor,
    };
  }, [defaultDateColor]);

  return (
    <div className="bg-basic-color-white overflow-hidden flex flex-col items-center justify-center relative text-center text-xl text-darkslategray-200 font-mulish" onClick={onClick}>
      {showDisabled && (
        <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-whitesmoke-100 z-[0]" />
      )}
      {showAvailable && (
        <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-eod-item-avaible z-[1]" />
      )}
      {showFocus && (
        <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-eod-item-finished z-[2]" />
      )}
      {showCurrent && (
        <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] box-border z-[3] border-[2px] border-solid border-deepskyblue" />
      )}
      <div
        className="self-stretch relative leading-[17px] font-semibold z-[4] lg:text-lg md:text-base sm:text-sm sm:leading-[17px] Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs Mobile_480:leading-[17px]"
        style={defaultDateStyle}
      >
        {defaultDateNumber}
      </div>
    </div>
  );
});

export default EndOfDayCalendarItem;
