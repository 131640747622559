import uuid from 'time-uuid'

import type { Order } from '@/data/Order'
import { OrderStatus } from '@/pos/OrderType'
import { signalSyncedWithLocalStorage } from '@/react/core/reactive'

import { completeOrder } from '../order-mutations'
import { srmTransactionLogic } from '../transaction.logic'
import { execTestcase, getCaTaxInfo } from './libs'

type O = Readonly<Order>

const [order01, setOrder01] = signalSyncedWithLocalStorage<O | null>('TESTCASE_019_ORDER_01', null)

export const testRunner019: Record<string, () => Promise<boolean>> = {
  '019.001': () =>
    execTestcase({
      num: 19,
      step: 1,
      device: 'A',
      run: async () => {
        const taxInfo = getCaTaxInfo()
        const baseOrder: O = {
          _id: uuid(),
          table: '001',
          status: OrderStatus.IN_PROGRESS,
          payments: [],
          items: [
            // Main Course
            { name: 'Bouillabaisse', price: 22, quantity: 1, modifiers: [], ...taxInfo },
            { name: 'Poutine', price: 33, quantity: 1, modifiers: [], ...taxInfo },
            // Drink
            { name: 'Bordeaux', price: 18, quantity: 1, modifiers: [], ...taxInfo },
            { name: 'Sortilège', price: 28, quantity: 1, modifiers: [], ...taxInfo },
            // Dessert
            { name: 'Crème Brûlée', price: 6.5, quantity: 1, modifiers: [], ...taxInfo },
            { name: 'Tarte Tatin', price: 8, quantity: 1, modifiers: [], ...taxInfo },
          ],
        }
        const splittedOrder1: O = {
          ...baseOrder,
          _id: uuid(),
          items: [
            ...baseOrder.items.filter((_, i) => i % 2 === 0),
            // New item
            { name: 'Gin de Neige', price: 10, quantity: 1, modifiers: [], ...taxInfo },
          ],
          seat: 0,
        }
        const splittedOrder2: O = {
          ...baseOrder,
          _id: uuid(),
          items: [...baseOrder.items.filter((_, i) => i % 2 === 1)],
          seat: 1,
        }
        const combinedOrder: O = { ...baseOrder, seatMap: [splittedOrder1, splittedOrder2] }
        await srmTransactionLogic.recordTemporaryBill(combinedOrder, { print: true })
        setOrder01(combinedOrder)
      },
    }),
  '019.002': () =>
    execTestcase({
      num: 19,
      step: 2,
      device: 'A',
      run: async () => {
        const o = order01()
        if (!o) throw new Error('Please run step 1 first')
        const order = o.seatMap?.[0]
        if (!order) throw new Error('Seat 0 not found')
        await srmTransactionLogic.recordTemporaryBill(o.seatMap[0], { print: true, parentOrder: o })
      },
    }),
  '019.003': () =>
    execTestcase({
      num: 19,
      step: 3,
      device: 'A',
      run: async () => {
        const o = order01()
        if (!o) throw new Error('Please run step 1 first')
        const order = o.seatMap?.[1]
        if (!order) throw new Error('Seat 1 not found')
        await srmTransactionLogic.recordTemporaryBill(o.seatMap[1], { print: true, parentOrder: o })
      },
    }),
  '019.004': () =>
    execTestcase({
      num: 19,
      step: 4,
      device: 'A',
      run: async () => {
        const o = order01()
        if (!o) throw new Error('Please run step 1 first')
        const order = o.seatMap?.[0]
        if (!order) throw new Error('Seat 0 not found')
        await srmTransactionLogic.recordClosingReceipt(completeOrder(order), { print: true, parentOrder: o })
      },
    }),
  '019.005': () =>
    execTestcase({
      num: 19,
      step: 5,
      device: 'A',
      run: async () => {
        const o = order01()
        if (!o) throw new Error('Please run step 1 first')
        const order = o.seatMap?.[1]
        if (!order) throw new Error('Seat 1 not found')
        await srmTransactionLogic.recordClosingReceipt(completeOrder(order), { print: true, parentOrder: o })
      },
    }),
}
