import { type FunctionComponent, useContext, useEffect } from "react";
import { ItemFactoryContext } from "@/react/PaymentView/OrderItemsFactory2.tsx";
import { printInvoice } from "@/react/Printer/print-invoice.ts";
import { InvoiceTypes } from "@/pos/OrderType.ts";
import { LL0 } from "@/react/core/I18nService.tsx";

export type PrintBillForSeatsType = {
  className?: string;
  onClose?: () => void;
};

const PrintBillForSeats: FunctionComponent<PrintBillForSeatsType> = ({
  className = "",
  onClose,
}) => {
  const { order0, seat, setSeat } = useContext(ItemFactoryContext)

  const handlePrintSeatMap = async () => {
    const currentOrder = order0?.()
    const currentSeat = seat?.()
    if (!currentOrder || !currentOrder.seatMap) return
    if (typeof currentSeat === 'undefined') return

    // print all
    if (currentSeat === -1) {
      for (const _seat of currentOrder.seatMap) {
        if (!_seat) continue
        const checkQuantity = _seat.items.filter(i => i.quantity > 0)
        if (checkQuantity.length !== 0) {
          await printInvoice(_seat, InvoiceTypes.GUEST_CHECK, currentOrder)
        }
      }
    } else {
      // single print
      const seatOrder = currentOrder.seatMap[currentSeat]
      if (!seatOrder) return
      const checkQuantity = seatOrder?.items.filter(i => i.quantity > 0)
      if (checkQuantity.length !== 0) {
        await printInvoice(seatOrder, InvoiceTypes.GUEST_CHECK, currentOrder)
      }
    }
    onClose?.()
  }
  useEffect(() => {
    if (isNaN(seat?.())) {
      setSeat?.(-1)
    }
  }, [])

  return (
    <div
      className={`w-full relative bg-white overflow-hidden flex flex-col items-start justify-start pt-8 px-6 pb-6 box-border gap-[24px] min-w-[520px] h-full max-w-full max-h-full text-left text-base text-blue-solid-blue-500-0051c1 font-mulish ${className}`}
    >
      <div className="self-stretch flex flex-col items-start justify-start gap-6 z-[0]">
        <b className="relative">
          {LL0().order.selectSeatToPrint()}:
        </b>
        <div className="self-stretch flex flex-row items-center flex-wrap justify-start gap-[20px] text-center text-sm text-black-solid-black-880-1d1d26">
          {order0?.().seatMap?.map((_order, index) => (
            <div key={index} className="shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] rounded-10xs bg-gray-solid-gray-130-f4f4f4 box-border h-[34px] flex flex-row items-center justify-center py-0 px-5 relative gap-[2px] min-w-[40px] min-h-[34px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1"
                 onClick={() => setSeat?.(index)}
            >
              {(seat?.() || 0) + 1 === index + 1 &&
                <div className="w-[calc(100%_+_2px)] absolute !m-[0] h-[calc(100%_+_2px)] top-[-1px] right-[-1px] bottom-[-1px] left-[-1px] rounded-10xs bg-cyan-solid-cyan-170-ceffff box-border z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />
              }
              <div className="w-[107px] relative hidden z-[1]">{`Lunch Sat & Sun`}</div>
              <div className="w-1 relative hidden z-[2]">|</div>
              <div className="relative z-[3]">{LL0().payment.seat()} {index + 1}</div>
              <img
                className="w-6 absolute !m-[0] top-[-8px] right-[-10px] h-6 hidden z-[4]"
                alt=""
                src="/order-view-delete-icon1.svg"
              />
            </div>
          ))}
          {/*<div className="shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] rounded-10xs bg-gray-solid-gray-130-f4f4f4 box-border h-[34px] flex flex-row items-center justify-center py-0 px-5 relative gap-[2px] min-w-[40px] min-h-[34px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1">*/}
          {/*  <div className="w-[calc(100%_+_2px)] absolute !m-[0] h-[calc(100%_+_2px)] top-[-1px] right-[-1px] bottom-[-1px] left-[-1px] rounded-10xs bg-cyan-solid-cyan-170-ceffff box-border z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />*/}
          {/*  <div className="w-[107px] relative hidden z-[1]">{`Lunch Sat & Sun`}</div>*/}
          {/*  <div className="w-1 relative hidden z-[2]">|</div>*/}
          {/*  <div className="relative z-[3]">Print Seat {(seat?.() || 0) + 1}</div>*/}
          {/*  <img*/}
          {/*    className="w-6 absolute !m-[0] top-[-8px] right-[-10px] h-6 hidden z-[4]"*/}
          {/*    alt=""*/}
          {/*    src="/order-view-delete-icon1.svg"*/}
          {/*  />*/}
          {/*</div>*/}

          <div className="shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] rounded-10xs bg-gray-solid-gray-130-f4f4f4 box-border h-[34px] flex flex-row items-center justify-center py-0 px-5 relative gap-[2px] min-w-[40px] min-h-[34px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1"
               onClick={() => setSeat?.(-1)}
          >
            {seat?.() === -1 &&
              <div className="w-[calc(100%_+_2px)] absolute !m-[0] h-[calc(100%_+_2px)] top-[-1px] right-[-1px] bottom-[-1px] left-[-1px] rounded-10xs bg-cyan-solid-cyan-170-ceffff box-border z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />
            }
            <div className="w-[107px] relative hidden z-[1]">{`Lunch Sat & Sun`}</div>
            <div className="w-1 relative hidden z-[2]">|</div>
            <div className="relative z-[3]">{LL0().order.printAll()}</div>
            <img
              className="w-6 absolute !m-[0] top-[-8px] right-[-10px] h-6 hidden z-[4]"
              alt=""
              src="/order-view-delete-icon1.svg"
            />
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-end justify-start z-[1] text-center text-sm text-white">
        <div className="rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8"
             onClick={handlePrintSeatMap}
        >
          <b className="relative">{LL0().ui.ok()}</b>
        </div>
      </div>
      <img
        className="w-6 absolute !m-[0] top-[12px] right-[12px] h-6 overflow-hidden shrink-0 object-cover cursor-pointer z-[2]"
        alt=""
        src="/icong-close-popup-icon--grey12@2x.png"
        onClick={onClose}
      />
    </div>
  );
};

export default PrintBillForSeats;
