import { Fragment, type FunctionComponent, useState } from 'react';
import ManuelTableFloorButton from '@edit-room/ManuelTableFloorButton';
import { createRoom, rooms0 } from '@/react/EditRoomView/EditRoomView.tsx';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import {LL0} from "@/react/core/I18nService.tsx";

type EditRoomFloorPlanAccordionType = {
  showFloorFlanAccordionClosed?: boolean;
};

const EditRoomFloorPlanAccordion: FunctionComponent<
  EditRoomFloorPlanAccordionType
> = ({  }) => {
  const [showFloorFlanAccordionClosed, setShowFloorFlanAccordionClosed] = useState<boolean>(false)
  const toggleShowAccordion = () => setShowFloorFlanAccordionClosed(prev => !prev)

  return (
    <div className="flex flex-col items-center justify-center gap-[10px] text-left text-sm text-dodgerblue font-mulish self-stretch">
      {!showFloorFlanAccordionClosed && (
        <div className="self-stretch flex flex-col items-center justify-center gap-[8px]">
          <div className="self-stretch bg-whitesmoke-300 h-[39px] flex flex-row items-center justify-between py-[9px] px-4 box-border"
            onPointerDown={toggleShowAccordion}
          >
            <div className="flex flex-row items-center justify-start gap-[16px]">
              <img
                className="relative w-[21px] h-[21px]"
                alt=""
                src="/floor-plan-icon.svg"
              />
              <b className="relative">{LL0().restaurant.floorPlan()}</b>
            </div>
            <img
              className="relative w-3 h-[7.1px]"
              alt=""
              src="/chevron-down-icon.svg"
            />
          </div>
          <div className="self-stretch overflow-hidden flex flex-col items-center justify-center py-1.5 px-[7px] gap-[12px] text-center text-royalblue-300">
            <div className="self-stretch flex-1 relative grid grid-cols-[repeat(2,1fr)] [grid-row-gap:4px] [grid-column-gap:3px]">
              {rooms0().map((room, index) =>  <ManuelTableFloorButton
                  floorText={room.name}
                  showSelected
                  showRedDot={false}
                  _id={room._id}
                  key={index}
                />)}
            </div>
            <div className="self-stretch rounded bg-aliceblue flex flex-row items-center justify-center py-[9.5px] px-0 border-[1px] border-dashed border-cornflowerblue"
                onPointerDown={() => createRoom()}
                 ref={makeRipple}
            >
              <b className="relative">{LL0().restaurant.addFloor()}</b>
            </div>
          </div>
        </div>
      )}
      {showFloorFlanAccordionClosed && (
        <div className="self-stretch bg-whitesmoke-300 h-[39px] flex flex-row items-center justify-between py-[9px] px-4 box-border"
             onPointerDown={toggleShowAccordion}
        >
          <div className="flex flex-row items-center justify-start gap-[16px]">
            <img
              className="relative w-[21px] h-[21px]"
              alt=""
              src="/floor-plan-icon.svg"
            />
            <b className="relative">{LL0().restaurant.floorPlan()}</b>
          </div>
          <img
            className="relative w-[7.1px] h-3"
            alt=""
            src="/chevron-right-icon.svg"
          />
        </div>
      )}
    </div>
  );
};

export default EditRoomFloorPlanAccordion;
