import { type FC, useState } from "react";

const Collapsible: FC<any> = (props: any) => {
  const [collapsed, setCollapsed] = useState(true);

  return <div className={props.className}>
    <div className="px-2 py-2 cursor-pointer bg-[#ddd] flex flex-row justify-between" onClick={() => setCollapsed(!collapsed)}>
      { props.title }
      <span className="mr-2">{ collapsed ? '+' : '-' }</span>
    </div>
    { collapsed ? null : <div>{props.children}</div> }
  </div>
}

export default Collapsible;