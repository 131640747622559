import {type FunctionComponent, useCallback, useEffect, useState } from 'react';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { customer0, handleSaveCustomer, setCustomer0 } from '@/react/NewDeliveryView/NewDeliveryView.tsx';
import { router } from '@/pos/PosRouter';
import TableManualKeyboard from '@/react/core/TableManualKeyboard.tsx';
import Input, { inputController0 } from '@/react/core/Input.tsx';
import { findCustomer, initCustomer } from '@/react/CustomerInfoView/CustomerInfoView.tsx';
import _ from 'lodash';
import type { Customer } from '@/data/Customer.ts';
import { convertDocument } from '@/data/data-utils.ts';
import clsx from 'clsx';
import { deepSignal } from '@/react/core/reactive.ts';
import { clone } from 'json-fn';
import axios from 'axios';
import { getApiUrl } from '@/shared/utils.ts';
import type { RxDocument } from 'rxdb';
import Autocomplete, { type AutocompleteProps } from "@/react/core/Autocomplete.tsx";
import { useNativeInput } from "@/react/core/NativeInput.ts";
import NewDeliveryCustomerInfoM from "@new-delivery/NewDeliveryCustomerInfoM.tsx";
import PortalPopup from "@order-view/PortalPopup.tsx";
import {LL0} from "@/react/core/I18nService.tsx";
import TextField from '@/react/core/TextField.tsx'
import { generalSetting0, mainScreen } from "@/data/PosSettingsSignal.ts";

export type NewDeliveryAddEditCustomerType = {
  onClose?: () => void;
};

export type CustomerMapAddress = {name?: string, placeId?: string, index?: number}

const NewDeliveryAddEditCustomer: FunctionComponent<
  NewDeliveryAddEditCustomerType
> = ({ onClose }) => {
  const [isNewDeliveryCustomerInfoMOpen, setNewDeliveryCustomerInfoMOpen] =
    useState(false);

  const openNewDeliveryCustomerInfoM = useCallback(() => {
    setNewDeliveryCustomerInfoMOpen(true);
  }, []);

  const closeNewDeliveryCustomerInfoM = useCallback(() => {
    setNewDeliveryCustomerInfoMOpen(false);
  }, []);

  const currentCustomerAddress = customer0()?.addresses?.[customer0()?.defaultAddressIndex || 0];

  const [customerTelOptions, setCustomerTelOptions] = useState<RxDocument<Customer>[]>([]);
  const [customerNameOptions, setCustomerNameOptions] = useState<RxDocument<Customer>[]>([]);
  const [autoCompleteAddressOptions, setAutoCompleteAddressOptions] = useState<CustomerMapAddress[]>([]);

  const fetchCustomerTel = useCallback(_.debounce(async () => {
    const foundCustomers = await findCustomer("" , customer0()?.phoneNumber)
    setCustomerTelOptions(foundCustomers || [])
  }, 500), []);

  const fetchCustomerName = useCallback(_.debounce(async () => {
    const foundCustomers = await findCustomer(customer0()?.name)
    setCustomerNameOptions(foundCustomers || [])
  }, 500), []);

  useEffect(() => {
    if ((customer0()?.phoneNumber?.length || 0) <= 3) return;
    fetchCustomerTel()
  }, [customer0()?.phoneNumber]);

  useEffect(() => {
    if ((customer0()?.name?.length || 0) <= 3) return;
    fetchCustomerName()
  }, [customer0()?.name]);

  const autoCompletePropsConstructor = (options: RxDocument<Customer>[], key: "phoneNumber" | "name"):
    AutocompleteProps  => ({
    sx: { height: "37px" },
    freeSolo: true,
    className: "flex-1",
    // not let mui do filter
    filterOptions: opts => opts,
    value: customer0()?.[key] || "",
    onChange:(_e, newValue) => {
      if (newValue === null) {
        setCustomer0(deepSignal(clone(initCustomer())))
        return;
      }
      _.assign(customer0(),{ [key]: newValue })
    },
    options: options.map(customer => customer?.[key] || ""),
    inputProps: {
      label: key === "phoneNumber" ? `${LL0().customerInfo.telephoneRequired()}` : `${LL0().customerInfo.customersName()}`,
      value: customer0()?.[key] || "",
      onChange: value => {
        _.assign(customer0(),{ [key]: value })
      },
      className: "w-full font-mulish text-sm text-gray-100"
    },
    renderOption: ({style, className, ...restProps}, option, state) => {
      const foundCustomer = options.find(customer => customer[key] === option)

      return (
        <li className="self-stretch box-border h-10 flex flex-row items-center justify-between py-1 px-2 relative border-b-[1px] border-solid border-gray-solid-gray-164-e1e1e1"
            key={foundCustomer?._id || state.index}
            {...restProps}
            onClick={(e) => {
              if (!foundCustomer) return;
              setCustomer0(convertDocument<Customer>(foundCustomer, true));
              restProps.onClick?.(e);
            }}
            ref={makeRipple}
        >
          { state.index % 2 === 0 &&
              <div className="!flex w-full absolute my-0 mx-[!important] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] bg-blue-opacity-blue-80-3c5ac6 hidden z-[0]" />
          }
          <div className={clsx("text-left flex-1 relative text-black-solid-black-880-1d1d26 z-[1]",
            state.index % 2 === 0 && "text-white")}>
            <span>{foundCustomer?.phoneNumber}</span>
          </div>
          <div className={clsx("relative text-blue-solid-blue-600-4b40c9 z-[3]",
            state.index % 2 === 0 && "text-white")}>
            {foundCustomer?.name}
          </div>
        </li>
      )
    }
  })

  const fetchAddress = useCallback(_.debounce(async (searchPlace: string) => {
    //TODO: Integrate w google api key later?
    const { data: foundAddresses } = await axios.get<
      CustomerMapAddress[]>(getApiUrl() + "/api/map/google-places", {
      params: {
        searchPlace
      }
    })
    setAutoCompleteAddressOptions(foundAddresses || [])
  }, 500), []);

  useEffect(() => {
    if ((currentCustomerAddress?.street?.length || 0) <= 3 ||
      (autoCompleteAddressOptions[0]?.index != null &&
      !! autoCompleteAddressOptions.find(option => option.name === currentCustomerAddress?.street))
    ) return;
    fetchAddress(currentCustomerAddress?.street || "")
  }, [currentCustomerAddress?.street]);

  const onClickSave = async () => {
    if (!customer0()?.phoneNumber) {
      openNewDeliveryCustomerInfoM();
      return;
    }
    await handleSaveCustomer();
  }

  return (
    <>
      <div className="w-full h-full rounded flex flex-col items-start justify-start min-w-[520px] max-w-full max-h-full overflow-auto text-center text-mini text-white-solid-white-100-ffffff font-mulish">
        <div className="self-stretch rounded-t rounded-b-none bg-white-solid-white-400-f2f2f2 flex flex-row items-center justify-end pt-[3px] px-3 pb-0.5 z-[2] sm:h-[42px] sm:pl-4 sm:pr-4 sm:box-border">
          <div className="flex flex-row items-center justify-end gap-[16px]">
            <div className="rounded-[30px] [background:linear-gradient(180deg,_#82e1ff,_#2aacf5)] shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0 px-6 box-border"
                 ref={makeRipple}
                 onClick={generalSetting0()?.addCustomerAfterOrder ? onClose : onClickSave}
            >
              <div className="relative leading-[16px] font-semibold">{LL0().ui.save()}</div>
            </div>
            <img
              className="w-[22px] relative h-[22px] object-cover"
              alt=""
              src="/icongeneralclose-popup-icon--grey@2x.png"
              onClick={() => generalSetting0()?.addCustomerAfterOrder ? onClose?.() : router.screen = mainScreen()}
            />
          </div>
        </div>
        <div className="no-scrollbar !flex-1 self-stretch bg-white-solid-white-100-ffffff shadow-[0px_0px_8px_rgba(0,_0,_0,_0.12)_inset] overflow-y-auto flex flex-col items-start justify-start pt-2 px-3.5 pb-1.5 gap-[10px] z-[1] border-[1px] border-solid border-gray-solid-gray-200-e8e8e8">
          <div className="self-stretch flex flex-row items-start justify-center gap-[16px] z-[2]">
            <Autocomplete
              {...autoCompletePropsConstructor(customerTelOptions, "phoneNumber")}
            />
            <Autocomplete
              {...autoCompletePropsConstructor(customerNameOptions, "name")}
            />
          </div>
          <Autocomplete
            sx={{ height: "37px" }}
            freeSolo
            fullWidth
            // not let mui do filter
            filterOptions={(options) => {
              return options
            }}
            value={{
              name: currentCustomerAddress?.street,
              index: customer0()?.defaultAddressIndex
            }}
            onChange={(_e, newValue) => {
              const _customer = customer0();
              if (!_customer) return;
              if (newValue?.index != null && newValue != null) {
                _customer.defaultAddressIndex = newValue?.index
                return;
              }
              _.merge(currentCustomerAddress,
                {street: newValue?.name
                  , placeId: newValue?.placeId
                })
            }}
            getOptionLabel={option => option.name || ""}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            options={autoCompleteAddressOptions}
            inputProps={{
              className: "w-full font-mulish text-sm text-gray-100",
              label: LL0().delivery.customer.address.street(),
              value: currentCustomerAddress?.street || '',
              onChange: value => _.merge(currentCustomerAddress, {street: value}),
              endAdornment: (onChange, value) => (
                <>
                  {
                    !!customer0()?.doc &&
                      <img
                          className="mr-2"
                          src="/new-delivery-blue-arrow.png"
                          alt="new-delivery-blue-arrow"
                          width={20}
                          height={20}
                          onClick={() => {
                            onChange?.({target: {value: value || ""}} as any)
                            setAutoCompleteAddressOptions(customer0()?.addresses?.map((address, index) => ({
                              name: address.street, index
                            })) || []);}}
                      />
                  }
                </>
              )
            }}
          />

          { !useNativeInput() ? <div className="self-stretch flex flex-row items-start justify-center gap-[16px] z-[0]">
              <Input
                className="flex-1 font-mulish text-sm text-gray-100"
                label={LL0().customerInfo.houseNumber()}
                value={currentCustomerAddress?.home || ''}
                onChange={value => _.merge(currentCustomerAddress, {home: value})}
              />
              <Input
                className="flex-1 font-mulish text-sm text-gray-100"
                label={LL0().article.note()}
                value={customer0()?.note || ''}
                onChange={value => _.assign(customer0(), {note: value})}
              />
            </div> :
            <div className="self-stretch flex flex-row items-start justify-center gap-[16px] z-[0]">
              <TextField
                className="flex-1 font-mulish text-sm text-gray-100"
                color="primary"
                label={LL0().customerInfo.houseNumber()}
                size="small"
                variant="outlined"
                sx={{ "& .MuiInputBase-root": { height: "37px" } }}
                value={currentCustomerAddress?.home || ''}
                onChange={e => _.merge(currentCustomerAddress, {home: e.target.value})}
              />
              <TextField
                className="flex-1 font-mulish text-sm text-gray-100"
                color="primary"
                label={LL0().article.note()}
                size="small"
                variant="outlined"
                sx={{ "& .MuiInputBase-root": { height: "37px" } }}
                value={customer0()?.note || ''}
                onChange={e => _.assign(customer0(), {note: e.target.value})}
              />
            </div>}
        </div>
        { !useNativeInput() &&
            <TableManualKeyboard
                inputController={inputController0}
                value={""}
                onEnter={onClickSave}
            />
        }
      </div>
      {isNewDeliveryCustomerInfoMOpen &&
          <PortalPopup
              overlayColor="rgba(113, 113, 113, 0.3)"
              placement="Centered"
              onOutsideClick={closeNewDeliveryCustomerInfoM}
          >
              <NewDeliveryCustomerInfoM
                  onClose={closeNewDeliveryCustomerInfoM}
              />
          </PortalPopup>
      }
    </>
  );
};

export default NewDeliveryAddEditCustomer;
