import { getDeviceId } from '@/shared/getDeviceId'
import { deviceSettingLock } from '@/data/DeviceSettingHub.ts'
import { effectOn } from "@/react/core/reactive.ts";
import {
  handleUpdateAPK,
  handleUpdateCore,
  handleUpdateElectron,
  handleUpdateS3
} from "@/react/Developer/UpdateNowPopup.logic.ts";
import { notificationToast } from "@/react/FloorPlanView/Noti.ts";
import SurrealClient from "@/shared/SurrealClient.ts";
import { captureException, captureMessage } from '@sentry/react';
import { liveSurrealHook } from "@/react/utils/hooks.ts";
import type Surreal from "surrealdb.js";
import _ from "lodash";
import { posSync0, posSyncLock } from "@/data/PosSyncState.ts";
import { posSetting0, posSettingLock } from "@/data/PosSettingsSignal.ts";
import { exportMeta } from "@/data/fix/backup-indexeddb.ts";
import { toast } from "react-toastify";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { masterId } from "@/lib/fetch-master.ts";

export const sendDeviceInfo = async () => {
  const id = getDeviceId()
  if (!deviceSetting0()?.cloudRegister || !id || !posSync0()!.id) return
  try {
    const db = await SurrealClient.getSurrealClient('cloud');
    if (!db) return
    await db.query(`UPSERT Device:${id} SET onlineDate = '${new Date()}';`)
  } catch (e: any) {
    console.log('Send device info failed: ', e.message)
  }
}

export const sendDeviceName = async () => {
  await deviceSettingLock.acquireAsync()
  await posSyncLock.acquireAsync()
  await posSettingLock.acquireAsync()
  const id = getDeviceId()
  if (!id || !posSync0()?.id) return
  try {
    const db = await SurrealClient.getSurrealClient('cloud');
    if (!db) return
    const ip = deviceSetting0()?.publicIp
    const name = deviceSetting0()?.name
    const show = deviceSetting0()?.show
    const devicePos = deviceSetting0()?.devicePos
    await db.query(`UPSERT Device:${id} SET ip = "${ip}" , name = "${name}" , show = ${show} , devicePos = ${devicePos}`)
    await db.query(`UPDATE PosUser:${posSync0()!.id} SET masterDevice = '${masterId()}';`)
  } catch (e: any) {
    console.log('Send device name failed: ', e.message)
  }
}

const handleUpdate = _.debounce(async (result: any) => {
  if (result.mode === "store") {
    notificationToast(`Update device now`, { autoClose: 5000 })
    await handleUpdateAPK()
  } else if (result.mode === "codepush") {
    notificationToast(`Update device now`, { autoClose: 5000 })
    await handleUpdateCore()
  } else if (result.mode === "s3") {
    await handleUpdateS3()
  } else if (result.mode === "electron") {
    await handleUpdateElectron()
  }
}, 300)

export const checkRequestUpdate = async () => {
  await deviceSettingLock.acquireAsync()
  await posSyncLock.acquireAsync()
  try {
    const db = await SurrealClient.getSurrealClient('cloud')
    if (!db) return
    const id = getDeviceId()
    // @ts-ignore
    const [[result]] = await db.query(`SELECT * FROM Request WHERE deviceId = '${id}' ORDER BY date DESC LIMIT 1;`)
    if (!result || !result?.id || result?.response) return
    await db.query(`UPDATE ${result.id} SET response = 'ok';`)
    handleUpdate(result)
  } catch (err) {
    captureException(new Error('Cannot check request update ' + err?.message))
  }
}

export const updateDeviceVersion = async () => {
  await posSyncLock.acquireAsync()
  await posSettingLock.acquireAsync()
  await deviceSettingLock.acquireAsync()
  const id = getDeviceId()
  try {
    const db = await SurrealClient.getSurrealClient('cloud');
    if (!db) return
    liveSurrealHook.on('request', async ([action, result]) => {
      if (id !== result.deviceId || result?.storeId !== posSync0()?.id) return
      if (action === "CREATE") {
        await db.query(`UPDATE ${result.id} SET response = 'ok';`)
        handleUpdate(result)
        if (result.mode === 'exportMeta') {
          await exportMeta()
          toast.success('Export success')
        }
      }
    })
  } catch (e: any) {
    captureException(new Error('Update device version failed: ', e.message))
  }
}

effectOn([posSync0], async () => {
  console.log('updateDeviceVersion');
  await updateDeviceVersion()
}, {defer: true})