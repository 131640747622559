import { type FunctionComponent, useContext, useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import { CommitAction, type OrderItem } from '@/pos/OrderType.ts';
import {
  setCurrentItem,
  setShowDiscountPopupItem,
  setShowTabSidebar,
  setTabDiscountPopup,
  showDiscountPopupItem,
  TabsDiscountPopup
} from '@/react/OrderView/OrderView.tsx';
import clsx from 'clsx';
import { twVisible } from '@/react/core/tw.ts';
import type { Order } from '@/data/Order.ts';
import { LL0 } from '@/react/core/I18nService.tsx';
import DiscountPopupItem from '@order-view/DiscountPopup/DiscountPopupItem.tsx';
import { AccessPort, getAccessibility } from '@/react/DecentralizationPort.ts';
import { ItemFactoryContext } from '@/react/PaymentView/OrderItemsFactory2.tsx';
import PortalPopup2 from '@order-view/PortalPopup2.tsx';
import { isSmallSidebar } from "@/data/DeviceSettingHub.ts";
import { toast } from "react-toastify";
import { LL3 } from "@/react/core/I18nCurrency.tsx";

type OrderItemType = {
  itemSName?: string;
  oldPrice?: number;
  price?: number;
  courseNumber?: string;
  quantityNumber?: string | number;
  showSeperatedLine?: boolean;
  orderItem?: OrderItem
  order?: Order;
  showAddMinusIcon?: boolean
};

const OrderItemComponent: FunctionComponent<OrderItemType> = (
  {
    itemSName = '5. Miso Soup',
    oldPrice = 50,
    price = 35,
    courseNumber = 'Course: 1',
    quantityNumber = '1',
    showSeperatedLine = true,
    orderItem,
    order,
    showAddMinusIcon = true
  }) => {
  if (!orderItem) return <></>

  const { onItemClick, seat, onPlus, onMinus, splitEnable, moveEnable } = useContext(ItemFactoryContext);

  itemSName = (orderItem?.id ? `${orderItem?.id}. ` : "") + orderItem?.name + (orderItem?.isVoucher ? ` - ${orderItem?.code}` : "")
  oldPrice = orderItem.price
  price = orderItem?.vPrice || 0
  courseNumber = (orderItem?.course || 0) > 1 ? `${LL0().order.course({ course: orderItem?.course })}` : ''
  // quantityNumber = splitEnable() ? (orderItem.quantity - (orderItem.movedQuantity || 0)) : orderItem.quantity
  quantityNumber = (orderItem.quantity - (orderItem.movedQuantity || 0))
  showSeperatedLine = !!(orderItem && orderItem?.quantity > 0 && orderItem?.separate)

  const printedOpacityClassName = orderItem?.printed ? 'opacity-70' : 'opacity-100'

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (orderItem.printed) return
      console.log('swipe left')

      if (orderItem.separate || orderItem.course === undefined) return
      if (orderItem.course >= 0) {
        order?.commits?.push({
          action: CommitAction.SET_COURSE,
          course: orderItem.course - 1,
          commitId: orderItem.commitRefs?.[0]
        })
      }
    },
    onSwipedRight: () => {
      if (orderItem.printed) return

      if (orderItem.separate) {
        order?.commits?.push({ action: CommitAction.SET_COURSE, course: 0, commitId: orderItem.commitRefs[0] })
      } else {
        if (orderItem.course === undefined) return
        order?.commits?.push({
          action: CommitAction.SET_COURSE,
          course: orderItem.course + 1,
          commitId: orderItem.commitRefs[0]
        })
      }
    },
    trackMouse: true,
    trackTouch: true,
    touchEventOptions: {
      passive: false
    }
  })

  const handlers2 = useSwipeable({
    onTap: async ({ event }) => {
      event.preventDefault()
      if (!order) return
      if (orderItem?.isVoucher) return
      if (splitEnable?.()) {
        onItemClick?.(orderItem, order)
        return
      }
      if (moveEnable?.()) {
        // todo: When moving an item without pressing print, the item will remain on the old table
        onItemClick?.(orderItem, order)
        return
      }
      // if (!orderItem.printed) {
      setShowDiscountPopupItem(true && !moveEnable?.())
      setShowTabSidebar(true)
      setTabDiscountPopup(TabsDiscountPopup.MODIFIER)
      setCurrentItem(orderItem)
      // }
    },
    trackMouse: true,
    trackTouch: true,
    touchEventOptions: {
      passive: false
    }
  })

  const onClickPlus = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (orderItem?.isVoucher) return
    if (!Number.isInteger(orderItem?.quantity) || orderItem.splitId && orderItem?.originalInfo?.oldPrice !== orderItem.price) return toast.error('Cannot increase quantity of split item!')
    // if (orderItem?.isVoucher || orderItem?.printed) return
    onPlus?.(orderItem, true, seat?.() || 0)
  }

  const onClickMinus = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (orderItem?.printed && !getAccessibility(AccessPort.DELETE_PRINTED_ITEM)) return
    onMinus?.(orderItem, true, seat?.() || 0)
  }

  //to bind not passive onTouchEnd

  const minusButtonRef = useRef<HTMLImageElement | null>(null)
  const plusButtonRef = useRef<HTMLImageElement | null>(null)
  useEffect(() => {
    minusButtonRef.current?.addEventListener('touchend', onClickMinus, { passive: false })
    plusButtonRef.current?.addEventListener('touchend', onClickPlus, { passive: false })

    return () => {
      minusButtonRef.current?.removeEventListener('touchend', onClickMinus)
      plusButtonRef.current?.removeEventListener('touchend', onClickPlus)
    }
  }, [])



  return (
    <>
      <div
        className={clsx('even:bg-[#F8F8F8] rounded-sm bg-white flex flex-col items-start justify-start box-border text-left text-sm text-gray-200 font-mulish self-stretch',
          isSmallSidebar() ? "p-[5px]" : "p-2.5",
          twVisible(quantityNumber > 0 && orderItem.name !== 'AAA'))}
        {...handlers}
      >
        <div className="self-stretch flex flex-row items-center">
          <div className={clsx(printedOpacityClassName, 'flex flex-col items-start justify-start gap-[4px] text-[#1e1e23] flex-1')}
               {...handlers2}>
            <b className="line-clamp-2 relative leading-[15px]">{itemSName}</b>
            <div className="flex flex-row items-center justify-start gap-[8px] text-xs text-tomato-100">
              {!!orderItem.vDiscount && orderItem.vDiscount !== 0 &&
                <div className="relative text-3xs [text-decoration:line-through]">
                  {LL3().format.currency(oldPrice)}
                </div>
              }
              <div className="relative text-smi leading-[15px] font-semibold text-gray-200">
                {LL3().format.currency(price)}
              </div>
              <div
                className="flex flex-row items-center justify-start gap-[8px] text-xs text-red-solid-red-200-ff5252">
                {courseNumber &&
                  <i className="relative font-bold">{courseNumber}</i>
                }
                {orderItem?.course === 0 &&
                  <i className="relative font-bold text-limegreen-100">{LL0().order.takeAway()}</i>
                }
              </div>
            </div>
          </div>
          <div className="relative shrink-0 flex flex-row items-center justify-start gap-[2px] text-center text-base">
            {showAddMinusIcon && (
              <div className={clsx("relative mr-[-14px] bg-gray-200 flex items-center justify-center cursor-pointer",
                isSmallSidebar() ? 'w-[37px] h-10' : 'w-12 h-11'
              )}
                   onMouseDown={onClickMinus}
              >
                <img
                  className={clsx(
                    'minusvector-red--%123 absolute left-[7px]',
                    !getAccessibility(AccessPort.DELETE_PRINTED_ITEM) && printedOpacityClassName,
                    isSmallSidebar() ? ' w-5 h-5' : ' w-6 h-6'
                  )}
                  alt=""
                  src={orderItem.printed ? '/minusvector-red.svg' : '/minusvector1.svg'}
                  ref={minusButtonRef}
                />
              </div>
            )}
            <b className={clsx(printedOpacityClassName, 'relative mr-[-2px] inline-block w-6 shrink-0 z-10 text-black')}>
              {quantityNumber}
            </b>
            {showAddMinusIcon && (
              <div className={clsx("relative ml-[-14px] mr-[-5px] bg-gray-200 flex items-center justify-center cursor-pointer",
                isSmallSidebar() ? 'w-[37px] h-10' : 'w-12 h-11'
              )}
                   onMouseDown={onClickPlus}
              >
                <img
                  className={clsx(
                    'plusvector1--%123 absolute right-[5px]',
                    printedOpacityClassName,
                    orderItem?.isVoucher && 'opacity-70',
                    isSmallSidebar() ? ' w-5 h-5' : ' w-6 h-6'
                  )}
                  alt=""
                  src="/plusvector1.svg"
                  ref={plusButtonRef}
                />
                {/*<i className="relative font-bold">{courseNumber}</i>*/}
              </div>
            )}
          </div>

        </div>
        <div
          className={clsx(printedOpacityClassName, 'self-stretch flex flex-row flex-wrap items-start justify-start text-xs text-dimgray gap-x-2 gap-y-2')}>
          {orderItem?.modifiers?.map((modifier, index) => (
            <div className="!bg-[#E8E8E8] rounded-sm bg-gainsboro-100 flex flex-row items-center justify-start p-1 gap-[2px]"
                 key={index}>
              {modifier?.name} | {LL3().format.currency(modifier.vPrice!)}
            </div>
          ))}
        </div>
        {showSeperatedLine && (
          <div className="!mt-2 self-stretch relative bg-tomato-100 h-0.5" />
        )}
      </div>
      {showDiscountPopupItem() && (
        <PortalPopup2
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setShowDiscountPopupItem(false)}
        >
          <DiscountPopupItem onClose={() => setShowDiscountPopupItem(false)} />
        </PortalPopup2>
      )}
    </>
  )
}

export default OrderItemComponent