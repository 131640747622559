import { SerialPort, type TSerialPort } from "@/shared/webview/rnwebview.ts";
import { computed, signal } from "@/react/core/reactive.ts";
import type { Remote } from "comlink";
import type { OrderItem } from "@/pos/OrderType.ts";
import { getMessageForVFD, pre1, pre2 } from "@/shared/vfd/vfd-utils.ts";
import { wsLock } from "@/react/websocket/ws-state.ts";
import { connectedSerialBaudRate, connectedSerialDevice, serialDeviceManager } from "../serial/serial.logic"
import { deviceSetting0 } from "@/data/DeviceSettingSignal"

const [vfd,setVfd] = signal<Remote<TSerialPort>>();

const isSerial = computed(() => deviceSetting0()?.secondDisplayType === 'serial');

export async function initVFD() {
  if (isSerial() && connectedSerialBaudRate() && connectedSerialDevice()) {
    // Reconnect to the serial device if it was connected before and the device is still available
    const devices = await serialDeviceManager.getSerialDevices(true)
    if (devices.includes(connectedSerialDevice())) {
      await serialDeviceManager.connectSerialPortPrinter(connectedSerialDevice(), Number(connectedSerialBaudRate()))
    }
    return
  }
  
  await wsLock.acquireAsync();
  const deviceList = await SerialPort.list();
  const device = deviceList.find(d => d.vendorId === 1659);
  if (device) {
    await SerialPort.tryRequestPermission(device.deviceId);
    const sp = await new SerialPort(device.deviceId);
    await sp.init();
    setVfd(() => sp);
  }
}

export async function clearVFD() {
  if (isSerial()) {
    await serialDeviceManager.printToSerialPort(`${pre2}${pre2}`);
  } else {
    await vfd().send(`${pre2}${pre2}`);
  }
}

let clearCustomerDisplayTimeout: any;

export async function testVFD() {
  if (isSerial()) {
    await serialDeviceManager.printToSerialPort(`${pre1}Line 1${pre2}Line 2`);
  } else {
    await vfd().send(`${pre1}Line 1${pre2}Line 2`);
  }
}

export async function displayVFD({ discount, item, sum }: { discount?: string, item?: { name?: string, price: number }, sum: string }) {
  if (!vfd() && !isSerial()) return

  if (sum && !item) {
    clearCustomerDisplayTimeout = setTimeout(clearVFD, 2 * 60 * 1000)
  } else {
    if (clearCustomerDisplayTimeout) {
      clearTimeout(clearCustomerDisplayTimeout);
      clearCustomerDisplayTimeout = null;
    }
  }

  const msg = getMessageForVFD({ discount, item, sum });
  if (isSerial()) {
    await serialDeviceManager.printToSerialPort(msg)
  } else {
    await vfd().send(msg)
  }
}