/**
 * @license
 * Copyright 2019 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

import type {Endpoint} from "comlink/src/protocol";
import type {DefaultEventMap, EventEmitter} from "tsee";

export interface NodeEndpoint<T extends DefaultEventMap> {
  postMessage(message: string): void;
  ee: EventEmitter<T>
}

export default function nodeEndpoint<T extends DefaultEventMap>(nep: NodeEndpoint<T>): Endpoint {
  return {
    postMessage: (event: any) => {
      nep.postMessage(JSON.stringify(event));
    },
    addEventListener: (event, handler) => {
      nep.ee.on(event, handler as any);
    },
    removeEventListener: (event, handler) => {
      nep.ee.removeListener(event, handler as any);
    },
    start: () => null,
  };
}
