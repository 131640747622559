import React, { type FunctionComponent } from "react";
import { OrderType, type TOrder } from "../../../../src/pos/OrderType";
import OrdersInfo from "./OrdersInfo";
import { useComputed } from "@/react/core/reactive";
import dayjs from "dayjs";
import { CircularProgress } from "@mui/material";
import { now } from "@/react/shared/utils";
import { smartFormatShortDateTime } from "@/shared/dateTimeUtils.ts";
import {LL0} from "@/react/core/I18nService.tsx";

const Header: FunctionComponent<TOrder> = (order) => {
  const isDeliveryOrder = order.type === OrderType.Delivery
  const customerAddress = order.customerRaw?.address

  const hasAnswerDateTimeCountdown = !!order.answerDate
  const timeoutInSeconds = hasAnswerDateTimeCountdown ? Math.max(0, dayjs(order.answerDate).diff(dayjs(order.createdDate), 'second')) : 0
  const remainTimeInSeconds = useComputed(() => hasAnswerDateTimeCountdown ? Math.max(0, dayjs(order.answerDate).diff(now(), 'second')) : 0)
  const progress = useComputed(() => {
    if (remainTimeInSeconds() > 0) {
      return remainTimeInSeconds() / timeoutInSeconds * 100
    }
    return 0
  })

  const orderTime = useComputed(() => {
    if (order.pickupDate === 'asap')
      return LL0().pendingOrder.asap()
    return smartFormatShortDateTime(order.pickupDate) || ''
  })

  return (
    <div
      className="self-stretch flex flex-row items-start justify-start z-[2] text-left text-sm text-mediumslateblue font-mulish">
      <div className="flex-1 flex flex-col items-start justify-start gap-[6px]">
        <OrdersInfo order={order} showOrderTypeIcon={true}/>
        {isDeliveryOrder && customerAddress && (<>
          <div className="self-stretch flex flex-row items-start justify-start text-black">
            <img
              className="relative w-[18px] h-[18px]"
              alt=""
              src="/pin-icon.svg"
            />
            <div className="flex-1 relative leading-[117.4%] sm:text-smi">
              {customerAddress}
            </div>
          </div>
        </>)}
      </div>

      {hasAnswerDateTimeCountdown ? (
          <div className="relative w-9 h-9 text-center text-materialize-red-lighten-2">
            <CircularProgress
              style={{ width: '100%', height: '100%' }}
              color="error"
              thickness={2}
              value={progress()}
              variant={"determinate"}/>
            <div
              className="absolute top-[0px] left-[0px] rounded-[50%] box-border w-9 h-9 border-[0.5px] border-solid border-grey-grey-lighten-2"/>
            <b className="absolute top-[10px] left-[2px] leading-[15px] inline-block w-[31px]">
              {remainTimeInSeconds()}
            </b>
          </div>
        )
        :
        <div>
          {orderTime()}
        </div>
      }
    </div>
  );
};

export default Header;
