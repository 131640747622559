import CashBalancingNextRight from '@cashbook/CashBalancingNextRight.tsx'
import CashbookCashPlugin from '@cashbook/CashbookCashPlugin.tsx'
import CashbookHistoryDetailsP from '@cashbook/CashbookHistoryDetailsP.tsx'
import CashbookHistoryPlugin from '@cashbook/CashbookHistoryPlugin.tsx'
import CashbookPlugin from '@cashbook/CashbookPlugin.tsx'
import CashCounting from '@cashbook/CashCountingPlugin.tsx'
import TransactionDetailsPlugin from '@cashbook/TransactionDetailsPlugin.tsx'
import dayjs, { type Dayjs } from 'dayjs'
import _ from 'lodash'
import { memo } from 'react'
import uuid from 'time-uuid'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {
  CashbookHistories,
  CashbookTransactions
} from '@/data/Cashbook'
import { renderPivotTable } from '@/pos/logic/pivot'
import { now } from '@/pos/logic/time-provider.ts'
import { getVDate, getVDateDayjs } from '@/pos/orderUtils'
import { batch, computed, signal } from '@/react/core/reactive.ts'
import type { RxDocument } from 'rxdb'
import { convertToBase64Png } from '../Printer/print-kitchen-utils'
import { onEnter, PosScreen } from "@/pos/PosRouter.ts";
import { printCashbook } from "@/react/CashbookView/cashbook-logic.ts";

import { companyInfo0, posSetting0 } from "@/data/PosSettingsSignal.ts";
import { loginUser } from "@/data/UserSignal.ts";

export type CashbookHistoryReport = {
  id: number
  vDate: number
  startBalance: number
  closeBalance: number
  incomingBalance: number
  outgoingBalance: number
  firstTransactionTime: number
  lastTransactionTime: number
  isClosed: boolean
  transactions: CashbookTransactions[]
}
type VATProps = {
  name: string
  value: number
}
type CurrencyProps = {
  value: number
  quantity: number
}

export const VATData: VATProps[] = [
  { name: '0%', value: 0 },
  { name: '5%', value: 0.05 },
  { name: '7%', value: 0.07 },
  { name: '10%', value: 0.1 },
  { name: '19%', value: 0.19 },
]

export const cashValueList: CurrencyProps[] = [
  { value: 500, quantity: 0 },
  { value: 200, quantity: 0 },
  { value: 100, quantity: 0 },
  { value: 50, quantity: 0 },
  { value: 20, quantity: 0 },
  { value: 10, quantity: 0 },
  { value: 5, quantity: 0 },
  { value: 2, quantity: 0 },
  { value: 1, quantity: 0 },
]

export const coinValueList: CurrencyProps[] = [
  { value: 0.5, quantity: 0 },
  { value: 0.2, quantity: 0 },
  { value: 0.1, quantity: 0 },
  { value: 0.05, quantity: 0 },
  { value: 0.02, quantity: 0 },
  { value: 0.01, quantity: 0 },
]

export enum CashbookViewList {
  Cashbook,
  CashbookCounting,
  CashbookCountingNext,
  CashbookCash,
  CashbookHistory,
  CashbookHistoryDetails,
  CashbookHistoryTransactionDetails,
}

export enum CashbookTransactionsAction {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
}

export enum CashbookTransactionsType {
  CASHSALE = 'CASHSALE',
  BANK = 'BANK',
  CHANGE = 'CHANGE',
}

export type CashbookTransaction = {
  transactionType: string
  amount: string
  note?: string
  tax: number
  receipt: string | ArrayBuffer
}

export const [selectedCashbookView, setSelectedCashbookView] = signal(CashbookViewList.Cashbook)
const [historyDetailsId, setHistoryDetailsId] = signal<number>()
const [historyTransactionDetailsId, setHistoryTransactionDetailsId] = signal<{ id: string, _id: string }>({
  id: "",
  _id: ""
})
export const [cashbookAction, setCashbookAction] = signal<CashbookTransactionsAction>(CashbookTransactionsAction.INCOMING)
export const [todayStartBalance, setTodayStartBalance] = signal<number>(0)
export const [currentBalance, setCurrentBalance] = signal<number>(0)
export const [counted, setCounted] = signal<number>(0)
export const [todayIncoming, setTodayIncoming] = signal<number>(0)
export const [todayOutgoing, setTodayOutgoing] = signal<number>(0)
export const [firstTransactionTime, setFirstTransactionTime] = signal<number>()
export const [lastTransactionTime, setLastTransactionTime] = signal<number>()
export const [cashbookTransaction, setCashbookTransaction] = signal<CashbookTransaction>({
  transactionType: cashbookAction() === CashbookTransactionsAction.INCOMING ? posSetting0()?.cashbook.incomingTypes[0] : posSetting0()?.cashbook.outgoingTypes[0],
  amount: "0",
  tax: 0,
  note: '',
  receipt: ""
})
export const [cashValue, setCashValue] = signal<CurrencyProps[]>(_.cloneDeep(cashValueList))
export const [coinValue, setCoinValue] = signal<CurrencyProps[]>(_.cloneDeep(coinValueList))
export const [cashbookHistoryList, setCashbookHistoryList] = signal<RxDocument<CashbookHistories, {}>[]>([])
export const [dateRange, setDateRange] = signal<(string | Dayjs | null)[]>([getCashBookHistoryStartDate(), dayjs().format('YYYY-MM-DD')])
export const [transactions, setTransactions] = signal<RxDocument<CashbookTransactions, {}>[]>([])
export const [isClosed, setIsClosed] = signal<boolean>(false)
export const [newTypeValue, setNewType] = signal<string>('')
export const [deleteTypeIndex, setDeleteTypeIndex] = signal<number | undefined>(undefined)

export const VIEW__HISTORY_DETAILS = {
  setSelectedCashbookView,
  historyDetailsId,
  onClick: (id: number) => setHistoryDetailsId(id),
}

export const VIEW__HISTORY_TRANSACTION_DETAILS = {
  setSelectedCashbookView,
  historyTransactionDetailsId,
  onClick: ({ _id, id }: { _id: string, id: string }) => setHistoryTransactionDetailsId({ id, _id }),
}

export const handleAddNewType = (newType: string, type?: CashbookTransactionsAction) => {
  let _newType = newType.trim().replace(/\s+/g, ' ')
  if (_newType.length === 0 && _newType === '') return
  if (type === CashbookTransactionsAction.INCOMING) {
    posSetting0()?.cashbook?.incomingTypes?.push(_newType)
  } else if (type === CashbookTransactionsAction.OUTGOING) {
    posSetting0()?.cashbook?.outgoingTypes?.push(_newType)
  } else {
    setNewType('')
    return
  }
  setNewType('')
}

export const handleChangeType = (value: string, index: number, type?: CashbookTransactionsAction) => {
  if (!posSetting0()?.cashbook) return
  if (type === CashbookTransactionsAction.INCOMING) {
    posSetting0()!.cashbook!.incomingTypes![index] = value.trim().replace(/\s+/g, ' ')
  } else if (type === CashbookTransactionsAction.OUTGOING) {
    posSetting0()!.cashbook!.incomingTypes![index] = value.trim().replace(/\s+/g, ' ')
  }
}

export const handleDeleteType = (type?: CashbookTransactionsAction) => {
  if (deleteTypeIndex() === undefined) return
  let newArr
  if (type === CashbookTransactionsAction.INCOMING) {
    newArr = posSetting0()?.cashbook?.incomingTypes?.filter((_, index) => index !== deleteTypeIndex());
    posSetting0()!.cashbook!.incomingTypes = newArr
  } else if (type === CashbookTransactionsAction.OUTGOING) {
    newArr = posSetting0()?.cashbook?.outgoingTypes?.filter((_, index) => index !== deleteTypeIndex());
    posSetting0()!.cashbook!.outgoingTypes = newArr
  }
  setDeleteTypeIndex(undefined)
}

const isArrayBuffer = (value: any) => value instanceof ArrayBuffer;

export const createCashbookTransaction = async (transaction: CashbookTransaction, action: CashbookTransactionsAction) => {
  const date = dayjs(now()).unix()
  const vDate = getVDate(date)
  let base64 = transaction.receipt
  if (isArrayBuffer(transaction.receipt)) {
    console.log('convertToBase64Png')
    base64 = await convertToBase64Png({ data: transaction.receipt as Buffer, width: 300, height: 500 })
  }
  await CashbookTransactions.insert({
    ...transaction,
    orderId: '',
    date,
    vDate,
    _id: uuid(),
    action,
    user: loginUser()?.name,
    amount: +transaction.amount,
    receipt: base64
  })
  await fetchCurrentBalance();
}

async function getLastEod(onlyNotCachedReports: boolean) {
  const lastClosedReport = await CashbookHistories.findOne({
    selector: { isClosed: true },
    sort: [{ id: 'desc' }]
  }).exec()
  return lastClosedReport
}

async function makeCashBookReport(onlyNotCachedReports = false): Promise<CashbookHistoryReport[]> {
  let lastEod = await getLastEod(false)
  const vDateCondition: any = {}
  if (lastEod) {
    vDateCondition.date = {
      $gt: lastEod.lastTransactionTime,
    }
  }
  const notClosedTransactions = (
    await CashbookTransactions.find(lastEod ? {
      selector: {
        ...vDateCondition,
      },
    } : {}).exec()
  ).sort((a, b) => a.date - b.date)

  let concatReducer = {
    label: 'orders',
    fn(_orders: any, order: any) {
      return _.concat(_orders, order)
    },
    initValue: [],
  }
  const transactionByVDate = renderPivotTable(
    {
      columns: ['@date:vDate'],
      reducers: [concatReducer],
    },
    notClosedTransactions
  )
  const vDates = Object.keys(transactionByVDate).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
  let lastCloseBalance = lastEod?.closeBalance || 0;
  let lastId = lastEod?.id || 0;
  const res = vDates.map((vDate, idx) => {
    const transactions = transactionByVDate[vDate]
    const balanceChanged = _.sumBy(transactions, (i: CashbookTransactions) => (i.action === CashbookTransactionsAction.INCOMING ? i.amount || 0 : -(i.amount || 0)))
    const incomingBalance = _.sumBy(
      transactions.filter((i: CashbookTransactions) => i.action === CashbookTransactionsAction.INCOMING),
      'amount'
    )
    const outgoingBalance = _.sumBy(
      transactions.filter((i: CashbookTransactions) => i.action === CashbookTransactionsAction.OUTGOING),
      'amount'
    )
    const startBalance = _.round(lastCloseBalance, 2)
    const closeBalance = _.round(lastCloseBalance + balanceChanged, 2);
    lastCloseBalance = closeBalance;
    lastId++;
    return {
      id: lastId,
      vDate: transactions[0].vDate,
      startBalance,
      closeBalance,
      incomingBalance: _.round(incomingBalance, 2),
      outgoingBalance: _.round(outgoingBalance, 2),
      firstTransactionTime: transactions[0].date,
      lastTransactionTime: transactions[transactions.length - 1].date,
      isClosed: false,
      transactions
    }
  })
  return res
}

async function savePendingReports(
  pendingReports: CashbookHistoryReport[],
  currentVDate: number
) {
  for (const report of pendingReports) {
    const { id, vDate } = report
    if (vDate === currentVDate) {
      continue
    }
    const eod = await CashbookHistories.findOne({ selector: { id: id } }).exec();
    if (eod) {
      await eod.incrementalUpdate({ $set: { ...getDefaultReportProps(report), isClosed: false } });
    } else {
      await CashbookHistories.insert({ ...getDefaultReportProps(report), isClosed: false, _id: uuid() });
    }
  }
}

export async function getLastCashBookReport() {
  const currentVDate = getCurrentVdate()
  const tempReports = await makeCashBookReport(true)
  // await savePendingReports(tempReports, currentVDate)
  if (tempReports.length) return getDefaultReportProps(tempReports[tempReports.length - 1])
  else return getLastEod(true)
}

export async function closeDay(onlyPreviousDays = false) {
  const notClosedReports = await makeCashBookReport(false)
  const currentVDate = getCurrentVdate()
  for (const report of notClosedReports) {
    const { id, vDate, transactions } = report
    const transactionIds = transactions.map((i: CashbookTransactions) => i._id)
    if (onlyPreviousDays && vDate === currentVDate) continue
    for (const transactionId of transactionIds) {
      const doc = await CashbookTransactions.findOne({
        selector: {
          _id: transactionId
        }
      }).exec()
      if (doc) {
        await doc.update({
          $set: {
            z: id
          }
        })
      }
    }
    await CashbookHistories.insert({ ...getDefaultReportProps(report), isClosed: true, _id: uuid() })
    // await printCashbook(getCurrentVdateDayjs(), report);
  }
  await printCashbook(getCurrentVdateDayjs(), notClosedReports);

  setIsClosed(true)
  await fetchCurrentBalance();
}

export async function getCashBookTransactions(id: number, from: number, to: number) {
  return await CashbookTransactions.find({
    selector: {
      date: {
        $gte: from,
        $lte: to
      }
    }
  }).exec()
}

export async function getCashBookHistoryList(from: number, to: number) {
  const currentVDate = getCurrentVdate()
  const tempReports = await makeCashBookReport(true)
  const tempReports2 = _.sortBy(tempReports.filter(r => r.vDate >= from && r.vDate <= to), ['vDate'], 'desc');
  // await savePendingReports(tempReports, currentVDate)
  const reports = await CashbookHistories.find({
    selector: {
      vDate: { $gte: from, $lte: to }
    }
  }).exec()
  for (const r of tempReports2) {
    reports.push(r as any);
  }
  return reports
}

function getDefaultReportProps(report: CashbookHistoryReport): Omit<CashbookHistoryReport, "transactions"> {
  return _.omit(report, ['transactions'])
}

export function getCashBookHistoryStartDate() {
  if (dayjs().date() < 7) {
    return dayjs().subtract(7, 'd').format('YYYY-MM-DD')
  } else return dayjs().startOf('month').format('YYYY-MM-DD')
}

export function toStartOfDate(date: string | Dayjs | null) {
  return dayjs(date, 'YYYY-MM-DD').startOf('d').unix()
}

export function toEndOfDate(date: string | Dayjs | null) {
  return dayjs(date, 'YYYY-MM-DD').endOf('d').unix()
}

export function getCurrentVdate() {
  return getVDate(dayjs(now()).unix())
}


export function getCurrentVdateDayjs() {
  return getVDateDayjs(dayjs(now()))
}

export function formatDate(date: number, type: string) {
  return dayjs.unix(date).format(type)
}

async function fetchCurrentBalance() {
  const lastReport = await getLastCashBookReport()
  console.log('lastReport', lastReport)
  if (!lastReport) return

  if (lastReport.isClosed) {
    batch(() => {
      setTodayStartBalance(lastReport.closeBalance || 0)
      setCurrentBalance(lastReport.closeBalance || 0)
      setTodayIncoming(0)
      setTodayOutgoing(0)
      setFirstTransactionTime(dayjs(now()).unix())
      setLastTransactionTime(dayjs(now()).unix())
    })
  } else {
    setTodayStartBalance(lastReport.startBalance || 0)
    setCurrentBalance(lastReport.closeBalance || 0)
    setTodayIncoming(lastReport.incomingBalance || 0)
    setTodayOutgoing(lastReport.outgoingBalance || 0)
    setFirstTransactionTime(lastReport.firstTransactionTime || dayjs(now()).unix())
    setLastTransactionTime(lastReport.lastTransactionTime || dayjs(now()).unix())
  }
}

export const formatNumber = (number: string | number) => {
  if (number.toString().includes(",")) {
    number = number.toString().replace(",", ".")
  }
  return +number
}

const CashbookView = () => {
  onEnter(PosScreen.CASHBOOK, async () => {
    await fetchCurrentBalance()
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {(selectedCashbookView() === CashbookViewList.Cashbook && <CashbookPlugin />) ||
        (selectedCashbookView() === CashbookViewList.CashbookCounting && <CashCounting />) ||
        (selectedCashbookView() === CashbookViewList.CashbookCountingNext && <CashBalancingNextRight />) ||
        (selectedCashbookView() === CashbookViewList.CashbookCash && cashbookAction() === CashbookTransactionsAction.INCOMING &&
          <CashbookCashPlugin type={CashbookTransactionsAction.INCOMING} />) ||
        (selectedCashbookView() === CashbookViewList.CashbookCash && cashbookAction() === CashbookTransactionsAction.OUTGOING &&
          <CashbookCashPlugin type={CashbookTransactionsAction.OUTGOING} />) ||
        (selectedCashbookView() === CashbookViewList.CashbookHistory &&
          (historyDetailsId() ? historyTransactionDetailsId()._id ? <TransactionDetailsPlugin /> :
            <CashbookHistoryDetailsP /> : <CashbookHistoryPlugin />))}
    </LocalizationProvider>
  )
}


export default memo(CashbookView)
