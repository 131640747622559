import _ from "lodash";
import uuid from "time-uuid";
import { dataLock } from "@/data/DataUtils.ts";
import { OnlineOrder } from "@/data/OnlineOrder.ts";
import { OnlineOrderProductMappingCollection, type OnlineOrderProductMapping } from '@/data/OnlineOrderProductMapping.ts';
import type { Product } from "@/data/Product.ts";
import { MarketPlaceProvider, type TOrder } from "@/pos/OrderType.ts";
import { computed, signal } from '@/react/core/reactive';
import { unwrapProxy } from "@/react/utils/common.ts";

export const [productMatches, setProductMatches] = signal<Array<OnlineOrderProductMapping>>([]);
export const productMatchesGroupByProvider = computed(() => _.groupBy(productMatches(), item => item.provider))
export const hasProductMatches = computed(() => productMatches().length > 0)

export const fetchProductMatches = async () => {
  try {
    const rxDocs: any = await OnlineOrderProductMappingCollection.find().exec() // <- here
    const docs = _.map(rxDocs, rxDoc => rxDoc.toMutableJSON())
    setProductMatches(docs)
  } catch (e) {
    console.error('Failed to fetch product matches', e)
  }
}

dataLock.acquireAsync().then(async () => {
  OnlineOrderProductMappingCollection.$.subscribe(fetchProductMatches)
  await fetchProductMatches()
})

/// -------
const requiredMappingFields = ['_id', 'id', 'sku', 'name']
function getExternalProductKey(provider: MarketPlaceProvider, item: any) {
  switch (provider) {
    case MarketPlaceProvider.RESTABLO:
      return `${item.name}__${item.sku || ''}`
    case MarketPlaceProvider.LIEFERANDO:
      return item.id;
    default:
      return item._id;
  }
}

export async function mapProductAndCorrectOrderItem(order: TOrder, itemIdx: number, posProduct?: Product) {
  const items = unwrapProxy(order.items)
  if (itemIdx >= items.length) {
    console.warn('[mapProduct] bad index')
    return
  }
  posProduct = unwrapProxy(posProduct)
  const original = _.cloneDeep(items[itemIdx])
  await mapProduct(order.provider!, original, posProduct?._id);

  // update items
  const externalProductKey = getExternalProductKey(order.provider!, original);
  const itemsWithTheSameKey = items.filter((item: any) => getExternalProductKey(order.provider!, item) === externalProductKey);

  for (const item of itemsWithTheSameKey) {
    // mapped
    item._id = posProduct?._id

    // printer
    item.labelPrinter = posProduct?.labelPrinter
    item.groupPrinter = posProduct?.groupPrinter
    item.groupPrinter2 = posProduct?.groupPrinter2

    // tax
    item.tax = posProduct?.tax
    item.tax2 = posProduct?.tax2
    item.taxCategory = posProduct?.taxCategory
    item.taxCategory2 = posProduct?.taxCategory2
    item.taxComponents = posProduct?.taxComponents
    item.taxComponents2 = posProduct?.taxComponents2
    item.taxes = [posProduct?.tax, posProduct?.tax2]
    // add more if needed
  }

  try {
    console.log('[mapProduct] update OnlineOrderCollection')
    const rawOrder = await OnlineOrder.findOne({ selector: { _id: order._id } }).exec()
    await rawOrder?.incrementalPatch({ items: items })
    console.log('[mapProduct] OnlineOrderCollection updated')
  } catch (e) {
    console.error('[mapProduct] failed to update items', e)
  }
}

export async function mapProduct(provider: MarketPlaceProvider, onlineOrderProduct: any, posProductId: string) {
  try {
    const externalProductKey = getExternalProductKey(provider, onlineOrderProduct);
    const existedDoc = await OnlineOrderProductMappingCollection.findOne({selector: {externalProductKey}}).exec()
    const change = {
      _id: existedDoc?._id,
      externalProductKey,
      original: _.pick(onlineOrderProduct, requiredMappingFields),
      productId: posProductId,
      provider,
    }
    console.log('[mapProduct] saving OnlineOrderProductMappingCollection', externalProductKey, change)
    await OnlineOrderProductMappingCollection.upsert(change)
    console.log('[mapProduct] OnlineOrderProductMappingCollection saved')
    await fetchProductMatches()
  } catch (e) {
    console.error('[mapProduct] failed to save OnlineOrderProductMappingCollection', e)
  }
}

export async function unmapProduct(_id: string) {
  const pair = await OnlineOrderProductMappingCollection.findOne({selector: {_id}}).exec()
  await pair?.remove()
  await fetchProductMatches()
}