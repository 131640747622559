import { Switch } from "@mui/material";
import {
  isShowCustomerInfo,
  isShowNewDeliveryOrder, isShowOnlineMenu,
  isShowPendingOrder, isShowReservation, updatePosFeature
} from "@/shared/posFeatures.ts";


export const DeveloperPosFeaturesTab = () => {
  return (
    <>
      <div className="grid gap-2 items-center place-items-start">
        <label className="flex items-center gap-2">
          <Switch
            checked={isShowPendingOrder()}
            onChange={(_e, checked) => updatePosFeature({ showPendingOrder: checked })}
          />
          <p>Pending Order</p>
        </label>

        <label className="flex items-center gap-2">
          <Switch
            checked={isShowNewDeliveryOrder()}
            onChange={(_e, checked) => updatePosFeature({ showNewDelivery: checked })}
          />
          <p>New Delivery</p>
        </label>

        <label className="flex items-center gap-2">
          <Switch
            checked={isShowOnlineMenu()}
            onChange={(_e, checked) => updatePosFeature({ showOnlineMenu: checked })}
          />
          <p>Online Menu</p>
        </label>

        <label className="flex items-center gap-2">
          <Switch
            checked={isShowReservation()}
            onChange={(_e, checked) => updatePosFeature({ showReservation: checked })}
          />
          <p>Reservation</p>
        </label>

        <label className="flex items-center gap-2">
          <Switch
            checked={isShowCustomerInfo()}
            onChange={(_e, checked) => updatePosFeature({ showCustomerInfo: checked })}
          />
          <p>Customer Information</p>
        </label>
      </div>

    </>
  )
}
export default DeveloperPosFeaturesTab