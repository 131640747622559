import type { FunctionComponent } from 'react'
import StaffReportPaymentDetailCash from './StaffReportPaymentDetailCash'
import StaffReportPaymentDetailCredit from './StaffReportPaymentDetailCredit'
import {LL0} from "@/react/core/I18nService.tsx";
import StaffReportPaymentDetailDebitor from "@staff-report/StaffReportPaymentDetailDebitor.tsx";

type P = {
  paymentDetailCash?: number
  paymentDetailCredit?: number
  paymentDetailDebitor?: number
}
const StaffReportPaymentDetail: FunctionComponent<P> = ({ paymentDetailCash, paymentDetailCredit, paymentDetailDebitor }) => {
  return (
    <div className="self-stretch bg-white-solid-white-100-ffffff flex flex-col items-start justify-start py-2 px-6 gap-[2px] text-left text-sm text-gray font-mulish Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border">
      <b className="self-stretch relative text-mini text-royalblue-200 Tablet_768:text-sm Tablet_600:text-smi">{LL0().staffReport.paymentDetail()}</b>
      <StaffReportPaymentDetailCash paymentDetailCash={paymentDetailCash} />
      <StaffReportPaymentDetailCredit paymentDetailCredit={paymentDetailCredit} />
      <StaffReportPaymentDetailDebitor paymentDetailDebitor={paymentDetailDebitor} />
    </div>
  )
}

export default StaffReportPaymentDetail
