import type { FunctionComponent } from "react";
import {LL0} from "@/react/core/I18nService.tsx";
import { useDeviceId } from '@/shared/getDeviceId'
import {paymentsWithTerminal, makePaymentsAvailable} from "@/data/PaymentHub.ts";
import {
  makeTerminalsAvailable,
  toggleUseTerminalAsDefaultForThisDevice,
} from '@/data/TerminalHub.ts'
import _ from 'lodash'

type PaymentSelectTerminalPopuType = {
  onClose?: () => void;
};

const PaymentSelectTerminalPopu: FunctionComponent<
  PaymentSelectTerminalPopuType
> = ({ onClose }) => {
  const LL = LL0()
  makeTerminalsAvailable()
  makePaymentsAvailable()

  const deviceId = useDeviceId()

  return (
    <div className="w-full h-full relative rounded bg-white flex flex-col items-center justify-start py-6 px-8 box-border gap-[24px] max-w-full max-h-full overflow-auto text-left text-base text-black-solid-black-900-1e1e23 font-mulish">
      <div className="self-stretch relative font-semibold">
        {LL.dashboard.selectTerminalTitle()}
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[21px]">
        {paymentsWithTerminal().map((pm, index) => <div key={index} className="self-stretch flex flex-col items-start justify-start gap-[12px]">
          <div className="self-stretch relative font-extrabold capitalize">{pm.name}:</div>
          <div className="flex flex-row flex-wrap items-center justify-start gap-[16px] text-mini text-gray-solid-gray-920-4e4e4e">
            {
              _.map(pm.terminals, (terminal, terIndex) =>
                <div key={terIndex} className="rounded-10xs bg-gray-solid-gray-130-f4f4f4 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] overflow-hidden flex flex-row items-center justify-center py-[10.5px] px-[13.5px] relative gap-[10px] border-[0.5px] border-solid border-gray-solid-gray-400-c9cbcc"
                    onClick={() => toggleUseTerminalAsDefaultForThisDevice(terminal!._id, deviceId)}>
                  {
                    terminal?.useAsDefaultForPosDevices.includes(deviceId) &&
                      <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff z-[0]" />
                  }
                  <div className="relative z-[1]">{terminal?.name}</div>
                </div>
            )}
          </div>
        </div>)}
      </div>
      <div className="self-stretch flex flex-row items-center justify-end gap-[12px] text-center text-sm text-blue-solid-blue-420-2979ff">
        {/*<div*/}
        {/*  className="self-stretch w-[78px] rounded-md bg-white-solid-white-100-ffffff box-border overflow-hidden shrink-0 flex flex-row items-center justify-center py-[9px] px-4 cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"*/}
        {/*  onClick={onClose}*/}
        {/*>*/}
        {/*  <b className="relative">{LL.onlineOrder.cancel()}</b>*/}
        {/*</div>*/}
        <div className="cursor-pointer self-stretch w-28 rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden shrink-0 flex flex-row items-center justify-center py-[9px] px-8 box-border text-white-solid-white-100-ffffff"
             onClick={onClose}>
          <b className="relative">{LL.ui.close()}</b>
        </div>
      </div>
    </div>
  );
};

export default PaymentSelectTerminalPopu;
