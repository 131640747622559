import type { CSSProperties, PropsWithChildren } from "react";

export interface GridLayoutProps {
	rows: number
	cols: number
	colGap?: number
	rowGap?: number
  scrollable?: boolean
}

const GridLayout = (props: GridLayoutProps & { className?: string; style?: CSSProperties } & PropsWithChildren) => {
	//todo: template-rows, template-columns
	const style0 = {
		display: 'grid',
		gridTemplateColumns: `repeat(${props.cols}, minmax(0, 1fr))`,
		gridRowGap: `${props.rowGap}px`,
		gridColumnGap: `${props.colGap}px`,
    ... !props.scrollable && {
      gridTemplateRows: `repeat(${props.rows}, minmax(0, 1fr))`,
      contain: 'strict'
    }
	}

	return <div className={props.className} style={{ ...style0, ...props.style }}>
		<>{props.children}</>
	</div>;
}

export default GridLayout;
