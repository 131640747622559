import { makeRipple } from "@/react/core/ripple-utils.ts";
import { useEffect, useState } from 'react'
import type { Setter } from "solid-js";

type propsKeyboardNumber = {
  setValueKeyboard: Setter<string>
  index?: number
  valueInput?: string
}

const KeyboardNumber = (props: propsKeyboardNumber) => {
  const [value, setValue] = useState('')
  const {setValueKeyboard, index, valueInput} = props
  setValueKeyboard(value)
  useEffect(() => {
    setValue(valueInput!)
  }, [index]);
  return <>
    <div className="self-stretch flex flex-col items-center justify-center py-0 px-3 text-center text-xl">
      <div
        className="self-stretch h-[86px] rounded bg-gray-solid-gray-180-ebecee [backdrop-filter:blur(30px)] flex flex-row items-center justify-center py-1 px-2 box-border Mobile_480:gap-[4px] Mobile_480:p-0.5 Mobile_480:box-border">
        <div
          className="flex-1 relative h-[78px] grid grid-rows-[repeat(2,1fr)] grid-cols-[repeat(6,1fr)] [grid-row-gap:5px] [grid-column-gap:8px]">
          <div ref={makeRipple} onClick={() => setValue(v => v + '.')}
               className="rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] shadow-[0px_1px_0px_#898a8d] overflow-hidden flex flex-row items-center justify-center relative col-[6/6] row-[1/1] text-base text-black-solid-black-900-1e1e23">
            <div className="relative leading-[17px] font-medium">.</div>
          </div>
          <div ref={makeRipple} onClick={() => setValue(v => (v.toString().slice(0, -1)))}
               className="rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] shadow-[0px_1px_0px_#898a8d] overflow-hidden flex flex-row items-center justify-center relative col-[6/6] row-[2/2]">
            <img
              className="w-[30px] relative h-[30px] object-cover"
              alt=""
              src="/iconicon-delete-key@2x.png"
            />
          </div>
          <div ref={makeRipple} onClick={() => setValue(v => v + '1')}
               className="rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center relative col-[2/2] row-[1/1] Mobile_480:relative Mobile_480:col-[2/2] Mobile_480:row-[1/1]">
            <div className="relative">1</div>
          </div>
          <div ref={makeRipple} onClick={() => setValue(v => (v + '2'))}
               className="rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center relative col-[3/3] row-[1/1]">
            <div className="relative">2</div>
          </div>
          <div ref={makeRipple} onClick={() => setValue(v => (v + '3'))}
               className="rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center relative col-[4/4] row-[1/1]">
            <div className="relative">3</div>
          </div>
          <div ref={makeRipple} onClick={() => setValue(v => (v + '4'))}
               className="rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center relative col-[5/5] row-[1/1]">
            <div className="relative">4</div>
          </div>
          <div ref={makeRipple} onClick={() => setValue(v => (v + '5'))}
               className="rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center relative col-[1/1] row-[2/2]">
            <div className="relative">5</div>
          </div>
          <div ref={makeRipple} onClick={() => setValue(v => (v + '6'))}
               className="rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center relative col-[2/2] row-[2/2]">
            <div className="relative">6</div>
          </div>
          <div ref={makeRipple} onClick={() => setValue(v => (v + '7'))}
               className="rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center relative col-[3/3] row-[2/2]">
            <div className="relative">7</div>
          </div>
          <div ref={makeRipple} onClick={() => setValue(v => (v + '8'))}
               className="rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center relative col-[4/4] row-[2/2]">
            <div className="relative">8</div>
          </div>
          <div ref={makeRipple} onClick={() => setValue(v => (v + '9'))}
               className="rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center relative col-[5/5] row-[2/2]">
            <div className="relative">9</div>
          </div>
          <div ref={makeRipple} onClick={() => setValue(v => (v + '0'))}
               className="rounded-8xs-6 bg-white-solid-white-120-fcfcfe shadow-[0px_1px_0px_#898a8d] flex flex-col items-center justify-center relative col-[1/1] row-[1/1]">
            <div className="relative">0</div>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default KeyboardNumber