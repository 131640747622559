import { type FunctionComponent, useCallback, useState } from 'react';
import TreeSelectionPopup from '@edit-room/TreeSelectionPopup';
import PortalPopup from '@edit-room/PortalPopup';
import { createRoomObject, EditorMode, setMode0 } from '@/react/EditRoomView/EditRoomView.tsx';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import {LL0} from "@/react/core/I18nService.tsx";

const AddWallTreeTableButtonGroup: FunctionComponent = () => {
  const [isTreeSelectionPopupOpen, setTreeSelectionPopupOpen] = useState(false);

  const openTreeSelectionPopup = useCallback(() => {
    setMode0(EditorMode.Tree)
    setTreeSelectionPopupOpen(true);
  }, []);

  const closeTreeSelectionPopup = useCallback(() => {
    setTreeSelectionPopupOpen(false);
  }, []);

  return (
    <>
      <div className="self-stretch flex flex-col items-start justify-start pt-0 px-[13px] pb-2.5 gap-[15px] text-left text-smi text-royalblue-300 font-mulish">
        <div className="self-stretch flex flex-row items-start justify-start gap-[6px]">
          <div className="flex-1 rounded-md flex flex-row items-center justify-center py-[10.5px] px-0 border-[1px] border-solid border-royalblue-100"
               ref={makeRipple}
               onPointerDown={() => createRoomObject('wall')}
          >
            <b className="relative leading-[15px]">{LL0().restaurant.addWall()}</b>
          </div>
          {/*<div*/}
          {/*  className="flex-1 rounded-md flex flex-row items-center justify-center py-[10.5px] px-0 cursor-pointer border-[1px] border-solid border-royalblue-100"*/}
          {/*  onClick={openTreeSelectionPopup}*/}
          {/*  ref={makeRipple}*/}
          {/*>*/}
          {/*  <b className="relative leading-[15px]">{LL0().restaurant.addTree()}</b>*/}
          {/*</div>*/}
        </div>
        <div className="self-stretch rounded-md bg-royalblue-100 flex flex-row items-center justify-center py-[10.5px] px-0 text-center text-basic-color-white border-[1px] border-solid border-royalblue-100"
             ref={makeRipple}
             onPointerDown={() => createRoomObject('table')}
        >
          <b className="relative leading-[15px]">{LL0().restaurant.addTable()}</b>
        </div>
      </div>
      {isTreeSelectionPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeTreeSelectionPopup}
        >
          <TreeSelectionPopup
            onClose={closeTreeSelectionPopup}
            onComplete={() => {}}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default AddWallTreeTableButtonGroup;
