import { useSignal } from "@/react/core/reactive";
import { type EventHandler, type MouseEvent, type TouchEvent, type MouseEventHandler, ReactEventHandler, type TouchEventHandler } from "react";

type SwipeHandlers = {
	handleStart: (event: MouseEvent | TouchEvent) => void;
	handleEnd: (event: MouseEvent | TouchEvent) => void;
}

export function useSwipeHandlers(onSwipeLeft: () => void = () => {
}, onSwipeRight: () => void = () => {
}, onTap: () => void = () => {
}): {
	onTouchStart: TouchEventHandler<HTMLDivElement>;
	onTouchEnd: TouchEventHandler<HTMLDivElement>;
	onMouseUp: MouseEventHandler<HTMLDivElement>;
	onMouseDown: MouseEventHandler<HTMLDivElement>
} {
	const [startX, setStartX] = useSignal(0);
	const [startY, setStartY] = useSignal(0);
	const [swiping, setSwiping] = useSignal(false);

	const handleStart: EventHandler<MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>> = (event) => {
		setSwiping(true);
		if ("touches" in event) {
			setStartX(event.touches[0].clientX);
			setStartY(event.touches[0].clientY);
		} else {
			setStartX(event.clientX);
			setStartY(event.clientY);
		}
	};

	const handleEnd:  EventHandler<MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>> = (event) => {
		let endX = 0, endY = 0;
		if ("changedTouches" in event) {
			endX = event.changedTouches[0].clientX;
			endY = event.changedTouches[0].clientY;
		} else {
			endX = event.clientX;
			endY = event.clientY;
		}

		const dx = endX - startX();
		const dy = endY - startY();
		const absDx = Math.abs(dx);
		const absDy = Math.abs(dy);

		if (!swiping() || absDx < 10 && absDy < 10) {
			onTap();
		} else if (absDx > absDy) {
			if (dx > 0) {
				onSwipeRight();
			} else {
				onSwipeLeft();
			}
		}
		setSwiping(false);
	};

	const events = {
		onMouseDown: handleStart,
		onMouseUp: handleEnd,
		onTouchStart: handleStart,
		onTouchEnd: handleEnd
	};

	return events;
}
