import type { FunctionComponent } from "react";
import DashboardPopupTabButton from "@dashboard/DashboardPopupTabButton";
import { PosScreen, router } from '@/pos/PosRouter.ts';
import { LL0 } from "@/react/core/I18nService.tsx";
import { getAccessibility } from '@/react/DecentralizationPort.ts';

import { isCashbookEnabled } from "@/data/PosSettingsSignal.ts";
import { loginUser } from "@/data/UserSignal.ts";

type ReportsCashbookPopupType = {
  onClose?: () => void;
};

const ReportsCashbookPopup: FunctionComponent<ReportsCashbookPopupType> = ({
  onClose,
}) => {
  const LL = LL0()
  const onClickTab = (screen: PosScreen) => {
    router.screen = screen;
    onClose?.();
  }

  return (
    <div className="report-cashbook-popup relative rounded-md [background:linear-gradient(180deg,_#fbfbfb,_#c1c1c1)] [backdrop-filter:blur(100px)] w-full flex flex-col items-center justify-start p-8 box-border gap-[24px] h-full max-w-full max-h-full overflow-auto Tablet_600:pl-6 Tablet_600:pr-6 Tablet_600:box-border Mobile_480:pl-4 Mobile_480:pr-4 Mobile_480:box-border">
      <div className="self-stretch overflow-y-auto flex flex-row flex-wrap items-start justify-start py-0.5 px-0 gap-[12px] z-[0] Tablet_600:gap-[8px]">
        {getAccessibility(PosScreen.EOD) &&
          <DashboardPopupTabButton
            iconImageUrl="/iconendofday-icon71@2x.png"
            buttonText={LL.dashboard.endOfDay()}
            noticeNumber="20"
            showChip={false}
            showInfoIcon={false}
            onClick={() => onClickTab(PosScreen.EOD)}
          />
        }
        {getAccessibility(PosScreen.MONTHLY_REPORT) &&
          <DashboardPopupTabButton
            iconImageUrl="/icon-monthly-report-icon911@2x.png"
            buttonText={LL.dashboard.monthlyReport()}
            noticeNumber="20"
            showChip={false}
            showInfoIcon={false}
            onClick={() => onClickTab(PosScreen.MONTHLY_REPORT)}
          />
        }
        {getAccessibility(PosScreen.STAFF_REPORT) &&
          <DashboardPopupTabButton
            iconImageUrl="/icon-staff-report-icon811@2x.png"
            buttonText={LL.dashboard.staffReport()}
            noticeNumber="20"
            showChip={false}
            showInfoIcon={false}
            onClick={() => onClickTab(PosScreen.STAFF_REPORT)}
          />
        }
        {/*<DashboardPopupTabButton*/}
        {/*  iconImageUrl="/icontime-management-icon@2x.png"*/}
        {/*  buttonText={LL.dashboard.timeManagement()}*/}
        {/*  noticeNumber="20"*/}
        {/*  showChip={false}*/}
        {/*  showInfoIcon={false}*/}
        {/*  onClick={()=> onClickTab(PosScreen.TIME_MANAGEMENT)}*/}
        {/*/>*/}
        {getAccessibility(PosScreen.CASHBOOK) &&
          <DashboardPopupTabButton
            iconImageUrl="/iconcashbook-icon111@2x.png"
            buttonText={LL.dashboard.cashbook()}
            noticeNumber="20"
            showChip={false}
            showInfoIcon={false}
            onClick={() => onClickTab(PosScreen.CASHBOOK)}
          />
        }
      </div>
      <img
        className="absolute my-0 mx-[!important] top-[7px] right-[7px] w-[25px] h-[25.4px] cursor-pointer z-[1]"
        alt=""
        src="/close-tab.svg"
        onClick={onClose}
      />
    </div>
  );
};

export default ReportsCashbookPopup;
