import type React from 'react';
import { useState } from 'react';
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { clsx } from "clsx";
import pTimeout, { TimeoutError } from "p-timeout";


interface ButtonWrapperProps {
  isDisabled?: boolean;
  children: React.ReactNode;
  timeOut?: number;
  onClick?: () => Promise<void> | void;
  className?: string | ''
}

const ButtonWrapper: React.FC<ButtonWrapperProps> = ({ isDisabled, timeOut = 9000, children, onClick,className= '' }) => {
  const [isProgress, setIsProgress] = useState<boolean>(false);
  const rippleRef = !isDisabled && !isProgress ? makeRipple : undefined;

  const handleClick = async () => {
    if (isDisabled || isProgress || !onClick) return;

    try {
      setIsProgress(true);
      await pTimeout(onClick(), { milliseconds: timeOut });
    } catch (error) {
      if (error instanceof TimeoutError) {
        throw error;
      } else {
        throw error;
      }
    } finally {
      setIsProgress(false);
    }
  };

  return (
    <button
      onClick={handleClick}
      disabled={isDisabled || isProgress}
      ref={rippleRef}
      className={clsx(`relative bg-transparent overflow-hidden rounded-81xl ${className}`,
        isProgress && ' cursor-not-allowed ',
        isDisabled && ' opacity-70 cursor-not-allowed '
      )}
    >
      {isProgress &&
        <div className={'absolute inset-0 flex items-center justify-center bg-black/60 z-10'}>
          <div className="animate-spin loader ease-linear rounded-full border-2 border-t-white border-slate-400 h-4 w-4"></div>
        </div>}
      {children}
    </button>
  );
};

export default ButtonWrapper;
