import {
  type CashbookTransactionsAction,
  CashbookViewList,
  setCashbookAction,
  setSelectedCashbookView
} from "@/react/CashbookView/CashbookView";
import { batch } from "@/react/core/reactive.ts";
import { makeRipple } from "@/react/core/ripple-utils";
import type { FunctionComponent } from "react";
import { LL3 } from "@/react/core/I18nCurrency.tsx";

type CashbookBalanceChangeType = {
  icon: string;
  title: string;
  color: string;
  change: number;
  type: CashbookTransactionsAction;
};

const CashbookBalanceChange: FunctionComponent<CashbookBalanceChangeType> = ({
  icon, title, color, change, type
}) => {
  return (
    <div
      className="self-stretch rounded-md [background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] h-[53px] flex flex-row items-center justify-between Tablet_768:h-10 height_414:h-[42px]">
      <div
        className="self-stretch rounded-md [background:linear-gradient(180deg,_rgba(139,_184,_255,_0.8),_rgba(38,_93,_219,_0.8))] w-56 flex flex-row items-center justify-start py-3 px-[25px] box-border gap-[11px] text-left Tablet_768:w-[200px] Tablet_600:w-[180px] Tablet_600:pl-4 Tablet_600:pr-4 Tablet_600:box-border mq1440:w-[260px] height_414:pt-1.5 height_414:pb-1.5 height_414:box-border">
        <img
          className="relative w-[29px] h-[29px] object-cover"
          alt=""
          src={icon}
        />
        <div
          className="flex-1 relative sm:text-base sm:leading-[16px] Tablet_768:text-sm Tablet_600:text-sm mq1440:text-xl height_414:text-base">
          {title}
        </div>
      </div>
      <div className="self-stretch flex-1 flex flex-row items-center justify-end">
        <div
          className="flex-1 relative leading-[18px] sm:text-base Tablet_768:text-base Tablet_600:text-sm mq1440:text-xl">
          {LL3().format.currency(change)}
        </div>
      </div>
      <div
        className={`hover:cursor-pointer self-stretch rounded-md [background:${color}] w-[62px] flex flex-col items-center justify-center p-2.5 box-border text-11xl`}
        onClick={() => {
          batch(() => {
            setSelectedCashbookView(CashbookViewList.CashbookCash)
            setCashbookAction(type)
          })
        }}
        ref={makeRipple}
      >
        <div className="relative leading-[30px]">+</div>
      </div>
    </div>
  );
};

export default CashbookBalanceChange;
