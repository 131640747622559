import { type FunctionComponent, useState, useCallback, useEffect } from "react";
import {
  Switch,
  FormControlLabel,
} from "@mui/material";
import MissingPopup from "./MissingPopup";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import Input, { inputController0 } from "@/react/core/Input.tsx";
import PortalPopup from "@payment/PortalPopup.tsx";
import { useSignal } from "@/react/core/reactive.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import clsx from "clsx";
import {
  addVoucher,
  checkVoucherStatus,
  genVoucherCode,
  redeemVoucher
} from "@/react/VoucherView/VoucherView.tsx";
import type { TOrder } from "@/pos/OrderType.ts";
import { PosScreen, router } from "@/pos/PosRouter.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import { vouchers0 } from "@/data/VoucherHub.ts";

export type VoucherPopupType = {
  className?: string;
  onClose?: () => void;
  order?: TOrder;
};

enum VoucherTabs {
  CREATE,
  REDEEM,
  OTHERS
}

interface VoucherInfo {
  code?: string,
  amount?: string,
  price?: string,
  firstName?: string,
  lastName?: string,
}

const VoucherPopup: FunctionComponent<VoucherPopupType> = ({
  className = "",
  onClose,
  order
}) => {
  const [isNewDeliveryCustomerInfoMOpen, setNewDeliveryCustomerInfoMOpen] =
    useState(false);

  const openNewDeliveryCustomerInfoM = useCallback(() => {
    setNewDeliveryCustomerInfoMOpen(true);
  }, []);

  const closeNewDeliveryCustomerInfoM = useCallback(() => {
    setNewDeliveryCustomerInfoMOpen(false);
  }, []);

  const [tab, setTab] = useSignal<VoucherTabs>(VoucherTabs.REDEEM)
  const [voucherInfo, setVoucherInfo] = useSignal<VoucherInfo>()
  const [isEqualPrice, setIsEqualPrice] = useSignal<boolean>(true)
  const [missing, setMissing] = useSignal<string>('')
  const [message, setMessage] = useSignal<string>()
  const [voucherExists, setVoucherExists] = useSignal<boolean>(false)

  const checkRequiredFields = useCallback((): boolean => {
    if (!voucherInfo()?.code) {
      setMissing(LL0().voucher.voucherCode());
      openNewDeliveryCustomerInfoM();
      return false;
    } else if (!voucherInfo()?.amount) {
      setMissing(LL0().voucher.voucherAmount());
      openNewDeliveryCustomerInfoM();
      return false;
    } else if (!voucherInfo()?.price && !isEqualPrice() && tab() !== VoucherTabs.REDEEM) {
      setMissing(LL0().voucher.voucherPrice());
      openNewDeliveryCustomerInfoM();
      return false;
    }
    return true;
  }, [missing()]);

  const handleAddVoucher = useCallback(() => {
    if (voucherExists()) return;
    if (checkRequiredFields()) {
      addVoucher({
        code: voucherInfo().code!,
        price: isEqualPrice() ? +voucherInfo().amount! : +voucherInfo().price!,
        voucher: {
          amount: +voucherInfo().amount!,
          firstName: voucherInfo().firstName,
          lastName: voucherInfo().lastName,
        },
      }, order!)
      onClose?.()
    }
  }, [])

  const handleRedeemVoucher = useCallback(async () => {
    if(voucherExists() && tab() === VoucherTabs.CREATE) return;
    if (checkRequiredFields()) {
      await redeemVoucher(
        voucherInfo().code!,
        +voucherInfo().amount!,
        order!,
        {
          firstName: voucherInfo().firstName,
          lastName: voucherInfo().lastName
        }
      )
      onClose?.()
    }
  }, [])

  useEffect(() => {
    const data = checkVoucherStatus(voucherInfo()?.code || '')
    if (data && data.valid) {
      setMessage(`${LL0().voucher.remainingValue()}: ${data.remainingValue}`)
      if (data.voucher) {
        if (tab() === VoucherTabs.CREATE) {
          setVoucherExists(true)
          return
        }
        setVoucherInfo(p =>
          ({
            ...p,
            amount: data.remainingValue.toString(),
            lastName: data.voucher?.customerLastName?.toString(),
            firstName: data.voucher?.customerFirstName?.toString(),
          }))

      }
    } else if (data && data.message) {
      if (tab() === VoucherTabs.CREATE) {
        setVoucherExists(false)
      }
      setMessage(data.message)
      if (voucherInfo()?.code === '') {
        setVoucherInfo(p =>
          ({
            ...p,
            amount: '',
            lastName: '',
            firstName: '',
          }))
      }
    }
  }, [voucherInfo()?.code]);

  return (
    <>
      <div
        className={`min-w-[620px] min-h-[350px] relative rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-start max-w-full max-h-full text-left text-3xl text-black-solid-black-1000-000000 font-mulish ${className}`}
      >
        <div
          className="self-stretch rounded-t rounded-b-none bg-white-solid-white-400-f2f2f2 border-white-solid-white-100-ffffff border-b-[1px] border-solid box-border h-10 flex flex-row items-center justify-between py-0.5 px-3 z-[1] text-mini text-blue-solid-blue-500-0051c1 sm:h-[42px] sm:pl-4 sm:pr-4 sm:box-border">
          <b className="relative">{LL0().voucher.voucher()}</b>
          <div className="flex flex-row items-center justify-end gap-4 text-center text-white-solid-white-100-ffffff">
            <div
              className="shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)] rounded-11xl [background:linear-gradient(180deg,_rgba(139,_184,_255,_0.8),_rgba(38,_93,_219,_0.8))] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0 px-6 box-border cursor-pointer"
              ref={makeRipple}
              onClick={async () => {
                if (tab() === VoucherTabs.CREATE) {
                  handleAddVoucher();
                } else if (tab() === VoucherTabs.REDEEM) {
                  await handleRedeemVoucher();
                }
              }}
            >
              <div className="relative leading-[16px] font-semibold">{LL0().ui.add()}</div>
            </div>
            <img
              className="w-[22px] relative h-[22px] object-cover cursor-pointer"
              alt=""
              src="/icongeneralclose-popup-icon--grey@2x.png"
              onClick={onClose}
            />
          </div>
        </div>
        <div className="no-scrollbar overflow-y-auto self-stretch flex-1 flex flex-row items-start justify-start z-[0] text-xs">
          <div
            className="self-stretch w-[90px] bg-gray-solid-gray-130-f4f4f4 overflow-y-auto shrink-0 flex flex-col items-start justify-start Tablet_600:flex Mobile_480:hidden mq320:hidden">
            <div className={clsx("self-stretch flex flex-col items-start justify-center",
              tab() === VoucherTabs.CREATE && 'text-blue-solid-blue-470-356ff6'
            )}
                 ref={makeRipple}
                 onClick={() => {
                   setTab(VoucherTabs.CREATE)
                   setVoucherInfo({})
                 }}
            >
              <div className="self-stretch flex flex-row items-center justify-start py-[15px] px-2 relative">
                {tab() === VoucherTabs.CREATE && <div
                  className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-blue-solid-blue-70-cfddfd border-blue-solid-blue-470-356ff6 border-l-[1.5px] border-solid box-border z-[0]"/>}
                <div className="relative font-semibold z-[1]">{LL0().voucher.create()}</div>
              </div>
            </div>
            <div className={clsx("self-stretch flex flex-col items-start justify-center",
              tab() === VoucherTabs.REDEEM && 'text-blue-solid-blue-470-356ff6'
            )}
                 ref={makeRipple}
                 onClick={() => {
                   setTab(VoucherTabs.REDEEM)
                   setVoucherInfo({})
                 }}
            >
              <div className="self-stretch flex flex-row items-center justify-start py-[15px] px-2 relative">
                {tab() === VoucherTabs.REDEEM && <div
                  className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-blue-solid-blue-70-cfddfd border-blue-solid-blue-470-356ff6 border-l-[1.5px] border-solid box-border z-[0]"/>}
                <div className="relative font-semibold z-[1]">{LL0().voucher.redeem()}</div>
              </div>
            </div>
            <div className={clsx("self-stretch flex flex-col items-start justify-center",
              tab() === VoucherTabs.OTHERS && 'text-blue-solid-blue-470-356ff6'
            )}
                 ref={makeRipple}
                 onClick={() => setTab(VoucherTabs.OTHERS)}
            >
              <div className="self-stretch flex flex-row items-center justify-start py-[15px] px-2 relative">
                {tab() === VoucherTabs.OTHERS && <div
                  className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-blue-solid-blue-70-cfddfd border-blue-solid-blue-470-356ff6 border-l-[1.5px] border-solid box-border z-[0]"/>}
                <div className="relative font-semibold z-[1]">{LL0().voucher.others()}</div>
              </div>
            </div>
          </div>
          {tab() === VoucherTabs.CREATE && <div
            className="gap-2.5 self-stretch flex-1 bg-white-solid-white-100-ffffff overflow-y-auto flex-col items-start justify-start pt-3 px-3 pb-2 box-border text-sm">
            <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-2.5">
              <div className="self-stretch flex flex-row items-end justify-start gap-2.5">
                <div className="flex-1 flex flex-col items-start justify-center">
                  <div className="self-stretch relative">{LL0().voucher.voucherCode()}</div>
                  <Input
                    className="self-stretch font-mulish font-medium text-base text-black-solid-black-900-1e1e23"
                    value={voucherInfo()?.code || ''}
                    onChange={value =>
                      setVoucherInfo(p => ({ ...p, code: value }))}
                  />
                </div>
                <div
                  className="rounded-md bg-blue-solid-blue-420-2979ff h-9 overflow-hidden flex flex-row items-center justify-center py-[9px] px-3 box-border text-center text-white-solid-white-100-ffffff"
                  ref={makeRipple}
                  onClick={() => {
                    const code = genVoucherCode()
                    setVoucherInfo(p => ({ ...p, code: code }))
                  }}
                >
                  <b className="relative">{LL0().voucher.autoGen()}</b>
                </div>
              </div>
              {voucherExists() &&
                <span className="text-sm ml-2">{LL0().voucher.voucherExists()}</span>
              }
              <div className="self-stretch flex flex-row items-center justify-start gap-3">
                {!isEqualPrice() && <div className="self-stretch flex-1 flex-col items-start justify-start">
                  <div className="self-stretch relative">{LL0().voucher.voucherPrice()}</div>
                  <Input
                    className="w-full self-stretch font-mulish font-medium text-base text-black-solid-black-900-1e1e23"
                    value={voucherInfo()?.price || ''}
                    onChange={value =>
                      setVoucherInfo(p => ({ ...p, price: value }))}
                  />
                </div>}
                <div className="self-stretch flex-1 flex flex-col items-start justify-start">
                  <div className="self-stretch relative">{LL0().voucher.voucherAmount()}</div>
                  <Input
                    className="self-stretch font-mulish font-medium text-base text-black-solid-black-900-1e1e23"
                    value={voucherInfo()?.amount || ''}
                    onChange={value =>
                      setVoucherInfo(p => ({ ...p, amount: value }))}
                  />
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-3">
                <div className="flex-1 flex flex-col items-start justify-center">
                  <div className="self-stretch relative">
                    {LL0().voucher.customersFirstName()}
                  </div>
                  <Input
                    className="self-stretch font-mulish font-medium text-base text-black-solid-black-900-1e1e23"
                    value={voucherInfo()?.firstName || ''}
                    onChange={value => setVoucherInfo(p => ({ ...p, firstName: value }))}
                  />
                </div>
                <div className="self-stretch flex-1 flex flex-col items-start justify-start">
                  <div className="self-stretch relative">
                    {LL0().voucher.customersLastName()}
                  </div>
                  <Input
                    className="self-stretch font-mulish font-medium text-base text-black-solid-black-900-1e1e23"
                    value={voucherInfo()?.lastName || ''}
                    onChange={value => setVoucherInfo(p => ({ ...p, lastName: value }))}
                  />
                </div>
              </div>
              <div className="self-stretch h-6 grid flex-row items-center justify-start gap-2 grid-flow-col">
                <div className="self-stretch w-10 relative">
                  <FormControlLabel
                    label={''}
                    className="absolute h-[120%] w-full top-[-10%] right-[0%] bottom-[-10%] left-[0%]"
                    control={<Switch color="primary"
                                     checked={isEqualPrice()}
                                     onChange={(e, checked) => setIsEqualPrice(checked)}
                    />}
                  />
                </div>
                <div className="self-stretch flex-1 relative font-medium flex items-center Tablet_768:text-sm">
                  {LL0().voucher.voucherPriceEqualsVoucherValue()}
                </div>
              </div>
            </div>
            <TableManualKeyboard
              inputController={inputController0}
              onEnter={handleAddVoucher}
            />
          </div>}
          {tab() === VoucherTabs.REDEEM && <div
            className="gap-2.5 self-stretch flex-1 bg-white-solid-white-100-ffffff overflow-y-auto flex flex-col items-start justify-start pt-3 px-3 pb-2">
            <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-2.5">
              <Input
                className="self-stretch font-mulish text-sm text-black-solid-black-1000-000000"
                label={LL0().voucher.enterCode()}
                value={voucherInfo()?.code || ''}
                onChange={value =>
                  setVoucherInfo(p => ({ ...p, code: value }))}
              />
              {voucherInfo()?.code &&
                <span className="text-sm ml-2">{message()}</span>
              }
              <Input
                className="self-stretch font-mulish text-sm text-black-solid-black-1000-000000"
                label={LL0().voucher.enterAmount()}
                value={voucherInfo()?.amount || ''}
                onChange={value =>
                  setVoucherInfo(p => ({ ...p, amount: value }))}
              />
              <div className="self-stretch flex flex-row items-center justify-start gap-3">
                <Input
                  className="flex-1 font-mulish text-sm text-black-solid-black-1000-000000"
                  label={LL0().voucher.customersFirstName()}
                  value={voucherInfo()?.firstName || ''}
                  onChange={value => setVoucherInfo(p => ({ ...p, firstName: value }))}
                />
                <Input
                  className="self-stretch flex-1 font-mulish text-sm text-black-solid-black-1000-000000"
                  label={LL0().voucher.customersLastName()}
                  value={voucherInfo()?.lastName || ''}
                  onChange={value => setVoucherInfo(p => ({ ...p, lastName: value }))}
                />
              </div>
            </div>
            <TableManualKeyboard
              inputController={inputController0}
              onEnter={handleRedeemVoucher}
            />
          </div>}
          {tab() === VoucherTabs.OTHERS && <div
            className="self-stretch flex-1 bg-white-solid-white-100-ffffff overflow-y-auto flex-col items-start justify-start pt-3 px-3 pb-2 box-border text-center text-mini text-white-solid-white-100-ffffff">
            <div className="self-stretch flex-1 flex flex-col items-center justify-center pt-0 px-0 pb-[200px]">
              <div
                className="shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)] rounded-11xl [background:linear-gradient(180deg,_rgba(139,_184,_255,_0.8),_rgba(38,_93,_219,_0.8))] h-[34px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 px-4 box-border gap-2.5 cursor-pointer"
                ref={makeRipple}
                onClick={() => {
                  router.screen = PosScreen.VOUCHER
                  onClose?.()
                }}
              >
                <img
                  className="w-[30px] relative h-[30px] object-cover sm:w-[60px] sm:h-[60px] Tablet_768:w-14 Tablet_768:h-14 Tablet_600:w-[50px] Tablet_600:h-[50px]"
                  alt=""
                  src="/icongeneral-settings-icon@2x.png"
                />
                <div className="relative leading-[16px] font-semibold">
                  {LL0().voucher.goToVoucherScreen()}
                </div>
              </div>
            </div>

          </div>}

        </div>
      </div>
      {isNewDeliveryCustomerInfoMOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeNewDeliveryCustomerInfoM}
        >
          <MissingPopup
            missingText={missing()}
            onClose={closeNewDeliveryCustomerInfoM}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default VoucherPopup;
