import {type FunctionComponent, memo} from "react";
import PendingOrderLeftPath from "./PendingOrderLeftPath";
import PendingOrderRightPath from "./PendingOrderRightPath";
import { onEnterScreens, PosScreen } from "@/pos/PosRouter.ts";
import { setV, dirty } from "@/react/OrderView/OrderView.tsx";

export const monthKeyMap = {
  'Jan': '_1',
  'Feb': '_2',
  'Mar': '_3',
  'Apr': '_4',
  'May': '_5',
  'Jun': '_6',
  'Jul': '_7',
  'Aug': '_8',
  'Sep': '_9',
  'Oct': '_10',
  'Nov': '_11',
  'Dec': '_12',
};

onEnterScreens([PosScreen.PENDING_ORDERS], () => {
  if (dirty())
    setV(v => v + 1)
})

const PendingOrdersPlugin: FunctionComponent = () => {
  return (
    <div className="pending-orders-screen w-full h-full overflow-hidden flex flex-row items-start justify-start pt-2 px-9 pb-6 box-border gap-[24px] !bg-[url('/pending-orders--plugin@3x.png')] bg-cover bg-no-repeat bg-[top] sm:pl-6 sm:pr-6 sm:box-border Tablet_768:gap-[24px] Tablet_768:pl-5 Tablet_768:pr-5 Tablet_768:box-border Tablet_600:gap-[16px] Tablet_600:pl-2 Tablet_600:pr-2 Tablet_600:box-border Tablet_600:h-full Tablet_600:[widows:100%] Mobile_480:gap-[24px] Mobile_480:pl-2 Mobile_480:pr-2 Mobile_480:box-border">
      <PendingOrderLeftPath />
      <PendingOrderRightPath />
    </div>
  );
};

export default memo(PendingOrdersPlugin);
