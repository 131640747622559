import Queue from 'queue';
import axios from "axios";
import { notificationToast } from "@/react/FloorPlanView/Noti.ts";
import { getUrlParam } from "@/shared/utils2.ts";
import { rnHost } from "@/shared/webview/rnwebview.ts";
import { isAlwaysHidePopup, printerErrDialog } from "@/react/Printer/printer-dialog.tsx";
import { testSerialPortPrinter } from "@/react/Printer/print-serial-port.ts";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import { groupPrinters0 } from "@/data/GroupPrinterHub.ts";
import _ from "lodash";

let openedPort: string;

let ip = import.meta.env.MODE === 'development' ? '192.168.0.159' : 'localhost';
console.log('my mode: ', import.meta.env.MODE);

export async function findSerialPorts() {
  let result: any
  if (getUrlParam('os') === 'android') {
    result = await axios.get(`http://${ip}:5005/get-serial-ports-paths`)
  } else {
    const paths = await rnHost.findSerialPortPrinter()
    result = { data: paths }
  }
  return result.data as string[];
}

const queueManager: {
  [k: string]: { q: Queue, id: string, opened: boolean, timeoutId?: ReturnType<typeof setTimeout> }
} = {}

//for sunmi
export const printSerialPort = (task: { data: Buffer, path: string, baudrate: number }) => {
  const queueName = `${task.path}/${task.baudrate}`;
  queueManager[queueName] = queueManager[queueName] || {
    q: new Queue({ autostart: true, concurrency: 1 }),
    opened: false,
  }
  queueManager[queueName].q.push(async () => {
    let count = 0;
    while (count < 1) {
      try {
        if (getUrlParam('os') === 'android') {
          if (queueName !== openedPort) {
            //get-serial-ports-paths
            await axios.post(`http://${ip}:5005/connect-serial-port-printer`, {
              path: task.path,
              baudrate: task.baudrate || 19200
            });
            openedPort = queueName;
          }
          const data = task.data.toString('base64');
          await axios.post(`http://${ip}:5005/print-to-serial-port`, { data: data });
          break;
        } else {
          await rnHost.printCom(task.data, task.path, task.baudrate);
          break;
        }
      } catch (e: any) {
        notificationToast("print to serial port: error" + e.message, {
          type: "error",
          autoClose: 1000 * 60
        })
        const printer = groupPrinters0().find(g => g.printers[0].printerType === 'serialPort');
        if (generalSetting0().showPopupPrintError && !isAlwaysHidePopup() && printer) {
          await printerErrDialog(LL0().printing.printerError({
            printer: _.upperFirst(printer?.name),
            printerType: _.upperFirst(printer?.printers[0].printerType)
          }), undefined, testSerialPortPrinter)
        }
        // console.error(`print to serial port: error`, e);
        //todo: emit to virtual printer
      } finally {
        count++;
      }
    }
  })
}
