import type { FunctionComponent } from "react";
import NewDeliveryRight from "./NewDeliveryRight";
import OrderScreenLeft from "@order-view/OrderScreenLeft.tsx";

const NewDeliveryUpperBar: FunctionComponent = () => {
  return (
    <div className="self-stretch flex-1 overflow-y-auto flex flex-row items-start justify-start text-center text-smi text-black-solid-black-900-1e1e23 font-mulish">
      <OrderScreenLeft />
      <NewDeliveryRight />
    </div>
  );
};

export default NewDeliveryUpperBar;
