import Emittery from 'emittery'
import type { Action } from 'surrealdb.js/src/types.ts'

import type { Order } from '@/data/Order.ts'
import type { TseMethod } from '@/pos/OrderType.ts'

// Emittery.isDebugEnabled = true;

export const paymentHook = new Emittery<{
  preSplitHandler: TseMethod | undefined
  paySplitHandler: any[]
  payOrderHandler: any[]
  postKitchenPrint: Order
}>({ debug: { name: 'payment' } })

export const eodHook = new Emittery<{
  prePrintZ: undefined
  postPrintZ: string
}>({ debug: { name: 'eod' } })

export const liveSurrealHook = new Emittery<{
  request: [action: Action, result: Record<string, any>]
}>({ debug: { name: 'request' } })
