import { untracked } from '@preact/signals-react'
import dayjs from 'dayjs'
import { log } from 'debug'
import JsonFn, { clone } from 'json-fn'
import _ from 'lodash'
import { toast } from 'react-toastify'
import uuid from 'time-uuid'

import type { Order, OrderDocument } from '@/data/Order'
import { addTseMethod, mergeSeat } from '@/pos/logic/order-utils.ts'
import { now } from '@/pos/logic/time-provider.ts'
import {
  CommitAction,
  EquallySplit,
  type ItemCommit,
  type ItemCommitAddModifier,
  type ItemCommitAddPayment,
  type ItemCommitAddProduct,
  type ItemCommitAddSplitItem,
  type ItemCommitAddSplitModifier,
  type ItemCommitChangeCourse,
  type ItemCommitChangePayment,
  type ItemCommitSplitItem,
  type ItemModifier,
  type itemModifierInput,
  type OrderItem,
  type orderItemInput,
  OrderStatus,
  type OrderStrip,
  type Tax,
  TseMethod,
  type VTaxComponents,
  type VTaxSum,
} from '@/pos/OrderType'
import { batch, deepSignal, effect, effectOn } from '@/react/core/reactive'
import { orderConfig, roundNumber, useServiceFeeBeforeTax } from '@/shared/order/order-config.ts'
import { isInSrmTrainingMode } from '@/srm/signals'

import {
  calItemNet,
  calItemTax,
  calItemVSum,
  calItemVSumByPath,
  calItemVTotal,
  calPriceWDiscount,
  filterTseCommits,
  getLastRecentItems,
  getVDate,
  itemCompareFactory,
  mergeVTaxGroup,
} from '../orderUtils'
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { PaymentType } from '@/data/Payment'

function handleDouble(commit: ItemCommitSplitItem | ItemCommitAddModifier, _order: Order, order: Order) {
  const orderNum = order?.commits?.filter(c => c.action === commit.action && c.commitId === commit.commitId && c.seat === _order.seat) || []
  const seatNum = _order?.commits?.filter(c => c.action === commit.action && c.commitId === commit.commitId && c.seat === _order.seat) || []
  if (seatNum?.length < orderNum?.length) {
    _order.commits!.push(JsonFn.clone(commit))
  } else if (seatNum?.length > orderNum?.length) {
    const seatIds = seatNum.map(i => i._id)
    const orderIds = orderNum.map(i => i._id)
    return _.difference(seatIds, orderIds)
  }
}

//<editor-fold desc="createModifier">
const createModifier = (_modifier: itemModifierInput, item: OrderItem, order: Order) => {
  return deepSignal<ItemModifier>({
    quantity: 1,
    ..._.cloneDeep(_modifier),
    get vPrice(): number {
      let discount: number | string = 0;
      if (order.pDiscount) {
        discount = order.pDiscount + '%';
      } else if (typeof item.discount === 'string' && item.discount.includes('%')) {
        discount = item.discount;
      }
      return calPriceWDiscount(this.price, discount, orderConfig.discountPrecision)
    },
    get vSum(): number {
      return this.quantity * (this.vPrice || 0)
    },
  } as ItemModifier)
}
//</editor-fold>

//<editor-fold desc="createItem">
const createItem = (_item: orderItemInput, order: Order) => {
  const hasComputedTax = !!order.taxTotal
  const item = deepSignal<OrderItem>({
    ..._.defaults(_item, {
      quantity: 1,
      movedQuantity: 0,
      modifiers: [],
      course: 1,
      commitRefs: [],
      discount: 0,
      vDiscount: 0,
      printed: false,
    }),
    lastQuantity: _item.lastQuantity ? _item.lastQuantity: _item.quantity,
    get vPrice(): number {
      const discount = this.isVoucher ? 0 : order.pDiscount ? order.pDiscount + '%' : this.discount
      return calPriceWDiscount(this.price, discount, orderConfig.discountPrecision)
    },
    get vSum(): number {
      return calItemVSum(this, this.tax!, orderConfig.sumPrecision)
    },
    get vTotal(): number {
      return calItemVTotal(this, 'vPrice', orderConfig.sumPrecision)
    },
    get vSubTotal(): number {
      return this.vTotal! * this.quantity
    },
    get takeAway(): boolean {
      if (this.course === 0) {
        return true
      } else if (item.course === -1) {
        return false
      } else {
        return false
      }
    },
    get separate(): boolean {
      if (this.course === 0) {
        return false
      } else if (item.course === -1) {
        return true
      } else {
        return false
      }
    },
    get vTakeAway(): boolean {
      return !!(order.takeAway || this.takeAway)
    },
    get tax(): number {
      if (hasComputedTax) {
        return 0
      }
      if (this.taxComponents && this.taxComponents.length > 0) {
        // @ts-expect-error
        return _.sumBy(this.taxComponents, c => parseFloat(c.value!))
      }
      if (!this.taxes || this.taxes.length < 2) return 0 // TODO: check if returning "0" here is valid
      return this.vTakeAway ? this.taxes[1] : this.taxes[0]
    },
    get vTaxComponents(): { [k: string]: number } | undefined {
      if (this.taxComponents && this.taxComponents.length > 0) {
        const vTaxComponents = {}
        for (const taxComponent of this.taxComponents) {
          _.assign(vTaxComponents, {
            [taxComponent!.printLabel!]: calItemTax(this, taxComponent!.value!, orderConfig.sumPrecision),
          })
        }
        return vTaxComponents
      }
    },
    get vTaxSum(): VTaxSum {
      if (hasComputedTax) {
        return {}
      }
      const gross = calItemVSum(this, this.tax!, orderConfig.sumPrecision);
      const net = calItemNet(this, this.tax!, orderConfig.sumPrecision);
      return {
        [this.tax || 0]: {
          tax: roundNumber(gross - net, 2),
          net: net,
          gross: gross,
        },
      }
    },
    get vDiscount(): number {
      if (orderConfig.discountBeforeTax) {
        const beforeDiscount = calItemVTotal(this, 'price', orderConfig.sumPrecision) * this.quantity;
        const afterDiscount = calItemVTotal(this, 'vPrice', orderConfig.sumPrecision) * this.quantity;
        return roundNumber(beforeDiscount - afterDiscount, orderConfig.discountPrecision)
      }
      const vSumOriginal = calItemVSumByPath(this, 'price', orderConfig.discountPrecision)
      const vSum = calItemVSumByPath(this, 'vPrice', orderConfig.discountPrecision)
      return roundNumber(vSumOriginal - vSum, orderConfig.discountPrecision)
    },
  })

  if (_item.modifiers && _item.modifiers?.length > 0) {
    const modifiers = _item.modifiers.map(m => createModifier(m, item, order))
    item.modifiers.splice(0, item.modifiers.length, ...modifiers)
  }

  return item
}

//</editor-fold>

function getUnixDate(count: number = 1) {
  const result = dayjs(now()).unix()
  if (count >= 2) return -1
  if (result < 1577811600) {
    return getUnixDate(count + 1)
  }
  //fixme: get last order date to compare
  return result
}

async function checkAndFixDateOrder(order: Order) {
  if (order.date === -1) {
    // @ts-ignore
    const isoDate = await window.rnHost?.getDate()
    if (isoDate) {
      order.date = dayjs(isoDate).unix()
    }
    setTimeout(() => {
      // toast('System time is incorrect, please restart device !!!', {type: 'error'});
      if (isoDate) {
        throw new Error(`System time is incorrect, but react native time is ok: ${isoDate}`)
      } else {
        toast('System time is incorrect, please restart device !!!', { type: 'error' })
        throw new Error('System time is incorrect, please check your system time and try again.')
      }
    }, 0)
  }
}

//todo: date
export const createOrder: (
  rawOrder?:
    | OrderStrip
    | {
    table?: string
    users: Array<string>
    items?: Order['items']
    tip?: number
    manual?: boolean
  }
) => Order = rawOrder => {
  const prepareOrder = {
    ..._.defaults(rawOrder ? clone(rawOrder) : {}, {
      items: [],
      cancellationItems: [],
      directCancellationItems: [],
      takeAway: false,
      status: 'inProgress',
      payments: [],
      users: [],
      commits: [],
      _id: uuid(),
      payable: true,

      type: undefined,
      seatMap: [],
      seatMode: false,
      date: dayjs(now()).unix(),
      getRecent: () => [],
      getCancellationRecent: () => [],
      getMoveOrder: () => moveOrder,
      cashback: 0,
      tip: 0,
      serviceFee: 0,
      lack: 0,
      discount: undefined,
      discountLabel: '',
      vSum: 0,
      tseMethod: TseMethod.auto,
      manualTip: rawOrder?.tip || undefined,
      cashTip: undefined,
      appVersion: import.meta.env.VITE_APP_VERSION,
      multiplePriceMenu: '',
      discountSeat: [],
      trainingMode: isInSrmTrainingMode(),
    }),
    //percent Discount
    get pDiscount(): number | undefined {
      if (this.discount === undefined) return undefined
      if (typeof this.discount === 'string' && this.discount.includes('%')) {
        return parseFloat((this.discount as string).replace('%', ''))
      } else {
        let discount = this.discount
        if (typeof discount === 'string') discount = parseFloat(discount)
        const vSumOriginal = _.sumBy(
          this.items.filter(i => !i.isVoucher),
          item => calItemVTotal(item, 'price') * item.quantity
        )
        return (discount / vSumOriginal >= 0 ? 1 : -1) * roundNumber((discount / vSumOriginal) * 100, orderConfig.discountPrecision)
      }
    },
    get vDiscount() {
      return _.sumBy(this.items, i => i.vDiscount || 0)
    },
    get vTaxSum() {
      const vTaxSum = {}
      for (const item of _.compact(this.items)) {
        _.mergeWith(vTaxSum, item.vTaxSum, mergeVTaxGroup)
      }

      if (this.serviceFee && useServiceFeeBeforeTax()) {
        const TPS_VAL = 5
        const TVQ_VAL = 9.975
        const sfTaxAmount = roundNumber((this.serviceFee * TPS_VAL) / 100, 2)
        const sfTaxAmount2 = roundNumber((this.serviceFee * TVQ_VAL) / 100, 2)
        const sfTax: Tax = {
          gross: sfTaxAmount + sfTaxAmount2,
          net: 0, // already included in `order.serviceFee` field
          tax: sfTaxAmount + sfTaxAmount2,
        }
        _.mergeWith(vTaxSum, { [TPS_VAL + TVQ_VAL]: sfTax }, mergeVTaxGroup)
      }

      // if (order.shippingData && order.shippingData.vTaxSum)
      //   _.mergeWith(vTaxSum, order.shippingData.vTaxSum, mergeVTaxGroup);

      return vTaxSum
    },
    get vTaxComponents() {
      if (_.every(this.items, i => i.taxComponents && i.taxComponents.length > 0)) {
        const vTaxComponents: VTaxComponents = {}
        for (const item of this.items) {
          for (const k of Object.keys(item.vTaxComponents!)) {
            if (vTaxComponents[k]) {
              vTaxComponents[k] += item.vTaxComponents![k]
            } else {
              vTaxComponents[k] = item.vTaxComponents![k]
            }
          }
        }

        if (this.serviceFee && useServiceFeeBeforeTax()) {
          // TODO: take those value from TaxCategory
          const TPS_VAL = 5
          const TVQ_VAL = 9.975
          vTaxComponents['TPS'] += (this.serviceFee * TPS_VAL) / 100
          vTaxComponents['TVQ'] += (this.serviceFee * TVQ_VAL) / 100
        }

        return vTaxComponents
      }
      return undefined
    },
  } as Order
  let moveOrder: Order
  const order = deepSignal<Order>(prepareOrder) as Order
  checkAndFixDateOrder(order).then()

  if ((rawOrder?.items?.length || 0) > 0) {
    const items = (rawOrder?.items || []).map(i => createItem(i, order as Order))
    order.items.splice(0, order.items.length, ...items)
  }

  //hooks.emit('create:defaultValue', rawOrder);

  effect(() => {
    order.vDate = getVDate(order.date!);
  })

  //vSum doesn't include tip
  //<editor-fold desc="order.vDate, order.vSum">
  effect(() => {
    if (order.seatMode) {
      order.vSum = _.sumBy(
        order.seatMap!.filter(o => o.status !== OrderStatus.PAID),
        o => o.vSum || 0
      )
      return
    }
    order.vSubTotal = roundNumber(
      _.sumBy(order.items, i => i.vSubTotal || 0),
      2
    )
    let vSum: number = _.sumBy(order.items, i => i.vSum || 0)
    order.vItemTotal = roundNumber(vSum, 2)
    untracked(() => {
      if (order.serviceFee && useServiceFeeBeforeTax()) {
        order.vSubTotal = order.vSubTotal! + order.serviceFee
        order.vItemTotal = order.vItemTotal! + order.serviceFee
      }
    })
    if (orderConfig.isNetPriceBase) {
      vSum += (order.shippingData?.fee || 0) + (order.shippingData?.serviceFee || 0) + (order.serviceFee || 0) + (order.bagFee || 0) + (order.donation || 0)
      if (order.taxTotal) {
        // if taxTotal exist, vSum is not include item tax
        // so that we can add taxTotal to make final vSum
        vSum += order.taxTotal
      } else {
        // otherwise, extraTax is totalTax - itemTax
        const extraTax = ((order.shippingData?.fee || 0) * (order.shippingData?.tax || 0)) / 100 + ((order.shippingData?.serviceFee || 0) * (order.shippingData?.serviceTax || 0)) / 100
        order.vExtraTax = extraTax
        vSum += extraTax
      }
    } else {
      vSum += (order.shippingData?.fee || 0) + (order.shippingData?.serviceFee || 0) + (order.serviceFee || 0) + (order.donation || 0) + (order.bagFee || 0)
    }

    if (order.serviceFee && useServiceFeeBeforeTax()) {
      const TPS_VAL = 5
      const TVQ_VAL = 9.975
      const sfTaxAmount = roundNumber((order.serviceFee * TPS_VAL) / 100, 2)
      const sfTaxAmount2 = roundNumber((order.serviceFee * TVQ_VAL) / 100, 2)
      vSum += sfTaxAmount + sfTaxAmount2
    }

    order.vSum = roundNumber(vSum, 2)
    order.vTotal = roundNumber(order.vSum + (order.tip || 0) + (order.shippingData?.tip || 0), 2)
  })

  //<editor-fold desc="payments">
  effect(() => {
    const payments = _.compact(order.payments)
    if (!order.vSum || order.vSum <= 0 || payments.length === 0) {
      order.tip = 0
      order.cashback = 0
      return
    }

    const cashPayment = _.find(payments, p => p.extraType === PaymentType.Cash)
    const cashlessPayments = _.filter(payments, p => p.extraType !== PaymentType.Cash)
    const cashlessValue = _.sumBy(cashlessPayments || [], 'value')
    const cashValue = cashPayment ? cashPayment.value : 0

    const tipInShipping = _.round(cashlessValue - order.vSum - (order.shippingData?.tip || 0), 2)
    let autoTip = cashlessValue > order.vSum && tipInShipping > 0 ? tipInShipping : 0
    if (order.manualTip !== undefined && order.manualTip !== 0) {
      order.tip = order.manualTip
    } else {
      order.tip = order.cashTip && cashValue > 0 ? order.cashTip : roundNumber(autoTip, orderConfig.sumPrecision)

      let change = roundNumber(cashValue + cashlessValue - order.vSum - order.tip - (order.shippingData?.tip || 0), orderConfig.sumPrecision)
      order.cashback = change > 0 ? change : 0
      order.lack = change < 0 ? -change : 0
    }
  })
  //</editor-fold>

  //<editor-fold desc="process commit">
  effect(() => {
    for (const commit of order.commits!) {
      if (!commit.processed) {
        commit._id = commit._id || uuid()
        if (!commit.date) commit.date = dayjs().unix()
        const { isSameItem, isSameItem2 } = itemCompareFactory()
        if (order.seatMode) {
          //todo: type depend
          //todo: filter
          if (
            commit.action !== CommitAction.MOVE &&
            commit.action !== CommitAction.MOVE_TO_ORDER &&
            commit.action !== CommitAction.ASSIGN_MOVE_ORDER
            // && commit.action !== CommitAction.SET_TSE_METHOD
          ) {
            for (let i = 0; i < order.seatMap!.length; i++) {
              if (
                commit.seat === i ||
                [
                  CommitAction.CLEAR_DISCOUNT_ORDER,
                  CommitAction.ADD_ORDER_DISCOUNT,
                  CommitAction.ADD_DISCOUNT_ORDER_ITEM,
                  CommitAction.CLEAR_DISCOUNT_ORDER_ITEM,
                  CommitAction.PRINT,
                  CommitAction.SPLIT_ITEM,
                  CommitAction.EDIT_ITEM,
                  CommitAction.SET_COURSE,
                  CommitAction.CHANGE_CASH_TIP,
                  CommitAction.SET_TIP,
                  CommitAction.SET_SERVICE_FEE,
                  CommitAction.ADD_PAYMENT_ALL_SEATS,
                  CommitAction.RECORD_SRM_TRANSACTIONS,
                ].includes(commit.action)
              ) {
                const _order = order.seatMap![i]
                if (commit.action === CommitAction.SPLIT_ITEM) {
                  const redundant = handleDouble(commit, _order, order as Order)
                  if (!redundant || redundant?.length === 0) {
                    log('no repeat item')
                  } else {
                    for (const r of redundant) {
                      const rIndex = _order?.commits?.findIndex(c => c._id === r)
                      _order.commits?.splice(rIndex!, 1)
                      const iIndex = _order?.items?.findIndex(i => i.commitRefs?.includes(r as string))
                      _order.items?.splice(iIndex, 1)
                    }
                  }
                } else if (commit.action === CommitAction.ADD_MODIFIER) {
                  const redundant = handleDouble(commit, _order, order as Order)
                  if (!redundant || redundant?.length === 0) {
                    log('no repeat modifier')
                  } else {
                    for (const r of redundant) {
                      const rIndex = _order?.commits?.findIndex(c => c._id === r)
                      _order.commits?.splice(rIndex!, 1)

                      const itemIndex = _order?.items?.findIndex(item => item.modifiers?.some(modifier => modifier._id === r))
                      const modifierIndex = itemIndex !== -1 ? _order?.items[itemIndex]?.modifiers?.findIndex(modifier => modifier._id === r) : -1
                      _order.items?.[itemIndex].modifiers?.splice(modifierIndex, 1)
                    }
                  }
                } else {
                  _order.commits!.push(JsonFn.clone(commit))
                }
              }
            }
          }
        }
        if (commit.action === CommitAction.ADD_PRODUCT) {
          //filter by seat
          if (!(order.hasOwnProperty('seat') && order.seat !== commit.seat)) {
            const item = createItem(
              {
                _id: commit._id,
                id: commit.productRef?.id,
                name: commit.productRef?.name,
                price: commit.price || commit.productRef?.price || 0,
                taxes: commit.productRef?.taxes || [],
                quantity: commit.quantity || 1,
                commitRefs: [commit._id],
                groupPrinter: commit.groupPrinter,
                groupPrinter2: commit.groupPrinter2,
                labelPrinter: commit?.labelPrinter,
                ...(commit.seat !== undefined && { seat: commit.seat }),
                course: 1,
                ...(commit.printed && { printed: true }),
                //for vouchers
                isVoucher: commit.productRef?.isVoucher,
                voucher: commit.productRef?.voucher,
                code: commit.productRef?.code,
                ...(commit.productRef?.ingredients && { ingredients: commit.productRef?.ingredients }),
                ...(commit.productRef?.taxComponents && { taxComponents: commit.productRef?.taxComponents }),
                // ...commit.productRef?.taxComponents2 && { taxComponents2: commit.productRef?.taxComponents2 },
                ...(commit.productRef?.categories && { categories: commit.productRef?.categories }),
                date: commit.date,
                tax2: 0,
                taxComponents2: [],
              },
              order as Order
            )

            const lastItem = order.items[order.items.length - 1]
            if (lastItem && !lastItem.printed && isSameItem(lastItem, item) && !lastItem.separate && generalSetting0()?.mergeSimilarItem && lastItem?.quantity !== 0) {
              lastItem.quantity += commit.quantity || 1
              lastItem.commitRefs!.push(commit._id)
            } else {
              order.items.push(item)
            }
            if (order.seatMode) {
              order.items?.forEach(i => (i.movedQuantity = i.quantity))
            }

            // order.items.splice(lastItemIndex, 0, item);
            // lastItemIndex = lastItemIndex + 1;
          }
        } else if (commit.action === CommitAction.SPLIT_ITEM) {
          if (commit.originalSeat != null && order.seatMode) {
            //handle if original order have seat - reduce quantity and remove item from exact seatMap
            const item = order.items.find(i => i.commitRefs && i.commitRefs.includes(commit.commitId));
            if (item && commit.originalQuantity) {
              item.quantity -= commit.originalQuantity;
            }
            const seatItemIndex = order?.seatMap?.[commit.originalSeat!].items.findIndex(i => i.commitRefs && i.commitRefs.includes(commit.commitId));
            if (seatItemIndex !== -1) {
              order?.seatMap?.[commit.originalSeat!].items.splice(seatItemIndex!, 1)
            }
          } else if (commit.originalSeat == null || order.hasOwnProperty('seat') && order.seat === commit.originalSeat) {
            //handle if original order doesn't have seat or seatOrder contains the original item
            const index = order.items.findIndex(i => i.commitRefs && i.commitRefs.includes(commit.commitId))

            if (index !== -1) {
              order.items.splice(index, 1)
            }
          }

          if (!(order.hasOwnProperty('seat') && order.seat !== commit.seat)) {
            const newItem = createItem(
              {
                _id: commit._id,
                id: commit.productRef?.id,
                name: commit.productRef?.name,
                price: commit.price || commit.productRef?.price || 0,
                taxes: commit.productRef?.taxes || [],
                quantity: commit.quantity || 1,
                commitRefs: [commit._id],
                // groupPrinter: commit.groupPrinter,
                // groupPrinter2: commit.groupPrinter2,
                ...(commit.seat !== undefined && { seat: commit.seat }),
                course: 1,
                ...(commit.printed && { printed: true }),
                isVoucher: commit.productRef?.isVoucher,
                code: commit.productRef?.code,
                ...(commit.productRef?.ingredients && { ingredients: commit.productRef?.ingredients }),
                ...(commit.productRef?.taxComponents && { taxComponents: commit.productRef?.taxComponents }),
                ...(commit.productRef?.categories && { categories: commit.productRef?.categories }),
                date: commit.date,
                splitId: commit._id,
                // printed: commit.printed,
                printedRound: commit.printedRound,
                tax2: 0,
                taxComponents2: [],
              },
              order as Order
            )
            _.assign(newItem, {originalInfo: {oldId: commit.oldId, oldPrice: commit.oldPrice, oldQuantity: commit.oldQuantity, srm_originalTransactionId: commit.srm_originalTransactionId }})
            order.items.push(newItem)

            if (order.seatMode) {
              order.items?.forEach(i => (i.movedQuantity = i.quantity))
            }
          }
        } else if (commit.action === CommitAction.CHANGE_QUANTITY) {
          const condition = (i: OrderItem) => (order.status === OrderStatus.IN_PROGRESS ? i.quantity > 0 : true)
          let item = order.items.find(i => (i.commitRefs!.includes(commit.commitId) || i._id === commit.commitId) && condition(i))

          if (item) {
            if (commit.quantityDelta < 0) {
              item.quantity += commit.quantityDelta as number

              if (commit.quantityDelta < 0 && item.printed) {
                const _item = _.assign({}, _.cloneDeep(item), { quantity: -commit.quantityDelta, printed: true })
                _item.printed = false
                order.cancellationItems!.push(createItem(_item, order as Order))
              }

              if (commit.quantityDelta < 0 && !item.printed && orderConfig.trackCancelBeforePrint) {
                const cloneItem = _.cloneDeep(item)
                const _item = _.assign({}, cloneItem, { quantity: -commit.quantityDelta, printed: false, date: dayjs(now()).unix() })
                order.directCancellationItems!.push(createItem(_item, order as Order))
              }
            } else {
              if (!item.printed || order.status !== OrderStatus.IN_PROGRESS) {
                item.quantity += commit.quantityDelta as number

                //remove cancelItem if increase quantity in refund
                if (order.status !== OrderStatus.IN_PROGRESS && order.cancellationItems && order.cancellationItems!.length > 0) {
                  const cancelItemIndex = order.cancellationItems!.findIndex(i => i.commitRefs!.includes(commit.commitId) || i._id === commit.commitId);
                  if (cancelItemIndex != null && cancelItemIndex !== -1) {
                    order.cancellationItems?.splice(cancelItemIndex, 1);
                  }
                }
              } else {
                const cloneItem = _.cloneDeep(item)
                const _item = _.assign({}, cloneItem, { quantity: commit.quantityDelta, printed: true })
                _item.printed = false
                delete _item.printedRound
                delete _item.lastQuantity
                _item.movedQuantity = 0
                const lastItem = order.items[order.items.length - 1]
                if (lastItem && !lastItem.printed && isSameItem2(lastItem, _item)) {
                  lastItem.quantity += commit.quantityDelta || 1
                } else {
                  //change _id and commit refs, push item in order
                  _item._id = uuid()
                  _item!.commitRefs = [_item._id]
                  if (_item?.modifiers?.length > 0) {
                    _item?.modifiers?.forEach(m => (m._id = uuid()))
                  }
                  order.items!.push(createItem(_item, order as Order))

                  //create sign to open printKitchen popup
                  const foundItemIndex = order.items.findIndex(i => i._id === _item._id)
                  if (foundItemIndex !== -1) {
                    _.assign(order.items[foundItemIndex], { change: true })
                  }
                }
              }
              if (order.seatMode) {
                order.items?.forEach(i => (i.movedQuantity = i.quantity))
              }
            }
            //todo: reduce all unnecessary commits
          }
        } else if (commit.action === CommitAction.CHANGE_TAKE_AWAY) {
          const item = order.items.find(i => i.commitRefs!.includes(commit.commitId))
          if (item) {
            // item.takeAway = commit.takeAway;
            item.course = 0
            //todo: reduce all unnecessary commits
          }
        } else if (commit.action === CommitAction.CHANGE_ORDER_TAKE_AWAY) {
          order.takeAway = commit.takeAway
          if (order?.seatMode) {
            order?.seatMap?.forEach(o => (o.takeAway = commit.takeAway))
          }
        } else if (commit.action === CommitAction.ADD_MODIFIER_TO_LAST_ITEM) {
          const lastItem = _.last(order.items)
          if (lastItem) {
            const modifier = createModifier(
              {
                id: commit.productRef?.id,
                commitRef: commit._id,
                name: commit.productRef?.name || '',
                price: commit.price || commit.productRef?.price || 0,
                quantity: commit.quantity,
              },
              lastItem,
              order
            )
            lastItem.modifiers.push(modifier)
          }
        } else if (commit.action === CommitAction.ADD_MODIFIER) {
          const item = _.find(order.items, i => i.commitRefs!.includes(commit.commitId))
          if (item) {
            const modifier = createModifier(
              {
                id: commit._id,
                name: commit.productRef?.name || '',
                price: commit.price || commit.productRef?.price || 0,
                quantity: commit.quantity,
              },
              item,
              order
            )
            item.modifiers.push(modifier)
          }
        } else if (commit.action === CommitAction.PRINT) {
          untracked(() => {
            if (getTotalItems(order as Order) === 0 && ((order.cancellationItems?.length ?? 0) > 0 || (order.directCancellationItems?.length ?? 0) > 0)) {
              order.status = OrderStatus.CANCELLED_BEFORE_PAID
            }
            //<editor-fold desc="PRINT HANDLER">
            order.lastPrintedRound = order.lastPrintedRound || 0
            order.lastPrintedRound++
            const lastPrintedRound = order.lastPrintedRound

            // lastPrintedRound++

            const items = [...order.items]
            items.forEach(i => (i.lastQuantity = 0))
            ;[...order.items, ...(order.cancellationItems || [])].forEach(i => {
              if (!i.printed) {
                i.printed = true
                i.printedRound = lastPrintedRound
                i.lastQuantity = i.quantity
              }
            })

            const result = _.reduce(
              items,
              (result: Array<OrderItem>, item) => {
                for (const _item of result) {
                  if (isSameItem2(_item, item) && !_item.separate && !item.separate) {
                    if (item.printedRound === lastPrintedRound) {
                      _item.lastQuantity = (_item.lastQuantity || 0) + item.quantity
                    }
                    _item.quantity += item.quantity
                    _item.commitRefs?.push(...item.commitRefs!)
                    _item.printedRound = lastPrintedRound
                    return result
                  }
                }
                result.push(item)
                return result
              },
              []
            )
            if (order.seatMode) {
              result.forEach(i => (i.movedQuantity = i.quantity))
            }
            order.items.splice(0, order.items.length, ...result)
            //todo: reduce payments
            // reducePayment(order);
            //</editor-fold>
          })
        } /*else if (commit.action === CommitAction.SEAT_SPLIT) {
          //todo: develop strip commits for better memory
          order.items = order.items.filter(i => i.seat = commit.seat)
        }*/ else if (commit.action === CommitAction.CHANGE_SEAT_QUANTITY) {
          //todo: filter commits
          const preMapLength = order.seatMap!.length
          for (let i = 0; i < commit.delta; i++) {
            const _order = createOrder({ table: order.table, users: order.users! })
            _order.seat = preMapLength + i
            _order.splitId = order._id
            _order._id = commit.ids[i]
            _order.takeAway = order.takeAway
            order.seatMap!.push(_order)
          }
        } else if (commit.action === CommitAction.CHANGE_SEAT_MODE) {
          //todo: cancellation
          order.seatMode = commit.seatMode
          if (commit.seatMode) {
            batch(() => {
              order.items.forEach(i => (i.movedQuantity = i.movedQuantity || 0))
            })
          }
        } else if (commit.action === CommitAction.MOVE) {
          //todo: move
          const seatOrder = order.seatMap![commit.seat]
          if (!seatOrder) return console.log('call')
          if (order.discount && order.discount !== 'NA') {
            seatOrder.discount = order.discount
          }
          // filter all commit base on commit-id, merge
          const addProductCommits = order
            .commits!.filter(c => '_id' in c && c._id === commit.commitId)
            .map(c => ({
              ...c,
              printed: commit.printed,
            }))

          const condition = (i: OrderItem) => (commit.quantity > 0 ? i.movedQuantity !== i.quantity : true)

          const item = order.items.find(i => i.commitRefs!.includes(commit.commitId) && condition(i))
          //find and set item seat
          if (item) item.seat = commit.seat
          const relateCommits = order.commits!.filter(c => {
            return 'commitId' in c && item?.commitRefs!.includes(c.commitId) && c.action !== CommitAction.MOVE && c.action !== CommitAction.SET_TSE_METHOD && c.action !== CommitAction.CHANGE_QUANTITY
          })
          const modifiers = item?.modifiers
          const relateModifierCommits = order.commits!.filter(c => {
            return modifiers?.find(m => m.commitRef === c._id)
          })
          const commits = [...addProductCommits, ...relateCommits, ...relateModifierCommits]
            .map(c => ({ ...c, seat: commit.seat, processed: false }))
            .filter(c => !seatOrder.commits!.find(_c => _c._id === c._id))
          // seatOrder.commits.push({action: CommitAction.ADD_PRODUCT});
          batch(() => {
            const movedQuantity = item!.movedQuantity || 0
            item!.movedQuantity = (item!.movedQuantity || 0) + commit.quantity
            // untrack(() => {
            // 	order.items = [...order.items];
            // })
            //check duplicate
            seatOrder.commits!.push(...commits)
            seatOrder.commits!.push({
              ..._.omit(commit, 'quantity'),
              resetQuantity: commits.length > 0,
              action: CommitAction.ASSIGN_SEAT,
              delta: commit.quantity,
            })

            if (item!.tseMethod === TseMethod.applyPart) {
              if ((movedQuantity || 0) === 0) {
                addTseMethod(seatOrder, item!, TseMethod.applyPart, commit.seat)
              } else {
                addTseMethod(seatOrder, item!, TseMethod.passthrough, commit.seat)
              }
            } else if (item!.tseMethod) {
              addTseMethod(seatOrder, item!, item!.tseMethod, commit.seat)
            }
          })
        } else if (commit.action === CommitAction.ASSIGN_SEAT && 'seat' in order) {
          const item = order.items.find(i => i.commitRefs!.includes(commit.commitId))
          if (item) {
            batch(() => {
              if (commit.resetQuantity) {
                item.quantity = 0
              }
              item.quantity = (item.quantity || 0) + commit.delta
            })
          }
        } else if (commit.action === CommitAction.ADD_PAYMENT) {
          //todo: use int for type to save memory
          if (!('seat' in commit && order.seatMode)) {
            batch(() => {
              order.payments.push({
                type: commit.type,
                extraType: commit.extraType,
                value: commit.value,
                commitRefs: [commit._id!],
                by: commit.by,
              })
            })
          }
        } else if (commit.action === CommitAction.MERGE_SEAT) {
          mergeSeat(order);
        } else if (commit.action === CommitAction.SET_ORDER_GROUP_BILLS) {
          order.isGroupBill = commit.value
        } else if (commit.action === CommitAction.CHANGE_PAYMENT) {
          if (!('seat' in commit && order.seatMode)) {
            const p = order.payments.find(p => p.type === commit.type)
            if (p) {
              p.value = commit.value
              p.commitRefs!.push(commit._id)
              p.by = commit.by
              p.extraType = commit.extraType
            }
            order.payments.splice(0, order.payments.length, ...order.payments)
          }
        } else if (commit.action === CommitAction.ADD_PAYMENT_ALL_SEATS) {
          if (!('seat' in commit && order.seatMode)) {
            const value = roundNumber((order.vSum ?? 0) + (order.tip ?? 0), 2)
            batch(() => {
              if (order.payments.length > 0) order.payments = []
              order.payments.push({
                type: commit.type,
                extraType: commit.extraType,
                value,
                commitRefs: [commit._id!],
                by: commit.by,
              })
            })
          }
        } else if (commit.action === CommitAction.CLEAR_PAYMENTS) {
          if (!('seat' in commit && order.seatMode)) {
            order.payments.splice(0, order.payments.length)
          }
        } else if (commit.action === CommitAction.PAY) {
          untracked(() => {
            if (order.hasOwnProperty('seat') && order.seat !== commit.seat) return
            if (commit.hasOwnProperty('seat') && order.seat !== commit.seat) return
            if (order.items.length === 0) {
              order.status = OrderStatus.CANCELLED
            } else if (order.seatMode && !order.isGroupBill) {
              order.status = OrderStatus.SPLIT
            } else {
              const debitPayment = order.payments.find(p => p.extraType === PaymentType.Debitor);
              if (debitPayment) {
                order.status = OrderStatus.DEBITOR
              } else {
                order.status = OrderStatus.PAID
              }
            }
            if (!order.hasOwnProperty('seat')) {
              order.immediatelyPay = !filterTseCommits(order.commits!).find(c => c.action === CommitAction.PRINT)
            }
          })
        } else if (commit.action === CommitAction.ADD_ORDER_DISCOUNT) {
          if (order.discount && order.discountLabel) {
            order.discount = 0
            order.discountLabel = ''
          }
          order.discountLabel = commit.label
          if (commit.type === 'percent') {
            console.log('percent discount: ', commit.value)
            order.discount = commit.value + '%'
            if (order.discountSeat?.length ?? 0 > 0) {
              order.discountSeat?.splice(0, order?.discountSeat?.length)
            }
          } else if (commit.type === 'amount') {
            if (commit.seat !== undefined) {
              if (!order.hasOwnProperty('seat') && order.seatMode) {
                if (!order.hasOwnProperty('seat') && order.seatMode) {
                  const index = order?.discountSeat?.findIndex(d => d.seat === commit.seat)
                  if (typeof index === 'number' && index !== -1) {
                    order.discountSeat?.splice(index, 1)
                  }
                  order.discountSeat?.push({ seat: commit.seat, discount: commit.value })
                  order.discount = order.discount === undefined || order.discount === '0' ? '-0' : '0'
                }
              }
            }
            if ((!order.seatMode && order.seat === undefined) || order.seat === commit.seat) {
              order.discount = commit.value
            }
          }
        } else if (commit.action === CommitAction.CREATE_MOVE_ORDER) {
          moveOrder = createOrder()
          moveOrder.isMoveOrder = true
        } else if (commit.action === CommitAction.MOVE_TO_ORDER) {
          //todo: move
          const seatOrder = moveOrder
          if (!seatOrder) return console.log('call')
          // filter all commit base on commit-id, merge
          const addProductCommits = order
            .commits!.filter(c => '_id' in c && c._id === commit.commitId)
            .map(c => ({
              ...c,
              printed: commit.printed,
            }))
          const item = order.items.find(i => i.commitRefs!.includes(commit.commitId))
          const relateCommits = order.commits!.filter(c => {
            return 'commitId' in c && item?.commitRefs!.includes(c.commitId) && c.action !== CommitAction.MOVE_TO_ORDER && c.action !== CommitAction.CHANGE_QUANTITY && c.action !== CommitAction.MOVE
          })
          const modifiers = item?.modifiers
          const relateModifierCommits = order.commits!.filter(c => {
            return modifiers?.find(m => m.commitRef === c._id)
          })
          const commits = [...addProductCommits, ...relateCommits, ...relateModifierCommits].map(c => ({ ...c, processed: false })).filter(c => !seatOrder.commits!.find(_c => _c._id === c._id))
          batch(() => {
            item!.movedQuantity = (item!.movedQuantity || 0) + commit.quantity
            seatOrder.commits!.push(...commits)
            seatOrder.commits!.push({
              ..._.omit(commit, 'quantity'),
              resetQuantity: commits.length > 0,
              action: CommitAction.ASSIGN_MOVE_ORDER,
              delta: commit.quantity,
            })
            console.log('finish')
          })
        } else if (commit.action === CommitAction.ASSIGN_MOVE_ORDER) {
          // if (order?.seatMode) {
          //   mergeSeat(order)
          // }
          const item = order.items.find(i => i.commitRefs!.includes(commit.commitId))
          if (item) {
            batch(() => {
              if (commit.resetQuantity) {
                item.quantity = 0
              }
              item.quantity = (item.quantity || 0) + commit.delta
            })
          }
        } else if (commit.action === CommitAction.SET_TSE_METHOD) {
          const item = order.items.find(i => i.commitRefs!.includes(commit.commitId) || i._id === commit.commitId)
          if (item) {
            if (item.tseMethod === TseMethod.applyPart && commit.tseMethod === TseMethod.passthrough) {
            } else {
              item.tseMethod = commit.tseMethod
            }
            if (commit.date) item.date = commit.date
          }
        } else if (commit.action === CommitAction.CLEAR_DISCOUNT_ORDER) {
          if (commit.seat !== undefined) {
            if (!order.hasOwnProperty('seat') && order.seatMode) {
              const index = order.discountSeat?.findIndex(d => d.seat === commit.seat)
              if (typeof index === 'number' && index !== -1) {
                order.discountSeat?.splice(index, 1)
                if (order.discountSeat?.length === 0) {
                  order.discount = '0'
                  order.discountLabel = ''
                } else {
                  order.discount = `0`
                }
              }
            } else if (order.hasOwnProperty('seat') && commit.seat === order.seat) {
              order.discount = '0'
              order.discountLabel = ''
            }
          } else {
            order.discount = 0
            order.discountLabel = ''
            for (const item of order.items) {
              item.discount = 0
            }
          }
        } else if (commit.action === CommitAction.COMPLETE_MOVE_ORDER) {
          if (!commit?.isGroup) {
            for (const item of order.items) {
              item.quantity -= item.movedQuantity || 0
              item.movedQuantity = 0
              order.cancellationItems?.push({ ...item, quantity: item.movedQuantity || 0, movedQuantity: 0 })
            }
          }
          if (commit.isGroup) {
            for (const item of order.items) {
              order.cancellationItems?.push({ ...item, movedQuantity: 0})
            }
            order.items.forEach(i => (i.quantity = 0));
            order.items.forEach(i => (i.movedQuantity = 0));
          }
        } else if (commit.action === CommitAction.SET_COURSE) {
          const item = order.items.find(i => i.commitRefs!.includes(commit.commitId))
          if (item) {
            item.course = commit.course
          }
        } else if (commit.action === CommitAction.ADD_DISCOUNT_ORDER_ITEM) {
          const item = order.items.find(i => i.commitRefs!.includes(commit.commitId))
          if (item) {
            if (item.discountLabel && item.discount) {
              item.discountLabel = ''
              item.discount = 0
            }
            item.discountLabel = commit.label
            if (commit.type === 'percent') {
              item.discount = commit.value + '%'
            } else if (commit.type === 'amount') {
              item.discount = commit.value
            }
          }
        } else if (commit.action === CommitAction.CLEAR_DISCOUNT_ORDER_ITEM) {
          const item = order.items.find(i => i.commitRefs!.includes(commit.commitId))
          if (item) {
            item.discount = 0
            item.discountLabel = ''
          }
        } else if (commit.action === CommitAction.SET_TIP) {
          // const percent = +(commit.value.replace('%', ''));
          const fixedValue = roundNumber((order.vItemTotal! * commit.value) / 100, 2)
          order.tip = fixedValue
        } else if (commit.action === CommitAction.CHANGE_CASH_TIP) {
          if (!(order.hasOwnProperty('seat') && order.seat !== commit.seat)) {
            order.cashTip = roundNumber(commit.value, 2)
          }
        } else if (commit.action === CommitAction.SET_SERVICE_FEE) {
          if (!(order.hasOwnProperty('seat') && order.seat !== commit.seat)) {
            const fixedValue = useServiceFeeBeforeTax() ? roundNumber(((order.getNet?.() ?? 0) * commit.value) / 100, 2) : roundNumber((order.vItemTotal! * commit.value) / 100, 2)

            order.serviceFee = fixedValue
          }
          // const percent = +(commit.value.replace('%', ''));
        } else if (commit.action === CommitAction.SET_ORDER_TYPE) {
          order.type = commit.orderType
        } else if (commit.action === CommitAction.SET_PROVIDER_TYPE) {
          order.provider = commit.provider
        } else if (commit.action === CommitAction.SET_STATUS) {
          if (order.hasOwnProperty('seat') && order.seat === commit.seat) {
            order.status = commit.status
          }
        } else if (commit.action === CommitAction.CANCEL_BEFORE_PRINT) {
          order.cancellationItems = order.items
          order.items = []
          order.status = OrderStatus.CANCELLED_BEFORE_PAID
        } else if (commit.action === CommitAction.EDIT_ITEM) {
          const item = order.items.find(i => i.commitRefs!.includes(commit.commitId))
          if (item) {
            item.name = commit.name
            item.price = commit.price
          }
        } else if (commit.action === CommitAction.ADD_TICKET_NUMBER) {
          order.ticketNumber = commit.ticketNumber
        } else if (commit.action === CommitAction.EQUALLY_SPLIT) {
          const items = order.items.filter(i => i.quantity !== 0)
          let ids = []
          let pushItems = []

          for (const item of items) {
            let _item;
            if (commit.option === EquallySplit.CHANGE_PRICE) {
              _item = _.assign({}, _.cloneDeep(item), { quantity: 1 })
              _item.price = (item.price * item.quantity) / commit.parts
            } else {
              _item = _.assign({}, _.cloneDeep(item), { price: item.price })
              _item.quantity = _.round(item.quantity / commit.parts, 2);
            }
            _item.splitId = item._id
            _item.originalInfo = {oldId: item._id, oldQuantity: item.quantity, oldPrice: item.price}

            const modifiers = _item.modifiers

            if (commit.option === EquallySplit.CHANGE_PRICE) {
              if (modifiers.length > 0) {
                for (const modifier of modifiers) {
                  modifier.price = (modifier.price * item.quantity) / commit.parts
                }
              }
            }

            ids.push(item._id)

            for (let i = 0; i < commit.parts; i++) {
              _item.seat = i
              _item._id = uuid()
              const _modifiers = _item.modifiers.map(m => ({ ...m, _id: uuid() }))

              const seatOrder = order.seatMap[i]

              _item.modifiers.splice(0, _item.modifiers.length, ..._modifiers)
              if (i === 0) {
                order.seatMap[i]?.items.push(createItem(_item, seatOrder as Order))
              } else {
                order.seatMap[i]?.items.push(createItem({ ..._item, _id: uuid() }, seatOrder as Order))
              }

              order.seatMap?.[i]?.commits?.push({
                action: CommitAction.ADD_SPLIT_ITEM,
                commitId: item._id ?? '',
                seat: i,
                productRef: {
                  ..._.pick(_item, ['name', 'id', 'price', 'taxes', 'category', 'ingredients', 'taxComponents', 'taxComponents2', 'isVoucher', 'voucher', 'code']),
                  name: _item.name ?? '',
                },
                ..._.pick(_item, ['_id', 'quantity', 'groupPrinter', 'groupPrinter2', 'labelPrinter', 'printed']),
                _id: _item._id ?? '',
                option: commit.option
              } as ItemCommitAddSplitItem)

              if (_item?.modifiers?.length > 0) {
                for (const modifier of _item?.modifiers) {
                  order.seatMap?.[i]?.commits?.push({
                    action: CommitAction.ADD_SPLIT_MODIFIER,
                    commitId: _item._id,
                    seat: i,
                    ref: _item._id,
                    _id: modifier._id,
                    productRef: { ..._.pick(modifier, ['name', 'price']) },
                    ..._.pick(modifier, ['quantity']),
                    option: commit.option
                  } as ItemCommitAddSplitModifier)
                }
              }
            }
          }

          for (const id of ids) {
            const index = order.items.findIndex(i => i._id === id)
            if (index !== -1 && index !== undefined) {
              order.items.splice(index, 1)
            }
          }
          if (order.seatMode) {
            let loadItems=[];
            pushItems = (order.seatMap || []).flatMap(seatOrder => seatOrder.items)
            for (const seatItem of pushItems) {
              const clone = _.cloneDeep(seatItem);
              loadItems.push(createItem(clone, order));
            }
            loadItems.forEach(i => (i.movedQuantity = i.quantity))
            order.items.push(...loadItems)
          }
        } else if (commit.action === CommitAction.CLEAR_DISCOUNT_COMMIT_BEFORE_MOVE) {
          const discountRelate = [
            CommitAction.ADD_ORDER_DISCOUNT,
            CommitAction.CLEAR_DISCOUNT_ORDER,
            CommitAction.ADD_DISCOUNT_ORDER_ITEM,
            CommitAction.CLEAR_DISCOUNT_ORDER_ITEM,
            CommitAction.CLEAR_DISCOUNT_COMMIT_BEFORE_MOVE,
          ]
          const discountUnRelateCommits = order?.commits?.filter(c => !discountRelate.includes(c.action))
          order.commits = [...discountUnRelateCommits]
        } else if (commit.action === CommitAction.GROUP_TABLE) {

          const seatOrder = moveOrder;
          if (!seatOrder) return console.log('call')

          let items: OrderItem[] = [];
          if (commit?.seatMode && !order.hasOwnProperty('seat')) {
            items = (order.seatMap || [])?.flatMap(seat => seat.items.filter(i => i.quantity !== 0));
          } else if (!commit?.seatMode) {
            items = order.items.filter(i => i.quantity !== 0);
          }

          //create add product, modifier and set course based on current items
          let addProductCommits: ItemCommitAddProduct[] = [];
          let modifierCommits: ItemCommitAddModifier[] = [];
          let setCourseCommits: ItemCommitChangeCourse[] = [];

          for (const item of items) {
            const addProductCommit = {
              action: CommitAction.ADD_PRODUCT,
              ..._.pick(item, ['_id', 'quantity', 'groupPrinter', 'groupPrinter2', 'labelPrinter', 'printed']),
              productRef: {
                ..._.pick(item, ['name', 'id', 'price', 'taxes', 'category', 'ingredients', 'taxComponents', 'taxComponents2', 'isVoucher', 'voucher', 'code']),
                name: item.name ?? '',
              },
              ...(item.seat !== undefined && { seat: item.seat }),
            }
            addProductCommits.push(addProductCommit);
            if (item.modifiers?.length > 0) {
              for (const modifier of item.modifiers) {
                const modifierCommit = {
                  action: CommitAction.ADD_MODIFIER,
                  commitId: item._id,
                  ...(item.seat !== undefined && { seat: item.seat }),
                  ref: item._id,
                  _id: modifier._id,
                  productRef: { ..._.pick(modifier, ['name', 'price']) },
                  ..._.pick(modifier, ['quantity']),
                }
                modifierCommits.push(modifierCommit)
              }
            }
            if (item.course !== 1) {
              setCourseCommits.push({ action: CommitAction.SET_COURSE, commitId: item?._id, course: item.course })
            }
          }
          const commits = [...addProductCommits, ...modifierCommits, ...setCourseCommits].map(c => ({ ...c, processed: false })) as ItemCommit[];
          batch(() => {
            //push commit to order.getMoveOrder
            if (commits?.length > 0) {
              seatOrder.commits!.push(...commits)
            }
          })
          console.log('finish')

        } else if (commit.action === CommitAction.HANDLE_ZERO_SEAT) {
          order.items.forEach(i => (i.seat = 0));
          _.assign(order.seatMap?.[0], { items: _.cloneDeep(order?.items) })
          order.items.forEach(i => (i.movedQuantity = i.quantity))
        } else if (commit.action === CommitAction.RECORD_SRM_TRANSACTIONS) {
          untracked(() => {
            if (order.seat !== commit.seat) return
            order.srm_transactions = order.srm_transactions || []
            order.srm_transactions.push(commit.data)
          })
        } else if (commit.action === CommitAction.ADD_DIRECT_CANCELLATION_ITEM) {
          const item = createItem(commit.data, order);
          order.directCancellationItems?.push(item);
        }
        commit.processed = true
      }
    }
  })

  effectOn([() => order.seatMap!.map(o => o.payable), () => order.commits!.length], () => {
    if (!order.table) return true
    if (_.last(filterCommits(order.commits!))?.action !== CommitAction.PRINT) {
      order.payable = false
    } /*else if (order.seatMode) {
      if (order.seatMap!.find(o => !o.payable)) {
        order.payable = false;
      } else {
        order.payable = true;
      }
    }*/ else {
      //todo: takeAway, discount -> use snapshot
      if ((getNewItemsSumQuantity() === 0 && getNewCancellationItemsSumQuantity() === 0) || _.last(order.commits)?.action === CommitAction.PRINT) {
        order.payable = true
      } else {
        order.payable = false
      }
    }
    const _commits = order!.commits?.filter(commit => commit.action !== (CommitAction.ADD_ORDER_DISCOUNT || CommitAction.ADD_DISCOUNT_ORDER_ITEM || CommitAction.PAY))
    if (_.last(_commits)?.action === CommitAction.PRINT) {
      order.payable = true
    }

    function filterCommits(commits: Array<ItemCommit>) {
      return commits.filter(
        commit =>
          ![
            CommitAction.SET_TSE_METHOD,
            CommitAction.PAY,
            CommitAction.CHANGE_PAYMENT,
            CommitAction.CLEAR_PAYMENTS,
            CommitAction.ADD_PAYMENT,
            CommitAction.MOVE,
            CommitAction.CHANGE_SEAT_QUANTITY,
            CommitAction.CHANGE_SEAT_MODE,
            CommitAction.ASSIGN_SEAT,
            CommitAction.CHANGE_CASH_TIP,
            CommitAction.SET_ORDER_GROUP_BILLS,
            CommitAction.ADD_TICKET_NUMBER
          ].includes(commit.action!)
      )
    }
  })

  //</editor-fold>

  function getNewItemsSumQuantity(): number {
    return _.sumBy(order.items, i => {
      if (i.printed) return 0
      return i.quantity
    })
  }

  function getNewCancellationItemsSumQuantity(): number {
    return _.sumBy(order.cancellationItems, i => {
      if (i.printed) return 0
      return i.quantity
    })
  }

  order.getRecent = (printed: boolean = false, lastPrintedRound: number = 0) => {
    if (printed) {
      if (order.seatMode) {
        return (order.seatMap ?? []).flatMap(seatOrder => getLastRecentItems(seatOrder.items, lastPrintedRound, order as Order))
      } else {
        return getLastRecentItems(order.items, lastPrintedRound, order as Order)
      }
    }
    return order.items.filter(i => !i.printed && i.quantity > 0)
  }

  order.getCancellationRecent = (printed: boolean = false, lastPrintedRound: number = 0) => {
    if (printed) {
      return getLastRecentItems(order.cancellationItems!, lastPrintedRound, order as Order)
    }
    return order.cancellationItems!.filter(i => i.quantity > 0)
  }

  order.getNet = (() => {
    //mergeVTaxGroup
    if (order.taxTotal) {
      return order.vSubTotal
    }
    if (order.items.length > 0) {
      const result = _.values<Tax | undefined>(order.vTaxSum).reduce(mergeVTaxGroup, { tax: 0, net: 0, gross: 0 })
      return result?.net
    }
    return 0
  }) as () => number

  order.getTax = (() => {
    //mergeVTaxGroup
    if (order.taxTotal) {
      return order.taxTotal
    }
    if (order.items.length > 0) {
      const result = _.values<Tax | undefined>(order.vTaxSum).reduce(mergeVTaxGroup, { tax: 0, net: 0, gross: 0 })
      return result?.tax
    }
    return 0
  }) as () => number

  return order as Order
}

export const cloneOrder = (order: Order) => {
  //ideas -> commits like before
  //commit -> pick items
  const _order = stripOrder(order)
  const order0 = createOrder(_order)
  return order0
}

//next problem: payment, discount per seat
// export const createSplitOrder = (order: Order, seat: string) => {
//   const _order = cloneOrder(order);
//   _order.commits.push({action: CommitAction.SEAT_SPLIT, seat})
//   return _order;
// }

export const resetOrder: (order: Order | OrderStrip) => OrderStrip = order => {
  const _order: OrderStrip = clone(order, false)
  delete _order.items
  delete _order.cancellationItems
  delete _order.directCancellationItems
  delete _order.lastPrintedRound
  // @ts-ignore
  delete _order.doc
  for (const commit of _order.commits || []) {
    // if (commit.action !== CommitAction.ADD_DIRECT_CANCELLATION_ITEM) {
      delete commit.processed
    // }
    // if ('productRef' in commit) delete commit.productRef;
  }
  delete _order.seatMode
  delete _order.seatMap
  return _order
}

//todo: sent prop
export const stripOrder: (order: Order) => OrderStrip = order => {
  const _order: OrderStrip = clone(order, false)
  // delete _order.items;
  // delete _order.cancellationItems;
  // delete _order.vSum;
  // delete _order.vDate;
  // delete _order.vDiscount;
  // delete _order.vTaxSum;
  delete _order.getRecent
  delete _order.getCancellationRecent
  delete _order.getNet
  delete _order.getTax
  delete _order.getMoveOrder
  delete _order.isMoveOrder
  // delete _order.payable;
  // delete _order.seatMap;
  // delete _order.selectedSeat;
  // delete _order.lack;
  // delete _order.tip;
  // delete _order.cashback;
  //todo: delete productRef
  // @ts-ignore
  delete _order.doc
  for (const commit of _order.commits!) {
    // delete commit.processed;
    // if ('productRef' in commit) delete commit.productRef;
  }

  for (const scopeOrder of _order.seatMap || []) {
    delete scopeOrder.getRecent
    delete scopeOrder.getCancellationRecent
    delete scopeOrder.getNet
    delete scopeOrder.getTax
    delete scopeOrder.getMoveOrder
    delete scopeOrder.isMoveOrder
  }
  // delete _order.seatMap;
  delete _order.srm_transactions

  return _order
}

export const stripPaidOrder: (order: Order) => OrderStrip = order => {
  const _order: OrderStrip = clone(order, false)
  delete _order.commits
  // delete _order.items;
  // delete _order.cancellationItems;
  // delete _order.vSum;
  // delete _order.vDate;
  // delete _order.vDiscount;
  // delete _order.vTaxSum;
  delete _order.getRecent
  delete _order.getCancellationRecent
  delete _order.getNet
  delete _order.getTax
  delete _order.getMoveOrder
  // @ts-ignore
  delete _order.doc
  for (const item of _order.items || []) {
    delete item.commitRefs
    // delete item.groupPrinter
    // delete item.groupPrinter2
    // delete item.course
    delete item.movedQuantity
    // delete item.printed
  }

  //strip orderSeats
  for (const seatOrder of _order.seatMap || []) {
    delete seatOrder.getRecent
    delete seatOrder.getCancellationRecent
    delete seatOrder.getNet
    delete seatOrder.getTax
    delete seatOrder.getMoveOrder
  }
  // delete _order.payable;
  // delete _order.seatMap;
  // delete _order.selectedSeat;
  // delete _order.lack;
  // delete _order.tip;
  // delete _order.cashback;
  //todo: delete productRef
  // for (const commit of _order.commits) {
  // 	// delete commit.processed;
  // 	// if ('productRef' in commit) delete commit.productRef;
  // }
  return _order
}

export const getPaymentTotal = (order: Order): number => {
  return _.sumBy(order.payments, p => p.value)
}

export function reducePayment(order: Order) {
  for (const payment of order.payments) {
    if (payment.commitRefs!.length > 1) {
      batch(() => {
        const c = _.find(order.commits, { _id: payment.commitRefs![0] }) as ItemCommitAddPayment
        if (c) {
          const cLast = _.find(order.commits, { _id: _.last(payment.commitRefs) }) as ItemCommitChangePayment
          c.value = cLast.value
        }
        const [first, ...refs] = payment.commitRefs!
        _.remove(order.commits!, c => refs.includes(c._id!))
      })
    }
  }
}

export function unpackOrders(orders: Array<OrderDocument>): Order[] {
  return orders.map(o => createOrder(o))
}

export function getMaxCourse(items: OrderItem[]): number {
  const maxCourse = (_.maxBy(items, i => (i.course ? +i.course : 0)) || { course: 0 }).course!
  return maxCourse
}

export function getTotalItems(order: Order) {
  return _.sumBy(order?.items, i => i.quantity)
}

export function getItemNet(item: OrderItem) {
  return _.values(item?.vTaxSum).reduce(mergeVTaxGroup, { tax: 0, net: 0, gross: 0 })?.net
}

export function getItemTax(item: OrderItem) {
  return _.values(item?.vTaxSum).reduce(mergeVTaxGroup, { tax: 0, net: 0, gross: 0 })?.tax
}
