import dayjs from 'dayjs'
import _ from 'lodash'
import type { RxCollection, RxDatabase, RxJsonSchema } from 'rxdb'
import { createCollection } from '@/data/utils/migration.ts'
import type { TOrder } from '@/pos/OrderType.ts'
import type { Order } from "@/data/Order.ts";
import uuid from "time-uuid";
import { getDeviceId } from "@/shared/getDeviceId.ts";
import { loginUser } from "@/data/UserSignal.ts";
import { getUrlParam } from "@/shared/utils2.ts";

export const USER_ACTION_HISTORY_COLLECTION = 'user_action_history'
export const VERSION = 8

export enum UserAction {
  ADD_ITEM = 'addItem',
  DELETE_ITEM = 'deleteItem',
  CANCEL_ADD_ITEM = 'cancelAddItem',
  CANCEL_DELETE_ITEM = 'cancelDeleteItem',
  INCREASE_QUANTITY = 'increaseQuantity',
  DECREASE_QUANTITY = 'decreaseQuantity',
  ADD_MODIFIER = 'addModifier',
  CHANGE_DISCOUNT_ORDER = 'changeDiscountOrder',
  CLEAR_DISCOUNT_ORDER = 'clearDiscountOrder',
  CHANGE_DISCOUNT_ITEM = 'changeDiscountItem',
  CANCEL_CHANGE_DISCOUNT_ORDER = 'cancelChangeDiscountOrder',
  CANCEL_CHANGE_DISCOUNT_ITEM = 'cancelChangeDiscountItem',
  CHANGE_ITEM_INFO = 'changeItemInfo',
  CANCEL_CHANGE_ITEM_INFO = 'cancelChangeItemInfo',
  CHANGE_PAYMENT = 'changePayment',
  CHANGE_TIP = 'changeTip',
  CHANGE_SERVICE_FEE = 'changeServiceFee',
  CANCEL_PAYMENT = 'cancelPayment',
  CANCEL_TIP = 'cancelTip',
  CANCEL_SERVICE_FEE = 'cancelServiceFee',
  REFUND_ITEM = 'refundItem',
  REFUND_AMOUNT = 'refundAmount',
  CANCEL_REFUND = 'cancelRefund',
  CHANGE_SEAT_MODE = 'changeSeatMode',
  CHANGE_SEAT_QUANTITY = 'changeSeatQuantity',
  MOVE_TO_SEAT = 'moveToSeat',
  CANCEL_MOVE_TO_SEAT = 'cancelMoveToSeat',
  SPLIT_ITEM = 'splitItem',
  CANCEL_SPLIT_ITEM = 'cancelSplitItem',
  CANCEL_CHANGE_SEAT_MODE = 'cancelChangeSeatMode',
  TOGGLE_MULTI_PAYMENT = 'toggleMultiPayment'
}

export interface UserActionHistory {
  _id: string;
  orderId: string;
  date: number;
  deviceId: string;
  userName?: string;
  transNo?: string;
  kind: UserAction;
  data?: UserActionData;
}

export interface UserActionData {
  seat?: number;
  productRef?: {
    productId?: string;
    oldPrice?: number;
    oldName?: string;
    price?: number;
    name?: string;
    modifierName?: string;
    modifierPrice?: number;
  }
  //productRef used
  quantity?: number;
  oldQuantity?: number;
  parts?: number;
  valueFrom?: number | string;
  valueTo?: number | string;
  paymentName?: string;
  oldPayment?: string;
  seatMode?: 'normal' | 'split';
  paymentMode?: 'normal' | 'multi';
  //amount used for CHANGE_PAYMENT, CHANGE_TIP, CHANGE_SERVICE_FEE, DISCOUNT
}


const userActionHistorySchema: RxJsonSchema<UserActionHistory> = {
  title: 'userActionHistory schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 100 },
    orderId: { type: 'string', maxLength: 100 },
    date: { type: 'number', multipleOf: 1, minimum: 0, maximum: 10 ** 10 },
    deviceId: { type: 'string', maxLength: 24 },
    userName: { type: 'string', maxLength: 24 },
    transNo: { type: 'string', maxLength: 48 },
    kind: { type: 'number' },
    data: {
      type: 'object',
      properties: {
        seat: { type: 'number' },
        productRef: {
          type: 'object',
          properties: {
            oldPrice: { type: 'number' },
            oldName: { type: 'string' },
            price: { type: 'number' },
            name: { type: 'string' },
            modifierName: { type: 'string' },
            modifierPrice: { type: 'number' }
          }
        },
        quantity: { type: 'number' },
        valueFrom: { type: 'number' },
        valueTo: { type: 'number' },
        parts: { type: 'number' },
        paymentName: { type: 'string' },
        oldPayment: { type: 'string' },
        seatMode: { type: 'string' },
        paymentMode: { type: 'string' }
      },
    },
  },
  indexes: ["orderId"],
  required: ['orderId', 'date', 'kind'],
} as const

export const UserActionHistory: RxCollection<UserActionHistory> = {} as RxCollection<UserActionHistory>

export const createUserActionHistoryCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: UserActionHistory,
    collectionName: USER_ACTION_HISTORY_COLLECTION,
    version: VERSION,
    schema: userActionHistorySchema,
  });
}

let userActionArr: UserActionHistory[] = []

/** Save User actions. Will do nothing if there is no commit */
export async function recordUserActionHistory(order: TOrder, action: UserAction, data?: UserActionData, needPush = true): Promise<void> {
  const country = getUrlParam('cn') || import.meta.env.VITE_COUNTRY
  if (country !== 'ca') return
  const { _id } = _.cloneDeep(order);
  const hasProductRef = data?.productRef && Object.keys(data.productRef).length > 0 || false;
  const record: UserActionHistory = {
    _id: uuid(),
    orderId: _id,
    date: dayjs().unix(),
    deviceId: getDeviceId(),
    userName: loginUser()?.name,
    kind: action,
    data: {
      ...data?.seat != null && { seat: data.seat },
      ...hasProductRef && {
        productRef: {
          ...data?.productRef?.price != null && { price: data?.productRef?.price },
          ...data?.productRef?.name && { name: data?.productRef?.name },
          ...data?.productRef?.oldPrice != null && { oldPrice: data?.productRef?.oldPrice },
          ...data?.productRef?.oldName != null && { oldName: data?.productRef?.oldName },
          ...data?.productRef?.productId != null && { productId: data?.productRef?.productId },
          ...data?.productRef?.modifierName != null && { modifierName: data?.productRef?.modifierName },
          ...data?.productRef?.modifierPrice != null && { modifierPrice: data?.productRef?.modifierPrice }
        }
      },
      ...data?.quantity != null && { quantity: data?.quantity },
      ...data?.oldQuantity != null && { oldQuantity: data?.oldQuantity },
      ...data?.valueFrom != null && { valueFrom: data?.valueFrom },
      ...data?.valueTo != null && { valueTo: data?.valueTo },
      ...data?.paymentName != null && { paymentName: data?.paymentName },
      ...data?.oldPayment != null && { oldPayment: data?.oldPayment },
      ...data?.seatMode != null && { seatMode: data?.seatMode },
      ...data?.paymentMode != null && { paymentMode: data?.paymentMode },
      ...data?.parts != null && { parts: data?.parts },
    }
  }
  if (needPush) userActionArr.push(record)

  await UserActionHistory.upsert(record)
  return
}

export const clearTempUserActions = () => {
  userActionArr = []
}

export const revertUserActions = (order: Order, snapshot?: Order) => {
  if (!userActionArr || !snapshot) return
  const recentItems = order!.getRecent?.()
  const cancellationItems = order!.getCancellationRecent?.()
  if (recentItems) {
    for (const recentItem of recentItems) {
      if (recentItem.quantity === 0) continue
      recordUserActionHistory(order!, UserAction.CANCEL_ADD_ITEM, {
        productRef: {
          productId: recentItem._id,
          name: recentItem.name
        },
        quantity: recentItem.quantity
      }).then()
    }
  }

  if (cancellationItems) {
    for (const cancelItem of cancellationItems) {
      if (cancelItem.quantity === 0) continue
      recordUserActionHistory(order!, UserAction.CANCEL_DELETE_ITEM, {
        productRef: {
          productId: cancelItem._id,
          name: cancelItem.name
        },
        quantity: cancelItem.quantity
      }).then()
    }
  }

  //cancel split mode
  if (order.seatMode !== snapshot.seatMode) {
    recordUserActionHistory(order!, UserAction.CANCEL_CHANGE_SEAT_MODE, {}).then()
  }

  const userActionsUnique = Object.values(
    userActionArr
      .filter(item => item.kind === UserAction.CHANGE_ITEM_INFO || item.kind === UserAction.SPLIT_ITEM)
      .reduce((acc: any, item) => {
        const productId = item?.data?.productRef?.productId!;
        if (!acc[productId] || item.date > acc[productId].date) {
          acc[productId] = item;
        }
        return acc;
      }, {})
  ) as UserActionHistory[];

  for (const userAction of userActionsUnique) {
    if (userAction.kind === UserAction.CHANGE_ITEM_INFO && userAction?.data?.productRef?.productId) {
      const originItem = snapshot.items.find(i => i._id === userAction.data!.productRef!.productId)
      if (originItem) {
        recordUserActionHistory(order!, UserAction.CANCEL_CHANGE_ITEM_INFO, {
          productRef: {
            productId: originItem._id,
            name: originItem.name,
            oldName: userAction.data.productRef.name,
            price: originItem.price,
            oldPrice: userAction.data.productRef.price
          },
        }, false).then()
      }
    }
    if (userAction.kind === UserAction.SPLIT_ITEM) {
      const originItem = snapshot.items.find(i => i._id === userAction.data!.productRef!.productId)
      if (originItem) {
        recordUserActionHistory(order!, UserAction.CANCEL_SPLIT_ITEM, {
          productRef: {
            name: originItem.name,
          },
        }, false).then()
      }
    }
  }
  if (order.discount !== snapshot.discount) {
    recordUserActionHistory(order!, UserAction.CANCEL_CHANGE_DISCOUNT_ORDER, {
      valueFrom: order.discount,
      valueTo: snapshot.discount || 0,
    }, false).then()
  } else {
    for (const itemSnapshot of snapshot.items) {
      const foundItem = order.items.find(i => i._id === itemSnapshot._id)
      if (foundItem && itemSnapshot.discount !== foundItem?.discount) {
        recordUserActionHistory(order!, UserAction.CANCEL_CHANGE_DISCOUNT_ITEM, {
          valueFrom: foundItem.discount,
          valueTo: itemSnapshot.discount || 0,
          productRef: {
            productId: itemSnapshot._id,
            name: itemSnapshot.name,
            oldPrice: foundItem.price,
            price: itemSnapshot.vPrice
          },
        }, false).then()
      }
    }
  }

  if (order.seatMode) {
    for (const seatOrder of order.seatMap!) {
      if (seatOrder.payments?.length > 0) {
        recordUserActionHistory(seatOrder!, UserAction.CANCEL_PAYMENT).then()
      }

      if (seatOrder.tip !== snapshot.tip) {
        recordUserActionHistory(seatOrder!, UserAction.CANCEL_TIP, {
          valueFrom: seatOrder.tip
        }).then()
      }

      if (seatOrder.serviceFee !== snapshot.serviceFee) {
        recordUserActionHistory(seatOrder!, UserAction.CANCEL_SERVICE_FEE, {
          valueFrom: seatOrder.serviceFee
        }).then()
      }
    }
  } else {
    if (order.payments?.length > 0) {
      recordUserActionHistory(order!, UserAction.CANCEL_PAYMENT).then()
    }

    if (order.tip !== snapshot.tip) {
      recordUserActionHistory(order!, UserAction.CANCEL_TIP, {
        valueFrom: order.tip
      }).then()
    }

    if (order.serviceFee !== snapshot.serviceFee) {
      recordUserActionHistory(order!, UserAction.CANCEL_SERVICE_FEE, {
        valueFrom: order.serviceFee
      }).then()
    }
  }

  clearTempUserActions()
}

export const updateTransNoForUserAction = async (orderId?: string, transNo?: string) => {
  if (!orderId || !transNo) return
  const queryUserActionsOrder = await UserActionHistory.find({ selector: { orderId } }).exec()
  const userActionsOrder = queryUserActionsOrder.map(u => u.toMutableJSON())
  for (const userAction of userActionsOrder) {
    if (!userAction.transNo) {
      const newUserAction = { transNo, ...userAction }
      await UserActionHistory.upsert(newUserAction)
    }
  }
}

// Expose to global
Object.assign(window, { UserActionHistory })
