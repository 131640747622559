import type { FunctionComponent } from "react";
import {LL0} from "@/react/core/I18nService.tsx";

export type DineInTaxAndTakeawayTaxIType = {
  onClose?: () => void;
};

const DineInTaxAndTakeawayTaxI: FunctionComponent<
  DineInTaxAndTakeawayTaxIType
> = ({ onClose }) => {
  return (
    <div className="w-full h-full rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-end justify-start pt-3 px-3 pb-6 box-border gap-[13px_0px] min-w-[520px] max-w-[640px] max-h-full text-left text-base text-blue-solid-blue-500-0051c1 font-mulish">
      <img
        className="relative w-[21px] h-[21px] object-cover cursor-pointer"
        alt=""
        src="/iconclose-popup-icon@2x.png"
        onClick={onClose}
      />
      <div className="self-stretch flex-1 overflow-y-auto flex flex-col items-end justify-start pt-0 px-3 pb-4 gap-[24px_0px]">
        <div className="self-stretch flex flex-col items-end justify-start gap-[8px_0px]">
          <b className="self-stretch relative">
            {LL0().taxPayment.dineInTaxDifferentTakeawayTax()}
          </b>
          <div className="self-stretch relative text-gray-300">
            {LL0().taxPayment.dineInTaxDifferentTakeawayTaxContent()}
          </div>
        </div>
        <div className="self-stretch flex flex-col items-end justify-start gap-[8px_0px]">
          <b className="self-stretch relative">
            {LL0().taxPayment.dineInTaxSameTakeawayTax()}
          </b>
          <div className="self-stretch relative text-gray-300">
            {LL0().taxPayment.dineInTaxSameTakeawayTaxContent()}
          </div>
        </div>
        <div className="self-stretch flex flex-col items-end justify-start gap-[8px_0px]">
          <b className="self-stretch relative">{LL0().settings.taxTypeNone()}</b>
          <div className="self-stretch relative text-gray-300">
            {LL0().taxPayment.noCustomerTax()}
          </div>
        </div>
      </div>
      <div
        className="rounded-md bg-royalblue-100 w-[121px] h-9 flex flex-row items-center justify-center cursor-pointer text-center text-mini text-basic-color-white-solid-white-100-ffffff"
        onClick={onClose}
      >
        <div className="relative font-semibold">{LL0().ui.ok()}</div>
      </div>
    </div>
  );
};

export default DineInTaxAndTakeawayTaxI;