import type { FunctionComponent } from "react";
import { LL0 } from "@/react/core/I18nService.tsx";
import { licenses0, makeLicenseAvailable } from "@/data/LicenseHub.ts";
import dayjs from "dayjs"
import { now } from "@/pos/logic/time-provider";
import { LL2 } from "@/react/core/I18nBackend.tsx";

export type PasscodeScreenViewLicensePType = {
  onClose?: () => void;
};

const PasscodeScreenViewLicenseP: FunctionComponent<
  PasscodeScreenViewLicensePType
> = ({ onClose }) => {
  makeLicenseAvailable()
  return (
    <div className="w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-end justify-start pt-[18px] px-4 pb-3.5 box-border gap-[4px] min-w-[320px] h-full max-w-full max-h-full text-left text-base text-blue-solid-blue-500-0051c1 font-mulish">
      <div className="self-stretch flex flex-col items-end justify-start gap-[10px]">
        <div className="self-stretch flex flex-row items-start justify-between">
          <b className="flex-1 relative">{LL0().posSetup.accountTab.registeredLicense()}</b>
          <img
            className="w-6 relative h-6 overflow-hidden shrink-0 object-cover cursor-pointer"
            alt=""
            src="/close-popup-icon--grey@2x.png"
            onClick={onClose}
          />
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-mini text-black-solid-black-1000-000000">
          <div className="self-stretch flex flex-row items-center justify-start gap-[15px]">
            <div className="w-[94px] relative font-semibold inline-block shrink-0">
              {LL0().posSetup.accountTab.licensePlan()}
            </div>
            <div className="rounded-11xl bg-[#FF9FEA] flex flex-row items-center justify-center py-1 px-3.5">
              <div className="relative">{LL0().posSetup.accountTab.trial()}</div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start gap-[15px]">
            <div className="w-[94px] relative font-semibold inline-block shrink-0">
              {LL0().posSetup.accountTab.startOfPlan()}:
            </div>
            <b className="flex-1 relative">{dayjs.unix(licenses0()[0]?.licenseFrom || dayjs(now()).unix()).format(LL2().dates.dateFormat())}</b>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start gap-[15px]">
            <div className="w-[94px] relative font-semibold inline-block shrink-0">
              {LL0().posSetup.accountTab.endOfPlan()}:
            </div>
            <b className="flex-1 relative">{dayjs.unix(licenses0()[0]?.licenseTo || dayjs().add(1, 'month').unix()).format(LL2().dates.dateFormat())}</b>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-end text-center text-sm text-white-solid-white-100-ffffff">
        <div className="rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8"
          onClick={onClose}
        >
          <b className="relative">{LL0().ui.ok()}</b>
        </div>
      </div>
    </div>
  );
};

export default PasscodeScreenViewLicenseP;
