import { type FunctionComponent, useMemo, type CSSProperties } from 'react'

export type AvatarType = {
  isSelected?: boolean
  onClick: () => void

  /** Style props */
  avatarBackgroundImage?: CSSProperties['backgroundImage']
  avatarBackgroundSize?: CSSProperties['backgroundSize']
}

const Avatar: FunctionComponent<AvatarType> = ({ isSelected = true, avatarBackgroundImage, avatarBackgroundSize, onClick }) => {
  const avatarStyle: CSSProperties = useMemo(() => {
    return {
      backgroundImage: avatarBackgroundImage,
      backgroundSize: avatarBackgroundSize,
    }
  }, [avatarBackgroundImage, avatarBackgroundSize])

  return (
    <div
      className="w-[78px] h-[78px] flex flex-col items-center justify-center relative Tablet_768:w-[72px] Tablet_768:h-[72px] Tablet_600:w-16 Tablet_600:h-16 height_414:w-16 height_414:h-16"
      style={avatarStyle}
      onClick={onClick}
    >
      {isSelected && (
        <div className="absolute !m-[0] h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[50%] box-border z-[0] border-[4px] border-solid border-red-solid-red-800-ff0022" />
      )}
      <div className="absolute !m-[0] top-[0px] right-[0px] w-6 h-6 overflow-hidden shrink-0 z-[1]">
        {isSelected && (
          <img
            className="absolute top-[0px] left-[0px] w-6 h-6 object-cover"
            alt=""
            src="/user-settingavatar-checked15@2x.png"
          />
        )}
      </div>
    </div>
  )
}

export default Avatar
