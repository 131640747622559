import { type ImgHTMLAttributes, useEffect, useRef, useState } from 'react'
import type { RxDocument } from 'rxdb'

type Prop<T, V> = {
  doc?: RxDocument<T, V>
  id?: string
  className?: string
  imgProp?: ImgHTMLAttributes<HTMLImageElement>
  src?: string
}

export default function RxAttachmentImage<T, V>({ doc, id, className, imgProp, src: defaultSrc }: Prop<T, V>) {
  const [src, setSrc] = useState<string | undefined>()
  const lastSrc = useRef(src)

  const cleanup = () => {
    if (lastSrc.current?.startsWith('blob:')) URL.revokeObjectURL(lastSrc.current)
  }

  useEffect(() => {
    cleanup()
    lastSrc.current = src

    return () => cleanup()
  }, [src])

  useEffect(() => {
    if (doc && id) {
      if (id.startsWith('attachment:')) {
        const att = doc.getAttachment(id)
        att
          ?.getData()
          .then(URL.createObjectURL)
          .then(setSrc)
          .catch(e => console.log('⚠️ failed to get attachment', e))
      } else {
        setSrc(id)
      }
    } else setSrc(undefined)
  }, [doc, id])

  return (
    <img
      src={src || defaultSrc}
      className={className}
      {...imgProp}
    />
  )
}
