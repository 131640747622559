import uuid from 'time-uuid'

import type { Order } from '@/data/Order'
import { OrderStatus } from '@/pos/OrderType'
import { signalSyncedWithLocalStorage } from '@/react/core/reactive'

import { srmTransactionLogic } from '../transaction.logic'
import { advanceToTestcase, execTestcase, getCaTaxInfo } from './libs'

type O = Readonly<Order>

const [order01, setOrder01] = signalSyncedWithLocalStorage<O | null>('TESTCASE_005_ORDER_01', null)
const [order04, setOrder04] = signalSyncedWithLocalStorage<O | null>('TESTCASE_005_ORDER_04', null)

const getBaseOrder = (): O => {
  const taxInfo = getCaTaxInfo()
  const order: O = {
    _id: uuid(),
    table: '1',
    status: OrderStatus.COMPLETED,
    payments: [{ type: 'cash', value: 0 }],
    tip: 0,
    discount: 0,
    multiplePriceMenu: '',
    items: [
      { name: 'Samosa', price: 20, quantity: 2, modifiers: [], ...taxInfo },
      { name: 'Garlic Bread', price: 5, quantity: 3, modifiers: [], ...taxInfo },
    ],
  }
  return order
}

export const testRunner005: Record<string, () => Promise<boolean>> = {
  '005.001': () =>
    execTestcase({
      num: 5,
      step: 1,
      device: 'A',
      run: async () => {
        const order = getBaseOrder()
        await srmTransactionLogic.recordClosingReceipt(order, { print: true })
        setOrder01(order)
      },
    }),
  '005.002': () =>
    execTestcase({
      num: 5,
      step: 2,
      device: 'A',
      run: async () => {
        const o = order01()
        if (!o) throw new Error('Please run step 1 first')
        await srmTransactionLogic.changePaymentMethod(o, 'card', () => advanceToTestcase('005.003'))
      },
    }),
  '005.004': () =>
    execTestcase({
      num: 5,
      step: 4,
      device: 'C',
      run: async () => {
        const order: O = { ...getBaseOrder(), _id: uuid(), table: undefined }
        await srmTransactionLogic.recordClosingReceipt(order, { print: true })
        setOrder04(order)
      },
    }),
  '005.005': () =>
    execTestcase({
      num: 5,
      step: 5,
      device: 'C',
      run: async () => {
        const o = order04()
        if (!o) throw new Error('Please run step 4 first')
        await srmTransactionLogic.changePaymentMethod(o, 'card', () => advanceToTestcase('005.006'))
      },
    }),
}
