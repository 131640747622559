import { Buffer } from 'buffer/'
import delay from 'delay'
import Dexie from 'dexie'
//@ts-ignore
import IDBExportImport from 'indexeddb-export-import'
import { md5 } from 'js-md5'
import pako from 'pako'
import { toast } from 'react-toastify'

import { posSync0 } from '@/data/PosSyncState.ts'
import { getDeviceId } from '@/shared/getDeviceId.ts'
import MultiAwaitLock from '@/shared/MultiAwaitLock.ts'
import SurrealClient from '@/shared/SurrealClient.ts'

type Item = {
  id?: string
  data: string
  pos: number
  dbName: string
  storeId: string
  hash: string
  storeName: string
  storeNameIndex: number
  deviceId: string
}

const CHUNK_SIZE = 1024 * 1024

function processNextChunk(idbDatabase: IDBDatabase, objectStoreNames: any, storeName: string, storeNameIndex: number, dbName: string, startKey: any, lock: MultiAwaitLock, chunkIndex = 0) {
  let chunk: any[] = []
  const transaction = idbDatabase.transaction(objectStoreNames, 'readonly')
  transaction.onerror = event => {
    console.log('error', event)
  }
  const cursorRequest = startKey ? transaction.objectStore(storeName).openCursor(IDBKeyRange.lowerBound(startKey, true)) : transaction.objectStore(storeName).openCursor()
  cursorRequest.onsuccess = async event => {
    //@ts-ignore
    const cursor = event.target!.result
    if (cursor) {
      startKey = cursor.key
      chunk.push(cursor.value)

      if (JSON.stringify(chunk).length >= CHUNK_SIZE) {
        const data0 = Buffer.from(JSON.stringify(chunk)).toString('base64')
        const item: Item = {
          storeId: posSync0().id?.toString() || '43',
          dbName: dbName,
          pos: chunkIndex,
          storeName: storeName,
          data: data0,
          hash: md5(data0),
          storeNameIndex: storeNameIndex,
          deviceId: getDeviceId(),
        }
        chunkIndex += 1
        const surreal = await SurrealClient.getSurrealClient('cloud')
        await surreal?.insert('indexdb_metadata', item)
        chunk = []
        processNextChunk(idbDatabase, objectStoreNames, storeName, storeNameIndex, dbName, startKey, lock)
      } else {
        cursor.continue()
      }
    } else {
      const data0 = Buffer.from(JSON.stringify(chunk)).toString('base64')
      const item: Item = {
        storeId: posSync0().id?.toString() || '43',
        dbName: dbName,
        pos: chunkIndex,
        storeName: storeName,
        data: data0,
        hash: md5(data0),
        storeNameIndex: storeNameIndex,
        deviceId: getDeviceId(),
      }
      chunkIndex += 1
      const surreal = await SurrealClient.getSurrealClient('cloud')
      await surreal?.insert('indexdb_metadata', item)
      lock.release().then()
    }
  }
}

async function exportToJsonString(idbDatabase: IDBDatabase, dbName: string) {
  const objectStoreNamesSet = new Set(idbDatabase.objectStoreNames)
  const size = objectStoreNamesSet.size
  if (size === 0) {
    return
  } else {
    const objectStoreNames = Array.from(objectStoreNamesSet)
    const toastId = toast(`Exporting`, {
      autoClose: false,
    })

    for (let index = 0; index < objectStoreNames.length; index++) {
      console.log('object', objectStoreNames[index])
      const storeName = objectStoreNames[index]
      toast.update(toastId, { render: `Exported ${dbName} ${index + 1}/${objectStoreNames.length}` })
      const lock = new MultiAwaitLock(true)
      processNextChunk(idbDatabase, objectStoreNames, storeName, index, dbName, null, lock)
      await lock.acquireAsync()
    }
  }
}

export async function exportMeta() {
  const surreal = await SurrealClient.getSurrealClient('cloud')
  for (const dbName of ['pos', 'pos2', 'pos3']) {
    await surreal?.query(`DELETE FROM indexdb_metadata WHERE storeId = '${posSync0().id?.toString() || '43'}' AND dbName = '${dbName}' AND deviceId = '${getDeviceId()}'`)
    const db = new Dexie(dbName)
    await db.open()
    const idbDatabase = db.backendDB() // get native IDBDatabase object from Dexie wrapper

    // export to JSON, clear database, and import from JSON
    await exportToJsonString(idbDatabase, dbName)
  }
}

export async function importMeta(storeId: string = '43', deviceId: string) {
  const surreal = await SurrealClient.getSurrealClient('cloud')
  const toastId = toast('begin... ', { autoClose: false })
  for (const dbName of ['pos', 'pos2']) {
    const db = new Dexie(dbName)
    await db.open()
    const idbDatabase = db.backendDB() // get native IDBDatabase object from Dexie wrapper

    const importedObject: Record<any, any> = {}
    let storeIndex = 0
    while (true) {
      let chunkIndex = 0
      let storeName = ''
      const allObjects = []
      while (true) {
        console.log(`Pulling storeIndex: ${storeIndex} pos: ${chunkIndex}`)
        const result: any[] = await surreal.query(
          `SELECT * FROM indexdb_metadata WHERE storeId = '${storeId}' AND dbName = '${dbName}' AND deviceId = '${deviceId}' AND pos = ${chunkIndex} AND storeNameIndex = ${storeIndex}`
        )
        if (!result?.[0]?.length) break
        const item = result[0][0]
        storeName = item.storeName
        const data0 = Buffer.from(item.data, 'base64').toString()
        allObjects.push(...JSON.parse(data0))
        chunkIndex += 1
      }
      if (!storeName?.length) break
      importedObject[storeName] = allObjects
      storeIndex += 1
    }

    IDBExportImport.clearDatabase(idbDatabase, function (err: any) {
      if (!err) {
        // cleared data successfully
        IDBExportImport.importFromJsonString(idbDatabase, JSON.stringify(importedObject), function (err: any) {
          if (!err) {
            console.log('Imported data successfully')
          }
        })
      }
    })
  }

  toast.update(toastId, {
    render: 'Imported data successfully',
    autoClose: 2000,
  })
  await delay(1000)
  location.reload()
}

function chunkUint8Array(arr: Uint8Array, length: number) {
  const chunks = []
  for (let i = 0; i < arr.length; i += length) {
    chunks.push(arr.slice(i, i + length))
  }
  return chunks
}

//@ts-ignore
window.testExport = exportMeta

//@ts-ignore
window.testImport = importMeta
