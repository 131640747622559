import uuid from 'time-uuid'

import { deviceSetting0 } from '@/data/DeviceSettingSignal'
import type { PosSrmSetting } from '@/data/PosSetting'
import { posSetting0 } from '@/data/PosSettingsSignal'
import { SrmDocumentLog } from '@/data/SrmDocumentLog'
import { SrmEventLog } from '@/data/SrmEventLog'
import { SrmTransactionLog } from '@/data/SrmTransactionLog'
import { User, UserRole } from '@/data/User'
import { loginUser } from '@/data/UserSignal'
import { bound, bypassConfirmation, handleError, progressDialog } from '@/shared/decorators'

import { csrContent, srmCertLogic } from '../cert.logic'
import { srmUserLogic } from '../user.logic'
import { TESTCASE_USERS, TESTCASE_VARS } from './constants'

const defaultUserTestOptions: Partial<User> = {
  noTVQ: TESTCASE_VARS.qstNumber,
  noTPS: TESTCASE_VARS.gstNumber,
}

class TestcaseLogic {
  @progressDialog(() => ({ title: `Configuring srm settings...` }))
  @handleError()
  @bound()
  async configureSrmSettings(env?: PosSrmSetting['env']) {
    const posSetting = posSetting0()?.doc
    if (!posSetting) throw new Error('PosSetting not initialized!')

    // Update setting for tests
    await posSetting.incrementalUpdate({
      $set: {
        'srm.qstNumber': TESTCASE_VARS.qstNumber,
        'srm.gstNumber': TESTCASE_VARS.gstNumber,
        'srm.authCode': TESTCASE_VARS.authCode,
        'srm.certificateCode': TESTCASE_VARS.certificateCode,
        'srm.identificationNumber': TESTCASE_VARS.identificationNumber,
        'srm.billingNumber': TESTCASE_VARS.billingNumber,
        'srm.timezone': TESTCASE_VARS.timezone,

        ...(env ? { 'srm.env': env } : {}),
      },
    })
  }
  @progressDialog(() => ({ title: `Removing ALL srm data...` }))
  @handleError()
  @bound()
  async clearAllSrmData() {
    await SrmTransactionLog.find().remove()
    await SrmDocumentLog.find().remove()
    await SrmEventLog.find().remove()
  }
  @progressDialog(() => ({ title: `Adding test users...` }))
  @handleError()
  @bound()
  async createTestUsers() {
    const { _id: michelId } = (await User.findOne({ selector: { name: TESTCASE_USERS.Michel } }).exec()) || {}
    const { _id: johnId } = (await User.findOne({ selector: { name: TESTCASE_USERS.John } }).exec()) || {}
    // const { _id: joeId } = (await User.findOne({ selector: { name: TESTCASE_USERS.Joe } }).exec()) || {}

    const users: User[] = [
      // Main user
      {
        _id: michelId ?? uuid(),
        avatar: '/src/assets/avatar/avatar_1.png',
        name: TESTCASE_USERS.Michel,
        passcode: '1111',
        role: UserRole.MANAGER,
        ...defaultUserTestOptions,

        editHighSecuritySetting: true,
        moveItem: true,
        viewOrderDetail: true,
        viewOrderHistory: true,
        editMenuLayout: true,
        accessZReport: true,
        viewOtherStaffReport: true,
        discount: true,
        viewCustomerInfo: true,
        allowTableTakeOver: true,
        editTablePlan: true,
        viewOwnReport: true,
        viewMonthlyReport: true,
        manageInventory: true,
        deletePrintedItem: true,
        viewOnlineOrderDashboard: true,
        viewOnlineOrderMenu: true,
        viewOrder: true,
        viewReservation: true,
        cancelOrder: true,
        editThePriceOfAMenuItem: true,
      },
      // Sub user
      {
        _id: johnId ?? uuid(),
        avatar: '/src/assets/avatar/avatar_12.png',
        name: TESTCASE_USERS.John,
        passcode: '2222',
        role: UserRole.STAFF,
        ...defaultUserTestOptions,
        viewOrderHistory: true,
        deletePrintedItem: true,
      },
    ]
    await User.bulkUpsert(users)
  }
  @progressDialog(() => ({ title: `Syncing test users...` }))
  @handleError()
  @bound()
  async syncTestUsersWithSrm() {
    const testUsers = await User.find({ selector: { name: { $in: [TESTCASE_USERS.Michel, TESTCASE_USERS.John] } } }).exec()
    for (const u of testUsers) await srmUserLogic.createUser(u)
  }
  @progressDialog(() => ({ title: `Syncing Admin user...` }))
  @handleError()
  @bound()
  async syncAdminUserWithSrm() {
    const admin = await User.findOne({ selector: { name: 'admin' } }).exec()
    if (!admin) throw new Error('Admin user not found!')
    const updated = await admin.incrementalUpdate({ $set: defaultUserTestOptions })
    await srmUserLogic.createUser(updated)
    const u = loginUser()
    if (u && u.name === admin.name) u.srmSynced = true
  }

  @progressDialog(() => ({ title: `Enrolling certificate...` }))
  @handleError()
  @bound()
  async enrollCertificate() {
    const srm = deviceSetting0()?.srm
    if (srm && srm.cert) {
      bypassConfirmation('once')
      await srmCertLogic.deleteCert().catch() // Remove existing cert, ignore error
    }
    csrContent.SN = 'Certificat A'
    await srmCertLogic.createCert()
  }
  @progressDialog(() => ({ title: `Configuring Company Info...` }))
  @handleError()
  @bound()
  async configureCompanyInfo() {
    const posSetting = posSetting0()?.doc
    if (!posSetting) throw new Error('PosSetting not initialized!')

    // Update setting for tests
    await posSetting.incrementalUpdate({
      $set: {
        'generalSetting.autoLogOutPeriod': 15 * 60 * 1000, // 15 min
        'generalSetting.autoLogOutAfterInactivity': true,
        'generalSetting.autoLogOutWhenCloseApp': true,
        'generalSetting.quebecSrmEnable': true,
        'generalSetting.trainingMode': false,

        'companyInfo.name': TESTCASE_VARS.name,
        'companyInfo.address': TESTCASE_VARS.street_address,
        'companyInfo.city': TESTCASE_VARS.city,
        'companyInfo.province': TESTCASE_VARS.province,
        'companyInfo.zipCode': TESTCASE_VARS.zipCode,
      },
    })
  }
  /**
   * Delete all users, except admin
   */
  @progressDialog(() => ({ title: `Adding test users...` }))
  @handleError()
  @bound()
  async clearDemoUsers() {
    await User.find({ selector: { name: { $ne: 'admin' } } }).remove()
  }
}
export const testcaseLogic = new TestcaseLogic()

// Expose to window for testing
Object.assign(window, { testcaseLogic })
