import {IconButton, InputAdornment, TextField as TextField0, type TextFieldProps} from "@mui/material";
import {useState} from "react";
import {effect, useSignal} from "@/react/core/reactive.ts";
import type {InputController} from "@/react/core/Input.tsx";
import _ from "lodash";
import clsx from "clsx";
import {Keyboard} from "@mui/icons-material";
import TextArea from "@/react/core/TextArea.tsx";
import PortalPopup from "@dashboard/PortalPopup.tsx";
import InputPopup from "@/react/core/InputPopup.tsx";
import { useNativeInput } from "@/react/core/NativeInput.ts";

const TextFieldArea = (props: TextFieldProps & {
  onEdit?: () => void;
  value?: string
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [inputControllers, setInputControllers] = useSignal<Array<InputController>>([]);

  const onClick = () => {
    props.onEdit?.();
    const dispose = effect(() => {
      if (inputControllers()[0]) {
        inputControllers()[0]?.setKeys(props.value?.split("")!);
        setTimeout(() => {
          inputControllers()[0]?.setFocus(true);
          inputControllers()[0]?.moveCaretToEnd();
          dispose();
        }, 200);
      }
    })
    setIsPopupOpen(true);
  };

  return <>
    {useNativeInput() && <TextField0
        color="primary"
        size="small"
        variant="outlined"
        multiline
        className={props.className + ' !box-border !p-0'}
        label={props.label}
        value={props.value || ''}
        disabled={props.disabled}
        onChange={event => props.onChange?.(event)}
        inputProps={{ className: props.className + ' !box-border !py-2 no-scrollbar !overflow-auto'}}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" disablePointerEvents={props.disabled}
                            className={clsx(props.disabled && 'opacity-40')}
                            // style={{ alignItems: 'flex-end', height: '100%',alignSelf: 'baseline', marginTop:'10px' }}
            >
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                onClick={onClick}
              >
                <Keyboard/>
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            paddingY: 0 ,
          },

        }}
    />}
    {!useNativeInput() && <TextArea
        className={props.className + ' !py-2 !pl-3'}
        value={props.value}
        label={props.label as string || ''}
        onChange={v => props.onChange?.({ target: { value: v } } as any)}

    >
        <div className={'mr-3'}>
            <IconButton
                aria-label="toggle password visibility"
                disabled={props.disabled}
                edge="end"
                onClick={onClick}
                // className={'!pt-0'}
            >
                <Keyboard />
            </IconButton>
        </div>
    </TextArea>}

    {isPopupOpen && (
      <PortalPopup
        overlayColor="rgba(0, 0, 0, 0.2)"
        placement="Centered"
        onOutsideClick={() => setIsPopupOpen(false)}
      >
        <InputPopup onClose={() => setIsPopupOpen(false)}
                    inputControllers={inputControllers}
                    value={props.value || ''}
                    label={props.label as string}
                    setInputControllers={setInputControllers}
                    onEnter={() => {
                      setIsPopupOpen(false);
                      props.onChange?.({
                        target: {
                          value: inputControllers()[0].keys().join('')
                        }
                      } as any)
                    }}
        />
      </PortalPopup>
    )}
  </>

}

export default TextFieldArea