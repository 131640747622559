import { type FunctionComponent, useEffect, useState } from "react";
import _ from "lodash";
import { FormControlLabel, Switch, } from "@mui/material";
import {
  multipleMenus0,
  printKitchenPricingSwitch
} from "@/react/EditMenuView/EditPricingLevel.logic.ts";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { LL0 } from "@/react/core/I18nService.tsx";

export type SetupMenusType = {
  className?: string;
  onClose?: () => void;
};

const SetupMenus: FunctionComponent<
  SetupMenusType
> = ({ className = "", onClose }) => {

  useEffect(() => {
    if (!generalSetting0()) return
    if (generalSetting0().multipleDineInMenusEnable && !generalSetting0().multipleDineInMenus) {
      _.assign(generalSetting0(), { multipleDineInMenus: multipleMenus0() ?? []})
    }
  }, [generalSetting0().multipleDineInMenusEnable]);

  return (
    <>
      <div
        className={`w-full relative rounded-md bg-white-solid-white-100-ffffff flex flex-col items-start justify-start py-[34px] px-0 box-border gap-[20px] min-w-[520px] max-h-[480px] h-full max-w-full overflow-auto text-left text-black-solid-black-900-1e1e23 font-mulish ${className}`}
      >
        <div className="self-stretch flex flex-col items-start justify-center gap-[6px] z-[0]">
          <div className="self-stretch flex flex-row items-start justify-center py-0 px-5">
            <div className="relative font-bold md:text-xs text-lg ">
              {LL0().div.pricingLevelSetup.setupMenus()}
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-center gap-[6px] z-[1]">
          <div
            className="self-stretch flex flex-col items-start justify-start gap-[6px] text-lg text-blue-solid-blue-500-0051c1">
            {(generalSetting0()?.multipleDineInMenusEnable && generalSetting0()?.multipleDineInMenus && generalSetting0()?.multipleDineInMenus?.length) ? generalSetting0()?.multipleDineInMenus?.map((pricingLevel, index) => (
              <div key={index}
                   className="self-stretch bg-gray-solid-gray-130-f4f4f4 flex flex-col items-start justify-start pt-3 px-0 pb-0 gap-[4px]">
                <div className="self-stretch flex flex-row items-center justify-start py-0 px-6 gap-[12px]">
                  {pricingLevel?.name || ''}
                </div>
                <div className="self-stretch flex flex-row items-start justify-start py-0 px-7 gap-[36px]">
                  <div className="flex flex-row items-center justify-start py-1 px-0 gap-[8px]">
                    <b className="relative md:text-xs">{LL0().dialogs.printKitchen.printOrder()}</b>
                    <FormControlLabel
                      label=''
                      control={<Switch color="primary" {...printKitchenPricingSwitch(index, pricingLevel)}
                      />}
                    />
                  </div>
                </div>
              </div>
            )) : null}
          </div>
        </div>
        <img
          className="w-6 absolute !m-[0] top-[12px] right-[12px] h-6 overflow-hidden shrink-0 object-cover cursor-pointer z-[2]"
          alt=""
          src="/close-popup-icon--grey@2x.png"
          onClick={onClose}
        />
      </div>
    </>
  );
};

export default SetupMenus;
