import uuid from 'time-uuid'

import type { Order } from '@/data/Order'
import { OrderStatus } from '@/pos/OrderType'
import { signalSyncedWithLocalStorage } from '@/react/core/reactive'

import { addItemToOrder } from '../order-mutations'
import { srmTransactionLogic } from '../transaction.logic'
import { execTestcase, getCaTaxInfo } from './libs'

type O = Readonly<Order>

const [order01, setOrder01] = signalSyncedWithLocalStorage<O | null>('TESTCASE_008_ORDER_01', null)
const [order02, setOrder02] = signalSyncedWithLocalStorage<O | null>('TESTCASE_008_ORDER_02', null)
const [order03, setOrder03] = signalSyncedWithLocalStorage<O | null>('TESTCASE_008_ORDER_03', null)

const getBaseOrder = (): O => {
  const taxInfo = getCaTaxInfo()
  const order: O = {
    _id: uuid(),
    status: OrderStatus.IN_PROGRESS,
    payments: [{ type: 'cash', value: 5.03 }],
    items: [
      { name: 'Samosa', price: 2, quantity: 2, modifiers: [], ...taxInfo },
      { name: 'Garlic Bread', price: 0.37, quantity: 1, modifiers: [], ...taxInfo },
    ],
    trainingMode: true,
  }
  return order
}

export const testRunner008: Record<string, () => Promise<boolean>> = {
  '008.001': () =>
    execTestcase({
      num: 8,
      step: 1,
      device: 'A',
      run: async () => {
        const order = getBaseOrder()
        await srmTransactionLogic.recordClosingReceipt(order, { print: true })
        setOrder01(order)
      },
    }),
  '008.002': () =>
    execTestcase({
      num: 8,
      step: 2,
      device: 'A',
      run: async () => {
        const taxInfo = getCaTaxInfo()
        const o: O = {
          _id: uuid(),
          status: OrderStatus.IN_PROGRESS,
          payments: [{ type: 'cash', value: 10.02 }],
          items: [
            { name: 'Samosa', price: 4, quantity: 2, modifiers: [], ...taxInfo },
            { name: 'Garlic Bread', price: 0.71, quantity: 1, modifiers: [], ...taxInfo },
          ],
          trainingMode: true,
        }
        await srmTransactionLogic.recordClosingReceipt(o, { print: true })
        setOrder02(o)
      },
    }),
  '008.003': () =>
    execTestcase({
      num: 8,
      step: 3,
      device: 'A',
      run: async () => {
        const order: O = { ...getBaseOrder(), _id: uuid(), table: '12' }
        await srmTransactionLogic.recordTemporaryBill(order, { print: true })
        setOrder03(order)
      },
    }),
  '008.004': () =>
    execTestcase({
      num: 8,
      step: 4,
      device: 'A',
      run: async () => {
        const taxInfo = getCaTaxInfo()
        const o = order03()
        if (!o) throw new Error('Please run step 3 first')
        const order: O = addItemToOrder({ ...o, _id: uuid() }, { name: 'Wakame', price: 4.34, quantity: 1, modifiers: [], ...taxInfo })
        await srmTransactionLogic.recordClosingReceipt(order, { print: true })
      },
    }),
  '008.005': () =>
    execTestcase({
      num: 8,
      step: 5,
      device: 'C',
      run: async () => {
        const order: O = { ...getBaseOrder(), _id: uuid() }
        await srmTransactionLogic.recordClosingReceipt(order, { print: true })
      },
    }),
  '008.006': () =>
    execTestcase({
      num: 8,
      step: 6,
      device: 'C',
      run: async () => {
        const o = order02()
        if (!o) throw new Error('Please run step 2 first')
        const order: O = { ...o, _id: uuid() }
        await srmTransactionLogic.recordClosingReceipt(order, { print: true })
      },
    }),
}
