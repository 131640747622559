import ManuelTablePlugin from '@dashboard/ManuelTablePlugin.tsx'
import ManuelTableTableItem from '@dashboard/ManuelTableTableItem.tsx'
import { useResizeObserver } from '@react-hookz/web'
import clsx from 'clsx'
import dayjs from 'dayjs'
import _ from 'lodash'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import { toast } from 'react-toastify'

import { convertDocuments, type DocDeepSignal } from '@/data/data-utils.ts'
import { dataLock } from '@/data/DataUtils.ts'
import { makeImagesAvailable } from '@/data/ImageHub.ts'
import { subscribePause } from '@/data/ImportData'
import { makeMenusAvailable } from '@/data/MenuHub'
import { makeOnlineProviderAvailable } from '@/data/OnlineProviderHub'
import { Order, type OrderDocMethods } from '@/data/Order.ts'
import { posSettingV, setPosSettingV } from '@/data/PosSettingHub.ts'
import { makeProductsAvailable } from '@/data/ProductHub.ts'
import { shouldWarnBecauseOffline } from '@/data/ReplicateEffect.ts'
import { Room, RoomObject } from '@/data/Room.ts'
import { StaffStatus } from '@/data/TableStaffStatus'
import { isTableHasStaffEditing, recordTableStaffStatus } from '@/data/TableStaffStatusHub'
import { isValidSrmUser, loginUsers, logOut, makeUsersAvailable } from '@/data/UserHub.ts'
import { getTotalItems, stripOrder } from '@/pos/logic/order-reactive.ts'
import { CommitAction, type OrderStrip } from '@/pos/OrderType.ts'
import { PosScreen, router, setParams } from '@/pos/PosRouter.ts'
import type { Layout } from '@/react/core/BoxProps.ts'
import {
  type Accessor,
  batch,
  computed,
  deepSignal,
  makeActiveFactory,
  mutable,
  onMount,
  selector,
  type Setter,
  signal,
  useComputed,
  useEffectOn,
  useSignal,
  useSignalEffect,
} from '@/react/core/reactive.ts'
import { styleVisible } from '@/react/core/tw.ts'
import { objectBorderRadiusConstructor } from '@/react/FloorPlanView/RenderRoom.tsx'
import { itemContext } from '@/react/OrderView/OrderView.tsx'
import { order0 } from '@/react/OrderView/OrderViewShare.ts'

import { effectClearTableStaffStatus } from '../effects/table-staff-status.effect'
import { getRoomHeight, getRoomWidth, getValidZoom } from './floor-plan-utils'
import PortalPopup from "@order-view/PortalPopup.tsx";
import MergeTablePopup from "@/react/FloorPlanView/MergeTablePopup.tsx";
import { handlePushCommit } from "@/react/FloorPlanView/floor-utils.ts";
import { isQuebecSrmEnabled, mainScreen } from "@/data/PosSettingsSignal.ts";
import { LL0 } from '@/react/core/I18nService'
import { srmTransactionLogic } from '@/srm/transaction.logic'
import type { RxDocument } from 'rxdb'

//todo: zoom calculate
type ZoomMeta = { maxWidth: number; maxHeight: number }
const ZOOM_PADDING = { width: 20, height: 20 }

const makeActive = makeActiveFactory()

export const [isManual, setIsManual] = signal<boolean>(false)

export const [manualOrders0, setManualOrders0] = signal<[]>([])

export const [isConfirmMergeTablePopUpOpen, setIsConfirmMergeTablePopUpOpen] = signal<boolean>(false)

export const onBack = async () => {
  await logOut()
}

export const onTableClick = _.debounce(
  async function (object: RoomObject) {
    if (object.type !== 'table') return

    // Check if the table is busy
    if (isQuebecSrmEnabled() && (await isTableHasStaffEditing(object.name))) {
      toast.error('Table is busy', { autoClose: 2000 })
      return
    }
    console.log('ℹ️ onTableClick', object)

    if (itemContext.moveEnable()) {
      if (isQuebecSrmEnabled() && !isValidSrmUser()) return toast.error(LL0().srm.errors.unauthorized())

      //check if customer move item to the same table, if true, cancel move
      if (object?.name !== order0().table) {
        if (object.busy) {
          const tableOrder = object.ref!;
          await handlePushCommit(tableOrder, order0())

          // order0().getMoveOrder!().commits?.forEach(commit => tableOrder.commits?.push(commit))
          setParams({ order: tableOrder as Order, isMoveTable: true })
        } else {
          setParams({
            order: { ...order0().getMoveOrder!(), table: object.name, users: loginUsers() },
            isMoveTable: true
          })
        }
        order0().commits?.push({ action: CommitAction.COMPLETE_MOVE_ORDER })
        order0().commits?.push({ action: CommitAction.PRINT })
        let stripOrder0 = stripOrder(order0())
        await Order.upsert(stripOrder0)
        itemContext.onMoveCancel()
      }
      itemContext.onMoveCancel()
      setTimeout(() => {
        router.screen = PosScreen.ORDER
      }, 0)
      return
    }

    if (itemContext.groupEnable()) {
      if (isQuebecSrmEnabled() && !isValidSrmUser()) return toast.error(LL0().srm.errors.unauthorized())

        //check if customer group the same table, if true, cancel group
      if (object?.name !== order0()?.table) {
        //handle if object have order
        if (object.busy) {
          const tableOrder = object.ref!;
          await handlePushCommit(tableOrder, order0(), true)
        } else {
          //handle if object doesn't have table
          delete order0().getMoveOrder
          setParams({
            order: { ...order0(), table: object.name, users: loginUsers() },
            isMoveTable: true
          })
        }
        order0().commits?.push({ action: CommitAction.PRINT })
        let stripOrder0 = stripOrder(order0())
        await Order.upsert(stripOrder0)
      }
      itemContext.onGroupCancel()
      setTimeout(() => {
        router.screen = PosScreen.ORDER
      }, 0)
      return
    }

    if (globalChangeActive() && !object.ref) {
      const currentOrder = currentObject().ref
      if (!currentOrder) throw new Error('⚠️ Failed to move table. Current order not found!')
      console.log(`➡ Moving table ${currentOrder.table} to table ${object.name} (currently empty)...`)
      if (isQuebecSrmEnabled() && !isValidSrmUser()) return toast.error(LL0().srm.errors.unauthorized())

        //handle if the target table is not busy
      setChangeActiveV(v => v + 1)
      currentOrder.table = object.name
      if (isQuebecSrmEnabled()){
        await srmTransactionLogic.recordTemporaryBill(currentOrder)
      }
      await (currentOrder as unknown as { doc: RxDocument<Order> }).doc?.incrementalPatch({ table: object.name })
      return
    } else if (globalChangeActive() && object.ref) {
      const currentOrder = currentObject().ref
      if (!currentOrder) throw new Error('⚠️ Failed to move table. Current order not found!')
      console.log(`➡ Moving table ${currentOrder.table} to table ${object.name} (currently occupied)...`)
      if (isQuebecSrmEnabled() && !isValidSrmUser()) return toast.error(LL0().srm.errors.unauthorized())
      //handle if the target table is busy
      if (object.ref.table === currentOrder.table) return setChangeActiveV(v => v + 1);
      setTargetObject(object);
      return setIsConfirmMergeTablePopUpOpen(true)
    }
    if (object.busy) {
      const order = await Order.findOne({ selector: { _id: object.ref?._id } }).exec()
      setParams({ order: order?.toMutableJSON() })
    } else {
      setParams({ table: object.name, takeAway: object?.takeAway })
    }

    if (isQuebecSrmEnabled() && object.name) {
      await recordTableStaffStatus(object.name, StaffStatus.MAKING_ORDER, object.ref?._id)
    }
    setTimeout(() => {
      router.screen = PosScreen.ORDER
    }, 0)
  },
  20,
  { leading: true, trailing: false }
)
interface RenderOpts {
  object: RoomObject
  zoom: Accessor<number>
  defaultZoom: number
  setCurrentObject: Setter<RoomObject>
  setGlobalChangeActive: Setter<boolean>
  changeActiveV: Accessor<number>
  setChangeActiveV: Setter<number>
  currentObject: Accessor<RoomObject>
  globalChangeActive: Accessor<boolean>
}
function RenderObject({ object, zoom, defaultZoom, setCurrentObject, setGlobalChangeActive, changeActiveV }: RenderOpts) {
  const [changeActive, setChangeActive] = useSignal<boolean>(false)
  const initY = (object.location?.y ?? 0) * zoom() * defaultZoom

  useSignalEffect(() => {
    changeActiveV()
    setChangeActive(false)
    setGlobalChangeActive(false)
  })

  /**
   * debounce to prevent two times call
   */

  const onSwiped = () => {
    console.log('onSwipedLeft')
    if (shouldWarnBecauseOffline()) return toast.error('Master is not available', { autoClose: 2000 })
    if (object.ref) {
      makeActive(setChangeActive)
      setGlobalChangeActive(true)
      setCurrentObject(object)
    }
  }
  const handlers = useSwipeable({
    onSwipedLeft: onSwiped,
    onSwipedRight: onSwiped,
    trackMouse: true,
    trackTouch: true,
  })

  return (
    <div
      className={clsx('overflow-hidden absolute flex center', changeActive() ? 'animate-bounce' : '')}
      style={{
        left: (object.location?.x ?? 0) * zoom() * defaultZoom + 'px',
        top: initY + 'px',
        width: (object.size?.width ?? 0) * zoom() * defaultZoom + 'px',
        height: object.size.height * zoom() * defaultZoom + 'px',
        opacity: object.isTransparent ? 0.5 : 1,
        backgroundColor: object.bgColor,
        ...objectBorderRadiusConstructor(object, zoom(), defaultZoom),
      }}
      {...handlers}
      onClick={async e => {
        e.stopPropagation()
        e.preventDefault()
        await onTableClick(object)
      }}
    >
      {
        // TODO: Replace currency label
        object.type === 'table' ? (
          <ManuelTableTableItem
            tableNumber={object.name}
            isMyTable={!!loginUsers()?.[0] && loginUsers()?.[0] === object.ref?.users?.[0]}
            isBooked={object.busy}
            price={_.round(object.ref?.vSum || 0, 2)}
            topRightRoundedPercent={object.shape === 'circle' ? 50 : _.round((object.borderRadius?.topRight / Math.min(object.size.width, object.size.height) || 0) * 100, 2)}
            remainingTime={object.ref?.date ? `${Math.floor((dayjs().unix() - object.ref?.date) / 60)} ` : ''}
          />
        ) : (
          <div className="text-black overflow-hidden">{object.name}</div>
        )
      }
    </div>
  )
}

export const [rooms0, setRooms0] = signal<Array<Room>>([])

//@ts-ignore
window.rooms0 = rooms0

declare global {
  interface Window {
    rooms0: typeof rooms0
  }
}

export const [activeRoomId, setActiveRoomId] = signal<string>(''),
  isActiveRoomId = selector<string>(activeRoomId)

export const [busyRoomMapper, setBusyRoomMapper] = signal<Record<string, boolean>>({})

export const zoom = computed(() => {
  return 1
  // if (Object.keys(zoomMap).length === 0) return 0;
  // return zoomMap[activeRoomId()];
})
const [globalChangeActive, setGlobalChangeActive] = signal<boolean>(false)
export const [changeActiveV, setChangeActiveV] = signal<number>(0)
export const [currentObject, setCurrentObject] = signal<RoomObject>()
const [isDeleteOrder, setIsDeleteOrder] = signal<boolean>(false);
export const [targetObject, setTargetObject] = signal<RoomObject>()

export function RoomComponent({ room }: { room: Room }) {
  const containerRef = useRef<HTMLDivElement>(null)
  const roomSize = useMemo(() => ({ w: getRoomWidth(room), h: getRoomHeight(room) }), [room])
  const [containerSize, setContainerSize] = useState({ w: 0, h: 0 })

  const defaultZoom = useMemo(() => {
    const check = localStorage.getItem('container-size')
    const _containerSize = !!check ? JSON.parse(check) : containerSize
    return Math.min(getValidZoom(_containerSize.w / roomSize.w), getValidZoom(_containerSize.h / roomSize.h));
  }, [containerSize, roomSize]);

  useResizeObserver(containerRef, e => {
    const w = e.target.clientWidth
    const h = e.target.clientHeight
    if (!w || !h) return
    const prevSize = JSON.parse(localStorage.getItem('container-size'));
    if (prevSize?.w !== w || prevSize?.h !== h)
      localStorage.setItem('container-size', JSON.stringify({ w, h }))
    setContainerSize({ w, h })
  })

  return (
    <div
      ref={containerRef}
      className="absolute inset-0"
      {...styleVisible(isActiveRoomId(room._id))}
    >
      {room.roomObjects?.map(object => (
        <RenderObject
          key={object._id}
          object={object}
          zoom={zoom}
          defaultZoom={defaultZoom}
          setCurrentObject={setCurrentObject}
          setGlobalChangeActive={setGlobalChangeActive}
          changeActiveV={changeActiveV}
          globalChangeActive={globalChangeActive}
          setChangeActiveV={setChangeActiveV}
          currentObject={currentObject}
        />
      ))}
      {isConfirmMergeTablePopUpOpen() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => {
            setIsConfirmMergeTablePopUpOpen(false)
            setChangeActiveV(v => v + 1);
          }}
        >
          <MergeTablePopup order={targetObject()?.ref} currentOrder={currentObject()?.ref!} onClose={() => setIsConfirmMergeTablePopUpOpen(false)} />
        </PortalPopup>
      )}
    </div>
  )
}

export const DEBOUNCE_TIME: { v: number } = { v: 1000 }

effectClearTableStaffStatus()

const FloorPlanView = () => {
  const [roomLayout, setRoomLayout] = useSignal<Layout>({})
  const zoomMap = mutable<{ [k: string]: number }>({})
  useSignalEffect(() => {
    if (posSettingV() === 0) setPosSettingV(1)
  })
  makeProductsAvailable()
  makeUsersAvailable()
  useSignalEffect(() => {
    if (roomLayout().width === undefined && roomLayout().width !== 0) return
    const getDistanceX = (obj: RoomObject): number => obj.size.width + obj.location.x
    const getDistanceY = (obj: RoomObject): number => obj.size.height + obj.location.y
    for (const room of rooms0()) {
      const meta = room.roomObjects!.reduce<ZoomMeta>(
        (meta, room) => {
          if (meta.maxWidth < getDistanceX(room)) meta.maxWidth = getDistanceX(room)
          if (meta.maxHeight < getDistanceY(room)) meta.maxHeight = getDistanceY(room)
          return meta
        },
        { maxWidth: 0, maxHeight: 0 }
      )
      const zoomWidth = roomLayout().width! / (meta.maxWidth + ZOOM_PADDING.width)
      const zoomHeight = roomLayout().height! / (meta.maxHeight + ZOOM_PADDING.height)
      zoomMap[room._id] = Math.min(zoomWidth, zoomHeight)
    }
  })
  const [tableStatusV, setTableStatusV] = useSignal(0)
  const fetchRoom = useCallback(
    _.debounce(
      async () => {
        await dataLock.acquireAsync()
        const rooms = await Room.find().exec()
        const roomObjects = await RoomObject.find().exec()
        const _rooms = rooms
          .sort((r1, r2) => r1.order - r2.order)
          .map(r => ({
            ...r.toMutableJSON(),
            roomObjects: roomObjects
              .map(r => ({
                ...r.toMutableJSON(),
                doc: r,
              }))
              .filter(o => o.room === r._id)
              .filter((o: { type: string }) => o.type === 'table' || o.type === 'wall'),
            doc: r,
          }))
          .map(r => deepSignal<Room>(r))
        batch(() => {
          setRooms0(_rooms)
          setActiveRoomId(_rooms[0]?._id)
          pullTableStatus().then()
        })
      },
      2000,
      { leading: true, trailing: true }
    ),
    []
  )
  onMount(fetchRoom)
  useEffectOn(
    [tableStatusV],
    async () => {
      await pullTableStatus()
    },
    { defer: true }
  )

  const objects = useComputed(() => rooms0().reduce<Array<RoomObject>>((l, r) => [...l, ...r.roomObjects!], []))
  const [, setTick] = useState<number>(0)
  const pullTableStatus = useCallback(
    _.debounce(
      async () => {
        //  reset busyRoomMapper
        setBusyRoomMapper({})
        const _orders = await Order.find({
          selector: {
            /*status: OrderStatus.IN_PROGRESS*/
          },
          sort: [{ date: 'asc' }],
        }).exec()

        const orders = convertDocuments<OrderStrip, OrderDocMethods>(_orders, false);
        if (orders && orders.length > 0) {
          for (const order of orders) {
            if (getTotalItems(order) === 0 && order.payable === true && order.table) {
              await order?.doc?.incrementalRemove();
              setIsDeleteOrder(true);
            }
          }
          if (isDeleteOrder()) {
            setTableStatusV(v => v + 1)
            setIsDeleteOrder(false)
          }
        }

        const tables = orders.map(o => o.table)
        setManualOrders0(orders?.filter(o => o.manual && o.table))
        const map = _.groupBy<DocDeepSignal<OrderStrip, OrderDocMethods>>(orders, o => o.table)
        batch(() => {
          for (const obj of objects()) {
            if (tables.includes(obj.name) && obj.type === 'table') {
              _.assign(obj, { busy: true, ref: map[obj.name!][0] })
              _.assign(busyRoomMapper(), { [obj.room]: true })
            } else {
              _.assign(obj, { busy: false, ref: null })
            }
          }
          setTick((v: number) => v + 1)
        })
      },
      DEBOUNCE_TIME.v,
      { leading: true, trailing: true }
    ),
    []
  )

  useEffect(() => {
    ;(async () => {
      await dataLock.acquireAsync()
      Order.$.subscribe(async _e => {
        if (subscribePause()) return
        await pullTableStatus()
      })

      Room.$.subscribe(async _e => {
        if (subscribePause()) return
        await fetchRoom()
      })
      RoomObject.$.subscribe(async _e => {
        if (subscribePause()) return
        await fetchRoom()
      })
    })()
  }, [])

  useSignalEffect(async () => {
    if (router.screen !== mainScreen()) return
    // await pullTableStatus()
  })

  // useEffect(() => {
  //   if (import.meta.env.MODE === 'development'){
  //     router.screen = mainScreen();
  //   }
  // }, [])
  makeImagesAvailable()
  makeMenusAvailable()
  makeOnlineProviderAvailable()
  return <ManuelTablePlugin />
}

export default memo(FloorPlanView)
