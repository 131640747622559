import normalizeString from "@/shared/printer/normalize-string";
import { formatCurrency } from "@/shared/utils.ts";
import type { OrderItem } from "@/pos/OrderType.ts";
import { getLL } from "@/react/core/I18nService.tsx";
import _ from "lodash";
import { hex2a } from "@/lib/utils.ts";

//0C -> Clear display
//0B -> Turn cursor to home
//1F4300 -> Turn cursor display mode off
//1F42 -> Turn cursor to bottom position
//0D -> Move cursor to left-most position
//need to set language
export const pre1 = hex2a(`1F43000C0B`);
export const pre2 = hex2a(`1F420D`);

export function getMessageForVFD({ discount, item, sum }: { discount?: string, item?: { name?: string, price: number }, sum: string }) {
  const LL = getLL();
  const numberOfCharactersPerLine = 20
  let line1: string, line2: string;

  if (item) {
    const price = formatCurrency(item.price);
    if (item.name!.length < numberOfCharactersPerLine - price.length) {
      line1 = `${_.padEnd(item.name, numberOfCharactersPerLine - price.length)}${price}`;
    } else {
      line1 = `${item.name!.substring(0, numberOfCharactersPerLine)}`;
    }
  } else if (discount) {
    discount = discount.toString();
    line1 = `${_.padEnd(`${LL().customerDisplay.discount()}:`, numberOfCharactersPerLine - 1 - discount.length)}${discount}%`;
  } else {
    line1 = '';
  }


  sum = formatCurrency(parseFloat(sum));
  line2 = `${_.padEnd(`${LL().customerDisplay.orderTotal()}:`, numberOfCharactersPerLine - sum.length)}${sum}`;

  line1 = normalizeString(line1);
  line2 = normalizeString(line2);
  return `${pre1}${line1}${pre2}${line2}`
}

