import { type FunctionComponent, memo, useEffect, useMemo } from 'react'
import type { ProductLayout } from '@/data/ProductLayout.ts';
import ModifierFlagUrl from '@/assets/modifier_flag.png';
import {
  categoryIndex,
  findProduct,
  getText,
  isSelectedCategory,
  onProductClick,
  productLayouts
} from '@/react/OrderView/OrderView.tsx';
import type { CategoryLayout } from '@/data/OrderLayout.ts'
import GridLayout from '@/react/core/GridLayout.tsx';
import GridElement from '@/react/core/GridElement.tsx';
import { ColorDarken } from '@/react/core/ColorDarken.tsx';
import clsx from 'clsx';
import EditMenuNumberKeyboard from '@edit-menu/EditMenuNumberKeyboard.tsx';
import { computed, effect, useComputed, useSignal } from "@/react/core/reactive.ts";
import PortalPopup from "./PortalPopup";

import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { keyboardConfig0, menuScrollbar } from "@/data/PosSettingsSignal.ts";

type ItemListProps = {
  category: CategoryLayout;
  index: number;
}

const scrollbarLayout = true;

const ItemList: FunctionComponent<ItemListProps> =  memo(({ category, index }: ItemListProps) => {
  function renderItemContent(productLayout: ProductLayout) {
    const product = findProduct(productLayout);

    return <>
      {product?.activePopupModifierGroup && product?.activePopupModifierGroup.length > 0 && (
        <img src={ModifierFlagUrl} className={"absolute top-2 right-2 h-[5px]"} alt="modifier icon" />
      )}
      <span
        className={clsx("font-mulish font-semibold text-[14px] px-[0.5] w-full text-ellipsis overflow-hidden",
          productLayout.type !== "Text" ? "text-black" : "text-white", productLayout.type === "Extra" && "italic")}
        style={{fontSize: deviceSetting0()?.screenConfig?.productFontSize, lineHeight: `${deviceSetting0()?.screenConfig?.textLineHeight}px`}}
      >
				{getText(productLayout)}
			      </span>
    </>;
  }

  const _productLayouts = useComputed(() => {
    const arr = []
    for (let i = 0; i < category.rows; i++) {
      for (let j = 0; j < category.columns; j++) {
        const productLayout = productLayouts()?.find(p => p.top === i && p.left === j && p.category === category._id);
        if (productLayout) {
          arr.push(productLayout);
        }
      }
    }
    return arr;
  });

  return (
    <div className={`w-full h-full self-stretch ${isSelectedCategory(index) ? "flex" : "hidden"} relative overflow-y-auto`}
         key={index}>
      <GridLayout rows={category.rows} cols={category.columns} colGap={5} rowGap={5}
                  className={`w-full ${menuScrollbar() ? 'auto-rows-min': 'h-full'}`}
                  scrollable={menuScrollbar()}>
        {_productLayouts()?.map((productLayout, index) =>
          <ColorDarken
            key={index}
            color={productLayout.type !== "Text" ? productLayout.color || "transparent" : "transparent"}>
            {(color, setClicked) => (
              <GridElement x={productLayout.left} y={productLayout.top} cols={1} rows={1}
                           style={{ background: color }}
                           className={"rounded-[5px] flex center relative min-h-[36px]"}
                           onClick={() => {
                             setClicked(true);
                             setTimeout(() => onProductClick(productLayout), 5);
                           }}>
                {renderItemContent(productLayout)}
              </GridElement>)}
          </ColorDarken>)}
        {keyboardConfig0() && (index === 0 || !keyboardConfig0()?.onlyShowInFirstPage)
          && keyboardConfig0().active &&
          <GridElement
            x={keyboardConfig0().left!}
            y={keyboardConfig0().top!}
            cols={keyboardConfig0().width!}
            rows={keyboardConfig0().height!}
            className="self-start h-full"
          >
            <EditMenuNumberKeyboard editable={false} />
          </GridElement>
        }
      </GridLayout>
    </div>
  );
});

export default ItemList;