import type { RxCollection, RxJsonSchema } from 'rxdb'

import type { PrinterAddress } from '@/shared/printer/types'

export const MASTER_ACTION_COLLECTION_NAME = 'master_actions'

export enum MasterActionType {
  print = 'print',
  test = 'test',
}
export enum MasterActionStatus {
  pending,
  processing,
  processed,
}

interface BaseActionResult {
  success: boolean
}
interface ActionSucceedResult extends BaseActionResult {
  success: true
  data?: unknown
}
interface ActionFailedResult extends BaseActionResult {
  success: false
  error: string
}
export type ActionResult = ActionSucceedResult | ActionFailedResult

interface MasterBaseAction {
  readonly _id: string
  readonly type: MasterActionType
  readonly createAt: number
  readonly requester: string
  readonly payload: unknown
  result?: ActionResult
  status?: MasterActionStatus
  processingAt?: number
  processedAt?: number
}

export interface MasterPrintAction extends MasterBaseAction {
  readonly type: MasterActionType.print
  readonly payload: {
    readonly scripts: Array<{ fn: string; args: unknown[] }>
    readonly address: PrinterAddress
    readonly metadata?: Record<string, unknown>
  }
}
export interface MasterTestAction extends MasterBaseAction {
  readonly type: MasterActionType.test
}

export type MasterAction = MasterPrintAction | MasterTestAction

export const masterActionSchema: RxJsonSchema<MasterAction> = {
  version: 3, // Increase this whenever schema changed
  title: 'MasterAction schema',
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    createAt: { type: 'number', minimum: 0, maximum: 2147483647, multipleOf: 1 },
    type: { type: 'string', enum: Object.values(MasterActionType), maxLength: 24 },
    requester: { type: 'string', maxLength: 24 },
    payload: { type: 'object' },
    result: { type: 'object' },
    status: { type: 'number', minimum: 0, maximum: 16, multipleOf: 1 },
    processedAt: { type: 'number', minimum: 0, maximum: 2147483647, multipleOf: 1 },
    processingAt: { type: 'number', minimum: 0, maximum: 2147483647, multipleOf: 1 },
  },
  required: ['_id', 'type', 'payload'],
  indexes: ['type', 'status'],
}

export const MasterAction = {} as RxCollection<MasterAction>

Object.assign(window, { MasterAction }) // Make available global
