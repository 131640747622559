import type { FunctionComponent } from "react";
import {LL0} from "@/react/core/I18nService.tsx";

export type SeatSelectedButtonType = {
  selected?: boolean;
  seatNumber?: string;
  onClick?: () => void;
};

const SeatSelectedButton: FunctionComponent<SeatSelectedButtonType> = ({
  selected,
  seatNumber,
  onClick
}) => {
  const textClass = `${!selected && '![background:white] !text-black'}`;
  return (
    <div className="shrink-0 flex flex-row items-center justify-start gap-[7px] text-left text-sm text-white-solid-white-100-ffffff font-mulish"
         onClick={onClick}>
      {selected && (
        <img className="relative w-[34px] h-[30px] center" alt="" src="/payment-icontriple-chevron-left-icon@2x.png" />
      )}
      <div className={`${textClass} rounded [background:linear-gradient(180deg,_#01a9f1,_#3d50fc)] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.25)] shrink-0 flex flex-row items-center justify-center py-[15px] px-3`}>
        <b className="relative">{LL0().payment.seat()} {seatNumber}</b>
      </div>
    </div>
  );
};

export default SeatSelectedButton;