import type { PrintingScripts } from '@/data/PrintScripts'
import type { IPrinter } from '@/shared/printer/types'

/** Virtual Printer. Used for printing script generation */
// prettier-ignore
export class VirtualPrinter implements IPrinter {
  private scripts: PrintingScripts = []
  
  private async recordScript(fn: string, args: unknown[]) {
    this.scripts.push({ fn, args })
  }

  /** Return all recorded scripts and clear it for a upcoming fresh recording section. */
  public getScriptsAndClear(): PrintingScripts {
    return this.scripts.splice(0, this.scripts.length)
  }

  
  alignLeft           = (...args: unknown[]) => this.recordScript('alignLeft'          , args)
  alignRight          = (...args: unknown[]) => this.recordScript('alignRight'         , args)
  alignCenter         = (...args: unknown[]) => this.recordScript('alignCenter'        , args)
  setTextDoubleHeight = (...args: unknown[]) => this.recordScript('setTextDoubleHeight', args)
  setTextDoubleWidth  = (...args: unknown[]) => this.recordScript('setTextDoubleWidth' , args)
  setTextQuadArea     = (...args: unknown[]) => this.recordScript('setTextQuadArea'    , args)
  bold                = (...args: unknown[]) => this.recordScript('bold'               , args)
  italic              = (...args: unknown[]) => this.recordScript('italic'             , args)
  setTextNormal       = (...args: unknown[]) => this.recordScript('setTextNormal'      , args)
  invert              = (...args: unknown[]) => this.recordScript('invert'             , args)
  newLine             = (...args: unknown[]) => this.recordScript('newLine'            , args)
  marginTop           = (...args: unknown[]) => this.recordScript('marginTop'          , args)
  drawLine            = (...args: unknown[]) => this.recordScript('drawLine'           , args)
  advancedTableCustom = (...args: unknown[]) => this.recordScript('advancedTableCustom', args)
  tableCustom         = (...args: unknown[]) => this.recordScript('tableCustom'        , args)
  leftRight           = (...args: unknown[]) => this.recordScript('leftRight'          , args)
  println             = (...args: unknown[]) => this.recordScript('println'            , args)
  printQrCode         = (...args: unknown[]) => this.recordScript('printQrCode'        , args)
  printBarcode        = (...args: unknown[]) => this.recordScript('printBarcode'       , args)
  printImage          = (...args: unknown[]) => this.recordScript('printImage'         , args)
  setFontSize         = (...args: unknown[]) => this.recordScript('setFontSize'        , args)
}
