/**
 * Static values that defined in the Revenu Québec's docs
 *
 * Consult the document SW-73-V for more details
 */
export const CONSTANT_VALUES = {
  /** Default code used for testing */
  CODCERTIF_EMPTY: 'FOB201999999',

  /** Empty Signature */
  emptySig: '========================================================================================',

  /** Empty Testcase */
  CASESSAI_EMPTY: '000.000',

  /** Unique identifier of the device. For testing only */
  IDAPPRL_EMPTY: '0000-0000-0000',
} as const

export const SPECIAL_ITEM_DESCR = {
  /**
   * Description indicating that it is a contracted rate.
   *
   * > **NOTE**
   * >
   * > Each contract must include the following details: a contract reference
   * > ("ref:xxxxxxxxxx") [e.g., a contract number], the date of service rendered
   * > ("date:yyyy-mm-dd"), the number of individuals or goods or services rendered, the
   * > date on which the supply becomes payable or the last instalment becomes payable,
   * > as the case may be ("payable:yyyy-mm-dd") and a brief description of the contract.
   */
  contract: 'Contrat',
  /**
   * Description giving information that the supply is part of a package.
   *
   * > **IMPORTANT**
   * >
   * > The "FORFAIT" item must be written in capital letters and followed by the items
   * > included in the package. The price of each item can be "$0.00"
   * > . However, if the
   * > mandatary decides to give the real value of each item, the "FORFAIT" price must
   * > correspond to the negative amount of the sum of all the items included in the
   * > package in order to produce a closing receipt with a subtotal (avantTax field) of
   * > "$0.00"
   * > . Thus, if the customer orders items not included in the package, the subtotal
   * > must correspond to the sum of these items.
   */
  package: 'FORFAIT',
  /**
   * Description indicating that the mandatary adds a mandatory tip as a service fees.
   *
   * > NOTE
   * >
   * > The "Frais de service" item cannot be used alone. It must always follow one or more
   * > items, except for a credit note. In addition, the value of the tax field must be "FP"
   * > .
   */
  serviceFees: 'Frais de service',
  /**
   * Description, on a closing receipt, indicating that the previously sent temporary bill or
   * estimate has been cancelled.
   *
   * > NOTE
   * >
   * > The "Annulation" item cannot be used alone. It must always follow one or more
   * > items. In addition, a description of the cancellation is mandatory for this item.
   */
  cancellation: 'Annulation',
  /**
   * Description indicating that the transaction has been cancelled and that no item is
   * included in it.
   *
   * > NOTE
   * >
   * > Users must not have the option of using the "SOB" item.
   */
  notAvailable: 'SOB',
  /**
   * Description indicating that there is a discount on the subtotal of the
   * bill (avantTax field).
   *
   * > NOTE
   * >
   * > The "Rabais" item must never be used alone. It must always follow
   * > one or more items.
   */
  discount: 'rabais',
  /**
   * A detail must be added to the "Taxe(s) supplémentaire(s)" item for each applicable tax.
   */
  additionalTax: 'Taxe(s) supplémentaire(s)',
  additionalTaxModifier: 'Boiss. alc. (t. incl.)',
} as const

export const DOCUMENT_NOTES = {
  cash: 'ARGENT COMPTANT',
  creditCard: 'CARTE DE CRÉDIT',
  debitCard: 'CARTE DE DÉBIT',
  noPayment: 'AUCUN PAIEMENT',
  orderThroughDigitalPlatform: 'COMMANDE PAR UNE PLATEFORME NUMÉRIQUE',
  trainingMode: '*** DOCUMENT DE FORMATION ***',
  merchantCopy: '*** COPIE DU COMMERÇANT ***',
  reproduction: 'REPRODUCTION',
  doNotGiveToClient: 'NE PAS REMETTRE AU CLIENT',
  occasionalThirdParty: 'TIERS INHABITUEL',
  quote: 'SOUMISSION',
  revisedQuote: 'SOUMISSION RÉVISÉE',
  estimate: 'ESTIMATION DES RENSEIGNEMENTS',
  revisedEstimate: 'ESTIMATION RÉVISÉE',
  cancelledEstimate: 'ESTIMATION ANNULÉE',
  originalBill: 'FACTURE ORIGINALE',
  revisedBill: 'FACTURE RÉVISÉE',
  cancelledBill: 'FACTURE ANNULÉE',
  paymentReceived: 'PAIEMENT REÇU',
  chargeToAccount: 'PORTÉ AU COMPTE',
  failureToPay: 'PAIEMENT NON REÇU',
  creditNote: 'NOTE DE CRÉDIT',
  abadonedTransaction: 'TRANSACTION ABANDONNÉE',
  correctedCreditNote: 'Note de crédit corrigée',
  correctedBill: 'Facture corrigée',
  /** `Remplace ${n} soumissions` */
  replaceNQuote: (n: number) => `Remplace ${n} soumission(s)`,
  /** `Remplace ${n} estimations` */
  replaceNEstimate: (n: number) => `Remplace ${n} estimation(s)`,
  /** `Remplace ${n} factures` */
  replaceNBill: (n: number) => `Remplace ${n} facture(s)`,
  electronicCopy: 'Copie électronique',
  viewTheTransactionOnline: 'Consulter la transaction en ligne',
  invalidCertificate: '*** CERTIFICAT INVALIDE ***',
  communicationProblem: 'PROBLÈME DE COMMUNICATION',
} as const

export interface SrmAppConfig {
  /** "Product identifier" in the Quebec Dashboard */
  IDSEV: string
  /** "Version identifier" of the registered product in the Quebec Dashboard */
  IDVERSI: string
  /** "Partner identifier" in the Quebec Dashboard */
  IDPARTN: string
  /** Version */
  version: string
  /** Previous Version. */
  previousVersion: string
}

export const REGISTERED_VERSIONS = {
  android_v1: 'android_v1',
  android_v2: 'android_v2',
  ios_v1: 'ios_v1',
  win_v1: 'win_v1',
} as const

/** Static variables, found in Quebec Dashboard */
export const REGISTERED_VALUES: Record<keyof typeof REGISTERED_VERSIONS, SrmAppConfig> = {
  [REGISTERED_VERSIONS.android_v1]: {
    IDSEV: '0000000000003562',
    IDVERSI: '0000000000003FC3',
    IDPARTN: '0000000000001C13',
    version: '1.0',
    previousVersion: '0',
  },
  [REGISTERED_VERSIONS.android_v2]: {
    IDSEV: '0000000000003562',
    IDVERSI: '00000000000042B4',
    IDPARTN: '0000000000001C13',
    version: '2',
    previousVersion: '1.0',
  },
  [REGISTERED_VERSIONS.ios_v1]: {
    IDSEV: '00000000000035D4',
    IDVERSI: '0000000000004078',
    IDPARTN: '0000000000001C13',
    version: '1.0',
    previousVersion: '0',
  },
  [REGISTERED_VERSIONS.win_v1]: {
    IDSEV: '0000000000003621',
    IDVERSI: '00000000000040C1',
    IDPARTN: '0000000000001C13',
    version: '1.0',
    previousVersion: '0',
  },
}

/**
 * - ADDI: Addition (temporary bill)
 * - ESTM: Estimation (estimate)
 * - RFER: Reçu de fermeture (closing receipt)
 * - SOUM: Soumission (quote)
 * - TIER: Tiers inhabituel (occasional third party)
 * - SOB: Sans objet (n/a
 */
export const TRANS_TYPES_DISPLAY = {
  ADDI: 'Temporary Bill',
  ESTM: 'Estimate',
  RFER: 'Closing Receipt',
  SOUM: 'Quote',
  TIER: 'Occasional Third Party',
  SOB: 'N/A',
} as const

/**
 * ANN: Annulation (cancellation)
 * RPR: Reproduction
 * DUP: Duplicata (duplicate)
 * PSP: Parti sans payer (failure to pay)
 * FAC: Facture ou tout autre document non inclus dans cette liste (bill or any other document not included in this list)
 */
export const PRINT_MODES_DISPLAY = {
  ANN: 'cancellation',
  RPR: 'reproduction',
  DUP: 'duplicate',
  PSP: 'failureToPay',
  FAC: 'bill',
} as const

/** List of error code returned from WEB-SRM that should be resend again */
export const RESENT_ERR_CODE = ['00', '11', '91', '15', '95']
