import type { FunctionComponent } from "react";
import {LL0} from "@/react/core/I18nService.tsx";

type EditMenuDeleteConfirmatioType = {
  onClose?: () => void;
  onComplete?: () => void;
};

const EditMenuDeleteConfirmatio: FunctionComponent<
  EditMenuDeleteConfirmatioType
> = ({ onClose, onComplete }) => {
  return (
    <div className="relative rounded bg-white-solid-white-100-ffffff w-full overflow-hidden flex flex-col items-center justify-center pt-8 px-6 pb-6 box-border gap-[32px] min-w-[360px] h-full max-w-full max-h-full text-center text-base text-black-solid-black-880-1d1d26 font-mulish">
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
        <b className="self-stretch relative text-xl leading-[28px] text-black-solid-black-900-1e1e23">
          {LL0().ui.confirmation()}
        </b>
        <div className="self-stretch relative leading-[28px] font-medium">
          {LL0().editMenu.areYouSureYouWantToDelete()}
        </div>
        {/*<b className="self-stretch relative leading-[28px]">*/}
        {/*  “36. Chicken Roll-up”?*/}
        {/*</b>*/}
      </div>
      <div className="w-[300px] flex flex-row items-center justify-center gap-[12px] text-sm text-gray-solid-gray-600-979797">
        <div
          className="flex-1 rounded-md bg-white-solid-white-100-ffffff box-border h-[37px] overflow-hidden flex flex-row items-center justify-center cursor-pointer border-[1px] border-solid border-gray-solid-gray-600-979797"
          onClick={onClose}
        >
          <b className="relative">{LL0().ui.cancel()}</b>
        </div>
        <div className="flex-1 rounded-md box-border h-[37px] overflow-hidden flex flex-row items-center justify-center text-blue-500 border-[1px] border-solid border-blue-solid-blue-420-2979ff"
          onClick={() => {
            onComplete?.();
            onClose?.();
          }}
        >
          <b className="relative">{LL0().ui.ok()}</b>
        </div>
      </div>
    </div>
  );
};

export default EditMenuDeleteConfirmatio;
