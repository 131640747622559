import type { FunctionComponent } from "react";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import Input, { inputController0 } from "@/react/core/Input.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { useSignal } from "@/react/core/reactive.ts";

export type ReservationCancelPopupType = {
  className?: string;
  onClose?: () => void;
  onConfirm?: () => void;
};

const ReservationCancelPopup: FunctionComponent<ReservationCancelPopupType> = ({
  className = "",
  onClose,
  onConfirm
}) => {

  const [openReason, setOpenReason] = useSignal<boolean>(false);
  const [reason, setReason] = useSignal<string>('');

  return (
    <div
      className={`!w-[530px] overflow-hidden px-1 w-[520px] relative rounded bg-white-solid-white-100-ffffff flex flex-col items-start justify-start pt-0 px-0 pb-2 box-border gap-[10px] min-w-[520px] h-full max-w-full max-h-full overflow-auto text-left text-sm text-black-solid-black-900-1e1e23 font-mulish ${className}`}
    >
      {openReason() &&
        <div
          className="!m-[0] absolute top-[115px] left-[16px] right-[16px] shadow-[0px_4px_12px_rgba(0,_0,_0,_0.08)] rounded bg-white-solid-white-100-ffffff box-border flex-col items-start justify-start p-1 z-[3] border-[1px] border-solid border-gray-solid-gray-320-d2d2d2">
          <div className="self-stretch flex flex-col items-start justify-start">
            <div
              className="self-stretch bg-white-solid-white-100-ffffff flex flex-row items-center justify-start p-2 border-b-[1px] border-solid border-gray-solid-gray-320-d2d2d2"
              ref={makeRipple}
              onClick={() => {
                setOpenReason(false)
                setReason(LL0().reservation.cancelReasonList.customerCancels())
              }}
            >
              <div className="relative font-semibold">{LL0().reservation.cancelReasonList.customerCancels()}</div>
            </div>
            <div
              className="self-stretch bg-white-solid-white-100-ffffff flex flex-row items-center justify-start p-2 border-b-[1px] border-solid border-gray-solid-gray-320-d2d2d2"
              ref={makeRipple}
              onClick={() => {
                setOpenReason(false)
                setReason(LL0().reservation.cancelReasonList.staffNotAvailable())
              }}
            >
              <div className="relative font-semibold">{LL0().reservation.cancelReasonList.staffNotAvailable()}</div>
            </div>
            <div
              className="self-stretch bg-white-solid-white-100-ffffff flex flex-row items-center justify-start p-2 border-b-[1px] border-solid border-gray-solid-gray-320-d2d2d2"
              ref={makeRipple}
              onClick={() => {
                setOpenReason(false)
                setReason(LL0().reservation.cancelReasonList.wronglyRecordedInformation())
              }}
            >
              <div className="relative font-semibold">{LL0().reservation.cancelReasonList.wronglyRecordedInformation()}</div>
            </div>
            <div
              className="self-stretch bg-white-solid-white-100-ffffff flex flex-row items-center justify-start p-2"
              ref={makeRipple}
              onClick={() => {
                setOpenReason(false)
                setReason(LL0().reservation.cancelReasonList.theGuestDidNotCome())
              }}
            >
              <div className="relative font-semibold">{LL0().reservation.cancelReasonList.theGuestDidNotCome()}</div>
            </div>
          </div>
        </div>}
      <div
        className="self-stretch overflow-y-auto flex flex-col items-start justify-start pt-6 px-3 pb-0 gap-[12px] z-[2] text-center text-red-solid-red-550-cf2222">
        <div className="self-stretch flex flex-row items-center justify-center text-base">
          <div className="flex-1 flex flex-row items-center justify-start">
            <b className="flex-1 relative">{LL0().reservation.cancelReservation()}</b>
          </div>
        </div>
        <div
          className="self-stretch flex flex-col items-start justify-center gap-[1px] text-left text-black-solid-black-880-1d1d26">
          <div className="relative leading-[18px] mix-blend-normal">
            {LL0().reservation.cancelReason()}:
          </div>
          <Input
            className="self-stretch font-mulish text-sm text-dimgray"
            value={reason()}
            onChange={value => {
              setReason(value)
            }}
            onFocus={() => setOpenReason(true)}
          />
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[8px] text-blue-solid-blue-420-2979ff">
          <div
            className="flex-1 rounded-md bg-white-solid-white-100-ffffff box-border h-9 flex flex-row items-center justify-center py-1 px-2.5 cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
            onClick={onClose}
            ref={makeRipple}
          >
            <b className="relative">{LL0().ui.close()}</b>
          </div>
          <div
            className="flex-1 rounded-md bg-blue-solid-blue-420-2979ff h-9 flex flex-row items-center justify-center py-1 px-2.5 box-border text-white-solid-white-100-ffffff"
            ref={makeRipple}
            onClick={() => {
              onConfirm?.()
              onClose?.()
            }}
          >
            <b className="relative">{LL0().ui.confirm()}</b>
          </div>
        </div>
      </div>
      <div className='w-full pr-1'>
        <TableManualKeyboard
          inputController={inputController0}
          onEnter={() => {
            onConfirm?.()
            onClose?.()
          }}
        />
      </div>
    </div>
  );
};

export default ReservationCancelPopup;
