import { type FunctionComponent, useCallback, useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import SearchVoucherPopup from "./SearchVoucherPopup";
import PortalPopup from "./PortalPopup";
import HappyHourDeleteConfirmationPopup from "./HappyHourDeleteConfirmationPopup";
import VoucherLeftContent from "./VoucherLeftContent";
import VoucherInfo from "./VoucherInfo";
import VoucherApplyTime from "./VoucherApplyTime";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { goBack } from "@/react/UserSettingView/UserSettingView.tsx";
import { createVoucher, selectedVoucher } from "@/react/VoucherView/VoucherView.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import { importDataFromCsv } from "@/shared/csv-utils.ts";
import _ from "lodash";

export type VoucherPluginType = {
  className?: string;
};

const VoucherPlugin: FunctionComponent<VoucherPluginType> = ({
  className = "",
}) => {
  const [isSearchVoucherPopupOpen, setSearchVoucherPopupOpen] = useState(false);
  const [isHappyHourDeleteConfirmatiOpen, setHappyHourDeleteConfirmatiOpen] =
    useState(false);

  const openSearchVoucherPopup = useCallback(() => {
    setSearchVoucherPopupOpen(true);
  }, []);

  const closeSearchVoucherPopup = useCallback(() => {
    setSearchVoucherPopupOpen(false);
  }, []);

  const openHappyHourDeleteConfirmati = useCallback(() => {
    setHappyHourDeleteConfirmatiOpen(true);
  }, []);

  const closeHappyHourDeleteConfirmati = useCallback(() => {
    setHappyHourDeleteConfirmatiOpen(false);
  }, []);

  return (
    <>
      <div
        className={`w-full h-full bg-white-solid-white-100-ffffff max-w-full flex flex-col items-start justify-start text-left text-xl text-white-solid-white-100-ffffff font-mulish ${className}`}
      >
        <div
          className="self-stretch bg-gray-opacity-gray-95-4d5663 h-[50px] flex flex-row items-center justify-end py-1 px-4 box-border Tablet_768:h-[42px] Tablet_768:pl-3 Tablet_768:pr-3 Tablet_768:box-border">
          <b className="flex-1 relative leading-[17px] Tablet_768:text-[17px]">
            {LL0().voucher.voucher()}
          </b>
          <div
            className="flex flex-row items-center justify-end gap-[6px] text-center text-sm text-black-solid-black-900-1e1e23 Tablet_768:gap-[4px] Tablet_600:gap-[2px]">
            <div
              className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl bg-white-solid-white-100-ffffff h-[34px] flex flex-row items-center justify-center py-0.5 px-3 box-border gap-[3px] cursor-pointer Tablet_768:h-[30px]"
              onClick={openSearchVoucherPopup}
            >
              <img
                className="w-5 relative h-5 object-cover"
                alt=""
                src="/iconsearch-blue@2x.png"
              />
              <b className="relative Tablet_768:text-xs">{LL0().voucher.search()}</b>
            </div>
            <div
              className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl bg-white-solid-white-100-ffffff h-[34px] flex flex-row items-center justify-center py-0.5 px-3 box-border gap-[3px] Tablet_768:h-[30px]"
              ref={makeRipple}
              onClick={createVoucher}
            >
              <img
                className="w-5 relative h-5 object-cover"
                alt=""
                src="/iconplus-blue@2x.png"
              />
              <b className="relative Tablet_768:text-xs">{LL0().voucher.createNew()}</b>
            </div>
            <div onClick={async () => {
              const api = importDataFromCsv()
              await api.importData('voucher')
            }}
                 className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl bg-white-solid-white-100-ffffff h-[34px] flex flex-row items-center justify-center py-0.5 px-3 box-border gap-[3px] Tablet_768:h-[30px]">
              <img
                className="w-5 relative h-5 object-cover"
                alt=""
                src="/iconimport-blue@2x.png"
              />
              <b className="relative Tablet_768:text-xs">{LL0().voucher.import()}</b>
            </div>
            <div
              className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl bg-blue-solid-blue-450-1271ff h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] min-w-[72px] text-white-solid-white-100-ffffff Tablet_768:h-[30px]"
              ref={makeRipple}
              onClick={goBack}
            >
              <img
                className="w-[30px] relative h-[30px] object-cover"
                alt=""
                src="/iconback-icon@2x.png"
              />
              <b className="w-[45px] relative leading-[17px] hidden">Button</b>
            </div>
          </div>
        </div>
        <div
          className="!h-full self-stretch flex-1 bg-white-solid-white-100-ffffff overflow-y-auto flex flex-row items-start justify-center text-base text-black-solid-black-1000-000000 md:gap-[16px] sm:gap-[14px] Tablet_600:gap-[10px] Tablet_600:pl-3 Tablet_600:pr-3 Tablet_600:box-border">
          <VoucherLeftContent/>

          <div
            className="no-scrollbar self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start z-[0]">
            {selectedVoucher() &&
              <>
                <VoucherInfo/>
                <VoucherApplyTime/>
                <div
                  className="self-stretch rounded-sm bg-white-solid-white-100-ffffff flex flex-row items-start justify-start py-5 px-6 gap-[16px] border-t-[1px] border-solid border-gray-solid-gray-420-b3b3b3 sm:py-4 sm:px-3 sm:box-border Tablet_768:pl-2 Tablet_768:pr-2 Tablet_768:pb-3 Tablet_768:box-border">
                  <div className="flex-1 flex flex-col items-start justify-center gap-[16px]">
                    <div className="self-stretch h-6 flex flex-row items-center justify-start gap-[8px]">
                      <FormControlLabel
                        className="w-10"
                        control={<Switch color="primary" checked={!!selectedVoucher()?.activated}
                                         onChange={(e) => _.assign(selectedVoucher(), { activated: e.target.checked })}/>}
                        label={''}/>
                      <div className="flex-1relative font-medium Tablet_768:text-sm">
                        {LL0().voucher.active()}
                      </div>
                    </div>
                    <div className="self-stretch h-6 grid flex-row items-center justify-start gap-[8px] grid-flow-col">
                      <div className="self-stretch w-10 relative">
                        <FormControlLabel
                          label={''}
                          className="absolute h-[120%] w-full top-[-10%] right-[0%] bottom-[-10%] left-[0%]"
                          control={<Switch color="primary"
                                           checked={selectedVoucher()?.isEqualPrice}
                                           onChange={(__, checked) => {
                                             _.assign(selectedVoucher(), { isEqualPrice: checked })
                                             if (checked) {
                                               _.assign(selectedVoucher(), { totalValue: selectedVoucher()?.price })
                                             }
                                           }}
                          />}
                        />
                      </div>
                      <div className="self-stretch flex-1 relative font-medium flex items-center Tablet_768:text-sm">
                        {LL0().voucher.voucherPriceEqualsVoucherValue()}
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-44 shadow-[0px_4px_6px_rgba(0,_0,_0,_0.2)] rounded bg-white-solid-white-100-ffffff h-10 flex flex-row items-center justify-center gap-[9px] cursor-pointer text-smi text-gray"
                    onClick={openHappyHourDeleteConfirmati}
                  >
                    <img
                      className="w-6 relative h-6 object-cover sm:flex Tablet_768:hidden"
                      alt=""
                      src="/delete-icon.png"
                    />
                    <b
                      className="relative Tablet_768:text-[11px]Tablet_768:hidden Tablet_600:hidden Mobile_480:hidden mq320:hidden">
                      {LL0().voucher.deleteVoucher()}
                    </b>
                  </div>
                </div>
              </>}
          </div>

        </div>
      </div>
      {isSearchVoucherPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeSearchVoucherPopup}
        >
          <SearchVoucherPopup onClose={closeSearchVoucherPopup}/>
        </PortalPopup>
      )}
      {isHappyHourDeleteConfirmatiOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeHappyHourDeleteConfirmati}
        >
          <HappyHourDeleteConfirmationPopup
            onClose={closeHappyHourDeleteConfirmati}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default VoucherPlugin;
