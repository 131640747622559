import type {FunctionComponent} from "react";

type TimeManagementTableItemType = {
  text?: string;
  showPlusIcon?: boolean;
  showText?: boolean;

  /** Style props */
};

const TimeManagementTableItem: FunctionComponent<
  TimeManagementTableItemType
> = ({
  text = "1",
  showPlusIcon = true,
  showText = true,
}) => {
  return (
    <div
      className="flex flex-row items-center justify-center text-center text-mini text-white-solid-white-100-ffffff font-mulish"
    >
      <div className="flex-1 flex flex-row items-center justify-center relative gap-[13px]">
        {showText && (
          <div className="flex-1 relative font-semibold z-[0] sm:text-sm Tablet_768:text-sm Tablet_768:hover:text-sm">
            {text}
          </div>
        )}
        {showPlusIcon && (
          <div className="my-0 mx-[!important] absolute right-[3px] bottom-[-2.5px] w-6 h-6 overflow-hidden shrink-0 flex flex-row items-center justify-center z-[1]">
            <img
              className="relative w-6 h-6 object-cover Tablet_600:w-[18px] Tablet_600:h-[18px]"
              alt=""
              src="/iconplus-green-icon@2x.png"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TimeManagementTableItem;
