import uuid from 'time-uuid'

import type { Order } from '@/data/Order'
import { OrderStatus } from '@/pos/OrderType'
import { signalSyncedWithLocalStorage } from '@/react/core/reactive'

import { srmTransactionLogic } from '../transaction.logic'
import { execTestcase, getCaTaxInfo } from './libs'

type O = Readonly<Order>

const [order01, setOrder01] = signalSyncedWithLocalStorage<O | null>('TESTCASE_007_ORDER_01', null)

const getBaseOrder = (): O => {
  const taxInfo = getCaTaxInfo()
  const order: O = {
    _id: uuid(),
    table: '1',
    status: OrderStatus.IN_PROGRESS,
    payments: [],
    multiplePriceMenu: '',
    items: [
      { name: 'Samosa', price: 20, quantity: 2, modifiers: [], ...taxInfo },
      { name: 'Garlic Bread', price: 10, quantity: 3, modifiers: [], ...taxInfo },
    ],
  }
  return order
}

export const testRunner007: Record<string, () => Promise<boolean>> = {
  '007.002': () =>
    execTestcase({
      num: 7,
      step: 2,
      device: 'A',
      run: async () => {
        const o = getBaseOrder()
        await srmTransactionLogic.recordTemporaryBill(o, { print: true })
        setOrder01(o)
      },
    }),
  '007.003': () =>
    execTestcase({
      num: 7,
      step: 3,
      device: 'A',
      run: async () => {
        const o = order01()
        if (!o) throw new Error('Please run step 2 first')
        await srmTransactionLogic.handleFailureToPay(o)
      },
    }),
}
