import type { ExtractDocumentTypeFromTypedRxJsonSchema, RxCollection, RxDatabase, RxJsonSchema } from 'rxdb'
import { createCollection } from "@/data/utils/migration.ts";

export const OPEN_HOUR_COLLECTION_NAME = 'open_hour'

export const VERSION = 0

const schema = {
  title: 'open hour schema',
  version: VERSION,
  primaryKey: "_id",
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    dayInWeeks: {
      type: "array",
      items: { type: "boolean" }
    },
    openTime: { type: 'string', maxLength: 24 },
    closeTime: { type: 'string', maxLength: 24 },
    deliveryStart: { type: 'string', maxLength: 24 },
    deliveryEnd: { type: 'string', maxLength: 24 },
  }
} as const satisfies RxJsonSchema<any>

export type OpenHour = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schema>;

export type TOpenHour = OpenHour

export const OpenHour = {} as RxCollection<OpenHour>

// @ts-ignore
window.OpenHour = OpenHour;

export const createOpenHourCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: OpenHour,
    collectionName: OPEN_HOUR_COLLECTION_NAME,
    version: VERSION,
    schema: schema,
  });
}
