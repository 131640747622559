import { Fragment, type FunctionComponent } from 'react';
import { getOrderPaymentIcon } from '@/data/PaymentHub';
import type { Order } from '@/data/Order.ts';
import { LL0 } from "@/react/core/I18nService.tsx";
import { computed } from "@/react/core/reactive.ts";
import _ from "lodash";
import { LL3 } from "@/react/core/I18nCurrency.tsx";

type PaymentMethodProps = {
  isShowText?: boolean;
  payments: Order['payments'];
};

const PaymentMethod: FunctionComponent<PaymentMethodProps> = ({ isShowText = true, payments }) => {

  const paymentsType = payments.map(payment => payment.type)

  const paymentIcon = computed(() => {
    if (paymentsType.includes("cash") && paymentsType.includes("card")) return <>
      <img className="relative w-8 h-8 object-contain" alt="" src="/iconorderhistorycash-card-icon@2x.png"/>
    </>
    return payments.map((payment, index) => {
      const iconSrc = getOrderPaymentIcon(payment)
      return (
        <Fragment key={index}>
          {iconSrc ?
            <img className="relative w-6 h-6 object-contain" alt="" src={iconSrc}/>
            : <b>{_.get(LL0().payment, payment.type)?.() || payment.type}</b>
          }
        </Fragment>

      )
    })
  })

  return (
    <div
      className="shrink-0 flex flex-row items-center justify-center gap-[4px] text-right text-sm text-green-solid-green-600-00c37c font-mulish-button-name-bold">
      {isShowText ? payments.map((payment, index) => {
        const currentPaymentIconSrc = getOrderPaymentIcon(payment);
        return (
          <div className="flex flex-row items-center justify-start gap-[4px]" key={index}>
            {index !== 0 && '+'}
            {currentPaymentIconSrc ?
              <img className="relative w-8 h-8 object-contain" alt="" src={currentPaymentIconSrc}/> :
              <b>{payment.type}</b>}
            <b className="relative"> {LL3().format.currency(payment.value)}</b>
          </div>
        );
      }) : paymentIcon()}
    </div>
  );
};

export default PaymentMethod;
