import {type FunctionComponent, useState, useCallback, useEffect, useRef} from "react";
import KitchenMonitorCardDetailP from "./KitchenMonitorCardDetailP";
import PortalPopup from "./PortalPopup";
import KitchenMonitorItem from "./KitchenMonitorItem";
import {heightCard, heightContainer, setHeightCard, type OrderFoods} from "@/react/KitchenMonitorView/KitchenMonitorView.tsx";
import clsx from "clsx";

type KitchenMonitorDisplayCardType = {
  timeline?: string;
  orderType?: string;
  orderTime?: string;
  orderID?: string;
  staff?: string;
  orderStatus?: "new" | "prepare" | "done";
  orderFoods?: OrderFoods[];
};

const KitchenMonitorDisplayCard: FunctionComponent<KitchenMonitorDisplayCardType> = (
  {
    timeline = "1 mins",
    orderType = "Table no. 3",
    orderTime = "3:00 PM",
    orderID = "#123454",
    staff = "Jennifer",
    orderStatus = "new",
    orderFoods
  }) => {

  const [isKitchenMonitorCardDetailPOpen, setKitchenMonitorCardDetailPOpen] =
    useState(false);

  const openKitchenMonitorCardDetailP = useCallback(() => {
    setKitchenMonitorCardDetailPOpen(true);
  }, []);

  const closeKitchenMonitorCardDetailP = useCallback(() => {
    setKitchenMonitorCardDetailPOpen(false);
  }, []);


  const refHeightCard = useRef<any>(null);
  const refHeightItem = useRef<any>(null);
  const [heightCardItem, setHeightCardItem] = useState(0);

  useEffect(() => {
    if (refHeightCard.current) {
      setHeightCard(refHeightCard.current.clientHeight);
    }
    if (refHeightItem.current) {
      setHeightCardItem(refHeightItem.current.clientHeight);
    }
  }, []);

  return (
    <>
      <div ref={refHeightCard}
           className={clsx("min-h-[209px] rounded bg-white-solid-white-100-ffffff w-[250px] overflow-hidden flex flex-col items-start justify-start relative gap-[8px] text-center text-mini text-black-solid-black-1000-000000 font-mulish",

           )}>
        <div
          className={`absolute my-0 mx-[!important] h-[calc(100%_+_1px)] w-[calc(100%_+_1px)] top-[-0.5px] right-[-0.5px] bottom-[-0.5px] left-[-0.5px] box-border z-[2] border-[1px] border-solid
             ${orderStatus === "new" ? "bg-orange-solid-orange-50-fff4ed border-orange-solid-orange-50-fff4ed"
            : orderStatus === "prepare" ? "bg-green-solid-green-50-f5fbec border-green-solid-green-50-f5fbec"
              : "bg-purple-solid-purple-50-f7f7ff border-purple-solid-purple-50-f7f7ff"}
          `}/>

        <div
          className="self-stretch flex flex-col items-start justify-start py-2 px-0 gap-[4px] z-[3] text-left text-smi border-b-[1px] border-solid border-gray-solid-gray-300-dbdbdb">
          <div className="self-stretch flex flex-row items-start justify-between py-0 px-2 text-mini">
            <div className="w-[33px] flex flex-row items-center justify-start gap-[4px]">
              <div className="relative leading-[19px] font-semibold">
                {orderStatus === "new" ? "New" : orderStatus === "prepare" ? "Preparing" : "Done"}
              </div>
            </div>
            <div className="relative leading-[19px] font-semibold text-right">
              {timeline}
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between py-0 px-2">
            <div className="relative leading-[16px]">{orderType}</div>
            <div className="flex flex-row items-center justify-start gap-[3px] text-right">
              <img
                className="relative w-[18px] h-[18px] object-cover"
                alt=""
                src="/iconclock-icon@2x.png"
              />
              <div className="relative leading-[16px]">{orderTime}</div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between py-0 px-2">
            <i className="relative leading-[16px]">{orderID}</i>
            <i className="relative leading-[16px] text-right">{staff}</i>
          </div>
        </div>
        <div ref={refHeightItem}
          className={clsx("self-stretch flex flex-col items-start justify-start relative z-[4] text-left text-sm text-red-solid-red-660-8f181d",
            heightCardItem / heightCard() > 0.8 && `h-[${0.5 * heightContainer() - 100}px] overflow-hidden`
          )}>
          {orderFoods?.map((item, index) => (
            <KitchenMonitorItem key={index} orderStatus={orderStatus} itemName={item.name} quantity={item.quantity} modifiers={item.modifiers} isChecked={false}/>
          ))}
          {heightCardItem / heightCard() > 0.8 && <div
              className="my-0 mx-[!important] absolute right-[0px] bottom-[0px] left-[0px] flex flex-row items-center justify-center pt-8 px-0 pb-1.5 box-border gap-[8px] cursor-pointer z-[21] text-smi text-blue-solid-blue-370-0094ff"
              onClick={openKitchenMonitorCardDetailP}
          >
              <div className={clsx("absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] z-[2]",
                orderStatus === "new" && "[background:linear-gradient(180deg,_rgba(255,_244,_237,_0.16),_#fff4ed_55.73%)]",
                orderStatus === "prepare" && "[background:linear-gradient(180deg,_rgba(245,_251,_236,_0.16),_#f5fbec_55.73%)]",
                orderStatus === "done" && "[background:linear-gradient(180deg,_rgba(247,_247,_255,_0.16),_#f7f7ff_55.73%)]"
                )}/>

              <b className="relative [text-decoration:underline] leading-[16px] z-[3]">
                  View All
              </b>
          </div>}
        </div>
        <div className={`self-stretch h-9 flex flex-row items-center justify-start py-0 px-2 box-border relative min-h-[36px] z-[7]
          ${orderStatus === "new" ? "bg-orange-solid-orange-400-ffa06a" : orderStatus === "prepare" ? "bg-green-solid-green-420-a9e15d" : "bg-purple-solid-purple-200-c0c0ff"}`}>
          <img
            className="relative w-6 h-6 object-cover z-[0]"
            alt=""
            src="/iconarchived-icon@2x.png"
          />
          <div
            className="absolute my-0 mx-[!important] top-[calc(50%_-_9.5px)] left-[calc(50%_-_31px)] font-semibold z-[1]">
            {orderStatus === "new" ? "Start" : orderStatus === "prepare" ? "Done" : "Archived"}
          </div>
        </div>
      </div>
      {isKitchenMonitorCardDetailPOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeKitchenMonitorCardDetailP}
        >
          <KitchenMonitorCardDetailP onClose={closeKitchenMonitorCardDetailP}/>
        </PortalPopup>
      )}
    </>
  );
};

export default KitchenMonitorDisplayCard;
