import uuid from 'time-uuid'

import type { Order } from '@/data/Order'
import { OrderStatus } from '@/pos/OrderType'
import { rnHost } from '@/shared/webview/rnwebview'

import { completeOrder } from '../order-mutations'
import { srmTransactionLogic } from '../transaction.logic'
import { TESTCASE_USERS } from './constants'
import { execTestcase, getCaTaxInfo, sleep } from './libs'

type O = Order

const getBaseOrder = (): O => {
  const taxInfo = getCaTaxInfo()
  const order: O = {
    _id: '',
    table: '000',
    status: OrderStatus.COMPLETED,
    payments: [{ type: 'cash', value: 0 }],
    items: [
      { name: 'Samosa', price: 20, quantity: 2, modifiers: [], ...taxInfo },
      { name: 'Garlic Bread', price: 10, quantity: 3, modifiers: [], ...taxInfo },
    ],
  }
  return order
}

export const testRunner009: Record<string, () => Promise<boolean>> = {
  '009.001': () =>
    execTestcase({
      num: 9,
      step: 1,
      device: 'A',
      run: async () => {
        const o1: O = { ...getBaseOrder(), _id: uuid(), table: undefined }
        const o2: O = { ...getBaseOrder(), _id: uuid(), table: undefined }

        await rnHost.switchOfflineMode('error')
        await srmTransactionLogic.recordClosingReceipt(o1, { print: true })
        await sleep(1100)
        await rnHost.switchOfflineMode('off')
        await sleep(1100)
        srmTransactionLogic.options.impersonate = TESTCASE_USERS.John
        await srmTransactionLogic.recordClosingReceipt(o2, { print: true })
      },
    }),
  '009.005': () =>
    execTestcase({
      num: 9,
      step: 5,
      device: 'A',
      run: async () => {
        const o1: O = { ...getBaseOrder(), _id: uuid(), table: undefined }
        const o2: O = { ...getBaseOrder(), _id: uuid(), table: undefined }
        const o3: O = { ...getBaseOrder(), _id: uuid(), table: undefined }

        await rnHost.switchOfflineMode('error')
        await sleep(1100)
        await srmTransactionLogic.recordClosingReceipt(o1, { print: true })
        await sleep(1100)
        await srmTransactionLogic.recordClosingReceipt(o2, { print: true })
        await sleep(1100)
        await srmTransactionLogic.recordClosingReceipt(o3, { print: true })
        await sleep(1100)
        await rnHost.switchOfflineMode('off')
        await sleep(1100)
        await srmTransactionLogic.sendOfflineTransactions()
      },
    }),
  '009.006': () =>
    execTestcase({
      num: 9,
      step: 6,
      device: 'A',
      run: async () => {
        const o1: O = { ...getBaseOrder(), _id: uuid(), table: '600', status: OrderStatus.IN_PROGRESS, payments: [] }
        const o2: O = { ...getBaseOrder(), _id: uuid(), table: '600', status: OrderStatus.IN_PROGRESS, payments: [] }

        await rnHost.switchOfflineMode('error')
        await sleep(1100)
        await srmTransactionLogic.recordTemporaryBill(o1, { print: true })
        await sleep(1100)
        await srmTransactionLogic.recordClosingReceipt(completeOrder(o1), { print: true })
        await sleep(1100)
        await srmTransactionLogic.recordTemporaryBill(o2, { print: true })
        await sleep(1100)
        await rnHost.switchOfflineMode('off')
        await srmTransactionLogic.recordClosingReceipt(completeOrder(o2), { print: true })
      },
    }),
  '009.007': () =>
    execTestcase({
      num: 9,
      step: 7,
      device: 'C',
      run: async () => {
        const o1: O = { ...getBaseOrder(), _id: uuid(), table: undefined }
        const o2: O = { ...getBaseOrder(), _id: uuid(), table: undefined }

        await rnHost.switchOfflineMode('error')
        await sleep(1100)
        await srmTransactionLogic.recordClosingReceipt(o1, { print: true })
        await sleep(1100)
        await rnHost.switchOfflineMode('off')
        await srmTransactionLogic.recordClosingReceipt(o2, { print: true })
      },
    }),
  '009.009': () =>
    execTestcase({
      num: 9,
      step: 9,
      device: 'C',
      run: async () => {
        const o1: O = { ...getBaseOrder(), _id: uuid(), table: undefined }
        const o2: O = { ...getBaseOrder(), _id: uuid(), table: undefined }

        await rnHost.switchOfflineMode('error')
        await sleep(1100)
        await srmTransactionLogic.recordTemporaryBill(o1, { print: true })
        await sleep(1100)
        await srmTransactionLogic.recordClosingReceipt(completeOrder(o1), { print: true })
        await sleep(1100)
        await srmTransactionLogic.recordTemporaryBill(o2, { print: true })
        await sleep(1100)
        await rnHost.switchOfflineMode('off')
        await srmTransactionLogic.recordClosingReceipt(completeOrder(o2), { print: true })
      },
    }),
}
