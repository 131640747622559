import type { FunctionComponent } from "react";
import TaxPaymentLeftPath from "./TaxPaymentLeftPath";
import TaxPaymentRightPath from "./TaxPaymentRightPath";

const TaxAndPaymentSettingPlugin: FunctionComponent = () => {
  return (
    <div className="payment-setting-screen w-full h-full bg-white-solid-white-100-ffffff flex flex-row items-start justify-start">
      <TaxPaymentLeftPath />
      <div className="self-stretch w-px relative bg-white-solid-white-100-ffffff box-border overflow-hidden shrink-0 border-[0.5px] border-solid border-darkgray" />
      <TaxPaymentRightPath />
    </div>
  );
};

export default TaxAndPaymentSettingPlugin;
