import HappyHourPlugin from '@happy-hour/HappyHourPlugin.tsx';
import {PosScreen, router} from '@/pos/PosRouter.ts';
import { computed, deepSignal, onMount, signal } from '@/react/core/reactive.ts';
import {happyHours0, makeHappyHoursAvailable, setHappyHourV} from '@/data/HappyHourHub.ts';
import {HappyHour} from '@/data/HappyHour.ts';
import dayjs from 'dayjs';
import uuid from 'time-uuid';
import type {DocDeepSignal} from '@/data/data-utils.ts';
import {clone} from 'json-fn';
import _ from 'lodash';
import {memo} from "react";
import {LL0} from "@/react/core/I18nService.tsx";
import { products0, selectedModifier } from '@/react/EditMenuView/EditMenuView.tsx';
import { mainScreen } from "@/data/PosSettingsSignal.ts";

export const discountTypeLabels = {
  percent: {
    itemLabel: "Percentage",
    valueLabel: "- %"
  },
  amount:{
    itemLabel: "Amount",
    valueLabel: "- $"
  },
  fixed: {
    itemLabel: "Fixed price",
    valueLabel: "= $"
  }
}

export const [happyHourId0, setHappyHourId0] = signal<string | undefined>()
export const happyHour0 = computed(() => happyHours0().find(happyHour => happyHour._id ===  happyHourId0()))

// Sunday label is always first, since weekdays order in design is Mon -> Sun
export const getHappyHourWeekdays = () => {
  const [sundayLabel, ...rest] = dayjs.weekdays();
  return [...rest, sundayLabel];
};
const getHappyHourWeekdaysShortened = (index: number) => getHappyHourWeekdays()?.[index]?.slice(0, 3)

const continuousArraySplitter = (array: number[]): (number[])[] => {
  const returnValue = [];
  let tempArray = []
  const minElement = _.min(array);
  const maxElement = _.max(array);
  if (minElement === undefined || maxElement ===  undefined) return [];
  for (let i = minElement; i <= maxElement; i++) {
    if (array.includes(i)) {
      tempArray.push(i);
      continue;
    }
    if (tempArray.length) {
      returnValue.push(tempArray);
      tempArray = [];
    }
  }
  returnValue.push(tempArray);
  return returnValue;
}

export const weekDayPeriodConverter = (weekDayIndexes: number[]) => {
  //get chunks of weekdays, then convert it to string
  return continuousArraySplitter(weekDayIndexes).map(chunkArray => {
    if (chunkArray.length === 0) return "--";
    if (chunkArray.length === 1) return _.get(LL0().onlineOrder.weekday, getHappyHourWeekdaysShortened(chunkArray[0])?.toLowerCase())?.();
    return _.get(LL0().onlineOrder.weekday, getHappyHourWeekdaysShortened(chunkArray[0])?.toLowerCase())?.() + " - " + _.get(LL0().onlineOrder.weekday, getHappyHourWeekdaysShortened(chunkArray[chunkArray.length - 1])?.toLowerCase())?.()
    // return getHappyHourWeekdaysShortened(chunkArray[0]) + " - " +
    //   getHappyHourWeekdaysShortened(chunkArray[chunkArray.length - 1]) ;
  }).join(", ")
}

export const createNewHappyHour = async () => {
  const _happyHour: DocDeepSignal<HappyHour> = deepSignal( {
    "_id": uuid(),
    "days": [],
    "from": "00:00",
    "to": "00:00",
    "discountValue": "",
    "discountType": "percent"
  })
  _happyHour.doc = await HappyHour.insert(clone(_happyHour));
  setHappyHourId0(_happyHour._id);
}

export const deleteHappyHour = async (happyHourDoc: DocDeepSignal<HappyHour>) => {
  products0().forEach(product => {
    if (!product.get().happyHours?.find(happyHour => happyHour === happyHourDoc?._id)) {
      return;
    }
    product.get().happyHours = product.get().happyHours!
      .filter(happyHour => happyHour !== happyHourDoc?._id);
  })

  await happyHourDoc?.doc?.incrementalRemove();
  // refresh happy-hour list
  setHappyHourV(v => v + 1);
  setHappyHourId0(happyHours0()?.[0]?._id);
}

export const onBack = () => {
  router.screen = mainScreen();
}

const HappyHourView = () => {
  makeHappyHoursAvailable();

  onMount(() => {
    setHappyHourId0(happyHours0()?.[0]?._id);
  })

  return <HappyHourPlugin />
}

export default memo(HappyHourView)