import _ from 'lodash'

import { UserAction, type UserActionHistory } from '@/data/UserActionHistory.ts'
import { LL3 } from '@/react/core/I18nCurrency.tsx'
import { LL0 } from '@/react/core/I18nService.tsx'

export function generateUserActionMessages(userActions: UserActionHistory[]): string[] {
  const payments = (data1: string) => _.get(LL0().payment, data1)?.() || data1
  return userActions.map(action => {
    const { kind, data } = action
    const messages: Array<string | number | undefined> = []

    switch (kind) {
      case UserAction.ADD_ITEM:
        messages.push(LL0().srm.userAction.addItem({ nameProduct: data?.productRef?.name || '', priceProduct: LL3().format.currency(data?.productRef?.price || 0) }))
        if (data?.quantity) messages.push(LL0().srm.userAction.andQuantity(), data.quantity)
        break

      case UserAction.CANCEL_ADD_ITEM:
        messages.push(LL0().srm.userAction.cancelAddItem({ nameProduct: data?.productRef?.name || '' }))
        break

      case UserAction.DELETE_ITEM:
        messages.push(LL0().srm.userAction.deleteItem({ nameProduct: data?.productRef?.name || '' }))
        break

      case UserAction.CANCEL_DELETE_ITEM:
        messages.push(LL0().srm.userAction.cancelDeleteItem({ nameProduct: data?.productRef?.name || '' }))
        break

      case UserAction.INCREASE_QUANTITY:
        messages.push(LL0().srm.userAction.increaseQuantity({ nameProduct: data?.productRef?.name || '', quantity: data?.quantity || 0 }))
        break

      case UserAction.DECREASE_QUANTITY:
        messages.push(LL0().srm.userAction.decreaseQuantity({ nameProduct: data?.productRef?.name || '', quantity: data?.quantity || 0 }))
        break

      case UserAction.ADD_MODIFIER:
        messages.push(
          LL0().srm.userAction.addModifier({
            modifierProduct: data?.productRef?.modifierName,
            modifierPrice: LL3().format.currency(data?.productRef?.modifierPrice || 0),
            nameProduct: data?.productRef?.name || '',
          })
        )
        break

      case UserAction.CHANGE_DISCOUNT_ORDER:
        messages.push(LL0().srm.userAction.changeDiscountOrder({ valueFrom: data?.valueFrom, valueTo: data?.valueTo }))
        break

      case UserAction.CLEAR_DISCOUNT_ORDER:
        messages.push(LL0().srm.userAction.clearDiscountOrder())
        break
      case UserAction.CANCEL_CHANGE_DISCOUNT_ORDER:
        messages.push(
          LL0().srm.userAction.cancelChangeDiscountOrder({
            valueFrom: data?.valueFrom ? `${LL0().onlineOrder.from()} ${data.valueFrom}` : '',
            valueTo: data?.valueTo ? `${LL0().onlineOrder.to()} ${data.valueTo}` : '',
          })
        )
        break

      case UserAction.CHANGE_DISCOUNT_ITEM:
        messages.push(LL0().srm.userAction.changeDiscountItem({ nameProduct: data?.productRef?.name || '', valueFrom: data?.valueFrom, valueTo: data?.valueTo }))
        break

      case UserAction.CANCEL_CHANGE_DISCOUNT_ITEM:
        messages.push(LL0().srm.userAction.cancelChangeDiscountItem({ nameProduct: data?.productRef?.name || '', valueFrom: data?.valueFrom, valueTo: data?.valueTo }))
        break

      case UserAction.CHANGE_ITEM_INFO:
        messages.push(
          LL0().srm.userAction.changeItemInfo({
            productOldName: data?.productRef?.oldName || '',
            productOldPrice: LL3().format.currency(data?.productRef?.oldPrice || 0),
            nameProduct: data?.productRef?.name || LL0().srm.userAction.newItem(),
            productNewPrice: LL3().format.currency(data?.productRef?.price || 0),
          })
        )
        break

      case UserAction.CANCEL_CHANGE_ITEM_INFO:
        messages.push(LL0().srm.userAction.cancelChangeItemInfo({ nameProduct: data?.productRef?.name || '', productPrice: LL3().format.currency(data?.productRef?.price || 0) }))
        break

      case UserAction.CHANGE_PAYMENT:
        messages.push(
          LL0().srm.userAction.changePayment({
            paymentName: payments(data?.paymentName || '') || LL0().srm.userAction.unknownPayment(),
            valueFrom: LL3().format.currency(Number(data?.valueFrom ?? 0)),
            valueTo: LL3().format.currency(Number(data?.valueTo)),
            oldPayment: payments(data?.oldPayment || '') ? `(${payments(data?.oldPayment || '')})` : ``,
          })
        )
        break

      case UserAction.REFUND_ITEM:
        messages.push(LL0().srm.userAction.refundItem({ nameProduct: data?.productRef?.name || '', productPrice: LL3().format.currency(data?.productRef?.price || 0) }))
        break

      case UserAction.REFUND_AMOUNT:
        messages.push(LL0().srm.userAction.refundAmount({ valueTo: data?.valueTo || 0 }))
        break

      case UserAction.CANCEL_REFUND:
        messages.push(LL0().srm.userAction.cancelRefund())
        break

      case UserAction.CANCEL_PAYMENT:
        messages.push(LL0().srm.userAction.cancelPayment())
        break

      case UserAction.CANCEL_TIP:
        messages.push(LL0().srm.userAction.cancelTip())
        break

      case UserAction.CANCEL_SERVICE_FEE:
        messages.push(LL0().srm.userAction.cancelServiceFee())
        break

      case UserAction.MOVE_TO_SEAT:
        messages.push(`${LL0().orderHistory.move()} ${data?.productRef?.name} (${data?.productRef?.price}$) ${LL0().srm.userAction.toSeat()} ${Number(data?.seat) + 1}`)
        break

      case UserAction.CANCEL_MOVE_TO_SEAT:
        messages.push(`${LL0().srm.userAction.cancelMove()} ${data?.productRef?.name} ${LL0().srm.userAction.toSeat()} ${data!.seat! + 1}`)
        break

      case UserAction.CHANGE_SEAT_QUANTITY:
        messages.push(`${LL0().srm.userAction.changeSeatQuantityFrom()} ${data?.oldQuantity} ${LL0().report.to()} ${data?.quantity}`)
        break

      case UserAction.CHANGE_TIP:
        messages.push(LL0().srm.userAction.changeTip({ valueFrom: LL3().format.currency(Number(data?.valueFrom ?? 0)), valueTo: LL3().format.currency(Number(data?.valueTo)) }))
        if (data?.seat != null) messages.push(`${LL0().srm.userAction.forSeat()} ${data.seat! + 1}`)
        break

      case UserAction.CHANGE_SERVICE_FEE:
        messages.push(LL0().srm.userAction.changeServiceFee({ valueFrom: LL3().format.currency(Number(data?.valueFrom ?? 0)), valueTo: LL3().format.currency(Number(data?.valueTo)) }))
        if (data?.seat != null) messages.push(`${LL0().srm.userAction.forSeat()} ${data.seat! + 1}`)
        break

      case UserAction.SPLIT_ITEM: // split item samosa (seat 2) to 3 part (each part has price = 4$
        messages.push(
          `${LL0().order.splitItem()} ${data!.productRef!.name} ${data!.seat ? `(${LL0().payment.seat()} ${data!.seat + 1})` : ''} ${LL0().report.to()} ${data!.parts} ${LL0().srm.userAction.parts()}`
        )
        if (data!.quantity != null) messages.push(`(${LL0().srm.userAction.eachPartHasQuantity()} ${data!.quantity})`)
        if (data!.productRef?.price) messages.push(`(${LL0().srm.userAction.eachPartHasPrice()} ${data!.productRef?.price}$)`)
        break

      case UserAction.CANCEL_SPLIT_ITEM:
        messages.push(`${LL0().srm.userAction.cancelledSplitItem()} ${data!.productRef!.name}`)
        break

      case UserAction.CANCEL_CHANGE_SEAT_MODE:
        messages.push(LL0().srm.userAction.cancelledSplitBill())
        break

      case UserAction.CHANGE_SEAT_MODE:
        if (data?.seatMode === 'split') messages.push(LL0().srm.userAction.enableSplitBill())
        else messages.push(LL0().srm.userAction.cancelledSplitBill())
        break

      case UserAction.TOGGLE_MULTI_PAYMENT:
        if (data?.paymentMode === 'multi') messages.push(LL0().srm.userAction.enableMultiPayment())
        else messages.push(LL0().srm.userAction.disableMultiPayment())
        break

      default:
        messages.push(LL0().srm.userAction.defaultMess())
    }
    if (action?.transNo) messages.push(LL0().srm.userAction.messageTransaction({ transId: action?.transNo }))
    return messages.filter(Boolean).join(' ')
  })
}
