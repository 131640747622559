import type { FunctionComponent } from "react";

export type OrderScreenFastFoodRestaurantType = {
  className?: string;
  onClose?: () => void;
};

const OrderScreenFastFoodRestaurant: FunctionComponent<
  OrderScreenFastFoodRestaurantType
> = ({ className = "", onClose }) => {
  return (
    <div
      className={`w-full h-full relative [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.98),_rgba(232,_238,_241,_0.8)),_rgba(0,_0,_0,_0.6)] overflow-hidden flex flex-row items-start justify-center pt-[34px] px-2.5 pb-2.5 box-border gap-2.5 min-w-[460px] max-w-full max-h-full text-left text-base text-white-solid-white-100-ffffff font-mulish ${className}`}
    >
      <img
        className="w-[25px] absolute !m-[0] top-[10px] right-[10px] h-[25.4px] cursor-pointer z-[0]"
        alt=""
        src="/close-tab.svg"
        onClick={onClose}
      />
      <div className="self-stretch flex-1 flex flex-col items-center justify-start gap-1.5 z-[1]">
        <div className="self-stretch flex flex-col items-start justify-start gap-1">
          <div className="self-stretch flex flex-row items-start justify-start gap-1">
            <div className="flex-1 rounded-10xs [background:linear-gradient(170.9deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#005fed] flex flex-col items-start justify-center py-[3px] px-1.5 relative mq320:gap-0.5 mq320:p-1 mq320:box-border">
              <div className="flex flex-row items-start justify-start gap-1 z-[0]">
                <div className="relative font-semibold Mobile_480:text-2xs mq320:text-3xs">
                  Total
                </div>
                <div className="relative font-semibold Mobile_480:text-2xs mq320:text-3xs">
                  10%
                </div>
              </div>
              <b className="self-stretch relative text-xl text-orange-solid-orange-200-ffc163 z-[1] Mobile_480:text-xs Mobile_480:self-stretch Mobile_480:w-auto mq320:text-2xs">
                $100.00
              </b>
              <img
                className="w-[21px] absolute !m-[0] top-[7px] right-[6px] h-[21px] object-cover hidden z-[2] Mobile_480:w-4 Mobile_480:h-4"
                alt=""
                src="/iconpaymentverticaldownarrow@2x.png"
              />
            </div>
            <div className="flex-1 rounded-10xs [background:linear-gradient(170.9deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#005fed] flex flex-col items-start justify-center py-[3px] px-1.5 relative mq320:gap-0.5 mq320:p-1 mq320:box-border">
              <div className="flex flex-row items-start justify-start gap-1 z-[0]">
                <div className="relative font-semibold Mobile_480:text-2xs mq320:text-3xs">
                  Change
                </div>
                <div className="relative font-semibold Mobile_480:text-2xs mq320:text-3xs">
                  10%
                </div>
              </div>
              <b className="self-stretch relative text-xl text-pink-solid-pink-150-ffa8ff z-[1] Mobile_480:text-xs Mobile_480:self-stretch Mobile_480:w-auto mq320:text-2xs mq320:self-stretch mq320:w-auto">
                -$0.00
              </b>
              <img
                className="w-[21px] absolute !m-[0] top-[7px] right-[6px] h-[21px] object-cover hidden z-[2] Mobile_480:w-4 Mobile_480:h-4"
                alt=""
                src="/iconpaymentverticaldownarrow@2x.png"
              />
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start gap-1">
            <div className="flex-1 rounded-10xs [background:linear-gradient(170.9deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#005fed] flex flex-col items-start justify-center py-[3px] px-1.5 relative mq320:gap-0.5 mq320:p-1 mq320:box-border">
              <div className="flex flex-row items-start justify-start gap-1 z-[0]">
                <div className="relative font-semibold Mobile_480:text-2xs mq320:text-3xs">
                  Tip
                </div>
                <div className="relative font-semibold Mobile_480:text-2xs mq320:text-3xs">
                  10%
                </div>
              </div>
              <b className="self-stretch relative text-xl text-green-solid-green-250-5effa3 z-[1] Mobile_480:text-xs Mobile_480:self-stretch Mobile_480:w-auto mq320:text-2xs mq320:self-stretch mq320:w-auto">
                10%:$10
              </b>
              <img
                className="w-[21px] absolute !m-[0] top-[7px] right-[6px] h-[21px] object-cover z-[2] Mobile_480:w-4 Mobile_480:h-4"
                alt=""
                src="/iconpaymentverticaldownarrow@2x.png"
              />
            </div>
            <div className="flex-1 rounded-10xs [background:linear-gradient(170.9deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#005fed] flex flex-col items-start justify-center py-[3px] px-1.5 relative mq320:gap-0.5 mq320:p-1 mq320:box-border">
              <div className="flex flex-row items-start justify-start gap-1 z-[0]">
                <div className="relative font-semibold Mobile_480:text-2xs mq320:text-3xs">
                  Fees
                </div>
                <div className="relative font-semibold Mobile_480:text-2xs mq320:text-3xs">
                  10%
                </div>
              </div>
              <b className="self-stretch relative text-xl text-cyan-solid-cyan-280-80e0ff z-[1] Mobile_480:text-xs Mobile_480:self-stretch Mobile_480:w-auto mq320:text-2xs mq320:self-stretch mq320:w-auto">
                $110.00
              </b>
              <img
                className="w-[21px] absolute !m-[0] top-[7px] right-[6px] h-[21px] object-cover z-[2] Mobile_480:w-4 Mobile_480:h-4"
                alt=""
                src="/iconpaymentverticaldownarrow@2x.png"
              />
            </div>
          </div>
        </div>
        <div className="self-stretch flex-1 relative overflow-y-auto grid grid-rows-[repeat(5,1fr)] grid-cols-[repeat(2,1fr)] [grid-row-gap:4px] [grid-column-gap:4px] text-center text-black-solid-black-880-1d1d26">
          <div className="shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid flex flex-row items-center justify-center py-0 px-1 relative gap-2 col-[1/1] row-[1/1] Mobile_480:col-[1/1] Mobile_480:row-[1/1] mq320:col-[1/1] mq320:row-[1/1]">
            <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs [background:linear-gradient(180deg,_#deffec,_#5ef29d)] hidden z-[0]" />
            <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs [background:linear-gradient(180deg,_#daffff,_#83dede)] z-[1]" />
            <img
              className="w-[34px] relative h-[34px] object-cover z-[2] Mobile_480:flex Mobile_480:w-8 Mobile_480:h-8 mq320:w-8 mq320:h-8"
              alt=""
              src="/iconcard-icon@2x.png"
            />
            <div className="relative font-semibold z-[3] Mobile_480:text-mini mq320:text-sm">
              Cash
            </div>
          </div>
          <div className="shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid flex flex-row items-center justify-center py-0 px-1 relative gap-2 col-[2/2] row-[1/1] Mobile_480:col-[2/2] Mobile_480:row-[1/1] mq320:col-[2/2] mq320:row-[1/1]">
            <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs [background:linear-gradient(180deg,_#deffec,_#5ef29d)] hidden z-[0]" />
            <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs [background:linear-gradient(180deg,_#daffff,_#83dede)] hidden z-[1]" />
            <img
              className="w-[34px] relative h-[34px] object-cover z-[2] Mobile_480:flex Mobile_480:w-8 Mobile_480:h-8 mq320:w-8 mq320:h-8"
              alt=""
              src="/iconcard-icon1@2x.png"
            />
            <div className="relative font-semibold z-[3] Mobile_480:text-mini mq320:text-sm">
              Card
            </div>
          </div>
          <div className="shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid flex flex-row items-center justify-center py-0 px-1 relative gap-2 col-[1/1] row-[2/2] Mobile_480:col-[1/1] Mobile_480:row-[2/2] mq320:col-[1/1] mq320:row-[2/2]">
            <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs [background:linear-gradient(180deg,_#deffec,_#5ef29d)] hidden z-[0]" />
            <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs [background:linear-gradient(180deg,_#daffff,_#83dede)] hidden z-[1]" />
            <img
              className="w-[34px] relative h-[34px] object-cover z-[2] Mobile_480:flex Mobile_480:w-8 Mobile_480:h-8 mq320:w-8 mq320:h-8"
              alt=""
              src="/iconcard-icon2@2x.png"
            />
            <div className="relative font-semibold z-[3] Mobile_480:text-mini mq320:text-sm">
              Card
            </div>
          </div>
          <div className="shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid flex flex-row items-center justify-center py-0 px-1 relative gap-2 col-[2/2] row-[2/2] Mobile_480:col-[2/2] Mobile_480:row-[2/2] mq320:col-[2/2] mq320:row-[2/2]">
            <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs [background:linear-gradient(180deg,_#deffec,_#5ef29d)] hidden z-[0]" />
            <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs [background:linear-gradient(180deg,_#daffff,_#83dede)] hidden z-[1]" />
            <img
              className="w-[34px] relative h-[34px] object-cover z-[2] Mobile_480:flex Mobile_480:w-8 Mobile_480:h-8 mq320:w-8 mq320:h-8"
              alt=""
              src="/iconcard-icon3@2x.png"
            />
            <div className="relative font-semibold z-[3] Mobile_480:text-mini mq320:text-sm">
              Card
            </div>
          </div>
          <div className="shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid flex flex-row items-center justify-center py-0 px-1 relative gap-2 col-[1/1] row-[3/3] Mobile_480:col-[1/1] Mobile_480:row-[3/3] mq320:col-[1/1] mq320:row-[3/3]">
            <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs [background:linear-gradient(180deg,_#deffec,_#5ef29d)] hidden z-[0]" />
            <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs [background:linear-gradient(180deg,_#daffff,_#83dede)] hidden z-[1]" />
            <img
              className="w-[34px] relative h-[34px] object-cover z-[2] Mobile_480:flex Mobile_480:w-8 Mobile_480:h-8 mq320:w-8 mq320:h-8"
              alt=""
              src="/iconcard-icon4@2x.png"
            />
            <div className="relative font-semibold z-[3] Mobile_480:text-mini mq320:text-sm">
              Card
            </div>
          </div>
          <div className="shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid flex flex-row items-center justify-center py-0 px-1 relative gap-2 col-[2/2] row-[3/3] Mobile_480:col-[2/2] Mobile_480:row-[3/3] mq320:col-[2/2] mq320:row-[3/3]">
            <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs [background:linear-gradient(180deg,_#deffec,_#5ef29d)] hidden z-[0]" />
            <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs [background:linear-gradient(180deg,_#daffff,_#83dede)] hidden z-[1]" />
            <img
              className="w-[34px] relative h-[34px] object-cover z-[2] Mobile_480:flex Mobile_480:w-8 Mobile_480:h-8 mq320:w-8 mq320:h-8"
              alt=""
              src="/iconcard-icon5@2x.png"
            />
            <div className="relative font-semibold z-[3] Mobile_480:text-mini mq320:text-sm">
              Sodexo
            </div>
          </div>
          <div className="shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid flex flex-row items-center justify-center py-0 px-1 relative gap-2 col-[1/1] row-[4/4] Mobile_480:col-[1/1] Mobile_480:row-[4/4] mq320:col-[1/1] mq320:row-[4/4]">
            <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs [background:linear-gradient(180deg,_#deffec,_#5ef29d)] hidden z-[0]" />
            <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs [background:linear-gradient(180deg,_#daffff,_#83dede)] hidden z-[1]" />
            <img
              className="w-[34px] relative h-[34px] object-cover z-[2] Mobile_480:flex Mobile_480:w-8 Mobile_480:h-8 mq320:w-8 mq320:h-8"
              alt=""
              src="/iconcard-icon6@2x.png"
            />
            <div className="relative font-semibold z-[3] Mobile_480:text-mini mq320:text-sm">
              Card
            </div>
          </div>
          <div className="shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid flex flex-row items-center justify-center py-0 px-1 relative gap-2 col-[2/2] row-[4/4] Mobile_480:col-[2/2] Mobile_480:row-[4/4] mq320:col-[2/2] mq320:row-[4/4]">
            <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs [background:linear-gradient(180deg,_#deffec,_#5ef29d)] hidden z-[0]" />
            <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs [background:linear-gradient(180deg,_#daffff,_#83dede)] hidden z-[1]" />
            <img
              className="w-[34px] relative h-[34px] object-cover z-[2] Mobile_480:flex Mobile_480:w-8 Mobile_480:h-8 mq320:w-8 mq320:h-8"
              alt=""
              src="/iconcard-icon7@2x.png"
            />
            <div className="relative font-semibold z-[3] Mobile_480:text-mini mq320:text-sm">
              Card
            </div>
          </div>
          <div className="shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid flex flex-row items-center justify-center py-0 px-1 relative gap-2 col-[1/1] row-[5/5] Mobile_480:col-[1/1] Mobile_480:row-[5/5] mq320:col-[1/1] mq320:row-[5/5]">
            <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs [background:linear-gradient(180deg,_#deffec,_#5ef29d)] hidden z-[0]" />
            <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs [background:linear-gradient(180deg,_#daffff,_#83dede)] hidden z-[1]" />
            <img
              className="w-[34px] relative h-[34px] object-cover z-[2] Mobile_480:flex Mobile_480:w-8 Mobile_480:h-8 mq320:w-8 mq320:h-8"
              alt=""
              src="/iconcard-icon8@2x.png"
            />
            <div className="relative font-semibold z-[3] Mobile_480:text-mini mq320:text-sm">
              WC 0.5
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch w-[272px] flex flex-col items-center justify-start pt-px px-0 pb-0 box-border gap-0.5 z-[2] text-right text-green-solid-green-700-1c974d font-verdana">
        <div className="self-stretch h-[22px] overflow-hidden shrink-0 flex flex-row items-center justify-center gap-2">
          <div className="flex flex-row items-center justify-start gap-0.5">
            <img
              className="w-10 relative h-10 object-cover Mobile_480:w-9 Mobile_480:h-9 mq320:w-7 mq320:h-7"
              alt=""
              src="/iconcash-icon@2x.png"
            />
            <b className="relative Mobile_480:text-sm mq320:text-sm">$223.00</b>
          </div>
          <div className="flex flex-row items-center justify-start gap-0.5 text-yellow-solid-yellow-700-da9001">
            <img
              className="w-10 relative h-10 object-cover Mobile_480:w-9 Mobile_480:h-9 mq320:w-7 mq320:h-7"
              alt=""
              src="/iconcard-icon9@2x.png"
            />
            <b className="relative Mobile_480:text-sm mq320:text-sm">$300.00</b>
          </div>
          <div className="hidden flex-row items-center justify-start text-black-solid-black-1000-000000">
            <b className="relative Mobile_480:text-sm mq320:text-sm">$300.00</b>
          </div>
        </div>
        <div className="gap-[4px] self-stretch flex-1 flex flex-col items-center justify-between text-center text-xl text-black-solid-black-900-1e1e23 font-mulish Mobile_480:max-w-[200px]">
          <div className="self-stretch shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs bg-gray-solid-gray-350-ced1d7 flex flex-row items-center justify-start py-[3px] px-3 relative gap-2 text-left Mobile_480:h-11">
            <div className="w-[calc(100%_+_1.5px)] absolute !m-[0] h-[calc(100%_+_1.5px)] top-[-0.7px] right-[-0.8px] bottom-[-0.8px] left-[-0.7px] rounded-10xs border-cyan-solid-cyan-250-8aeaff border-[1.5px] border-solid box-border z-[0]" />
            <div className="flex-1 relative font-semibold z-[1] mq320:text-mini">
              0
            </div>
            <div className="flex flex-row items-center justify-end gap-5 z-[2]">
              <img
                className="w-[22px] relative h-[22px] object-cover mq320:w-[18px] mq320:h-[18px]"
                alt=""
                src="/icon-close-popup-icon--grey@2x.png"
              />
              <div className="flex flex-col items-start justify-start relative">
                <img
                  className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover z-[0]"
                  alt=""
                  src="/iconpaymentverticalmultiple-yellow@2x.png"
                />
                <img
                  className="w-[40.7px] relative h-[33px] object-cover z-[1]"
                  alt=""
                  src="/iconpaymentverticalmultiple-red@2x.png"
                />
              </div>
            </div>
          </div>
          <div className="self-stretch h-[238px] relative grid grid-rows-[repeat(5,1fr)] grid-cols-[repeat(3,1fr)] [grid-row-gap:3px] [grid-column-gap:3px] text-4xl">
            <div className="shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid flex flex-row items-center justify-center relative gap-2.5 col-[1/1] row-[1/1]">
              <div className="w-[calc(100%_+_0.8px)] absolute !m-[0] h-[calc(100%_+_0.4px)] top-[-0.2px] right-[-0.6px] bottom-[-0.2px] left-[-0.2px] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid box-border hidden z-[0]" />
              <b className="flex-1 relative z-[1] Mobile_480:text-xl mq320:text-lg">
                7
              </b>
            </div>
            <div className="shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid flex flex-row items-center justify-center relative gap-2.5 col-[2/2] row-[1/1]">
              <div className="w-[calc(100%_+_0.8px)] absolute !m-[0] h-[calc(100%_+_0.4px)] top-[-0.2px] right-[-0.6px] bottom-[-0.2px] left-[-0.2px] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid box-border hidden z-[0]" />
              <b className="flex-1 relative z-[1] Mobile_480:text-xl mq320:text-lg">
                8
              </b>
            </div>
            <div className="shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid flex flex-row items-center justify-center relative gap-2.5 col-[3/3] row-[1/1]">
              <div className="w-[calc(100%_+_0.8px)] absolute !m-[0] h-[calc(100%_+_0.4px)] top-[-0.2px] right-[-0.6px] bottom-[-0.2px] left-[-0.2px] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid box-border hidden z-[0]" />
              <b className="flex-1 relative z-[1] Mobile_480:text-xl mq320:text-lg">
                9
              </b>
            </div>
            <div className="shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid flex flex-row items-center justify-center relative gap-2.5 col-[1/1] row-[2/2]">
              <div className="w-[calc(100%_+_0.8px)] absolute !m-[0] h-[calc(100%_+_0.4px)] top-[-0.2px] right-[-0.6px] bottom-[-0.2px] left-[-0.2px] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid box-border hidden z-[0]" />
              <b className="flex-1 relative z-[1] Mobile_480:text-xl mq320:text-lg">
                4
              </b>
            </div>
            <div className="shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid flex flex-row items-center justify-center relative gap-2.5 col-[2/2] row-[2/2]">
              <div className="w-[calc(100%_+_0.8px)] absolute !m-[0] h-[calc(100%_+_0.4px)] top-[-0.2px] right-[-0.6px] bottom-[-0.2px] left-[-0.2px] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid box-border hidden z-[0]" />
              <b className="w-12 relative flex items-center justify-center shrink-0 z-[1] Mobile_480:text-xl mq320:text-lg">
                5
              </b>
            </div>
            <div className="shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid flex flex-row items-center justify-center relative gap-2.5 col-[3/3] row-[2/2]">
              <div className="w-[calc(100%_+_0.8px)] absolute !m-[0] h-[calc(100%_+_0.4px)] top-[-0.2px] right-[-0.6px] bottom-[-0.2px] left-[-0.2px] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid box-border hidden z-[0]" />
              <b className="w-12 relative flex items-center justify-center shrink-0 z-[1] Mobile_480:text-xl mq320:text-lg">
                6
              </b>
            </div>
            <div className="shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid flex flex-row items-center justify-center relative gap-2.5 col-[1/1] row-[3/3]">
              <div className="w-[calc(100%_+_0.8px)] absolute !m-[0] h-[calc(100%_+_0.4px)] top-[-0.2px] right-[-0.6px] bottom-[-0.2px] left-[-0.2px] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid box-border hidden z-[0]" />
              <b className="w-12 relative flex items-center justify-center shrink-0 z-[1] Mobile_480:text-xl mq320:text-lg">
                1
              </b>
            </div>
            <div className="shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid flex flex-row items-center justify-center relative gap-2.5 col-[2/2] row-[3/3]">
              <div className="w-[calc(100%_+_0.8px)] absolute !m-[0] h-[calc(100%_+_0.4px)] top-[-0.2px] right-[-0.6px] bottom-[-0.2px] left-[-0.2px] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid box-border hidden z-[0]" />
              <b className="w-12 relative flex items-center justify-center shrink-0 z-[1] Mobile_480:text-xl mq320:text-lg">
                2
              </b>
            </div>
            <div className="shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid flex flex-row items-center justify-center relative gap-2.5 col-[3/3] row-[3/3]">
              <div className="w-[calc(100%_+_0.8px)] absolute !m-[0] h-[calc(100%_+_0.4px)] top-[-0.2px] right-[-0.6px] bottom-[-0.2px] left-[-0.2px] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid box-border hidden z-[0]" />
              <b className="w-12 relative flex items-center justify-center shrink-0 z-[1] Mobile_480:text-xl mq320:text-lg">
                3
              </b>
            </div>
            <div className="shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid flex flex-row items-center justify-center relative gap-2.5 col-[1/1] row-[4/4]">
              <div className="w-[calc(100%_+_0.8px)] absolute !m-[0] h-[calc(100%_+_0.4px)] top-[-0.2px] right-[-0.6px] bottom-[-0.2px] left-[-0.2px] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid box-border hidden z-[0]" />
              <b className="w-12 relative flex items-center justify-center shrink-0 z-[1] Mobile_480:text-xl mq320:text-lg">
                0
              </b>
            </div>
            <div className="shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid flex flex-row items-center justify-center relative gap-2.5 col-[2/2] row-[4/4]">
              <div className="w-[calc(100%_+_0.8px)] absolute !m-[0] h-[calc(100%_+_0.4px)] top-[-0.2px] right-[-0.6px] bottom-[-0.2px] left-[-0.2px] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid box-border z-[0]" />
              <b className="w-12 relative flex items-center justify-center shrink-0 z-[1] Mobile_480:text-xl mq320:text-lg">
                .
              </b>
            </div>
            <div className="shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid flex flex-row items-center justify-center relative gap-2.5 col-[3/3] row-[4/4]">
              <div className="w-[calc(100%_+_0.8px)] absolute !m-[0] h-[calc(100%_+_0.4px)] top-[-0.2px] right-[-0.6px] bottom-[-0.2px] left-[-0.2px] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid box-border z-[0]" />
              <img
                className="w-9 relative h-9 object-cover z-[1]"
                alt=""
                src="/order-view-delete-keyboard@2x.png"
              />
            </div>
            <div className="rounded-10xs flex flex-row items-center justify-center relative gap-2.5 col-[1/1] row-[5/5] text-base text-white-solid-white-100-ffffff Mobile_480:col-[1/1] Mobile_480:row-[5/5] mq320:col-[1/1] mq320:row-[5/5]">
              <div className="w-[calc(100%_+_0.8px)] absolute !m-[0] h-[calc(100%_+_0.4px)] top-[-0.2px] right-[-0.6px] bottom-[-0.2px] left-[-0.2px] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(175.65deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#10e095] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid box-border z-[0]" />
              <div className="w-[calc(100%_+_0.8px)] absolute !m-[0] h-[calc(100%_+_0.4px)] top-[-0.2px] right-[-0.6px] bottom-[-0.2px] left-[-0.2px] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] border-purple-linear-purple-linear-4000 border-[0.5px] border-solid box-border hidden z-[1]" />
              <div className="relative font-semibold z-[2] Mobile_480:text-sm mq320:text-mini">
                Drawer
              </div>
            </div>
            <div className="rounded-10xs flex flex-row items-center justify-center relative gap-2.5 col-[2/2] row-[5/5] text-base text-white-solid-white-100-ffffff Mobile_480:col-[2/2] Mobile_480:row-[5/5] mq320:col-[2/2] mq320:row-[5/5]">
              <div className="w-[calc(100%_+_0.8px)] absolute !m-[0] h-[calc(100%_+_0.4px)] top-[-0.2px] right-[-0.6px] bottom-[-0.2px] left-[-0.2px] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(183.09deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#ff1ea5] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid box-border z-[0]" />
              <div className="w-[calc(100%_+_0.8px)] absolute !m-[0] h-[calc(100%_+_0.4px)] top-[-0.2px] right-[-0.6px] bottom-[-0.2px] left-[-0.2px] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] border-purple-linear-purple-linear-4000 border-[0.5px] border-solid box-border hidden z-[1]" />
              <div className="relative font-semibold z-[2] Mobile_480:text-sm mq320:text-mini">
                Print
              </div>
            </div>
            <div className="shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(182.19deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#df0000] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid flex flex-row items-center justify-center relative col-[3/3] row-[5/5] text-base text-white-solid-white-100-ffffff Mobile_480:relative Mobile_480:col-[3/3] Mobile_480:row-[5/5] mq320:relative mq320:col-[3/3] mq320:row-[5/5]">
              <div className="relative font-semibold Mobile_480:text-sm mq320:text-mini">
                Red Bill
              </div>
            </div>
          </div>
          <div className="self-stretch h-[45px] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] rounded-10xs [background:linear-gradient(170.9deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#005fed] border-gray-solid-gray-700-898a8d border-[0.5px] border-solid box-border flex flex-row items-center justify-center text-base text-white-solid-white-100-ffffff Mobile_480:h-12 mq320:h-[37px]">
            <div className="relative font-semibold Mobile_480:text-sm mq320:text-mini">
              Complete
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderScreenFastFoodRestaurant;
