import type { FunctionComponent } from "react";

const SignUpConfirm: FunctionComponent = () => {
  return (
    <div className="self-stretch rounded bg-blue-opacity-blue-15-f3f9ff [backdrop-filter:blur(30px)] flex flex-col items-center justify-start py-7 px-[27px] gap-[22px] text-center text-4xl text-white-solid-white-100-ffffff font-mulish">
      <div className="self-stretch relative font-extralight">{`Please check your information `}</div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[10px] text-left text-mini">
        <div className="self-stretch flex flex-row items-start justify-start gap-[15px]">
          <b className="relative">Store’s name:</b>
          <div className="flex-1 relative">pizza34</div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[15px]">
          <b className="relative">Store’s address:</b>
          <div className="flex-1 relative">
            1234 West 74 Street, Lincoln, NE 68509, USA
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[15px]">
          <b className="relative">Your phone number:</b>
          <div className="flex-1 relative">0386032310</div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-start gap-[15px]">
          <b className="relative">License plan:</b>
          <div className="rounded-11xl bg-gold w-[52px] h-6 flex flex-row items-center justify-center py-0.5 px-2.5 box-border text-black-solid-black-1000-000000">
            <div className="relative">Trial</div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[15px]">
          <b className="relative">End of plan:</b>
          <div className="flex-1 relative">2023-04-03</div>
        </div>
      </div>
      <div className="self-stretch rounded bg-darkslateblue [backdrop-filter:blur(5px)] flex flex-col items-start justify-start p-3 gap-[10px] text-smi">
        <div className="self-stretch flex flex-row items-center justify-start gap-[11px]">
          <img className="relative w-5 h-5" alt="" src="/note-icon.svg" />
          <div className="relative font-medium">important note</div>
        </div>
        <div className="self-stretch relative text-sm text-left">{`With trial license, store data will be automatically deleted other 30 days of in activity. `}</div>
      </div>
      <div className="rounded-41xl [background:linear-gradient(170.9deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#005fed] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] w-[162px] h-11 overflow-hidden shrink-0 flex flex-row items-center justify-center py-[11px] px-2.5 box-border text-lg">
        <div className="flex-1 relative font-semibold">Complete</div>
      </div>
    </div>
  );
};

export default SignUpConfirm;
