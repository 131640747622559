import type { FunctionComponent } from "react";
import { LL0 } from "@/react/core/I18nService.tsx";

export type GeneralSettingMergeItemsInsType = {
  className?: string;
  onClose?: () => void;
};

const GeneralSettingMergeItemsIns: FunctionComponent<
  GeneralSettingMergeItemsInsType
> = ({ className = "", onClose }) => {
  return (
    <div
      className={`w-full relative bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-start justify-start pt-8 px-6 pb-6 box-border gap-[24px] max-w-[509px] h-full max-h-full text-left text-base text-blue-solid-blue-500-0051c1 font-mulish ${className}`}
    >
      <img
        className="w-6 absolute !m-[0] top-[8px] right-[8px] h-6 overflow-hidden shrink-0 object-cover cursor-pointer z-[0]"
        alt=""
        src="/close-popup-icon--grey@2x.png"
        onClick={onClose}
      />
      <b className="self-stretch relative z-[1]">
        {LL0().settings.generalSetting.mergeSimilarItems()}:
      </b>
      <div className="self-stretch relative text-black-solid-black-880-1d1d26 z-[2]">
        <p className="m-0">
          {LL0().settings.generalSetting.groupSimilarDishes()}
        </p>
        <p className="m-0">
          {LL0().settings.generalSetting.separateSimilarItems()}
        </p>
      </div>
      <div className="self-stretch flex flex-row items-center justify-end z-[3] text-center text-sm text-white-solid-white-100-ffffff">
        <div
          className="rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8 cursor-pointer"
          onClick={onClose}
        >
          <b className="relative">{LL0().ui.ok()}</b>
        </div>
      </div>
    </div>
  );
};

export default GeneralSettingMergeItemsIns;
