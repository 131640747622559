import { collectionsMap } from "@/data/CollectionsMap.ts";
import type { RxCollection } from "rxdb";
import { md5 } from "js-md5";
import { now } from "@/pos/logic/time-provider.ts";
import dayjs from "dayjs";
import { database, database2, database3 } from "@/data/data-utils.ts";
import { captureException, captureMessage } from "@sentry/react";
import { notificationToast } from "@/react/FloorPlanView/Noti.ts";
import SurrealClient from "@/shared/SurrealClient.ts";
import { dataLock } from "@/data/DataUtils.ts";
import { isMaster } from "@/lib/fetch-master.ts";
import { posSync0 } from "@/data/PosSyncState.ts";
import { deviceSetting0, deviceSettings0 } from "@/data/DeviceSettingSignal.ts";


async function startTrigger() {
  setTimeout(async () => {
    setInterval(async () => {
      await validateAll();
    }, 1000 * 60 * 30)
    await validateAll();
  }, getTimeToNextMinuteMark(30))
}

let current: number;

async function validateAll() {
  await dataLock.acquireAsync();
  current = dayjs(now()).startOf('m').unix()
  await Promise.all([
    database.v?.requestIdlePromise(),
    database2.v?.requestIdlePromise(),
    database3.v?.requestIdlePromise(),
  ])
  for (const item of collectionsMap()) {
    await addInfoToValidateCollection(item.collection, item.name)
  }

  console.log('validate all');

  setTimeout(async () => {
    if (!isMaster()) return;
    const db = await SurrealClient.getSurrealClient('cloud');
    const result = await db?.query<[VerifyData[]]>(`SELECT * FROM verify WHERE date = $date AND storeId = $storeId`,
      { date: current, storeId: posSync0().id });

    console.log('validate', result);

    let verify = true;
    for (const colItem of collectionsMap()) {
      const _items = result?.[0]?.filter(i => i.collection === colItem.name) || [];
      if (_items.length === 0) break;
      const hashItems = _items.map(i => i.hash);
      if (!allStringsEqual(hashItems)) {
        verify = false;
      }
    }
    if (verify) {
      captureMessage('verify all success', {tags: {type: 'verify'}});
      console.log('verify all success');
    } else {
      captureException('verify all failed', {tags: {type: 'verify'}});
      notificationToast('verify all failed', {autoClose: 5 * 1000 * 60});
      console.log('verify all failed');
    }
  }, 2000);
}

type VerifyData = {
  date: number;
  isMaster: boolean;
  collection: string;
  storeId?: number;
  deviceId: string | undefined;
  hash: string;
  content: { _id: any; updatedAt: any }[]
};

async function addInfoToValidateCollection(collection: RxCollection, name: string) {
  const allDocs = await collection.find().sort({ updatedAt: 'desc' }).limit(50).exec()
  const content = allDocs.map(doc => {
    return {
      _id: doc._id,
      updatedAt: doc.updatedAt
    }
  })
  const hash = md5(JSON.stringify(content));
  const deviceId = deviceSetting0()?._id;
  const storeId = posSync0().id;
  if (!storeId) return;
  const data: VerifyData = {
    storeId: storeId,
    deviceId,
    collection: name,
    isMaster: isMaster(),
    hash,
    content,
    date: current
  };

  const db = await SurrealClient.getSurrealClient('cloud');
  await db?.create('verify', data)
}


// @ts-ignore
window.validateCollection = addInfoToValidateCollection

// @ts-ignore
window.startTrigger = startTrigger


function getTimeToNextMinuteMark(min = 5) {
  const now = new Date();
  const minutes = now.getMinutes();
  const seconds = now.getSeconds();
  const milliseconds = now.getMilliseconds();

  const minutesToNextMark = (min - (minutes % min)) % min;
  const timeToNextMark = (minutesToNextMark * 60 - seconds) * 1000 - milliseconds;
  return timeToNextMark;
}

function allStringsEqual(arr: string[]) {
  if (arr.length <= 1) return true;
  const firstElement = arr[0];
  return arr.every(element => element === firstElement);
}

// startTrigger().then();