import dayjs from 'dayjs'
import _ from 'lodash'
import uuid from 'time-uuid'

import { deleteMany } from '@/data/data-utils.ts'
import { EodCache } from '@/data/EodCache.ts'
import { getMaxIdZ, maxId0 } from '@/data/MaxIdHub.ts'
import { Order, PaidOrder } from '@/data/Order'
import { payments0 } from '@/data/PaymentHub.ts'
import { createOrder, stripOrder, stripPaidOrder } from '@/pos/logic/order-reactive.ts'
import { now } from '@/pos/logic/time-provider.ts'
import {
  CommitAction,
  type OrderItem,
  type OrderItemStrip, type OrderPayment,
  OrderStatus,
  type OrderStrip,
  Reason,
  TseMethod
} from '@/pos/OrderType.ts'
import { calItemNet, calItemTax, calItemVSum, mergeVTaxGroup } from '@/pos/orderUtils.ts'
import { orderConfig, roundNumber, useServiceFeeBeforeTax } from '@/shared/order/order-config'
import { PaymentType } from '@/data/Payment'

export function getPayment(order: Order): string {
  if (order.payments.length === 1) {
    if (order.payments[0].extraType === PaymentType.Cash) return 'cash'
    return order.payments[0].type
  } else {
    return 'multi'
  }
}

export async function getRxPaidOrder(order: Order) {
  return await PaidOrder.findOne({ selector: { _id: order._id } }).exec()
}

export async function patchOrder(order: Order, patch: Partial<Order>) {
  const _order = await PaidOrder.findOne({ selector: { _id: order._id } }).exec()
  await _order?.incrementalPatch(_.cloneDeep(patch))
}

export function clearPayment(order: Order) {
  order.commits!.push({
    action: CommitAction.CLEAR_PAYMENTS,
  })
}

export function addCashPayment(order: Order) {
  const cashPayment = payments0().find(p => p.type === 'cash')
  order.commits!.push({
    action: CommitAction.ADD_PAYMENT,
    type: cashPayment?.name || 'cash',
    value: order.vSum!,
    extraType: PaymentType.Cash,
  })
}

export function addNonCashPayment(order: Order, type?: PaymentType, name?: string) {
  order.commits!.push({
    action: CommitAction.ADD_PAYMENT,
    type: name || 'cash',
    value: order.vSum!,
    extraType: type,
  })
}

export function addTseMethod(order: Order, item: OrderItem, tseMethod: TseMethod, seat?: number) {
  order.commits!.push({
    action: CommitAction.SET_TSE_METHOD,
    tseMethod: tseMethod,
    commitId: item.commitRefs![0],
    ...(seat !== undefined && { seat: seat }),
  })
  item.tseMethod = tseMethod
}

export async function makeCancelOrder(order: Order, cb?: Function) {
  let _order = _.cloneDeep(order)
  delete _order.id
  _order._id = uuid()
  delete _order.qrCode
  _order.refOrder = order._id
  _order.refOrderId = order.id
  _order.status = OrderStatus.CANCELLATION_REFERENCE
  for (const item of _order.items) {
    item.quantity = -item.quantity
  }
  _order.cancellationItems = []
  _order.date = dayjs(now()).unix()

  const { id, z } = await getMaxIdZ(dayjs.unix(order.vDate!))
  _order.id = id
  _order.z = z

  _order = createOrder(_order)
  //fixme: check if it is multiple payment or not
  //fixme: multiple payment => cash
  //fixme: single payment => using that payment
  const payments = Array.isArray(order.payments) ? order.payments : Object.values(order.payments || {});

  clearPayment(_order)
  //todo: use last payment ??
  //todo: what will happen if cancel order is multiple payment but don't pay by cash???
  handleAddPayment(payments, _order)
  // await recordOrderCommits(_order)
  const stripOrder = stripPaidOrder(_order)
  await PaidOrder.insert(stripOrder)
  if (cb) cb(_order)
  return _order
}

export async function makeCancelOrderNoTse(order: Order) {
  let _order = _.cloneDeep(order)
  for (const item of _order.items) {
    item.quantity = -item.quantity
  }
  _order = createOrder(_order)
  const payments = Array.isArray(order.payments) ? order.payments : Object.values(order?.payments || {});
  clearPayment(_order)
  //todo: use last payment ??
  handleAddPayment(payments, _order)

  return _order
}

export function handleAddPayment(payments: OrderPayment[], order: Order) {
  if (payments.length > 1) {
    addCashPayment(order)
  } else {
    const paymentType = payments?.[0]?.extraType;
    const paymentName = payments?.[0]?.type;
    addNonCashPayment(order, paymentType, paymentName)
  }
}

export async function clearEodCache(query = {}) {
  await deleteMany(EodCache, { selector: query })
}

export function reCreateOrder(order: Order) {
  let _order: Order

  return {
    get order() {
      return _order
    },
    internalCreateOrder,
    recordCommits,
    insertOrder,
  }

  //region reCreateOrder.utils
  async function internalCreateOrder() {
    //re-make id, qrcode, all tseMethod = TseMethod.apply, payment v.v
    //todo: commit base ?
    _order = _.cloneDeep(order)
    delete _order.id
    _order._id = uuid()
    delete _order.qrCode

    _order.cancellationItems = []
    _order.date = dayjs(now()).unix()
    const { id, z } = await getMaxIdZ(dayjs.unix(order.vDate!))
    _order.id = id
    _order.z = z
    _order.items.forEach(i => ([i.tseMethod, i.date] = [TseMethod.apply, dayjs(now()).unix()]))
    ;[_order.tseMethod, _order.date] = [TseMethod.apply, dayjs(now()).unix()]
    _order = createOrder(_order)
  }

  async function insertOrder(isPaidOrder = true) {
    if (isPaidOrder) return await PaidOrder.insert(stripPaidOrder(_order))
    await Order.insert(stripOrder(_order))
  }

  async function recordCommits() {
    // await recordOrderCommits(_order)
  }
  //endregion
}

export const getOrderNet = (order: Order | OrderStrip): number => {
  if ((order.items?.length ?? 0) > 0) {
    const result = _.values(order.vTaxSum).reduce(mergeVTaxGroup, { tax: 0, net: 0, gross: 0 })
    let net = result?.net ?? 0
    if (useServiceFeeBeforeTax()) net = roundNumber(net + (order.serviceFee ?? 0))
    return net
  }
  return 0
}

export const getOrderTax = (order: Order): number => {
  //mergeVTaxGroup
  if (order.items.length > 0) {
    const result = _.values(order.vTaxSum).reduce(mergeVTaxGroup, { tax: 0, net: 0, gross: 0 })
    return result?.tax ?? 0
  }
  return 0
}

export function getOrderOriginalSum(order: Order | OrderStrip, includeCancelled = false): number {
  if (!order.items) return 0
  let sum = _.sumBy(order.items, getOrderItemOriginalSum)
  if (includeCancelled) {
    sum += _.sumBy(order.cancellationItems, getOrderItemOriginalSum)
    sum += _.sumBy(order.directCancellationItems, getOrderItemOriginalSum)
  }
  return sum
}

export function getOrderItemOriginalSum(item: OrderItem | OrderItemStrip): number {
  return item.quantity * (item.price + _.sumBy(item.modifiers, m => m.price * m.quantity))
}

export function remakeReactiveOrder(order: OrderStrip, removeCancellationItems: boolean) {
  const rawOrder = _.assign(_.cloneDeep(order), removeCancellationItems ? { cancellationItems: [] } : {})
  for (const item of rawOrder.items!) {
    item.printed = true
  }
  const _order = createOrder(rawOrder)
  return _order
}

export function mergeSeat(order: Order) {
  order.seatMode = false
  order.seatMap = []
  for (const item of order.items) {
    item.movedQuantity = 0;
    delete item.seat;
  }
}

export function generateIds(count: number) {
  return Array.from({ length: count }, uuid);
}


export function isCashPayment(order: Order | OrderStrip) {
  const payments: OrderPayment[] = Array.isArray(order.payments) ? order.payments : Object.values(order.payments || {});
  const cashPayment = payments?.filter(p => p?.extraType === PaymentType.Cash || ['cash', 'bar'].includes(p?.type.toLowerCase()));

  if (cashPayment?.length > 0) return {
    cashPayment: cashPayment,
    isMultiple: order.payments?.length > 1
  };

  return false
}

export function isRefundOrder<T extends { status: OrderStatus, reason?: Reason }>(order?: T): boolean {
  return order?.status === OrderStatus.CANCELLATION_REFERENCE && order?.reason === Reason.REFUND
}

export function calculateItemOrginalVSum(item: OrderItem) {
  if (item.taxComponents && item.taxComponents.length > 0) {
    let vSum = calItemNet(item, 0, orderConfig.sumPrecision)
    if (orderConfig.isNetPriceBase) {
      for (const taxComponent of item.taxComponents) {
        vSum += calItemTax(item, taxComponent!.value!, orderConfig.sumPrecision, true)
      }
    }
    return vSum
  }
  return calItemVSum(item, item.tax!, orderConfig.sumPrecision, true)
}