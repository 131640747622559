import type { FunctionComponent } from 'react'
import _ from 'lodash'
import { LL0 } from '@/react/core/I18nService.tsx'
import { CircularProgress } from '@mui/material'

type ViewDetailsPopupType = {
  onClose?: () => void;
  imageSrc?: string;
  metadata?: Record<string, unknown>
};
const ViewDetailsPopup: FunctionComponent<ViewDetailsPopupType> = (
  {
    onClose,
    imageSrc,
    metadata
  }) => {
  return (
    <div
      className="relative bg-white-solid-white-100-ffffff w-full flex flex-col items-start justify-start p-4 box-border gap-[8px] h-full max-w-full max-h-screen overflow-hidden text-left text-3xs text-black-solid-black-900-1e1e23 font-mulish">
      <div className="pr-12 relative font-semibold z-[0]">
        {LL0().productViewSetting.modifierEditorDialog.printer()}: Bar, Ip: {`${_.get(metadata, 'address.ip') ?? ''}`}
        , {LL0().virtualPrinter.orderId()}: {`${_.get(metadata, 'orderId') ?? '-'}`}
      </div>
      <div className="w-full h-full overflow-y-auto no-scrollbar">
        {imageSrc ?
          <img
            className="object-cover z-[1]"
            alt=""
            src={imageSrc}
          /> 
          :
          <div className="flex flex-col items-center justify-center gap-1">
            <CircularProgress size="1em" />
            <p>Loading...</p>
          </div>
        }
      </div>

      <div
        className="absolute my-0 mx-[!important] top-[8px] right-[7.5px] w-6 h-6 overflow-hidden shrink-0 cursor-pointer z-[2]"
        onClick={onClose}
      >
        <img
          className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
          alt=""
          src="/iconclose-popup-icon--grey@2x.png"
        />
      </div>
    </div>
  )
}

export default ViewDetailsPopup