import { LL0 } from '@/react/core/I18nService'
import type { FunctionComponent } from 'react'
import { LL3 } from "@/react/core/I18nCurrency.tsx";

type StaffReportTotalRevenueType = {
  totalRevenuePrice?: number
}

const StaffReportTotalRevenue: FunctionComponent<StaffReportTotalRevenueType> = ({ totalRevenuePrice }) => {
  const LL = LL0()
  return (
    <div className="self-stretch bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-between py-2 px-6 text-left text-mini text-royalblue-200 font-mulish Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border">
      <b className="relative Tablet_768:text-sm Tablet_600:text-smi">{LL.staffReport.totalRevenue()}</b>
      <b className="relative text-xl text-firebrick text-right Tablet_768:text-lg Tablet_600:text-sm">{totalRevenuePrice ? LL3().format.currency(totalRevenuePrice) : '-'}</b>
    </div>
  )
}

export default StaffReportTotalRevenue
