import { useSignal } from '@/react/core/reactive.ts'
import TextField from '@/react/core/TextField.tsx'
import { backupCollections } from '@/react/Developer/developer.logic.ts'

export function InstallationBackupTab() {
  const [emailBackup, setEmailBackup] = useSignal('')
  return (
    <div className="flex flex-row gap-4">
      <TextField
        value={emailBackup()}
        label={'Enter your email'}
        className={'self-stretch'}
        onChange={e => setEmailBackup(e.target.value)}
      />
      <button onClick={() => backupCollections(emailBackup())}>Backup data</button>
    </div>
  )
}
