import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Clock } from '@staff-report/EditTimeEntry.tsx'
import StaffReportPlugin from '@staff-report/StaffReportPlugin.tsx'
import dayjs, { type Dayjs } from 'dayjs'
import debug from 'debug'
import _ from 'lodash'
import _fp from 'lodash/fp'
import { memo, useEffect } from 'react'

import type { DocDeepSignal } from '@/data/data-utils.ts'
import { dataLock } from '@/data/DataUtils.ts'
import { TimeClock } from '@/data/TimeClock.ts'
import type { User } from '@/data/User.ts'
import { makeUsersAvailable } from '@/data/UserHub'
import { getEodReportPeriodAggregate } from '@/pos/logic/eod-calendar-utils'
import type { MonthlyReport } from '@/pos/logic/ReportType'
import { printStaffReport } from '@/pos/logic/staff-report-print.ts'
import { onEnter, PosScreen, router } from '@/pos/PosRouter.ts'
import { computed, signal } from '@/react/core/reactive'
import { printInvoiceFromRaster } from '@/react/Printer/print-invoice.ts'
import { srmReportLogic } from '@/srm/report.logic'
import { isQuebecSrmEnabled, mainScreen } from "@/data/PosSettingsSignal.ts";
import { loginUser, users0 } from "@/data/UserSignal.ts";

const log = debug('data:staff-report')

type TCancellationItem = { table: string; name: string; date: number }
// Group the data by `table` & `date`, then adding the `count`
// const processCancellationHistoryData = _fp.pipe(
//   _fp.groupBy<TCancellationItem>(r => (r ? `${r.table}|${r.date}` : '')),
//   _fp.toPairs,
//   _fp.map(([key, val]) => ({ key, count: val.length, ...val[0] } as TCancellationItem & { key: string; count: number })),
//   _fp.sortBy(v => v.date * -1) // sort descending
// )

const processCancellationHistoryData = _fp.pipe(
  _fp.groupBy<TCancellationItem>(r => (r ? `${r.table}|${r.date}` : '')),
  _fp.toPairs,
  _fp.flatMap(([key, val]) =>
    val.map(item => ({
      key,
      ...item
    }))
  ),
  _fp.groupBy(i => (i ? `${i.key}|${i.name}|${i.id}` : '')),
  _fp.toPairs,
  _fp.map(([key, items]) => {
    const totalQuantity = _.sumBy(items, 'quantity');
    return {
      key,
      count: totalQuantity,
      ...items[0],
    } as TCancellationItem & { key: string; count: number };
  }),
  _fp.sortBy(v => v.date * -1)
)

export const [selectedUserId, setSelectedUserId] = signal<string | undefined>()
export const [startDate, setStartDate] = signal<Dayjs>(dayjs().startOf('D'))
export const [endDate, setEndDate] = signal<Dayjs>(dayjs().endOf('D'))

//computed lists
export const staffReportUsers = computed<Array<DocDeepSignal<User>>>(() =>
  users0().filter(user => (user._id === loginUser()?._id && loginUser()?.viewOwnReport) || (user._id !== loginUser()?._id && loginUser()?.viewOtherStaffReport))
)

// Those 2 signal does not need to be export
export const [reportData, setReportData] = signal<MonthlyReport | null>()
export const selectedUserName = computed(() => users0().find(({ _id }) => _id === selectedUserId())?.name)



export const staffReportData = computed(() => {
  const data = reportData()
  const userName = selectedUserName() ?? ''

  const groupByPayment = data?.staffReport?.groupByPayment?.[userName]
  const voucherReport = data?.staffReport?.voucherReportByUser?.[userName]

  return {
    totalRevenue: data?.staffReport?.userSales?.[userName]?.vTaxSum?.gross,
    totalPaymentInCash: data?.staffReport?.cashSalesByUserExcludingCashBack[userName],
    totalPaymentInCredit: data?.staffReport?.cashlessSalesByUserExcludingTip[userName],
    totalCashlessSales: data?.staffReport?.cashlessSalesByUser?.[userName],
    totalCashlessSalesExcludingTip: data?.staffReport?.cashlessSalesByUserExcludingTip?.[userName],
    totalTip: data?.staffReport?.totalTipByUser?.[userName],
    totalItemSold: data?.staffReport?.soldItemsCnt?.[userName],
    usedDiscount: data?.staffReport?.totalDiscountByUser?.[userName],
    totalVoucherSoldCount: voucherReport?.soldTotal,
    totalVoucherSoldValue: _.sumBy(voucherReport?.soldVouchers, v => v.price),
    totalVoucherUsedCount: voucherReport?.redeemedTotal,
    totalVoucherUsedValue: _.sumBy(voucherReport?.redeemedVouchers, v => v.price),
    returnedTotalPrice: data?.staffReport?.totalCashbackByUser?.[userName],
    cancellationHistory: processCancellationHistoryData(data?.staffReport?.cancellationItems?.filter(v => v.user === userName)),
    timeClock: data?.timeClocksByUser?.[userName],
    totalPaymentInDebitor: data?.staffReport?.debitorByUser?.[userName],
    refundHistory: processCancellationHistoryData(data?.staffReport?.refundItems?.filter(v => v.user === userName))
  }
})

export const currentYear = dayjs().year();
export const lastFiveYears = Array.from({ length: 5 }, (_, i) => currentYear - i);

export const onPrintStaffReport = async () => {
  const _reportData = reportData()
  if (!_reportData) return
  const raster = await printStaffReport(_reportData, selectedUserName())
  if (!raster) return
  await printInvoiceFromRaster(raster, { metadata: { date: dayjs().unix(), type: 'staff-report' } })
}
export async function printSaleReport() {
  if (!isQuebecSrmEnabled()) return

  const _startDate = startDate().toDate()
  const _endDate = endDate().toDate()
  if (!_startDate || !_endDate) return
  // TODO: handle this in master
  await srmReportLogic.printUserReport([_startDate, _endDate])
}

export const onBack = () => {
  router.screen = mainScreen()
}

export const formatTotalHours = (h: number) => {
  const hours = parseInt(String(h))
  const minutes = _.round((h - hours) * 60)
  return h ? (hours === 0 ? `${minutes}'` : minutes === 0 ? `${hours}h` : `${hours}h${minutes}'`) : '0'
}

// export const updateTimeWithDate = (date: any, time: any) => {
//   if (time) {
//     const newDate = new Date(date || Date.now())
//     newDate.setHours(time.getHours())
//     newDate.setMinutes(time.getMinutes())
//     newDate.setSeconds(0)
//     return newDate
//   }
//   return null
// }

export const updateTimeWithDate = (date: any, time: any) => {
  if (time) {
    const newDate = dayjs(date || dayjs())
      .hour(dayjs(time).hour())
      .minute(dayjs(time).minute())
      .second(0)
    return newDate
  }
  return null
}

export const updateStaffReport = async () => {
  await dataLock.acquireAsync()
  const report = await getEodReportPeriodAggregate(startDate().unix(), endDate().unix())
  setReportData(report)
}

export const getShift = async (data: Clock) => {
  return await TimeClock.findOne({
    selector: {
      _id: data?.id,
    },
  }).exec()
}

const prepareData = _.debounce(
  function () {
    if (!startDate() || !endDate()) setReportData(null)
    else {
      ;(async () => {
        log('⚡️ Getting report data...')
        await dataLock.acquireAsync()
        const data = await getEodReportPeriodAggregate(startDate().unix(), endDate().unix())
        setReportData(data)
        log('✅ Report data:', data)
      })()
    }
  },
  100,
  { leading: true, trailing: true }
)

const StaffReportView = () => {
  makeUsersAvailable()

  // Select the first users in the list by default
  onEnter(PosScreen.STAFF_REPORT, () => {
    setSelectedUserId(staffReportUsers()?.[0]?._id)
    prepareData()
  })

  useEffect(() => {
    prepareData()
  }, [startDate(), endDate()])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaffReportPlugin />
    </LocalizationProvider>
  )
}

export default memo(StaffReportView)
