import { LL3 } from '@/react/core/I18nCurrency'
import { LL0 } from '@/react/core/I18nService'
import type { FunctionComponent } from 'react'

type StaffReportReturnedTotalType = {
  returnedTotalPrice?: number
}

const StaffReportReturnedTotal: FunctionComponent<StaffReportReturnedTotalType> = ({ returnedTotalPrice }) => {
  const LL = LL0()
  return (
    <div className="self-stretch bg-white-solid-white-100-ffffff flex flex-row items-center justify-between py-2 px-6 text-left text-mini text-royalblue-200 font-mulish Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border">
      <b className="flex-1 relative Tablet_768:text-sm Tablet_600:text-smi">{LL.report.returnedTotal()}</b>
      <b className="relative text-base text-firebrick text-right Tablet_768:text-mini Tablet_600:text-sm">{returnedTotalPrice ? LL3().format.currency(returnedTotalPrice) : '-'}</b>
    </div>
  )
}

export default StaffReportReturnedTotal
