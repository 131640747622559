import dayjs, { type Dayjs, type OpUnitType, type QUnitType } from 'dayjs';

export const getDiffTime = function (startTime: Dayjs, endTime: Dayjs, unit : QUnitType | OpUnitType = "m") {
  return dayjs(endTime).diff(startTime, unit)
}

const getHourMinute = (time: string) => { //format: hh:mm
  return time.split(':').map(x => Number(x))
}

const isBefore = (hs: number, ms: number, he: number, me: number) => {
  if (hs === he) return ms <= me
  return hs < he
}

export const isBetween = (time: string, start: string, end: string): boolean => { //format: hh:mm, 24h
  const [h, m] = getHourMinute(time)
  const [hs, ms] = getHourMinute(start)
  const [he, me] = getHourMinute(end)
  if (isBefore(hs, ms, he, me)) {
    return isBefore(hs, ms, h, m) && isBefore(h, m , he, me)
  } else
    return isBetween(time, `${hs}:${ms}`, '24:00') || isBetween(time, '00:00', `${he}:${me}`)
}