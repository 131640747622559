import { openobserveLogs } from '@openobserve/browser-logs'
import debug from 'debug'

import { getDeviceId } from './getDeviceId'
import { deviceSetting0 } from '@/data/DeviceSettingSignal.ts'
import { posSync0 } from '@/data/PosSyncState.ts'
import _ from "lodash";

const originalLog = debug.log
debug.log = (...args) => {
  const tags = _.pick(args[4] || {}, ['alert', 'orderId', 'itemId']);
  openobserveLogs.logger.info(JSON.stringify(args[0]).replaceAll('%c', ''), {
    storeId: posSync0()?.id,
    deviceId: getDeviceId(),
    deviceName: deviceSetting0()?.name,
    appVersion: import.meta.env.VITE_APP_VERSION,
    appTag: args[0].split('%c')[1]?.replaceAll(' ', ''),
    ...tags
  })
  originalLog(...args)
}
