import { captureException } from '@sentry/react'
import axios from 'axios'
import debug from 'debug'
import delay from 'delay'
import _ from 'lodash'
import { toast } from 'react-toastify'

import bellMp3 from '@/assets/sounds/bell.mp3'
import { dataLock } from '@/data/DataUtils'
import { OnlineOrder } from '@/data/OnlineOrder'
import { runMasterHandlerPaidOrderFactory } from '@/data/OrderHub.ts'
import type { Payment } from '@/data/Payment.ts'
import { payments0 } from '@/data/PaymentHub.ts'
import { isQuebecSrmEnabled } from '@/data/PosSettingsSignal.ts'
import { Product } from '@/data/Product.ts'
import { StaffStatus } from '@/data/TableStaffStatus'
import { isTableHasStaffEditing, recordTableStaffStatus } from '@/data/TableStaffStatusHub'
import { isValidSrmUser } from '@/data/UserHub'
import { createOrder, stripOrder } from '@/pos/logic/order-reactive'
import { OrderAction as Action } from '@/pos/OnlineOrderType'
import {
  CommitAction,
  InvoiceTypes,
  MarketPlaceProvider,
  MasterHandlerCommand, type OrderItem,
  OrderStatus,
  type TOrder
} from '@/pos/OrderType'
import { PosScreen, router } from '@/pos/PosRouter.ts'
import { LL0 } from '@/react/core/I18nService.tsx'
import { effectOn, signal } from '@/react/core/reactive'
import {
  callbackPayment,
  onPaymentClick,
  order0 as paymentOrder0,
  setOrder0 as _setOrder0
} from '@/react/PaymentView/PaymentView.tsx'
import msgBox from '@/react/SystemService/msgBox.tsx'
import { paymentHook } from '@/react/utils/hooks.ts'
import { getApiUrl } from '@/shared/utils.ts'
import { srmTransactionLogic } from '@/srm/transaction.logic'

import { applyQuebecTaxComponents, checkPaymentConsistency, recalculateDiscount } from './srm-fix'
import { ensureValidItemNameLength, nameLengthRegex } from "@/srm/lib/utils.ts";
import { getTicketNumber } from "@/react/Printer/print-label.ts";
import { isLabelPrinterEnable } from "@/react/Printer/PrinterSettingView.tsx";
import { defaultPrinter } from "@/data/GroupPrinterHub.ts";

const log = debug('dev:pending-order')

export const [onlineOrdersV, setOnlineOrdersV] = signal<number>(0)
export const [pendingOrders0, setPendingOrders0] = signal<Array<TOrder>>([])
export const [kitchenOrders0, setKitchenOrders0] = signal<Array<TOrder>>([])

export const [totalOrder, setTotalOrder] = signal<number>(0)
export const [totalPendingOrder, setTotalPendingOrder] = signal<number>(0)
export const [totalKitchenOrder, setTotalKitchenOrder] = signal<number>(0)

export const [popupOrder, setPopupOrder] = signal<TOrder>()

const repeat = () => true
const playSound = () => true

let isPlaying = false
let shouldStopAtNextTurn = false

const bell = new Audio(bellMp3)

function playBell() {
  if (import.meta.env.MODE === 'development') return

  if (!playSound()) {
    console.log('play sound is disabled. skip')
    return
  }

  if (isPlaying) {
    console.log('bell is playing. skip')
    return
  }

  shouldStopAtNextTurn = false
  bell.play().then()
}

export function stopBell() {
  shouldStopAtNextTurn = true
}

let bellInitialized = false

function initBell() {
  if (bellInitialized) return
  bellInitialized = true
  isPlaying = false
  shouldStopAtNextTurn = false
  bell.addEventListener('play', () => (isPlaying = true))
  bell.addEventListener('ended', () => {
    isPlaying = false
    const isPendingOrdersNotEmpty = totalPendingOrder() > 0
    if (repeat() && !shouldStopAtNextTurn && isPendingOrdersNotEmpty) {
      playBell()
    }
  })
}

const axiosConfig = {
  // bypass 3xx redirect
  // TODO: check if 2xx, 4xx, 5xx
  validateStatus: (status: number) => status < 400,
}

function isOrderByPhone(order: any) {
  return order.provider === MarketPlaceProvider.PHONE
}

export async function declineOrder(order: TOrder, reason: string) {
  const LL = LL0()
  try {
    const rawOrder = await OnlineOrder.findOne({ selector: { _id: order._id } }).exec()
    await rawOrder?.incrementalPatch({ status: OrderStatus.REJECTING, responseMessage: reason })
    if (!rawOrder?.isFake)
      await axios.post(
        `${getApiUrl()}/api/order/update-status`,
        {
          order: Object.assign(order, { responseMessage: reason }),
          action: Action.REJECT,
        },
        axiosConfig
      )
    await delay(100)
    await rawOrder?.incrementalPatch({ status: OrderStatus.REJECTED })
  } catch (e: any) {
    console.error('error', e)
    captureException(new Error('declineOrder', { cause: e }), { tags: { type: 'oo' } })
    await msgBox.show(LL.ui.error(), `${LL.onlineOrder.failedToDecline()}: ${e.response?.data?.error || e.message}`, msgBox.Buttons.OK, msgBox.Icons.Error)
  }
}

export async function cancelOrder(order: TOrder) {
  log(`⚡️ Cancelling online order #${order.externalId}...`, { orderId: order._id })
  if (isQuebecSrmEnabled() && !isValidSrmUser()) return toast.error(LL0().srm.errors.unauthorized())
  const LL = LL0()
  try {
    const rawOrder = await OnlineOrder.findOne({ selector: { _id: order._id } }).exec()
    await rawOrder?.incrementalPatch({ status: OrderStatus.CANCELLING })
    if (!rawOrder?.isFake && !isOrderByPhone(order)) await axios.post(`${getApiUrl()}/api/order/update-status`, { order, action: Action.CANCEL }, axiosConfig)
    await delay(100)
    await rawOrder?.incrementalPatch({ status: OrderStatus.CANCELLED })

    if (isQuebecSrmEnabled()) {
      log(`⏺️ Recording cancellation for online order #${order.externalId}...`, { orderId: order._id })
      await srmTransactionLogic.recordCancellation(order);
    }

    log(`✅ Online order #${order.externalId} cancelled`, { orderId: order._id })
  } catch (e: any) {
    log(`❌ Failed to cancel online order #${order.externalId}: ${e}`, { orderId: order._id })
    captureException(new Error('cancelOrder', { cause: e }), { tags: { type: 'oo' } })
    await msgBox.show(LL.ui.error(), `${LL.onlineOrder.failedToCancel()}: ${e.response?.data?.error || e.message}`, msgBox.Buttons.OK, msgBox.Icons.Error)
  }
}

export type OnAcceptOption = { pickupDate?: string; dropOffDate?: string }

async function ensureValidGroupPrinter(orderItems: OrderItem[], orderId: string): Promise<OrderItem[]> {
  for (const item of orderItems) {
    if (!item.groupPrinter && !item.groupPrinter2) {
      const itemCheck = await Product.findOne({ selector: { _id: item._id } }).exec();
      log("item missing groupPrinter", { alert: true, orderId, itemId: item._id });
      item.groupPrinter = itemCheck?.groupPrinter || defaultPrinter()?._id;
    }

    if (!item.labelPrinter && isLabelPrinterEnable()) {
      const itemCheck = await Product.findOne({ selector: { _id: item._id } }).exec();
      if (itemCheck?.labelPrinter) {
        log("item missing labelPrinter", { alert: true, orderId, itemId: item._id });
        item.labelPrinter = itemCheck.labelPrinter;
      }
    }
  }
  return orderItems;
}


//fixme: print over call master api
export async function acceptOrder(order: TOrder, storeExpectedDate: OnAcceptOption) {
  log(`⚡️ Accepting online order #${order.externalId}...`, { orderId: order._id })
  if (isQuebecSrmEnabled() && !isValidSrmUser()) return toast.error(LL0().srm.errors.unauthorized())
  const LL = LL0()
  try {
    toast.info(LL.pendingOrder.updateOrderStatus(), { autoClose: 1000 })
    if (!order?.isFake)
      await axios.post(
        `${getApiUrl()}/api/order/update-status`,
        {
          order: Object.assign({}, order, storeExpectedDate),
          action: Action.ACCEPT,
        },
        axiosConfig
      )
    await delay(100)
    getTicketNumber(order)
    log(`ℹ️ Updating online order #${order.externalId} and set status to ACCEPTED...`, { orderId: order._id })
    order.status = OrderStatus.ACCEPTED
    order.pickupDate = storeExpectedDate.pickupDate
    order.dropOffDate = storeExpectedDate.dropOffDate
    order.items = await ensureValidGroupPrinter(order.items, order._id)
    // order.users = loginUsers();
    // printKitchen(order, false).catch(e => captureException(new Error('acceptOrder', { cause: e }), { tags: { type: 'print' } }))
    // await handlePrintLabel(order)
    // await assignZ(order);
    // order.id = maxId0().orderId!;
    // await onPrintTse(order);
    if (!ensureValidItemNameLength(order.items)) {
      const validateNameLength = (name: string) => {
        return name.padEnd(2, '_').substring(0, 128)
      };

      order.items.forEach(item => {
        if (!nameLengthRegex.test(item?.name ?? '')) {
          item.name = validateNameLength(item?.name || '');
        }

        if (item?.modifiers?.length) {
          item.modifiers.forEach(modifier => {
            if (!nameLengthRegex.test(modifier.name))
            modifier.name = validateNameLength(modifier?.name || '');
          });
        }
      });
    }
    if (isQuebecSrmEnabled()) {
      log(`⏺️ Recording temporary bill for online order #${order.externalId}...`, { orderId: order._id })
      await srmTransactionLogic.recordTemporaryBill(order);
    }
    order.commits?.push({ action: CommitAction.PRINT })
    const api = runMasterHandlerPaidOrderFactory(stripOrder(order), [MasterHandlerCommand.onKitchenAndLabelPrint])
    log(`ℹ️ Running master handler for online order #${order.externalId}...`, { orderId: order._id })
    await api.runFull(true, OnlineOrder);
    log(`✅ Online order #${order.externalId} accepted successfully`, { orderId: order._id })
    // await OnlineOrderCollection.upsert(stripOrder(order));
  } catch (e) {
    console.error('error', e)
    captureException(new Error('acceptOrder', { cause: e }), { tags: { type: 'oo' } })
    await msgBox.show(LL.ui.error(), `${LL.onlineOrder.failedToAccept()}: ${e.response?.data?.error || e.message}`, msgBox.Buttons.OK, msgBox.Icons.Error)
  }
}

export async function readyOrder(order: TOrder) {
  const LL = LL0()
  try {
    const rawOrder = await OnlineOrder.findOne({ selector: { _id: order._id } }).exec()
    if (!rawOrder?.isFake && !isOrderByPhone(order)) await axios.post(`${getApiUrl()}/api/order/update-status`, { order, action: Action.READY }, axiosConfig)
    await delay(100)
    await rawOrder?.incrementalPatch({ status: OrderStatus.READY })
  } catch (e: any) {
    captureException(new Error('readyOrder', { cause: e }), { tags: { type: 'oo' } })
    await msgBox.show(LL.ui.error(), `${LL.onlineOrder.failedToReady()}: ${e.response?.data?.error || e.message}`, msgBox.Buttons.OK, msgBox.Icons.Error)
  }
}

//fixme: print over call master api
export async function completeOrder(order: TOrder) {
  log(`⚡️ Finalizing online order #${order.externalId}...`, { orderId: order._id })
  if (isQuebecSrmEnabled() && !isValidSrmUser()) return toast.error(LL0().srm.errors.unauthorized())
  const LL = LL0()
  const _update = async (order: TOrder) => {
    try {
      log(`ℹ️ Updating online order #${order.externalId} status...`, { orderId: order._id })
      const rawOrder = await OnlineOrder.findOne({ selector: { _id: order._id } }).exec()
      if (!order.isFake && !isOrderByPhone(order)) await axios.post(`${getApiUrl()}/api/order/update-status`, { order, action: Action.COMPLETE }, axiosConfig)
      await delay(100)
      await rawOrder?.incrementalPatch({ status: OrderStatus.COMPLETED })
      log(`ℹ️ Online order #${order.externalId} marked as completed`, { orderId: order._id })

      // When user click print invoice in the payment screen, the order is considered as paid.
      if (order.status === OrderStatus.PAID) {
        log(`ℹ️ Online order #${order.externalId} is already paid, exiting...`, { orderId: order._id })
        return
      }

      const cmds: any[] = []
      log(`ℹ️ Execute pay hooks on #${order.externalId}...`, { orderId: order._id })
      await paymentHook.emit('payOrderHandler', cmds)
      if (isQuebecSrmEnabled()) {
        log(`ℹ️ Recording SRM closing recept for online order #${order.externalId}...`, { orderId: order._id })
        await srmTransactionLogic.recordClosingReceipt(order, { print: true })
      } else {
        cmds.push('printInvoice', InvoiceTypes.INVOICE.toString())
      }
      const api = runMasterHandlerPaidOrderFactory(Object.assign(order, { status: OrderStatus.PAID }), [MasterHandlerCommand.singleComplete, cmds.join(',') as any])
      log(`ℹ️ Running master handler for online order #${order.externalId}...`, { orderId: order._id })
      await api.runFull()
      log(`✅ Online order #${order.externalId} completed successfully`, { orderId: order._id })
      //todo handle tse case
    } catch (e) {
      log(`❌ Failed to complete online order #${order.externalId}: ${e}`, { orderId: order._id })
      console.error('error', e)
      captureException(new Error('completeOrder', { cause: e }), { tags: { type: 'oo' } })
      await msgBox.show(LL.ui.error(), `${LL.onlineOrder.failedToComplete()}: ${e.response?.data?.error || e.message}`, msgBox.Buttons.OK, msgBox.Icons.Error)
    }
  }

  const orderPayments = _.map(order.payments, pm => _.toUpper(pm.extraType))
  const isPaid = orderPayments.length && !orderPayments.includes('CASH')
  if (isPaid) {
    log(`⚡️ Online order #${order.externalId} is already paid, proceed to finalizing step...`, { orderId: order._id })
    await _update(order)
  } else {
    if (isQuebecSrmEnabled() && order.table && (await isTableHasStaffEditing(order.table))) {
      log(`⚠️ Online order #${order.externalId} on table ${order.table} is invalid, skipping...`, { orderId: order._id })
      return // TODO: check if this flow is valid ?
    }

    log(`⚡️ Making payment for online order #${order.externalId}...`, { orderId: order._id })
    router.screen = PosScreen.PAYMENT
    if (isQuebecSrmEnabled() && order.table) await recordTableStaffStatus(order.table, StaffStatus.PROCESSING_PAYMENT, order._id)

    // set default payment to cash
    const cashPayment = payments0().find(item => _.toUpper(item.type) === 'CASH')
    const order1 = createOrder(order)
    order1.payments.splice(0, order1.payments.length)
    _setOrder0(order1)
    onPaymentClick(cashPayment || ({ name: 'cash' } as Payment))

    callbackPayment.fn = async () => {
      delete callbackPayment.fn
      router.screen = PosScreen.PENDING_ORDERS
      const order = paymentOrder0()
      log(`ℹ️ Online order #${order.externalId} paid, proceed to finalizing step...`, { orderId: order._id })
      await _update(order)
      return order
    }
  }
}

export function isProviderSupportModifyOrderDate(order: TOrder) {
  return (
    order.provider === MarketPlaceProvider.RESTAURANT_PLUS
    || order.provider === MarketPlaceProvider.RESTABLO
    || order.provider === MarketPlaceProvider.PIKAPOINT
    || order.provider === MarketPlaceProvider.UBER_EATS
    || order.provider === MarketPlaceProvider.LIEFERANDO
  )
}

export function predefinedTimes(order: TOrder) {
  return (
    order.provider === MarketPlaceProvider.LIEFERANDO
      ? [10, 20, 30, 40, 50, 60] // lieferando allow maximum 60 minutes
      : [15, 30, 45, 60, 70, 90]
  )
}

export function allowCustomTimeDialog(order: TOrder) {
  return order.provider !== MarketPlaceProvider.LIEFERANDO
}

const pendingOrderStatus = [OrderStatus.IN_PROGRESS, OrderStatus.REJECTING, OrderStatus.ACCEPTING]
const kitchenOrderStatus = [OrderStatus.ACCEPTED, OrderStatus.CANCELLING, OrderStatus.READY]

const fetchOrders = _.debounce(async () => {
  log('⚡️ Fetching online orders...')
  await dataLock.acquireAsync()
  const orders = await OnlineOrder.find({
    selector: {
      status: { $in: [...pendingOrderStatus, ...kitchenOrderStatus] },
    },
  }).exec()
  if (orders.length === 0) {
    log(`ℹ️ No new online order`)
    setTotalOrder(0)
    setTotalPendingOrder(0)
    setPendingOrders0([])
    setTotalKitchenOrder(0)
    setKitchenOrders0([])
    return
  }

  const pendingOrders: TOrder[] = []
  const kitchenOrders: TOrder[] = []

  log(`⚡️ Got ${orders.length} new online order(s)`)

  for (const o of orders) {
    const { status } = o
    if (!pendingOrderStatus.includes(status) && !kitchenOrderStatus.includes(status)) continue
    const order = o.toMutableJSON()

    log(`⚡️ Got new online order #${order.externalId} $${order.metadata?.providerTotal}...`, { orderId: order._id })
    // FIXME: This is a temporary fix for Quebec SRM to avoid missing tax components in receiving order's items.
    //        Should be handled for all other cases properly.
    if (isQuebecSrmEnabled()) {
      applyQuebecTaxComponents(order)

      if (order.provider === MarketPlaceProvider.PIKAPOINT) recalculateDiscount(order)

      log(`⚡️ Fixed new online order #${order.externalId} $${order.vTotal}...`, { orderId: order._id })
    }

    const recreatedOrder = createOrder(order)

    // FIXME: This is a temporary fix for Quebec SRM to avoid missing tax components in receiving order's items.
    //        Should be handled for all other cases properly.
    if (order.provider === MarketPlaceProvider.PIKAPOINT && isQuebecSrmEnabled()) {
      checkPaymentConsistency(recreatedOrder)
    }
    log(`⚡️ Recreated online order #${order.externalId} $${order.vTotal}...`, { orderId: order._id })

    if (pendingOrderStatus.includes(status)) {
      pendingOrders.push(recreatedOrder)
    } else if (kitchenOrderStatus.includes(status)) {
      kitchenOrders.push(recreatedOrder)
    } else {
      // ...
    }
  }

  const shouldPlayBell = pendingOrders0().length < pendingOrders.length

  setTotalOrder(pendingOrders.length + kitchenOrders.length)

  setTotalPendingOrder(pendingOrders.length)
  setPendingOrders0(pendingOrders)

  setTotalKitchenOrder(kitchenOrders.length)
  setKitchenOrders0(_.sortBy(kitchenOrders, o => o.pickupDate))

  if (shouldPlayBell) {
    playBell()
    const currentScreenIsPendingOrderScreen: boolean = PosScreen.PENDING_ORDERS === router.screen
    const autoClose: any = currentScreenIsPendingOrderScreen ? 1000 : false
    toast(LL0().onlineOrder.youHaveNewOnlineOrder(), {
      type: 'info',
      autoClose,
      style: {
        fontSize: '20px',
        padding: '20px',
        backgroundColor: '#93e793',
        color: '#333',
      },
      onClick: () => {
        if (currentScreenIsPendingOrderScreen) return
        router.screen = PosScreen.PENDING_ORDERS
      },
    })
  }
}, 100)

effectOn([onlineOrdersV], fetchOrders)

dataLock.acquireAsync().then(() => {
  OnlineOrder.$.subscribe(() => setOnlineOrdersV(v => v + 1))
  initBell()
})
