import SyncOnlineMenuPlugin from '@sync-menu/SyncOnlineMenuPlugin.tsx'
import { effect, effectOn, selector, signal } from '@/react/core/reactive.ts'
import type { Product } from '@/data/Product.ts'
import type { ProductLayout } from '@/data/ProductLayout.ts'
import { VIEW__CATEGORY } from '../EditOnlineMenuView/EditOnlineMenuView.logic'
import { findProduct, orderLayout0 } from '@/react/OrderView/OrderView.tsx'
import { onEnter, PosScreen } from '@/pos/PosRouter.ts'
import debug from 'debug'
import {memo} from "react";

const log = debug('data:sync-menu')

// gonna use the same data as orderView as the data is shared w each other
// duplicate if we use lazy load on OrderView2?
// except selected category

export const [categoryIndex, setCategoryIndex] = signal<number>(0)
export const isSelectedCategory = selector<number>(categoryIndex)

// local type, used for importProductLayouts, since we need the layout for
// filtering layouts and product for displaying on the right side
type ImportProductLayout = Omit<ProductLayout, 'product'> & { product: Product }
export const [importProductLayouts, setImportProductLayouts] = signal<ImportProductLayout[]>([])
export const [preSelectedProducts, setPreSelectedProducts] = signal<ProductLayout[]>([])

effect(() => log('⚡️ importProductLayouts', importProductLayouts()))

const setAndConvertToImportProductLayout = (data: ProductLayout[]) => {
  const converted = data
    .map(a => {
      const product = findProduct(a)
      if (!product || a.type === 'Text') return
      return { ...a, product }
    })
    .filter((a): a is ImportProductLayout => !!a)
  setImportProductLayouts(converted)
}

export const onCompleteImport = (withSync?: boolean) => {
  //TODO: sort by id?
  const filteredImportProducts = importProductLayouts()
    .filter(productLayout => productLayout.type !== 'Extra')
    .map(productLayout => productLayout.product)

  VIEW__CATEGORY.onImportComplete(filteredImportProducts, withSync)
}

const selectImportProductLayout = (productLayout: ImportProductLayout) => {
  //check if product layout is existed
  if (importProductLayouts().findIndex(importLayout => importLayout._id === productLayout._id) !== -1) return
  setImportProductLayouts(prev => [...prev, productLayout])
}

//for better performance, we use productLayoutIndex as 2nd parameter that can bypass finding stage
const deselectImportProductLayout = (productLayout: Omit<ProductLayout, "product">, productLayoutIndex?: number) => {
  const removeLayoutIndex = productLayoutIndex ?? importProductLayouts().findIndex(importLayout => importLayout._id === productLayout._id)
  if (removeLayoutIndex !== -1) {
    setImportProductLayouts(prev => {
      prev.splice(removeLayoutIndex, 1)
      //to make list rerender
      return [...prev]
    })
  }
}

export const onClickImportProduct = (productLayout: ImportProductLayout) => {
  //toggle productLayout
  const productLayoutIndex = importProductLayouts().findIndex(importLayout => importLayout._id === productLayout._id)
  if (productLayoutIndex !== -1) {
    deselectImportProductLayout(productLayout, productLayoutIndex)
    return
  }
  setImportProductLayouts(prev => [...prev, productLayout])
}

export const [isSelectingAll, setIsSelectingAll] = signal<boolean>(false)

// used for both "Select All" and "Deselect All"
export const onSelectAllProducts = () => {
  const currentCategory = orderLayout0()?.categories.find((_, index) => index === categoryIndex())
  if (isSelectingAll()) {
    currentCategory?.products?.forEach(productLayout => {
      deselectImportProductLayout(productLayout)
    })
  } else {
    currentCategory?.products?.forEach(productLayout => {
      const product = findProduct(productLayout)
      if (!product || productLayout.type === 'Text') return
      selectImportProductLayout({ ...productLayout, product })
    })
  }
  setIsSelectingAll(prev => !prev)
}

effectOn([categoryIndex], () => setIsSelectingAll(false))

const SyncMenuView = () => {
  onEnter(PosScreen.SYNC_MENU, () => setAndConvertToImportProductLayout(preSelectedProducts()))

  return <SyncOnlineMenuPlugin />
}

export default memo(SyncMenuView)
