import { TextField, useAutocomplete, type UseAutocompleteProps, type AutocompleteProps as MUIAutocompleteProps } from "@mui/material";
import Input, { type InputProps } from "@/react/core/Input.tsx";
import type * as React from "react";
import { useMemo, useState } from "react";
import { clsx } from "clsx";
import CloseIcon from "@mui/icons-material/Close";
import { useNativeInput } from "@/react/core/NativeInput.ts";
import MUIAutocomplete from "@mui/material/Autocomplete";

export type CustomAutocompleteProps = UseAutocompleteProps<any, boolean, boolean, boolean> & {
  inputProps: Pick<InputProps, "className" | "label"> & {
    endAdornment?: (onChange?:  React.ChangeEventHandler<HTMLInputElement>, value?: string) => React.ReactNode;
  },
  renderOption?: MUIAutocompleteProps<any, boolean, boolean, boolean>["renderOption"];
  className?: string;
}

const CustomAutocomplete = ({inputProps: {endAdornment, ...restInputProps}, renderOption, className, ...rest}: CustomAutocompleteProps) => {
  const {
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value
  } = useAutocomplete({
    ...rest
  });

  const [firstMount, setFirstMount] = useState<boolean>(true);

  const target = useMemo(() => ({
    message1: "hello",
    message2: "everyone",
  }), []);
  let ref2;

  const handler2 = useMemo(() => ({
    get(target, prop, receiver) {
      if (prop === 'removeAttribute') {
        return function () {
          console.log('removeAttribute', arguments);
        }
      } else if (prop === 'setAttribute') {
        return function () {
          console.log('setAttribute', arguments);
        }
      } else if (prop === 'focus') {
        return function () {
          console.log('focus 2');
          ref2?.[prop]();
        }
      } else if (prop === 'selectionEnd') {
        return function () {
          ref2?.[prop]();
        }
      } else if (prop === 'nodeName') {
        return 'INPUT';
      }
      console.log('get', prop);
      return ref2?.[prop];
    },
  }), []);

  const proxy2 = useMemo(() => new Proxy(target, handler2), []);

  getInputProps().ref.current = proxy2;

  console.log('groupedOptions.length', groupedOptions.length, getInputProps().value);
  return <div className={clsx("relative", className)}>
    <Input
      value={getInputProps().value?.toString() || ""}
      onChange={v => {
        console.log('onChange');
        if (firstMount) {
          setFirstMount(false);
          return;
        }
        getInputProps().onChange?.({ target: { value: v } } as any);
      }}
      onFocus={() => {
        console.log('onFocus');
        getInputProps().onFocus?.();
      }}
      onBlur={() => {
        console.log('onBlur');
        getInputProps().onBlur?.()
      }}
      {...restInputProps}
    >
      <div className="flex h-full items-center">
        {endAdornment?.(getInputProps().onChange, getInputProps().value?.toString())}
        {
          getInputProps().value &&
          (<div className="relative w-8">
            <CloseIcon
              className="absolute top-1/2 -translate-y-1/2 right-2 cursor-pointer"
              onClick={() => getInputProps().onChange?.({ type: "click", target: { value: "" } } as any)}
            />
          </div>)
        }
      </div>
    </Input>
    {groupedOptions && groupedOptions?.length > 0 && (
        <ul {...getListboxProps()}
            className='z-[10] list-none absolute top-[58px] w-full max-h-[180px] bg-white overflow-y-auto border border-[rgba(0,0,0,0.25)]'
        >
          {(groupedOptions as typeof groupedOptions).map((option, index) => (
            renderOption ? renderOption(getOptionProps({ option, index }), option, {index, inputValue: "", selected: true}, {} as any) :
              <li {...getOptionProps({ option, index })}
                  className={`text-[#212121] self-stretch box-border h-10 flex flex-row items-center justify-between py-1 px-2 relative border-b-[1px] border-solid border-gray-solid-gray-164-e1e1e1 ${(rest.isOptionEqualToValue?.(option, value) || option === getInputProps().value) ? "bg-gray-solid-gray-164-e1e1e1" : ""}`}
                  key={index}>{rest.getOptionLabel?.(option) || option}</li>
          ))}
        </ul>
      )}
  </div>
}

export type AutocompleteProps = Omit<MUIAutocompleteProps<any, boolean, boolean, boolean>, "renderInput"> & {
  inputProps: Pick<InputProps, "className" | "label" | "value" | "onChange"> &  {
    endAdornment?: (onChange?:  React.ChangeEventHandler<HTMLInputElement>, value?: string) => React.ReactNode;
  },
  dontUseNativeInput?: boolean
}

const Autocomplete = ({inputProps: {value, onChange, ...restInputProps}, dontUseNativeInput, ...props}: AutocompleteProps) => {
  return <>
    {
      (dontUseNativeInput || !useNativeInput()) ?
        <CustomAutocomplete
          className="w-full"
          {...props}
          onInputChange={(_e, value) => onChange?.(value)}
          inputProps={restInputProps}
        />
        :
        <MUIAutocomplete
          {...props}
          renderInput={(params) =>
            <TextField {...params}
                       sx={{ "& .MuiInputBase-root": { height: "37px" } }}
                       size={"small"}
                       value={value}
                       className={restInputProps.className}
                       label={restInputProps.label}
                       autoFocus = {true}
                       onChange={e => onChange?.(e.target.value)}
                       InputProps={{
                         ...params.InputProps,
                         endAdornment: (
                           <>
                             { restInputProps.endAdornment?.() }
                             {params.InputProps.endAdornment}
                           </>
                         )
                       }}
            />}
        />
    }
  </>
}

export default Autocomplete;