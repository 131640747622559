import type { RxCollection, RxDatabase, } from "rxdb";
import type { CloverTerminalMetadata } from "@/react/Terminal/CloverTerminal.ts";
import type { ZvtMetadata } from "@/react/Terminal/ZvtTerminal";
import type { StripeTerminalMetadata } from "@/react/Terminal/StripeTerminal";
import { createCollection } from "@/data/utils/migration.ts";

export const TERMINAL_COLLECTION_NAME = 'terminals'

export const VERSION = 1
const TerminalSchema = {
  title: 'Terminal schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    name: { type: 'string', maxLength: 50 },
    type: { type: 'string', maxLength: 50 },
    metadata: {
      type: 'object',
      properties: {
        authKey: { type: 'string' },
        registerId: { type: 'string' },
        timeoutInSeconds: { type: 'number' }
        // TODO: update metadata
      }
    }
    ,
  },
  indexes: []
} as const;

export enum TerminalTypes {
  Clover = "Clover",
  Zvt = "Zvt",
  Simulator = "Simulator",
  CCVA920 = "CCVA920"
  // nuvei: "Nuvei Terminal"
}

export type Terminal = {
  _id: string; // terminal profile _id
  name: string; // terminal profile friendly name
  type: TerminalTypes; // type of terminal
  useAsDefaultForPosDevices: Array<string>; // device ids
  metadata?: StripeTerminalMetadata | CloverTerminalMetadata | ZvtMetadata; // metadata about this terminal
}

export const Terminal = {} as RxCollection<Terminal>

export const createTerminalCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: Terminal,
    collectionName: TERMINAL_COLLECTION_NAME,
    version: VERSION,
    schema: TerminalSchema,
  });
}