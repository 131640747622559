import type { FunctionComponent } from "react";

type PopupNotificationSyncSuccessType = {
  onClose?: () => void;
  message?: string;
};

const PopupNotificationSyncSuccess: FunctionComponent<
  PopupNotificationSyncSuccessType
> = ({ onClose, message }) => {
  return (
    <div className="rounded-md bg-green-solid-green-100-cdffbc shadow-[0px_2px_10px_rgba(0,_0,_0,_0.1)] flex flex-row items-center justify-center py-2.5 px-5 box-border gap-[16px] max-w-full max-h-full overflow-auto text-center text-xl text-blue-solid-blue-900-1c1f37 font-mulish-button-name-bold">
      <img
        className="relative w-[26px] h-[26px] object-cover"
        alt=""
        src="/checkmark-1@2x.png"
      />
      <b className="relative lg:text-lg md:text-base sm:text-sm mq768:text-smi mq600:text-smi">
        {message}
      </b>
      <img
        className="relative w-[25px] h-[25.4px] cursor-pointer"
        alt=""
        src="/close-tab11.svg"
        onClick={onClose}
      />
    </div>
  );
};

export default PopupNotificationSyncSuccess;
