import {type FunctionComponent, memo} from "react";
import ManualTable from "@dashboard/ManualTable";

const DashboardRight: FunctionComponent = () => {
  return (
    <div className="self-stretch flex-1 flex flex-col items-start justify-start relative h-full">
      <ManualTable />
    </div>
  );
};

export default memo(DashboardRight);
