import {type FunctionComponent, memo, useEffect, useRef} from "react";
import SignInBox from "./SignInBox";
import SignUpDefault from "./SignUpDefault";
import SignUpFull from "./SignUpFull";
import SignUpConfirm from "./SignUpConfirm";
import Loading from "./Loading";
import PairingDefault from "./PairingDefault";
import PairingSetup from "./PairingSetup";
import KeyboardWCloseIcon from "./KeyboardWCloseIcon";
import {signal, useSignal} from "@/react/core/reactive.ts";
import {makeRipple} from "@/react/core/ripple-utils.ts";
import {clsx} from "clsx";
import SignUpWrapper from "@sign-in/SignUpWrapper.tsx";
import {type InputController, inputController0} from "@/react/core/Input.tsx";
import PairingWrapper from "@sign-in/PairingWrapper.tsx";
import {setShowKeyboardSignIn, showKeyboardSignIn} from "@/react/SignInView/SignInView.tsx";

type tabState = "signin" | "signup" | "paring";

const SignInPlugin: FunctionComponent = () => {
  const [currentTab, setCurrentTab] = useSignal<tabState>('signin')
  const formContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (showKeyboardSignIn() && formContainerRef.current) {
      formContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [showKeyboardSignIn()]);

  return (
    <div className="w-full h-full flex flex-col items-start justify-start relative gap-[10px] !bg-[url('/sign-in--plugin@3x.png')] bg-cover bg-no-repeat bg-[top] text-center text-4xl text-white-solid-white-100-ffffff font-mulish">
      <div onClick={()=> setShowKeyboardSignIn(false)} className="self-stretch no-scrollbar flex-1 overflow-y-auto flex flex-col items-center justify-start py-10 px-[308px] box-border h-full z-[0] md:pl-[244px] md:pr-[244px] md:box-border sm:pl-[180px] sm:pr-[180px] sm:box-border mq1440:pl-[380px] mq1440:pr-[380px] mq1440:box-border Tablet_768:pl-[100px] Tablet_768:pr-[100px] Tablet_768:box-border Tablet_600:pl-20 Tablet_600:pr-20 Tablet_600:box-border mq480:pl-16 mq480:pr-16 mq480:box-border">
        <div className="self-stretch flex-1 flex flex-col items-center justify-start gap-[20px]">
          <div className="relative font-extralight">Have an account?</div>
          <img
            className="relative w-[42px] h-[51.9px] object-cover"
            alt=""
            src="/lion-logo@2x.png"
          />
          <div className="self-stretch flex flex-row items-start justify-center gap-[16px] text-xl">
            <div className={clsx("flex-1 flex flex-col items-center justify-start h-full lg:box-border")}>
              <div className="self-stretch flex flex-row items-center justify-center">
                <div className={clsx("cursor-pointer flex-1 rounded-t rounded-b-none [backdrop-filter:blur(30px)] h-[49px] flex flex-row items-center justify-center p-1.5 box-border",
                  currentTab()=== 'signin' && 'bg-blue-opacity-blue-15-f3f9ff'
                  )}
                 onClick={()=> setCurrentTab('signin')}
                 ref={makeRipple}
                >
                  <div className="flex-1 relative font-medium">Sign in</div>
                </div>
                <div className={clsx("cursor-pointer flex-1 rounded-t rounded-b-none [backdrop-filter:blur(30px)] h-[49px] flex flex-row items-center justify-center p-1.5 box-border",
                  currentTab()==='signup' && 'bg-blue-opacity-blue-15-f3f9ff'
                  )}
                 onClick={()=> setCurrentTab('signup')}
                 ref={makeRipple}
                >
                  <div className="flex-1 relative font-medium">Sign up</div>
                </div>
                <div className={clsx("cursor-pointer flex-1 rounded-t rounded-b-none [backdrop-filter:blur(30px)] h-[49px] flex flex-row items-center justify-center p-1.5 box-border",
                  currentTab()==='paring' && 'bg-blue-opacity-blue-15-f3f9ff'
                  )}
                 onClick={()=> setCurrentTab('paring')}
                 ref={makeRipple}
                >
                  <div className="flex-1 relative font-medium">Pairing</div>
                </div>
              </div>

              {currentTab() === 'signin' && <SignInBox/>}
              {currentTab() === 'signup' && <SignUpWrapper/>}
              {currentTab() === 'paring' && <PairingWrapper/>}
            </div>
            {/*<div className="flex-1 flex flex-row items-start justify-start gap-[8px] h-full lg:h-full Tablet_768:h-full">*/}
            {/*  <div className="flex-1 hidden flex-col items-center justify-start">*/}
            {/*    <div className="self-stretch flex flex-row items-center justify-center">*/}
            {/*      <div className="flex-1 rounded-t rounded-b-none [backdrop-filter:blur(30px)] h-[49px] flex flex-row items-center justify-center p-1.5 box-border">*/}
            {/*        <div className="flex-1 relative font-medium">Sign in</div>*/}
            {/*      </div>*/}
            {/*      <div className="flex-1 rounded-t rounded-b-none bg-blue-opacity-blue-15-f3f9ff [backdrop-filter:blur(30px)] h-[49px] flex flex-row items-center justify-center p-1.5 box-border">*/}
            {/*        <div className="flex-1 relative font-medium">Sign up</div>*/}
            {/*      </div>*/}
            {/*      <div className="flex-1 rounded-t rounded-b-none [backdrop-filter:blur(30px)] h-[49px] flex flex-row items-center justify-center p-1.5 box-border">*/}
            {/*        <div className="flex-1 relative font-medium">Pairing</div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <SignUpDefault />*/}
            {/*  </div>*/}
            {/*  <div className="flex-1 flex flex-col items-center justify-start">*/}
            {/*    <div className="self-stretch flex flex-row items-center justify-center">*/}
            {/*      <div className="flex-1 rounded-t rounded-b-none [backdrop-filter:blur(30px)] h-[49px] flex flex-row items-center justify-center p-1.5 box-border">*/}
            {/*        <div className="flex-1 relative font-medium">Sign in</div>*/}
            {/*      </div>*/}
            {/*      <div className="flex-1 rounded-t rounded-b-none bg-blue-opacity-blue-15-f3f9ff [backdrop-filter:blur(30px)] h-[49px] flex flex-row items-center justify-center p-1.5 box-border">*/}
            {/*        <div className="flex-1 relative font-medium">Sign up</div>*/}
            {/*      </div>*/}
            {/*      <div className="flex-1 rounded-t rounded-b-none [backdrop-filter:blur(30px)] h-[49px] flex flex-row items-center justify-center p-1.5 box-border">*/}
            {/*        <div className="flex-1 relative font-medium">Pairing</div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <SignUpFull />*/}
            {/*  </div>*/}
            {/*  <div className="flex-1 hidden flex-col items-center justify-start">*/}
            {/*    <div className="self-stretch flex flex-row items-center justify-center">*/}
            {/*      <div className="flex-1 rounded-t rounded-b-none [backdrop-filter:blur(30px)] h-[49px] flex flex-row items-center justify-center p-1.5 box-border">*/}
            {/*        <div className="flex-1 relative font-medium">Sign in</div>*/}
            {/*      </div>*/}
            {/*      <div className="flex-1 rounded-t rounded-b-none bg-blue-opacity-blue-15-f3f9ff [backdrop-filter:blur(30px)] h-[49px] flex flex-row items-center justify-center p-1.5 box-border">*/}
            {/*        <div className="flex-1 relative font-medium">Sign up</div>*/}
            {/*      </div>*/}
            {/*      <div className="flex-1 rounded-t rounded-b-none [backdrop-filter:blur(30px)] h-[49px] flex flex-row items-center justify-center p-1.5 box-border">*/}
            {/*        <div className="flex-1 relative font-medium">Pairing</div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <SignUpConfirm />*/}
            {/*  </div>*/}
            {/*  <div className="self-stretch flex-1 hidden flex-col items-center justify-start">*/}
            {/*    <div className="self-stretch flex flex-row items-center justify-center">*/}
            {/*      <div className="flex-1 rounded-t rounded-b-none [backdrop-filter:blur(30px)] h-[49px] flex flex-row items-center justify-center p-1.5 box-border">*/}
            {/*        <div className="flex-1 relative font-medium">Sign in</div>*/}
            {/*      </div>*/}
            {/*      <div className="flex-1 rounded-t rounded-b-none bg-blue-opacity-blue-15-f3f9ff [backdrop-filter:blur(30px)] h-[49px] flex flex-row items-center justify-center p-1.5 box-border">*/}
            {/*        <div className="flex-1 relative font-medium">Sign up</div>*/}
            {/*      </div>*/}
            {/*      <div className="flex-1 rounded-t rounded-b-none [backdrop-filter:blur(30px)] h-[49px] flex flex-row items-center justify-center p-1.5 box-border">*/}
            {/*        <div className="flex-1 relative font-medium">Pairing</div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <Loading />*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="flex-1 flex flex-row items-start justify-start gap-[8px]">*/}
            {/*  <div className="flex-1 hidden flex-col items-center justify-start h-full">*/}
            {/*    <div className="self-stretch flex flex-row items-center justify-center">*/}
            {/*      <div className="flex-1 rounded-t rounded-b-none [backdrop-filter:blur(30px)] h-[49px] flex flex-row items-center justify-center p-1.5 box-border">*/}
            {/*        <div className="flex-1 relative font-medium">Sign in</div>*/}
            {/*      </div>*/}
            {/*      <div className="flex-1 rounded-t rounded-b-none [backdrop-filter:blur(30px)] h-[49px] flex flex-row items-center justify-center p-1.5 box-border">*/}
            {/*        <div className="flex-1 relative font-medium">Sign up</div>*/}
            {/*      </div>*/}
            {/*      <div className="flex-1 rounded-t rounded-b-none bg-blue-opacity-blue-15-f3f9ff [backdrop-filter:blur(30px)] h-[49px] flex flex-row items-center justify-center p-1.5 box-border">*/}
            {/*        <div className="flex-1 relative font-medium">Pairing</div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <PairingDefault />*/}
            {/*  </div>*/}
            {/*  <div className="flex-1 flex flex-col items-center justify-start h-full">*/}
            {/*    <div className="self-stretch flex flex-row items-center justify-center">*/}
            {/*      <div className="flex-1 rounded-t rounded-b-none [backdrop-filter:blur(30px)] h-[49px] flex flex-row items-center justify-center p-1.5 box-border">*/}
            {/*        <div className="flex-1 relative font-medium">Sign in</div>*/}
            {/*      </div>*/}
            {/*      <div className="flex-1 rounded-t rounded-b-none [backdrop-filter:blur(30px)] h-[49px] flex flex-row items-center justify-center p-1.5 box-border">*/}
            {/*        <div className="flex-1 relative font-medium">Sign up</div>*/}
            {/*      </div>*/}
            {/*      <div className="flex-1 rounded-t rounded-b-none bg-blue-opacity-blue-15-f3f9ff [backdrop-filter:blur(30px)] h-[49px] flex flex-row items-center justify-center p-1.5 box-border">*/}
            {/*        <div className="flex-1 relative font-medium">Pairing</div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <PairingSetup />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          {/*<div className="self-stretch relative h-20" />*/}
          {showKeyboardSignIn() && <div ref={formContainerRef} className="self-stretch relative h-[100px]"/>}
          {/*<div className="self-stretch relative h-20" />*/}
        </div>
      </div>

      { showKeyboardSignIn() &&
        <div className="my-0 mx-[!important] absolute w-full right-[0px] bottom-[0px] left-[0px] flex flex-col items-center justify-start z-[1]">
          <KeyboardWCloseIcon inputController={inputController0}/>
        </div>
      }
    </div>
  );
};

export default memo(SignInPlugin);
