import { type Order, PaidOrder } from "@/data/Order.ts";
import dayjs from "dayjs";
import _ from "lodash";
import { convertDocuments } from "@/data/data-utils.ts";
import { Eod } from "@/data/Eod.ts";
import uuid from "time-uuid";
import { clearEodCache } from "@/pos/logic/order-utils.ts";
import { cashPaymentName0 } from "@/data/PaymentHub.ts";
import {maxId0} from '@/data/MaxIdHub.ts'
import { getVDate } from "@/pos/orderUtils.ts";
import { batch } from "@/react/core/reactive.ts";
import { PaymentType } from "../Payment"

export function eodFixFactory({ from, to, fromEqual, toEqual }: {
  from?: string, to?: string, fromEqual?: string, toEqual?: string
}) {
  let orders: Order[] = [];
  return {
    generateEodsFrom,
    removeOrders,
    removeEods,
    fixOrderCash,
    fixOrderCard,
    ensureLastZForMaxId0,
    fixOrderVDate,
    generateEodsAndFixDate
  }

  async function fixOrderVDate() {
    const order3s = await queryOrders();
    for (const order of order3s) {
      if (!order.vDate) {
        order.vDate = getVDate(order.date!)
      }
    }
  }

  async function ensureLastZForMaxId0() {
    //tim last z bang eod hoac order roi assign vao maxId0().z
    const lastZ = await Eod.findOne({
        selector: {},
        sort: [{z: 'desc'}]
      }).exec();
    console.log(`last z is: ${JSON.stringify(lastZ)}`)
    if (lastZ !== null && typeof lastZ !== 'undefined') {
      _.assign(maxId0(), {
        z: lastZ.z
      })
    }
  }

  async function generateEodsFrom(beginZ: number) {
    await removeEods();
    const fromDate = dayjs(from, 'DD.MM.YYYY');
    const group = await queryGroups();
    let z = beginZ;

    for (const key in group) {
      const orders = group[key];
      const _date = dayjs(key, 'DD.MM.YYYY').unix();
      await Eod.upsert({ _id: uuid(), vDate: _date, z: z, complete: false, date: _date });
      for (const order of orders) {
        order.z = z;
      }
      z++;
    }
    await clearEodCache();
    await ensureLastZForMaxId0();
  }

  async function generateEodsAndFixDate(beginZ: number) {
    await removeEods();
    const fromDate = dayjs(from, 'DD.MM.YYYY');
    const group = await queryGroups();
    let z = beginZ;

    for (const key in group) {
      const orders = group[key];
      const _date = dayjs.unix(orders[0].date!).startOf('d').unix();
      await Eod.upsert({ _id: uuid(), vDate: _date, z: z, complete: false, date: _date });
      batch(() => {
        for (const order of orders) {
          // if (order.pickupDate) {
          //   order.date = dayjs(order.pickupDate).unix();
          // } else {
          //   order.date = _.last(order.items)!.date;
          // }
          order.vDate = getVDate(order.date!);
          order.z = z;
          if (order.masterHandlers?.status !== 'completed') {
            order.masterHandlers.status = 'completed';
          }
        }
      })
      z++;
    }
    await clearEodCache();
    await ensureLastZForMaxId0();
  }

  async function fixOrderCash() {
    await queryOrders();
    for (const order of orders) {
      if (order.payments[0]?.type === 'cash' || order.payments[0]?.type === 'Cash' || order.payments[0]?.extraType === PaymentType.Cash) {
        const o: any = {}
        o.payments[0].type = cashPaymentName0();
        o.payments[0].extraType = PaymentType.Cash;
        o.masterHandlers = {};
        _.assign(order, o);
      }
    }
    await clearEodCache();
  }

  async function fixOrderCard() {
    await queryOrders();
    for (const order of orders) {
      if (order.payments[0]?.type === 'card' || order.payments[0]?.type === 'Card') {
        const o: any = {}
        o.payments[0].type = 'Karte';
        o.payments[0].extraType = undefined
        o.masterHandlers = {};
        _.assign(order, o);
      }
    }
    await clearEodCache();
  }

  async function queryOrders() {
    const order2s = await PaidOrder.find({ selector: { date: makeSelector() } }).exec();
    const order3s = _.sortBy(convertDocuments(order2s, true), 'date');
    orders = order3s as Order[]
    return orders;
  }

  async function queryGroups() {
    const order3s = await queryOrders();
    const group = _.groupBy(order3s, o => dayjs.unix(o.vDate!).format('DD.MM.YYYY'));
    return group;
  }

  function makeSelector() {
    if (from) {
      return { $gt: dayjs(from, 'DD.MM.YYYY').unix() }
    } else if (to) {
      return { $lt: dayjs(to, 'DD.MM.YYYY').unix() }
    } else if (fromEqual) {
      return { $gte: dayjs(fromEqual, 'DD.MM.YYYY').unix() }
    } else if (toEqual) {
      return { $lte: dayjs(toEqual, 'DD.MM.YYYY').unix() }
    } else {
      return { $gt: 0 }
    }
  }

  async function removeOrders() {
    const paidOrders = await PaidOrder.find({ selector: { vDate: makeSelector() } }).exec();
    for (const order of paidOrders || []) await order.remove();
  }

  async function removeEods() {
    const _eods = await Eod.find({ selector: { vDate: makeSelector() } }).exec();
    // let eods = convertDocuments(_eods, true);
    for (const eod of _eods) {
      await eod.remove();
    }
    await clearEodCache();
  }
}

async function fix1() {
  // let api = eodFixFactory({to: '30.04.2024'});
  // await api.removeOrders();
  // await eodFixFactory({}).removeEods();k

  let api = eodFixFactory({ fromEqual: '30.06.2024' });
  // await api.removeEods();
  // await api.fixOrderVDate();
  // await api.generateEodsFrom(1);
  await api.generateEodsAndFixDate(1)

  // let api = eodFixFactory({});
  // await api.fixOrderCash();
  // await api.fixOrderCard();
  console.log('finish');
}

//@ts-ignore
window.fix1 = fix1;