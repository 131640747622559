import Queue from 'queue';
import { rnHost } from "@/shared/webview/rnwebview";
import uuid from "time-uuid";
import { captureException } from "@sentry/react";
import debug from 'debug';
import { notificationToast } from "@/react/FloorPlanView/Noti.ts";
import _ from "lodash";
import pTimeout from "p-timeout";
import delay from "delay";
import { allConfig } from "@/extensions/firebase/useFirebase.ts";
import { convertToBase64Png } from "@/react/Printer/print-kitchen-utils.ts";
import { isAlwaysHidePopup, printerErrDialog } from "@/react/Printer/printer-dialog.tsx";
import { testNetworkPrinter } from "@/react/Printer/print-network.ts";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import { groupPrinters0 } from "@/data/GroupPrinterHub.ts";

const log = debug('printer:lanManager');

const queueManager: {
  [k: string]: { q: Queue, id: string, opened: boolean, timeoutId?: ReturnType<typeof setTimeout> }
} = {}

const LIMIT = import.meta.env.MODE === 'production' ? 1000 : 10;

export const printStar = (task: { data: string, dropConnection: boolean, printerIp: string, width: number }) => {
  log('print Lan ', task.printerIp);

  const notiErr = _.debounce((e: any) => {
    notificationToast(`print to ip: ${task.printerIp} error ${e.message}`, { autoClose: 30 * 1000 });
  }, 30000, { leading: true });

  queueManager[task.printerIp] = queueManager[task.printerIp] || {
    q: new Queue({ autostart: true, concurrency: 1 }),
    id: task.printerIp,
    opened: false,
  }

  queueManager[task.printerIp].q.push(async () => {
    let count = 0;
    while (count < LIMIT) {
      try {
        const payload = task.data;
        const _uuid = queueManager[task.printerIp].id;
        try {
          await pTimeout(rnHost.add(1, 1), {
            milliseconds: 1000,
          })
        } catch (e) {
          throw new Error('Comlink fail');
        }

        await rnHost.printStar(payload, task.width, task.printerIp);
        break;
      } catch (e: any) {
        notiErr(e);
        captureException(new Error(`print to star: ${task.printerIp} error ${e.message}`), { tags: { type: 'print' } });
        console.error(`print to star: ${task.printerIp} error`, e);
        const printer = groupPrinters0().find(g => g.printers[0].printerType === 'star' && g.printers[0].ip === task.printerIp);
        if (generalSetting0().showPopupPrintError && !isAlwaysHidePopup() && printer) {
          await printerErrDialog(LL0().printing.printerError({
            printer: _.upperFirst(printer?.name),
            printerType: _.upperFirst(printer?.printers[0].printerType)
          }), printer?.printers[0].ip, testNetworkPrinter)
        }
        //todo: emit to virtual printer
      } finally {
        count++;
      }
    }
  })
}
