import { type FunctionComponent, memo } from 'react'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import { router } from '@/pos/PosRouter.ts'
import Input, { inputController0 } from '@/react/core/Input.tsx'
import TableManualKeyboard from '@/react/core/TableManualKeyboard.tsx'
import { LL0 } from '@/react/core/I18nService.tsx'
import MockTSEPopup from "@/react/Developer/MockTSEPopup.tsx";
import { onTerminalEnter } from "@/react/TerminalView/TerminalView.tsx";
import { mainScreen } from "@/data/PosSettingsSignal.ts";

const TerminalPlugin: FunctionComponent = () => {
  //todo:
  //command 1
  return (
    <>
      <div
        className="terminal-screen w-full h-full rounded-sm bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-start justify-start text-left text-[15px] text-gray-solid-gray-850-767676 font-mulish">
        <div
          className="self-stretch bg-gray-solid-gray-130-f4f4f4 [backdrop-filter:blur(8px)] h-[46px] flex flex-row items-center justify-between py-0 pr-2 pl-6 box-border sm:h-[42px] Tablet_768:pl-4 Tablet_768:box-border Tablet_600:pl-2 Tablet_600:box-border height_414:h-[42px]">
          <b className="relative uppercase sm:text-sm height_414:text-sm mq1440:text-lg">{LL0().dashboard.support()}</b>
          <div
            className="rounded-[100px] bg-blue-solid-blue-450-1271ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-9 overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border min-w-[72px] sm:h-8 sm:min-w-[68px] Tablet_768:h-[30px] Tablet_600:h-[30px] Tablet_600:pl-2.5 Tablet_600:box-border Tablet_600:min-w-[64px] Mobile_480:h-[30px] height_414:h-[30px] mq320:h-[30px]"
            ref={makeRipple}
            onClick={() => (router.screen = mainScreen())}
          >
            <img
              className="w-[30px] relative h-[30px] object-cover"
              alt=""
              src="/iconback-icon@2x.png"
            />
          </div>
        </div>
        <div
          className="self-stretch flex-1 flex flex-col items-start justify-start text-center text-3xl text-black-solid-black-1000-000000">
          <div className="overflow-y-auto no-scrollbar self-stretch flex-1" />
          <div
            className="self-stretch rounded-10xs bg-gray-solid-gray-180-ebecee flex flex-col items-start justify-start pt-1 px-0 pb-0">
            <div className="self-stretch flex flex-col items-start justify-start py-0 px-3">
              <Input
                className="w-full font-mulish italic text-[13px] text-gray"
                refInputController={i => {
                  i.setFocus(true)
                  i.moveCaretToEnd()
                }}
                value=""
              />
            </div>
            <TableManualKeyboard
              enterText={'Enter'}
              onEnter={ async () => {
                await onTerminalEnter()
              }}
              inputController={inputController0}
            />
          </div>
        </div>
      </div>
      <MockTSEPopup/>
    </>
  )
}

export default memo(TerminalPlugin)
