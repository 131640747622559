import { type CSSProperties, type FunctionComponent, useCallback, useContext, useMemo, useState } from 'react'
import { FormControlLabel, Switch } from '@mui/material'
import {
  handleAddSeat,
  isShowPrice,
  isShowToolbar,
  onMergeSeats,
  onToggleTakeOut,
  onGroupBill,
  ScreenMode,
  setIsShowPrice,
  setIsShowToolbar,
  setScreenMode,
  setShowTabSidebar,
  setTabDiscountPopup,
  setVoucherStatus,
  TabsDiscountPopup
} from '@/react/OrderView/OrderView.tsx'
import { LL0 } from '@/react/core/I18nService.tsx'
import PortalPopup from '@order-view/PortalPopup.tsx'
import DiscountPopupItem from '@order-view/DiscountPopup/DiscountPopupItem.tsx'
import OrderScreenCustomDishPo from '@order-view/OrderScreenCustomDishPo.tsx'
import { AccessPort, getAccessibility } from '@/react/DecentralizationPort.ts'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import { printInvoice } from '@/react/Printer/print-invoice.ts'
import { InvoiceTypes } from '@/pos/OrderType.ts'
import { ItemFactoryContext } from '@/react/PaymentView/OrderItemsFactory2.tsx'
import OrderScreenFunctionsPopup from '@order-view/OrderScreenFunctionsPopup.tsx'
import { shouldWarnBecauseOffline } from "@/data/ReplicateEffect.ts";
import { toast } from "react-toastify";
import PrintBillForSeats from "./PrintBillForSeats";
import { openCashDrawer } from "@/pos/cashdrawer.ts";
import { isFeatureEnabled, PosFeatures } from '@/srm/features.logic'
import VoucherPopup from "@/react/VoucherView/VoucherPopup.tsx";
import { clsx } from "clsx";
import { isSmallSidebar, isVerticalMenu } from "@/data/DeviceSettingHub.ts";
import _ from "lodash";
import SplitBillEquallyPopup from "@order-view/SplitBillEquallyPopup.tsx";
import { signal } from "@/react/core/reactive.ts";
import { checkItemBeforeSplit, removeDiscountBeforeSplit } from "@/react/OrderView/order-splitbill-utils.ts";
import PrintKitchenPopUp from "@order-view/Popups/PrintKitchenPopUp.tsx";
import { generalSetting0, isQuebecSrmEnabled, posSetting0 } from "@/data/PosSettingsSignal.ts";
import { dialogConfirmMergeSeat } from "@order-view/Popups/dialogConfirmMergeSeat.tsx";
import ConfirmMergeTablePopUp, {
  isOpenMergeSeatPopUp,
  setIsOpenMergeSeatPopup
} from "@order-view/Popups/ConfirmMergeTablePopUp.tsx";
import ConfirmMergeSeatBeforeMove from "@order-view/Popups/ConfirmMergeSeatBeforeMove.tsx";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { MenuDirection } from "@/data/MenuDirection.ts";
import OrderGroupPopup from "@order-view/NoteOrderPopup.tsx";
import { invoiceFLow } from "@/shared/logger.ts";
import CustomerInfoPopup from "@order-view/Popups/CustomerInfoPopup.tsx";


type OrderBurgerMenuButtonType = {
  isExpanded?: boolean;
  isSelected?: boolean;
  showButtonText?: boolean;

  /** Style props */
  iconTooltipArrowUpIconWidth?: CSSProperties['width'];
  iconTooltipArrowUpIconHeight?: CSSProperties['height'];
  buttonCursor?: CSSProperties['cursor'];
  buttonCursor1?: CSSProperties['cursor'];
  buttonCursor2?: CSSProperties['cursor'];
};

export const [isPrintKitchenPopUpOpen, setPrintKitchenPopUpOpen] = signal<boolean>(false);

export const [isSplitBillEquallyPopupOpen, setSplitBillEquallyPopupOpen] =
  signal<boolean>(false);

export const [isConfirmMergeTablePopupOpen, setIsConfirmMergeTablePopupOpen] = signal<boolean>(false);

export const [isOpenCustomerInfoPopup, setIsOpenCustomerInfoPopup] = signal<boolean>(false);

const OrderBurgerMenuButton: FunctionComponent<OrderBurgerMenuButtonType> = (
  {
    iconTooltipArrowUpIconWidth,
    iconTooltipArrowUpIconHeight
  }) => {
  const { order0, splitEnable, onSeatToggle, onMoveToggle, onSplitCancel } = useContext(ItemFactoryContext)
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const closeMenu = () => setIsExpanded(false)
  const [isPrintBillForSeatsOpen, setPrintBillForSeatsOpen] = useState(false);
  const [isOrderScreenFunctionsPopupOpen, setOrderScreenFunctionsPopupOpen] =
    useState(false)
  const [isDiscountPopupOpen, setDiscountPopupOpen] = useState(false)
  const [isVoucherPopupOpen, setVoucherPopupOpen] = useState(false)
  const [isOrderSreenCustomDishPoOpen, setOrderSreenCustomDishPoOpen] =
    useState(false)
  const [isNoteOrderPopupOpen, setNoteOrderPopupOpen] =
    useState(false)

  const openOrderScreenFunctionsPopup = useCallback(() => {
    setOrderScreenFunctionsPopupOpen(true);
  }, []);

  const closeOrderScreenFunctionsPopup = useCallback(() => {
    setOrderScreenFunctionsPopupOpen(false);
  }, []);
  const openDiscountPopup = useCallback(() => {
    setDiscountPopupOpen(true)
    setShowTabSidebar(false)
    setTabDiscountPopup(TabsDiscountPopup.DISCOUNT)
    closeMenu()
  }, [])

  const closeDiscountPopup = useCallback(() => {
    setDiscountPopupOpen(false)
  }, [])

  const openVoucherPopup = useCallback(() => {
    setVoucherPopupOpen(true)
    closeMenu()
  }, [])

  const closeVoucherPopup = useCallback(() => {
    setVoucherStatus("")
    setVoucherPopupOpen(false)
  }, [])

  const openOrderSreenCustomDishPo = useCallback(() => {
    setOrderSreenCustomDishPoOpen(true)
    closeMenu()
  }, [])

  const closeOrderSreenCustomDishPo = useCallback(() => {
    setOrderSreenCustomDishPoOpen(false)
  }, [])

  const openPrintBillForSeats = useCallback(() => {
    setPrintBillForSeatsOpen(true);
  }, []);

  const closePrintBillForSeats = useCallback(() => {
    setPrintBillForSeatsOpen(false);
  }, []);
  const openSplitBillEquallyPopup = useCallback(() => {
    setSplitBillEquallyPopupOpen(true);
  }, []);
  const closeSplitBillEquallyPopup = useCallback(() => {
    setSplitBillEquallyPopupOpen(false);
  }, []);
  const iconTooltipArrowUpIconStyle: CSSProperties = useMemo(() => {
    return {
      width: iconTooltipArrowUpIconWidth,
      height: iconTooltipArrowUpIconHeight
    }
  }, [iconTooltipArrowUpIconWidth, iconTooltipArrowUpIconHeight])

  return (
    <>
      {isExpanded &&
        <div className="z-[1] w-full h-full fixed top-0 left-0 opacity-0"
             onClick={closeMenu}
        />
      }
      <div
        className="order-button-more flex flex-col items-center justify-start gap-[8px] text-left text-sm text-black-solid-black-900-1e1e23 font-mulish">
        {!isExpanded &&
          <img
            className="relative w-10 h-10 object-cover"
            alt=""
            src={!order0?.().takeAway ? '/iconburger-menu-icon@2x.png' : '/iconorderscreentakeaway@2x.png'}
            onClick={() => setIsExpanded(prev => !prev)}
          />
        }
        {isExpanded && (
          <div className="flex flex-col items-start justify-start relative">
            <img
              className="w-10 relative h-10 object-cover opacity-[0.5] z-[0] Tablet_768:w-[34px] Tablet_768:h-[34px] Tablet_600:w-[34px] Tablet_600:h-[34px] Mobile_480:w-[34px] Mobile_480:h-[34px] height_414:w-[34px] height_414:h-[34px]"
              alt=""
              src="/iconburger-menu-expanded-icon@2x.png"
            />
            <div
              className={clsx("!m-[0] absolute top-full flex flex-col items-center justify-start z-[1] height_414:h-60",
                isSmallSidebar() ? 'right-1/2' : 'left-[calc(50%_-_202px)]'
              )}
            >
              <div className={clsx("self-stretch flex flex-col justify-start",
                isSmallSidebar() ? 'items-end' : 'items-center '
              )}>
                <img
                  className="w-2.5 relative h-[9px] object-cover"
                  alt=""
                  src="/icontooltip-arrow-up-icon@2x.png"
                  style={iconTooltipArrowUpIconStyle}
                />
              </div>
              <div
                className="w-[404px] shadow-[0px_3px_4px_rgba(0,_0,_0,_0.3)] rounded bg-aliceblue-100 overflow-y-auto flex flex-row flex-wrap items-start justify-start py-2.5 px-2 box-border gap-[10px]">
                <div
                  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] cursor-pointer border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                  ref={makeRipple}
                  onClick={async () => {
                    closeMenu()
                    if (shouldWarnBecauseOffline()) return toast.error('Master is not available', { autoClose: 2000 })
                    const _order = order0?.()
                    if (!_order) return
                    if (!_order.isGroupBill && _order.seatMap?.length) {
                      openPrintBillForSeats()
                    } else {
                      invoiceFLow('print invoice from order-view');
                      await printInvoice(_order, InvoiceTypes.GUEST_CHECK)
                    }
                  }}
                >
                  <div
                    className="!hidden w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff z-[0]" />
                  <img
                    className="w-[30px] relative h-[30px] object-cover z-[1]"
                    alt=""
                    src="/iconorderunpaid-bill-icon@2x.png"
                  />
                  <b className="!flex flex-1 relative leading-[17px] hidden z-[2]">
                    {LL0().order.invoiceTypes.guestCheck()}
                  </b>
                  <b className="!hidden flex-1 relative leading-[17px] text-white z-[3]">
                    {LL0().order.invoiceTypes.guestCheck()}
                  </b>
                </div>
                {getAccessibility(AccessPort.OPEN_CASH_DRAWER_ANY_TIME) &&
                  <div
                    className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                    ref={makeRipple}
                    onClick={() => {
                      openCashDrawer()
                      closeMenu()
                    }}
                  >
                    <div
                      className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                    <img
                      className="w-[30px] relative h-[30px] object-cover z-[1]"
                      alt=""
                      src="/orderdrawer-icon@2x.png"
                    />
                    <b className="flex-1 relative leading-[17px] z-[2]">
                      {LL0().order.openCashDrawer()}
                    </b>
                    <b className="w-[69px] relative leading-[17px] hidden text-white z-[3]">
                      {LL0().order.openCashDrawer()}
                    </b>
                  </div>}
                <div
                  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                  onClick={() => {
                    onToggleTakeOut()
                    closeMenu()
                  }}
                  ref={makeRipple}
                >
                  {order0?.().takeAway &&
                    <div
                      className="!flex w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />}
                  <img
                    className="w-[30px] relative h-[30px] object-cover z-[1]"
                    alt=""
                    src="/ordertake-out-icon@2x.png"
                  />
                  {order0?.().takeAway ?
                    <b className="flex-1 relative leading-[17px] text-white z-[3]">
                      {LL0().order.takeAway()}
                    </b>
                    :
                    <b className="flex-1 relative leading-[17px] z-[2]">
                      {LL0().order.takeAway()}
                    </b>
                  }
                </div>
                <div
                  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] cursor-pointer border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                  // ref={makeRipple}
                  // onClick={openEditMenuChangeMenuPopup}
                >
                  <div
                    className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                  <img
                    className="w-[30px] relative h-[30px] object-cover z-[1]"
                    alt=""
                    src="/order-change-menu-price-icon2@2x.png"
                  />
                  <b className="flex-1 relative leading-[17px] z-[2]">
                    {LL0().order.changeMenuPrice()}
                  </b>
                  <b className="w-[69px] relative leading-[17px] hidden text-white z-[3]">
                    {LL0().order.changeMenuPrice()}
                  </b>
                </div>
                {isFeatureEnabled(PosFeatures.SplitBill) && (order0?.().items.length === 0 || (!splitEnable?.() && order0?.().seatMode) ?
                  <div
                    className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                    ref={makeRipple}
                    onClick={() => {
                      handleAddSeat()
                      closeMenu()
                    }}
                  >
                    <div
                      className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                    <img
                      className="w-[30px] relative h-[30px] object-cover z-[1]"
                      alt=""
                      src="/iconaddseat-icon@2x.png"
                    />
                    <b className="flex-1 relative leading-[17px] z-[2]">
                      {LL0().order.addSeat()}
                    </b>
                    <b className="w-[69px] relative leading-[17px] hidden text-white z-[3]">
                      {LL0().order.addSeat()}
                    </b>
                  </div>
                  :
                  <div
                    className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                    onClick={() => {
                      closeMenu()
                      if (checkItemBeforeSplit(order0?.()!)) {
                        removeDiscountBeforeSplit(order0?.()!)
                        onSeatToggle?.()
                      } else {
                        setPrintKitchenPopUpOpen(true)
                      }
                    }}
                    ref={makeRipple}
                  >
                    <div
                      className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                    <img
                      className="w-[30px] relative h-[30px] object-cover z-[1]"
                      alt=""
                      src="/ordersplit-bill-icon1@2x.png"
                    />
                    <b className="flex-1 relative leading-[17px] z-[2]">
                      {LL0().order.splitOrder()}
                    </b>
                    <b className="w-[69px] relative leading-[17px] hidden text-white z-[3]">
                      {LL0().order.splitOrder()}
                    </b>
                  </div>)}
                {isFeatureEnabled(PosFeatures.SplitBill) && order0?.().seatMode &&
                  <div
                    className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                    onClick={() => {
                      closeMenu()
                      if (checkItemBeforeSplit(order0?.())) {
                        removeDiscountBeforeSplit(order0?.())
                        onSeatToggle?.()
                      } else {
                        setPrintKitchenPopUpOpen(true)
                      }
                    }}
                    ref={makeRipple}
                  >
                    <div
                      className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                    <img
                      className="w-[30px] relative h-[30px] object-cover z-[1]"
                      alt=""
                      src="/ordersplit-bill-icon1@2x.png"
                    />
                    <b className="flex-1 relative leading-[17px] z-[2]">
                      {LL0().order.reDivision()}
                    </b>
                    <b className="w-[69px] relative leading-[17px] hidden text-white z-[3]">
                      {LL0().order.reDivision()}
                    </b>
                  </div>
                }
                {isFeatureEnabled(PosFeatures.SplitBill) &&
                  <div
                    className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 border-blue-solid-blue-300-3faaff border-[1px] border-solid box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-1 cursor-pointer"
                    onClick={() => {
                      openSplitBillEquallyPopup()
                      closeMenu()
                    }}
                  >
                    <div
                      className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                    <img
                      className="w-[30px] relative h-[30px] object-cover z-[1]"
                      alt=""
                      src="/order-equally-split-icon@2x.png"
                    />
                    <b className="flex-1 relative leading-[17px] z-[2]">
                      {LL0().order.equalSplit()}
                    </b>
                    <b className="w-[69px] relative leading-[17px] hidden text-white-solid-white-100-ffffff z-[3]">
                      {LL0().order.equalSplit()}
                    </b>
                  </div>
                }
                {isFeatureEnabled(PosFeatures.MoveTable) /*&& !order0?.().seatMode && order0?.().payable */ && generalSetting0()?.hasTablePlan &&
                  <div
                    className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                    onClick={() => {
                      setIsConfirmMergeTablePopupOpen(true)
                      // onMoveToggle?.()
                      closeMenu()
                    }}
                    ref={makeRipple}
                  >
                    <div
                      className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                    <img
                      className="w-[30px] relative h-[30px] object-cover z-[1]"
                      alt=""
                      src="/ordermove-table-icon@2x.png"
                    />
                    <b className="flex-1 relative leading-[17px] z-[2]">
                      {LL0().order.moveTable()}
                    </b>
                    <b className="w-[69px] relative leading-[17px] hidden text-white z-[3]">
                      {LL0().order.moveTable()}
                    </b>
                  </div>}
                {getAccessibility(AccessPort.DISCOUNT) &&
                  <div
                    className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                    onClick={() => {
                      openDiscountPopup()
                      closeMenu()
                    }}
                    ref={makeRipple}
                  >
                    <div
                      className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                    <img
                      className="w-[30px] relative h-[30px] object-cover z-[1]"
                      alt=""
                      src="/orderdiscount-icon@2x.png"
                    />
                    <b className="flex-1 relative leading-[17px] z-[2]">
                      {LL0().order.discount()}
                    </b>
                    <b className="w-[69px] relative leading-[17px] hidden text-white z-[3]">
                      {LL0().order.discount()}
                    </b>
                  </div>}
                <div
                  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                  onClick={() => {
                    openVoucherPopup()
                    closeMenu()
                  }}
                  ref={makeRipple}
                >
                  <div
                    className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                  <img
                    className="w-[30px] relative h-[30px] object-cover z-[1]"
                    alt=""
                    src="/ordervoucher-icon2@2x.png"
                  />
                  <b className="flex-1 relative leading-[17px] z-[2]">
                    {LL0().order.voucher()}
                  </b>
                  <b className="w-[69px] relative leading-[17px] hidden text-white z-[3]">
                    {LL0().order.voucher()}
                  </b>
                </div>
                {isFeatureEnabled(PosFeatures.CustomDish) &&
                  <div
                    className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                    onClick={() => {
                      openOrderSreenCustomDishPo()
                      closeMenu()
                    }}
                    ref={makeRipple}
                  >
                    <div
                      className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                    <img
                      className="w-[30px] relative h-[30px] object-cover z-[1]"
                      alt=""
                      src="/ordercustom-dish-icon@2x.png"
                    />
                    <b className="flex-1 relative leading-[17px] z-[2]">
                      {LL0().order.customDish()}
                    </b>
                    <b className="w-[69px] relative leading-[17px] hidden text-white z-[3]">
                      {LL0().order.customDish()}
                    </b>
                  </div>}
                <div
                  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                  ref={makeRipple}
                  onClick={() => {
                    setScreenMode(ScreenMode.EDIT)
                    closeMenu()
                  }}
                >
                  <div
                    className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                  <img
                    className="w-[30px] relative h-[30px] object-cover z-[1]"
                    alt=""
                    src="/orderedit-screen-icon1@2x.png"
                  />
                  <b className="flex-1 relative leading-[17px] z-[2]">
                    {LL0().order.editScreen()}
                  </b>
                  <b className="w-[69px] relative leading-[17px] hidden text-white z-[3]">
                    {LL0().order.editScreen()}
                  </b>
                </div>
                {(isQuebecSrmEnabled() && order0?.()?.seatMode) &&
                  <div
                    className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 pr-1.5 pl-2 border-[1px] border-solid border-blue-solid-blue-300-3faaff">
                    <div className="w-9 relative h-[18px]">
                      <FormControlLabel
                        label={''}
                        className="absolute h-full w-[83.33%] top-[0%] right-[11.11%] bottom-[0%] left-[5.56%]"
                        control={<Switch color="primary" size="small"
                                         checked={order0?.()?.isGroupBill || false}
                                         onChange={(e) => {
                                           if (order0)
                                             onGroupBill(order0(), e.target.checked)
                                         }} />}
                      />
                    </div>
                    <b className="flex-1 relative leading-[17px]">
                      {LL0().order.groupBills()}
                    </b>
                  </div>}
                {order0?.()?.seatMode &&
                  <div
                    className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                    ref={makeRipple}
                    onClick={async () => {
                      await dialogConfirmMergeSeat(() => {
                        if (onSplitCancel && order0) {
                          onSplitCancel(false)
                          onMergeSeats(order0())
                          closeMenu()
                        }
                      })
                    }}
                  >
                    <div
                      className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                    <img
                      className="w-[30px] relative h-[30px] object-cover z-[1]"
                      alt=""
                      src="/icon-merge.png"
                    />
                    <b className="flex-1 relative leading-[17px] z-[2]">
                      {LL0().order.mergeSeats()}
                    </b>
                  </div>}
                {/*{isQuebecSrmEnabled() && order0?.().table && isOrderAlreadyTransmitted(order0()) &&*/}
                {/*  <div className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"*/}
                {/*       ref={makeRipple}*/}
                {/*       onClick={() => srmTransactionLogic.cancelProcessingOrder(order0(), true)}*/}
                {/*  >*/}
                {/*    <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />*/}
                {/*    <img*/}
                {/*      className="w-[24px] relative h-[24px] object-cover z-[1]"*/}
                {/*      alt=""*/}
                {/*      src="/payment-close-popup-icon--grey@2x.png"*/}
                {/*    />*/}
                {/*    <b className="flex-1 relative leading-[17px] z-[2]">*/}
                {/*      {LL0().order.receipt.cancelBill()}*/}
                {/*    </b>*/}
                {/*    <b className="!w-full w-[69px] relative leading-[17px] hidden text-white z-[3]">*/}
                {/*      {LL0().order.receipt.cancelBill()}*/}
                {/*    </b>*/}
                {/*  </div>}*/}
                <div
                  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 pr-1.5 pl-2 border-[1px] border-solid border-blue-solid-blue-300-3faaff">
                  <div className="w-9 relative h-[18px]">
                    <FormControlLabel
                      label={''}
                      className="absolute h-full w-[83.33%] top-[0%] right-[11.11%] bottom-[0%] left-[5.56%]"
                      control={<Switch color="primary" size="small"
                                       checked={isShowToolbar() || false}
                                       onChange={e => setIsShowToolbar(e.target.checked)}
                      />}
                    />
                  </div>
                  <b className="flex-1 relative leading-[17px]">{LL0().order.showToolbar()}</b>
                </div>
                <div
                  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 pr-1.5 pl-2 border-[1px] border-solid border-blue-solid-blue-300-3faaff">
                  <div className="w-9 relative h-[18px]">
                    <FormControlLabel
                      label={''}
                      className="absolute h-full w-[83.33%] top-[0%] right-[11.11%] bottom-[0%] left-[5.56%]"
                      control={<Switch color="primary" size="small"
                                       checked={isShowPrice() || false}
                                       onChange={e => setIsShowPrice(e.target.checked)}
                      />}
                    />
                  </div>
                  <b className="flex-1 relative leading-[17px]">
                    {LL0().order.showTotal()}
                  </b>
                </div>
                <div
                  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 pr-1.5 pl-2 border-[1px] border-solid border-blue-solid-blue-300-3faaff">
                  <div className="w-9 relative h-[18px]">
                    <FormControlLabel
                      label={''}
                      className="absolute h-full w-[83.33%] top-[0%] right-[11.11%] bottom-[0%] left-[5.56%]"
                      control={<Switch color="primary" size="small"
                                       checked={isVerticalMenu() || false}
                                       onChange={e => {
                                         const newMenuDirection = e.target.checked ? MenuDirection.VERTICAL : MenuDirection.HORIZONTAL;
                                         _.assign(deviceSetting0()?.screenConfig, { menuDirection: newMenuDirection });
                                       }}
                      />}
                    />
                  </div>
                  <b className="flex-1 relative leading-[17px]">
                    {LL0().productViewSetting.verticalCategory()}
                  </b>
                </div>
                <div
                  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 pr-1.5 pl-2 border-[1px] border-solid border-blue-solid-blue-300-3faaff">
                  <div className="w-9 relative h-[18px]">
                    <FormControlLabel
                      label={''}
                      className="absolute h-full w-[83.33%] top-[0%] right-[11.11%] bottom-[0%] left-[5.56%]"
                      control={<Switch color="primary" size="small"
                                       checked={isSmallSidebar() || false}
                                       onChange={(event) => _.assign(deviceSetting0()?.screenConfig, { smallSidebar: event.target.checked })}
                      />}
                    />
                  </div>
                  <b className="flex-1 relative leading-[17px]">
                    {LL0().order.smallSidebar()}
                  </b>
                </div>
                {!order0?.().table &&
                  <div
                    className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 pr-1.5 pl-2 border-[1px] border-solid border-blue-solid-blue-300-3faaff">
                    <div className="w-9 relative h-[18px]">
                      <FormControlLabel
                        label={''}
                        className="absolute h-full w-[83.33%] top-[0%] right-[11.11%] bottom-[0%] left-[5.56%]"
                        control={<Switch color="primary" size="small"
                                         checked={posSetting0()?.defaultTakeaway ?? false}
                                         onChange={(event) => {
                                           _.assign(posSetting0(), { defaultTakeaway: event.target.checked })
                                           if (order0) _.assign(order0(), { takeAway: event.target.checked })
                                         }}
                        />}
                      />
                    </div>
                    <b className="flex-1 relative leading-[17px]">
                      {LL0().order.defaultTakeaway()}
                    </b>
                  </div>
                }
                <div
                  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                  onClick={() => {
                    setNoteOrderPopupOpen(true)
                    closeMenu()
                  }}
                  ref={makeRipple}
                >
                  <div
                    className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                  <img
                    className="w-[30px] relative h-[30px] object-cover z-[1]"
                    alt=""
                    src="/orderedit-screen-icon1@2x.png"
                  />
                  <b className="flex-1 relative leading-[17px] z-[2]">
                    {LL0().reservation.note()}
                  </b>
                  <b className="w-[69px] relative leading-[17px] hidden text-white z-[3]">
                    {LL0().reservation.note()}
                  </b>
                </div>
                {order0?.().table && <div
                  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                  onClick={() => {
                    setIsOpenCustomerInfoPopup(true)
                    closeMenu()
                  }}
                  ref={makeRipple}
                >
                  <div
                    className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                  <img
                    className="w-[30px] relative h-[30px] object-cover z-[1]"
                    alt=""
                    src="/iconnew-deliveryadd-customer-icon@2x.png"
                  />
                  <b className="flex-1 relative leading-[17px] z-[2]">
                    {LL0().onlineOrder.addCustomer()}
                  </b>
                  <b className="w-[69px] relative leading-[17px] hidden text-white z-[3]">
                    {LL0().onlineOrder.addCustomer()}
                  </b>
                </div>}
              </div>
            </div>
          </div>
        )}
      </div>

      {isDiscountPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeDiscountPopup}
        >
          <DiscountPopupItem onClose={closeDiscountPopup} />
        </PortalPopup>
      )}
      {isVoucherPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeVoucherPopup}
        >
          <VoucherPopup onClose={closeVoucherPopup} order={order0?.()} />
        </PortalPopup>
      )}
      {isOrderSreenCustomDishPoOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeOrderSreenCustomDishPo}
        >
          <OrderScreenCustomDishPo onClose={closeOrderSreenCustomDishPo} />
        </PortalPopup>
      )}
      {isOrderScreenFunctionsPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeOrderScreenFunctionsPopup}
        >
          <OrderScreenFunctionsPopup onClose={closeOrderScreenFunctionsPopup} />
        </PortalPopup>
      )}
      {isSplitBillEquallyPopupOpen() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeSplitBillEquallyPopup}
        >
          <SplitBillEquallyPopup onClose={closeSplitBillEquallyPopup} />
        </PortalPopup>
      )}
      {isPrintBillForSeatsOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closePrintBillForSeats}
        >
          <PrintBillForSeats onClose={closePrintBillForSeats} />
        </PortalPopup>
      )}
      {isPrintBillForSeatsOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closePrintBillForSeats}
        >
          <PrintBillForSeats onClose={closePrintBillForSeats} />
        </PortalPopup>
      )}
      {isPrintKitchenPopUpOpen() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setPrintKitchenPopUpOpen(false)}
        >
          <PrintKitchenPopUp onClose={() => setPrintKitchenPopUpOpen(false)} />
        </PortalPopup>
      )}
      {isConfirmMergeTablePopupOpen() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setIsConfirmMergeTablePopupOpen(false)}
        >
          <ConfirmMergeTablePopUp onClose={() => setIsConfirmMergeTablePopupOpen(false)} />
        </PortalPopup>
      )}
      {isOpenMergeSeatPopUp() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setIsOpenMergeSeatPopup(false)}
        >
          <ConfirmMergeSeatBeforeMove onClose={() => setIsOpenMergeSeatPopup(false)} />
        </PortalPopup>
      )}
      {isNoteOrderPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setNoteOrderPopupOpen(false)}
        >
          <OrderGroupPopup onClose={() => setNoteOrderPopupOpen(false)} />
        </PortalPopup>
      )}
      {isOpenCustomerInfoPopup() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setIsOpenCustomerInfoPopup(false)}
        >
          <CustomerInfoPopup onClose={() => setIsOpenCustomerInfoPopup(false)} />
        </PortalPopup>
      )}
    </>
  )
}

export default OrderBurgerMenuButton