import uuid from 'time-uuid'

import type { Order } from '@/data/Order'
import { OrderStatus } from '@/pos/OrderType'
import { signalSyncedWithLocalStorage } from '@/react/core/reactive'

import { completeOrder, splitItem } from '../order-mutations'
import { srmTransactionLogic } from '../transaction.logic'
import { execTestcase, getCaTaxInfo } from './libs'

type O = Readonly<Order>

const [order01, setOrder01] = signalSyncedWithLocalStorage<O | null>('TESTCASE_021_ORDER_01', null)
const [order02, setOrder02] = signalSyncedWithLocalStorage<O | null>('TESTCASE_021_ORDER_02', null)

export const testRunner021: Record<string, () => Promise<boolean>> = {
  '021.001': () =>
    execTestcase({
      num: 21,
      step: 1,
      device: 'A',
      run: async () => {
        const taxInfo = getCaTaxInfo()
        const baseOrder: O = {
          _id: uuid(),
          table: '020',
          status: OrderStatus.IN_PROGRESS,
          payments: [],
          items: [
            // Main Course
            { name: 'Bouillabaisse', price: 22, quantity: 1, modifiers: [], ...taxInfo },
            { name: 'Poutine', price: 33, quantity: 1, modifiers: [], ...taxInfo },
            // Drink
            { name: 'Bordeaux', price: 18, quantity: 1, modifiers: [], ...taxInfo },
            { name: 'Sortilège', price: 28, quantity: 1, modifiers: [], ...taxInfo },
            // Dessert
            { name: 'Crème Brûlée', price: 6.5, quantity: 1, modifiers: [], ...taxInfo },
            { name: 'Tarte Tatin', price: 8, quantity: 1, modifiers: [], ...taxInfo },
          ],
        }
        const order01: O = { ...baseOrder, _id: uuid(), seat: 0, items: [...baseOrder.items.filter((_, i) => i % 2 === 0)] }
        const order02: O = { ...baseOrder, _id: uuid(), seat: 1, items: [...baseOrder.items.filter((_, i) => i % 2 === 1)] }

        await srmTransactionLogic.recordTemporaryBill(order01, { print: true })

        const groupedOrder: O = { ...baseOrder, seatMap: [order01, order02] }
        setOrder01(groupedOrder)
      },
    }),
  '021.002': () =>
    execTestcase({
      num: 21,
      step: 2,
      device: 'A',
      run: async () => {
        const o = order01()
        if (!o) throw new Error('Please run step 1 first')
        const order = o.seatMap?.[1]
        if (!order) throw new Error('Seat 1 not found')
        await srmTransactionLogic.recordTemporaryBill(order, { print: true })
        setOrder01(o)
      },
    }),
  '021.003': () =>
    execTestcase({
      num: 21,
      step: 3,
      device: 'A',
      run: async () => {
        const o = order01()
        if (!o) throw new Error('Please run step 1 first')
        const splittedOrder: O = splitItem(o, i => i.name === 'Bouillabaisse')
        const order = splittedOrder.seatMap?.[0]
        if (!order) throw new Error('Seat 0 not found')
        await srmTransactionLogic.recordTemporaryBill(order, { print: true, parentOrder: splittedOrder })
        setOrder02(splittedOrder)
      },
    }),
  '021.004': () =>
    execTestcase({
      num: 21,
      step: 4,
      device: 'A',
      run: async () => {
        const o = order02()
        if (!o) throw new Error('Please run step 1 first')
        const order = o.seatMap?.[1]
        if (!order) throw new Error('Seat 1 not found')
        await srmTransactionLogic.recordTemporaryBill(order, { print: true, parentOrder: o })
      },
    }),
  '021.005': () =>
    execTestcase({
      num: 21,
      step: 5,
      device: 'A',
      run: async () => {
        const o = order02()
        if (!o) throw new Error('Please run step 1 first')
        const order = o.seatMap?.[0]
        if (!order) throw new Error('Seat 0 not found')
        await srmTransactionLogic.recordClosingReceipt(completeOrder(order), { print: true, parentOrder: o })
      },
    }),
  '021.006': () =>
    execTestcase({
      num: 21,
      step: 6,
      device: 'A',
      run: async () => {
        const o = order02()
        if (!o) throw new Error('Please run step 1 first')
        const order = o.seatMap?.[1]
        if (!order) throw new Error('Seat 1 not found')
        await srmTransactionLogic.recordClosingReceipt(completeOrder(order), { print: true, parentOrder: o })
      },
    }),
}
