import type { FunctionComponent } from "react";
import {
  LocalizationProvider,
  DatePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@/react/core/TextField.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import {
  customerName,
  setCustomerName,
  customerPhone,
  setCustomerPhone,
  rsvDate, setRsvDate, setRsvTime,
  rsvNote,
  rsvGuest,
  setRsvNote,
  setRsvGuest,
} from "@/data/ReservationHub.ts";
import dayjs from "dayjs";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { LL2 } from "@/react/core/I18nBackend.tsx";
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";

export type InfoSectionType = {
  className?: string;
};

const InfoSection: FunctionComponent<InfoSectionType> = ({
  className = "",
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        className={`self-stretch rounded-lg flex flex-col items-start justify-start gap-[16px] z-[1] text-left text-sm text-black-solid-black-900-1e1e23 font-mulish ${className}`}
      >
        <div className="self-stretch flex flex-row items-start justify-start gap-[16px] z-[2]">
          <div className="flex-1 flex flex-col items-start justify-center relative gap-[8px] z-[1]">
            <div className="self-stretch flex flex-row items-start justify-start gap-[4px] z-[2]">
              <b className="relative sm:text-smi mq768:text-xs mq600:text-2xs">
                {LL0().reservation.customerName()}:
              </b>
              <b className="relative text-red-solid-red-160-fb4553">*</b>
            </div>
            <TextField
              className="self-stretch shadow-[-0.5px_-0.5px_3px_rgba(160,_160,_160,_0.8)_inset] font-mulish text-sm text-black-solid-black-900-1e1e23 z-[1]"
              color="primary"
              fullWidth={true}
              variant="outlined"
              sx={{ "& .MuiInputBase-root": { height: "40px" } }}
              value={customerName()}
              onChange={e => setCustomerName(e.target.value)}
            />
          </div>
          <div className="flex-1 flex flex-col items-start justify-center relative gap-[8px] z-[0]">
            <div className="self-stretch flex flex-row items-start justify-start gap-[4px] z-[0]">
              <b className="relative sm:text-smi mq768:text-xs mq600:text-2xs">
                {LL0().reservation.phoneNumber()}
              </b>
              <b className="relative text-red-solid-red-160-fb4553">*</b>
            </div>
            <TextField
              className="self-stretch shadow-[-0.5px_-0.5px_3px_rgba(160,_160,_160,_0.8)_inset] font-mulish text-sm text-black-solid-black-900-1e1e23 z-[1]"
              color="primary"
              fullWidth={true}
              variant="outlined"
              sx={{ "& .MuiInputBase-root": { height: "40px" } }}
              value={customerPhone()}
              onChange={e => setCustomerPhone(e.target.value)}
            />
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[16px] z-[1] h.414:flex-col mq320:flex-col">
          <div className="flex-1 flex flex-col items-start justify-center gap-[8px] h.414:flex-[unset] h.414:self-stretch mq320:flex-[unset] mq320:self-stretch">
            <div className="self-stretch flex flex-row items-start justify-start gap-[4px]">
              <b className="relative sm:text-smi mq768:text-xs mq600:text-2xs">
                {LL0().reservation.date()}
              </b>
              <b className="relative text-red-solid-red-160-fb4553">*</b>
            </div>
            <div className="self-stretch shadow-[-0.5px_-0.5px_3px_rgba(160,_160,_160,_0.8)_inset]">
              <DatePicker
                format={LL2().dates.dateFormat()}
                value={dayjs.unix(rsvDate())}
                onChange={(newValue: any) => setRsvDate(newValue)}
                sx={{}}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                    required: false,
                    autoFocus: false,
                    error: false,
                    color: "primary",
                  },
                }}
              />
            </div>
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[8px] h.414:flex-[unset] h.414:self-stretch mq320:flex-[unset] mq320:self-stretch">
            <div className="self-stretch flex flex-row items-start justify-start gap-[4px]">
              <b className="relative sm:text-smi mq768:text-xs mq600:text-2xs">
                {LL0().reservation.time()}
              </b>
              <b className="relative text-red-solid-red-160-fb4553">*</b>
            </div>
            <div className="self-stretch shadow-[-0.5px_-0.5px_3px_rgba(160,_160,_160,_0.8)_inset]">
              <TimePicker
                value={dayjs.unix(rsvDate())}
                onChange={(newValue: any) => setRsvTime(newValue)}
                ampm={companyInfo0()?.country !== 'de'}
                sx={{}}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                    required: false,
                    autoFocus: false,
                    error: false,
                    color: "primary",
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-end justify-center gap-[16px] z-[0] h.414:gap-[4px] mq320:gap-[4px]">
          <div className="self-stretch flex-1 flex flex-col items-start justify-center gap-[8px]">
            <div className="self-stretch flex flex-row items-start justify-start gap-[4px]">
              <b className="relative sm:text-smi mq768:text-xs mq600:text-2xs">
                {LL0().reservation.note()}
              </b>
              <b className="w-[7px] relative hidden text-red-solid-red-160-fb4553">
                *
              </b>
            </div>
            <TextField
              className="self-stretch shadow-[-0.5px_-0.5px_3px_rgba(160,_160,_160,_0.8)_inset] font-mulish italic font-medium text-sm text-gray-solid-gray-500-888f97"
              color="primary"
              variant="outlined"
              sx={{ "& .MuiInputBase-root": { height: "40px" } }}
              value={rsvNote()}
              onChange={e => setRsvNote(e.target.value)}
            />
          </div>
          <div className="self-stretch flex flex-col items-end justify-center gap-[8px]">
            <div className="self-stretch flex flex-row items-start justify-start gap-[4px]">
              <b className="flex-1 relative sm:text-smi mq768:text-xs mq600:text-2xs">
                {LL0().reservation.guestQuantity()}
              </b>
              <b className="w-[7px] relative hidden text-red-solid-red-160-fb4553">
                *
              </b>
            </div>
            <div className="self-stretch shadow-[-0.5px_-0.5px_2px_rgba(0,_0,_0,_0.25)_inset,_0.5px_0.5px_2px_rgba(0,_0,_0,_0.25)_inset] rounded bg-white-solid-white-100-ffffff box-border h-10 flex flex-col items-end justify-center p-1 text-gray-solid-gray-952-35404f border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1">
              <div className="flex-1 flex flex-row items-center justify-center gap-[8px] mq480:gap-[6px] h.414:gap-[4px]">
                <div className="self-stretch w-10 rounded-sm bg-blue-solid-blue-420-2979ff flex flex-row items-center justify-center p-1.5 box-border max-w-[80px] mq480:w-8 h.414:w-7"
                  ref={makeRipple}
                     onClick={() => {
                       if (rsvGuest() <= 1) return;
                       setRsvGuest(v => v - 1)
                     }}
                >
                  <img
                    className="w-5 relative h-5 overflow-hidden shrink-0"
                    alt=""
                    src="/mdiminus.svg"
                  />
                </div>
                <div className="self-stretch w-[38px] rounded-sm bg-white-solid-white-100-ffffff flex flex-col items-center justify-center max-w-[38px]">
                  <b className="relative">{rsvGuest()}</b>
                </div>
                <div className="self-stretch w-10 rounded-sm bg-blue-solid-blue-420-2979ff flex flex-row items-center justify-center p-1.5 box-border max-w-[80px] mq480:w-8 h.414:w-7"
                  ref={makeRipple}
                     onClick={() => setRsvGuest(v => v + 1)}
                >
                  <img
                    className="w-5 relative h-5 overflow-hidden shrink-0"
                    alt=""
                    src="/mdiplus.svg"
                  />
                </div>
              </div>
            </div>
            <div className="w-[142px] shadow-[-0.5px_-0.5px_2px_rgba(0,_0,_0,_0.25)_inset,_0.5px_0.5px_2px_rgba(0,_0,_0,_0.25)_inset] rounded bg-gray-solid-gray-164-e1e1e1 box-border h-10 hidden flex-col items-center justify-center p-1 text-gray-solid-gray-870-656565 border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1">
              <div className="w-full rounded-sm h-8 flex flex-col items-center justify-center max-w-[38px]">
                <b className="relative">{rsvGuest()}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default InfoSection;
