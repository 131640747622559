import { getAll } from 'firebase/remote-config'
import formatFactory from 'format-number'
import _ from 'lodash'
import type { PointerEvent, PointerEventHandler } from 'react'

import { remoteConfig } from '@/extensions/firebase/useFirebase.ts'
import { CANADA_SERVER_1, CANADA_SANDBOX_SERVER, GERMANY_SERVER_1, GERMANY_SERVER_2, type Server, servers } from '@/lib/servers.ts'
import { convertToBase64Png } from '@/react/Printer/print-kitchen-utils'
import type { Raster } from '@/shared/printer/pure-image-printer-parallel'
import { server } from "@/data/Server.ts";
import { getUrlParam } from "@/shared/utils2.ts";

export function stop0<T>(fn?: PointerEventHandler<T> | undefined): ((e: PointerEvent<T>) => void) | undefined {
  if (fn) {
    return (e: PointerEvent<T>) => {
      e.preventDefault()
      fn(e)
    }
  }
}

export function getServers() {
  const allConfig = getAll(remoteConfig!)
  servers[CANADA_SERVER_1].surrealDbCloud.url = allConfig['surreal_canada1']?.asString() || servers[CANADA_SERVER_1].surrealDbCloud.url
  servers[CANADA_SERVER_1].surrealDbCloud.password = allConfig['surreal_canada1_password']?.asString() || servers[CANADA_SERVER_1].surrealDbCloud.password

  // using the same surreal db as prod server
  servers[CANADA_SANDBOX_SERVER].surrealDbCloud.url = servers[CANADA_SERVER_1].surrealDbCloud.url
  servers[CANADA_SANDBOX_SERVER].surrealDbCloud.password = servers[CANADA_SERVER_1].surrealDbCloud.password

  servers[GERMANY_SERVER_1].surrealDbCloud.url = allConfig['surreal_germany1']?.asString() || servers[GERMANY_SERVER_1].surrealDbCloud.url
  servers[GERMANY_SERVER_1].surrealDbCloud.password = allConfig['surreal_germany1_password']?.asString() || servers[GERMANY_SERVER_1].surrealDbCloud.password
  servers[GERMANY_SERVER_2].surrealDbCloud.url = allConfig['surreal_germany2']?.asString() || servers[GERMANY_SERVER_2].surrealDbCloud.url
  servers[GERMANY_SERVER_2].surrealDbCloud.password = allConfig['surreal_germany2_password']?.asString() || servers[GERMANY_SERVER_2].surrealDbCloud.password

  // agora rtm
  return servers
}

export function getServersFromFlavor(): Record<string, Server> {
  const flavor = getUrlParam('flavor')
  if (flavor === 'hanseatic') {
    return _.omit(getServers(), [GERMANY_SERVER_2])
  }
  return getServers()
}

export function getServer(): Server {
  const temp = localStorage.getItem('tempServer')
  return getServersFromFlavor()[temp || server() || CANADA_SERVER_1]
}

export function getApiUrl() {
  // return "http://localhost:3000"
  return getServer().manageServer.url
}

export function getUrlSurrealDbCloud() {
  return getServer().surrealDbCloud.url
}

export const formatCurrency = formatFactory({ round: 2, padRight: 2 })

export function readableError(error: any) {
  return error?.response?.data?.message || error?.response?.data?.error || error?.message
}

export function ensureHasValue<T>(a: T): a is NonNullable<T> {
  return typeof a !== 'undefined' && a !== null
}

export async function downloadPrintedImage(rasterOrBase64Url: string | Raster, name = 'raster.png') {
  console.log(`⬇️ Downloading ${name}...`)
  const a = document.createElement('a')
  a.href = typeof rasterOrBase64Url === 'string' ? rasterOrBase64Url : await convertToBase64Png(rasterOrBase64Url)
  a.download = `${name}.png`
  a.click()
}

export function downloadFile(file: File) {
  const a = document.createElement('a')
  a.href = URL.createObjectURL(file)
  a.download = file.name
  document.body.appendChild(a)
  a.click()
  URL.revokeObjectURL(a.href)
  document.body.removeChild(a)
}

