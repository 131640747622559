import type { FunctionComponent } from "react";

const ButtonGroup: FunctionComponent = () => {
  return (
    <div className="self-stretch hidden flex-row items-end justify-center">
      <div className="flex-1 bg-mediumslateblue-200 flex flex-row items-center justify-center py-2 px-4">
        <img
          className="relative w-8 h-8 overflow-hidden shrink-0"
          alt=""
          src="/cash-icon.svg"
        />
      </div>
      <div className="flex-1 bg-mediumseagreen-200 flex flex-row items-center justify-center py-2 px-4">
        <img
          className="relative w-8 h-8 overflow-hidden shrink-0"
          alt=""
          src="/mastercard-icon.svg"
        />
      </div>
      <div className="flex-1 bg-dimgray-200 flex flex-row items-center justify-center py-2 px-4">
        <img
          className="relative w-8 h-8 overflow-hidden shrink-0"
          alt=""
          src="/card-dollar-icon.svg"
        />
      </div>
    </div>
  );
};

export default ButtonGroup;
