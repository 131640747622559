import React, { type FunctionComponent, useCallback, useEffect, useState } from 'react';
import { ITEM_MODE, setEditorMode0, } from '@/react/EditMenuView/EditMenuView.tsx';
import { LL0 } from "@/react/core/I18nService.tsx";
import { FormControlLabel, Switch } from "@mui/material";
import {multipleMenusEnableSwitch} from "@/react/EditMenuView/EditPricingLevel.logic.ts";
import PortalPopup from "@eod/PortalPopup.tsx";
import EditMenuCustomDishPopu from "@edit-menu/EditMenuCustomDishPopu.tsx";
import _ from "lodash";
import { generalSetting0, posSetting0 } from "@/data/PosSettingsSignal.ts";
import SetupMenus from "@edit-menu/Popups/SetupMenus.tsx";

type EditMenuMoreMenuType = {
  onClose?: () => void;
};


const EditMenuMoreMenu: FunctionComponent<EditMenuMoreMenuType> = ({
  onClose,
}) => {

  const [isEditMenuCustomDishPopuOpen, setEditMenuCustomDishPopuOpen] =
    useState(false);

  const [isSetupMenusOpen, setIsSetupMenusOpen] =
    useState(false);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const openEditMenuCustomDishPopu = useCallback(() => {
    setEditMenuCustomDishPopuOpen(true);
  }, []);

  const closeEditMenuCustomDishPopu = useCallback(() => {
    setEditMenuCustomDishPopuOpen(false);
  }, []);
  return (
    <>

    <div
      className={`!opacity-100 w-[259px] h-full relative shadow-[0px_0px_6px_2px_#fff] [background:linear-gradient(90deg,_#f8f8f8,_rgba(235,_236,_238,_0.96))] flex flex-col items-center justify-start pt-10 px-0 pb-2.5 box-border gap-[16px] [&.animate]:animate-[0.25s_ease_0s_1_normal_forwards_slide-in-left] opacity-[0] max-w-[90%] overflow-auto text-center text-mini text-black-solid-black-1000-000000 font-mulish`}
      data-animate-on-scroll
    >
      <div className="flex flex-col items-center justify-start gap-[16px] z-[0]">
        <div
          className="self-stretch shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-white-solid-white-100-ffffff h-[35px] flex flex-row items-center justify-center py-0 px-4 box-border"
          onClick={() => {
            setEditorMode0(ITEM_MODE.KEYBOARD);
            onClose?.();
          }}
        >
          <b className="relative">{LL0().editMenu.setupKeyboard()}</b>
        </div>
        <div
          className="self-stretch shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-white-solid-white-100-ffffff h-[35px] flex flex-row items-center justify-center py-0 px-4 box-border cursor-pointer"
          onClick={openEditMenuCustomDishPopu}
        >
          <b className="relative">{LL0().order.customDish()}</b>
        </div>
        <div
          className="self-stretch shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-white-solid-white-100-ffffff h-[35px] flex flex-row items-center justify-center py-0 px-4 box-border gap-[8px]">
          <div className="w-[30px] relative h-[18px]">
            <FormControlLabel
              label={''}
              className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]"
              control={<Switch color="primary" size="small"
                               {...multipleMenusEnableSwitch()}
              />}
            />
          </div>
          <b className="relative">{LL0().div.multipleMenu()}</b>
        </div>
        <div
          className="self-stretch shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-white-solid-white-100-ffffff h-[35px] flex flex-row items-center justify-center py-0 px-4 box-border gap-[8px]">
          <div className="w-[30px] relative h-[18px]">
            <FormControlLabel
              label={''}
              className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]"
              control={<Switch color="primary" size="small"
                               checked={posSetting0()?.menuScrollbar || false}
                               onChange={(e) => {
                                 _.assign(posSetting0(), { menuScrollbar: e.target.checked })
                               }}
              />}
            />
          </div>
          <b className="relative">{LL0().order.scrollableLayout()}</b>
        </div>
        {generalSetting0()?.multipleDineInMenusEnable && (<div
          className="self-stretch shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-white-solid-white-100-ffffff h-[35px] flex flex-row items-center justify-center py-0 px-4 box-border cursor-pointer"
          onClick={() => setIsSetupMenusOpen(true)}
        >
          <b className="relative">{LL0().div.pricingLevelSetup.setupMenus()}</b>
        </div>)}
      </div>
      <img
        className="w-6 h-6 absolute !m-[0] top-[8px] left-[8px] object-cover cursor-pointer z-[1]"
        alt=""
        src="/icondelete-fillter-icon@2x.png"
        onClick={onClose}
      />
      {isEditMenuCustomDishPopuOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeEditMenuCustomDishPopu}
        >
          <EditMenuCustomDishPopu onClose={closeEditMenuCustomDishPopu} />
        </PortalPopup>
      )}
      {isSetupMenusOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setIsSetupMenusOpen(false)}
        >
          <SetupMenus onClose={() => setIsSetupMenusOpen(false)} />
        </PortalPopup>
      )}
    </div>
    </>
  );
};

export default EditMenuMoreMenu;
