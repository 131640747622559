// import EditMenuPlugin from '@edit-menu/EditMenuPlugin.tsx';
import {
  type Accessor,
  batch,
  computed,
  effect,
  selector,
  type Setter,
  signal,
  useAsyncEffect
} from '@/react/core/reactive.ts';
import { convertDocument, convertDocuments, type DocDeepSignal } from '@/data/data-utils.ts';
import { Product } from '@/data/Product.ts';
import { type CategoryLayout, OrderLayout } from '@/data/OrderLayout.ts';
import { ProductLayout } from '@/data/ProductLayout.ts';
import { dataLock } from '@/data/DataUtils.ts';
import { onEnter, PosScreen, router } from '@/pos/PosRouter.ts';
import { GroupPrinter } from '@/data/GroupPrinter.ts';
import { makeModifiersAvailable, modifiers0 } from '@/data/ModifierHub.ts'
import { Modifier, type ModifierItem, type ModifierType } from '@/data/Modifier.ts';
import { makeHappyHoursAvailable } from '@/data/HappyHourHub.ts';
import _ from 'lodash';
import PortalPopup from '@dashboard/PortalPopup.tsx';
import InputPopup from '@/react/core/InputPopup.tsx';
import { memo } from 'react';
import type { InputController } from '@/react/core/Input.tsx';
import { makeCategoriesAvailable } from '@/data/CategoryHub.ts';
import { makeInventoriesAvailable } from '@/data/InventoryHub.ts';
import EditMenuPlugin from '@edit-menu/EditMenuPlugin.tsx';
import { makeProductsAvailable } from '@/data/ProductHub.ts';
import uuid from "time-uuid";
import PopupRemind from "@edit-menu/Popups/RemindPopup.tsx";
import { setShowPopup, showPopup, taxResult } from "@edit-menu/EditMenuForm/EditMenuForm.tsx";
import { generateSignalDataAccess } from '../utils/generateSignalDataAccess';
import { MakeObservableDocument, type DocObservable, MakeObservableList } from "@/data/utils/data-utils-2.ts";
import {
  getProducts,
  menuSelected,
  multipleMenus0,
  transformArray
} from "@/react/EditMenuView/EditPricingLevel.logic.ts";
import { posSetting0 } from "@/data/PosSettingsSignal.ts";
import { generalSetting0, keyboardConfig0, mainScreen } from "@/data/PosSettingsSignal.ts";


export const PRODUCT_COLORS = ['#FFFFFF', '#8AF1FF', '#87CDFF', '#C0C0FF', '#E1BEE7', '#FFC0FF', '#D4F5D0', '#CCFF85', '#F4FF7C', '#FFF59D', '#FFC86B', '#FF938A']

export enum ITEM_MODE {
  ITEM = "item",
  CATEGORY = "category",
  KEYBOARD = "keyboard",
  INGREDIENT = "ingredient"
}
export enum CustomDish {
  FOOD = "FOOD",
  DRINK = "DRINK"
}

export type TempItem = {
  active: boolean,
  _id?: string,
  type: ITEM_MODE,
  left?: number,
  top?: number,
  mode: "copy" | "swap"
};

export const [v, setV] = signal<number>(0);
export const [products0, setProducts0] = signal<DocObservable<Product>[]>([]);
export const [orderLayout0, setOrderLayout0] = signal<DocDeepSignal<OrderLayout, {}>>();
export const [productLayouts0, setProductLayouts0] = signal<DocObservable<ProductLayout>[]>([]);
export const [tempItem, setTempItem] = signal<TempItem | undefined>();
export const [currentCategory, setCurrentCategory] = signal<number>(0);
export const isSelected = selector<number>(currentCategory);
export const [categoryLayout0, setCategoryLayout0] = signal<CategoryLayout>();
export const [editorMode0, setEditorMode0] = signal<ITEM_MODE>(ITEM_MODE.ITEM);
export const [product0, setProduct0] = signal<DocDeepSignal<Product>>();
export const [productLayout0, setProductLayout0] = signal<DocDeepSignal<ProductLayout>>();


// @ts-ignore
window.product0 = product0;
// @ts-ignore
window.productLayout0 = productLayout0
// @ts-ignore
window.orderLayout1 = orderLayout0;


export const pushData = (inputControllers: Accessor<InputController[]>) => {
  inputControllers()[0].setKeys(product0()?.name?.split('') || []);
  inputControllers()[1].setKeys(product0()?.id?.split('') || []);
  if (generalSetting0()?.multipleDineInMenus && menuSelected() !== multipleMenus0()?.[0]?._id) {
    let index = product0().menuPrices?.findIndex(m => m.menu === menuSelected());
    if (index !== -1) {
      inputControllers()[2].setKeys(product0()?.menuPrices?.[index!]?.value?.toString().split('') || []);
    }
  } else {
    inputControllers()[2].setKeys(product0()?.price?.toString()?.split('') || []);
  }
  inputControllers()[0].moveCaretToEnd();
  inputControllers()[1].moveCaretToEnd();
  inputControllers()[2].moveCaretToEnd();
};


export const onDelete0: { value?: Function } = { value: () => null }

export function findProduct(product: ProductLayout) {
  if (!product?.product) return;
  return products0().find((p) => p._id === product.product);
}

export const rowsCategoryArr = computed(() => new Array(orderLayout0()?.rows).fill(0));
export const columnsCategoryArr = computed(() => new Array(orderLayout0()?.columns).fill(0));


export const onClickSwitch = (mode: "copy" | "swap") => {
  if (tempItem()?.active) return setTempItem();
  if (editorMode0() === ITEM_MODE.ITEM) {
    setTempItem(() => ({
      active: true,
      _id: productLayout0()?._id,
      top: productLayout0()?.top,
      left: productLayout0()?.left,
      type: ITEM_MODE.ITEM,
      mode
    }));
  } else {
    setTempItem(() => ({
      active: true,
      _id: categoryLayout0()?._id,
      top: categoryLayout0()?.top,
      left: categoryLayout0()?.left,
      type: ITEM_MODE.CATEGORY,
      mode
    }));
  }
}

//printers
export const [activeSecondPrinter0, setActiveSecondPrinter0] = signal<boolean>(false);

export const getPrinterClassname = (groupPrinter: GroupPrinter) => {
  if (product0()?.groupPrinter === groupPrinter._id) {
    return 'border-[2px] border-[#8AEAFF] !bg-[#CEFFFF]';
  } else if (product0()?.groupPrinter2 === groupPrinter._id) {
    return 'border-[2px] border-[#e556a3] !bg-[#FF938A]';
  }else if (product0()?.labelPrinter === groupPrinter._id) {
    return 'border-[2px] border-[#8AEAFF] !bg-[#F4FF7C]';
  } else {
    return 'border-[0.5px] border-[#B1B1B1]';
  }
};
export const getFoodClassname = (groupPrinter: GroupPrinter) => {
  if (posSetting0()?.divArticleSettings?.food?.groupPrinter === groupPrinter._id) {
    return 'border-[2px] border-[#8AEAFF] !bg-[#CEFFFF]';
  } else {
    return 'border-[0.5px] border-[#B1B1B1]';
  }
};
export const getDrinkClassname = (groupPrinter: GroupPrinter) => {
  if (posSetting0()?.divArticleSettings?.drink?.groupPrinter === groupPrinter._id) {
    return 'border-[2px] border-[#8AEAFF] !bg-[#CEFFFF]';
  } else {
    return 'border-[0.5px] border-[#B1B1B1]';
  }
};

export const [groupPrinters0] = generateSignalDataAccess(GroupPrinter, {
  auto: true,
  convertOpts: { autoSave: true },
  rxQuery: () => ({ selector: { type: 'kitchen' } } as const),
})

export const [selectedModifier, setSelectedModifier] = signal<DocDeepSignal<Modifier>>();
export const [selectedItem, setSelectedItem] = signal<ModifierItem>();
export const removeModifierItem = (modifier: Modifier, index: number) => {
  modifier.items?.splice(index, 1);
}

export const [showKeyboardIngredients, setShowKeyboardIngredients] = signal<boolean>(false)

export const removeCurrentModifier = async () => {
  products0().forEach(product => {
    if (!product.get().activePopupModifierGroup?.find(modifier => modifier === selectedModifier()?._id)) {
      return;
    }
    product.get().activePopupModifierGroup = product.get().activePopupModifierGroup!
      .filter(modifier => modifier !== selectedModifier()?._id);
    product.get().activePopupModifierGroup = product.get()!.activePopupModifierGroup!.filter(modifierId =>
      modifiers0().some(modifier => modifier._id === modifierId))
  })
  await selectedModifier()?.doc?.incrementalRemove();
}

export const createNewModifier = async (type: ModifierType, modifier?: Modifier, product?: string) => {
  //override _id
  console.log('⚡️ Creating new Modifier...')
  const row = await Modifier.insert({ items: [], ...modifier, _id: uuid(), useType: type, products: [product || ''] })
  console.log('⚡️ Created new Modifier', row)
}

//happy hour handler
export const onToggleHappyHour = (happyHourId: string) => {
  const happyHourIndex = product0().happyHours?.indexOf(happyHourId);
  const existingHappyHours = product0().happyHours || [];
  if (happyHourIndex === undefined || happyHourIndex === -1) {
    _.assign(product0(), { happyHours: [...existingHappyHours, happyHourId] });
    return;
  }
  existingHappyHours.splice(happyHourIndex, 1);
  _.assign(product0(), { happyHours: existingHappyHours });
}

//keyboard handlers
export const [isPopupOpen, setIsPopupOpen] = signal<boolean>(false);
export const [inputControllers, setInputControllers] = signal<Array<InputController>>([]);
export const [currenKeyboardKey, setCurrenKeyboardKey] = signal<{ top: number, left: number }>({ top: 0, left: 0 })

export const extraColumns = computed(() => {
  if (keyboardConfig0()) {
    return _.max(keyboardConfig0().rows.map(row => row.length)) || 0;
  }
  return 0;
})

export const changeExtraColumns = (newExtraColumns: number) => {
  const config = Object.assign({}, keyboardConfig0())
  const rows = config.rows
  if (newExtraColumns > extraColumns()) { // add column
    if (rows.length === 0) {
      while (rows.length < 4) {
        rows.unshift([])
      }
    }
    for (let row of rows) {
      if (!row) row = []
      row.unshift(" ")
    }
  } else {
    for (let row of rows) {
      if (!row) row = []
      row.shift()
    }
  }
}

export const resetKeyboard = () => {
  keyboardConfig0().rows = keyboardConfig0().rows.map(row => row.map(_ => " "));
}

// #region handleInputPopup
export function handleInputPopup(
  setShow: Setter<boolean>,
  inputControllers: Accessor<InputController[]>,
  pushData: (inputControllers: Accessor<InputController[]>) => void,
  focusIndex: number) {
  setShow(v => !v);
  const dispose = effect(() => {
    if (inputControllers().length === 3) {
      pushData(inputControllers);
      setTimeout(() => {
        dispose();
        inputControllers()[focusIndex].setFocus(true);
      }, 200);
    }
  });
}

// #endregion

// materialCategories handlers
export const onToggleCategory = (categoryId: string) => {
  const categoryIndex = product0().categories?.indexOf(categoryId);
  const existingCategory = product0().categories || [];
  if (categoryIndex === undefined || categoryIndex === -1) {
    _.assign(product0(), { categories: [...existingCategory, categoryId] });
    return;
  }
  existingCategory.splice(categoryIndex, 1);
  _.assign(product0(), { categories: existingCategory });
}

const [dirty, setDirty] = signal(false);

export async function checkTaxAndPrinter() {
  const withoutMenuTax1: any[] = [];
  const withoutMenuTax2: any[] = [];

  const products = await getProducts();

  if (!products || !products.length) return;

  const productWithoutTax = products.filter(p => !p.tax || !p.tax2)?.map(p => `${p.id ? ` ${p.id}. ` : ' '}${p.name}`);
  const productWithoutPrinter = products.filter(p => !p.groupPrinter && !p.groupPrinter2)?.map(p => `${p.id ? ` ${p.id}. ` : ' '}${p.name}`);

  if (generalSetting0()?.multipleDineInMenusEnable) {
    const productWithMenuPrices = products.filter(p => p.menuPrices);
    if (productWithMenuPrices?.length) {
      for (const productFound of productWithMenuPrices) {
        const menu = productFound.menuPrices?.map(p => p.menu) ?? [];
        const menuTax1 = productFound.menuTaxes?.map(m => m.menu) ?? [];
        const menuTax2 = productFound.menuTaxes2?.map(m => m.menu) ?? [];

        const productDisplayName = `${productFound.id ? ` ${productFound.id}. ` : ' '}${productFound.name}`

        //check if any tax or tax2 missing in product in any pricing level
        const lackTax1 = _.difference(menu, menuTax1);
        const lackTax2 = _.difference(menu, menuTax2);

        if (lackTax1.length) {
          withoutMenuTax1.push(...lackTax1?.map(m => ({ [m]: productDisplayName })));
        }
        if (lackTax2.length) {
          withoutMenuTax2.push(...lackTax2?.map(m => ({ [m]: productDisplayName })));
        }
      }
    }
  }

  return {
    resultTaxes: productWithoutTax,
    resultPrinter: productWithoutPrinter,
    resultMenuTaxes: withoutMenuTax1.length > 0 ? transformArray(withoutMenuTax1) : [],
    resultMenuTaxes2: withoutMenuTax2.length > 0 ? transformArray(withoutMenuTax2) : [],
  };
}

const closeNotification = () => {
  setShowPopup(false);
  router.screen = mainScreen();
};

const EditMenuView = () => {
  makeHappyHoursAvailable();
  makeCategoriesAvailable();
  makeInventoriesAvailable();
  makeProductsAvailable();
  makeModifiersAvailable();
  multipleMenus0();

  useAsyncEffect(async () => {
    await dataLock.acquireAsync();
    console.time("loading");
    const orderLayout = await OrderLayout.findOne().exec();
    if (!orderLayout) return;
    const productLayoutBuilder = await MakeObservableList(
      ProductLayout.find(),
      () => true,
      doc => MakeObservableDocument(doc).createSignal().makeAutoObserve()
        .makeAutoSave().exec()
      ).addListSignal(productLayouts0, setProductLayouts0)
      .makeAutoPull()
      .pull();

    const productBuilder = await MakeObservableList(
      Product.find(),
      () => true,
      doc => MakeObservableDocument(doc).createSignal().makeAutoObserve()
        /*.makeDebounce(2000)*/.makeAutoSave().exec()
    ).addListSignal(products0, setProducts0)
      .makeAutoPull()
      .pull();

    MakeObservableDocument(orderLayout).addSignal(orderLayout0, setOrderLayout0).makeAutoObserve().makeAutoSave().makeAutoReplace().exec();

    console.timeEnd("loading");
    let firstTime = true;
    orderLayout.$.subscribe((_e) => {
      if (firstTime) return (firstTime = false);
      console.log("orderLayout change");
      setDirty(true);
    });
    if (v() === 0) {
      ProductLayout.$.subscribe((e) => {
        // if (e.documentData.category !== e.previousDocumentData?.category) {
        //   setV(v => v + 1);
        //   return;
        // }


        console.log("ProductLayout change");
        setDirty(true);
      });
      OrderLayout.$.subscribe(async (e) => {
        console.log("OrderLayout change ");
        // const o = await OrderLayout.findOne().exec();
        // setOrderLayout0(convertDocument<OrderLayout>(o!, true));
        setDirty(true);
      });
      Product.$.subscribe(change => {
        // const products = convertDocuments<Product>(_products, true, [], { debounce: 2000 });
        // setProducts0(products);
        console.log("Product change ");
        setDirty(true);
      })
      // Product.$.subscribe((e) => {
      //   console.log("Product change");
      //   setDirty(true);
      // });
    }
  }, [/*v()*/])

  onEnter(PosScreen.EDIT_MENU, () => {
    if (dirty()) {
      setV((v) => v + 1);
      setDirty(false);
    }
  });

  console.log("render EditViewLeft", orderLayout0()?.categories.length);

  return <>
    <EditMenuPlugin />
    {showPopup() && (
      <PortalPopup
        overlayColor="rgba(0, 0, 0, 0.2)"
        placement="Centered"
        onOutsideClick={() => setShowPopup(false)}
      >
        <PopupRemind
          onClose={() => setShowPopup(false)}
          onConfirmed={closeNotification}
        />
      </PortalPopup>
    )}
    {isPopupOpen() && (
      <PortalPopup
        overlayColor="rgba(0, 0, 0, 0.2)"
        placement="Centered"
        onOutsideClick={() => setIsPopupOpen(false)}
      >
        <InputPopup onClose={() => setIsPopupOpen(false)}
                    inputControllers={inputControllers}
                    label={'Key'}
                    setInputControllers={setInputControllers}
                    onEnter={() => {
                      setIsPopupOpen(false);
                      _.set(keyboardConfig0(), ['rows', currenKeyboardKey().top - 1, currenKeyboardKey().left - 1], inputControllers()[0].keys().join(''));
                    }}
                    value={""}
        />
      </PortalPopup>
    )}
  </>
}

export default memo(EditMenuView);