import { type FunctionComponent, useMemo, type CSSProperties } from "react";
import {makeRipple} from "@/react/core/ripple-utils.ts";

type RoundedButtonType = {
  buttonIcon?: string;
  text?: string;
  onClick: Function,
  showButtonIcon?: boolean;
  showButtonText?: boolean;

  /** Style props */
  roundedButtonBackgroundColor?: CSSProperties["backgroundColor"];
};

const RoundedButton: FunctionComponent<RoundedButtonType> = ({
  buttonIcon,
  text = "Button",
  onClick,
  showButtonIcon = true,
  showButtonText = true,
  roundedButtonBackgroundColor,
}) => {
  const roundedButtonStyle: CSSProperties = useMemo(() => {
    return {
      backgroundColor: roundedButtonBackgroundColor,
    };
  }, [roundedButtonBackgroundColor]);

  return (
    <div
      className="cursor-pointer rounded-81xl bg-royalblue-200 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] text-center text-sm text-basic-color-white font-mulish"
      style={roundedButtonStyle}
      ref={makeRipple}
      onClick={() => onClick()}
    >
      {showButtonIcon && (
        <img
          className="relative w-[30px] h-[30px] object-cover"
          alt=""
          src={buttonIcon}
        />
      )}
      {showButtonText && <b className="relative leading-[17px]">{text}</b>}
    </div>
  );
};

export default RoundedButton;
