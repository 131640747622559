import { type FunctionComponent, useCallback, useState } from "react";
import { FormControlLabel, MenuItem, Select, Switch } from "@mui/material";
import UseAPhysicalKeyboardPopu from "./UseAPhysicalKeyboardPopu";
import PortalPopup from "./PortalPopup";
import OpenCashDrawerAutomatically from "./OpenCashDrawerAutomatically";
import UseVirtualPrinterPopup from "./UseVirtualPrinterPopup";
import FavouriteArticlesPopup from "./FavouriteArticlesPopup";
import CalculateShippingDistance from "./CalculateShippingDistance";
import _ from "lodash";
import { LL0 } from "@/react/core/I18nService.tsx";
import { AUTO_LOGOUT_PERIODS, mergeSimilarItemSwitch } from "@/react/GeneralSettingsView/GeneralSettingsView.tsx";
import { SyncMode } from '@/data/data-enum'
import GeneralSettingMergeItemsIns from "./GeneralSettingMergeItemsIns";
import { toast } from "react-toastify";
import { onRecord } from "@/pos/logic/rrweb-utils.ts";
import { setIsStop } from "@/pos/logic/rrweb-share.ts";
import { tseConfig0 } from "@/data/TseConfigHub.ts";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import {
  generalSetting0,
  isAllowMultiplePayments,
  isCashbookEnabled,
  isQuebecSrmEnabled
} from "@/data/PosSettingsSignal.ts";

const GeneralSettingsLeftForm: FunctionComponent = () => {
  const [isUseAPhysicalKeyboardPopuOpen, setUseAPhysicalKeyboardPopuOpen] =
    useState(false);
  const [isAdvancedSettingOpen, setAdvancedSettingOpen] =
    useState(true);
  const [
    isOpenCashDrawerAutomaticallyOpen,
    setOpenCashDrawerAutomaticallyOpen,
  ] = useState(false);
  const [isUseVirtualPrinterPopupOpen, setUseVirtualPrinterPopupOpen] =
    useState(false);
  const [isFavouriteArticlesPopupOpen, setFavouriteArticlesPopupOpen] =
    useState(false);
  const [
    isGeneralSettingMergeItemsInsOpen,
    setGeneralSettingMergeItemsInsOpen,
  ] = useState(false);
  const [isCalculateShippingDistanceOpen, setCalculateShippingDistanceOpen] =
    useState(false);
  const openAdvancedSetting = useCallback(() => {
    setAdvancedSettingOpen(true);
  }, []);

  const closeAdvancedSetting = useCallback(() => {
    setAdvancedSettingOpen(false);
  }, []);
  const openUseAPhysicalKeyboardPopu = useCallback(() => {
    setUseAPhysicalKeyboardPopuOpen(true);
  }, []);

  const closeUseAPhysicalKeyboardPopu = useCallback(() => {
    setUseAPhysicalKeyboardPopuOpen(false);
  }, []);

  const openOpenCashDrawerAutomatically = useCallback(() => {
    setOpenCashDrawerAutomaticallyOpen(true);
  }, []);

  const closeOpenCashDrawerAutomatically = useCallback(() => {
    setOpenCashDrawerAutomaticallyOpen(false);
  }, []);

  const openUseVirtualPrinterPopup = useCallback(() => {
    setUseVirtualPrinterPopupOpen(true);
  }, []);

  const closeUseVirtualPrinterPopup = useCallback(() => {
    setUseVirtualPrinterPopupOpen(false);
  }, []);

  const openFavouriteArticlesPopup = useCallback(() => {
    setFavouriteArticlesPopupOpen(true);
  }, []);

  const closeFavouriteArticlesPopup = useCallback(() => {
    setFavouriteArticlesPopupOpen(false);
  }, []);

  const openGeneralSettingMergeItemsIns = useCallback(() => {
    setGeneralSettingMergeItemsInsOpen(true);
  }, []);

  const closeGeneralSettingMergeItemsIns = useCallback(() => {
    setGeneralSettingMergeItemsInsOpen(false);
  }, []);
  const openCalculateShippingDistance = useCallback(() => {
    setCalculateShippingDistanceOpen(true);
  }, []);
  const closeCalculateShippingDistance = useCallback(() => {
    setCalculateShippingDistanceOpen(false);
  }, []);
  return (
    <>
      <div
        className="no-scrollbar self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start py-0 px-6 gap-[8px_0px] text-left text-mini text-gray-solid-gray-850-767676 font-mulish">
        <div
          className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-2 gap-[12px_0px] border-b-[0.4px] border-solid border-gray-solid-gray-480-b1b1b1 Tablet_600:pl-4 Tablet_600:pr-4 Tablet_600:box-border">
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
              <div className="w-12 relative h-6">
                <FormControlLabel
                  label=''
                  className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                  control={<Switch color="primary" size="medium" checked={!!deviceSetting0()?.useVirtualKeyboard}
                                   onChange={(e, checked: boolean) => {
                                     _.assign(deviceSetting0(), { useVirtualKeyboard: checked })
                                   }}/>}
                />
              </div>
              <div className="flex-1 relative">{LL0().settings.usePhysicalKeyboard()}</div>
            </div>
            <img
              className="w-6 relative h-6 object-cover cursor-pointer"
              alt=""
              src="/iconquestion-icon@2x.png"
              onClick={openUseAPhysicalKeyboardPopu}
            />
          </div>
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
              <div className="w-12 relative h-6">
                <FormControlLabel
                  label=''
                  className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                  control={<Switch color="primary" size="medium" checked={deviceSetting0()?.autoOpenCashDrawer}
                                   onChange={(e, checked: boolean) => _.assign(deviceSetting0(), { autoOpenCashDrawer: checked })}/>}
                />
              </div>
              <div className="flex-1 relative">
                {LL0().settings.autoCashdrawer()}
              </div>
            </div>
            <img
              className="w-6 relative h-6 object-cover cursor-pointer"
              alt=""
              src="/iconquestion-icon@2x.png"
              onClick={openOpenCashDrawerAutomatically}
            />
          </div>
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
              <div className="w-12 relative h-6">
                <FormControlLabel
                  label=''
                  className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                  control={<Switch color="primary" size="medium" checked={generalSetting0()?.useVirtualPrinter}
                                   onChange={(e, checked: boolean) => _.assign(generalSetting0(), { useVirtualPrinter: checked })}/>}
                />
              </div>
              <div className="flex-1 relative">{LL0().settings.generalSetting.usingVirtualPrinter()}</div>
            </div>
            <img
              className="w-6 relative h-6 object-cover cursor-pointer"
              alt=""
              src="/iconquestion-icon@2x.png"
              onClick={openUseVirtualPrinterPopup}
            />
          </div>
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
              <div className="w-12 relative h-6">
                <FormControlLabel
                  label=''
                  className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                  control={<Switch color="primary" size="medium"
                                   {...mergeSimilarItemSwitch()}
                  />}
                />
              </div>
              <div className="flex-1 relative">
                {LL0().settings.generalSetting.mergeSimilarItems()}
              </div>
            </div>
            <img
              className="w-6 relative h-6 object-cover cursor-pointer"
              alt=""
              src="/iconquestion-icon@2x.png"
              onClick={openGeneralSettingMergeItemsIns}
            />
          </div>
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
              <div className="w-12 relative h-6">
                <FormControlLabel
                  label=''
                  className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                  control={<Switch color="primary" size="medium" />}
                />
              </div>
              <div className="flex-1 relative">
                {LL0().settings.generalSetting.calculateShippingDistanceForDeliveryOrder()}
              </div>
            </div>
            <img
              className="w-6 relative h-6 object-cover"
              alt=""
              src="/iconquestion-icon@2x.png"
            />
          </div>

          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
              <div className="w-12 relative h-6">
                <FormControlLabel
                  label=''
                  className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                  control={<Switch color="primary" size="medium" checked={!generalSetting0()?.cashTipEnable}
                                   onChange={(e, checked: boolean) => _.assign(generalSetting0(), { cashTipEnable: !checked })}/>}
                />
              </div>
              <div className="flex-1 relative">
                {LL0().order.cashTipEnable()}
              </div>
            </div>
            <img
              className="hidden w-6 relative h-6 object-cover cursor-pointer"
              alt=""
              src="/iconquestion-icon@2x.png"
              onClick={openCalculateShippingDistance}
            />
          </div>
        </div>
        <div
          className="self-stretch flex flex-col items-start justify-start gap-[8px] text-mini text-gray-solid-gray-850-767676">
          {!isAdvancedSettingOpen &&
            <div className="w-[512px] hidden flex-row items-center justify-start pt-2 px-0 pb-0 box-border gap-[4px]"
                 onClick={openAdvancedSetting}
            >
              <b className="relative [text-decoration:underline]">{LL0().settings.advanced()}</b>
              <img
                className="w-6 relative h-6 object-cover"
                alt=""
                src="/iconarrow-up-icon--grey@2x.png"
              />
            </div>}
          {isAdvancedSettingOpen && <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
            <div className="self-stretch flex flex-row items-center justify-start pt-2 px-0 pb-0 gap-[4px]">
              <b className="relative [text-decoration:underline]">{LL0().settings.advanced()}</b>
              <img
                className="relative w-6 h-6 object-cover"
                alt=""
                src="/iconarrow-down-icon--grey@2x.png"
              />
            </div>
            <div
              className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-2 gap-[12px] text-sm text-black-solid-black-880-1d1d26">
              <div className="self-stretch h-6 flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    label=''
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    control={<Switch color="primary" size="medium" checked={!!generalSetting0()?.useTimeClock}
                                     onChange={(e, checked: boolean) => _.assign(generalSetting0(), { useTimeClock: checked })} />}
                  />
                </div>
                <div className="flex-1 relative">{LL0().settings.runTimeClock()}</div>
              </div>

              <div className="self-stretch h-6 flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    label=''
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    control={<Switch color="primary" size="medium" checked={!!generalSetting0()?.hideFastCheckout}
                                     onChange={(e, checked: boolean) => _.assign(generalSetting0(), { hideFastCheckout: checked })} />}
                  />
                </div>
                <div className="flex-1 relative">{LL0().settings.hideFastCheckout()}</div>
              </div>
              <div className="self-stretch h-6 flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    label=''
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    control={<Switch color="primary" size="medium" checked={!!generalSetting0()?.hideManualTable}
                                     onChange={(e, checked: boolean) => _.assign(generalSetting0(), { hideManualTable: checked })} />}
                  />
                </div>
                <div className="flex-1 relative">{LL0().settings.hideManualTable()}</div>
              </div>

              <div className="self-stretch h-6 flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    label=''
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    control={<Switch color="primary" size="medium" checked={!!generalSetting0()?.autoLogOutWhenCloseApp}
                                     onChange={(e, checked: boolean) => _.assign(generalSetting0(), { autoLogOutWhenCloseApp: checked })} />}
                  />
                </div>
                <div className="flex-1 relative">
                  {LL0().settings.autoClockOutWhenCloseApp()}
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                <div className="self-stretch h-6 flex flex-row items-center justify-start gap-[4px]">
                  <div className="w-12 relative h-6">
                    <FormControlLabel
                      label=''
                      className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                      control={<Switch color="primary" size="medium"
                                       checked={!!generalSetting0()?.autoLogOutAfterInactivity}
                                       onChange={(e, checked: boolean) => _.assign(generalSetting0(), { autoLogOutAfterInactivity: checked })} />}
                    />
                  </div>
                  <div className="flex-1 relative">
                    {LL0().settings.autoLogOutUsersAfterInactivity()}
                  </div>
                </div>
                {!!generalSetting0()?.autoLogOutAfterInactivity &&
                  <div
                    className="self-stretch flex flex-row flex-wrap items-center justify-start py-0 pr-0 pl-[52px] gap-[6.4px]">
                    {
                      AUTO_LOGOUT_PERIODS.map(({ label, value }, index) => (
                        <div
                          className="!w-fit px-2 h-8 w-[50px] rounded-10xs bg-gray-solid-gray-130-f4f4f4 box-border flex flex-col items-center justify-center relative gap-[8px_0px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1"
                          onClick={() => _.assign(generalSetting0(), { autoLogOutPeriod: value })}
                          key={index}
                        >
                          {generalSetting0()?.autoLogOutPeriod === value &&
                            <div
                              className="!flex w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-170-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />
                          }
                          <div className="relative z-[1]">{label}</div>
                        </div>
                      ))
                    }
                  </div>
                }
              </div>
              {/*TODO: Move to developer only later*/}
              {/*<div className="self-stretch h-6 flex flex-row items-center justify-start gap-[4px]">*/}
              {/*  <div className="w-12 relative h-6">*/}
              {/*    <FormControlLabel*/}
              {/*      label=''*/}
              {/*      className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"*/}
              {/*      control={<Switch color="primary" size="medium" checked={generalSetting0()?.logUserActions}*/}
              {/*                       onChange={(e, checked: boolean) => _.assign(generalSetting0(), {logUserActions: checked})}                 */}
              {/*      />}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*  <div className="flex-1 relative">{LL0().settings.generalSetting.logUserActions()}</div>*/}
              {/*</div>*/}
              {/*<div className="self-stretch h-6 flex flex-row items-center justify-start gap-[4px]">*/}
              {/*  <div className="w-12 relative h-6">*/}
              {/*    <FormControlLabel*/}
              {/*      label=''*/}
              {/*      className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"*/}
              {/*      control={<Switch color="primary" size="medium" checked={tseConfig0()?.tseEnable || false}*/}
              {/*                       onChange={(e, checked: boolean) => _.assign(tseConfig0(), { tseEnable: checked })}*/}
              {/*      />}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*  <div className="flex-1 relative">{LL0().settings.generalSetting.tseEnable()}</div>*/}
              {/*</div>*/}
              <div className="self-stretch h-6 flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    label=''
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    control={<Switch color="primary" size="medium" checked={!!generalSetting0()?.hasTablePlan}
                                     onChange={(e) => _.assign(generalSetting0(), { hasTablePlan: e.target.checked })} />}
                  />
                </div>
                <div className="flex-1 relative">{LL0().settings.haveTablePlanMode()}</div>
              </div>
              <div className="self-stretch h-6 flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    label=''
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    control={<Switch color="primary" size="medium" checked={!!generalSetting0()?.runInventory}
                                     onChange={(e) => _.assign(generalSetting0(), { runInventory: e.target.checked })} />}
                  />
                </div>
                <div className="flex-1 relative">{LL0().settings.generalSetting.runInventory()}</div>
              </div>
              <div className="self-stretch h-6 flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    label=''
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    control={<Switch color="primary" size="medium"
                                     checked={!!generalSetting0()?.onlyCheckoutPrintedItems}
                                     onChange={(e, checked: boolean) => _.assign(generalSetting0(), { onlyCheckoutPrintedItems: checked })} />}
                  />
                </div>
                <div className="flex-1 relative">
                  {LL0().settings.generalSetting.onlyCheckoutPrintedItems()}
                </div>
              </div>
              {/*<div className="self-stretch h-6 flex flex-row items-center justify-start gap-[4px]">*/}
              {/*  <div className="w-12 relative h-6">*/}
              {/*    <FormControlLabel*/}
              {/*      label=''*/}
              {/*      className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"*/}
              {/*      control={<Switch color="primary" size="medium"/>}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*  <div className="flex-1 relative">{LL0().settings.generalSetting.viewPastStaffReport()}</div>*/}
              {/*</div>*/}


              <div className="self-stretch flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    label=''
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    control={<Switch color="primary" size="medium"
                                     checked={generalSetting0()?.resetMaxIdPerDay ?? false}
                                     onChange={(e, checked) => _.assign(generalSetting0(), { resetMaxIdPerDay: checked })}
                    />}
                  />
                </div>
                <div className="flex-1 relative">
                  {LL0().settings.generalSetting.resetInvoiceIDEveryDay()}
                </div>
              </div>
              {!isQuebecSrmEnabled() &&
                <div className="self-stretch flex flex-row items-center justify-start gap-[4px]">
                  <div className="w-12 relative h-6">
                    <FormControlLabel
                      label=''
                      className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                      control={<Switch color="primary" size="medium"
                                       checked={isAllowMultiplePayments() ?? true}
                                       onChange={(e, checked) => _.assign(generalSetting0(), { allowMultiplePayments: checked })}
                      />}
                    />
                  </div>
                  <div className="flex-1 relative">
                    {LL0().settings.generalSetting.allowMultiplePayments()}
                  </div>
                </div>}

              <div className="self-stretch flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    label=''
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    control={<Switch color="primary" size="medium" checked={isCashbookEnabled() ?? false}
                                     onChange={(e, checked: boolean) => _.assign(generalSetting0(), { cashbookEnable: checked })} />}
                  />
                </div>
                <div className="flex-1 relative">
                  {LL0().settings.generalSetting.cashbookEnable()}
                </div>
              </div>

              <div className="self-stretch flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    label=''
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    control={<Switch color="primary" size="medium" checked={generalSetting0()?.monitoring ?? false}
                                     onChange={(e, checked: boolean) => {
                                       _.assign(generalSetting0(), { monitoring: checked })
                                       if (!checked) {
                                         toast.success('stop recording')
                                         setIsStop(true)
                                       } else {
                                         toast.success('start recording')
                                         setIsStop(false)
                                         setTimeout(() => {
                                           toast.success('Restart app to enable monitoring')
                                           location.reload()
                                         }, 1000)
                                       }
                                     }} />}
                  />
                </div>
                <div className="flex-1 relative">
                  {LL0().settings.userSettings.monitoring()}
                </div>
              </div>

              {tseConfig0()?.tseEnable &&
                <div className="self-stretch flex flex-row items-center justify-start gap-[4px]">
                  <div className="w-12 relative h-6">
                    <FormControlLabel
                      label=''
                      className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                      control={<Switch color="primary" size="medium" checked={tseConfig0()?.hideTseQrCode ?? false}
                                       onChange={(e, checked: boolean) => _.assign(tseConfig0(), { hideTseQrCode: checked })} />}
                    />
                  </div>
                  <div className="flex-1 relative">
                    {LL0().settings.tseConfig.hideTseQrCode()}
                  </div>
                </div>}

              <div className="self-stretch flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    label=''
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    control={<Switch color="primary" size="medium" checked={generalSetting0()?.addCustomerAfterOrder ?? false}
                                     onChange={(e, checked: boolean) => _.assign(generalSetting0(), { addCustomerAfterOrder: checked })} />}
                  />
                </div>
                <div className="flex-1 relative">
                  {LL0().settings.generalSetting.addCustomerAfterOrder()}
                </div>
              </div>

              <div className="self-stretch flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    label=''
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    control={<Switch color="primary" size="medium"
                                     checked={generalSetting0()?.showPopupPrintError ?? false}
                                     onChange={(e, checked: boolean) => _.assign(generalSetting0(), { showPopupPrintError: checked })}
                    />}
                  />
                </div>
                <div className="flex-1 relative">
                  {LL0().printing.showPopupByPrintError()}
                </div>
              </div>

              {/* Select for sync */}
              {<Select
                className='w-full'
                size="small"
                value={deviceSetting0()?.syncMode}
                onChange={(e) => {
                  _.assign(deviceSetting0(), { syncMode: e.target.value });
                }}
              >
                <MenuItem value={SyncMode.online}>{LL0().settings.userSettings.syncOnline()}</MenuItem>
                <MenuItem value={SyncMode.offline}>{LL0().settings.userSettings.syncOffline()}</MenuItem>
                <MenuItem value={SyncMode.mixed}>{LL0().settings.userSettings.syncMix()}</MenuItem>
              </Select>}


            </div>
          </div>}
        </div>
      </div>
      {isUseAPhysicalKeyboardPopuOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeUseAPhysicalKeyboardPopu}
        >
          <UseAPhysicalKeyboardPopu onClose={closeUseAPhysicalKeyboardPopu} />
        </PortalPopup>
      )}
      {isOpenCashDrawerAutomaticallyOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeOpenCashDrawerAutomatically}
        >
          <OpenCashDrawerAutomatically
            onClose={closeOpenCashDrawerAutomatically}
          />
        </PortalPopup>
      )}
      {isUseVirtualPrinterPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeUseVirtualPrinterPopup}
        >
          <UseVirtualPrinterPopup onClose={closeUseVirtualPrinterPopup}/>
        </PortalPopup>
      )}
      {isFavouriteArticlesPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeFavouriteArticlesPopup}
        >
          <FavouriteArticlesPopup onClose={closeFavouriteArticlesPopup}/>
        </PortalPopup>
      )}
      {isCalculateShippingDistanceOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeCalculateShippingDistance}
        >
          <CalculateShippingDistance onClose={closeCalculateShippingDistance}/>
        </PortalPopup>
      )}
      {isGeneralSettingMergeItemsInsOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeGeneralSettingMergeItemsIns}
        >
          <GeneralSettingMergeItemsIns
            onClose={closeGeneralSettingMergeItemsIns}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default GeneralSettingsLeftForm;