import { Fragment, type FunctionComponent, memo } from "react";
import StartSplittingBillVertical1 from "./StartSplittingBillVertical1";
import StartSplittingBillVertical from "./StartSplittingBillVertical";
import { paymentContext0 } from '@/react/PaymentView/PaymentView';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { LL0 } from '@/react/core/I18nService.tsx';
import { useComputed } from '@/react/core/reactive.ts';
import { LL3 } from "@/react/core/I18nCurrency.tsx";

const SplitBillVerticalPlugin: FunctionComponent = () => {
  const {order0, currentSeat, isCurrentSeat, setCurrentSeat, splitEnable, onNext} = paymentContext0;

  const activeSeatOrder = useComputed(() => {
    return order0()?.seatMap?.[currentSeat()];
  });
  const _activeSeatOrder = activeSeatOrder();
  const items = activeSeatOrder()?.items;
  let showNotice = items?.length === 0;

  return (
    <div className="w-full h-[1024px] bg-black-solid-black-700-364150 overflow-hidden flex flex-col items-start justify-start text-center text-sm text-gray-solid-gray-460-9ca3af font-mulish-button-name-bold">
      <div className="self-stretch bg-black-solid-black-500-59636f shadow-[0px_3px_4px_rgba(0,_0,_0,_0.3)] flex flex-row items-center justify-start py-[11px] px-3 z-[1] Mobile_480:py-2.5 Mobile_480:px-2 Mobile_480:box-border mq320:py-2 mq320:px-1.5 mq320:box-border">
        <div className="flex-1 h-[42px] overflow-x-auto flex flex-row items-center justify-start gap-[4px] Mobile_480:h-9 mq320:h-8">
          {order0()?.seatMap?.map((sOrder, index) => {
            const isSelected = isCurrentSeat(index)
            return (
              <StartSplittingBillVertical1
                seatNumber={`${LL0().payment.seat().toUpperCase()} ${index + 1}`}
                seatLabel={`${LL0().payment.seat().toUpperCase()} ${index + 1}`}
                isDefault={!isSelected}
                isSelected={isSelected}
                showSeat={isSelected}
                startSplittingBillVerticaFlexShrink="0"
                onClick={() => setCurrentSeat(index)}
                key={index}
              />
            )
          })}
        </div>
      </div>
      <div className="self-stretch flex-1 flex flex-row items-center justify-start z-[0]">
        <div className="self-stretch flex-1 rounded [background:linear-gradient(180deg,_#1d2634,_rgba(31,_41,_56,_0.75))] flex flex-col items-center justify-between py-2.5 px-0 z-[1]">
          <div className="self-stretch flex-1 overflow-y-auto flex flex-col items-center justify-start py-0 px-2.5 gap-[12px_0px]">
            {showNotice ?
            <b className="!block self-stretch relative hidden h-4 shrink-0 Mobile_480:text-smi">
              {LL0().order.selectItemsFromTheRight()}
            </b>
              :
            <div className="self-stretch rounded bg-gray-solid-gray-950-414c5e shadow-[2px_2px_2px_rgba(189,_189,_189,_0.2)_inset] overflow-y-auto shrink-0 flex flex-col items-center justify-start py-3 px-0 gap-[12px] border-[1px] border-solid border-blue-opacity-blue-15-f3f9ff Mobile_480:hidden">
              {_activeSeatOrder && items?.filter(item => item.quantity - (item.movedQuantity || 0) > 0).map((item, index) => (
                  <Fragment key={index}>
                      {index !== 0 &&
                        <div className="self-stretch relative h-px shrink-0">
                          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] [background:linear-gradient(180deg,_#1d2634,_rgba(31,_41,_56,_0.75))] opacity-[0.7]" />
                        </div>
                      }
                      <StartSplittingBillVertical
                        itemName="7. Roast Chicken Salad 2"
                        oldPrice="$90.99"
                        price="$99.99"
                        modifierS="medium size, tomato sauce"
                        quantity="1"
                        showOldPrice={false}
                        showModifierS={false}
                        item={item}
                        order={_activeSeatOrder}
                      />
                  </Fragment>
                ))}
            </div>
            }
            <div className="self-stretch flex flex-row items-end justify-between text-left text-mini text-basic-color-white Mobile_480:h-auto">
              <div className="h-[39px] flex flex-col items-start justify-end gap-[2px_0px]">
                <b className="relative leading-[15px] inline-block w-[43px] mq320:text-sm">
                  {LL0().payment.total()}:
                </b>
                <b className="relative text-sm text-red-solid-red-200-ff5252 mq320:text-smi">
                  {LL3().format.currency(activeSeatOrder()?.vSum || 0)}
                </b>
              </div>
              <div className="rounded-81xl [background:linear-gradient(180deg,_rgba(139,_184,_255,_0.8),_rgba(38,_93,_219,_0.8))] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[42px] overflow-hidden flex flex-row items-center justify-center py-0 px-8 box-border text-center text-base mq320:h-9 mq320:pl-6 mq320:pr-6 mq320:box-border"
                   ref={makeRipple}
                   onClick={onNext}
              >
                <b className="relative Mobile_480:text-mini mq320:text-smi">
                  {LL0().posSetup.next()}
                </b>
              </div>
            </div>
          </div>
          <div className="self-stretch h-[50px] flex flex-row items-end justify-center Mobile_480:h-11 mq320:h-10">
            <div className="self-stretch flex-1 rounded-10xs [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#ff5621] flex flex-col items-center justify-center">
              <img
                className="relative w-10 h-10 object-cover Mobile_480:w-9 Mobile_480:h-9 mq320:w-8 mq320:h-8"
                alt=""
                src="/icon-vertical-cash-icon1@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 rounded-10xs [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#4249ff] flex flex-col items-center justify-center">
              <img
                className="relative w-10 h-10 object-cover Mobile_480:w-9 Mobile_480:h-9 mq320:w-8 mq320:h-8"
                alt=""
                src="/icon-vertical-card-icon11@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 rounded-10xs [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#2befa9] flex flex-col items-center justify-center">
              <img
                className="relative w-10 h-10 object-cover Mobile_480:w-9 Mobile_480:h-9 mq320:w-8 mq320:h-8"
                alt=""
                src="/icon-vertical-card-dollar-icon@2x.png"
              />
            </div>
          </div>
        </div>
        <div className="self-stretch flex-1 flex flex-row items-center justify-start p-2.5 z-[0] Mobile_480:hidden Mobile_480:p-2 Mobile_480:box-border mq320:p-1.5 mq320:box-border">
          <b className="self-stretch flex-1 relative hidden items-center justify-center Mobile_480:text-smi">
            {LL0().payment.noMoreItemToSelect()}
          </b>
          <div className="self-stretch flex-1 rounded bg-gray-solid-gray-930-546073 shadow-[2px_2px_2px_rgba(189,_189,_189,_0.2)_inset] overflow-y-auto flex flex-col items-center justify-start py-3 px-0 gap-[12px] border-[1px] border-solid border-blue-opacity-blue-15-f3f9ff">
            {order0().items.filter(item => item.quantity - (item.movedQuantity || 0) > 0).map((item, index) => (
                <Fragment key={index}>
                    {index !== 0 &&
                      <div className="self-stretch relative h-px shrink-0">
                        <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] [background:linear-gradient(180deg,_#1d2634,_rgba(31,_41,_56,_0.75))] opacity-[0.7]" />
                      </div>
                    }
                    <StartSplittingBillVertical
                      itemName="7. Roast Chicken Salad 2"
                      oldPrice="$90.99"
                      price="$99.99"
                      modifierS="medium size, tomato sauce"
                      quantity={"1"}
                      showOldPrice={false}
                      showModifierS={false}
                      item={item}
                      order={order0()}
                    />
                </Fragment>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(SplitBillVerticalPlugin);
