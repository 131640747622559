import debug from 'debug'

import { LL0 } from '@/react/core/I18nService'
import { assert } from '@/shared/assert'
import { roundNumber } from '@/shared/order/order-config'
import { stringifyObj } from '@/shared/utils2'

import type { TransactionData } from './lib/types'

const log = debug('dev:transaction-assert')

export function assertTransaction(t: TransactionData, ref: string) {
  const net = Number(t.mont.avantTax)
  const gst = Number(t.mont.TPS)
  const qst = Number(t.mont.TVQ)
  const gross = Number(t.mont.apresTax)
  const ajus = Number(t.mont.ajus)
  const mtdu = Number(t.mont.mtdu)

  console.log('Asserting transaction')
  console.table({ net, gst, qst, gross, ajus, mtdu })

  try {
    if (net === 0) assert(gst === 0 && qst === 0 && gross === 0, 'Something wrong when calculating order amount: NET = 0 but GST, QST or GROSS are not 0')
    assert(gross === roundNumber(net + gst + qst), 'Transaction amount is not correct apresTax should equal avantTax + TPS + TVQ')
    if (!isNaN(ajus)) {
      assert(!isNaN(mtdu), 'Transaction amount is not correct mtdu should be set when ajustement is set')
      assert(mtdu === roundNumber(gross + ajus), 'Transaction amount is not correct mtdu should equal apresTax + ajus')
    }
  } catch (e) {
    if (e instanceof Error) log(e.message, { alert: true })
    else log('Unknown error' + stringifyObj(e as object), { alert: true })

    throw new Error(LL0().srm.errors.failToMakeTransaction({ refCode: ref }))
  }
  // TODO: add more check here
}
