import type { RxCollection, RxDatabase, RxJsonSchema } from 'rxdb'

import { createCollection } from '@/data/utils/migration.ts'
import type { InvoiceTypes } from '@/pos/OrderType.ts'

export const PRINT_IMAGE_COLLECTION_NAME = 'print_images'
export const VERSION = 7

/** @deprecated use PrintScripts instead */
export interface PrintImage {
  _id: string
  order?: string
  orderId?: string
  typeInvoice?: InvoiceTypes
  data?: string
  date?: number
  type?: string
  metadata?: Record<string, unknown>
}

const printImageSchema: RxJsonSchema<PrintImage> = {
  title: 'printImage schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    order: { type: 'string' },
    orderId: { type: 'string' },
    typeInvoice: { type: 'string' },
    data: { type: 'string' },
    date: { type: 'number', multipleOf: 1, minimum: 1, maximum: 10 ** 10 },
    type: { type: 'string' },
    metadata: { type: 'object' },
  },
  indexes: [],
  required: ['_id'],
}

export type PrintImageCollection = RxCollection<PrintImage>
/** @deprecated use PrintScripts instead */
export const PrintImage: PrintImageCollection = {} as PrintImageCollection

// Make this collection available globally
Object.assign(window, { PrintImage })

export const createPrintImageCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: PrintImage,
    collectionName: PRINT_IMAGE_COLLECTION_NAME,
    version: VERSION,
    schema: printImageSchema,
    extraStrategy: { 7: () => null } // remove all data
  })
}
