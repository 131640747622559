import {convertDocuments, type DocDeepSignal} from "@/data/data-utils";
import {computed, effectOn, signal} from "@/react/core/reactive";
import {dataLock} from "@/data/DataUtils.ts";
import {type Reservation, ReservationCollection, ReservationStatus} from "@/data/Reservation.ts";
import dayjs from 'dayjs';
import _ from 'lodash';
import {MarketPlaceProvider} from "@/pos/OrderType.ts";
import axios from "axios";
import {getApiUrl} from "@/shared/utils.ts";
import {ReservationAction} from "@/pos/OnlineReservationType.ts";
import uuid from "time-uuid";
import {products0} from "@/data/ProductHub.ts";
import type {Product} from "@/data/Product.ts";

const axiosConfig = {
  // bypass 3xx redirect
  // TODO: check if 2xx, 4xx, 5xx
  validateStatus: (status: number) => status < 400
}

export const [reservations0, setReservations0] = signal<Array<DocDeepSignal<Reservation>>>([]);
export const [reservationsV, setReservationsV] = signal<number>(0);
export const [reservation0, setReservation0] = signal<Reservation | undefined>();
// @ts-ignore
window.reservations0 = reservations0;

// region rsv1
export const [filter, setFilter] = signal<string>("all");
export const rsvFilters = computed<Array<string>>(() => {
  return filter() === "all" ? ['pending', 'completed'] : [filter()]
})
export const [selectingDate, setSelectingDate] = signal<Date>(new Date());
export const selectingDateUnix = computed<number>(() => dayjs(selectingDate()).startOf('day').unix())
export const reservationsOfSelectingDate = computed<Array<Reservation>>(() => {
  const foundItem = reservationsInViewGroupByWeek().find(item => item.unix === selectingDateUnix())
  if (!foundItem)
    return []
  return foundItem.reservations
})

export const [viewDate, setViewDate] = signal<Date>(new Date());
export const viewMonth = computed<string>(() => dayjs(viewDate()).format('MM YYYY'))
export const viewPrevWeek = () => {
  console.log('viewPrevMonth')
  setViewDate(v => dayjs(v).subtract(1, 'week').toDate())
}
export const viewNextWeek = () => {
  console.log('viewNextMonth')
  setViewDate(v => dayjs(v).add(1, 'week').toDate())
}
export const viewPrevMonth = () => {
  console.log('viewPrevMonth')
  setViewDate(v => dayjs(v).subtract(1, 'month').toDate())
}
export const viewNextMonth = () => {
  console.log('viewNextMonth')
  setViewDate(v => dayjs(v).add(1, 'month').toDate())
}
type ReservationsOfDay = {
  unix: number,
  date: Date,
  dayOfWeek: string,
  dayOfMonth: string,
  reservations: Array<Reservation>
}
export const startOfWeek = computed(() => dayjs(viewDate()).startOf('week'))
export const endOfWeek = computed(() => dayjs(viewDate()).endOf('week'))
export const reservationsInViewGroupByWeek = computed<Array<ReservationsOfDay>>(() => {
  console.log('trigger reservationsInViewGroupByWeek')
  const output = []
  const rsvsInView = reservations0().filter((rsv: Reservation) => {
    return rsvFilters().includes(rsv.status) &&
      dayjs(rsv.date).isAfter(startOfWeek()) &&
      dayjs(rsv.date).isBefore(endOfWeek())
  })
  const rsvsGroupByDate = _.groupBy(rsvsInView, rsv => dayjs(rsv.date).startOf('day').unix())
  let cursor = startOfWeek();
  while(cursor.isBefore(endOfWeek())) {
    output.push({
      unix: cursor.unix(),
      date: cursor.toDate(),
      dayOfWeek: cursor.format('ddd'),
      dayOfMonth: cursor.format('D'),
      reservations: rsvsGroupByDate[cursor.unix()] || []
    })
    cursor = cursor.add(1, 'day')
  }
  console.log('output', output)
  return output
})
// actions
export const upsertReservation = async (reservation: Reservation) => {
  await ReservationCollection.upsert(reservation)
}
export const completeReservation = async (reservation: Reservation) => {
  if (reservation.status === ReservationStatus.Completed)
    return
  const rawDoc = await ReservationCollection.findOne({selector: {_id: reservation._id}}).exec()
  rawDoc?.incrementalPatch({status: ReservationStatus.Completed})
  setReservation0(v => undefined)
}
// endregion

// v2
export const DATE_FILTERS = {
  today: 1,
  yesterday: 2,
  thisWeek: 3,
  thisMonth: 4,
  custom: 5
}
export const [dateFilter, setDateFilter] = signal<number>(DATE_FILTERS.thisWeek)
export const [startDate, setStartDate] = signal<Date>(dayjs().startOf('w').toDate());
export const [endDate, setEndDate] = signal<Date>(dayjs().endOf('w').toDate());
const shortDateFmt = 'MMM DD' // TODO: i18n
export const fmtStartDate = computed(() => dayjs(startDate()).format(shortDateFmt))
export const fmtEndDate = computed(() => dayjs(endDate()).format(shortDateFmt))

export function filterToday() {
  setDateFilter(DATE_FILTERS.today)
  setStartDate(dayjs().startOf('day').toDate())
  setEndDate(dayjs().endOf('day').toDate())
}
export function filterYesterday() {
  setDateFilter(DATE_FILTERS.yesterday)
  const yesterday = dayjs().subtract(1, 'd')
  setStartDate(yesterday.startOf('day').toDate())
  setEndDate(yesterday.endOf('day').toDate())
}
export function filterThisWeek() {
  setDateFilter(DATE_FILTERS.thisWeek)
  setStartDate(dayjs().startOf('w').toDate())
  setEndDate(dayjs().endOf('w').toDate())
}
export function filterThisMonth() {
  setDateFilter(DATE_FILTERS.thisMonth)
  setStartDate(dayjs().startOf('month').toDate())
  setEndDate(dayjs().endOf('month').toDate())
}
export function filterCustomDateRange(start: any, end: any) {
  setDateFilter(DATE_FILTERS.custom);
  setStartDate(dayjs(start).startOf('day').toDate())
  setEndDate(dayjs(end).endOf('day').toDate())
}

export const [statusFilter, setStatusFilter] = signal<string>(ReservationStatus.Pending);
export const isUpcomingStatusFilterSelected = computed<boolean>(() => statusFilter() === ReservationStatus.Pending)
export const isOldBookingStatusFilterSelected = computed<boolean>(() => statusFilter() === ReservationStatus.Completed)
export const isCancelledStatusFilterSelected = computed<boolean>(() => statusFilter() === ReservationStatus.Declined)

export const [searchFilter, _setSearchFilter] = signal<string>('');
export const setSearchFilter = _.debounce(_setSearchFilter, 500, {trailing: true, leading: true});

export const todayPendingReservations = computed<number>(() => {
  const rsvs = _.filter(reservations0(),
    (rsv: Reservation) => rsv.status === ReservationStatus.Pending &&
      dayjs().startOf('day').isBefore(dayjs.unix(rsv.date)) &&
      dayjs().endOf('day').isAfter(dayjs.unix(rsv.date)))
  return rsvs.length
});
export const filteredReservations = computed(() => {
  const rangeFilters = _.sortBy(reservations0().filter(r => {
    const appointmentDate = dayjs.unix(r.date);
    return appointmentDate.isAfter(startDate()) && appointmentDate.isBefore(endDate())
  }), r => r.date)
  return searchFilter()
    ? rangeFilters.filter(r => _.toLower(r.customer?.name).includes(_.toLower(searchFilter())) || r.customer?.phone?.includes(searchFilter()))
    : rangeFilters
})
export const upcomingReservations = computed<Array<Reservation>>(() => {
  return filteredReservations().filter(reservation => reservation.status === ReservationStatus.Pending)
})
export const oldBookingReservations = computed<Array<Reservation>>(() => {
  return filteredReservations().filter(reservation => reservation.status === ReservationStatus.Completed)
})
export const cancelledReservations = computed<Array<Reservation>>(() => {
  return filteredReservations().filter(reservation => reservation.status === ReservationStatus.Declined)
})

export const reservationsViewModels = computed<Array<Reservation>>(() => {
  switch (statusFilter()) {
    case ReservationStatus.Pending:
      return upcomingReservations();
    case ReservationStatus.Completed:
      return oldBookingReservations();
    case ReservationStatus.Declined:
      return cancelledReservations();
    default:
      return [];
  }
})

export const upcomingReservationsCount = computed<number>(() => upcomingReservations().length)
export const oldBookingReservationsCount = computed<number>(() => oldBookingReservations().length)
export const cancelledReservationsCount = computed<number>(() => cancelledReservations().length)

export function showUpcomingReservation() {
  setStatusFilter(ReservationStatus.Pending)
}
export function showOldBookingReservation() {
  setStatusFilter(ReservationStatus.Completed)
}
export function showCancelledReservation() {
  setStatusFilter(ReservationStatus.Declined)
}

export enum ReservationDetailMode {
  NEW = 1,
  EDIT = 2,
  REFRESH = 3
}
export const [reservationDetailMode, setReservationDetailMode] = signal<ReservationDetailMode>(ReservationDetailMode.NEW)
export const [autoCancel, setAutoCancel] = signal<string>('')
export const [customerName, setCustomerName] = signal<string>('');
export const [customerPhone, setCustomerPhone] = signal<string>('');
export const [customerEmail, setCustomerEmail] = signal<string>('');
export const [rsvDate, _setRsvDate] = signal<number>(0);
export const [rsvNote, setRsvNote] = signal<string>('');
export const [rsvGuest, setRsvGuest] = signal<number>(1);
export const [rsvServices, setRsvServices] = signal<any[]>([])
export const [isMultipleStaffs, setIsMultipleStaffs] = signal<boolean>(false);
export const [staff, setStaff] = signal<string>('');
export function setStaffForService(serviceDisplayName?: string, staff?: string) {
  if (!serviceDisplayName) {
    console.log('setStaffForService', 'serviceDisplayName empty')
    return
  }
  setRsvServices(services => {
    const service = services.find(item => getServiceDisplayText(item) === serviceDisplayName)
    if (service) {
      console.log('found service, set staff', staff)
      service.staff = staff
    } else {
      console.log('service not found')
    }
    return [...services]
  })
}
export type SearchServicePopupModes = "add" | "edit";
export const [searchServicePopupMode, setSearchServicePopupMode] = signal<SearchServicePopupModes>("add")
export const [currentProduct, setCurrentProduct] = signal<Product | undefined | null>();
export const isFromWeb = computed(() => reservation0() && reservation0()?.provider)
export function setRsvDate(newValue: Date) {
  const date = dayjs(rsvDate());
  const hours = date.hour();
  const minutes = date.minute();
  const newDate = dayjs(newValue).startOf('day').add(hours, 'hours').add(minutes, 'minutes');
  _setRsvDate(newDate.unix());
}
export function setRsvTime(newValue: Date) {
  const date = dayjs(newValue);
  const hours = date.hour();
  const minutes = date.minute();
  const newDate = dayjs.unix(rsvDate()).startOf('day').add(hours, 'hours').add(minutes, 'minutes');
  _setRsvDate(newDate.unix());
}
export function servicesSummary(services?: Array<{name: string, items?: Array<{name: string}>}>) {
  if (_.isEmpty(services))
    return '-'

  return services!.map(service => {
    const hasSubService = !_.isEmpty(service.items)
    if (hasSubService)
      return `${service.name} (${_.join(service.items!.map(item => item.name), ', ')})`
    return service.name
  }).join(', ')
}

export function onConfirmModifiers(product: any, modifiers: Array<any>) {
  console.log('onConfirmModifiers', modifiers)
  addService({
    ...product,
    modifiers: _.map(modifiers, m => _.pick(m, ['_id', 'name', 'price']))
  })
}
export function addService(service: any) {
  console.log('addService', service);
  service = Object.assign(
    _.pick(service, ['_id', 'id', 'modifiers', 'name', 'note', 'price', 'quantity', 'groupPrinter', 'groupPrinter2']),
    { tax: Array.isArray(service.tax) ? service.tax : [service.tax, service.tax2] }
  );
  console.log('service', service);

  if (searchServicePopupMode() === "add") {
    setRsvServices(val => [...val, service])
  } else {
    console.log('update', currentProduct(), service);
    setRsvServices(currentServices => {
      const curId = currentProduct()?._id;
      const idx = currentServices.findIndex(item => item._id === curId);
      const newServices = _.clone(currentServices);
      if (idx >= 0) {
        console.log('found, update')
        newServices.splice(idx, 1, service)
      }
      return newServices
    })
  }

  _resetSearchServicePopupState()
}
export function removeServiceAt(idx: number) {
  setRsvServices(s => s.filter((_, i) => i !== idx))
}
function _resetSearchServicePopupState() {
  setCurrentProduct(v => undefined)
  setSearchServicePopupMode("add")
}
export function getServiceDisplayText(p: any) {
  if (!p) return '';
  return _.toLower(_.compact([p.id, p.name]).join('. '))
}
export function findProductFromDisplayText(productDisplayText?: string) {
  if (!productDisplayText)
    return
  for (const p of products0()) {
    if (getServiceDisplayText(p) === productDisplayText) {
      return _.cloneDeep(p)
    }
  }
}

export function onNewReservation() {
  setReservation0(v => undefined)
  setCustomerName('')
  setCustomerPhone('')
  setCustomerEmail('')
  _setRsvDate(dayjs().unix())
  setRsvNote('')
  setRsvGuest(1)
  setRsvServices([])
  setIsMultipleStaffs(false);
  setStaff('');
  setReservationDetailMode(ReservationDetailMode.NEW)
}
function _prepareEditReservation(reservation: Reservation) {
  if (!reservation) return;
  setReservation0(reservation);
  setCustomerName(`${reservation.customer.name}`)
  setCustomerPhone(reservation.customer.phone)
  setCustomerEmail(reservation.customer.email)
  _setRsvDate(reservation.date)
  setRsvNote(reservation.note)
  setRsvGuest(reservation.noOfGuests)
  setRsvServices(_.cloneDeep(reservation.items) as [])
  setIsMultipleStaffs(!!reservation.isMultipleStaffs);
  setStaff(reservation.staff || '');
}
export function onEditReservation(reservation: Reservation) {
  _prepareEditReservation(reservation)
  setReservationDetailMode(ReservationDetailMode.EDIT)
}
export function onRefreshReservation(reservation: Reservation) {
  _prepareEditReservation(reservation)
  setReservationDetailMode(ReservationDetailMode.REFRESH)
}
export async function onSaveCurrentReservation() {
  console.log('onSaveCurrentReservation');
  let updated: any;
  const mode = reservationDetailMode();
  if (mode === ReservationDetailMode.NEW) {
    updated = {
      _id: uuid(),
      status: ReservationStatus.Pending,
      customer: {}
    }
  } else {
    updated = _.cloneDeep(reservation0());
    // @ts-ignore
    delete updated.doc;
    if (mode === ReservationDetailMode.REFRESH) {
      updated.status = ReservationStatus.Pending
    }
  }
  updated.customer.name = customerName();
  updated.customer.phone = customerPhone();
  updated.customer.email = customerEmail();
  updated.date = rsvDate();
  updated.note = rsvNote();
  updated.noOfGuests = rsvGuest();
  updated.items = rsvServices();
  updated.staff = staff();
  updated.isMultipleStaffs = isMultipleStaffs();
  console.log('upsert', mode, updated);
  await ReservationCollection.upsert(updated);
  if (
    mode === ReservationDetailMode.EDIT ||
    mode === ReservationDetailMode.REFRESH
  ) {
    await _updateOnlineReservation(updated, ReservationAction.PENDING)
  }
}
export async function removeCurrentReservation() {
  const r = reservation0()
  if (!r || !r._id) return;
  await removeReservation(r._id)
}
export async function checkInCurrentReservation() {
  const r = reservation0()
  if (!r || !r._id) return
  await checkInReservation(r._id)
}
export async function removeReservation(_id: string) {
  const rawDoc = await ReservationCollection.findOne({selector: {_id}}).exec()
  if (!rawDoc) {
    console.error(`reservation ${_id} not found`)
    return
  }
  await rawDoc.incrementalPatch({status: ReservationStatus.Declined})
  setReservation0(v => undefined)
  await _updateOnlineReservation(rawDoc._data, ReservationAction.CANCEL)
}
export async function cancelReservation(_id: string) {
  console.log('cancelReservation', _id)
  const rawDoc = await ReservationCollection.findOne({selector: {_id}}).exec()
  if (!rawDoc) {
    console.error(`reservation with _id ${_id} not found`)
    return
  }
  await rawDoc.incrementalPatch({status: ReservationStatus.Declined})
  setReservation0(v => undefined)
  await _updateOnlineReservation(rawDoc._data, ReservationAction.CANCEL)
}
export async function checkInReservation(_id: string) {
  const rawDoc = await ReservationCollection.findOne({selector: {_id: _id}}).exec()
  if (!rawDoc) {
    console.error(`reservation with _id ${_id} not found`)
    return
  }
  await rawDoc.incrementalPatch({status: ReservationStatus.Completed})
  setReservation0(v => undefined)
  await _updateOnlineReservation(rawDoc._data, ReservationAction.CHECK_IN)
}
async function _updateOnlineReservation(reservation: Reservation, action: ReservationAction) {
  if (reservation.provider === MarketPlaceProvider.PIKAPOINT) {
    try {
      await axios.post(`${getApiUrl()}/api/pika/update-reservation`, { reservation, action }, axiosConfig)
    } catch (e) {
      console.error('failed to update PikaPoint reservation with error', e)
    }
  }
}

effectOn([reservationsV], async () => {
  await dataLock.acquireAsync();
  const rsvs = await ReservationCollection.find().exec();
  const reservations = convertDocuments<Reservation>(rsvs, true, [], {debounce: 200});
  setReservations0(reservations)
})

dataLock.acquireAsync().then(() => {
  ReservationCollection.$.subscribe(() => {
    console.log('ReservationCollection changed')
    setReservationsV(v => v + 1);
  })
})
