import { RECURRING_WEEKDAYS } from "@/data/Product";
import { STATES, VIEW__PRODUCT } from "@/react/EditOnlineMenuView/EditOnlineMenuView.logic";
import type { FunctionComponent } from "react";
import {LL0} from "@/react/core/I18nService.tsx";
import _ from "lodash";

const ValidityRecurringFieldset: FunctionComponent = () => {
  return (
    <div className="self-stretch h-[77px] flex flex-col items-start justify-start gap-[8px] text-left text-lg text-gray-opacity-gray-60-2e2e48-300 font-mulish-button-name-bold height_414:gap-[2px]">
      <div className="relative font-semibold md:text-base sm:text-sm height_414:text-smi">{`>> ${LL0().editOnlineMenu.recurringEvery()}`}</div>
      <div className="self-stretch rounded-10xs bg-orange-opacity-orange-50-fbe5c9 shadow-[0px_2px_4px_rgba(0,_0,_0,_0.1)_inset,_0px_-1px_4px_rgba(0,_0,_0,_0.1)_inset] h-[46px] flex flex-row items-center justify-center py-1 px-2 box-border gap-[6px] text-center text-lgi text-orange-solid-orange-600-bf710f sm:gap-[2px] sm:pl-1 sm:pr-1 sm:box-border">
        {Object.entries(RECURRING_WEEKDAYS).map(([key, val]) => (
        <div key={key} onClick={() => VIEW__PRODUCT.onToogleValidityRecurringWeekDays(key)} className="flex-1 rounded-3xs flex flex-row items-center justify-center py-[3px] px-2 relative gap-[6px] text-blue-solid-blue-900-081a51 sm:h-8 sm:pl-1.5 sm:pr-1.5 sm:box-border mq768:h-[38px] mq768:pl-1 mq768:pr-1 mq768:box-border">
          {STATES.editingProduct()?.validity_recurringWeekDays?.includes(key) && (
          <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-3xs bg-white-solid-white-100-ffffff shadow-[0px_1px_2px_rgba(15,_86,_179,_0.2),_0px_2px_4px_rgba(15,_86,_179,_0.2)] z-[0]" />
          )}
          <div className="flex-1 relative tracking-[0.02em] leading-[150%] font-medium z-[1] md:text-base sm:text-sm mq768:text-smi mq600:text-xs">
            {_.get(LL0().onlineOrder.weekday, key)?.()}
          </div>
        </div>
        ))}
        {/*
        <div className="flex-1 rounded-3xs flex flex-row items-center justify-center py-[3px] px-2 sm:h-8 sm:pl-1.5 sm:pr-1.5 sm:box-border mq768:h-[38px] mq768:pl-1 mq768:pr-1 mq768:box-border">
          <div className="flex-1 relative tracking-[0.02em] leading-[150%] font-medium md:text-base sm:text-sm mq768:text-smi mq600:text-xs">
            Mon
          </div>
        </div>
        <div className="flex-1 rounded-3xs flex flex-row items-center justify-center py-[3px] px-2 sm:h-8 sm:pl-1.5 sm:pr-1.5 sm:box-border mq768:h-[38px] mq768:pl-1 mq768:pr-1 mq768:box-border">
          <div className="flex-1 relative tracking-[0.02em] leading-[150%] font-medium md:text-base sm:text-sm mq768:text-smi mq600:text-xs">
            Tue
          </div>
        </div>
        <div className="flex-1 rounded-3xs flex flex-row items-center justify-center py-[3px] px-2 sm:h-8 sm:pl-1.5 sm:pr-1.5 sm:box-border mq768:h-[38px] mq768:pl-1 mq768:pr-1 mq768:box-border">
          <div className="flex-1 relative tracking-[0.02em] leading-[150%] font-medium md:text-base sm:text-sm mq768:text-smi mq600:text-xs">
            Web
          </div>
        </div>
        <div className="flex-1 rounded-3xs flex flex-row items-center justify-center py-[3px] px-2 sm:h-8 sm:pl-1.5 sm:pr-1.5 sm:box-border mq768:h-[38px] mq768:pl-1 mq768:pr-1 mq768:box-border">
          <div className="flex-1 relative tracking-[0.02em] leading-[150%] font-medium md:text-base sm:text-sm mq768:text-smi mq600:text-xs">
            Thu
          </div>
        </div>
        <div className="flex-1 rounded-3xs flex flex-row items-center justify-center py-[3px] px-2 relative gap-[6px] text-blue-solid-blue-900-081a51 sm:h-8 sm:pl-1.5 sm:pr-1.5 sm:box-border mq768:h-[38px] mq768:pl-1 mq768:pr-1 mq768:box-border">
          <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-3xs bg-white-solid-white-100-ffffff shadow-[0px_1px_2px_rgba(15,_86,_179,_0.2),_0px_2px_4px_rgba(15,_86,_179,_0.2)] z-[0]" />
          <div className="flex-1 relative tracking-[0.02em] leading-[150%] font-medium z-[1] md:text-base sm:text-sm mq768:text-smi mq600:text-xs">
            Fri
          </div>
        </div>
        <div className="flex-1 rounded-3xs flex flex-row items-center justify-center py-[3px] px-2 relative gap-[6px] text-blue-solid-blue-900-081a51 sm:h-8 sm:pl-1.5 sm:pr-1.5 sm:box-border mq768:h-[38px] mq768:pl-1 mq768:pr-1 mq768:box-border">
          <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-3xs bg-white-solid-white-100-ffffff shadow-[0px_1px_2px_rgba(15,_86,_179,_0.2),_0px_2px_4px_rgba(15,_86,_179,_0.2)] z-[0]" />
          <div className="flex-1 relative tracking-[0.02em] leading-[150%] font-medium z-[1] md:text-base sm:text-sm mq768:text-smi mq600:text-xs">
            Sat
          </div>
        </div>
        */}
      </div>
    </div>
  );
};

export default ValidityRecurringFieldset;
