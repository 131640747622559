import type { FunctionComponent } from "react";
import {useSignal} from "@/react/core/reactive.ts";
import {makeRipple} from "@/react/core/ripple-utils.ts";
import {LL0} from "@/react/core/I18nService.tsx";


const DefauleTimeToCompleteOder: FunctionComponent = () => {
  const [timeCompleteOrder, setTimeCompleteOrder] = useSignal(10)
  return (
    <div className="self-stretch overflow-y-auto shrink-0 flex flex-col items-start justify-start gap-[8px] text-left text-base text-black-solid-black-880-1d1d26 font-mulish">
      <b className="relative sm:text-mini Tablet_600:text-mini">
        {LL0().onlineOrder.setDefaultTimeCompleteOrder()}
      </b>
      <div className="flex flex-row flex-wrap items-center justify-start gap-[20px] text-mini">
        {[10, 20, 30, 40, 50, 60].map((value)=> (
          <div key={value} className="rounded-10xs bg-gray-solid-gray-130-f4f4f4 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] box-border h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1"
            onClick={()=>setTimeCompleteOrder(value)} ref={makeRipple}
          >
            {timeCompleteOrder() === value && <div
              className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff"/>}
            <div className="relative z-[1]">{value}</div>
          </div>
        ))}
        {/*<div className="rounded-10xs bg-gray-solid-gray-130-f4f4f4 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] box-border h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1">*/}
        {/*  <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />*/}
        {/*  <div className="relative z-[1]">10</div>*/}
        {/*</div>*/}
        {/*<div className="rounded-10xs bg-gray-solid-gray-130-f4f4f4 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] box-border h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1">*/}
        {/*  <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />*/}
        {/*  <div className="relative z-[1]">20</div>*/}
        {/*</div>*/}
        {/*<div className="rounded-10xs bg-gray-solid-gray-130-f4f4f4 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] box-border h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1">*/}
        {/*  <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />*/}
        {/*  <div className="relative z-[1]">30</div>*/}
        {/*</div>*/}
        {/*<div className="rounded-10xs bg-gray-solid-gray-130-f4f4f4 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] box-border h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1">*/}
        {/*  <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />*/}
        {/*  <div className="relative z-[1]">40</div>*/}
        {/*</div>*/}
        {/*<div className="rounded-10xs bg-gray-solid-gray-130-f4f4f4 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] box-border h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1">*/}
        {/*  <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />*/}
        {/*  <div className="relative z-[1]">50</div>*/}
        {/*</div>*/}
        {/*<div className="rounded-10xs bg-gray-solid-gray-130-f4f4f4 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] box-border h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1">*/}
        {/*  <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />*/}
        {/*  <div className="relative z-[1]">60</div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default DefauleTimeToCompleteOder;
