import {
  LocalizationProvider, StaticTimePicker, StaticDatePicker
} from "@mui/x-date-pickers";
import type {FunctionComponent, SyntheticEvent} from "react";
import dayjs, {type Dayjs} from "dayjs";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {FormControlLabel, Switch, Tab, Tabs} from "@mui/material";
import PortalPopup from "@eod/PortalPopup.tsx";
import {signal, useComputed, useSignal} from "@/react/core/reactive.ts";
import {makeRipple} from "@/react/core/ripple-utils.ts";
import {setNow} from "@/pos/logic/time-provider.ts";


const tabs = ['Mock Time', 'Tab 2', 'Tab 3'];

export const [openDateTimePickerPopup, setOpenDateTimePickerPopup] = signal<boolean>(false)

const DateTimePickerPopup: FunctionComponent = () => {

  const [tabIndex, setTabIndex] = useSignal(0);

  const [currentDate, setCurrentDate] = useSignal<Dayjs | null>(dayjs());
  const [currentTime, setCurrentTime] = useSignal<Dayjs | null>(dayjs());
  const [mockTimeSwitchStatus, setMockTimeSwitchStatus] = useSignal<boolean>(false)

  const combinedDateTime = useComputed(() => currentDate()?.hour(currentTime().hour()).minute(currentTime().minute()));

  const handleTabIndexChange = (event: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };
  const handleDateChange = (value: Dayjs | null) => {
    setCurrentDate(value);
  };

  const handleTimeChange = (value: Dayjs | null) => {
    setCurrentTime(value);
  };
  const handleConfirm = () => {
    if (mockTimeSwitchStatus()) {
      setNow(combinedDateTime().toISOString())
    } else {
      setNow(undefined)
    }
    // console.log('now ', dayjs(now())?.format('YYYY-MM-DD HH:mm'), now())
    setOpenDateTimePickerPopup(false)
  }
  // useEffect(() => {
  //   if (currentDate() && currentTime() && mockTimeSwitchStatus()) {
  //
  //   }
  // }, [currentDate(), currentTime(), mockTimeSwitchStatus(), combinedDateTime()]);
  return (
    <>
      {openDateTimePickerPopup() && <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setOpenDateTimePickerPopup(false)}
      >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div
                  className='bg-basic-color-white w-full h-full overflow-hidden flex flex-col items-center justify-start p-4 box-border gap-[24px] min-w-[550px] max-w-full max-h-full text-left text-base text-materialize-grey-darken-3 font-mulish'>
                  <Tabs value={tabIndex()} onChange={(event, value) => handleTabIndexChange(event, value)} centered>
                    {tabs.map((tab, index) => (
                      <Tab key={index} label={tab}/>
                    ))}
                  </Tabs>
                  <div className="p-4 w-full">
                    {tabIndex() === 0 && (
                      <div className="self-stretch flex flex-col items-center justify-start py-0 px-[18px] gap-[12px]">
                        <div
                          className="w-full flex-1 flex flex-row justify-center align-middle gap-4 relative [text-decoration:underline] font-semibold Tablet_768:text-sm Tablet_600:text-smi">
                          <StaticDatePicker
                            value={currentDate()}
                            onChange={(value) => handleDateChange(value)}
                          />
                          <StaticTimePicker
                            value={currentTime()}
                            onChange={(value) => handleTimeChange(value)}
                          />
                        </div>
                        <FormControlLabel control={<Switch checked={mockTimeSwitchStatus()} onChange={(event) => {
                          setMockTimeSwitchStatus(event.target.checked)
                          if (!event.target.checked) {
                            setNow(undefined)
                          }
                        }}/>} label="Mock Time"/>
                      </div>
                    )}
                    {tabIndex() === 1 && (
                      <div className="self-stretch flex flex-col items-center justify-start py-0 px-[18px] gap-[12px]">
                        <div
                          className="w-full flex-1 flex flex-row justify-center align-middle gap-4 relative [text-decoration:underline] font-semibold Tablet_768:text-sm Tablet_600:text-smi">
                          <p>Tab 2</p>
                        </div>
                      </div>
                    )}
                    {tabIndex() === 2 && (
                      <div className="self-stretch flex flex-col items-center justify-start py-0 px-[18px] gap-[12px]">
                        <div
                          className="w-full flex-1 flex flex-row justify-center align-middle gap-4 relative [text-decoration:underline] font-semibold Tablet_768:text-sm Tablet_600:text-smi">
                          <p>Tab 3</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='w-full flex flex-row justify-end'>
                      <button className='w-12 bg-blue-600 text-white text-[16px] uppercase cursor-pointer p-2'
                              ref={makeRipple}
                              onClick={handleConfirm}>OK
                      </button>
                  </div>
              </div>
          </LocalizationProvider>
      </PortalPopup>}

    </>
  )
}
export default DateTimePickerPopup