import type { FunctionComponent } from "react";
import type { TOrder } from "@/pos/OrderType";
import _ from 'lodash';
import Notice1 from "../components/Notice1";
import Header from "./Header";
import OrderList from "./OrderList";
import ActionButtons, { type OnAcceptOrder } from "./ActionButtons";
import { OrderStatus } from "../../../../src/pos/OrderType";
import { LL0 } from "@/react/core/I18nService.tsx";
import DottedLine from "./DottedLine.tsx";
import { LL3 } from "@/react/core/I18nCurrency.tsx";

type PendingOrdersOrderDetailType = {
  order: TOrder,
  onAccept: OnAcceptOrder,
  onDecline: (reason: string) => {},
  onOpenProductMatchDialog?: Function
};

const PendingOrdersOrderDetail: FunctionComponent<PendingOrdersOrderDetailType> = ({
  order,
  onAccept,
  onDecline,
  onOpenProductMatchDialog
}) => {
  const showProgressing = (order.status === OrderStatus.REJECTING || order.status === OrderStatus.ACCEPTING)
  const payments = _.map(order.payments, pm => _.toUpper(pm.extraType))
  const isPaid = payments.length && !payments.includes('CASH')

  return (
    <div
      className="w-full rounded bg-white box-border w-[456px] flex flex-col items-start justify-start py-4 px-4 relative gap-[12px] text-left text-smi text-black font-mulish border-[0.4px] border-solid border-materialize-grey-grey">
      {showProgressing && <div
        className="absolute my-0 mx-[!important] h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-[#E761001F] box-border z-[0] border-[2px] border-solid border-chocolate-100"/>}
      {showProgressing && <Notice1/>}
      <Header {...order} />
      {order.note && <div className="self-stretch flex flex-row items-start justify-start gap-[12px] text-black">
        {order.note}
      </div>}
      <OrderList
        {...order}
        showPrice
        onOpenProductMatchDialog={(itemIdx: number) => onOpenProductMatchDialog?.(itemIdx)}
      />
      {
        <div className="self-stretch flex items-center">
          <div className="relative">{LL0().common.subtotal()}</div>
          <DottedLine/>
          <div className="relative text-xs text-right">{LL3().format.currency(order.vSubTotal || 0)}</div>
        </div>
      }
      {
        order.discount ? <div className="self-stretch flex items-center">
          <div className="relative">{LL0().common.discount()}</div>
          <DottedLine/>
          <div className="relative text-xs text-right">{LL3().format.currency(order.vDiscount || 0)}</div>
        </div> : null
      }
      {
        _.map(order.discountDetails, dd => <div className="self-stretch flex items-center" key={dd.name}>
          <div className="relative text-xs text-[#777] ml-[20px]">{dd.name}</div>
          <DottedLine/>
          <div className="relative text-xs text-right">{LL3().format.currency(dd.value || 0)}</div>
        </div>)
      }
      {
        order.taxTotal
          ? <div className="self-stretch flex items-center">
              <div className="relative">{LL0().pendingOrder.totalTax()}</div>
              <DottedLine/>
              <div className="relative text-xs text-right">{LL3().format.currency(order.taxTotal)}</div>
            </div>
          : <div className="self-stretch flex items-center">
              <div className="relative">{LL0().pendingOrder.totalTax()}</div>
              <DottedLine/>
              <div className="relative text-xs text-right">{LL3().format.currency(_.sum(_.map(order.vTaxSum, ({tax}) => tax)) + (order.vExtraTax || 0))}</div>
            </div>
      }
      {
        order.donation ? <div className="self-stretch flex items-center">
          <div className="relative">{LL0().payment.donation()}</div>
          <DottedLine/>
          <div className="relative text-xs text-right">{LL3().format.currency(order.donation)}</div>
        </div> : null
      }
      {
        order.tip ? <div className="self-stretch flex items-center">
          <div className="relative">{LL0().payment.tip()}</div>
          <DottedLine/>
          <div className="relative text-xs text-right">{LL3().format.currency(order.tip)}</div>
        </div> : null
      }
      {
        /* shipping fee */
        order.shippingData?.fee ? <div className="self-stretch flex items-center">
          <div className="relative">{LL0().payment.deliveryFee()}</div>
          <DottedLine/>
          <div className="relative text-xs text-right">{LL3().format.currency(order.shippingData?.fee || 0)}</div>
        </div> : null
      }
      {
        /* shipping fee */
        order.shippingData?.serviceFee ? <div className="self-stretch flex items-center">
          <div className="relative">{LL0().pendingOrder.deliveryServiceFee()}</div>
          <DottedLine/>
          <div className="relative text-xs text-right">{LL3().format.currency(order.shippingData?.serviceFee || 0)}</div>
        </div> : null
      }
      {
        /* serviceFee */
        order.serviceFee ? <div className="self-stretch flex items-center">
          <div className="relative">{LL0().payment.serviceFee1()}</div>
          <DottedLine/>
          <div className="relative text-xs text-right">{LL3().format.currency(order.serviceFee || 0)}</div>
        </div> : null
      }
      {
        order.bagFee ? <div className="self-stretch flex items-center">
          <div className="relative">{LL0().pendingOrder.bagFee()}</div>
          <DottedLine/>
          <div className="relative text-xs text-right">{LL3().format.currency(order.bagFee)}</div>
        </div> : null
      }
      {
        <div className="self-stretch flex items-center">
          <div className="relative">{LL0().sidebar.payment()}</div>
          <DottedLine/>
          <div className="relative text-xs text-right">{isPaid ? LL0().printing.paid() : LL0().printing.unPaid()}</div>
        </div>
      }
      <ActionButtons
        order={order}
        onDecline={onDecline}
        onAccept={onAccept}/>
    </div>
  )
};

export default PendingOrdersOrderDetail;
