import type { RxCollection } from 'rxdb'

import { Call, MissedCall } from '@/data/Call.ts'
import { CashbookHistories, CashbookTransactions } from '@/data/Cashbook.ts'
import { Category } from '@/data/Category.ts'
import { Customer } from '@/data/Customer.ts'
import { DeviceSetting } from '@/data/DeviceSetting.ts'
import { deviceSetting0 } from '@/data/DeviceSettingSignal.ts'
import { Eod } from '@/data/Eod.ts'
import { EodCache } from '@/data/EodCache.ts'
import { GroupPrinter } from '@/data/GroupPrinter.ts'
import { HappyHour } from '@/data/HappyHour.ts'
import { Image } from '@/data/Image.ts'
import { Inventory } from '@/data/Inventory.ts'
import { InventoryAction } from '@/data/InventoryAction.ts'
import { MasterAction } from '@/data/MasterAction'
import { MaxId } from '@/data/MaxId.ts'
import { Menu } from '@/data/Menu.ts'
import { Modifier } from '@/data/Modifier.ts'
import { ModifierSync } from '@/data/ModifierSync.ts'
import { OnlineOrder } from '@/data/OnlineOrder.ts'
import { OnlineOrderPrinterGroupMappingCollection } from '@/data/OnlineOrderPrinterGroupMapping.ts'
import { OnlineOrderProductMappingCollection } from '@/data/OnlineOrderProductMapping.ts'
import { OnlineProvider } from '@/data/OnlineProvider.ts'
import { OpenHour } from '@/data/OpenHour.ts'
import { Order, PaidOrder } from '@/data/Order.ts'
import { OrderCommits } from '@/data/OrderCommits'
import { OrderHandler } from '@/data/OrderHandler.ts'
import { OrderLayout } from '@/data/OrderLayout.ts'
import { OrderTseTemp } from '@/data/OrderTseTemp.ts'
import { Payment } from '@/data/Payment.ts'
import { PosSetting } from '@/data/PosSetting.ts'
import { PrintImage } from '@/data/PrintImage.ts'
import { PrintScripts } from '@/data/PrintScripts'
import { Product } from '@/data/Product.ts'
import { ProductLayout } from '@/data/ProductLayout.ts'
import { ProductSync } from '@/data/ProductSync.ts'
import { ReservationCollection } from '@/data/Reservation.ts'
import { Room, RoomObject } from '@/data/Room.ts'
import { SrmDocumentLog } from '@/data/SrmDocumentLog'
import { SrmEventLog } from '@/data/SrmEventLog'
import { SrmTransactionLog } from '@/data/SrmTransactionLog'
import { TableStaffStatus } from '@/data/TableStaffStatus'
import { TaxCategory } from '@/data/TaxCategory.ts'
import { Terminal } from '@/data/Terminal.ts'
import { TimeClock } from '@/data/TimeClock.ts'
import { TseCertificate } from '@/data/TseCertificate.ts'
import { TseConfig } from '@/data/TseConfig.ts'
import { TseTransaction } from '@/data/TseTransaction.ts'
import { TxLogCollection } from '@/data/TxLog.ts'
import { TxRefundLogCollection } from '@/data/TxRefundLog.ts'
import { TxVoidLogCollection } from '@/data/TxVoidLog.ts'
import { User } from '@/data/User.ts'
import { UserActionHistory } from '@/data/UserActionHistory.ts'
import { Voucher } from '@/data/Voucher.ts'
import { ZvtLog } from '@/data/ZvtLog.ts'

interface CollectionItem {
  collection: RxCollection
  name: string
  db: number
  menuUserCreated?: boolean
}

export const collectionsMap = (filter: 'all' | 'sync' | 'backup' = 'all'): CollectionItem[] => {
  let _filter = (item: CollectionItem) => true
  if (deviceSetting0()?.isSupportDevice && filter === 'sync') {
    _filter = (item: CollectionItem) => item.name !== 'print_image' && item.name !== 'user_action_history'
  }
  if (filter === 'backup') {
    _filter = (item: CollectionItem) => !['order_tse_temp', 'tse_transaction', 'tse_config', 'tse_certificate'].includes(item.name)
  }
  const data: CollectionItem[] = [
    { collection: Product, name: 'product', db: 1, menuUserCreated: true },
    { collection: User, name: 'user', db: 1, menuUserCreated: true },
    { collection: Order, name: 'order', db: 2, menuUserCreated: false },
    { collection: OnlineOrder, name: 'online_order', db: 2, menuUserCreated: false },
    { collection: PaidOrder, name: 'paid_order', db: 2, menuUserCreated: false },
    { collection: OrderLayout, name: 'order_layout', db: 1, menuUserCreated: true },
    { collection: ProductLayout, name: 'product_layout', db: 1, menuUserCreated: true },
    { collection: Room, name: 'room', db: 1, menuUserCreated: true },
    { collection: RoomObject, name: 'room_object', db: 1, menuUserCreated: true },
    { collection: Payment, name: 'payment', db: 1, menuUserCreated: false },
    { collection: Eod, name: 'eod', db: 2, menuUserCreated: false },
    { collection: EodCache, name: 'eod_cache', db: 2, menuUserCreated: false },
    { collection: GroupPrinter, name: 'group_printer', db: 1, menuUserCreated: false },
    { collection: Modifier, name: 'modifier', db: 1, menuUserCreated: true },
    { collection: TaxCategory, name: 'tax_category', db: 1, menuUserCreated: false },
    { collection: PosSetting, name: 'pos_setting', db: 1, menuUserCreated: true },
    { collection: PrintImage, name: 'print_image', db: 2, menuUserCreated: false },
    { collection: DeviceSetting, name: 'device_setting', db: 3, menuUserCreated: false },
    { collection: Terminal, name: 'terminal', db: 1, menuUserCreated: false },
    { collection: MaxId, name: 'max_id', db: 1, menuUserCreated: false },
    { collection: HappyHour, name: 'happy_hour', db: 1, menuUserCreated: false },
    { collection: TxLogCollection, name: 'tx_log', db: 2, menuUserCreated: false },
    { collection: TxRefundLogCollection, name: 'tx_refund_log', db: 2, menuUserCreated: false },
    { collection: TxVoidLogCollection, name: 'tx_void_log', db: 2, menuUserCreated: false },
    { collection: TseCertificate, name: 'tse_certificate', db: 1, menuUserCreated: false },
    { collection: TseConfig, name: 'tse_config', db: 1, menuUserCreated: false },
    { collection: TseTransaction, name: 'tse_transaction', db: 2, menuUserCreated: false },
    { collection: OrderTseTemp, name: 'order_tse_temp', db: 2, menuUserCreated: false },
    { collection: ReservationCollection, name: 'reservation', db: 2, menuUserCreated: false },
    { collection: Menu, name: 'menu', db: 1, menuUserCreated: false },
    { collection: ProductSync, name: 'product_sync', db: 1, menuUserCreated: true },
    { collection: Category, name: 'category', db: 1, menuUserCreated: true },
    { collection: OnlineProvider, name: 'online_provider', db: 1, menuUserCreated: false },
    { collection: Inventory, name: 'inventory', db: 1, menuUserCreated: true },
    { collection: InventoryAction, name: 'inventory_action', db: 2, menuUserCreated: false },
    { collection: Customer, name: 'customer', db: 2, menuUserCreated: false },
    { collection: Call, name: 'call', db: 2, menuUserCreated: false },
    { collection: MissedCall, name: 'missed_call', db: 2, menuUserCreated: false },
    { collection: Voucher, name: 'voucher', db: 2, menuUserCreated: false },
    { collection: SrmEventLog, name: 'srm_event_log', db: 2, menuUserCreated: false },
    { collection: SrmTransactionLog, name: 'srm_transaction_log', db: 2, menuUserCreated: false },
    { collection: SrmDocumentLog, name: 'srm_document_log', db: 2, menuUserCreated: false },
    { collection: ModifierSync, name: 'modifier_sync', db: 1, menuUserCreated: false },
    { collection: ZvtLog, name: 'zvt_log', db: 2, menuUserCreated: false },
    { collection: Image, name: 'image', db: 2, menuUserCreated: false },
    { collection: TimeClock, name: 'time_clock', db: 2, menuUserCreated: false },
    { collection: OpenHour, name: 'open_hour', db: 2, menuUserCreated: false },
    { collection: OnlineOrderPrinterGroupMappingCollection, name: 'online_order_printer_group_mapping', db: 2, menuUserCreated: false },
    { collection: OnlineOrderProductMappingCollection, name: 'online_order_product_mapping', db: 2, menuUserCreated: false },
    { collection: OrderCommits, name: 'order_commits', db: 2 },
    { collection: CashbookTransactions, name: 'cashbook_transaction', db: 2, menuUserCreated: false },
    { collection: CashbookHistories, name: 'cashbook_history', db: 2, menuUserCreated: false },
    { collection: TableStaffStatus, name: TableStaffStatus.name, db: 2, menuUserCreated: false },
    { collection: OrderHandler, name: OrderHandler.name, db: 2, menuUserCreated: false },
    { collection: UserActionHistory, name: UserActionHistory.name, db: 2, menuUserCreated: false },
    { collection: MasterAction, name: MasterAction.name, db: 2, menuUserCreated: false },
    { collection: PrintScripts, name: PrintScripts.name, db: 2, menuUserCreated: false },
  ]
  return data.filter(_filter)
}
