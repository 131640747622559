import { convertDocuments, type DocDeepSignal } from "@/data/data-utils";
import { TseCertificate } from "@/data/TseCertificate";
import { effectOn, signal, useAsyncEffect } from "@/react/core/reactive";
import { dataLock } from "@/data/DataUtils.ts";

export const [tseCertificates0, setTseCertificates0] = signal<Array<DocDeepSignal<TseCertificate>>>([]);
export const [tseCertificateV, setTseCertificateV] = signal(0);
effectOn([tseCertificateV], async () => {
  await dataLock.acquireAsync();
  const _tseCertificates = await TseCertificate.find().exec();
  const tseCertificates = convertDocuments<TseCertificate>(_tseCertificates, true);
  setTseCertificates0(tseCertificates);
});

export const makeTseCertificatesAvailable = () => {
  useAsyncEffect(async () => {
    if (tseCertificateV() === 0) {
      await dataLock.acquireAsync();
      TseCertificate.$.subscribe((change) => {
        setTseCertificateV(v => v + 1);
      })
      setTseCertificateV(1);
    }
  }, [tseCertificateV()]);
};