import {type FunctionComponent, memo} from "react";
import CardTurorial from "./CardTurorial";
import {makeRipple} from "@/react/core/ripple-utils.ts";
import {router} from "@/pos/PosRouter.ts";

import { mainScreen } from "@/data/PosSettingsSignal.ts";

const TutorialScreenPlugin: FunctionComponent = () => {
  return (
    <div className="w-full h-full flex flex-col items-start justify-start !bg-[url('/tutorial-screen--plugin@3x.png')] bg-cover bg-no-repeat bg-[top] text-left text-mini text-white-solid-white-100-ffffff font-mulish">
      <div className="self-stretch bg-black-solid-black-750-313843 h-[50px] overflow-hidden shrink-0 flex flex-row items-center justify-end py-1 px-2.5 box-border sm:h-[42px]">
        <div className="self-stretch rounded-[20px] bg-blue-solid-blue-350-2196f3 w-[72px] overflow-hidden shrink-0 flex flex-row items-center justify-center min-w-[72px] sm:h-[34px]"
             onClick={() => router.screen = mainScreen()}
             ref={makeRipple}
        >
          <div className="relative w-10 h-8">
            <img
              className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
              alt=""
              src="/iconback-icon@2x.png"
            />
          </div>
        </div>
      </div>
      <div className="self-stretch flex-1 overflow-y-auto no-scrollbar flex flex-col items-start justify-start pt-4 px-0 pb-0 gap-[30px] sm:gap-[16px]">
        <div className="self-stretch overflow-hidden shrink-0 flex flex-col items-start justify-start gap-[12px]">
          <div className="self-stretch overflow-hidden flex flex-row items-start justify-start py-[5px] px-6">
            <b className="relative sm:text-smi">Tutorial Videos:</b>
          </div>
          <div className="self-stretch flex flex-row items-center justify-center py-0 px-[9px] gap-[14px]">
            <div className="rounded-8xl bg-blue-solid-blue-480-4175fb w-[27px] h-[27px] overflow-hidden shrink-0 flex flex-row items-center justify-center">
              <div className="relative w-[7px] h-[9px]">
                <img
                  className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                  alt=""
                  src="/iconleft-arrow-icon@2x.png"
                />
              </div>
            </div>
            <div className="flex-1 overflow-x-auto no-scrollbar flex flex-row items-center justify-start gap-[10px] md:gap-[7px] sm:gap-[7px] Tablet_768:gap-[6px] Tablet_600:gap-[7px] Mobile_480:gap-[20px] height_414:gap-[6px] xl:gap-[10px]">
              <CardTurorial
                tutorialName="Video 1:"
                tutorialDescription="Basic Creating Orders."
                showIconVideo
                showTagFree={false}
                cardHeaderBackgroundImage="url('/card-header@3x.png')"
              />
              <CardTurorial
                tutorialName="Video 2:"
                tutorialDescription="Basic Payment Operation."
                showIconVideo
                showTagFree={false}
                cardHeaderBackgroundImage="url('/card-header1@3x.png')"
              />
              <CardTurorial
                tutorialName="Video 3:"
                tutorialDescription="How to Split a Bill."
                showIconVideo
                showTagFree={false}
                cardHeaderBackgroundImage="url('/card-header2@3x.png')"
              />
              <CardTurorial
                tutorialName="Video 4:"
                tutorialDescription="The Importance of “Master Machine”."
                showIconVideo
                showTagFree={false}
                cardHeaderBackgroundImage="url('/card-header3@3x.png')"
              />
              <CardTurorial
                tutorialName="Video 5"
                tutorialDescription="Benefits of Using ‘Digital Menu’."
                showIconVideo
                showTagFree={false}
                cardHeaderBackgroundImage="url('/card-header4@3x.png')"
              />
              <CardTurorial
                tutorialName="Video 6"
                tutorialDescription="Tutorial Description"
                showIconVideo
                showTagFree={false}
                cardHeaderBackgroundImage="url('/card-header5@3x.png')"
              />
              <CardTurorial
                tutorialName="Video 7:"
                tutorialDescription="Tutorial Description"
                showIconVideo
                showTagFree={false}
                cardHeaderBackgroundImage="url('/card-header6@3x.png')"
              />
              <CardTurorial
                tutorialName="Video 8:"
                tutorialDescription="Tutorial Description"
                showIconVideo
                showTagFree={false}
                cardHeaderBackgroundImage="url('/card-header7@3x.png')"
              />
              <CardTurorial
                tutorialName="Video 9:"
                tutorialDescription="Tutorial Description"
                showIconVideo
                showTagFree={false}
                cardHeaderBackgroundImage="url('/card-header8@3x.png')"
              />
              <CardTurorial
                tutorialName="Video 10:"
                tutorialDescription="Tutorial Description"
                showIconVideo
                showTagFree={false}
                cardHeaderBackgroundImage="url('/card-header9@3x.png')"
              />
            </div>
            <div className="rounded-8xl bg-blue-solid-blue-480-4175fb w-[27px] h-[27px] overflow-hidden shrink-0 flex flex-row items-center justify-center">
              <div className="relative w-[7px] h-[9px]">
                <img
                  className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                  alt=""
                  src="/iconright-arrow-icon@2x.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch relative box-border h-0.5 shrink-0 opacity-[0.8] border-t-[2px] border-dashed border-gray-solid-gray-730-737373" />
        <div className="self-stretch overflow-hidden shrink-0 flex flex-col items-start justify-start gap-[12px]">
          <div className="self-stretch overflow-hidden flex flex-row items-start justify-start py-[5px] px-6">
            <b className="relative sm:text-smi">Business Knowledge:</b>
          </div>
          <div className="self-stretch overflow-y-auto no-scrollbar flex flex-row flex-wrap items-start justify-start pt-0 px-[50px] pb-3 gap-[10px] lg:gap-[8px] md:gap-[7px] sm:gap-[7px] sm:items-center sm:justify-start sm:pl-[50px] sm:pr-[50px] sm:pb-3 sm:box-border xl:gap-[10px] Tablet_768:gap-[6px] Tablet_600:gap-[7px] Tablet_600:items-start Tablet_600:justify-start Mobile_480:gap-[20px] height_414:gap-[6px]">
            <CardTurorial
              tutorialName="Chapter 1:"
              tutorialDescription="Attracting and Conquering Customers."
              showIconVideo={false}
              showTagFree
              cardHeaderBackgroundImage="url('/card-header10@3x.png')"
            />
            <CardTurorial
              tutorialName="Chapter 2:"
              tutorialDescription="Good Service Comes From The Staff."
              showIconVideo={false}
              showTagFree
              cardHeaderBackgroundImage="url('/card-header11@3x.png')"
            />
            <CardTurorial
              tutorialName="Chapter 3:"
              tutorialDescription="Turning Leads into Real Customers."
              showIconVideo={false}
              showTagFree
              cardHeaderBackgroundImage="url('/card-header12@3x.png')"
            />
            <CardTurorial
              tutorialName="Chapter 4:"
              tutorialDescription="Revenue Breakthrough by Differentiation."
              showIconVideo={false}
              showTagFree
              cardHeaderBackgroundImage="url('/card-header13@3x.png')"
            />
            <CardTurorial
              tutorialName="Chapter 5"
              tutorialDescription="Coping With The Covid-19 Crisis."
              showIconVideo={false}
              showTagFree
              cardHeaderBackgroundImage="url('/card-header14@3x.png')"
            />
            <CardTurorial
              tutorialName="Chapter 6"
              tutorialDescription="Tutorial Description"
              showIconVideo
              showTagFree
              cardHeaderBackgroundImage="url('/card-header10@3x.png')"
            />
            <CardTurorial
              tutorialName="Chapter 7:"
              tutorialDescription="Tutorial Description"
              showIconVideo={false}
              showTagFree
              cardHeaderBackgroundImage="url('/card-header10@3x.png')"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(TutorialScreenPlugin);
