import _ from 'lodash'

import { TaxCategory } from '@/data/TaxCategory'
import { computed } from '@/react/core/reactive.ts'
import { generateSignalDataAccess } from '@/react/utils/generateSignalDataAccess'

export const [taxCategories0, makeTaxCategoriesAvailable] = generateSignalDataAccess(TaxCategory, {
  convertOpts: {
    autoSave: true,
    preSaved: taxCategory => {
      if (taxCategory.type === 'combo') {
        return {
          ...taxCategory,
          value: _.sumBy(taxCategory.components, comp => +(comp.value || 0)),
        }
      }
      return taxCategory
    },
  },
})
export const taxCategoriesVM = computed(() => {
  return taxCategories0()
})

window.taxCategories0 = taxCategories0

//used to prevent duplicating taxes in edit menu, so that this won't affect edit flow
export const dineInTaxCategories0 = computed(() => taxCategories0().filter(tax => tax.type === 'food:dineIn' || tax.type === 'drink:dineIn'))
export const takeAwayTaxCategories0 = computed(() => taxCategories0().filter(tax => tax.type === 'drink:takeAway' || tax.type === 'food:takeAway'))

export const getTotalTaxFromComponents = (components: TaxCategory['components']) => {
  return _.sumBy(components, comp => +(comp.value || 0))
}
