import type { FunctionComponent } from "react";
import { RenderRoom } from '@/react/FloorPlanView/RenderRoom.tsx';
import { activeRoom } from '@/react/EditRoomView/EditRoomView.tsx';

const EditRoomRight: FunctionComponent = () => {
  return (
    <div className="relative self-stretch flex-1 flex flex-row items-center justify-center !bg-[url('/main-content@3x.png')] bg-cover bg-no-repeat bg-[top]">
      <RenderRoom room={activeRoom()} />
    </div>
  );
};

export default EditRoomRight;
