import { Fragment, type FunctionComponent } from "react";
import { scopeOrder0 } from "@/react/PaymentView/PaymentView.tsx";
import { paymentsMap } from "@/data/PaymentHub.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import _ from "lodash";
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { getPaymentIcon } from "@/react/PaymentSettingView/PaymentMap"

const PaymentMethod: FunctionComponent = () => {

  return (
    <div className="flex flex-row items-center justify-center gap-[4px] text-right text-sm text-green-solid-green-600-00c37c font-mulish">
      {scopeOrder0().payments.map((payment, index) => {
        const paymentIcon = getPaymentIcon(paymentsMap().get(payment.type)?.icon)

        return (
          <Fragment key={index}>
            <div className="flex flex-row items-center justify-start gap-[4px]">
              <div className="relative flex justify-center items-center">
                { paymentIcon ?
                  <img
                    className="relative object-contain w-[18px] h-[18px] overflow-hidden shrink-0"
                    alt=""
                    src={paymentIcon}
                  />
                  :
                  <div className="font-bold">{_.get(LL0().payment, payment.type)?.() || payment.type}</div>
                }
              </div>
              <b className="relative">{LL3().format.currency(payment.value)}</b>
            </div>
            {index !== scopeOrder0().payments.length - 1 &&
              <div className="relative text-smi text-gray-400">+</div>}
          </Fragment>
        )
      })}
    </div>
  );
};

export default PaymentMethod;
