import { type FunctionComponent, useState } from "react";
import { TextField, Icon } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {LL0} from "@/react/core/I18nService.tsx";
import { LL2 } from "@/react/core/I18nBackend.tsx";

type InventoryDateChoosingPoType = {
  onClose?: () => void;
};

const InventoryDateChoosingPo: FunctionComponent<
  InventoryDateChoosingPoType
> = ({ onClose }) => {
  const [dateDateTimePickerValue, setDateDateTimePickerValue] = useState<
    string | null
  >(null);
  const [dateDateTimePicker1Value, setDateDateTimePicker1Value] = useState<
    string | null
  >(null);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="relative bg-white-solid-white-100-ffffff w-full overflow-hidden flex flex-col items-start justify-start pt-10 px-6 pb-6 box-border gap-[24px] min-w-[340px] h-full max-w-full max-h-full text-left text-base text-gray-solid-gray-750-696969 font-mulish Mobile_480:h-full">
        <img
          className="absolute my-0 mx-[!important] top-[12px] right-[12px] w-6 h-6 object-cover cursor-pointer z-[0]"
          alt=""
          src="/icongeneralclose-popup-icon--grey@2x.png"
          onClick={onClose}
        />
        <div className="self-stretch h-[148px] overflow-hidden shrink-0 flex flex-col items-start justify-start gap-[16px] z-[1]">
          <div className="self-stretch flex flex-row items-start justify-center pt-0 px-0 pb-4 gap-[16px] border-b-[1px] border-solid border-gray-solid-gray-300-dbdbdb">
            <div className="flex-1 h-16 flex flex-col items-start justify-center gap-[4px] Mobile_480:flex-col Mobile_480:gap-[8px] Mobile_480:items-start Mobile_480:justify-start">
              <div className="relative font-medium">{LL0().onlineOrder.from()}:</div>
              <div className="self-stretch">
                <DatePicker
                  format={LL2().dates.dateFormat()}
                  value={dateDateTimePickerValue}
                  onChange={(newValue: any) => {
                    setDateDateTimePickerValue(newValue);
                  }}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      size: "small",
                      fullWidth: true,
                      color: "primary",
                    },
                  }}
                />
              </div>
            </div>
            <div className="flex-1 h-16 flex flex-col items-start justify-center gap-[4px] Mobile_480:flex-col Mobile_480:gap-[8px] Mobile_480:items-start Mobile_480:justify-start">
              <div className="relative font-medium">{LL0().onlineOrder.to()}:</div>
              <div className="self-stretch">
                <DatePicker
                  format={LL2().dates.dateFormat()}
                  value={dateDateTimePicker1Value}
                  onChange={(newValue: any) => {
                    setDateDateTimePicker1Value(newValue);
                  }}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      size: "small",
                      fullWidth: true,
                      color: "primary",
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row flex-wrap items-center justify-center gap-[12px] text-center text-sm text-black-solid-black-1000-000000">
            <div className="flex-1 rounded-md bg-gray-solid-gray-167-e0e0e0 box-border h-[37px] flex flex-row items-center justify-center py-[9px] px-4 min-w-[98px] border-[1px] border-solid border-gray-solid-gray-167-e0e0e0">
              <div className="relative font-medium">{LL0().onlineOrder.today()}</div>
            </div>
            <div className="flex-1 rounded-md bg-gray-solid-gray-167-e0e0e0 box-border h-[37px] flex flex-row items-center justify-center py-[9px] px-4 min-w-[98px] border-[1px] border-solid border-gray-solid-gray-167-e0e0e0">
              <div className="relative font-medium">{LL0().onlineOrder.yesterday()}</div>
            </div>
            <div className="flex-1 rounded-md bg-gray-solid-gray-167-e0e0e0 box-border h-[37px] flex flex-row items-center justify-center py-[9px] px-4 min-w-[98px] border-[1px] border-solid border-gray-solid-gray-167-e0e0e0">
              <div className="relative font-medium">{LL0().onlineOrder.thisWeek()}</div>
            </div>
            <div className="flex-1 rounded-md bg-gray-solid-gray-167-e0e0e0 box-border h-[37px] flex flex-row items-center justify-center py-[9px] px-4 min-w-[98px] border-[1px] border-solid border-gray-solid-gray-167-e0e0e0">
              <div className="relative font-medium">{LL0().onlineOrder.thisMonth()}</div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-end pt-2 px-0 pb-0 z-[2] text-center text-sm text-white-solid-white-100-ffffff">
          <div className="rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8">
            <b className="relative uppercase">{LL0().ui.ok()}</b>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default InventoryDateChoosingPo;
