import { type ChangeEvent, type FormEvent, type FunctionComponent, useState } from "react";
import { LL0 } from "@/react/core/I18nService.tsx";
import TextField from '@/react/core/TextField.tsx'
import { getUrlParam } from "@/shared/utils2.ts";
import { rnHost } from "@/shared/webview/rnwebview.ts";
import { VIEW__PRODUCT } from "@/react/EditOnlineMenuView/EditOnlineMenuView.logic.ts";
import { resizeBase64AndConvertToFile } from "@/react/shared/utils.ts";
import { toast } from "react-toastify";
import { toDataUrl } from "@/react/core/utils.ts";

type PhotoPickerFormType = {
  onClose?: () => void
  onFileChange: (e: ChangeEvent<HTMLInputElement>) => void
  onUrlChange: (e: ChangeEvent<HTMLInputElement>) => void
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  file: File | null
  url: string | null
};

const PhotoPickerForm: FunctionComponent<PhotoPickerFormType> = ({
  onFileChange,
  onUrlChange,
  onClose,
  onSubmit,
  file,
  url
}) => {
  const [activeTab, setActiveTab] = useState(0)
  async function onChooseImage(e) {
    if (!getUrlParam('os')) return
    e.stopPropagation()
    e.preventDefault()
    const image = await rnHost.chooseImage()
    if (image) {
      toast.info('Uploading!')
      const file = await resizeBase64AndConvertToFile(image)
      await VIEW__PRODUCT.onUploadImage(file, 'dataUrl', await toDataUrl(file))
      toast.info('Success!')
      onClose?.()
    }
  }
  return (
    <form id="uploadImg" onSubmit={onSubmit} className="w-full flex flex-col items-end justify-start text-center text-xl text-basic-color-white font-mulish-button-name-bold">
      <div className="self-stretch flex flex-row items-start justify-start text-left text-lg text-[#2e2e48]">
        <button type="button" onClick={() => setActiveTab(0)}  className="bg-basic-color-white h-16 flex flex-row items-center justify-center py-0 px-4 box-border relative gap-[10px] sm:h-14 sm:pl-3 sm:pr-3 sm:box-border mq768:h-12 mq768:pl-2 mq768:pr-2 mq768:box-border">
          {activeTab === 0 && (
          <div className="absolute my-0 mx-[!important] h-[calc(100%_+_1px)] w-[calc(100%_+_1px)] top-[-0.5px] right-[-0.5px] bottom-[-0.5px] left-[-0.5px] bg-gray-solid-gray-400-e7e7e7-100 box-border z-[0] border-[1px] border-solid border-gray-solid-gray-400-e7e7e7-100" />
          )}
          <div className="relative font-semibold z-[1] sm:text-base mq768:text-sm">
            {LL0().editOnlineMenu.uploadAPhoto()}
          </div>
        </button>
        <button type="button" onClick={() => setActiveTab(1)} className="bg-white-solid-white-100-ffffff h-16 flex flex-row items-center justify-center py-0 px-4 box-border relative sm:h-14 sm:pl-3 sm:pr-3 sm:box-border mq768:h-12 mq768:pl-2 mq768:pr-2 mq768:box-border">
          {activeTab === 1 && (
          <div className="absolute my-0 mx-[!important] h-[calc(100%_+_1px)] w-[calc(100%_+_1px)] top-[-0.5px] right-[-0.5px] bottom-[-0.5px] left-[-0.5px] bg-gray-solid-gray-400-e7e7e7-100 box-border z-[0] border-[1px] border-solid border-gray-solid-gray-400-e7e7e7-100" />
          )}
          <div className="relative font-semibold sm:text-base mq768:text-sm">
            {LL0().editOnlineMenu.pastePhotoUrl()}
          </div>
        </button>
        <div className="bg-white-solid-white-100-ffffff h-16 flex flex-row items-center justify-center py-0 px-4 box-border relative sm:h-14 sm:pl-3 sm:pr-3 sm:box-border mq768:h-12 mq768:pl-2 mq768:pr-2 mq768:box-border">
          <div className="relative font-semibold sm:text-base mq768:text-sm">
            {LL0().editOnlineMenu.selectFromLibrary()}
          </div>
        </div>
      </div>
      {activeTab === 0 && (
        <div className="self-stretch bg-gray-solid-gray-400-e7e7e7-100 box-border h-[125px] flex flex-col items-end justify-start py-10 px-4 border-[1px] border-solid border-gray-solid-gray-400-e7e7e7-100">
          <div onClick={(e) => onChooseImage(e)} className="self-stretch flex flex-row items-center justify-start gap-[16px]">
            <label className="cursor-pointer rounded-3xs [background:linear-gradient(135deg,_#7ebcfe,_#0072eb)] shadow-[0px_3px_14px_rgba(48,_48,_48,_0.16)] shrink-0 flex flex-row items-center justify-center py-2.5 px-5 md:pt-2 md:pb-2 md:box-border sm:py-1.5 sm:px-4 sm:box-border mq768:pl-3 mq768:pr-3 mq768:box-border mq600:pl-2 mq600:pr-2 mq600:box-border">
              <b className="relative lg:text-lg md:text-base sm:text-sm mq768:text-smi mq600:text-smi">
                + {LL0().inventory.newComboItem()}
              </b>
              <input
                id="upload"
                type="file"
                className="hidden"
                accept="image/*"
                onInput={onFileChange}
              />
            </label>
            {file ? (
            <p className="text-[#2e2e48]">{file.name}</p>
            ) : (
            <div className="relative text-mini text-gray-opacity-gray-60-2e2e48-300 text-left">
              {LL0().editOnlineMenu.noFileChosen()}
            </div>
            )}
          </div>
        </div>
      )}
      {activeTab === 1 && (
        <div className="self-stretch bg-gray-solid-gray-400-e7e7e7-100 box-border h-[126px] flex flex-col items-end justify-start py-10 px-4 border-[1px] border-solid border-gray-solid-gray-400-e7e7e7-100">
          <TextField
            className="self-stretch font-mulish-button-name-bold italic font-medium text-mini text-silver"
            color="primary"
            size="small"
            fullWidth={true}
            variant="outlined"
            value={url ?? ''}
            onChange={onUrlChange}
          />
        </div>
      )}
      {activeTab === 2 && (
        <div className="self-stretch bg-gray-solid-gray-400-e7e7e7-100 box-border h-[125px] flex flex-col items-end justify-start py-10 px-4 border-[1px] border-solid border-gray-solid-gray-400-e7e7e7-100">
          <div className="self-stretch flex flex-row items-center justify-start gap-[16px]">
            <div className="rounded-3xs [background:linear-gradient(135deg,_#7ebcfe,_#0072eb)] shadow-[0px_3px_14px_rgba(48,_48,_48,_0.16)] shrink-0 flex flex-row items-center justify-center py-2.5 px-5 md:pt-2 md:pb-2 md:box-border sm:py-1.5 sm:px-4 sm:box-border mq768:pl-3 mq768:pr-3 mq768:box-border mq600:pl-2 mq600:pr-2 mq600:box-border">
              <b className="relative lg:text-lg md:text-base sm:text-sm mq768:text-smi mq600:text-smi">
                + {LL0().inventory.newComboItem()}
              </b>
            </div>
            <div className="relative text-mini text-gray-opacity-gray-60-2e2e48-300 text-left">
              {LL0().editOnlineMenu.noFileChosen()}
            </div>
          </div>
        </div>
      )}
    </form>
  );
};

export default PhotoPickerForm;
