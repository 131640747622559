import uuid from 'time-uuid'

import type { Order } from '@/data/Order'
import { OrderStatus } from '@/pos/OrderType'
import { getOsFromParam } from '@/shared/utils2.ts'

import { srmTransactionLogic } from '../transaction.logic'
import { execTestcase, getCaTaxInfo } from './libs'

type O = Readonly<Order>

const getBaseOrder = (): O => {
  const taxInfo = getCaTaxInfo()
  return {
    _id: uuid(),
    table: '001',
    status: OrderStatus.COMPLETED,
    payments: [{ type: getOsFromParam() === 'android' ? 'cash' : 'credit', value: 0 }],
    items: [
      // Complimentary Starter
      {
        name: 'Baguette with Olive Oil & Balsamic Vinegar',
        price: 0,
        quantity: 2,
        modifiers: [
          { name: 'Sliced Pears', price: 1.99, quantity: 1 },
          { name: 'Almonds', price: 0, quantity: 1 },
        ],
        ...taxInfo,
      },
      // Appetizer
      {
        name: 'French Onion Soup',
        price: 0,
        quantity: 2,
        modifiers: [
          { name: 'Gruyère', price: 1.99, quantity: 1 },
          { name: 'Emmental', price: 1.99, quantity: 1 },
        ],
        ...taxInfo,
      },

      // Main Course
      { name: 'Bouillabaisse', price: 22, quantity: 2, modifiers: [], ...taxInfo },
      // Drink
      { name: 'Bordeaux', price: 18, quantity: 2, modifiers: [{ name: 'Fruity', price: 0, quantity: 1 }], ...taxInfo },
      // Dessert
      { name: 'Crème Brûlée', price: 6.5, quantity: 1, modifiers: [], ...taxInfo },
      { name: 'Tarte Tatin', price: 8, quantity: 1, modifiers: [], ...taxInfo },
    ],
  }
}

export const testRunner031: Record<string, () => Promise<boolean>> = {
  '031.001': () => execTestcase({ num: 31, step: 1, device: 'A', run: () => srmTransactionLogic.recordClosingReceipt({ ...getBaseOrder(), table: '100' }, { print: true }) }),
  '031.002': () => execTestcase({ num: 31, step: 2, device: 'A', run: () => srmTransactionLogic.recordClosingReceipt({ ...getBaseOrder(), table: '200' }, { print: true }) }),
  '031.003': () => execTestcase({ num: 31, step: 3, device: 'A', run: () => srmTransactionLogic.recordClosingReceipt({ ...getBaseOrder(), table: '300' }, { print: true }) }),
}
