import type { PosSetting } from '@/data/PosSetting'
import type { VALID_OS } from '@/shared/utils2.ts'
import { CONSTANT_VALUES, REGISTERED_VALUES } from '@/srm/lib/constants'
import { TESTCASE_VARS } from '@/srm/testcase/constants'

export const getDemoPosSetting = (os: VALID_OS): PosSetting => ({
  _id: '5e1d89db00971c2ac29fccc9',
  useSingleOnlineMenu: false,
  menuScrollbar: false,
  generalSetting: {
    beginHour: '06:00',
    quickBtn: true,
    quickFnRows: 1,
    deliveryOrderMode: 'tablet',
    useVirtualPrinter: true,
    googleMapApiKey: 'AIzaSyBRwI87BhIHdQmkZ-Mih_A0FvBhubfQhsU',
    onlyCheckoutPrintedItems: true,
    showTutorial: true,
    isProductPriceIncludingTax: true,
    taxType: 'two',
    useTimeClock: true,
    courseDuration: 5,
    logUserActions: false,
    hasTablePlan: true,
    isDisplayPasscodeTutorial: true,
    autoLogOutWhenCloseApp: false,
    autoLogOutAfterInactivity: false,
    autoLogOutPeriod: 60 * 60 * 1000,
    decimalPointExpressed: 2,
    defaultTips: [10, 20, 30],
    serviceCharges: [10, 15],
    // useVirtualKeyboard: true,
    resetMaxIdPerDay: false,
    multipleDineInMenusEnable: false,
    mergeSimilarItem: true,
    allowMultiplePayments: true,
    hideFastCheckout: false,
    hideManualTable: false,
    showPopupPrintError:true,
  },
  cashbook: {
    incomingTypes: [
      'Barverkauf',
      'Privateinlage',
      'von Bank',
      'Wechselgeldeinzahlung',
      'Differenz (Einlage)',
      'Einnahme 1',
      'Einnahme 2',
      'Einnahme 3',
      'Einnahme 4',
      'Einnahme 5',
      'Einnahme 6',
    ],
    outgoingTypes: [
      'Bareinkauf',
      'Privatentnahme',
      'zu Bank',
      'Honorar',
      'Trinkgeld',
      'Wechselgeldauszahlug',
      'Schwund',
      'Differenz (Auszahlung)',
      'Ausgabe 1',
      'Ausgabe 2',
      'Ausgabe 3',
    ]
  },
  hardwares: [
    {
      name: ' 3',
      id: '6233fd91545014552e05a338',
    },
    {
      name: ' 1',
      id: '6248b686ade896ed3bf9b68a',
    },
    {
      name: 'QCOM-BTD (255057858)',
      id: '62b4acb8c4fe20650b4e5919',
    },
  ],
  keyboardConfig: {
    active: true,
    onlyShowInFirstPage: false,
    x: true,
    top: 6,
    left: 4,
    width: 2,
    height: 4,
    rows: [],
  },
  // call: {
  //   ipAddresses: {},
  // },
  companyInfo: {
    name: 'La Bonita Restaurant',
    address: '1079 Wellington St. W',
    address2: '',
    zipCode: 'K1Y2Y3',
    city: 'Ottawa',
    ustId: 'ustId',
    country: 'de',
    telephone: ' 613 798 9292',
    taxNumber: '0',
    taxLabel: 'St.-Nr',
    logoSize: 6,
    footerText: 'Gracias!',
    redInvoiceContent: '',
  },
  posFeature: {
    showPendingOrder: true,
    showNewDelivery: true,
    showOnlineMenu: true,
    showReservation: true,
    showCustomerInfo: true,
  },
  // keyboardDeliveryConfig: [],
  // onlineDevice: {
  //   services: {},
  //   store: {},
  // },
  // onlineOrderSoundSetting: {},
  printerGeneralSetting: {
    // entireReceipt: 0,
    // useMultiPrinterForKitchenPrinter: false,
    // useMultiPrinterForEntirePrinter: false,
    // useMultiPrinterForInvoicePrinter: true,
    printOverMasterDevice: true,
  },
  // reservation: {
  //   openHours: [],
  //   seatLimit: [],
  // },
  // signInRequest: {},
  customerLedDisplay: {
    interface: 'OFF',
    device: '',
  },
  hardwareID: '516345303a147338',
  divArticleSettings: {
    drink: {
      tax: 13,
      tax2: 13,
      groupPrinter: '5e7864b186e3ec0997b5e48e',
      groupPrinter2: undefined,
    },
    food: {
      groupPrinter: '5e7864c486e3ec0997b5e492',
      groupPrinter2: undefined,
      tax: 13,
      tax2: 13,
    },
  },
  reportSettings: {
    showProductSold: true,
    showMoreInfo: true,
  },
  reportSetting: {
    receiversEmail: 'gabriela0304@gmail.com',
    biweekly: false,
    daily: false,
    weekly: false,
    monthly: false,
  },
  srm: {
    // Deverloper values
    productId:
      os === 'ios'
        ? REGISTERED_VALUES.ios_v1.IDSEV
        : // FIXME: temporary make MacOS using the same value as Windows
        os === 'win32' || os === 'darwin'
        ? REGISTERED_VALUES.win_v1.IDSEV
        : REGISTERED_VALUES.android_v1.IDSEV,
    productVersionId:
      os === 'ios'
        ? REGISTERED_VALUES.ios_v1.IDVERSI
        : // FIXME: temporary make MacOS using the same value as Windows
        os === 'win32' || os === 'darwin'
        ? REGISTERED_VALUES.win_v1.IDVERSI
        : REGISTERED_VALUES.android_v1.IDVERSI,
    partnerId: REGISTERED_VALUES.android_v1.IDPARTN,
    certificateCode: TESTCASE_VARS.certificateCode,
    testcaseNumber: CONSTANT_VALUES.CASESSAI_EMPTY,
    version: REGISTERED_VALUES.android_v1.version,
    previousVersion: REGISTERED_VALUES.android_v1.previousVersion,

    // Mandatary values
    billingNumber: TESTCASE_VARS.billingNumber,
    identificationNumber: TESTCASE_VARS.identificationNumber,
    authCode: TESTCASE_VARS.authCode,
    // timezone: TESTCASE_VARS.timezone,
    // timezoneZ: 'N',
    qstNumber: TESTCASE_VARS.qstNumber,
    gstNumber: TESTCASE_VARS.gstNumber,
  },
})
