import { csrContent, srmCertLogic } from '../cert.logic'
import { clearTestDevices, execTestcase } from './libs'
import { testcaseLogic } from './testcase.logic'

export const testRunner001: Record<string, () => Promise<boolean>> = {
  '001.001': async () => {
    clearTestDevices()
    await testcaseLogic.configureCompanyInfo()
    await testcaseLogic.configureSrmSettings()
    return await execTestcase({
      num: 1,
      step: 1,
      device: 'A',
      run: async () => {
        csrContent.SN = 'Certificat A'
        await srmCertLogic.createCert()
      },
    })
  },
  '001.002': () => execTestcase({ num: 1, step: 2, device: 'A', run: () => srmCertLogic.replaceCert() }),
  '001.003': () => execTestcase({ num: 1, step: 3, device: 'A', run: () => srmCertLogic.deleteCert() }),
  '001.004': () => execTestcase({ num: 1, step: 4, device: 'A', run: () => srmCertLogic.createCert() }),
  '001.005': () =>
    execTestcase({
      num: 1,
      step: 5,
      device: 'B',
      run: async () => {
        csrContent.SN = 'Certificat B'
        await srmCertLogic.createCert()
      },
    }),
  '001.008': () =>
    execTestcase({
      num: 1,
      step: 8,
      device: 'C',
      run: async () => {
        csrContent.SN = 'Certificat E'
        await srmCertLogic.createCert()
      },
    }),
}
