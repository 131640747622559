import { type FunctionComponent, useMemo, type CSSProperties } from "react";

type CardTurorialType = {
  tutorialName?: string;
  tutorialDescription?: string;
  showIconVideo?: boolean;
  showTagFree?: boolean;

  /** Style props */
  cardHeaderBackgroundImage?: CSSProperties["backgroundImage"];
};

const CardTurorial: FunctionComponent<CardTurorialType> = ({
  tutorialName = "Tutorial Name:",
  tutorialDescription = "Tutorial Description",
  showIconVideo = true,
  showTagFree = true,
  cardHeaderBackgroundImage,
}) => {
  const cardHeaderStyle: CSSProperties = useMemo(() => {
    return {
      backgroundImage: cardHeaderBackgroundImage,
    };
  }, [cardHeaderBackgroundImage]);

  return (
    <div className="rounded-xl overflow-hidden flex-shrink-0 flex flex-col items-center justify-end text-center text-3xs text-black-solid-black-1000-000000 font-mulish w-44 md:w-[154px] sm:w-[162px] Tablet_600:w-[162px] height_414:w-[162px] max-lg:w-[182px] xl:w-[182px]">
      <div
        className="self-stretch h-[106px] overflow-hidden shrink-0 flex flex-row items-center justify-center relative !bg-[url('/card-header10@3x.png')] bg-cover bg-no-repeat bg-[top]"
        style={cardHeaderStyle}
      >
        {showIconVideo && (
          <img
            className="relative w-[45px] h-[45px] object-cover z-[0]"
            alt=""
            src="/icon-video@2x.png"
          />
        )}
        {showTagFree && (
          <div className="my-0 mx-[!important] absolute top-[5px] left-[10px] rounded-11xl bg-white-opacity-white-75-ffffff w-10 h-[18px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 px-[11px] box-border z-[1]">
            <div className="relative font-semibold">FREE</div>
          </div>
        )}
      </div>
      <div className="self-stretch h-[76px] overflow-hidden shrink-0 flex flex-col items-start justify-start py-1.5 px-[11px] box-border gap-[5px] !bg-[url('/card-footer@3x.png')] bg-cover bg-no-repeat bg-[top] text-left text-smi text-purple-solid-purple-150-ebcbff">
        <div className="self-stretch relative [text-decoration:underline] overflow-hidden text-ellipsis whitespace-nowrap">
          {tutorialName}
        </div>
        <div className="self-stretch relative leading-[128%] font-semibold text-white-solid-white-100-ffffff overflow-hidden text-ellipsis whitespace-nowrap">
          {tutorialDescription}
        </div>
      </div>
    </div>
  );
};

export default CardTurorial;
