import type { FunctionComponent } from "react";
import {EmailHistoryData} from "@/react/EmailReportView/EmailReportView.tsx";
import EmailReportHistoryRow from "@email-report/EmailReportHistoryRow.tsx";
import {LL0} from "@/react/core/I18nService.tsx";

type EmailReportEmailReportHiType = {
  onClose?: () => void;
};

const EmailReportEmailReportHi: FunctionComponent<
  EmailReportEmailReportHiType
> = ({ onClose }) => {
  return (
    <div className="rounded bg-white w-full h-full overflow-hidden flex flex-col items-end justify-start pt-8 px-4 pb-6 box-border relative gap-[13px] min-w-[580] text-left text-[16px] text-black-solid-black-900-1e1e23 font-mulish hover:bg-white hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[13px] hover:items-end hover:justify-start hover:rounded hover:pt-8 hover:px-4 hover:pb-6 hover:box-border hover:min-w-[580]">
      <b className="self-stretch relative z-[0]">{LL0().settings.emailReport.emailHistoryDialog.emailReportHistory()}</b>
      <div className="self-stretch flex-1 overflow-y-auto flex flex-col items-end justify-start z-[1]">
        <table className="self-stretch rounded overflow-y-auto shrink-0 border-spacing-[0] border-[2px] border-solid border-gray-solid-gray-164-e1e1e1">
          <thead>
            <tr className="bg-lightslategray">
              <th className="relative pr-0 pb-0">
                <div className="flex flex-row items-center justify-between py-3 px-2 box-border w-full h-full Tablet_768:pt-2.5 Tablet_768:pb-2.5 Tablet_768:box-border">
                  <div className="flex-1 relative text-mini font-semibold font-mulish text-white text-left Tablet_768:text-smi">
                    {LL0().settings.emailReport.emailHistoryDialog.time()}
                  </div>
                </div>
              </th>
              <th className="relative pr-0 pb-0">
                <div className="flex flex-row items-center justify-between py-3 px-2 box-border w-full h-full Tablet_768:pt-2.5 Tablet_768:pb-2.5 Tablet_768:box-border">
                  <div className="flex-1 relative text-mini font-semibold font-mulish text-white text-left Tablet_768:text-smi">
                    {LL0().settings.emailReport.emailHistoryDialog.reportType()}
                  </div>
                </div>
              </th>
              <th className="relative pr-0 pb-0">
                <div className="flex flex-row items-center justify-between py-3 px-2 box-border w-full h-full Tablet_768:pt-2.5 Tablet_768:pb-2.5 Tablet_768:box-border">
                  <div className="flex-1 relative text-mini font-semibold font-mulish text-white text-left Tablet_768:text-smi">
                    {LL0().settings.emailReport.emailHistoryDialog.email()}
                  </div>
                </div>
              </th>
              <th className="relative pb-0">
                <div className="flex flex-row items-center justify-between py-3 px-2 box-border w-full h-full Tablet_768:pt-2.5 Tablet_768:pb-2.5 Tablet_768:box-border">
                  <div className="flex-1 relative text-mini font-semibold font-mulish text-white text-left Tablet_768:text-smi">
                    {LL0().settings.emailReport.emailHistoryDialog.status()}
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
          {EmailHistoryData.map((historyEmail,index)=>(
            <EmailReportHistoryRow {...historyEmail} key={index} isEven={index}/>
          ))}
          </tbody>
        </table>
      </div>
      <img
        className="absolute my-0 mx-[!important] top-[12px] right-[12px] w-[21px] h-[21px] object-cover cursor-pointer z-[2]"
        alt=""
        src="/iconclose-popup-icon@2x.png"
        onClick={onClose}
      />
    </div>
  );
};

export default EmailReportEmailReportHi;
