import { Fragment, type FunctionComponent } from 'react';
import EndOfDayCalendarItem from '@eod/EndOfDayCalendarItem';
import { currentMonth, dateMap, matrix0, onChangeMonth } from '@/react/EndOfDayView/EndOfDayView.tsx';
import {LL0} from "@/react/core/I18nService.tsx";

const EndOfDayLeft: FunctionComponent = () => {

  const month = () => {
    if (currentMonth().format('MMMM') === "January") return LL0().dateTime.monthFull._1()
    if (currentMonth().format('MMMM') === "February") return LL0().dateTime.monthFull._2()
    if (currentMonth().format('MMMM') === "March") return LL0().dateTime.monthFull._3()
    if (currentMonth().format('MMMM') === "April") return LL0().dateTime.monthFull._4()
    if (currentMonth().format('MMMM') === "May") return LL0().dateTime.monthFull._5()
    if (currentMonth().format('MMMM') === "June") return LL0().dateTime.monthFull._6()
    if (currentMonth().format('MMMM') === "July") return LL0().dateTime.monthFull._7()
    if (currentMonth().format('MMMM') === "August") return LL0().dateTime.monthFull._8()
    if (currentMonth().format('MMMM') === "September") return LL0().dateTime.monthFull._9()
    if (currentMonth().format('MMMM') === "October") return LL0().dateTime.monthFull._10()
    if (currentMonth().format('MMMM') === "November") return LL0().dateTime.monthFull._11()
    if (currentMonth().format('MMMM') === "December") return LL0().dateTime.monthFull._12()
  }

  return (
    <div className="self-stretch flex-1 flex flex-col items-center justify-start p-5 gap-[20px] !bg-[url('/end-of-day-left@3x.png')] bg-cover bg-no-repeat bg-[top] text-center text-xl text-basic-color-white font-mulish md:gap-[16px] sm:gap-[14px] Tablet_768:gap-[12px] Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border Tablet_600:gap-[10px] Tablet_600:pl-3 Tablet_600:pr-3 Tablet_600:box-border">
      <div className="self-stretch flex flex-row items-center justify-center gap-[24px]">
        <div className="relative w-6 h-6 hover:[filter:invert(0.5)_sepia(1)_saturate(5)_hue-rotate(175deg)] sm:w-5 sm:h-5" onClick={() => onChangeMonth('PREV')}>
          <img className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover" alt="" src="/iconleft-arrow-icon@2x.png" />
        </div>
        <b className="relative leading-[17px] inline-block min-w-[152px] lg:text-xl lg:leading-[17px] md:text-lg sm:text-base">{`${month()} ${currentMonth().format('YYYY')}`}</b>
        <div className="relative w-6 h-6 hover:[filter:invert(0.5)_sepia(1)_saturate(5)_hue-rotate(175deg)] sm:w-5 sm:h-5" onClick={() => onChangeMonth('NEXT')}>
          <img className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover" alt="" src="/iconright-arrow-icon@2x.png" />
        </div>
      </div>
      <div className="self-stretch flex-1 rounded overflow-hidden flex flex-col items-start justify-start gap-[1px] h-full text-mini text-darkslategray-200">
        <div className="self-stretch relative rounded-t rounded-b-none h-[31px] overflow-hidden shrink-0 grid grid-cols-[repeat(7,1fr)] [grid-row-gap:0px] [grid-column-gap:0px]">
          <div className="bg-whitesmoke-200 h-[31px] flex flex-row items-center justify-start relative text-darkred">
            <div className="flex-1 relative leading-[17px] inline-block h-[15px] min-w-[44px] md:text-sm sm:text-smi Tablet_768:text-xs">{LL0().settings.happyHour.daysOfWeekAbbreviated.Sunday()}</div>
          </div>
          <div className="bg-whitesmoke-200 h-[31px] flex flex-row items-center justify-start relative">
            <div className="flex-1 relative leading-[17px] inline-block h-[15px] min-w-[44px] md:text-sm sm:text-smi Tablet_768:text-xs">{LL0().settings.happyHour.daysOfWeekAbbreviated.Monday()}</div>
          </div>
          <div className="bg-whitesmoke-200 h-[31px] flex flex-row items-center justify-start relative">
            <div className="flex-1 relative leading-[17px] inline-block h-[15px] min-w-[44px] md:text-sm sm:text-smi Tablet_768:text-xs">{LL0().settings.happyHour.daysOfWeekAbbreviated.Tuesday()}</div>
          </div>
          <div className="bg-whitesmoke-200 h-[31px] flex flex-row items-center justify-start relative">
            <div className="flex-1 relative leading-[17px] inline-block h-[15px] min-w-[44px] md:text-sm sm:text-smi Tablet_768:text-xs">{LL0().settings.happyHour.daysOfWeekAbbreviated.Wednesday()}</div>
          </div>
          <div className="bg-whitesmoke-200 h-[31px] flex flex-row items-center justify-start relative">
            <div className="flex-1 relative leading-[17px] inline-block h-[15px] min-w-[44px] md:text-sm sm:text-smi Tablet_768:text-xs">{LL0().settings.happyHour.daysOfWeekAbbreviated.Thursday()}</div>
          </div>
          <div className="bg-whitesmoke-200 h-[31px] flex flex-row items-center justify-start relative">
            <div className="flex-1 relative leading-[17px] inline-block h-[15px] min-w-[44px] md:text-sm sm:text-smi Tablet_768:text-xs">{LL0().settings.happyHour.daysOfWeekAbbreviated.Friday()}</div>
          </div>
          <div className="bg-whitesmoke-200 h-[31px] flex flex-row items-center justify-start relative">
            <div className="flex-1 relative leading-[17px] inline-block h-[15px] min-w-[44px] md:text-sm sm:text-smi Tablet_768:text-xs">{LL0().settings.happyHour.daysOfWeekAbbreviated.Saturday()}</div>
          </div>
        </div>
        <div className="self-stretch flex-1 relative h-full grid grid-rows-[repeat(5,1fr)] grid-cols-[repeat(7,1fr)] [grid-row-gap:1px] [grid-column-gap:1px]">
          {matrix0()?.map((row, y) => (
            <Fragment key={y}>
              {row?.map((item, x) => (
                <EndOfDayCalendarItem iso={item.iso} type={item.type} defaultDateColor={x % 7 === 0 ? '#990011' : '#364150'} key={`${x}/${y}`} />
              ))}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EndOfDayLeft;
