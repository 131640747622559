import { type FunctionComponent, useState } from "react";
import { makeRipple } from '@/react/core/ripple-utils.ts';
import {LL0} from "@/react/core/I18nService.tsx";
import { printInvoice } from '@/react/Printer/print-invoice.ts'
import { order0, scopeOrder0 } from '@/react/PaymentView/PaymentView.tsx'
import { InvoiceTypes } from '@/pos/OrderType.ts'

export type PaymentVerticalPrintButtoType = {
  onClose?: () => void;
  isExpanded?: boolean;
};

const PaymentVerticalPrintButto: FunctionComponent<
  PaymentVerticalPrintButtoType
> = ({ onClose }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <>
      {
        isExpanded &&
        <div className="opacity-0 fixed top-0 left-0 w-full h-full z-[2]" onClick={() => setIsExpanded(false)} />
      }
    <div className="flex flex-col items-start justify-start relative text-center text-mini text-basic-color-white font-mulish-button-name-bold self-stretch">
      <div className="flex-1 rounded-81xl [background:linear-gradient(170.9deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#005fed] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] overflow-hidden flex flex-row items-center justify-center py-0 px-3.5 gap-[0px_8px] z-[0] Mobile_480:h-9 mq320:h-8"
           ref={makeRipple}
           onClick={() => setIsExpanded(prev => !prev)}
      >
        <img
          className="w-[30px] relative h-[30px] object-cover Mobile_480:w-[26px] Mobile_480:h-[26px] mq320:w-6 mq320:h-6"
          alt=""
          src="/icon-vertical-receipt-icon@2x.png"
        />
        <b className="relative Mobile_480:text-smi mq320:text-xs">{LL0().ui.print()}</b>
      </div>
      {isExpanded && (
        <div className="!z-[3] !w-[178px] !max-h-none max-w-none my-0 mx-[!important] absolute top-[42px] left-[0px] flex flex-col items-center justify-start py-1 px-0.5 box-border max-w-[none] max-h-[none] overflow-auto z-[1] text-base text-black-solid-black-900-1e1e23">
          <div className="self-stretch flex flex-row items-center justify-start py-0 pr-0 pl-[45px] Mobile_480:pl-[42px] Mobile_480:box-border mq320:pl-10 mq320:box-border">
            <img
              className="w-[13px] relative h-[11.3px]"
              alt=""
              src="/icon-vertical-polygon1.svg"
            />
          </div>
          <div className="rounded-sm bg-black-opacity-black-30-000000-200 shadow-[1px_0px_4px_#525f71,_10px_-10px_14px_rgba(149,_149,_149,_0.1)_inset,_-10px_10px_14px_rgba(255,_255,_255,_0.1)_inset] [backdrop-filter:blur(40px)] overflow-hidden flex flex-col items-center justify-start p-3 gap-[16px_0px]">
            <div className="self-stretch rounded-81xl [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[42px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 px-4 box-border gap-[0px_8px] Mobile_480:h-9 mq320:h-8"
                 ref={makeRipple}
                 onClick={async () => {
                   setIsExpanded(false);
                   await printInvoice(scopeOrder0(), InvoiceTypes.RED_INVOICE);
                 }}
            >
              <img
                className="w-[30px] relative h-[30px] object-cover Mobile_480:w-[26px] Mobile_480:h-[26px] mq320:w-6 mq320:h-6"
                alt=""
                src="/icon-vertical-redbill-icon-12@2x.png"
              />
              <b className="flex-1 relative leading-[17px] Mobile_480:text-mini mq320:text-sm">
                {LL0().fnBtn.paymentFunctions.redBill()}
              </b>
            </div>
            <div className="self-stretch rounded-81xl [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[42px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 px-4 box-border gap-[0px_8px] Mobile_480:h-9 mq320:h-8"
                 ref={makeRipple}
                 onClick={async () => {
                   setIsExpanded(false);
                   await printInvoice(scopeOrder0());
                 }} >
              <img
                className="w-[30px] relative h-[30px] object-cover Mobile_480:w-[26px] Mobile_480:h-[26px] mq320:w-6 mq320:h-6"
                alt=""
                src="/iconpaidbill-icon1@2x.png"
              />
              <b className="flex-1 relative leading-[17px] Mobile_480:text-mini mq320:text-sm">
                {LL0().order.invoiceTypes.bill()}
              </b>
            </div>
            <div className="self-stretch rounded-81xl [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[42px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 px-4 box-border gap-[0px_8px] Mobile_480:h-9 mq320:h-8"
                 ref={makeRipple}
                 onClick={async () => {
                   setIsExpanded(false);
                   await printInvoice(scopeOrder0(), InvoiceTypes.GUEST_CHECK);
                 }}
            >
              <img
                className="w-[30px] relative h-[30px] object-cover Mobile_480:w-[26px] Mobile_480:h-[26px] mq320:w-6 mq320:h-6"
                alt=""
                src="/iconverticalunpaidbill-icon@2x.png"
              />
              <b className="relative leading-[17px] Mobile_480:text-mini mq320:text-sm">
                {LL0().order.invoiceTypes.guestCheck()}
              </b>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default PaymentVerticalPrintButto;
