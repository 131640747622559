import type { CSSProperties } from "react";

export const styleSelected = (selected: boolean, selectedColor: string = "#3487e7"): { style: CSSProperties } => {
	return { style: { borderColor: selected ? selectedColor : "#C9CBCC" } };
};

export const styleVisible = (visible: boolean): { style: CSSProperties } => {
	return { style: { "display": visible ? "flex" : "none" } };
};

export const twVisible = (visible: boolean) => {
	return visible ? "flex" : "hidden";
};
