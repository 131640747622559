import type { FunctionComponent } from "react";
import { LL0 } from "@/react/core/I18nService.tsx";
import { useSignal, useComputed } from "@/react/core/reactive.ts";
import { inputController0 } from "@/react/core/Input.tsx";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { products0 } from "@/data/ProductHub.ts"
import _ from "lodash";
import Autocomplete from "@/react/core/Autocomplete.tsx";
import {getServiceDisplayText, findProductFromDisplayText} from "@/data/ReservationHub.ts";

const SERVICES_WILL_BE_LISTED_WITHOUT_SEARCH = 15;

export type SearchServicesPopupType = {
  className?: string;
  displayText?: string;
  onClose?: () => void;
  onConfirm?: (product: any) => void;
};

const SearchServicesPopup: FunctionComponent<SearchServicesPopupType> = ({
  className = "",
  displayText = "",
  onClose,
  onConfirm
}) => {
  const [searchService, setSearchService] = useSignal<string>(displayText)
  const [productDisplayText, setProductDisplayText] = useSignal<string>(displayText)
  const filteredProducts = useComputed(() => {
    return (
      searchService()
        ? products0().filter(p => p.name?.includes(searchService()))
        : _.take(products0(), SERVICES_WILL_BE_LISTED_WITHOUT_SEARCH)
    )
  })
  const filteredProductNames = useComputed(() => _.uniq(filteredProducts().map(getServiceDisplayText)));
  const selectedProduct = useComputed(() => findProductFromDisplayText(productDisplayText()))

  function autoCompleteProps() {
    return {
      id: "combo-box-service",
      freeSolo: true,
      className: "flex-1",
      value: productDisplayText(),
      filterOptions: (opts: any) => opts,
      onChange: (e: any, v: any) => {
        setSearchService('')
        setProductDisplayText(v || '')
      },
      options: filteredProductNames(),
      inputValue: searchService() || productDisplayText(),
      inputProps: {
        label: 'Services',
        value: searchService(),
        onChange: (value: any) => {
          setSearchService(value || '')
          setProductDisplayText(value || '')
        },
        className: "w-full font-mulish text-sm text-gray-100"
      },
      renderOption: ({style, className, ...restProps}, option, state) => {
        return (
          <li className="self-stretch box-border h-10 flex flex-row items-center justify-between py-1 px-2 relative border-b-[1px] border-solid border-gray-solid-gray-164-e1e1e1"
              key={option}
              {...restProps}
              onClick={(e) => {
                setProductDisplayText(option)
                restProps.onClick?.(e);
              }}
          >
            { state.index % 2 === 0 &&
              <div className="!flex w-full absolute my-0 mx-[!important] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] bg-[#ddd] hidden z-[0]" />
            }
            <div className="text-left flex-1 relative text-black-solid-black-880-1d1d26 z-[1]">
              <span>{option}</span>
            </div>
          </li>
        )
      }
    }
  }

  return (
    <>
      <div
        className={`w-[600px] h-full relative bg-white-solid-white-100-ffffff flex flex-col items-start justify-start pt-8 px-6 pb-6 box-border gap-[24px] max-w-full max-h-full overflow-auto text-left text-sm text-black-solid-black-900-1e1e23 font-mulish ${className}`}
      >
        <div className="min-h-[180px] self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start gap-[11px] z-[0]">
          <b className="self-stretch relative text-base text-blue-solid-blue-500-0051c1">
            {LL0().reservation.chooseService()}:
          </b>
          <div className="self-stretch flex flex-row items-start justify-start gap-[2px]">
            <Autocomplete {...autoCompleteProps()}/>
          </div>
        </div>
        <div
          className="self-stretch flex flex-row items-center justify-end z-[1] text-center text-white-solid-white-100-ffffff">
          <div
            className="self-stretch rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8 cursor-pointer"
            ref={makeRipple}
            onClick={() => {
              onConfirm?.(selectedProduct())
              onClose?.()
            }}
          >
            <b className="relative">{LL0().ui.ok()}</b>
          </div>
        </div>
        <img
          className="w-6 absolute !m-[0] top-[10px] right-[12px] h-6 object-cover cursor-pointer z-[2]"
          alt=""
          src="/icongeneralclose-popup-icon--grey@2x.png"
          onClick={onClose}
        />
        <TableManualKeyboard
          inputController={inputController0}
          onEnter={() => {
            onConfirm?.(selectedProduct())
            onClose?.()
          }}
        />
      </div>
    </>
  );
};

export default SearchServicesPopup;
