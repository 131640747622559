import type { FunctionComponent } from 'react';

type EndOfDayTabsType = {
  id?: string;
  startTime?: string;
  endTime?: string;
  showSelected?: boolean;
  onClick?: () => void;
};

const EndOfDayTabs: FunctionComponent<EndOfDayTabsType> = ({
  id = "Z: 382",
  startTime = "02:45 PM",
  endTime = "02:35 PM",
  showSelected = true,
  onClick,
}) => {
  // const isSelected = useSelectorRaw<number>(currentZ);
  // showSelected = isSelected(2)
  return (
    <div className="bg-lightgray flex flex-col items-center justify-center pt-3 px-2 pb-2 relative gap-[4px] text-right text-xl text-darkslategray-200 font-mulish" onClick={onClick}>
      {showSelected && (
        <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-basic-color-white box-border z-[0] border-t-[6px] border-solid border-royalblue-200" />
      )}
      <b className="relative z-[1] md:text-lg sm:text-base Tablet_768:text-sm Tablet_600:text-xs">
        {id}
      </b>
      <div className="flex flex-row items-center justify-center gap-[2px] z-[2] text-center text-5xs text-darkgray">
        <b className="relative">{startTime}</b>
        <b className="relative">-</b>
        <b className="relative">{endTime}</b>
      </div>
    </div>
  );
};

export default EndOfDayTabs;
