import { router } from '@/pos/PosRouter.ts';
import { mutable, signal } from '@/react/core/reactive.ts';
import { makePaymentsAvailable, payments0, setPaymentV } from '@/data/PaymentHub.ts';
import { makeTaxCategoriesAvailable, taxCategories0 } from '@/data/TaxCategoryHub.ts';
import { type SubTax, TaxCategory } from '@/data/TaxCategory.ts';
import { Payment } from '@/data/Payment.ts';
import { clone } from 'json-fn';
import uuid from 'time-uuid';
import TaxAndPaymentSettingPlugin from '@tax-payment/TaxAndPaymentSettingPlugin.tsx';
import type { DocDeepSignal } from '@/data/data-utils.ts';
import { Terminal, TerminalTypes } from '@/data/Terminal.ts';
import { makeTerminalsAvailable, setTerminalV } from '@/data/TerminalHub.ts';
import _ from 'lodash';
import type { InputController } from '@/react/core/Input.tsx';
import { memo } from 'react';
import defaultTaxesByCity from "./defaultTaxesByCity.json"
import { generalSetting0, mainScreen } from "@/data/PosSettingsSignal.ts";

export const initTaxCategory: TaxCategory = { name: '', value: 0, type: 'food:dineIn' }
export const initSubTax: SubTax = { name: "", printLabel: "", value: 0, note: "F"}
export const [taxCategory0, setTaxCategory0] = signal<TaxCategory | undefined>()

// @ts-expect-error debug only
window.taxCategory0 = taxCategory0

export const initPaymentMethod: Omit<Payment, "_id"> = {
  name: "",
  terminalIds: [],
}
export const [payment0, setPayment0] = signal<Payment | undefined>()

export const initTerminal: Omit<Terminal, "_id"> = {
  name: "",
  useAsDefaultForPosDevices: [],
  type: TerminalTypes.Clover,
  metadata: {
    terminalId: '',
    posId: '',
    merchantId: '',
    clientId: '',
    timeoutInSeconds: 1000
  }
}
export const [terminal0, setTerminal0] = signal<Terminal | undefined>()

// Tax Category handlers

export const taxCategoryTypes = [
  "food:dineIn",
  "food:takeAway",
  "drink:dineIn",
  "drink:takeAway",
  "HST",
  "combo"]

export const convertCityNameToCountryCity = (option: string)=> {
  const [country, city] = option.split("-");
  return {
    country, city
  }
}

export const convertCountryCityToCityName = (option?: {
  country: string;
  city: string;
} | null) =>  `${option?.country}-${option?.city}`

export const onAutofillTaxByRegion = async (cityName: keyof typeof defaultTaxesByCity) => {
  const newTaxCategories = defaultTaxesByCity[cityName] as TaxCategory[]
  const {country} = convertCityNameToCountryCity(cityName)
  if (country === "Germany") {
    //clear all existing taxes
    _.assign(generalSetting0(), {
      isProductPriceIncludingTax: true,
      taxType: "two"
    })
  } else if (country === "Canada") {
    _.assign(generalSetting0(), {
      isProductPriceIncludingTax: false,
      taxType: "one"
    })
  }

  if (newTaxCategories) {
    await Promise.all(taxCategories0().map(taxCategory => taxCategory?.doc?.incrementalRemove()))
    await TaxCategory.bulkInsert(newTaxCategories.map((newTax) => ({...newTax, _id: uuid()})));
  }
}

export const createTaxCategory = async (taxCategory: TaxCategory) => {
  const _taxCategory : DocDeepSignal<TaxCategory>= mutable({
    ...taxCategory,
    _id: uuid()
  })
  _taxCategory.doc = await TaxCategory.insert(clone(_taxCategory));
  return _taxCategory
}

export const deleteTaxCategory = async (taxCategory: DocDeepSignal<TaxCategory>) => {
  await taxCategory?.doc?.incrementalRemove();
}

export const deleteTaxComponent = (index: number) => {
  taxCategory0()?.components?.splice(index, 1);
}

// Payment type handlers

export const createPaymentMethod = async (payment: Payment) => {
  if (payment.enableTip === undefined) {
    _.assign(payment, {enableTip: true})
  }
  const _payment : DocDeepSignal<Payment>= mutable({
    ...payment,
    _id: uuid()
  })
  _payment.doc = await Payment.insert(clone(_payment));
  setPaymentV(v => v + 1);
  return _payment;
}

export const deletePaymentMethod = async (payment: DocDeepSignal<Payment>) => {
  await payment?.doc?.incrementalRemove();
  //refresh payment methods list
  setPaymentV(v => v + 1);
}

// Terminal handlers

export const createTerminal = async (terminal: Terminal) => {
  const _terminal: DocDeepSignal<Terminal> = { ...terminal, _id: uuid() };
  _terminal.doc = await Terminal.insert(clone(_terminal));
  setTerminalV(v => v + 1);
  return _terminal;
}

export const updateTerminal = async (terminal: Terminal) => {
  const change = clone(_.omit(terminal, ['doc']));
  console.log('updateTerminal', change);
  await Terminal.upsert(change);
  setTerminalV(v => v + 1);
}


export const deleteTerminal = async (terminal: DocDeepSignal<Terminal>) => {
  payments0().forEach(payment => {
    if (!payment.terminalIds?.find(terminalId => terminalId === terminal._id)) {
      return;
    }
    payment.terminalIds = payment.terminalIds
      .filter(terminalId => terminalId !== terminal._id);
  })
  await terminal?.doc?.incrementalRemove();
  setTerminalV(v => v + 1);
}

// Tip & Service charge handlers

export const addDefaultTip = (defaultTip: number) => {
  const oldDefaultTips = generalSetting0()?.defaultTips || [];
  _.assign(generalSetting0(), { defaultTips: [ ...oldDefaultTips, defaultTip] });
}

export const deleteDefaultTip = (defaultTipIndex: number) => {
  const oldDefaultTips = generalSetting0()?.defaultTips || [];
  oldDefaultTips.splice(defaultTipIndex, 1)
  _.assign(generalSetting0(), { defaultTips: oldDefaultTips});
}

export const addServiceCharge = (serviceCharge: number) => {
  const oldServiceCharges = generalSetting0()?.serviceCharges || [];
  _.assign(generalSetting0(), { serviceCharges: [ ...oldServiceCharges, serviceCharge] });
}

export const deleteServiceCharge = (serviceChargeIndex: number) => {
  const oldServiceCharges = generalSetting0()?.serviceCharges || [];
  oldServiceCharges.splice(serviceChargeIndex, 1)
  _.assign(generalSetting0(), { serviceCharges: oldServiceCharges });
}

export const onBack = () => {
  router.screen = mainScreen()
}

export const [inputControllers, setInputControllers] = signal<Array<InputController>>()

const PaymentSettingView = () => {
  makePaymentsAvailable();
  makeTaxCategoriesAvailable();
  makeTerminalsAvailable();

  return <TaxAndPaymentSettingPlugin />
}

export default memo(PaymentSettingView)


