import { CheckCircle as CheckCircleIcon, Error as ErrorIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch, Tooltip } from '@mui/material'
import _ from 'lodash'
import { Fragment, type FC } from 'react'

import { LL0 } from '@/react/core/I18nService'
import { signal, signalSyncedWithLocalStorage } from '@/react/core/reactive'
import { testcaseLogic } from '@/srm/testcase/testcase.logic'

type Steps = keyof typeof testcaseLogic
interface StepState {
  selected: boolean
  status?: 'working' | 'success' | 'error'
  error?: string
}
const defaultStates: Record<Steps, StepState> = {
  clearAllSrmData: { selected: true },
  clearDemoUsers: { selected: true },
  configureCompanyInfo: { selected: true },
  configureSrmSettings: { selected: true },
  enrollCertificate: { selected: true },
  createTestUsers: { selected: true },
  syncTestUsersWithSrm: { selected: true },
  syncAdminUserWithSrm: { selected: false },
}
const [states, setStates] = signalSyncedWithLocalStorage('SRM_SETUP_STATES', defaultStates)
const steps = Object.keys(defaultStates) as Steps[]

const [open, setOpen] = signal(false)
const [working, setWorking] = signal(false)

function handleToggleStep(step: Steps, val: boolean) {
  const state = states()[step]
  state.selected = val
  setStates(pre => ({ ...pre, [step]: state }))
}
function handleDialogClose() {
  if (working()) return
  setOpen(false)
}
function handleReset() {
  setStates(_.cloneDeep(defaultStates))
}
async function handleOk() {
  setWorking(true)
  try {
    for (const step of steps) {
      const state = states()[step]
      if (!state.selected) continue
      state.status = 'working'
      try {
        console.log('Starting...', step, state)
        const fn = testcaseLogic[step]
        if (!fn) throw new Error(`Method not implemented: ${step}`)
        await fn()
        // after the step is executed, unselect it
        state.selected = false
        state.status = 'success'
      } catch (e) {
        state.status = 'error'
        state.error = e instanceof Error ? e.message : JSON.stringify(e)
      } finally {
        setStates(pre => ({ ...pre, [step]: state }))
      }
    }
    // Close dialog if no errors
    if (!Object.values(states).some(s => (s as StepState).error)) setOpen(false)
  } finally {
    setWorking(false)
  }
}
export function showSrmSetupDialog() {
  setOpen(true)
}

const SrmSetupDialog: FC = () => (
  <Dialog
    open={open()}
    onClose={handleDialogClose}
  >
    <DialogTitle>SRM Setup</DialogTitle>
    <DialogContent className="grid grid-cols-[1fr_32px] gap-2 items-center">
      {steps.map(step => {
        const state: StepState | undefined = states()[step as Steps]
        return (
          <Fragment key={step}>
            <FormControlLabel
              control={
                <Switch
                  checked={!!state?.selected}
                  onChange={(_e, val) => handleToggleStep(step, val)}
                  disabled={working()}
                />
              }
              label={LL0().srm.testcaseSetupStep[step]()}
            />
            {state?.status === 'working' ? (
              <CircularProgress size={16} />
            ) : state?.status === 'success' ? (
              <CheckCircleIcon color="success" />
            ) : state?.status === 'error' ? (
              <Tooltip title={state?.error}>
                <ErrorIcon color="error" />
              </Tooltip>
            ) : (
              <p></p>
            )}
          </Fragment>
        )
      })}
    </DialogContent>
    <DialogActions>
      <Button
        onClick={handleDialogClose}
        disabled={working()}
      >
        {LL0().ui.close()}
      </Button>
      <Button
        onClick={handleReset}
        disabled={working()}
      >
        {LL0().ui.reset()}
      </Button>
      <LoadingButton
        onClick={handleOk}
        variant="contained"
        loading={working()}
      >
        {LL0().ui.ok()}
      </LoadingButton>
    </DialogActions>
  </Dialog>
)

export default SrmSetupDialog
