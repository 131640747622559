import axios from 'axios'
import debug from 'debug'

import { signal, signalSyncedWithLocalStorage } from '@/react/core/reactive'
import msgBox, { Buttons, Icons } from '@/react/SystemService/msgBox'
import { bound, busyIndicator, showErrorToast } from '@/shared/decorators'

import { base64 } from '../base64'
import { getOsFromParam } from '../utils2'
import { rnHost } from '../webview/rnwebview'

const [serialDevices, setSerialDevices] = signalSyncedWithLocalStorage<string[]>('serialDevices', [])
const [connectedSerialDevice, setConnectedSerialDevice] = signalSyncedWithLocalStorage('connectedSerialDevice', '')
const [connectedSerialBaudRate, setConnectedSerialBaudRate] = signalSyncedWithLocalStorage('connectedSerialBaudRate', '')

const [selectedSerialDevice, setSelectedSerialDevice] = signal(connectedSerialDevice() || '')
const [selectedBaudRate, setSelectedBaudRate] = signal(connectedSerialBaudRate() || '9600')

const [fetchingSerialDevices, setFetchingSerialDevices] = signal(false)
const [connectingSerialPort, setConnectingSerialPort] = signal(false)

export {
  serialDevices,
  selectedSerialDevice,
  selectedBaudRate,
  fetchingSerialDevices,
  connectingSerialPort,
  setSelectedSerialDevice,
  setSelectedBaudRate,
  connectedSerialDevice,
  connectedSerialBaudRate,
}

const log = debug('data:serial')

const MANAGER_SERVER_BASE_URL = 'http://localhost:5005'
const api = {
  async getSerialDevices() {
    if (getOsFromParam() === 'android') {
      const res = await axios.get(`${MANAGER_SERVER_BASE_URL}/get-serial-ports-paths`)
      return res.data
    } else {
      return await rnHost.findSerialPortPrinter()
    }
  },
  async connectSerialPortPrinter(payload: { path: string; baudrate: number }) {
    if (getOsFromParam() === 'android') {
      await axios.post(`${MANAGER_SERVER_BASE_URL}/connect-serial-port-printer`, payload)
    }
  },
  async printToSerialPort(payload: { data: string }) {
    if (getOsFromParam() === 'android') {
      await axios.post(`${MANAGER_SERVER_BASE_URL}/print-to-serial-port`, payload)
    } else {
      await rnHost.printCom(payload.data, connectedSerialDevice(), Number(connectedSerialBaudRate()))
    }
  },
}

class SerialDeviceManager {
  @showErrorToast()
  @busyIndicator(setFetchingSerialDevices)
  @bound()
  async getSerialDevices(silent = false): Promise<string[]> {
    try {
      const paths = await api.getSerialDevices()
      log('Serial devices:', { paths })
      // await sleep(3000) // Only testing
      setSerialDevices(paths)
      return paths
    } catch (e) {
      setSerialDevices([])
      if (!silent) throw new Error('Failed to get serial devices', { cause: e })
      return []
    }
  }

  @bound()
  async connectToSelectedSerialDevice() {
    const path = selectedSerialDevice()
    const baudrate = Number(selectedBaudRate())
    await this.connectSerialPortPrinter(path, baudrate)
    msgBox.show('Connected', `Connected to ${path} at ${baudrate} baudrate`, Buttons.OK, Icons.Success)
  }

  @showErrorToast(device => `Failed to connect to serial device ${device}`)
  @busyIndicator(setConnectingSerialPort)
  @bound()
  async connectSerialPortPrinter(path: string, baudrate: number) {
    if (!path) throw new Error('No serial device selected')
    if (!baudrate) throw new Error('No baudrate selected')

    log(`⚡️ Connecting to serial port ${path} at ${baudrate} baudrate...`)
    await api.connectSerialPortPrinter({ path, baudrate })
    // await sleep(3000) // Only testing
    setConnectedSerialDevice(path)
    setConnectedSerialBaudRate(`${baudrate}`)
    log(`✅ Connected to serial port ${path} at ${baudrate} baudrate`)
  }

  async printToSerialPort(data: string) {
    const encoded = base64.encodeString(data)
    log('Printing to serial port:', { data, encoded })
    await api.printToSerialPort({ data: encoded })
  }
}

export const serialDeviceManager = new SerialDeviceManager()
