import { record } from 'rrweb';
import { getDeviceId } from '@/shared/getDeviceId'
import dayjs from "dayjs";
import { getVDateDayjs } from "@/pos/orderUtils.ts";
import { Buffer } from "buffer";
import uuid from "time-uuid";
import pako from "pako";
import { captureException } from "@sentry/react";
import SurrealClient from '@/shared/SurrealClient.ts'
import {
  type FirstDomSnapshot,
  iconClassRegex,
  iconMap,
  isStop,
  patch2,
  screenClassRegex,
  screensMap,
  setIsStop,
  setVRecord,
  syncitApi,
  vRecord
} from "@/pos/logic/rrweb-share.ts";
import debug from "debug";
//@ts-ignore
import DiffMatchPatch from 'diff-match-patch';
import { RecordId } from "surrealdb.js";
import { database, database2, database3 } from "@/data/data-utils.ts";
import { dataLock } from "@/data/DataUtils.ts";
import { posSync0, posSyncLock } from "@/data/PosSyncState.ts";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";

const dmp = new DiffMatchPatch();
let _id = uuid()
let idManage = ''
let replayId: { _id: string; date: number; id: string }[] = []
let saveReplayInterval: NodeJS.Timeout | undefined = undefined
let saveManageInterval: NodeJS.Timeout | undefined = undefined
const IDLE_TIMEOUT = 20; //IDLE_TIMEOUT * 3 = seconds
let _idleSecondsCounter = 0;
const log = debug('rrweb:record')

async function getDb() {
  return await SurrealClient.getSurrealClient('cloud');
}

export const onRecord = async () => {
  if (!isStop() || syncitApi()) return
  await posSyncLock.acquireAsync()
  setIsStop(false)
  console.log('start recording')
  try {
    let arrayEvents: any[] = []
    const stopRecording = record({
      sampling: {
        mousemove: false,
        mouseInteraction: true,
        input: 'last',
      },
      emit(event) {
        if (isStop()) {
          stopRecording?.()
        }
        arrayEvents.push(event)
      },
    })
    document.onpointerdown = function () {
      _idleSecondsCounter = 0;
    };
    saveReplayInterval = setInterval(async () => {
      if (isStop()) {
        clearInterval(saveReplayInterval)
        clearInterval(saveManageInterval)
        return
      }
      _idleSecondsCounter++;
      if (_idleSecondsCounter >= IDLE_TIMEOUT) {
        if (arrayEvents.length > 10) {
          await dataLock.acquireAsync();
          await Promise.all([
            database.v?.requestIdlePromise(),
            database2.v?.requestIdlePromise(),
            database3.v?.requestIdlePromise(),
          ])
          _idleSecondsCounter = 0;

          const _events = arrayEvents
          arrayEvents = []
          handleSaveReplay(_events).finally(() => {
            log(`save after 1 minute ${_id}`)
            console.log('save after 1 minute ', _id)
          })
        } else {
          _idleSecondsCounter = 0;
        }
      }
    }, 3000);
    saveManageInterval = setInterval(() => {
      if (isStop()) {
        clearInterval(saveManageInterval)
        return
      }
      handleSaveManage().then(r => {
        arrayEvents = []
        clearInterval(saveReplayInterval)
        clearInterval(saveManageInterval)
        log(`save manage and reset _id`)
        console.log('save manage and reset _id...')
      })
    }, 86400000) //86400000
  } catch (e: any) {
    console.log(e)
  }
}

async function retrySave(_data: string) {
  const db = await getDb();
  if (!db) {
    console.log('!db -> return')
    log(`Cannot login surrealDB`)
    captureException(new Error('Cannot login surrealDB'))
    return
  }
  const date = dayjs().unix()
  const vDate = getVDateDayjs(dayjs()).unix()
  const [_create] = await db.insert('Replay', {
    id: uuid(),
    _id: _id,
    date: date,
    data: _data,
    storeId: posSync0()?.id || 1,
    version: import.meta.env.VITE_APP_VERSION,
    name: deviceSetting0()?.name,
    deviceId: getDeviceId()
  })
  log(`create Replay ${_create?.id?.id}`)
  if (_create) {
    const _replay = {
      _id: _id,
      date: date,
      id: (_create.id.tb + ':' + _create.id.id),
    }
    replayId.push(_replay)
    if (!idManage) {
      const [_manage] = await db.insert('ManageReplay', {
        id: _id,
        storeId: posSync0()?.id || 1,
        deviceId: getDeviceId(),
        name: deviceSetting0()?.name,
        vDate: vDate,
        date: date,
        replays: replayId
      })
      log(`create ManageReplay ${_manage?.id?.id}`)
      idManage = uuid()
    }
  }
}


async function handleEvents(events: any[]): Promise<any[]> {
  const eventsWrapper: {
    convertType: 'patch' | 'patch2' | 'none',
    data: string,
    patchId?: RecordId<'FirstDomSnapshot'>,
    protocolVersion: number,
    screen?: string
  }[] = [];
  for (const event of events) {

    const eventStr = JSON.stringify(event);
    const isHaveScreen = eventStr.match(screenClassRegex());
    const isHaveIcon = eventStr.match(iconClassRegex());

    const isEventBig = eventStr.length > 1000;
    if (event.type === 2) {
      const firstSnapshot = await getFirstSnapshot();
      let originStr = eventStr
      const randomId = new RecordId('FirstDomSnapshot', uuid())
      let id = randomId
      if (firstSnapshot) {
        originStr = firstSnapshot.data;
        id = firstSnapshot.id;
      } else {
        await createFirstSnapshot(eventStr, id)
      }
      const patches = dmp.patch_make(originStr, eventStr);
      let data = JSON.stringify(patches)
      if (data.length > 10000) {
        id = randomId
        await createFirstSnapshot(eventStr, id)
        await updateFirstDomSnapshot()
        data = '[]'
      }
      eventsWrapper.push({
        convertType: 'patch',
        data: data,
        patchId: id,
        protocolVersion: 2
      });
    } else if ((isHaveScreen || isHaveIcon) && isEventBig) {
      const screen = screensMap.find(screen => screen.class === isHaveScreen?.toString() || '');
      const icon = iconMap.find(screen => screen.class === isHaveIcon?.toString() || '');
      if (screen) {
        let originStr = eventStr
        const randomId = new RecordId('FirstDomSnapshot', uuid())
        let id = randomId
        const firstScreenSnapshot = await getFirstSnapshot(screen.screenName);
        if (firstScreenSnapshot) {
          originStr = firstScreenSnapshot.data;
          id = firstScreenSnapshot.id;
        } else {
          await createFirstSnapshot(eventStr, id, screen.screenName, event.type)
        }
        const patches = dmp.patch_make(originStr, eventStr);
        let data = JSON.stringify(patches)
        if (data.length > 10000) {
          id = randomId
          await createFirstSnapshot(eventStr, id, screen.screenName, event.type)
          await updateFirstDomSnapshot()
          data = '[]'
        }

        eventsWrapper.push({
          convertType: 'patch',
          data: data,
          protocolVersion: 2,
          patchId: id,
          screen: screen.screenName,
        });
      } else if (icon) {
        let originStr = eventStr
        const randomId = new RecordId('FirstDomSnapshot', uuid())
        let id = randomId
        const firstScreenSnapshot = await getFirstSnapshot(icon.screenName);
        if (firstScreenSnapshot) {
          originStr = firstScreenSnapshot.data;
          id = firstScreenSnapshot.id;
        } else {
          await createFirstSnapshot(eventStr, id, icon.screenName, event.type)
        }
        const diffs2 = patch2(originStr, event);
        if (diffs2.length < 3000) {
          eventsWrapper.push({
            convertType: 'patch2',
            data: diffs2,
            protocolVersion: 2,
            patchId: id,
            screen: icon.screenName,
          });
        } else {
          eventsWrapper.push({
            convertType: 'none',
            data: eventStr,
            protocolVersion: 2
          })
        }

      } else {
        eventsWrapper.push({
          convertType: 'none',
          data: eventStr,
          protocolVersion: 2
        })
        captureException(new Error('Cannot find screen from RegExMatch'))
      }
    } else {
      eventsWrapper.push({
        convertType: 'none',
        data: eventStr,
        protocolVersion: 2
      })
    }
  }
  return eventsWrapper;
}

async function handleSaveReplay(events: any[]) {
  const db = await getDb();
  if (!db) return;
  const eventsWrapper = await handleEvents(events)
  const body = JSON.stringify(eventsWrapper)
  const _data = pako.deflate(body)
  const data = Buffer.from(_data).toString('base64')

  try {
    await retrySave(data)
  } catch (e) {
    let retries = 0;
    const retryInterval = setInterval(async () => {
      if (retries < 1000) {
        retries++;
        try {
          await retrySave(data);
          clearInterval(retryInterval);
        } catch (retryError) {
        }
      } else {
        captureException(new Error('Cannot save replay, cancel retry ' + e));
        clearInterval(retryInterval);
        setIsStop(true);
      }
    }, 25000) //25000
  }
}

function saveManage() {
  replayId = []
  _id = uuid()
  idManage = ''
}


async function handleSaveManage() {
  saveManage()
  setIsStop(true)
  console.log('stop recording')
  setVRecord((vRecord() || 0) + 1)
}

async function createFirstSnapshot(str: string, id: RecordId<'FirstDomSnapshot'>, screen?: string, eventType?: number) {
  const db = await getDb();
  if (!db) {
    log(`create FirstDomSnapshot failed, cannot connect to db`, { alert: true })
    return
  }
  const vDate = getVDateDayjs(dayjs()).unix()
  const store = posSync0()?.id || -1;
  const _data = pako.deflate(str)
  const data = Buffer.from(_data).toString('base64')
  if (screen) {
    const _firstScreenSnapshot = {
      storeId: store,
      id: id,
      data: data,
      screen: screen,
      eventType: eventType,
      date: vDate,
      outDate: false,
    };
    const [result] = await db.insert(`FirstDomSnapshot`, _firstScreenSnapshot)
    localStorage.setItem(`First${screen}Snapshot`, JSON.stringify(_firstScreenSnapshot));
    log(`create FirstDomSnapshot for screen ${screen} ${result?.id?.id}`)
  } else {
    const _firstDomSnapshot = {
      storeId: store,
      id: id,
      data: data,
      eventType: 2,
      date: vDate,
      outDate: false,
    };
    const [result] = await db.insert(`FirstDomSnapshot`, _firstDomSnapshot)
    localStorage.setItem(`FirstDomSnapshot`, JSON.stringify(_firstDomSnapshot));
    log(`create FirstDomSnapshot ${result?.id?.id}`)
  }
}

async function getFirstSnapshot(screen?: string): Promise<FirstDomSnapshot | undefined> {
  try {
    const db = await getDb();
    if (!db) return;
    const storeId = posSync0()?.id || -1;
    log(`start getFirstSnapshot for screen ${screen || 'no-screen'}...`)
    let firstDomSnapshot: FirstDomSnapshot | undefined
    let queryCheck: string
    let query: string
    let key: string

    if (screen) {
      key = `First${screen}Snapshot`
      queryCheck = `SELECT id FROM FirstDomSnapshot WHERE storeId = ${storeId} AND screen = '${screen}' AND eventType != 2 AND outDate = false;`
      query = `SELECT * FROM FirstDomSnapshot WHERE storeId = ${storeId} AND screen = '${screen}' AND eventType != 2 AND outDate = false;`
    } else {
      key = 'FirstDomSnapshot'
      queryCheck = `SELECT id FROM FirstDomSnapshot WHERE storeId = ${storeId} AND eventType = 2 AND outDate = false;`
      query = `SELECT * FROM FirstDomSnapshot WHERE storeId = ${storeId}  AND eventType = 2 AND outDate = false;`
    }
    const [[firstDomSnapshotCheck]] = await db.query<[FirstDomSnapshot[]]>(queryCheck);
    const localSnapshot = localStorage.getItem(key)
    if (!!localSnapshot) {
      firstDomSnapshot = JSON.parse(localSnapshot as any);
    }
    if (!firstDomSnapshotCheck || !firstDomSnapshot || firstDomSnapshotCheck?.id?.toString() !== firstDomSnapshot?.id?.toString()) {
      firstDomSnapshot = undefined;
    }
    if (!firstDomSnapshot) {
      ([[firstDomSnapshot]] = await db.query<[FirstDomSnapshot[]]>(query));
      localStorage.setItem(key, JSON.stringify(firstDomSnapshot));
    }
    if (!firstDomSnapshot || !firstDomSnapshot.data) return
    const data = Buffer.from(firstDomSnapshot.data, 'base64');
    firstDomSnapshot.data = pako.inflate(data as any, { to: 'string' });
    return firstDomSnapshot
  } catch (e) {
    log(`getFirstSnapshot error for screen ${screen || 'no-screen'}... Error is: ${e.message}`)
    captureException(new Error('getFirstSnapshot error' + e))
    return
  }
}

async function updateFirstDomSnapshot(screen?: string) {
  const db = await getDb()
  if (!db || !posSync0()?.id) return
  try {
    if (screen) {
      await db.query(`UPDATE FirstDomSnapshot SET outDate = true WHERE storeId = ${posSync0().id} AND screen = '${screen}';`)
      localStorage.removeItem(`First${screen}Snapshot`)
      return
    }
    await db.query(`UPDATE FirstDomSnapshot SET outDate = true WHERE storeId = ${posSync0().id};`)
    localStorage.removeItem('FirstDomSnapshot')
  } catch (e) {
    captureException(new Error('Error when reset FirstDomSnapshot: ' + e))
    return
  }
}

//@ts-ignore
window.onRecord = onRecord
