import { type FunctionComponent, useCallback, useState } from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, } from "@mui/material";
import PrinterTemplatePopup from "@printer-setting/PrinterTemplatePopup";
import PortalPopup from "@printer-setting/PortalPopup";
import { LL0 } from "@/react/core/I18nService.tsx";
import { currentPrinter, PRINTER_TYPES, usbPrinterDevices } from '@/react/Printer/PrinterSettingView.tsx';
import _ from 'lodash';
import TextField from '@/react/core/TextField.tsx';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { toast } from "react-toastify";
import { type BluetoothPrinterData, rnHost } from "@/shared/webview/rnwebview.ts";
import { demoCutPaper, testPrintLabelIP, testPrintLabelUSB } from "@/react/Printer/print-label.ts";

const LabelPrinterSettings: FunctionComponent = () => {
  const [isPrinterTemplatePopupOpen, setPrinterTemplatePopupOpen] =
    useState(false);

  const openPrinterTemplatePopup = useCallback(() => {
    setPrinterTemplatePopupOpen(true);
  }, []);

  const closePrinterTemplatePopup = useCallback(() => {
    setPrinterTemplatePopupOpen(false);
  }, []);
  const [bluetoothDevices, setBluetoothDevices] = useState<BluetoothPrinterData[]>([])
  return (
    <>
      <div className="!flex !h-full !text-[#1d1d26] self-stretch h-[567px] hidden flex-col items-start justify-start gap-[16px] z-[3] text-left text-sm text-gray-400 font-mulish">
        <div className="relative text-3xl font-semibold Tablet_768:text-xl Tablet_600:text-lg">
          {LL0().settings.labelPrinters.title()}
        </div>
        <div className="no-scrollbar self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start gap-[12px_0px]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
            <b className="relative">{LL0().printerSetting.terminalPrinter()}</b>
            <div className="self-stretch gap-[8px] grid grid-cols-[repeat(auto-fit,140px)] [grid-row-gap:8px] [grid-column-gap:8px] text-mini">
              {PRINTER_TYPES.filter(({ label }) =>
                label === 'Bluetooth' || label === 'Network Printer' || label === 'USB'
              )
                .map(({ value, label }, index) => (
                  <div
                    className="rounded-10xs bg-whitesmoke-100 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-10 flex flex-col items-center justify-center relative gap-[8px] border-[0.5px] border-solid border-darkgray Tablet_768:h-9"
                    key={index}
                    onPointerDown={() => _.assign(currentPrinter(), { printerType: value })}
                  >
                    {currentPrinter()?.printerType === value && (
                      <div className="!flex absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />
                    )}
                    <div className="relative z-[1]">{label}</div>
                  </div>
                ))}
              <div
                className="rounded-10xs bg-whitesmoke-100 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-10 flex flex-col items-center justify-center relative gap-[8px] border-[0.5px] border-solid border-darkgray Tablet_768:h-9"
                onPointerDown={() => delete currentPrinter()?.printerType}
              >

                <div className="relative z-[1]">{LL0().ui.reset()}</div>
              </div>
            </div>
            {currentPrinter()?.printerType === "bluetooth" &&
              <div className="self-stretch flex flex-row items-start justify-start gap-[0px_8px] text-center text-basic-color-white">
                <div className="rounded-10xs bg-blue-500 h-[37px] flex flex-row items-center justify-center py-0 px-5 box-border"
                     ref={makeRipple}
                     onClick={async () => {
                       const t = toast("Scanning Bluetooth Devices..., please wait !!!", {
                         autoClose: false,
                         theme: "dark",
                         progress: 100,
                       })
                       const result = (await rnHost.scanDevices())?.paired || [];
                       console.log("scanDevices", result);
                       setBluetoothDevices(result);
                       toast.done(t);
                     }}
                >
                  <div className="relative font-semibold">{LL0().printerSetting.scan()}</div>
                </div>
                <FormControl className="self-stretch flex-1" variant="outlined">
                  <InputLabel color="primary" />
                  <Select color="primary" size="small"
                          value={currentPrinter()?.address || ''}
                          onChange={(e) => _.assign(currentPrinter(), { address: e.target.value })}
                  >
                    {
                      ((currentPrinter()?.address && !bluetoothDevices.length) ? [{
                        address: currentPrinter()?.address?.split("/")[0],
                        name: currentPrinter()?.address?.split("/")[1],
                      }] : bluetoothDevices).map(({ address, name }, index) => (
                        <MenuItem value={`${address}/${name || 'unpair'}`} key={index}>{address} - {name || 'unpair'}</MenuItem>
                      ))
                    }
                  </Select>
                  <FormHelperText />
                </FormControl>
                <div className="rounded-10xs bg-blue-500 h-[37px] flex flex-row items-center justify-center py-0 px-3 box-border"
                     ref={makeRipple}
                     onClick={async () => {
                       await demoCutPaper()
                     }}>
                  <div className="relative font-semibold">{LL0().printerSetting.testPrinter()}</div>
                </div>
              </div>
            }
            {currentPrinter()?.printerType === "usb" &&
              <div className="self-stretch flex flex-row items-start justify-start gap-[8px] text-center text-basic-color-white">
                <div className="rounded-10xs bg-blue-500 h-[37px] flex flex-row items-center justify-center py-0 px-5 box-border"
                     ref={makeRipple}

                >
                  <div className="relative font-semibold">{LL0().printerSetting.scan()}</div>
                </div>
                <FormControl
                  className="self-stretch flex-1 font-mulish text-sm text-black-solid-black-880-1d1d26"
                  variant="outlined"
                >
                  <InputLabel color="primary" />
                  <Select color="primary" size="small"
                          sx={{
                            borderRadius: "0px 0px 0px 0px",
                            height: "37px",
                            m: 0,
                            "& .MuiInputBase-root": {
                              m: 0,
                              minHeight: "37px",
                              justifyContent: "center",
                              display: "inline-flex",
                            },
                            "& .MuiInputBase-input": {
                              textAlign: "left",
                            },
                          }}
                          value={currentPrinter()?.usb || ''}
                          onChange={(e) => _.assign(currentPrinter(), {usb: e.target.value})}
                  >
                    {
                      ((currentPrinter()?.usb && !usbPrinterDevices().length) ? [currentPrinter()?.usb]: usbPrinterDevices()).map((path, index) => (
                        <MenuItem value={path} key={index}>{path}</MenuItem>
                      ))
                    }
                  </Select>
                  <FormHelperText />
                </FormControl>
                <div className="rounded-10xs bg-blue-500 h-[37px] flex flex-row items-center justify-center py-0 px-3 box-border"
                     ref={makeRipple}
                     onClick={async () => {
                       await testPrintLabelUSB()
                       toast.success('success')
                     }}
                >
                  <div className="relative font-semibold">{LL0().printerSetting.testPrinter()}</div>
                </div>
              </div>
            }
            {currentPrinter()?.printerType === "ip" &&
              <div className="self-stretch flex flex-row items-start justify-start items-end gap-[0px_8px] text-center text-basic-color-white">
                <TextField
                  className="bg-[transparent] flex-1 font-mulish text-sm text-black-solid-black-880-1d1d26"
                  color="primary"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  value={currentPrinter()?.ip || ""}
                  onChange={e => _.assign(currentPrinter(), { ip: e.target.value })}
                  label="IP"
                />
                <div className="rounded-10xs bg-blue-500 h-[37px] flex flex-row items-center justify-center py-0 px-3 box-border"
                     ref={makeRipple}
                     onClick={async () => {
                       await testPrintLabelIP()
                       toast.success('success')
                     }}
                >
                  <div className="relative font-semibold">{LL0().printerSetting.testPrinter()}</div>
                </div>
              </div>
            }
          </div>
          {/*<div className="self-stretch flex flex-row items-start justify-start gap-[0px_40px]">*/}
          {/*  <FormControlLabel*/}
          {/*    label="TSC POS"*/}
          {/*    control={<Switch color="primary"*/}
          {/*                     checked={currentPrinter()?.tscPOS || false}*/}
          {/*                     onChange={e => _.assign(currentPrinter(), {tscPOS: e.target.checked})}*/}
          {/*    />}*/}
          {/*  />*/}
          {/*  <FormControlLabel*/}
          {/*    label="ESC POS"*/}
          {/*    control={<Switch color="primary"*/}
          {/*                     checked={currentPrinter()?.escPOS || false}*/}
          {/*                     onChange={e => _.assign(currentPrinter(), {escPOS: e.target.checked})} />}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px_0px]">
            <div className="flex flex-row items-center justify-start gap-[0px_8px]">
              <b className="relative">{LL0().settings.labelPrinters.tscPOSLayoutSetting()}</b>
              <img
                className="w-6 relative h-6 object-cover cursor-pointer"
                alt=""
                src="/iconquestion-icon@2x.png"
                onClick={openPrinterTemplatePopup}
              />
            </div>
            <div className="self-stretch gap-[8px_16px] grid grid-cols-[repeat(2,1fr)] [grid-row-gap:8px] [grid-column-gap:16px] Tablet_768:grid Tablet_768:grid-cols-[repeat(1,1fr)] Tablet_768:[grid-row-gap:8px] Tablet_768:[grid-column-gap:16px]">
              <div className="flex flex-col items-start justify-start gap-[4px_0px] relative">
                <div className="relative">{LL0().settings.labelPrinters.paperWidth()}(mm)</div>
                <TextField
                  className="self-stretch"
                  color="primary"
                  size="small"
                  fullWidth={true}
                  type={"number"}
                  variant="outlined"
                  onChange={(e) => _.assign(currentPrinter(), {canvasWidth: e.target.value})}
                  value={currentPrinter()?.canvasWidth||0}
                />
              </div>
              <div className="flex flex-col items-start justify-start gap-[4px_0px] relative">
                <div className="relative">{LL0().settings.labelPrinters.fontSize1Or2()}</div>
                <TextField
                  className="self-stretch"
                  color="primary"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  value={""}
                />
              </div>
              <div className="flex flex-col items-start justify-start gap-[4px_0px] relative">
                <div className="relative">Paper height (mm)</div>
                <TextField
                  className="self-stretch"
                  color="primary"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  type={'number'}
                  value={currentPrinter()?.canvasHeight || 50}
                  onChange={(e) => _.assign(currentPrinter(), {canvasHeight: e.target.value})}
                />
              </div>
              <div className="flex flex-col items-start justify-start gap-[4px_0px] relative">
                <div className="relative">{LL0().settings.labelPrinters.lineHeight()}(mm)</div>
                <TextField
                  className="self-stretch"
                  color="primary"
                  size="small"
                  type={"number"}
                  fullWidth={true}
                  variant="outlined"
                  onChange={(e) => _.assign(currentPrinter(), {lineHeight: e.target.value})}
                  value={currentPrinter()?.lineHeight||0}
                />
              </div>
              <div className="flex flex-col items-start justify-start gap-[4px_0px] relative">
                <div className="relative">{LL0().settings.labelPrinters.modifierPadding()} (mm)</div>
                <TextField
                  className="self-stretch"
                  color="primary"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  value={""}
                />
              </div>
              <div className="flex flex-col items-start justify-start gap-[4px_0px] relative">
                <div className="relative">{LL0().settings.labelPrinters.leftPadding()} (mm)</div>
                <TextField
                  className="self-stretch"
                  color="primary"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  type={"number"}
                  onChange={(e) => _.assign(currentPrinter(), {leftPadding: e.target.value})}
                  value={currentPrinter()?.leftPadding||0}
                />
              </div>
              <div className="flex flex-col items-start justify-start gap-[4px_0px] relative">
                <div className="relative">{LL0().settings.labelPrinters.topPadding()} (mm)</div>
                <TextField
                  className="self-stretch"
                  color="primary"
                  size="small"
                  type={"number"}
                  fullWidth={true}
                  variant="outlined"
                  onChange={(e) => _.assign(currentPrinter(), {marginTop: e.target.value})}
                  value={currentPrinter()?.marginTop || 0}
                />
              </div>
              <div className="flex flex-col items-start justify-start gap-[4px_0px] relative">
                <div className="relative">
                  Gap (mm)
                </div>
                <TextField
                  className="self-stretch"
                  color="primary"
                  size="small"
                  type={"number"}
                  fullWidth={true}
                  variant="outlined"
                  onChange={(e) => _.assign(currentPrinter(), {gap: e.target.value})}
                  value={currentPrinter()?.gap || 0}
                />
              </div>
            </div>
          </div>
        </div>
        {/*<div className="self-stretch flex flex-row items-center justify-end gap-[0px_12px] text-center text-basic-color-white">*/}
        {/*  <div className="rounded-10xs bg-text-color-blue-1271ff h-[37px] flex flex-row items-center justify-center py-0 px-3 box-border"*/}
        {/*       ref={makeRipple}*/}
        {/*       onClick={demoPrintTsc}*/}
        {/*  >*/}
        {/*    <div className="relative font-semibold">{LL0().settings.labelPrinters.test()}</div>*/}
        {/*  </div>*/}
        {/*  <div className="rounded-10xs bg-text-color-blue-1271ff h-[37px] flex flex-row items-center justify-center py-0 px-4 box-border"*/}
        {/*       ref={makeRipple}*/}
        {/*  >*/}
        {/*    <div className="relative font-semibold">{LL0().onlineOrder.save()}</div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      {isPrinterTemplatePopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closePrinterTemplatePopup}
        >
          <PrinterTemplatePopup onClose={closePrinterTemplatePopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default LabelPrinterSettings;