import uuid from 'time-uuid'

import type { Order } from '@/data/Order'
import { OrderStatus } from '@/pos/OrderType'

import { srmTransactionLogic } from '../transaction.logic'
import { execTestcase, getCaTaxInfo } from './libs'

type O = Readonly<Order>

const getBaseOrder = (): O => {
  const taxInfo = getCaTaxInfo()
  return {
    _id: uuid(),
    status: OrderStatus.COMPLETED,
    payments: [{ type: 'cash', value: 0 }],
    items: [
      // Main Course
      { name: 'Bouillabaisse', price: 22, quantity: 2, modifiers: [{ name: 'Jelly', price: 0, quantity: 1 }], ...taxInfo },
      // Drink
      { name: 'Bordeaux', price: 18, quantity: 2, modifiers: [], ...taxInfo },
      // Dessert
      { name: 'Crème Brûlée', price: 6.5, quantity: 1, modifiers: [], ...taxInfo },
      { name: 'Tarte Tatin', price: 8, quantity: 1, modifiers: [], ...taxInfo },
      // Free
      { name: 'Bouillabaisse Free', price: 0, quantity: 2, modifiers: [{ name: 'Topping', price: 8, quantity: 1 }], ...taxInfo },
    ],
  }
}

export const testRunner030: Record<string, () => Promise<boolean>> = {
  '030.001': () => execTestcase({ num: 30, step: 1, device: 'A', run: () => srmTransactionLogic.recordClosingReceipt(getBaseOrder(), { print: true }) }),
  '030.002': () => execTestcase({ num: 30, step: 2, device: 'A', run: () => srmTransactionLogic.recordClosingReceipt(getBaseOrder(), { print: true }) }),
  '030.003': () => execTestcase({ num: 30, step: 3, device: 'A', run: () => srmTransactionLogic.recordClosingReceipt(getBaseOrder(), { print: true }) }),
}
