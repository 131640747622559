import uuid from 'time-uuid'

import type { Order } from '@/data/Order'
import { OrderStatus } from '@/pos/OrderType'
import { signalSyncedWithLocalStorage } from '@/react/core/reactive'

import { srmTransactionLogic } from '../transaction.logic'
import { execTestcase, getCaTaxInfo } from './libs'

type O = Readonly<Order>

const [order03, setOrder03] = signalSyncedWithLocalStorage<O | null>('TESTCASE_004_ORDER_03', null)
const [order09, setOrder09] = signalSyncedWithLocalStorage<O | null>('TESTCASE_004_ORDER_09', null)

const getBaseOrder = (): O => {
  const taxInfo = getCaTaxInfo()
  const order: O = {
    _id: uuid(),
    table: '1',
    status: OrderStatus.IN_PROGRESS,
    payments: [],
    items: [
      { name: 'Samosa', price: 20, quantity: 2, modifiers: [], ...taxInfo },
      { name: 'Garlic Bread', price: 5, quantity: 3, modifiers: [], ...taxInfo },
    ],
  }
  return order
}

export const testRunner004: Record<string, () => Promise<boolean>> = {
  '004.001': () =>
    execTestcase({
      num: 4,
      step: 1,
      device: 'A',
      run: async () => {
        const order = getBaseOrder()
        await srmTransactionLogic.recordCancellation(order)
      },
    }),
  '004.002': () =>
    execTestcase({
      num: 4,
      step: 2,
      device: 'A',
      run: async () => {
        const order: O = { ...getBaseOrder(), _id: uuid(), table: undefined }
        await srmTransactionLogic.recordCancellation({ ...order, items: [] }, { makingPayment: true })
      },
    }),
  '004.003': () =>
    execTestcase({
      num: 4,
      step: 3,
      device: 'A',
      run: async () => {
        const order: O = { ...getBaseOrder(), _id: uuid(), table: '3' }
        await srmTransactionLogic.recordTemporaryBill(order, { print: true })
        setOrder03(order)
      },
    }),
  '004.004': () =>
    execTestcase({
      num: 4,
      step: 4,
      device: 'A',
      run: async () => {
        const o = order03()
        if (!o) throw new Error('Please run step 3 first')
        await srmTransactionLogic.recordCancellation(
          {
            ...o,
            status: OrderStatus.CANCELLED_BEFORE_PAID,
            directCancellationItems: o.items,
            items: [],
          },
          { makingPayment: true }
        )
      },
    }),
  '004.007': () =>
    execTestcase({
      num: 4,
      step: 7,
      device: 'C',
      run: async () => {
        const order: O = { ...getBaseOrder(), _id: uuid(), table: undefined }
        await srmTransactionLogic.recordCancellation(order)
      },
    }),
  '004.008': () =>
    execTestcase({
      num: 4,
      step: 8,
      device: 'C',
      run: async () => {
        const order: O = { ...getBaseOrder(), _id: uuid(), table: undefined }
        await srmTransactionLogic.recordCancellation({ ...order, items: [] }, { makingPayment: true })
      },
    }),
  '004.009': () =>
    execTestcase({
      num: 4,
      step: 9,
      device: 'C',
      run: async () => {
        const order: O = { ...getBaseOrder(), _id: uuid(), table: undefined }
        await srmTransactionLogic.recordTemporaryBill(order, { print: true })
        setOrder09(order)
      },
    }),
  '004.010': () =>
    execTestcase({
      num: 4,
      step: 10,
      device: 'C',
      run: async () => {
        const o = order09()
        if (!o) throw new Error('Please run step 9 first')
        await srmTransactionLogic.recordCancellation({
          ...o,
          status: OrderStatus.CANCELLED_BEFORE_PAID,
          cancellationItems: o.items,
          items: [],
        })
      },
    }),
}
