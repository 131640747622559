import { type CSSProperties, type FunctionComponent, useMemo } from "react";
import { ColorDarken } from "@/react/core/ColorDarken.tsx";
import { setCategoryIndex } from "@/react/OrderView/OrderView.tsx";

import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";

type OrdersButtonCategoryType = {
  categoryText?: string;
  index: number

  /** Style props */
  ordersButtonCategoryBackgroundColor?: CSSProperties["backgroundColor"];
  ordersButtonCategoryAlignSelf?: CSSProperties["alignSelf"];
  categoryTextLineHeight?: CSSProperties["lineHeight"];
  categoryTextDisplay?: CSSProperties["display"];
  categoryTextWhiteSpace?: CSSProperties["whiteSpace"];
  categoryTextFlex?: CSSProperties["flex"];
};

const OrdersButtonCategory: FunctionComponent<OrdersButtonCategoryType> = ({
  categoryText = "Pizza",
  ordersButtonCategoryBackgroundColor,
  ordersButtonCategoryAlignSelf,
  categoryTextLineHeight,
  categoryTextDisplay,
  categoryTextWhiteSpace,
  categoryTextFlex,
  index
}) => {
  const ordersButtonCategoryStyle: CSSProperties = useMemo(() => {
    return {
      backgroundColor: ordersButtonCategoryBackgroundColor,
      alignSelf: ordersButtonCategoryAlignSelf,
    };
  }, [ordersButtonCategoryBackgroundColor, ordersButtonCategoryAlignSelf]);

  const categoryTextStyle: CSSProperties = useMemo(() => {
    return {
      lineHeight: categoryTextLineHeight,
      display: categoryTextDisplay,
      whiteSpace: categoryTextWhiteSpace,
      flex: categoryTextFlex,
    };
  }, [
    categoryTextLineHeight,
    categoryTextDisplay,
    categoryTextWhiteSpace,
    categoryTextFlex,
  ]);

  return (
    <ColorDarken color={ordersButtonCategoryBackgroundColor}>
      {(color, setClicked) => (
        <div
          className="w-full rounded shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] flex flex-row items-center justify-center py-2 px-1 box-border text-center text-sm text-text-color-black-1e1e23 font-mulish self-stretch"
          style={{
            backgroundColor: color
          }}
          onClick={() => {
            setCategoryIndex(index)
            setClicked(true);
          }}
        >
          <b
            className="line-clamp-2 relative leading-[17px] overflow-hidden text-ellipsis"
            style={{fontSize: deviceSetting0()?.screenConfig?.categoryFontSize}}
          >
            {categoryText}
          </b>
        </div>
      )}
    </ColorDarken>
  );
};

export default OrdersButtonCategory;
