import uuid from 'time-uuid'

import type { Order } from '@/data/Order'
import { MarketPlaceProvider, OrderStatus } from '@/pos/OrderType'

import { srmTransactionLogic } from '../transaction.logic'
import { execTestcase, getCaTaxInfo } from './libs'

type O = Readonly<Order>

export const testRunner036: Record<string, () => Promise<boolean>> = {
  '036.001': () =>
    execTestcase({
      num: 36,
      step: 1,
      device: 'A',
      run: async () => {
        const taxInfo = getCaTaxInfo()
        const o: O = {
          _id: uuid(),
          status: OrderStatus.COMPLETED,
          payments: [{ type: 'cash', value: 0 }],
          items: [
            // Main Course
            { name: 'Bouillabaisse', price: 22, quantity: 2, modifiers: [{ name: 'Jelly', price: 0, quantity: 1 }], ...taxInfo },
            // Drink
            { name: 'Bordeaux', price: 18, quantity: 2, modifiers: [], ...taxInfo },
            // Dessert
            { name: 'Crème Brûlée', price: 6.5, quantity: 1, modifiers: [], ...taxInfo },
            { name: 'Tarte Tatin', price: 8, quantity: 1, modifiers: [], ...taxInfo },
            // Free
            { name: 'Bouillabaisse', price: 0, quantity: 2, modifiers: [{ name: 'Topping', price: 8, quantity: 1 }], ...taxInfo },
          ],
          provider: MarketPlaceProvider.DELIVERECT,
        }
        await srmTransactionLogic.recordClosingReceipt(o, { print: true })
      },
    }),
}
