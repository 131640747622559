import AndroidIcon from '@mui/icons-material/Android'
import AppleIcon from '@mui/icons-material/Apple'
import LaptopWindowsIcon from '@mui/icons-material/LaptopWindows'
import { Badge, Button, MenuItem, Select, ToggleButton, ToggleButtonGroup } from '@mui/material'

import TextField from '@/react/core/TextField.tsx'
import { REGISTERED_VERSIONS } from '@/srm/lib/constants'
import { srmLogic } from '@/srm/logic'

import { activePreset, handlePresetChanged, onChanged, srmSetting } from './SrmSettings.logic'

export default function SrmSettings() {
  return (
    <div
      className="grid grid-cols-2 gap-2 items-center"
      data-testid="srmSettings"
    >
      <label className="text-blue-500">Presets</label>
      <ToggleButtonGroup
        value={activePreset()}
        exclusive
        onChange={handlePresetChanged}
        aria-label="text alignment"
      >
        {Object.entries(REGISTERED_VERSIONS).map(([key, value]) => (
          <ToggleButton
            key={key}
            value={key}
            aria-label="left aligned"
          >
            {value === REGISTERED_VERSIONS.android_v1 && (
              <Badge badgeContent="v1">
                <AndroidIcon />
              </Badge>
            )}
            {value === REGISTERED_VERSIONS.android_v2 && (
              <Badge badgeContent="v2">
                <AndroidIcon />
              </Badge>
            )}
            {value === REGISTERED_VERSIONS.ios_v1 && (
              <Badge badgeContent="v1">
                <AppleIcon />
              </Badge>
            )}
            {value === REGISTERED_VERSIONS.win_v1 && (
              <Badge badgeContent="v1">
                <LaptopWindowsIcon />
              </Badge>
            )}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <label>Product Identifier</label>
      <TextField
        value={srmSetting()?.productId ?? ''}
        onChange={e => onChanged('productId', e.target.value)}
      />
      <label>Product Version Identifier</label>
      <TextField
        value={srmSetting()?.productVersionId ?? ''}
        onChange={e => onChanged('productVersionId', e.target.value)}
      />
      <label>Partner Identifier</label>
      <TextField
        value={srmSetting()?.partnerId ?? ''}
        onChange={e => onChanged('partnerId', e.target.value)}
      />
      <label>Version</label>
      <TextField
        value={srmSetting()?.version ?? ''}
        onChange={e => onChanged('version', e.target.value)}
      />
      <label>Previous Version</label>
      <TextField
        value={srmSetting()?.previousVersion ?? ''}
        onChange={e => onChanged('previousVersion', e.target.value)}
      />
      <hr className="col-span-2" />

      <label>Testcase Number</label>
      <TextField
        value={srmSetting()?.testcaseNumber ?? ''}
        onChange={e => onChanged('testcaseNumber', e.target.value)}
      />
      <label className="text-red-700">Environment</label>
      <Select
        size="small"
        value={srmSetting()?.env ?? 'DEV'}
        onChange={e => onChanged('env', e.target.value)}
      >
        <MenuItem value="DEV">🪲 DEV</MenuItem>
        <MenuItem value="ESSAI">🧪 ESSAI</MenuItem>
        <MenuItem value="PROD">🔒 PROD</MenuItem>
      </Select>

      <label>Certificate Code</label>
      <TextField
        value={srmSetting()?.certificateCode ?? ''}
        onChange={e => onChanged('certificateCode', e.target.value)}
      />
      <label></label>
      <Button
        variant="outlined"
        onClick={srmLogic.resetSrmSettingsToDefault}
      >
        Reset Settings
      </Button>
    </div>
  )
}
