import type { FunctionComponent } from "react";
import TableManualKeyboard from "../TableManualKeyboard";
import TextField from '@/react/core/TextField.tsx'

export type AddTipPopupType = {
  onClose?: () => void;
};

const AddTipPopup: FunctionComponent<AddTipPopupType> = ({ onClose }) => {
  return (
    <div className="w-full rounded bg-white-solid-white-100-ffffff flex flex-col items-center justify-start pt-4 px-[18px] pb-2 box-border gap-[20px_0px] min-w-[560px] min-h-[190px] h-full max-w-full max-h-full overflow-auto text-left text-base text-blue-solid-blue-500-0051c1 font-mulish">
      <div className="self-stretch flex flex-row items-start justify-between">
        <b className="relative">Add tip:</b>
        <img
          className="w-[21px] relative h-[21px] object-cover cursor-pointer"
          alt=""
          src="/iconclose-popup-icon@2x.png"
          onClick={onClose}
        />
      </div>
      <div className="self-stretch flex flex-col items-center justify-start">
        <TextField
          className="[border:none] bg-[transparent] self-stretch opacity-[0.6]"
          color="primary"
          label="%"
          size="small"
          fullWidth={true}
          variant="outlined"
          sx={{ "& .MuiInputBase-root": { height: "37px" } }}
        />
      </div>
      <TableManualKeyboard
        buttonText="OK"
        tableManualKeyboardMinWidth="unset"
        tableManualKeyboardMinHeight="unset"
        tableManualKeyboardHeight="258px"
        keyboardFlex="1"
        keyboardPadding="8px 10px"
        keyboardGap="8px 0px"
        lineDivFlex="1"
        lineDivHeight="unset"
        lineDivFlex1="1"
        lineDivHeight1="unset"
        lineDivFlex2="1"
        lineDivHeight2="unset"
        lineDivFlex3="1"
        lineDivHeight3="unset"
        lineDivFlex4="1"
        lineDivHeight4="unset"
      />
    </div>
  );
};

export default AddTipPopup;
