import type { FunctionComponent } from "react";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { LL0 } from "@/react/core/I18nService.tsx";

export type VoucherComponentType = {
  className?: string;
  isAvalable?: boolean;
  voucherCode?: string;
  creatorName?: string;
  expDate?: string;
  showPercentDecrease?: boolean;
  showIsSelected?: boolean;
  voucherName?: string;
  percentDecrease?: string;
  onClick?: () => void;
};

const VoucherComponent: FunctionComponent<VoucherComponentType> = ({
  className = "",
  isAvalable,
  voucherCode,
  creatorName,
  expDate,
  showPercentDecrease,
  showIsSelected,
  voucherName,
  percentDecrease,
  onClick,
}) => {
  return (
    <div
      className={`self-stretch bg-white-solid-white-100-ffffff flex flex-col items-start justify-start py-2 pr-4 pl-3 relative gap-[8px] text-left text-sm text-blue-solid-blue-0057ff font-mulish ${className}`}
      onClick={onClick}
      ref={makeRipple}
    >
      {!isAvalable ?
        <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] bg-purple-solid-purple-100-f5e6fe z-[1]" />
        :
        <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] bg-orange-solid-orange-50-fff4ed z-[1]" />
      }
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px] z-[2]">
        <div className="self-stretch relative font-medium">{voucherCode}</div>
        <div className="self-stretch relative font-semibold text-gray-solid-gray-932-3c3c3c">
          {voucherName}
        </div>
        <div className="self-stretch relative text-xs font-medium text-gray-solid-gray-930-546073">
          {creatorName}
        </div>
      </div>
      <div className="self-stretch relative box-border h-px z-[3] border-[1px] border-dashed border-gray-solid-gray-725-707070" />
      <div className="self-stretch flex flex-row items-center justify-start gap-[4px] z-[4] text-xs text-gray-solid-gray-932-3c3c3c">
        <div className="flex-1 flex flex-col items-start justify-start gap-[2px]">
          <i className="relative font-medium">{LL0().voucher.expiryDate()}</i>
          <i className="relative text-smi font-medium">{expDate}</i>
        </div>
        {showPercentDecrease && (
          <div className="rounded-sm bg-green-solid-green-600-00c37c flex-row items-center justify-center py-0.5 px-2 text-mini text-white-solid-white-100-ffffff">
            <b className="relative">{percentDecrease}</b>
          </div>
        )}
      </div>
      {showIsSelected && (
        <div className="w-[5px] absolute !m-[0] h-full top-[0%] right-[0px] bottom-[0%] shadow-[0px_0px_4px_rgba(20,_113,_255,_0.6)] bg-blue-solid-blue-460-1471ff z-[5]" />
      )}
    </div>
  );
};

export default VoucherComponent;
