import type {EmailReportHistoryProps} from "@/react/EmailReportView/EmailReportView.tsx";

type EmailReportHistoryRowProps = EmailReportHistoryProps & {
  isEven:number
}

const EmailReportHistoryRow = ({email,reportTime,reportType,isEven,time,status,notes}:EmailReportHistoryRowProps) => {
  return(
    <tr className={isEven % 2 == 0 ? 'bg-white-solid-white-100-ffffff' : 'bg-gray-solid-gray-180-ebecee'}>
      <td className="relative pr-0">
        <div className="flex flex-row items-center justify-start py-3 px-2 box-border w-full h-full">
          <div className="relative text-sm font-mulish text-grey-grey-darken-3 text-left Tablet_768:text-xs">
            {time}
          </div>
        </div>
      </td>
      <td className="relative pr-0">
        <div className="flex flex-row items-center justify-start py-3 px-2 box-border gap-[4px] w-full h-full">
          <div className="relative text-sm font-mulish text-grey-grey-darken-3 text-left Tablet_768:text-xs">
            {reportType}
          </div>
          <b className="relative text-sm font-mulish text-grey-grey-darken-3 text-left Tablet_768:text-xs">
            {reportTime}
          </b>
        </div>
      </td>
      <td className="relative pr-0">
        <div className="flex flex-row items-center justify-start py-3 px-2 box-border w-full h-full">
          <div className="relative text-sm font-mulish text-grey-grey-darken-3 text-left Tablet_768:text-xs">
            {email}
          </div>
        </div>
      </td>
      <td className="relative">
        <div className="flex flex-row items-center justify-start py-3 px-2 box-border gap-[4px] w-full h-full">
          <div className="relative text-sm font-semibold font-mulish text-red-solid-red-600-d40000 text-left Tablet_768:text-xs">
            {status}
          </div>
          <div className="relative text-sm font-semibold font-mulish text-grey-grey-darken-3 text-left Tablet_768:text-xs">
            [{notes}]
          </div>
        </div>
      </td>
    </tr>
  )
}

export default EmailReportHistoryRow