import { computed, signal } from "@/react/core/reactive.ts";
import type { DocDeepSignal } from "@/data/data-utils.ts";
import type { DeviceSetting } from "@/data/DeviceSetting.ts";
import { getDeviceId } from '@/shared/getDeviceId'
import _ from "lodash";

export const deviceSettings0Signal = signal<Array<DocDeepSignal<DeviceSetting>>>([]);
export const deviceSettings0 = deviceSettings0Signal[0];
export const setDeviceSettings0 = deviceSettings0Signal[1];
export const deviceSettingVSignal = signal(0);
export const deviceSettingV = deviceSettingVSignal[0];
export const setDeviceSettingV = deviceSettingVSignal[1];
export const deviceSetting0 = computed<DocDeepSignal<DeviceSetting> | undefined>(() => {
  const check = localStorage.getItem('device-setting-cache')
  const deviceSettingCache = check ? JSON.parse(check) : undefined
  const id = getDeviceId();
  const deviceSetting = deviceSettings0().find(d => d._id === id)!;
  if (!deviceSetting && deviceSettingCache) return deviceSettingCache
  const deviceSettingCheck = _.omit(_.cloneDeep(deviceSetting), ['updatedAt', 'date', 'doc'])
  const deviceCacheCheck = _.omit(deviceSettingCache, ['updatedAt', 'date', 'doc'])
  if (deviceSetting && (!check || !_.isEqual(deviceSettingCheck, deviceCacheCheck))) {
    localStorage.setItem('device-setting-cache', JSON.stringify(deviceSetting))
  }
  return deviceSetting
});