import { convertDocuments } from '@/data/data-utils'
import { dataLock } from '@/data/DataUtils.ts'
import { deviceSetting0 } from '@/data/DeviceSettingSignal.ts'
import { isQuebecSrmEnabled, mainScreen, posSetting0 } from '@/data/PosSettingsSignal.ts'
import { User, UserRole } from '@/data/User'
import { loginUser, setLoginUser, setUsers0, users0 } from '@/data/UserSignal.ts'
import { onMountScreen, PosScreen, router } from '@/pos/PosRouter.ts'
import { computed, effectOn, signal, useAsyncEffect } from '@/react/core/reactive.ts'
import { AccessPort, getAccessibility } from '@/react/DecentralizationPort.ts'
import { ModeConnect } from '@/react/Developer/DataMenu.logic'
import MultiAwaitLock from '@/shared/MultiAwaitLock.ts'
import { isSrmCertificateValid, srmCertLogic } from '@/srm/cert.logic'
import { CONSTANT_VALUES } from '@/srm/lib/constants'
import { SrmLogic } from '@/srm/logic'
import { isInSrmTrainingMode } from '@/srm/signals'
import { SrmTransactionLogic } from '@/srm/transaction.logic'

import { clearStaffStatusOnAllTables } from './TableStaffStatusHub'

export const [userV, setUserV] = signal<number>(0)
export const [openSRMCertificateWarningPopup, setOpenSRMCertificateWarningPopup] = signal<boolean>(false)
const userLock = new MultiAwaitLock()
userLock.tryAcquire()

effectOn(
  [userV],
  async () => {
    await dataLock.acquireAsync()
    const _users = await User.find().exec()
    const users = convertDocuments<User>(_users, true)
    setUsers0(users)
    if (users.length > 0) {
      userLock.release().then()
    }
  },
  { defer: true }
)

export const makeUsersAvailable = () => {
  useAsyncEffect(async () => {
    if (userV() === 0) {
      await dataLock.acquireAsync()
      User.$.subscribe(() => setUserV(v => v + 1))
      setUserV(1)
    }
  }, [userV()])
}

export const login = async (passcode: string) => {
  console.log('🔑 Logging in using passcode', passcode)
  await userLock.acquireAsync()
  if (users0().length === 0 && passcode === '0000') {
    if (isQuebecSrmEnabled()) await clearStaffStatusOnAllTables()
    return true
  }
  const user = users0().find(user => user.passcode === passcode)
  if (user) {
    console.log('🔑 Logging in as', user.name)
    setLoginUser(user)
    localStorage.setItem('loggedPasscode', passcode)
    if (isQuebecSrmEnabled()) {
      await clearStaffStatusOnAllTables()
      // Check certificate validity asynchronously after login
      if (localStorage.getItem('modeConnect') !== ModeConnect.DEVELOPER.toString()) {
        setTimeout(async () => {
          const result = srmCertLogic.checkCertificateValidity(deviceSetting0()?.srm?.cert)
          console.log('🔑 Certificate validity:', result)
          if (result.status !== 'ok') await srmCertLogic.promptUserToUpdateCert(result)
        }, 500)
      }
    }
    return true
  }
  return false
}

export const logOut = async () => {
  const user = loginUser()?.name
  // We need to impersonate the current user because we will log him out right away
  if (isValidSrmUser()) {
    // Because we does not await here, need to pass the user to impersonate before log out
    // Send offline transactions, if any
    new SrmTransactionLogic({ impersonate: user }).sendOfflineTransactionsUnattended()
    // Turn off training mode when logout
    if (isInSrmTrainingMode()) new SrmLogic({ impersonate: user }).setSrmTrainingMode(false)
  }
  if (isQuebecSrmEnabled()) await clearStaffStatusOnAllTables()

  console.log('🚶‍♂️ Logging out as', user)
  setLoginUser()
  router.screen = PosScreen.PASSCODE
  localStorage.removeItem('loggedPasscode')
}

export const autoLogin = async () => {
  const user = users0().find(user => ['admin', 'Admin'].includes(user.name!) || user.role === UserRole.ADMIN)
  if (user) {
    return await login(user.passcode ?? '')
  }
}

export const loginUsers = computed<string[]>(() => {
  if (loginUser()?.name) {
    return [loginUser()!.name!]
  }
  return []
})

// show button settings
export const loginUsersEditSettingPermission = computed(() => {
  if (loginUser()) {
    return getAccessibility(AccessPort.EDIT_HIGH_SECURITY_SETTING) || getAccessibility(PosScreen.HAPPY_HOUR) || getAccessibility(PosScreen.EDIT_MENU) || getAccessibility(PosScreen.EDIT_FLOOR_PLAN)
  }
  return false
})
export const loginUsersReportsPermission = computed(() => {
  if (loginUser()) {
    return getAccessibility(PosScreen.STAFF_REPORT) || getAccessibility(PosScreen.EOD) || getAccessibility(PosScreen.MONTHLY_REPORT) || getAccessibility(PosScreen.CASHBOOK)
  }
  return false
})
export const isValidSrmUser = computed(
  () =>
    // Running testcase
    posSetting0()?.srm?.testcaseNumber !== CONSTANT_VALUES.CASESSAI_EMPTY ||
    // OR user is synced with SRM
    (loginUser()?.srmSynced &&
      // AND have does not have any sync error
      !loginUser()?.srmSyncingError &&
      // AND have valid certificate
      isSrmCertificateValid() &&
      // AND have valid device ID
      deviceSetting0()?.srm?.deviceId)
)

onMountScreen(mainScreen(), async () => {
  await userLock.acquireAsync()
  if (import.meta.env.MODE === 'development') {
    console.log('loginUsersEditSettingPermission', loginUsersEditSettingPermission())
    if (!(await autoLogin())) await logOut()
  }
})
