import { type FunctionComponent, useCallback } from 'react'
import { setSelectedUserId } from '@/react/StaffReportView/StaffReportView'

type UserTabType = {
  imageId?: string
  userName?: string
  active?: boolean
  id?: string
}

const UserTab: FunctionComponent<UserTabType> = ({ id, imageId, userName = 'Carolyn Campbell', active }) => {
  const handleClick = useCallback(() => setSelectedUserId(id), [])
  return (
    <div
      className="bg-white-solid-white-230-f3f4f4 box-border h-[68px] flex flex-row items-center justify-start py-3.5 px-4 relative gap-[12px] text-left text-smi text-gray-solid-gray-910-4d4d4e font-mulish border-b-[1px] border-solid border-gray-solid-gray-360-d6d5d5 Tablet_768:py-2.5 Tablet_768:px-3 Tablet_768:box-border Tablet_600:gap-[8px] Tablet_600:py-2.5 Tablet_600:px-3 Tablet_600:box-border"
      onClick={handleClick}
    >
      {active && (
        <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-white-solid-white-100-ffffff box-border z-[0] border-r-[4px] border-solid border-royalblue-200" />
      )}
      <img
        className="relative rounded-981xl w-10 h-10 z-[1] Tablet_768:w-8 Tablet_768:h-8 Tablet_600:w-[26px] Tablet_600:h-[26px]"
        alt=""
        src={imageId}
      />
      <div
        data-active={active}
        className="relative z-[2] data-[active=true]:text-royalblue-200 Tablet_600:text-xs"
      >
        {userName}
      </div>
    </div>
  )
}

export default UserTab
