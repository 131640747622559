import OnlineProviderSettingGrid from "@online-provider-setting/OnlineProviderSettingGrid.tsx";
import {signal} from "@/react/core/reactive.ts";
import type {InputController} from "@/react/core/Input.tsx";
import {memo} from "react";

export const [inputControllers, setInputControllers] = signal<Array<InputController>>([]);

const WebMenuView = () => {
  return(
   <OnlineProviderSettingGrid/>
  )
}
export default memo(WebMenuView)