import { type FunctionComponent, useMemo, type CSSProperties } from "react";

type StatePendingType = {
  chipNumber?: number;

  /** Style props */
  statePendingFlexShrink?: CSSProperties["flexShrink"];
  statePendingBackgroundColor?: CSSProperties["backgroundColor"];
};

const StatePending: FunctionComponent<StatePendingType> = ({
  chipNumber ,
  statePendingFlexShrink,
  statePendingBackgroundColor,
}) => {
  const statePendingStyle: CSSProperties = useMemo(() => {
    return {
      flexShrink: statePendingFlexShrink,
      backgroundColor: statePendingBackgroundColor,
      borderRadius: '12px'
    };
  }, [statePendingFlexShrink, statePendingBackgroundColor]);

  return (
    <div
      className="bg-materialize-yellow-darken-3 w-[30px] overflow-hidden flex flex-row items-center justify-center py-px px-0 box-border text-center text-xs text-basic-color-white font-mulish"
      style={statePendingStyle}
    >
      <div className="flex-1 relative">{chipNumber}</div>
    </div>
  );
};

export default StatePending;
