import { type Order, PaidOrder } from "@/data/Order.ts";
import { CommitAction, type OrderItem, OrderStatus, TseMethod } from "@/pos/OrderType.ts";
import dayjs from "dayjs";
import { TseTransaction, VorgangArt } from "@/data/TseTransaction.ts";
import { sendContentToTse, sendContentToTsePayApi } from "@/tse/tse-communicate.ts";
import { debugTse, type TransactionContent } from "@/tse/tse-init.ts";
import _ from "lodash";
import { makeKassenBelegFinish, makeKassenBestellung } from "@/tse/makeProcessDataLib.ts";
import uuid from "time-uuid";
import { createOrder, stripOrder } from "@/pos/logic/order-reactive.ts";
import { addCashPayment, clearPayment } from "@/pos/logic/order-utils.ts";
import { deleteMany, updateMany } from "@/data/data-utils.ts";
import JsonFn from "json-fn";

export const makeTempTransaction = async function (items: OrderItem[], cancellationItems: OrderItem[], order: Order) {
  const [fakeTseItems, fakeTseStornoItems] = [items, cancellationItems]
    .map(v => v.reduce<OrderItem[]>((list, item) => {
      if (item.tseMethod === TseMethod.passthrough) list.push(item);
      if (item.tseMethod === TseMethod.applyPart && item.quantity > 1) {
        const _item = _.cloneDeep(item);
        _item.quantity--;
        _item.tseMethod = TseMethod.passthrough;
        list.push(_item);
      }
      return list;
    }, []));
  const content = makeKassenBestellung(fakeTseItems, fakeTseStornoItems, true);
  if (!content.transactionData) return;
  debugTse('tse unreachable: ', content.transactionData.replace(/\r/g, '\n'));

  await TseTransaction.insert({
    _id: uuid(),
    fake: true,
    TSE_TA_START: order.date,
    TSE_TA_ENDE: order.date,
    TSE_TA_VORGANGSART: content.processType,
    TSE_TA_VORGANGSDATEN: content.transactionData,
    order: order._id,
    TSE_TA_FEHLER: 'TSE ist nicht erreichbar',
    z: order.z
  });
};

export const handleNotPassthroughTse = async (order: Order, content: TransactionContent) => {
  order.tseMethod = TseMethod.passthrough;
  order.items.forEach(i => i.tseMethod = TseMethod.passthrough);
  if (process.env.TSE_ENV === 'test') console.log('Tse unreachable: ', content.transactionData.replace(/\r/g, '\n'));
  await TseTransaction.insert({
    _id: uuid(),
    fake: true,
    TSE_TA_START: order.date,
    TSE_TA_ENDE: order.date,
    TSE_TA_VORGANGSART: content.processType,
    TSE_TA_VORGANGSDATEN: content.transactionData,
    order: order._id,
    TSE_TA_FEHLER: 'TSE ist nicht erreichbar',
    z: order.z
  });

  const orderContent = makeKassenBelegFinish(order);
  await TseTransaction.insert({
    _id: uuid(),
    fake: true,
    TSE_TA_START: order.date,
    TSE_TA_ENDE: order.date,
    TSE_TA_VORGANGSART: orderContent.processType,
    TSE_TA_VORGANGSDATEN: orderContent.transactionData,
    order: order._id,
    TSE_TA_FEHLER: 'TSE ist nicht erreichbar',
    z: order.z
  });
};

export const removeFakeEod = async (orders: Order[]) => {
  // orders = orders.filter(o => !o.qrCode);
  for (const o of orders) {
    await removeFakeTransaction(o);
  }
}

export const handleTsePassthroughInvoice = async function (order: Order, needReassignQr: boolean) {
  const orderContent = makeKassenBelegFinish(order);
  debugTse('tse unreachable: (handle passthrough)', orderContent.transactionData.replace(/\r/g, '\n'));
  await TseTransaction.insert({
    _id: uuid(),
    ...!needReassignQr && { fake: true },
    TSE_TA_START: order.date,
    TSE_TA_ENDE: order.date,
    TSE_TA_VORGANGSART: orderContent.processType,
    TSE_TA_VORGANGSDATEN: orderContent.transactionData,
    order: order._id,
    TSE_TA_FEHLER: 'TSE ist nicht erreichbar',
    z: order.z,
    fake: true
  });
}

export function cloneOrder(order: Order, date: number, items: OrderItem[]) {
  let order2 = _.cloneDeep(order);
  order2._id = uuid()
  delete order2.table;
  delete order2.qrCode;
  order2.date = dayjs.unix(date).add(5, 'minute').unix();
  order2.items = items;
  order2 = createOrder(order2);
  clearPayment(order2);
  addCashPayment(order2);
  return order2;
}

export const mutateOrderEod = async (order: Order, items: OrderItem[]) => {
  //prevent side effect to order history
  //make new order in this time, date = last item date
  items.forEach(i => i.tseMethod = TseMethod.apply)
  items = _.sortBy(items, ['date']);
  let order2 = cloneOrder(order, order.date!, items);
  if (order.status === OrderStatus.CANCELLED) {
    order2.originalOrder = order._id;
  }
  if (order.status === OrderStatus.CANCELLATION_REFERENCE) {
    const _order = await PaidOrder.findOne({ selector: { originalOrder: order.refOrder } }).exec();
    order2.refOrder = _order?._id;
  }

  await PaidOrder.insert(stripOrder(order2));
  //make new transaction in this time, date = last item date


  const orderContent = makeKassenBelegFinish(order2);
  await TseTransaction.insert({
    _id: uuid(),
    fake: true,
    TSE_TA_START: order2.date,
    TSE_TA_ENDE: order2.date,
    TSE_TA_VORGANGSART: orderContent.processType,
    TSE_TA_VORGANGSDATEN: orderContent.transactionData,
    order: order2._id,
    TSE_TA_FEHLER: 'TSE ist nicht erreichbar',
    z: order.z
  });

  //remove real data for invoice transaction:
  await updateMany(TseTransaction,
    { selector: { order: order._id, z: order.z, TSE_TA_VORGANGSDATEN2: { $exists: true } } },
    { TSE_TA_VORGANGSDATEN2: undefined });
}

export const removeFakeTransaction = async (order: Order) => {
  // await TseTransaction.deleteMany({ order: order._id, TSE_TA_FEHLER: { $exists: true }, fake: true });
  let _id: any = order._id;
  if (order.splitId) _id = {$in : [order._id, order.splitId]}
  await deleteMany(TseTransaction, { selector: { order: _id, TSE_TA_FEHLER: { $exists: true }, fake: true } })
  const transactions = await TseTransaction.find({
    selector: {
      order: order._id,
      TSE_TA_VORGANGSDATEN2: { $exists: true }
    }
  }).exec();
  for (const transaction of transactions) {
    await transaction.incrementalPatch({ TSE_TA_VORGANGSDATEN2: undefined });
  }
}

export const tseMakeApply = async (order: Order, sentItems: OrderItem[], sentCancellationItems: OrderItem[], items: OrderItem[], cancellationItems: OrderItem[], shouldMakeQrCode: boolean = false) => {
  //todo: apply:1 -> phai deal
  //mục tiêu là chỉ cần có cancel thì sẽ biến thành apply 100%

  sentItems = sentItems.filter(i => i.tseMethod !== TseMethod.apply);
  sentCancellationItems = sentCancellationItems.filter(i => i.tseMethod !== TseMethod.apply);
  // change date for item
  const date = new Date();
  sentItems.concat(sentCancellationItems).forEach(i => {
    if (!order.commits) {
      return i.tseMethod = TseMethod.apply;
    }
    order.commits!.push({
      action: CommitAction.SET_TSE_METHOD, commitId: i.commitRefs![0] || i._id,
      ... i.seat !== undefined && { seat: i.seat },
      tseMethod: TseMethod.apply, date: dayjs(date).unix()
    })
  });

  let _id: any = order._id;
  if (order.splitId) _id = {$in : [order._id, order.splitId]}

  const fakeTransactions = await TseTransaction.find({
    selector: {
      order: _id,
      TSE_TA_FEHLER: { $exists: true }
    }
  }).exec();
  for (const fakeTransaction of fakeTransactions) {
    if (fakeTransaction.TSE_TA_VORGANGSART !== VorgangArt.KassenbelegV1) {
      const content: TransactionContent = {
        transactionData: fakeTransaction.TSE_TA_VORGANGSDATEN!,
        processType: fakeTransaction.TSE_TA_VORGANGSART!
      };
      await sendContentToTse(content, order, order.z!);
      //todo: remove fake transaction
      await TseTransaction.bulkRemove([fakeTransaction._id]);
    } else {
      await TseTransaction.bulkRemove([fakeTransaction._id]);
      shouldMakeQrCode = true;
    }
  }

  //remove fake
  // await cms.emit('order:finished:removeFake', order);
  await removeFakeTransaction(order);

  items.concat(cancellationItems).forEach(i => i.tseMethod = TseMethod.apply)

  if (!order.qrCode && shouldMakeQrCode) {
    order.qrCode = await sendContentToTsePayApi(order);
  }
  order.tseMethod = TseMethod.apply;
}
