import { type FunctionComponent, useState } from 'react';
import { displayPassword, password } from '@passcode/PasscodeRight.tsx';
import clsx from 'clsx';

export type InputPasscodeType = {
  visibleCode?: boolean;
  hideCode?: boolean;
  showVisibleIcon?: boolean;
  hideIcon?: boolean;
};

const InputPasscode: FunctionComponent<InputPasscodeType> = ({
  visibleCode = true,
  hideCode = false,
  showVisibleIcon,
  hideIcon,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  visibleCode = isVisible;
  hideCode = !isVisible;
  showVisibleIcon = !isVisible;
  hideIcon = !isVisible;

  return (
    <div
      className="w-full box-border flex flex-col items-center justify-start pt-0 px-0 pb-1 relative gap-[4px] max-w-[480px] text-center text-6xl text-white-solid-white-100-ffffff font-mulish border-b-[2px] border-solid border-blue-opacity-blue-34-cddeff">
      <div className="self-stretch flex flex-col items-center justify-center gap-[4px] z-[0]">
        {visibleCode && (
          <div
            className="self-stretch relative tracking-[5px] font-medium flex items-center justify-center h-5 shrink-0"
            >
            {password()}
          </div>
        )}
        {hideCode && (
          <div className={clsx("self-stretch relative text-xl tracking-[5px] font-medium flex items-center justify-center h-5 shrink-0",
            password().length > 0 ? 'opacity-100' : 'opacity-[0.4]'
          )}>
            {displayPassword()}
          </div>
        )}
      </div>
      <div className="!m-[0] absolute top-[calc(50%_-_11px)] right-[0px] flex flex-row items-center justify-end pt-0 px-0 pb-0.5 gap-[4px] z-[1]">
        {!hideIcon && (
          <img
            className="w-5 relative h-5 overflow-hidden shrink-0 object-cover"
            alt=""
            src="/iconpasscodehide-icon@2x.png"
            onPointerDown={() => setIsVisible(false)}
          />
        )}
        {showVisibleIcon && (
          <img
            className="w-5 relative h-5 overflow-hidden shrink-0"
            alt=""
            src="/iconpasscodevisible-icon.svg"
            onPointerDown={() => setIsVisible(true)}
          />
        )}
      </div>
    </div>
  );
};

export default InputPasscode;
