import type { FunctionComponent } from "react";
import { TextField, InputAdornment, Icon, IconButton } from "@mui/material";

export type AddTipPopupType = {
  className?: string;
  onClose?: () => void;
};

const AddTipPopup: FunctionComponent<AddTipPopupType> = ({
  className = "",
  onClose,
}) => {
  return (
    <div
      className={`w-full relative rounded bg-basic-color-white flex flex-col items-center justify-start pt-4 px-[18px] pb-2 box-border gap-[20px] min-w-[560px] min-h-[190px] h-full max-w-full max-h-full overflow-auto text-left text-base text-blue-solid-blue-500-0051c1 font-mulish-button-name-bold ${className}`}
    >
      <div className="self-stretch flex flex-row items-start justify-between">
        <b className="relative">Add tip:</b>
        <img
          className="w-[21px] relative h-[21px] object-cover cursor-pointer"
          alt=""
          src="/iconclose-popup-icon@2x.png"
          onClick={onClose}
        />
      </div>
      <div className="self-stretch flex flex-col items-center justify-start">
        <TextField
          className="[border:none] bg-[transparent] self-stretch opacity-[0.6]"
          color="primary"
          label="%"
          size="small"
          fullWidth={true}
          variant="outlined"
          sx={{ "& .MuiInputBase-root": { height: "37px" } }}
        />
      </div>
      <div className="self-stretch h-[258px] rounded-10xs bg-gray-solid-gray-180-ebecee flex flex-col items-center justify-start gap-[12px] text-center text-3xl text-black-solid-black-1000-000000 height_414:h-[220px]">
        <div className="self-stretch flex-1 [backdrop-filter:blur(30px)] rounded bg-blue-opacity-blue-15-f3f9ff flex flex-col items-center justify-center py-2 px-2.5 gap-[8px]">
          <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[8px] Tablet_768:gap-[6px] Tablet_600:gap-[6px] Mobile_480:gap-[4px]">
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">Q</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">W</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">E</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">R</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">T</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">Y</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">U</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">I</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">O</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">P</div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 px-[33px] gap-[8px] Tablet_768:gap-[6px] Tablet_600:gap-[6px] Mobile_480:gap-[4px] Mobile_480:pl-[30px] Mobile_480:pr-[30px] Mobile_480:box-border">
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center z-[8]">
              <div className="relative">A</div>
            </div>
            <div className="self-stretch flex-1 flex flex-col items-start justify-start relative gap-[2px] z-[7] text-basic-color-white">
              <div className="!m-[0] absolute top-[-50px] left-[0px] shadow-[0px_2px_4px_rgba(0,_0,_0,_0.22)] rounded-8xs-6 bg-white-solid-white-120-fcfcfe box-border h-12 hidden flex-row items-center justify-center py-0 px-2 gap-[6px] z-[1] border-[0.3px] border-solid border-gray-solid-gray-450-b6b6b6">
                <div className="rounded-10xs flex flex-row items-center justify-center py-1 px-2 relative gap-[2px]">
                  <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs bg-blue-solid-blue-480-4175fb hidden z-[0]" />
                  <div className="w-3.5 relative hidden z-[1]">S</div>
                  <div className="relative text-black-solid-black-1000-000000 z-[2]">
                    S
                  </div>
                </div>
                <div className="rounded-10xs flex flex-row items-center justify-center py-1 px-2 relative gap-[2px]">
                  <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs bg-blue-solid-blue-480-4175fb z-[0]" />
                  <div className="relative z-[1]">ß</div>
                  <div className="w-3.5 relative text-black-solid-black-1000-000000 hidden z-[2]">
                    ß
                  </div>
                </div>
                <div className="rounded-10xs flex flex-row items-center justify-center py-1 px-2 relative gap-[2px]">
                  <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs bg-blue-solid-blue-480-4175fb hidden z-[0]" />
                  <div className="w-3.5 relative hidden z-[1]">Ś</div>
                  <div className="relative text-black-solid-black-1000-000000 z-[2]">
                    Ś
                  </div>
                </div>
                <div className="rounded-10xs flex flex-row items-center justify-center py-1 px-2 relative gap-[2px]">
                  <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs bg-blue-solid-blue-480-4175fb hidden z-[0]" />
                  <div className="w-3.5 relative hidden z-[1]">Š</div>
                  <div className="relative text-black-solid-black-1000-000000 z-[2]">
                    Š
                  </div>
                </div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center z-[0] text-black-solid-black-1000-000000">
                <div className="relative">S</div>
              </div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center z-[6]">
              <div className="relative">D</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center z-[5]">
              <div className="relative">F</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center z-[4]">
              <div className="relative">G</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center z-[3]">
              <div className="relative">H</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center z-[2]">
              <div className="relative">J</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center z-[1]">
              <div className="relative">K</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center z-[0]">
              <div className="relative">L</div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[22px] Tablet_768:gap-[16px] Tablet_600:gap-[12px] Mobile_480:gap-[4px]">
            <div className="self-stretch shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center py-0 px-7">
              <img
                className="w-[18.9px] relative h-[16.5px]"
                alt=""
                src="/shift1.svg"
              />
            </div>
            <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 px-0.5 gap-[6px]">
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">Z</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">X</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">C</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">V</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">B</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">N</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">M</div>
              </div>
            </div>
            <div className="self-stretch shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] flex flex-col items-center justify-center py-0 px-7">
              <img
                className="w-[22.7px] relative h-[17px]"
                alt=""
                src="/delete-button1.svg"
              />
            </div>
          </div>
          <div className="self-stretch flex-1 gap-[8px] grid grid-cols-[1fr_2fr_1fr] [grid-row-gap:0px] [grid-column-gap:8px] text-base Tablet_600:grid Tablet_600:grid-cols-[1fr_1fr_1fr] Tablet_600:[grid-row-gap:0px] Tablet_600:[grid-column-gap:8px]">
            <div className="flex flex-row items-start justify-start gap-[8px] min-w-[146px] relative col-[1/1] row-[1/1] Tablet_768:relative Tablet_768:col-[1/1] Tablet_768:row-[1/1]">
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] flex flex-col items-center justify-center">
                <div className="relative tracking-[-0.32px] leading-[21px]">
                  123
                </div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center text-3xl">
                <div className="relative">,</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center text-lg">
                <div className="relative">EN</div>
              </div>
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center relative col-[2/2] row-[1/1]">
              <div className="relative tracking-[-0.32px] leading-[21px]">
                space
              </div>
            </div>
            <div className="flex flex-row items-start justify-start gap-[8px] min-w-[120px] relative col-[3/3] row-[1/1] text-3xl Tablet_600:relative Tablet_600:col-[3/3] Tablet_600:row-[1/1]">
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center min-w-[34px] Tablet_600:max-w-[52px]">
                <div className="relative">.</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-blue-solid-blue-350-2196f3 flex flex-col items-center justify-center text-base text-basic-color-white Mobile_480:flex-1">
                <div className="relative">Add</div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[784px] flex-1 [backdrop-filter:blur(30px)] rounded bg-blue-opacity-blue-15-f3f9ff hidden flex-col items-center justify-center py-2 px-2.5 box-border gap-[8px]">
          <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[8px] Tablet_768:gap-[6px] Tablet_600:gap-[6px] Mobile_480:gap-[4px]">
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">Q</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">W</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">E</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">R</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">T</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">Z</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">U</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">I</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">O</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">P</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">Ü</div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[8px] Tablet_768:gap-[6px] Tablet_600:gap-[6px] Mobile_480:gap-[4px]">
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center z-[10]">
              <div className="relative">A</div>
            </div>
            <div className="self-stretch flex-1 flex flex-col items-start justify-start relative gap-[2px] z-[9] text-basic-color-white">
              <div className="!m-[0] absolute top-[-50px] left-[0px] shadow-[0px_2px_4px_rgba(0,_0,_0,_0.22)] rounded-8xs-6 bg-white-solid-white-120-fcfcfe box-border h-12 flex flex-row items-center justify-center py-0 px-2 gap-[6px] z-[1] border-[0.3px] border-solid border-gray-solid-gray-450-b6b6b6">
                <div className="rounded-10xs flex flex-row items-center justify-center py-1 px-2 relative gap-[2px]">
                  <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs bg-blue-solid-blue-480-4175fb hidden z-[0]" />
                  <div className="w-3.5 relative hidden z-[1]">S</div>
                  <div className="relative text-black-solid-black-1000-000000 z-[2]">
                    S
                  </div>
                </div>
                <div className="rounded-10xs flex flex-row items-center justify-center py-1 px-2 relative gap-[2px]">
                  <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs bg-blue-solid-blue-480-4175fb z-[0]" />
                  <div className="relative z-[1]">ß</div>
                  <div className="w-3.5 relative text-black-solid-black-1000-000000 hidden z-[2]">
                    ß
                  </div>
                </div>
                <div className="rounded-10xs flex flex-row items-center justify-center py-1 px-2 relative gap-[2px]">
                  <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs bg-blue-solid-blue-480-4175fb hidden z-[0]" />
                  <div className="w-3.5 relative hidden z-[1]">Ś</div>
                  <div className="relative text-black-solid-black-1000-000000 z-[2]">
                    Ś
                  </div>
                </div>
                <div className="rounded-10xs flex flex-row items-center justify-center py-1 px-2 relative gap-[2px]">
                  <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs bg-blue-solid-blue-480-4175fb hidden z-[0]" />
                  <div className="w-3.5 relative hidden z-[1]">Š</div>
                  <div className="relative text-black-solid-black-1000-000000 z-[2]">
                    Š
                  </div>
                </div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center z-[0] text-black-solid-black-1000-000000">
                <div className="relative">S</div>
              </div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center z-[8]">
              <div className="relative">D</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center z-[7]">
              <div className="relative">F</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center z-[6]">
              <div className="relative">G</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center z-[5]">
              <div className="relative">H</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center z-[4]">
              <div className="relative">J</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center z-[3]">
              <div className="relative">K</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center z-[2]">
              <div className="relative">L</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center z-[1]">
              <div className="relative">Ö</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center z-[0]">
              <div className="relative">Ä</div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[22px] Tablet_768:gap-[16px] Tablet_600:gap-[12px] Mobile_480:gap-[6px]">
            <div className="self-stretch shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center py-0 px-7 Mobile_480:pl-[18px] Mobile_480:pr-[18px] Mobile_480:box-border">
              <img
                className="w-[18.9px] relative h-[16.5px]"
                alt=""
                src="/shift2.svg"
              />
            </div>
            <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 px-0.5 gap-[6px]">
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">Y</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">X</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">C</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">V</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">B</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">N</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">M</div>
              </div>
            </div>
            <div className="self-stretch shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] flex flex-col items-center justify-center py-0 px-7 Mobile_480:pl-5 Mobile_480:pr-5 Mobile_480:box-border Mobile_480:max-w-[52px]">
              <img
                className="w-[22.7px] relative h-[17px]"
                alt=""
                src="/delete-button2.svg"
              />
            </div>
          </div>
          <div className="self-stretch flex-1 gap-[8px] grid grid-cols-[1fr_2fr_1fr] [grid-row-gap:0px] [grid-column-gap:8px] text-base Tablet_600:grid Tablet_600:grid-cols-[1fr_1fr_1fr] Tablet_600:[grid-row-gap:0px] Tablet_600:[grid-column-gap:8px]">
            <div className="flex flex-row items-start justify-start gap-[8px] min-w-[146px] relative col-[1/1] row-[1/1] Tablet_768:relative Tablet_768:col-[1/1] Tablet_768:row-[1/1]">
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] flex flex-col items-center justify-center">
                <div className="relative tracking-[-0.32px] leading-[21px]">
                  123
                </div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center text-3xl">
                <div className="relative">,</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center text-lg">
                <div className="relative">DE</div>
              </div>
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center relative col-[2/2] row-[1/1]">
              <div className="relative tracking-[-0.32px] leading-[21px]">
                Leerzeichen
              </div>
            </div>
            <div className="flex flex-row items-start justify-start gap-[8px] min-w-[120px] relative col-[3/3] row-[1/1] text-3xl Tablet_600:relative Tablet_600:col-[3/3] Tablet_600:row-[1/1]">
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center min-w-[34px] Tablet_600:max-w-[52px]">
                <div className="relative">.</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-blue-solid-blue-350-2196f3 flex flex-col items-center justify-center min-w-[94px] text-base text-basic-color-white Mobile_480:flex-1">
                <div className="relative">Hinzufügen</div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[784px] flex-1 [backdrop-filter:blur(30px)] rounded bg-blue-opacity-blue-15-f3f9ff hidden flex-col items-center justify-center py-2 px-2.5 box-border gap-[8px]">
          <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[8px] Tablet_768:gap-[6px] Tablet_600:gap-[6px] Mobile_480:gap-[4px]">
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">1</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">2</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">3</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">4</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">5</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">6</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">7</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">8</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">9</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">0</div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[8px] Tablet_768:gap-[6px] Tablet_600:gap-[6px] Mobile_480:gap-[4px]">
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">-</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">/</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">:</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">;</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">(</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">)</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">$</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">€</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">£</div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">¥</div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[32px] Tablet_768:gap-[16px] Tablet_600:gap-[12px] Mobile_480:gap-[6px]">
            <div className="self-stretch w-[78px] shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative">@</div>
            </div>
            <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 px-0.5 gap-[6px]">
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">{`&`}</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">#</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">%</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">?</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
                <div className="relative">!</div>
              </div>
            </div>
            <div className="self-stretch shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] flex flex-col items-center justify-center py-0 px-7 Mobile_480:pl-5 Mobile_480:pr-5 Mobile_480:box-border Mobile_480:max-w-[52px]">
              <img
                className="w-[22.7px] relative h-[17px]"
                alt=""
                src="/delete-button2.svg"
              />
            </div>
          </div>
          <div className="self-stretch flex-1 gap-[8px] grid grid-cols-[1fr_2fr_1fr] [grid-row-gap:0px] [grid-column-gap:8px] text-base Tablet_600:grid Tablet_600:grid-cols-[1fr_1fr_1fr] Tablet_600:[grid-row-gap:0px] Tablet_600:[grid-column-gap:8px]">
            <div className="flex flex-row items-start justify-start gap-[8px] min-w-[146px] relative col-[1/1] row-[1/1] Tablet_768:relative Tablet_768:col-[1/1] Tablet_768:row-[1/1]">
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] flex flex-col items-center justify-center">
                <div className="relative tracking-[-0.32px] leading-[21px]">
                  ABC
                </div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center text-3xl">
                <div className="relative">,</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center text-lg">
                <div className="relative">EN</div>
              </div>
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center relative col-[2/2] row-[1/1]">
              <div className="relative tracking-[-0.32px] leading-[21px]">
                space
              </div>
            </div>
            <div className="flex flex-row items-start justify-start gap-[8px] min-w-[120px] relative col-[3/3] row-[1/1] text-3xl Tablet_600:relative Tablet_600:col-[3/3] Tablet_600:row-[1/1]">
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center min-w-[34px] Tablet_600:max-w-[52px]">
                <div className="relative">.</div>
              </div>
              <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-blue-solid-blue-350-2196f3 flex flex-col items-center justify-center text-base text-basic-color-white Mobile_480:flex-1">
                <div className="relative">Add</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTipPopup;
