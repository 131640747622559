import { type FunctionComponent, useState, useCallback } from "react";
import PopupImageSelect from "./PopupImageSelect";
import PortalPopup from "../../Popup/PortalPopup";
import { STATES } from "@/react/EditOnlineMenuView/EditOnlineMenuView.logic";
import RxAttachmentImage from "@/react/RxAttachmentImage";
import {LL0} from "@/react/core/I18nService.tsx";
import {makeRipple} from "@/react/core/ripple-utils.ts";

const ImagePickerFieldset: FunctionComponent = () => {
  const [isUploadAPhotoPopupOpen, setUploadAPhotoPopupOpen] = useState(false);
  const [isUploadAPhotoPopup1Open, setUploadAPhotoPopup1Open] = useState(false);

  const openUploadAPhotoPopup = useCallback(() => {
    setUploadAPhotoPopupOpen(true);
  }, []);

  const closeUploadAPhotoPopup = useCallback(() => {
    setUploadAPhotoPopupOpen(false);
  }, []);

  const openUploadAPhotoPopup1 = useCallback(() => {
    setUploadAPhotoPopup1Open(true);
  }, []);

  const closeUploadAPhotoPopup1 = useCallback(() => {
    setUploadAPhotoPopup1Open(false);
  }, []);

  return (
    <>
      <div className="self-stretch flex flex-col items-start justify-start gap-[5px] text-left text-lg text-gray-opacity-gray-60-2e2e48-300 font-mulish-button-name-bold">
        <div className="relative font-semibold md:text-base sm:text-sm height_414:text-smi">
          {LL0().editOnlineMenu.image()}
        </div>
        <div className="relative font-semibold text-blue-500 text-sm md:text-smi sm:text-smi-1 height_414:text-smi-1">
          • {LL0().editOnlineMenu.imageGuidelines()}
        </div>
        <div className="relative font-semibold text-blue-500 text-sm md:text-smi sm:text-smi-1 height_414:text-smi-1">
          • {LL0().editOnlineMenu.imageQuality()}
        </div>
        <div className="relative font-semibold text-blue-500 text-sm md:text-smi sm:text-smi-1 height_414:text-smi-1">
          • {LL0().editOnlineMenu.noThirdPartyLogos()}
        </div>
        <div className="relative font-semibold text-blue-500 text-sm md:text-smi sm:text-smi-1 height_414:text-smi-1">
          • {LL0().editOnlineMenu.aspectRatio()}
        </div>
        <div
          className="flex flex-row items-start justify-start gap-[8px] text-center text-purple-solid-purple-300-454586 mt-2">
          {!STATES.editingProduct()?.image ? (
            <div
              className="rounded-10xs bg-blue-opacity-blue-5-475484 shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] box-border w-[181px] h-[181px] flex flex-col items-center justify-center gap-[24px] cursor-pointer border-[1px] border-dashed border-royalblue md:w-[150px] md:h-[150px] md:gap-[16px] sm:w-[140px] sm:h-[140px] sm:gap-[6px] mq768:w-[120px] mq768:h-[120px] mq768:gap-[4px]"
              onClick={openUploadAPhotoPopup}
              ref={makeRipple}
            >
              <img
                className="relative w-12 h-12 object-cover height_414:w-[30px] height_414:h-[30px]"
                alt=""
                src="/iconmenuupload-icon@2x.png"
              />
              <div
                className="self-stretch flex flex-col items-center justify-start gap-[10px] sm:gap-[8px] mq768:gap-[2px]">
                <div
                  className="self-stretch relative font-semibold md:text-sm sm:text-sm mq768:text-smi height_414:text-smi">
                  {LL0().editOnlineMenu.uploadImage()}
                </div>
                <div
                  className="self-stretch relative text-xs text-gray-opacity-gray-60-2e2e48-300 height_414:font-medium height_414:font-mulish-button-name-bold height_414:text-3xs">
                  {LL0().editOnlineMenu.imageAllowed()}
                </div>
              </div>
            </div>
          ) : (
            <div
              className="group rounded-10xs w-[181px] h-[181px] flex flex-col items-center justify-center !bg-[url('/onlineproduct-template-image2@3x.png')] bg-cover bg-no-repeat bg-[top] cursor-pointer md:w-[150px] md:h-[150px] md:gap-[16px] sm:w-[140px] sm:h-[140px] sm:gap-[12px] mq768:w-[120px] mq768:h-[120px]"
              onClick={openUploadAPhotoPopup1}
              ref={makeRipple}
            >
              <img
                className="hidden group-hover:block absolute z-10 w-12 h-12 object-cover height_414:w-[30px] height_414:h-[30px]"
                alt=""
                src="/iconmenuedit-img-icon@2x.png"
              />
              <RxAttachmentImage
                className="w-full h-full object-cover"
                doc={STATES.editingProduct()?.doc}
                id={STATES.editingProduct()?.image}
              />
            </div>
          )}
        </div>
      </div>
      {isUploadAPhotoPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeUploadAPhotoPopup}
        >
          <PopupImageSelect onClose={closeUploadAPhotoPopup} />
        </PortalPopup>
      )}
      {isUploadAPhotoPopup1Open && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeUploadAPhotoPopup1}
        >
          <PopupImageSelect onClose={closeUploadAPhotoPopup1} />
        </PortalPopup>
      )}
    </>
  );
};

export default ImagePickerFieldset;
