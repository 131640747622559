import CashUrl from '@/assets/Cash.png'
import AmexUrl from '@/assets/payments/amex.png'
import ApplePayUrl from '@/assets/payments/apple_pay.png'
import BlackCardUrl from '@/assets/payments/black_card.png'
import CardUrl from '@/assets/payments/card.png'
import ChequeUrl from '@/assets/payments/cheque.png'
import DebitCardUrl from '@/assets/payments/debit_card.png'
import DebitorUrl from '@/assets/payments/debitor.png'
import DoordashUrl from '@/assets/payments/doordash.png'
import GiftCardUrl from '@/assets/payments/gift_card.png'
import GooglePayUrl from '@/assets/payments/google_pay.png'
import InteracUrl from '@/assets/payments/interac.png'
import MastercardUrl from '@/assets/payments/mastercard.png'
import MenuOttawaUrl from '@/assets/payments/menu_ottawa.png'
import PaypalUrl from '@/assets/payments/paypal.png'
import SkipUrl from '@/assets/payments/skip.png'
import SodexoUrl from '@/assets/payments/sodexo.png'
import UberUrl from '@/assets/payments/uber.png'
import VisaUrl from '@/assets/payments/visa.png'

// prettier-ignore
export enum PaymentIcons {
  amex        = 'amex'       ,
  apple_pay   = 'apple_pay'  ,
  black_card  = 'black_card' ,
  card        = 'card'       ,
  cash        = 'cash'       ,
  cheque      = 'cheque'     ,
  debit_card  = 'debit_card' ,
  door_dash   = 'door_dash'  ,
  gift_card   = 'gift_card'  ,
  google_pay  = 'google_pay' ,
  interac     = 'interac'    ,
  mastercard  = 'mastercard' ,
  menu_ottawa = 'menu_ottawa',
  paypal      = 'paypal'     ,
  skip        = 'skip'       ,
  sodexo      = 'sodexo'     ,
  uber        = 'uber'       ,
  visa        = 'visa'       ,
  debitor     = 'debitor'    ,
}

interface PaymentMapProps {
  id: number
  name?: string
  icon?: PaymentIcons
}

export const PaymentMap: PaymentMapProps[] = [
  {
    id: 0,
    name: 'Amex',
    icon: PaymentIcons.amex,
  },
  {
    id: 1,
    name: 'Apple Pay',
    icon: PaymentIcons.apple_pay,
  },
  {
    id: 2,
    name: 'Black Card',
    icon: PaymentIcons.black_card,
  },
  {
    id: 3,
    name: 'Card',
    icon: PaymentIcons.card,
  },
  {
    id: 4,
    name: 'Cash',
    icon: PaymentIcons.cash,
  },
  {
    id: 5,
    name: 'Cheque',
    icon: PaymentIcons.cheque,
  },
  {
    id: 6,
    name: 'Debit Card',
    icon: PaymentIcons.debit_card,
  },
  {
    id: 7,
    name: 'Door Dash',
    icon: PaymentIcons.door_dash,
  },
  {
    id: 8,
    name: 'Gift Card',
    icon: PaymentIcons.gift_card,
  },
  {
    id: 9,
    name: 'Google Pay',
    icon: PaymentIcons.google_pay,
  },
  {
    id: 10,
    name: 'Interac',
    icon: PaymentIcons.interac,
  },
  {
    id: 11,
    name: 'Mastercard',
    icon: PaymentIcons.mastercard,
  },
  {
    id: 12,
    name: 'Menu Ottawa',
    icon: PaymentIcons.menu_ottawa,
  },
  {
    id: 13,
    name: 'Paypal',
    icon: PaymentIcons.paypal,
  },
  {
    id: 14,
    name: 'Skip',
    icon: PaymentIcons.skip,
  },
  {
    id: 15,
    name: 'Sodexo',
    icon: PaymentIcons.sodexo,
  },
  {
    id: 16,
    name: 'Uber',
    icon: PaymentIcons.uber,
  },
  {
    id: 17,
    name: 'Visa',
    icon: PaymentIcons.visa,
  },
  {
    id: 18,
    name: 'Debitor',
    icon: PaymentIcons.debitor,
  },
]

// prettier-ignore
export const PAYMENT_ICON_URL_MAP = {
  [PaymentIcons.amex       ]: AmexUrl      ,
  [PaymentIcons.apple_pay  ]: ApplePayUrl  ,
  [PaymentIcons.black_card ]: BlackCardUrl ,
  [PaymentIcons.card       ]: CardUrl      ,
  [PaymentIcons.cash       ]: CashUrl      ,
  [PaymentIcons.cheque     ]: ChequeUrl    ,
  [PaymentIcons.debit_card ]: DebitCardUrl ,
  [PaymentIcons.door_dash  ]: DoordashUrl  ,
  [PaymentIcons.gift_card  ]: GiftCardUrl  ,
  [PaymentIcons.google_pay ]: GooglePayUrl ,
  [PaymentIcons.interac    ]: InteracUrl   ,
  [PaymentIcons.mastercard ]: MastercardUrl,
  [PaymentIcons.menu_ottawa]: MenuOttawaUrl,
  [PaymentIcons.paypal     ]: PaypalUrl    ,
  [PaymentIcons.skip       ]: SkipUrl      ,
  [PaymentIcons.sodexo     ]: SodexoUrl    ,
  [PaymentIcons.uber       ]: UberUrl      ,
  [PaymentIcons.visa       ]: VisaUrl      ,
  [PaymentIcons.debitor    ]: DebitorUrl   ,
}

export function getPaymentIcon(icon: string | undefined): string | undefined {
  // console.log('Getting payment icon for', icon)
  if (!icon) return
  if (icon.startsWith('http://') || icon.startsWith('https://') || icon.startsWith('data:') || icon.startsWith('blob:')) {
    // For development, we need to replace the localhost URL with the local path
    if (import.meta.env.DEV) return icon.replace('http://localhost:8888/', '/src/assets/payments/')
    return icon
  } else {
    return PAYMENT_ICON_URL_MAP[icon as PaymentIcons]
  }
}
