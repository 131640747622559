import EditRoomTableItem from '@edit-room/EditRoomTableItem.tsx'
import { useResizeObserver } from '@react-hookz/web'
import { type PointerEvent as RPointerEvent, useEffect, useMemo, useRef, useState } from 'react'

import ResizeUrl from '@/assets/resize.png'
import type { Room, RoomObject } from '@/data/Room'
import { useSelector } from '@/react/core/reactive'
import { roomObject0, setRoomObject0 } from '@/react/EditRoomView/EditRoomView.tsx'
import DraggableResizableRectangle from '@/react/FloorPlanView/DraggableResizableRectangle'

import { getRoomHeight, getRoomWidth, getValidZoom } from './floor-plan-utils'
import { zoom } from './FloorPlanView'

//this is just used for edit room view

export function RenderRoom({ room }: { room?: Room }) {
  const containerRef = useRef<HTMLDivElement>(null)
  const roomSize = useMemo(() => ({ w: getRoomWidth(room), h: getRoomHeight(room) }), [room])
  const [containerSize, setContainerSize] = useState({ w: 0, h: 0 })
  const defaultZoom = useMemo(() => Math.min(getValidZoom(containerSize.w / roomSize.w), getValidZoom(containerSize.h / roomSize.h)), [containerSize, roomSize])

  useResizeObserver(containerRef, e => {
    const w = e.target.clientWidth
    const h = e.target.clientHeight
    if (!w || !h) return
    setContainerSize({ w, h })
  })

  return (
    <div
      ref={containerRef}
      className="absolute inset-0"
    >
      {room?.roomObjects?.map(object => (
        <RenderObject
          key={object._id}
          object={object}
          zoom={zoom()}
          defaultZoom={defaultZoom}
        />
      ))}
    </div>
  )
}

type ResizeIconProps = {
  onResizeMouseDown: (e: RPointerEvent<HTMLImageElement>) => void
  onResizeTouchStart: () => void
}

const ResizeIcon = ({ onResizeMouseDown, onResizeTouchStart }: ResizeIconProps) => {
  const imgRef = useRef<HTMLImageElement | null>(null)
  useEffect(() => {
    imgRef.current?.addEventListener('touchstart', onResizeTouchStart, { passive: false })
  }, [])

  return (
    <img
      src={ResizeUrl}
      className="h-[16px] w-[16px] absolute top-[100%] left-[100%] max-w-none"
      draggable={false}
      onMouseDown={onResizeMouseDown}
      ref={imgRef}
      alt="resize icon"
    />
  )
}

// Default radius (if not set) is 4px
export const objectBorderRadiusConstructor = (object: RoomObject, zoom: number, defaultZoom: number) => {
  if (object?.shape === 'circle') return { borderRadius: '9999px' }

  return {
    borderTopLeftRadius: (object?.borderRadius?.topLeft ?? 4) * zoom * defaultZoom,
    borderTopRightRadius: (object?.borderRadius?.topRight ?? 4) * zoom * defaultZoom,
    borderBottomLeftRadius: (object?.borderRadius?.bottomLeft ?? 4) * zoom * defaultZoom,
    borderBottomRightRadius: (object?.borderRadius?.bottomRight ?? 4) * zoom * defaultZoom,
  }
}

export function RenderObject({ object, zoom, defaultZoom }: { object: RoomObject; zoom: number; defaultZoom: number }) {
  const isActiveRoomObject = useSelector<RoomObject | undefined, string>(roomObject0, (obj: RoomObject | undefined, _id: string) => _id === obj?._id)

  const active = isActiveRoomObject(object._id ?? '')

  return (
    <DraggableResizableRectangle
      key={`${object._id}-${zoom}-${defaultZoom}`} // Force rerender when zoom level changed
      className="flex items-center justify-center select-none"
      width={(object.size?.width ?? 0) * zoom * defaultZoom}
      height={(object.size?.height ?? 0) * zoom * defaultZoom}
      top={(object.location?.y ?? 0) * zoom * defaultZoom}
      left={(object.location?.x ?? 0) * zoom * defaultZoom}
      setWidth={width => object.size && (object.size.width = width / (zoom * defaultZoom))}
      setHeight={height => object.size && (object.size.height = height / (zoom * defaultZoom))}
      setTop={top => object.location && (object.location.y = top / (zoom * defaultZoom))}
      setLeft={left => object.location && (object.location.x = left / (zoom * defaultZoom))}
      style={{
        border: `${active ? '2.5px solid #e53b3b' : ''}`,
        opacity: object.isTransparent ? 0.5 : 1,
        backgroundColor: object.bgColor,
        ...objectBorderRadiusConstructor(object, zoom, defaultZoom),
      }}
      active={active}
      setActive={() => {
        console.log('setActive', active)
        if (!active) {
          setRoomObject0(object)
        }
      }}
      resizeIcon={(onResizeMouseDown, onResizeTouchStart) => (
        <ResizeIcon
          onResizeMouseDown={onResizeMouseDown}
          onResizeTouchStart={onResizeTouchStart}
        />
      )}
    >
      {object.type === 'table' ? (
        <EditRoomTableItem
          tableNumber={object.name}
          isSelected={false}
          isMyTable={false}
          isBooked={false}
        />
      ) : (
        <div className="text-black overflow-hidden">{object.name}</div>
      )}
    </DraggableResizableRectangle>
  )
}
