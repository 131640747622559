import { FormControlLabel, Switch } from '@mui/material'

import { isQuebecSrmEnabled } from '@/data/PosSettingsSignal.ts'
import { LL0 } from '@/react/core/I18nService.tsx'
import { onSetSrmEnabled } from '@/react/GeneralSettingsView/GeneralSettingsView.tsx'
import SettingsFieldset from '@/srm/components/SettingsFieldset.tsx'

export function InstallationSrmTab() {
  return (
    <div className="flex flex-col gap-4">
      <div className="self-stretch h-6 flex flex-row items-center justify-start gap-[4px]">
        <div className="w-12 relative h-6">
          <FormControlLabel
            label=""
            className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
            control={
              <Switch
                color="primary"
                size="medium"
                checked={isQuebecSrmEnabled() ?? false}
                onChange={(e, checked: boolean) => onSetSrmEnabled(checked)}
              />
            }
          />
        </div>
        <div className="flex-1 relative">{LL0().settings.generalSetting.quebecSrmEnable()}</div>
      </div>
      <SettingsFieldset />
    </div>
  )
}
