import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Button } from '@mui/material'
import type { FunctionComponent } from 'react'

import { makeLicenseExpired } from '@/data/LicenseHub'

import SrmSetupDialog, { showSrmSetupDialog } from './SrmSetupDialog'

export const SrmUtilities: FunctionComponent = () => (
  <div className="grid gap-2 items-center">
    <Button
      onClick={showSrmSetupDialog}
      data-testid="srmSettings.testcaseSetup"
      endIcon={<OpenInNewIcon />}
    >
      Testcase Setup
    </Button>
    <hr />
    <Button
      variant={'contained'}
      size={'medium'}
      onClick={makeLicenseExpired}
    >
      Make license expired
    </Button>
    <SrmSetupDialog />
  </div>
)
