import type { PosSrmSetting } from '@/data/PosSetting'
import { posSetting0 } from '@/data/PosSettingsSignal'
import { computed, deepSignal, type Accessor } from '@/react/core/reactive'
import { REGISTERED_VALUES } from '@/srm/lib/constants'

export const srmSetting = computed(() => posSetting0()?.srm)

const activePreset: Accessor<string | undefined> = computed(() => {
  const currentSetting = srmSetting()
  if (!currentSetting) return

  for (const p of Object.keys(REGISTERED_VALUES)) {
    const configPreset = REGISTERED_VALUES[p as keyof typeof REGISTERED_VALUES]
    if (!configPreset) continue

    if (
      currentSetting.productId === configPreset.IDSEV &&
      currentSetting.productVersionId === configPreset.IDVERSI &&
      currentSetting.partnerId === configPreset.IDPARTN &&
      currentSetting.version === configPreset.version &&
      currentSetting.previousVersion === configPreset.previousVersion
    )
      return p
  }
})

export { activePreset }

export function onChanged(key: keyof PosSrmSetting, val: string) {
  const currentVal = srmSetting()?.[key]
  const posSetting = posSetting0()
  if (val !== currentVal && !!posSetting) {
    let srm = posSetting.srm
    if (!srm) srm = deepSignal({})

    if (key === 'env') {
      if (val === 'ESSAI' || val === 'PROD') srm[key] = val
      else srm[key] = 'DEV'
    } else srm[key] = val

    posSetting.srm = srm
  }
}

export async function handlePresetChanged(e: React.MouseEvent, val: string) {
  const config = REGISTERED_VALUES[val as keyof typeof REGISTERED_VALUES]
  const doc = posSetting0()?.doc
  if (!config || !doc) return

  await doc.incrementalUpdate({
    $set: {
      'srm.productId': config.IDSEV,
      'srm.productVersionId': config.IDVERSI,
      'srm.partnerId': config.IDPARTN,
      'srm.version': config.version,
      'srm.previousVersion': config.previousVersion,
    },
  })
}
