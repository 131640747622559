import { type FunctionComponent, useCallback } from "react";
import { useSignal } from "@/react/core/reactive.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import { setServer } from "@/data/PosSettingHub.ts";
import _ from "lodash";
import { posSetting0 } from "@/data/PosSettingsSignal.ts";
import { server } from "@/data/Server.ts";
import { availableServers } from "@/react/CompanyInformationView/companyInfomation.logic.ts";

type PasscodeScreenChooseServerType = {
  onClose?: () => void;
};

const PasscodeScreenChooseServer: FunctionComponent<
  PasscodeScreenChooseServerType
> = ({ onClose }) => {
  const [tempServer, setTempServer] = useSignal<string>(server())
  const finalSetServer = useCallback(async ( ) => {
    if (tempServer()) {
      await setServer(tempServer())
      _.assign(posSetting0(), { server: tempServer() })
      localStorage.setItem('clear-print-cache','true');
      location.reload()
    }
    if (onClose) onClose()
  }, [])

  return (
    <div className="relative rounded bg-white-solid-white-100-ffffff flex flex-col items-center justify-start py-6 px-8 box-border gap-[24px] h-full w-full max-w-full max-h-full overflow-auto text-left text-base text-black-solid-black-400-424242 font-mulish">
      <div className="self-stretch relative font-semibold">
        {LL0().login.chooseOneOfServer()}:
      </div>
      <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[12px_16px] text-mini text-gray-solid-gray-920-4e4e4e">
        {availableServers().map(key =>
          <div key={key} className="cursor-pointer rounded-10xs bg-gray-solid-gray-130-f4f4f4 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] overflow-hidden flex flex-row items-center justify-center py-[10.5px] px-[13.5px] relative gap-[10px] border-[0.5px] border-solid border-gray-solid-gray-400-c9cbcc"
            onClick={() => setTempServer(key)}>
            {tempServer() === key ? <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff z-[0]" /> : null}
            <div className="relative z-[1] capitalize">{key}</div>
          </div>
        )}
      </div>

      <div className="self-stretch flex flex-row items-center justify-end gap-[12px] text-center text-sm text-text-color-blue-1271ff">
        <div
          className="cursor-pointer self-stretch rounded-md overflow-hidden flex flex-row items-center justify-center py-[9px] px-8 cursor-pointer border-[1px] border-solid border-text-color-blue-1271ff"
          onClick={onClose}>
          <b className="relative">{LL0().ui.cancel()}</b>
        </div>

        <div className="cursor-pointer self-stretch w-28 rounded-md bg-text-color-blue-1271ff overflow-hidden shrink-0 flex flex-row items-center justify-center py-[9px] px-8 box-border text-white-solid-white-100-ffffff"
          onClick={() => finalSetServer()}>
          <b className="relative">{LL0().ui.save()}</b>
        </div>
      </div>
    </div>
  );
};

export default PasscodeScreenChooseServer;
