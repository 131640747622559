import uuid from "time-uuid";
import _ from "lodash";
import { getDiff } from "recursive-diff";
import { clone } from "rxdb";
import {
  type Accessor, batch,
  onMount,
  type Setter,
  signal,
  useComputed,
  useEffectOn,
  useSelector,
  useSignal,
  useSignalEffect
} from "@/react/core/reactive.ts";
import { Fragment, type PropsWithChildren, useEffect, useMemo } from "react";
import clsx from "clsx";
import { type InputController, inputController0, setInputController0 } from "@/react/core/Input.tsx";

export type TextAreaController = {
  keys: Accessor<Array<string>>,
  setKeys: Setter<Array<string>>,
  focus: Accessor<boolean>,
  setFocus: Setter<boolean>,
  focusLock: Accessor<boolean>,
  setFocusLock: Setter<boolean>,
  caretIndex: Accessor<number>,
  setCaretIndex: Setter<number>,
  addKey: (char: string) => void,
  backspaceHandle: () => void,
  moveCaretToEnd: () => void
  id: string
  setOnChange: Setter<OnChange>
};

export interface TextAreaProps {
  value?: string,
  onChange?: (value: string) => void
  // refTextAreaController?: (i: TextAreaController) => void
  className?: string
  label?: string
  refInputController?: (i: InputController) => void
}

export const [textAreaController0, setTextAreaController0] = signal<TextAreaController>();

type ChunkItem = { key: string, index: number };
type Selecting = {
  start: number,
  end: number,
  selecting: boolean
}
type OnChange = (value: string) => void;

const TextArea = (props: TextAreaProps & PropsWithChildren) => {
  const [keys, setKeys] = useSignal<Array<string>>(props.value?.split("") || []);
  const [caretIndex, setCaretIndex] = useSignal<number>(0);
  let selectingInitValue = { start: -1, end: -1, selecting: false };
  const [selecting, setSelecting] = useSignal<Selecting>(selectingInitValue);
  const selected = useComputed(() => selecting().start !== selecting().end);
  const [focus, setFocus] = useSignal<boolean>(false);
  const [focusV, setFocusV] = useSignal<number>(0);
  const [focusLock, setFocusLock] = useSignal<boolean>(false);
  const id = useMemo(() => uuid(), []);
  const [onChange, setOnChange] = useSignal<OnChange>(undefined as any);
  const [chunkKeys2, setChunkKeys2] = useSignal<Array<Array<ChunkItem>>>([]);

  useEffect(() => {
    props.onChange?.(keys().join(""));
    onChange()?.(keys().join(""));
  }, [keys()]);

  useEffect(() => {
    if (props.hasOwnProperty("value") && props.value !== keys().join("")) {
      setKeys(props.value?.split("") || []);
    }
  }, [props.value]);

  const addKey = (char: string) => {
    setKeys(_keys => {
      if (selected()) {
        const left = _keys.splice(0, Math.min(selecting().start, selecting().end));
        left.push(char);
        _keys.splice(0, Math.max(selecting().start, selecting().end) - Math.min(selecting().start, selecting().end) + 1);
        setCaretIndex(left.length);
        setSelecting(selectingInitValue);
        return [...left, ..._keys];
      }
      const left = _keys.splice(0, caretIndex());
      const right = [..._keys];
      left.push(char);
      setCaretIndex(left.length);
      // debugger
      return [...left, ...right];
    });
  };

  const backspaceHandle = () => {
    setChunkKeys2([]);
    setKeys(_keys => {
      if (selected()) {
        const left = _keys.splice(0, Math.min(selecting().start, selecting().end));
        _keys.splice(0, Math.max(selecting().start, selecting().end) - Math.min(selecting().start, selecting().end) + 1);
        setCaretIndex(left.length);
        setSelecting(selectingInitValue);
        return [...left, ..._keys];
      }
      const left = _keys.splice(0, caretIndex());
      const right = [..._keys];
      left.pop();
      setCaretIndex(left.length);
      return [...left, ...right];
    });
  };
  const moveCaretToEnd = () => {
    setCaretIndex(keys().length);
  };

  const textAreaController = {
    keys, setKeys, focus, setFocus, focusLock,
    setFocusLock, caretIndex, setCaretIndex, addKey,
    backspaceHandle, id, moveCaretToEnd, setOnChange
  };

  // props.refTextAreaController?.(textAreaController);

  const inputController = useMemo(() => {
    const inputController = {
      keys, setKeys, focus, setFocus, focusLock,
      setFocusLock, caretIndex, setCaretIndex, addKey,
      backspaceHandle, id, moveCaretToEnd, setOnChange
    };

    props.refInputController?.(inputController);

    return inputController;
  }, []);

  useSignalEffect(() => {
    if (focus() && inputController0()?.id !== id) {
      inputController0()?.setFocus(false);
      setInputController0(inputController);
    }
  });

  // useSignalEffect(() => {
  // 	if (focus() && textAreaController0()?.id !== id) {
  // 		textAreaController0()?.setFocus(false);
  // 		setTextAreaController0(textAreaController);
  // 	}
  // });

  //todo: clean up
  useEffect(() => {
    const l1 = (event: KeyboardEvent) => {
      if (!focus()) return;
      const key = event.key;
      if (key === "Backspace") {
        backspaceHandle();
        // setCaretIndex(keys().length);
      } else if (key.length === 1) {
        addKey(key);
      } else if (key === "ArrowLeft") {
        setCaretIndex(i => i > 0 ? i - 1 : i);
      } else if (key === "ArrowRight") {
        setCaretIndex(i => i < keys().length ? i + 1 : i);
      } else if (key === "Enter") {
        addKey("\n");
      }

      if (key === " ") event.preventDefault();
    };

    function l2() {
      if (focusLock()) return;
      setFocus(false);
    }

    document.addEventListener("keydown", l1, false);
    document.addEventListener("pointerdown", l2);
    return () => {
      document.removeEventListener("keydown", l1);
      document.removeEventListener("pointerdown", l2);
    }
  }, []);

  function makeFocus() {
    batch(() => {
      setFocus(true);
      setFocusLock(true);
      setFocusV((v: number) => v + 1);
      setTimeout(() => setFocusLock(false), 100);
    })
  }

  function onCharPointerdown(index: number) {
    if (!focus()) makeFocus();
    setCaretIndex(index);
    setSelecting({ start: index, end: index, selecting: true });
  }

  function onCharMove(index: number) {
    if (selecting().selecting) {
      setSelecting(v => ({ ...v, end: index }));
    }
  }


  function onCharPointerup(index: number) {
    if (selecting().selecting) {
      setSelecting(v => ({ ...v, end: index, selecting: false }));
      if (selecting().start !== selecting().end) {
        setCaretIndex(Math.max(selecting().start, selecting().end) + 1);
      }
    }
  }

  const isSelected = useSelector<Selecting, number>(selecting, (index: number, _selecting: any) => {
    if (selecting().start === selecting().end) return false;
    if (selecting().start <= index && selecting().end >= index) {
      return true;
    } else if (selecting().start >= index && selecting().end <= index) {
      return true;
    }
    return false;
  });

  //performance problem ??

  return <fieldset
    className={clsx("h-[100px] w-[240px] px-[4px] pt-[1px] pb-[5px] flex rounded-[5px] overflow-y-auto bg-white border border-[#838383] relative select-none",
      focus() && ' !border-[#1976d2] border-[2px]',
      props.className)}
    onPointerDown={(e) => {
      // e.stopPropagation();
      makeFocus();
    }}>
    <legend className={clsx(props.label && 'px-1')}>{props.label}</legend>
    <div className={"no-scrollbar flex-1 overflow-y-auto overflow-x-hidden pl-[2px]"} onPointerDown={() => setCaretIndex(keys().length)}>
      <div className={""}>
        {keys().map((key, index) => (
          <Fragment key={index}>
						<span className={clsx("w-[0] h-[14px] inline-block relative")}>
							{caretIndex() === index && focus() &&
                <div className={"absolute inline-block w-[1px] h-[14px] bg-black"} />}
              {key === "\n" &&
                <div className={"absolute inline-block h-[14px] w-[240px]"}
                     onPointerDown={(e) => (e.stopPropagation(), onCharPointerdown(index))}
                />}
						</span>

            {key === "\n" ? <br /> : (
              <span
                className={clsx(
                  "ml-[-1px]"
                  // isSelected(index) ? "bg-[#3487e7]" : ""
                )}
                onPointerOver={() => onCharMove(index)}
                onPointerDown={(e) => (e.stopPropagation(), onCharPointerdown(index))}
                onPointerUp={() => onCharPointerup(index)}>
											{key}
										</span>
            )}
          </Fragment>
        ))}

        {caretIndex() === keys().length && focus() &&
          <span className={"w-[1px] h-[14px] bg-black inline-block animate-blink blink-caret"} />}
      </div>
    </div>
    <div className={clsx("h-full flex flex-row items-center", props.label && ' pb-2')}>
      {props.children}
    </div>
  </fieldset>;
};
export default TextArea;
