import MultiAwaitLock from '@/shared/MultiAwaitLock'

import type { Identify } from "@/react/websocket/ws-register.ts";

export const secondDisplayLock = new MultiAwaitLock(true)
export const wsLock = new MultiAwaitLock(true)
export const deviceIdentifier: { v?: Identify } = {};
export const wsOpenLock = new MultiAwaitLock(true)

export const setDeviceIdentifier = (ns: Identify) => (deviceIdentifier.v = ns)
