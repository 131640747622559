import { order0 } from "@/react/OrderView/OrderViewShare.ts";
import type { Product } from "@/data/Product.ts";
import { computed } from "@/react/core/reactive.ts";
import { multipleMenus0 } from "@/react/EditMenuView/EditPricingLevel.logic.ts";
import { dineInTaxCategories0, takeAwayTaxCategories0, taxCategories0 } from "@/data/TaxCategoryHub.ts";
import _ from "lodash";
import type { ModifierItem } from "@/data/Modifier.ts";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { log } from "debug";

export function orderPricingLevelFactory() {
  return {
    isNotDineInMenu,
    getPrice,
    getMultipleTaxes,
    computedMenuName,
    getPricingLevelTaxComponent,
    getMultipleTaxesComponent,
    handleChangeMenu,
    handleSetSelected,
    getPricingModifierItem
  };

  function isNotDineInMenu() {
    const multipleMenus = generalSetting0()?.multipleDineInMenusEnable;
    if (!multipleMenus) return false;
    if (multipleMenus && order0()) {
      const isExistMultiplePrice = order0()?.multiplePriceMenu;
      if (!isExistMultiplePrice) {
        return false;
      } else {
        return true;
      }
    }
  }

  function getPrice(product: Product) {
    return computed(() => {
      let price = product.price;
      if (isNotDineInMenu()) {
        if (!product.menuPrices) return +price!;
        const menuPrice = product.menuPrices!.find(m => m.menu === order0().multiplePriceMenu);
        if (menuPrice) {
          price = menuPrice.value;
        }
      }
      return +price!;
    });
  }

  function getMultipleTaxes(product: Product): any {
    return computed(() => {
      let taxes = [product.tax, product.tax2];
      if (isNotDineInMenu()) {
        if (!product.menuTaxes && !product.menuTaxes2) return taxes;
        const tax1 = product.menuTaxes?.find(m => m.menu === order0().multiplePriceMenu)?.value;
        const tax2 = product.menuTaxes2?.find(m => m.menu === order0().multiplePriceMenu)?.value;
        if (tax1 !== undefined && tax2 !== undefined && generalSetting0()?.taxType === 'one') {
          taxes = [tax1, tax2];
        }
        if (tax2 !== undefined && generalSetting0()?.taxType === 'two') {
          taxes = [tax1, tax2];
        }
      }
      return taxes;
    });
  }

  function computedMenuName() {
    let menu;
    if (!order0().multiplePriceMenu) {
      menu = multipleMenus0()?.[0]?.name;
    } else {
      menu = multipleMenus0()?.find(m => m._id === order0()?.multiplePriceMenu)?.name;
    }
    return menu;
  }

  function getPricingLevelTaxComponent(product: Product, option: number): any {
    return {
      productTaxComponents: option === 1 ? product?.taxComponents : product?.taxComponents2,
      pricingLevelTax: option === 1 ? product?.menuTaxes : product?.menuTaxes2,
      taxCategoryByType: option === 1 ? dineInTaxCategories0() : takeAwayTaxCategories0(),
    };
  }

  function getMultipleTaxesComponent(product: Product, option: number): any {
    return computed(() => {
      const taxResult = getPricingLevelTaxComponent(product, option);
      let taxComponents = taxResult?.productTaxComponents;
      let taxCategory: any;
      let taxType: any;

      if (isNotDineInMenu()) {
        if (!taxResult?.pricingLevelTax) {
          log(`lack pricing level tax for product ${product?.name} _id ${product?._id}`, {orderId: order0()?._id})
          return taxComponents
        }
        const taxName = taxResult?.pricingLevelTax?.find((m: any) => m.menu === order0().multiplePriceMenu)?.ref;
        //handle if user don't choose tax for pricing level menu, return product.taxComponents

        if (!taxName) {
          log(`lack tax for product ${product?.name} _id ${product?._id} in menu ${order0().multiplePriceMenu}`, {orderId: order0()?._id})
          return taxComponents;
        }

        taxCategory = generalSetting0()?.taxType === 'two'
          ? taxResult?.taxCategoryByType?.find((t: any) => t.name === taxName)
          : taxCategories0()?.find((t: any) => t.name === taxName);

        taxType = taxCategory?.type;

        taxComponents = taxType === 'combo'
          ? taxCategory?.components as typeof taxComponents
          : undefined;
      }

      return taxComponents;
    });
  }

  function handleChangeMenu(menu: string): void {
    if (!isNotDineInMenu()) {
      _.assign(order0(), { multiplePriceMenu: menu });
    } else if (menu === multipleMenus0()?.[0]?._id) {
      _.unset(order0(), 'multiplePriceMenu');
    } else {
      _.assign(order0(), { multiplePriceMenu: menu });
    }
  }

  function handleSetSelected() {
    let menuValue: string;
    if (!order0().multiplePriceMenu) {
      menuValue = multipleMenus0()?.[0]?._id as string
    } else {
      menuValue = order0().multiplePriceMenu as string;
    }
    return multipleMenus0()?.findIndex(menu => menu._id === menuValue);
  }

  function getPricingModifierItem(item: ModifierItem): number {
    if (!item) return 0;
    if (!generalSetting0().multipleDineInMenusEnable || !order0().multiplePriceMenu) {
      return item.price || 0;
    }
    return item?.menuPrices?.find(m => m.menu === order0().multiplePriceMenu)?.value ?? 0
  }

}


// handleChangeMenu
// const getModifierPrice = (item: ModifierItem) => {
//   let itemPrice;
//   if (!generalSetting0().multipleDineInMenusEnable || !order0().multiplePriceMenu) {
//     itemPrice = item.price
//   } else {
//     itemPrice = item.menuPrices?.find(m => m.menu === order0().multiplePriceMenu)?.value;
//   }
//   return itemPrice
// }
//
// const {seat} = useContext(ItemFactoryContext)
//
//
// const changePriceLevel = async() => {
//   handleChangeMenu(priceLevelMenu);
//   const addProductCommits = order0().commits?.filter(c => c.action === CommitAction.ADD_PRODUCT) as ItemCommitAddProduct[];
//   const items = order0().items;
//   for (const commit of addProductCommits!) {
//     const product = await Product.findOne({ selector: { _id: commit.ref } }).exec();
//     // const productItem = items.find(item => item!.commitRefs?.includes(commit._id));
//     const productItem = items.find(item => item!._id === commit._id);
//     const productQuantity = productItem?.quantity;
//
//     if (productItem?.printed && !getAccessibility(AccessPort.DELETE_PRINTED_ITEM)) return;
//     const extra = seat?.() || 0;
//     order0().commits!.push({
//       action: CommitAction.CHANGE_QUANTITY, commitId: productItem!.commitRefs![0], quantityDelta: -productQuantity!, ...extra
//     });
//
//
//   }
//
//
//   const addModifierCommits = order0().commits?.filter(c => c.action === CommitAction.ADD_MODIFIER_TO_LAST_ITEM) as ItemCommitAddModifier[];
//   const _modifiers = await Modifier.find().exec();
//   const modifiers = convertDocuments(_modifiers, true)
//   for (const modifierCommit of addModifierCommits) {
//
//   }
//
//
//   // const addModifierCommits = order0().commits?.filter(c => c.action === CommitAction.ADD_MODIFIER_TO_LAST_ITEM) as ItemCommitAddModifier[];
//   // const _modifiers = await Modifier.find().exec();
//   // const modifiers = convertDocuments(_modifiers, true)
//   // for (const modifierCommit of addModifierCommits) {
//   //   modifiers?.forEach(modifier => {
//   //     const item = modifier?.items?.find(item => item._id === modifierCommit.ref);
//   //     if (item) {
//   //       // const itemPrice = modifier!.items.find(i => i._id = item._id)
//   //       _.assign(modifierCommit.productRef, {price: getModifierPrice(item)})
//   //     }
//   //     const itemIndex = items.findIndex(item => item.modifiers.some(subItem => subItem.commitRef === modifierCommit._id));
//   //     if (itemIndex) {
//   //       const modifierItemIndex = items[itemIndex].modifiers.findIndex(subItem => subItem.commitRef === modifierCommit._id);
//   //       const modifierItem = items[itemIndex].modifiers[modifierItemIndex];
//   //       // const modifierItem = convertDocument(_modifierItem, true, ['menuPrices, menuTaxes']);
//   //       // _.assign(modifierItem, {price: getModifierPrice(itemPrice!)()})
//   //     }
//   //   })
//   // }
//   setIsShowChangePriceLv(false);
//   setEditMenuChangeMenuPopupOpen(false)
// }

