import type { FunctionComponent } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import KitchenMonitorItem from "./KitchenMonitorItem";

type KitchenMonitorCardDetailPType = {
  onClose?: () => void;
};

const KitchenMonitorCardDetailP: FunctionComponent<
  KitchenMonitorCardDetailPType
> = ({ onClose }) => {
  return (
    <div className="bg-white-solid-white-100-ffffff w-full overflow-hidden flex flex-col items-start justify-start pt-8 px-6 pb-6 box-border relative gap-[16px] h-full max-w-full max-h-full text-left text-smi text-black-solid-black-1000-000000 font-mulish sm:gap-[12px] sm:pl-4 sm:pr-4 sm:pb-4 sm:box-border Tablet_768:gap-[10px] Tablet_768:pl-3 Tablet_768:pr-3 Tablet_768:pb-3 Tablet_768:box-border Tablet_600:gap-[6px] Tablet_600:pl-2.5 Tablet_600:pr-2.5 Tablet_600:pb-2.5 Tablet_600:box-border">
      <div
        className="absolute my-0 mx-[!important] top-[12px] right-[12px] w-6 h-6 overflow-hidden shrink-0 cursor-pointer z-[0]"
        onClick={onClose}
      >
        <img
          className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
          alt=""
          src="/icongeneralclose-popup-icon--grey@2x.png"
        />
      </div>
      <div className="self-stretch flex flex-col items-start justify-start pt-3 px-0 pb-2 gap-[4px] z-[1] border-b-[1px] border-solid border-gray-solid-gray-300-dbdbdb height_414:gap-[0px]">
        <div className="self-stretch flex flex-row items-start justify-between text-mini">
          <div className="w-[33px] flex flex-row items-center justify-start gap-[4px]">
            <div className="relative font-semibold hidden">New</div>
            <div className="relative font-semibold hidden">Preparing</div>
            <div className="relative font-semibold">Done</div>
          </div>
          <div className="relative font-semibold text-right">1 mins</div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="relative">Table no. 3</div>
          <div className="flex flex-row items-center justify-start gap-[3px] text-right">
            <img
              className="relative w-[18px] h-[18px] object-cover"
              alt=""
              src="/iconclock-icon@2x.png"
            />
            <div className="relative">3:00 PM</div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-between">
          <i className="relative">#123454</i>
          <i className="relative text-right">Jennifer</i>
        </div>
      </div>
      <div className="self-stretch relative h-[184px] overflow-y-auto no-scrollbar shrink-0 grid grid-rows-[repeat(4,1fr)] grid-cols-[repeat(3,1fr)] [grid-row-gap:8px] [grid-column-gap:8px] z-[2]">
        <KitchenMonitorItem
          quantity={3}
          itemName="Chicken sohhup"
          modifiers="(khong hanh, them thit)"
        />
        <KitchenMonitorItem
          quantity={3}
          itemName="Chicken sohhup"
          modifiers="(khong hanh, them thit)"
        />
        <KitchenMonitorItem
          quantity={3}
          itemName="Chicken sohhup"
          modifiers="(khong hanh, them thit)"
        />
        <KitchenMonitorItem
          quantity={3}
          itemName="Chicken sohhup"
          modifiers="(khong hanh, them thit)"
        />
        <KitchenMonitorItem
          quantity={3}
          itemName="Chicken sohhup"
          modifiers="(khong hanh, them thit)"
        />
        <KitchenMonitorItem
          quantity={3}
          itemName="Chicken sohhup"
          modifiers="(khong hanh, them thit)"
        />
        <KitchenMonitorItem
          quantity={3}
          itemName="Chicken sohhup"
          modifiers="(khong hanh, them thit)"
        />
        <KitchenMonitorItem
          quantity={3}
          itemName="Chicken sohhup"
          modifiers="(khong hanh, them thit)"
        />
        <KitchenMonitorItem
          quantity={3}
          itemName="Chicken sohhup"
          modifiers="(khong hanh, them thit)"
        />
        <KitchenMonitorItem
          quantity={3}
          itemName="Chicken sohhup"
          modifiers="(khong hanh, them thit)"
        />
        <KitchenMonitorItem
          quantity={3}
          itemName="Chicken sohhup"
          modifiers="(khong hanh, them thit)"
        />
        <KitchenMonitorItem
          quantity={3}
          itemName="Chicken sohhup"
          modifiers="(khong hanh, them thit)"
        />
      </div>
      <div className="self-stretch flex flex-row items-start justify-end pt-1 px-0 pb-0 gap-[16px] z-[3] text-center text-mini">
        <div className="flex-1 rounded bg-orange-solid-orange-400-ffa06a h-9 hidden flex-row items-center justify-center py-0 px-2 box-border max-w-[200px] min-h-[36px]">
          <div className="relative font-semibold">Start</div>
        </div>
        <div className="flex-1 rounded bg-green-solid-green-420-a9e15d h-9 hidden flex-row items-center justify-start py-0 px-2 box-border relative max-w-[200px] min-h-[36px]">
          <img
            className="relative w-6 h-6 object-cover z-[0]"
            alt=""
            src="/iconcheckmark-icon@2x.png"
          />
          <div className="absolute my-0 mx-[!important] top-[calc(50%_-_9.5px)] left-[calc(50%_-_19px)] font-semibold z-[1]">
            Done
          </div>
        </div>
        <div className="flex-1 rounded bg-purple-solid-purple-200-c0c0ff h-9 flex flex-row items-center justify-start py-0 px-2 box-border relative max-w-[200px] min-h-[36px]">
          <img
            className="relative w-6 h-6 object-cover z-[0]"
            alt=""
            src="/iconarchived-icon@2x.png"
          />
          <div className="absolute my-0 mx-[!important] top-[calc(50%_-_9.5px)] left-[calc(50%_-_31px)] font-semibold z-[1]">
            Archived
          </div>
        </div>
      </div>
    </div>
  );
};

export default KitchenMonitorCardDetailP;
