import { type FunctionComponent, useEffect, useState } from 'react';
import { LL0 } from '@/react/core/I18nService.tsx';
import type { RxDocument } from 'rxdb';
import type { Call } from '@/data/Call.ts';
import type { Customer } from '@/data/Customer.ts';
import dayjs from 'dayjs';
import { onAcceptCall, onAcceptOrderCall, onAcceptReservationCall } from '@/react/NewDeliveryView/NewDeliveryView.tsx';
import { OrderType } from '@/pos/OrderType.ts';
import { makeRipple } from '@/react/core/ripple-utils.ts';

type PendingOrdersOrderType = {
  customerSName?: string;
  customerPhoneNumber?: string;
  orderStatus?: string;
  callTime?: string;
  customerNote?: string;
  showDismissAction?: boolean;
  showOrderActions?: boolean;
  showCallTime?: boolean;
  showCustomerNote?: boolean;
  showMissedCalls?: boolean;
  showIconCallIcon?: boolean;
  showIconCancelCall?: boolean;
  showButtonText?: boolean;

  incomingCall: RxDocument<Call, {}>
};

const PendingOrdersOrder: FunctionComponent<PendingOrdersOrderType> = ({
  customerSName = "New customer",
  customerPhoneNumber = "098765432",
  orderStatus = "Incoming call",
  callTime = "15 min ago",
  customerNote = "Không ăn được cay, không hành lá, lấy hành phi, lấy thêm tương ớt, thêm bánh phở",
  showDismissAction = true,
  showOrderActions = true,
  showCallTime = true,
  showCustomerNote = true,
  showMissedCalls,
  showIconCallIcon,
  showIconCancelCall,
  showButtonText,
  incomingCall
}) => {
  const [customer, setCustomer] = useState<RxDocument<Customer, {}> | undefined>();
  useEffect(() => {
    const fetchCustomer = async () => {
      setCustomer(await  incomingCall.populate("customerId"))
    }
    fetchCustomer();
  }, []);

  customerSName = customer?.name || "";
  customerPhoneNumber = incomingCall.phoneNumber || "";
  callTime = dayjs().diff(dayjs.unix(incomingCall.date || 0), "minutes") + " " + LL0().pendingOrder.minAgo();
  customerNote = customer?.note || ""


  return (
    <div className="rounded bg-basic-color-white box-border flex flex-col items-center justify-center py-2.5 px-4 relative gap-[4px] text-left text-sm text-black font-mulish self-stretch border-[0.4px] border-solid border-materialize-grey-grey Mobile_480:grid-cols-[Repeat_(3,_2fr)]">
      {showMissedCalls && (
        <div className="absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2px)] top-[-1px] right-[-1px] bottom-[-1px] left-[-1px] rounded box-border z-[0] border-[2px] border-solid border-tomato-300" />
      )}
      <div className="self-stretch flex flex-row items-start justify-between pt-0 px-0 pb-2 z-[1] border-b-[1px] border-solid border-grey-grey-lighten-2">
        <div className="flex-1 flex flex-col items-start justify-start py-0 pr-1 pl-0 gap-[4px]">
          <div className="self-stretch flex flex-row items-start justify-start gap-[2px]">
            <b className="relative leading-[15px]">{customerSName}</b>
            <b className="relative leading-[15px]">-</b>
            <b className="relative leading-[15px]">{customerPhoneNumber}</b>
          </div>
          <div className="self-stretch flex flex-row items-center justify-start gap-[2px] text-smi text-grey-grey-darken-1">
            <div className="relative leading-[117.4%]">{orderStatus}</div>
            {showCallTime && (
              <div className="flex flex-row items-center justify-start gap-[2px]">
                <div className="relative leading-[117.4%]">-</div>
                <div className="relative leading-[117.4%]">{callTime}</div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row items-center justify-start gap-[8px]">
          {showIconCallIcon && (
            <img
              className="relative w-5 h-5 object-cover"
              alt=""
              src="/iconcall-icon1@2x.png"
            />
          )}
          {showIconCancelCall && (
            <img
              className="relative w-5 h-5 object-cover"
              alt=""
              src="/iconcancel-call@2x.png"
            />
          )}
        </div>
      </div>
      {showCustomerNote && (
        <div className="self-stretch flex flex-row items-start justify-between pt-0 px-0 pb-2 z-[2] text-smi border-b-[1px] border-solid border-grey-grey-lighten-2">
          <div className="flex-1 relative leading-[120%]">{customerNote}</div>
        </div>
      )}
      {showOrderActions && (
        <div className="self-stretch flex flex-row items-center justify-center py-1 px-0 gap-[16px] z-[3] text-xs Tablet_768:gap-[8px] Tablet_768:pl-0 Tablet_768:box-border Tablet_768:grid Tablet_768:grid-cols-[repeat(2,_1fr)] Mobile_480:gap-[12px] Mobile_480:pl-0 Mobile_480:box-border Mobile_480:grid Mobile_480:grid-cols-[Repeat(2,_1fr)] Mobile_480:[row-gap:8px] Mobile_480:[column-gap:8px]">
          <div className="flex-1 rounded-sm box-border flex flex-col items-center justify-center py-2.5 px-6 gap-[2px] min-w-[47px] border-[1px] border-solid border-grey-grey-lighten-2"
               onClick={() => onAcceptCall(incomingCall)}
          >
            <img
              className="relative w-6 h-6 object-cover"
              alt=""
              src="/iconcancel-order-icon2@2x.png"
            />
            <div className="relative font-medium">{LL0().ui.delete()}</div>
          </div>
          <div className="flex-1 rounded-sm box-border flex flex-col items-center justify-center py-2.5 px-6 gap-[2px] min-w-[47px] border-[1px] border-solid border-grey-grey-lighten-2"
               ref={makeRipple}
               onClick={() => onAcceptReservationCall(incomingCall, customer)}
          >
            <img
              className="relative w-6 h-6 object-cover"
              alt=""
              src="/iconorder-already-icon1@2x.png"
            />
            <div className="relative font-medium">{LL0().pendingOrder.reserve()}</div>
          </div>
          <div className="flex-1 rounded-sm box-border flex flex-col items-center justify-center py-2.5 px-6 gap-[2px] min-w-[47px] border-[1px] border-solid border-grey-grey-lighten-2"
               onClick={() => onAcceptOrderCall(incomingCall, customer, OrderType.PickUp)}
          >
            <img
              className="relative w-6 h-6 object-cover"
              alt=""
              src="/icontakeaway-icon@2x.png"
            />
            <div className="relative font-medium">{LL0().report.takeOut()}</div>
          </div>
          <div className="flex-1 rounded-sm box-border flex flex-col items-center justify-center py-2.5 px-6 gap-[2px] min-w-[47px] border-[1px] border-solid border-grey-grey-lighten-2"
               onClick={() => onAcceptOrderCall(incomingCall, customer, OrderType.Delivery)}
          >
            <img
              className="relative w-6 h-6 object-cover"
              alt=""
              src="/icondeliver-icon@2x.png"
            />
            <div className="relative font-medium">{LL0().dashboard.delivery()}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PendingOrdersOrder;
