import { type FunctionComponent, useCallback, useState } from 'react';
import { clsx } from 'clsx';
import { kitchenGroupPrinters0 } from '@/data/GroupPrinterHub.ts';
import {
  createNewGroupPrinter, defaultPrinterTaxes,
  deleteCurrentGroupPrinter,
  isSelectedGroupPrinter,
  PrinterMode,
  printerSettingMode,
  setCurrentPrinterIndex,
  setPrinterSettingMode,
  setSelectedGroupPrinterId
} from '@/react/Printer/PrinterSettingView.tsx';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { Button, FormControl, MenuItem, Select } from '@mui/material';
import { LL0 } from '@/react/core/I18nService.tsx';
import PortalPopup from './PortalPopup';
import DeleteConfirmationPopup from '@printer-setting/DeleteConfirmationPopup.tsx';
import PrinterSettingSelectTaxCat from '@printer-setting/PrinterSettingSelectTaxCat.tsx';
import _ from 'lodash';

import { generalSetting0, posSetting0 } from "@/data/PosSettingsSignal.ts";
import type { PrinterGeneralSetting } from "@/data/PosSetting.ts";

type PrinterPrintOrderTicketAccType = {
  showButtonGroup?: boolean;
  isExpanded?: boolean;
  isDefault?: boolean;
  isSelected?: boolean;
};

const PrinterPrintOrderTicketAcc: FunctionComponent<
  PrinterPrintOrderTicketAccType
> = ({
  showButtonGroup = true,
  isExpanded = true,
  isDefault = true,
  isSelected,
}) => {
  const [isAccordionOpened, setIsAccordionOpened] = useState<boolean>(true);
  isDefault = !isAccordionOpened;
  isExpanded = isAccordionOpened;

  const [isPrinterSettingSelectTaxCatOpen, setPrinterSettingSelectTaxCatOpen] =
    useState(false);
  const [isDeleteConfirmationPopupOpen, setDeleteConfirmationPopupOpen] =
    useState(false);

  const closePrinterSettingSelectTaxCat = useCallback(() => {
    setPrinterSettingSelectTaxCatOpen(false);
  }, []);

  const closeDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupOpen(false);
  }, []);

  const handleAddKitchenPrinter = async () => {
    if ((_.keys(defaultPrinterTaxes())?.length || 0) > 0 && generalSetting0()?.taxType === "two") {
      setPrinterSettingSelectTaxCatOpen(true);
      return;
    }
    const additionalOptions = generalSetting0()?.taxType === "one" ? {
      defaultDineInTax: _.values(defaultPrinterTaxes())[0].dineIn,
      defaultTakeAwayTax: _.values(defaultPrinterTaxes())[0].takeAway,
    } : undefined
    await createNewGroupPrinter('kitchen', additionalOptions);
  }

  return (
    <>
      <div className="flex flex-col items-start justify-start gap-[8px_0px] text-left text-sm text-dodgerblue font-mulish self-stretch">
        {isDefault && (
          <div className="relative self-stretch bg-whitesmoke-300 flex flex-row items-center justify-between py-[9px] pr-2 pl-4"
            onPointerDown={() => setIsAccordionOpened(prev => !prev)}
        >
          {/*{isSelected &&*/}
          {/*<div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] [background:linear-gradient(45deg,_#3949ab,_#4fc3f7)] z-[0]" />*/}
            {/*}*/}
            <div className="flex flex-row items-center justify-start gap-[12px]">
              <img
                className="w-5 relative h-5 overflow-hidden shrink-0 object-cover"
                alt=""
                src="/virtual-printer-icon@2x.png"
              />
              <b className="relative">{LL0().sidebar.printerOrderTicketsAt()}</b>
            </div>
            <img
            className="w-5 relative h-5 object-cover"
              alt=""
              src="/icongeneralarrow-right-icon--white@2x.png"
            />
            <img
              className="w-5 relative h-5 object-cover hidden z-[3]"
              alt=""
              src="/iconaccordion-arow-right@2x.png"
            />
          </div>
        )}
        {isExpanded && (
          <div className="self-stretch bg-whitesmoke-200 flex flex-col items-center justify-center gap-[4px] text-darkslategray">
            <div className="relative self-stretch bg-whitesmoke-300 shadow-[0px_0.2px_1.2px_rgba(0,_0,_0,_0.3)] flex flex-row items-center justify-between py-[9px] pr-2 pl-4 text-dodgerblue"
               onPointerDown={() => setIsAccordionOpened(prev => !prev)}
          >
            {/*{isSelected &&*/}
              {/*  <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] [background:linear-gradient(45deg,_#3949ab,_#4fc3f7)] z-[0]" />*/}
              {/*}*/}
            <div className="flex flex-row items-center justify-start gap-[12px]">
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0 object-cover"
                  alt=""
                  src="/virtual-printer-icon@2x.png"
                />
                <b className="relative">{LL0().sidebar.printerOrderTicketsAt()}</b>
              </div>
              <img
                className="w-5 relative h-5 object-cover z-[2]"
                alt=""
                src="/icongeneralarrow-down-icon--white@2x.png"
              />
              <img
                className="w-5 relative h-5 object-cover hidden z-[3]"
                alt=""
                src="/iconaccordion-arow-right@2x.png"
              />
            </div>
            {kitchenGroupPrinters0().map((g) => {
            const isSelected = isSelectedGroupPrinter(g) && printerSettingMode() === PrinterMode.NORMAL

            return (
              <div className={clsx("self-stretch h-10 flex flex-row items-center justify-start py-0 px-2 box-border relative gap-[7px] text-smi",
                isSelected && "text-white")}
                   onPointerDown={() => {
                     setPrinterSettingMode(PrinterMode.NORMAL);
                     setSelectedGroupPrinterId(g._id);
                     setCurrentPrinterIndex(0);
                   }}
                   key={g._id}
              >
                {isSelected &&
                  <div className="!flex absolute my-0 mx-[!important] h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] [background:linear-gradient(45deg,_#3949ab,_#4fc3f7)] hidden z-[0]" />
                }
                <div className="relative rounded-[50%] box-border w-3 h-3 z-[1] border-[1px] border-solid border-text-color-gray-979797" />
                <b className="relative leading-[15px] z-[2] md:text-2xs">{g.name}
              </b>
              </div>
            )
          })}
          <div className="self-stretch overflow-hidden flex flex-col items-center justify-center p-2 text-center text-royalblue-200">
              {showButtonGroup && (
                <div className="self-stretch flex flex-row items-center justify-center gap-[0px_8px]">
                <div className="flex-1 rounded bg-blue-solid-blue-30-e3f2fd flex flex-row items-center justify-center py-[9.5px] px-0 border-[1px] border-dashed border-blue-solid-blue-350-2196f3"
                     ref={makeRipple}
                     onClick={handleAddKitchenPrinter}
                >
                  <b className="relative leading-none">+ {LL0().ui.add()}</b>
                </div>
                {kitchenGroupPrinters0()?.length ?
                <Button className="!flex-1 !rounded !bg-red-solid-red-10-f6d5dd !flex !flex-row !items-center !justify-center !py-[9.5px] !px-0 !text-red-solid-red-300-ff4836 !border-[1px] !border-dashed !border-red-solid-red-300-ff4836"
                        ref={makeRipple}
                        onClick={() => setDeleteConfirmationPopupOpen(true)}
                        disabled={printerSettingMode() !== PrinterMode.NORMAL}
                        sx={{textTransform: "capitalize", fontFamily: "inherit"}}
                >
                  <b className="relative text-sm leading-none">{LL0().ui.delete()}</b>
                </Button>
                  :null}
            </div>
              )}
          </div>
      </div>
        )}
      </div>
      {isPrinterSettingSelectTaxCatOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closePrinterSettingSelectTaxCat}
        >
          <PrinterSettingSelectTaxCat
            onClose={closePrinterSettingSelectTaxCat}
          />
        </PortalPopup>
      )}
      {isDeleteConfirmationPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeDeleteConfirmationPopup}
        >
          <DeleteConfirmationPopup onClose={closeDeleteConfirmationPopup} onComplete={deleteCurrentGroupPrinter} />
        </PortalPopup>
      )}
    </>
  );
};

export default PrinterPrintOrderTicketAcc;