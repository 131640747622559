import { type FunctionComponent, useMemo, type CSSProperties } from "react";

type ButtonPaymentType = {
  itemCode?: string;
  buttonText?: string;
  isAddTip?: boolean;
  showPaymentIcon?: boolean;
  isSelected?: boolean;

  onClick?: () => void;
};

const ButtonPayment: FunctionComponent<ButtonPaymentType> = ({
  itemCode,
  buttonText = "Card",
  isAddTip = true,
  showPaymentIcon = true,
  isSelected = true,
  onClick
}) => {
  return (
    <div
      className="rounded-10xs [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] shadow-[0px_0px_4px_rgba(0,_0,_0,_0.3)_inset] box-border h-[54px] flex flex-row items-center justify-center py-0 px-1 relative gap-[8px] text-center text-base text-black-solid-black-880-1d1d26 font-mulish-button-name-bold border-[0.5px] border-solid border-gray-solid-gray-700-898a8d"
      onClick={onClick}
    >
      {isAddTip && (
        <div
          className="absolute !m-[0] h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs [background:linear-gradient(180deg,_#deffec,_#5ef29d)] z-[0]"
        />
      )}
      {isSelected && (
        <div
          className="absolute !m-[0] h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs [background:linear-gradient(180deg,_#daffff,_#83dede)] z-[1]"
        />
      )}
      {showPaymentIcon && (
        <img
          className="!object-contain relative w-[34px] h-[34px] object-cover z-[2] Mobile_480:flex Mobile_480:w-8 Mobile_480:h-8 mq320:w-8 mq320:h-8"
          alt=""
          src={itemCode}
        />
      )}
      <div className="relative font-semibold z-[3] Mobile_480:text-mini mq320:text-sm">
        {buttonText}
      </div>
    </div>
  );
};

export default ButtonPayment;
