import _ from 'lodash'
import { toast } from 'react-toastify'

import { Product } from '@/data/Product'
import type { TaxCategory } from '@/data/TaxCategory'
import { busyIndicator, requireConfirmation } from '@/shared/decorators'

import { signal } from '../core/reactive'

const [applyingTaxToAllProduct, setApplyingTaxToAllProduct] = signal(false)

class PaymentSettingViewLogic {
  @requireConfirmation(() => 'This will apply the tax to ALL items in the menu. Are you sure?')
  @busyIndicator(setApplyingTaxToAllProduct)
  async applyTaxToAllProduct(taxCategory: TaxCategory) {
    const updated = await Product.find().update({
      $set: {
        tax: taxCategory.value,
        tax2: taxCategory.value,
        taxCategory: taxCategory.name,
        taxCategory2: taxCategory.name,
        taxComponents: _.cloneDeep(taxCategory.components),
        taxComponents2: _.cloneDeep(taxCategory.components),
      },
    })
    toast.success(`Tax applied to ${updated.length} item(s)`)
  }
}

export const paymentSettingViewLogic = new PaymentSettingViewLogic()
export { applyingTaxToAllProduct }
