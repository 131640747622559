import Queue from 'queue';
import axios from "axios";
import { captureException } from "@sentry/react";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { isAlwaysHidePopup, printerErrDialog } from "@/react/Printer/printer-dialog.tsx";
import { groupPrinters0 } from "@/data/GroupPrinterHub.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import _ from "lodash";
import { testIntegratePrint } from "@/react/Printer/print-integrate.ts";

const queue = new Queue({ autostart: true, concurrency: 1 });

let inited = false;

let ip = import.meta.env.MODE === 'development' ? '10.2.52.229' : 'localhost';
console.log('my mode: ', import.meta.env.MODE);

//for sunmi
export const printIntegrate = (task: { data: Buffer }) => {
  queue.push(async () => {
    let count = 0;
    while (count < 10) {
      try {
        const data = task.data.toString('base64');
        if (!inited) {
          await axios.post(`http://${ip}:5005/init-sunmi-printer`, {});
          inited = true;
        }
        await axios.post(`http://${ip}:5005/print-to-sunmi`, { data });
        break;
      } catch (e: any) {
        captureException(new Error(`print to integrated: error ${e.message}`), { tags: { type: 'print' } });
        console.error(`print to integrated: error`, e);

        const printer = groupPrinters0().find(g => g.printers[0].printerType === 'integrate');
        if (generalSetting0().showPopupPrintError && !isAlwaysHidePopup() && printer) {
          await printerErrDialog(LL0().printing.printerError({
            printer: _.upperFirst(printer?.name),
            printerType: _.upperFirst(printer?.printers[0].printerType)
          }), undefined, testIntegratePrint)
        }
        //todo: emit to virtual printer
      } finally {
        count++;
      }
    }
  })
}
