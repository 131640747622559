//to check payment for a specific order, not its seats
import dialogService from "@/react/SystemService/dialogService.tsx";
import PaymentScreenPopup from "@payment/PaymentScreenPopup.tsx";
import type { Order } from "@/data/Order.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import _ from "lodash";
import { order0 } from "@/react/PaymentView/PaymentView.tsx";

export const checkSingleOrderPayments = (order: Order) => {
  if (order.payments.length === 0) {
    return {
      status: PaymentStatus.HAST_NO_PAYMENT
    }
  }

  const ok = _.round(_.sumBy(order.payments, "value"), 2) >= (order.vTotal || 0);
  if (!ok) return {
    status: PaymentStatus.HAST_PAYMENT_INSUFFICIENT,
    ...order.seat !== undefined && {
      seat: `${LL0().payment.seat()} ${(order.seat || 0) + 1}`
    }
  }

  return {
    status: PaymentStatus.OK
  }
};
//returns payment insufficient popup content or true if success
export const checkOrderPayment = (order: Order) => {
  if (!order.seatMode)
    // if true return true, else ""
    return checkSingleOrderPayments(order);
  const group = _.groupBy(order.seatMap!, o => checkSingleOrderPayments(o).status);
  if ((group[PaymentStatus.HAST_PAYMENT_INSUFFICIENT]?.length || 0) > 0) {
    return {
      status: PaymentStatus.HAST_PAYMENT_INSUFFICIENT,
      seat: group[PaymentStatus.HAST_PAYMENT_INSUFFICIENT]
        .map(checkSingleOrderPayments)
        .filter(state => state.seat)
        .map(state => state.seat)
        .join(';')
    }
  } else if ((group[PaymentStatus.HAST_NO_PAYMENT]?.length || 0) > 0) {
    return {
      status: PaymentStatus.HAST_NO_PAYMENT
    }
  } else {
    return {
      status: PaymentStatus.OK
    }
  }
}
export const preventInsufficientPayment = async (Order: Order) => {
  const paymentResult = checkOrderPayment(order0());
  if (paymentResult.status === PaymentStatus.HAST_PAYMENT_INSUFFICIENT) {
    return await dialogService.show({
      component: PaymentScreenPopup,
      isLocofyPopup: true,
      bind: { content: paymentResult.seat }
    }) // Do not wait for the dialog to close
  } else if (paymentResult.status === PaymentStatus.HAST_NO_PAYMENT) {
    return await dialogService.show({
      component: PaymentScreenPopup,
      isLocofyPopup: true,
      bind: { stillWantToCompleteThePaymentProcess: true}
    }) // Do not wait for the dialog to close
    // return false
  }
  return true;
}

// state: OK, HAST_NO_PAYMENT, HAST_PAYMENT_INSUFFICIENT, write enum
export enum PaymentStatus {
  OK = "OK",
  HAST_NO_PAYMENT = "HAST_NO_PAYMENT",
  HAST_PAYMENT_INSUFFICIENT = "HAST_PAYMENT_INSUFFICIENT"
}
// errors