import EditRoomPlugin from "@edit-room/EditRoomPlugin.tsx"
import { PosScreen, router } from '@/pos/PosRouter.ts';
import { batch, computed, deepSignal, effectOn, mutable, onMount, selector, signal } from '@/react/core/reactive.ts';
import { Room, RoomObject, type RoomObjectType } from '@/data/Room.ts';
import _ from 'lodash';
import { dataLock } from '@/data/DataUtils.ts';
import { convertDocument, convertDocuments } from '@/data/data-utils.ts';
import { clone } from 'json-fn';
import uuid from 'time-uuid';
import {memo} from "react";
import { toast } from "react-toastify";
import { mainScreen } from "@/data/PosSettingsSignal.ts";

export const TABLE_COLORS = ["#FFFFFF", "#CEEFAB", "#B1F46A", "#FFF59D", "#FFD363", "#A4866A", "#C8AAFF", "#FFDBE7", "#A5D9FF", "#6AB2F4", "#73F8F8"];
export const WALL_COLORS = ["#FFFFFF", "#7E858E", "#5C68B1", "#BA5EBA", "#CE3100", "#4C8D1F", "#7D5B2F", "#CEEFAB", "#007C7C"];

export enum EditorMode {
  Room = "room",
  Table = "table",
  Wall = "wall",
  Tree = "tree"
}

export const [mode0, setMode0] = signal<EditorMode>(EditorMode.Room);
export const isSelectedMode = selector(mode0)
export const [roomObject0, setRoomObject0] = signal<RoomObject | undefined>();
export const [rooms0, setRooms0] = signal<Array<Room>>([]);
const roomObjects0 = computed(() => rooms0().reduce((acc: RoomObject[], curr) => [...acc, ...(curr.roomObjects || [])], []))

export const [activeRoomId, setActiveRoomId] = signal<string>("")
export const activeRoom = computed(() => rooms0().find(r => r._id === activeRoomId()));
export const activeRoomIndex = computed(() => rooms0().findIndex(r => r._id === activeRoomId()));

// set mode on activeObject change
effectOn([roomObject0], () => {
  switch (roomObject0()?.type ) {
    case 'table':
      setMode0(EditorMode.Table);
      break;
    case 'wall':
      setMode0(EditorMode.Wall);
      break;
    case 'tree':
      setMode0(EditorMode.Tree)
  }
})

//Edit Room Form handlers

export async function deleteRoom() {
  const _activeRoom = activeRoom();
  setRooms0((rooms) => {
    rooms.splice(activeRoomIndex(), 1);
    return [...rooms];
  });
  await _activeRoom?.doc?.incrementalRemove();
  //then set current room
  setActiveRoomId(rooms0()?.[0]?._id)
}

export async function createRoom() {
  const room = {
    _id: uuid(),
    name: "New Room",
    order: Math.max(...rooms0().map(r => r.order)) + 1,
    roomObjects: []
  };
  const roomDoc = await Room.insert(clone(room))
  const newRoom = convertDocument<Room>(roomDoc, true, ['roomObjects'])
  setRooms0(rooms => [...rooms, newRoom]);
}

export async function liftRoom(direction: 'UP' | 'DOWN') {
  //swap order for current room and adjacent room
  const _activeRoom = activeRoom()
  const adjacentRoomIndex = activeRoomIndex() + (direction === 'UP' ? -1 : 1);
  if (adjacentRoomIndex < 0 || adjacentRoomIndex >= rooms0().length || !_activeRoom) return;
  const adjacentRoom = rooms0()[adjacentRoomIndex];
  const tempAdjacentRoomOrder = adjacentRoom.order
  adjacentRoom.order = _activeRoom.order;
  _.assign(activeRoom(), {order: tempAdjacentRoomOrder})
  setRooms0(prev => [...prev
    .sort((r1, r2) => r1.order - r2.order)])
}

//Edit Table Form handlers
export const copyRoomObject = async () => {
  //getActiveRoom
  if (!roomObject0()) return;
  const activeRoom = rooms0().find(r => r._id === activeRoomId());
  const _roomObject= mutable<RoomObject>({
    ...clone(roomObject0()!), _id: uuid()
  });
  if (_roomObject.type === "table") _roomObject.name = newObjectName();
  _roomObject.location.y += _roomObject.size.height + 10;
  const roomObjectDoc = await RoomObject.insert(clone(_roomObject))
  const newRoomObject = convertDocument(roomObjectDoc, true);
  activeRoom?.roomObjects?.push(newRoomObject);
  setRoomObject0(newRoomObject);
}

const isTableNameExist = (tableName: string) =>
  roomObjects0()?.findIndex(value => value.name ===  tableName) !== -1

const newObjectName = () => {
  let res = 1
  while (isTableNameExist(res.toString())) res++
  return res.toString();
}

const objectSizeNameConstructor = (type: RoomObjectType) => {
  if (type === 'table')
    return {
      size: {
        width: 76,
        height: 75
      },
      borderRadius: {
        topLeft: 4,
        bottomLeft: 4,
        bottomRight: 4,
        topRight: 4
      },
      name: newObjectName(),
    }
  return {
    size: {
      width: 200,
      height: 20
    }}
}

export const createRoomObject = async (type: RoomObjectType) => {
  //getActiveRoom
  const activeRoom = rooms0().find(r => r._id === activeRoomId());
  if (!activeRoom){
    return toast.error('Please create a room first!')
  }
  const _roomObject: RoomObject = {
     _id: uuid(),
    type,
    room: activeRoom?._id,
    location: {
      x: 10,
      y: 10
    },
    ...objectSizeNameConstructor(type),
    shape: 'rectangle',
    bgColor: '#FFFFFF'
  };
  const roomObjectDoc = await RoomObject.insert(clone(_roomObject))
  const newRoomObject = convertDocument<RoomObject>(roomObjectDoc, true)
  activeRoom?.roomObjects?.push(newRoomObject);
  setRoomObject0(newRoomObject);
}

//</editor-fold>
export const deleteRoomObject = async () => {
  const activeRoom = rooms0().find(r => r._id === activeRoomId());
  let _roomObject = roomObject0();
  const index = activeRoom?.roomObjects?.findIndex(o => o._id === _roomObject?._id);
  if (index !== -1 && index !== undefined) activeRoom?.roomObjects?.splice(index!, 1);
  await _roomObject?.doc?.incrementalRemove();
  setRoomObject0();
};

export const updateObjectName = (name: string) => {
  if (isTableNameExist(name)) return false;
  _.assign(roomObject0(), { name });
  return true;
}

export const onBack = () => {
  router.screen = mainScreen();
};

const EditRoomView = () => {

  onMount(async () => {
    await dataLock.acquireAsync();
    const roomsRaw = await Room.find().exec();
    const rooms = convertDocuments<Room>(roomsRaw, true, ['roomObjects']);
    const roomObjectsRaw = await RoomObject.find().exec();
    const roomObjects = convertDocuments<RoomObject>(roomObjectsRaw, true);
    setRoomObject0(roomObjects[0])
    console.log('room objects', roomObjects.length);

    rooms
      .sort((r1, r2) => r1.order - r2.order)
      .forEach(r => {
        r.roomObjects = r.roomObjects || [];
        r.roomObjects?.push(...roomObjects.filter(o => o.room === r._id).filter((o: { type: string; }) => o.type === "table" || o.type === "wall"));
      });
    batch(() => {
      setRooms0(rooms);
      setActiveRoomId(rooms[0]?._id);
    });
    console.log('room objects 2');
    RoomObject.$.subscribe((e) => {
      console.log('change: ', e);
    })
    //todo: reactive
  });


  return <EditRoomPlugin />
}

export default memo(EditRoomView);