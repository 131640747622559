import {LL0} from "@/react/core/I18nService.tsx";
import {type FunctionComponent, type MouseEvent, type TouchEvent, useEffect, useMemo, useRef, useState} from "react";
import _ from "lodash";
import { categories0 } from '@/data/CategoryHub.ts';
import { currentCategory, isSelected, orderLayout0 } from '@/react/EditMenuView/EditMenuView.tsx';
import GridLayout from '@/react/core/GridLayout.tsx';
import {makeRipple} from "@/react/core/ripple-utils.ts";
import { keyboardConfig0 } from "@/data/PosSettingsSignal.ts";

type EditMenuKeyboardPositionType = {
  onClose?: () => void;

};

interface State {
  mouseDownCell: { x: number; y: number };
  mouseCurrentCell: { x: number; y: number };
  selecting: boolean;
}

const initialState :State = {
  mouseDownCell: { x: 0, y: 0 },
  mouseCurrentCell: { x: 0, y: 0 },
  selecting: false,
};
const EditMenuKeyboardPosition: FunctionComponent<
  EditMenuKeyboardPositionType
> = ({onClose}) => {
  const [state, setState] = useState<State>(initialState);

  //get current category for render cols and rows
  const {rows: categoryRows, columns: categoryCols} = orderLayout0()?.categories?.[currentCategory()]

  const initialData = useRef(initialState);

  useEffect(() => {
    initialData.current = initialState;
    //import existing data when enter
    const {top, left, height, width} = keyboardConfig0();
    if (!(top && left && height && width)) return;
    setState({  mouseDownCell: { x: left + 1, y: top + 1 },
      mouseCurrentCell: { x: left + width, y: top + height },
      selecting: false,})
  }, []);

  const getCellStyles = (col: number, row: number) => {
    return {
      gridArea: `${row}/${col}/${row + 1}/${col + 1}`,
    }
  }

  const selectingCells = useMemo(() => {
    let down = {...state.mouseDownCell};
    let up = {...state.mouseCurrentCell};

    if (state.mouseDownCell.x > state.mouseCurrentCell.x) {
      down.x = state.mouseCurrentCell.x;
      up.x = state.mouseDownCell.x;
    }

    if (state.mouseDownCell.y > state.mouseCurrentCell.y) {
      down.y = state.mouseCurrentCell.y;
      up.y = state.mouseDownCell.y;
    }

    return {
      'gridArea': `${down.y}/${down.x}/${up.y + 1}/${up.x + 1}`,
      background: '#D0D5F3',
    };
  }, [state.mouseDownCell, state.mouseCurrentCell]);

  const handleMouseDown = (e: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>, col: number, row: number) => {
    setState((prevState) => ({
      ...prevState,
      selecting: true,
      mouseDownCell: {x: col, y: row},
      mouseCurrentCell: {x: col, y: row}
    }));
    if (e.type === 'touchstart') {
      console.log(e)
    }
  }

  const handleMouseEnter = (col: number, row: number) => {
    if (state.selecting) {
      setState((prevState) => ({
        ...prevState,
        mouseCurrentCell: {x: col, y: row}
      }));
    }
  }

  const handleMouseUp = () => {
    setState((prevState) => ({
      ...prevState,
      selecting: false,
    }))
  }
  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    const ele = document.elementFromPoint(e.touches[0].clientX, e.touches[0].clientY);
    if (ele && ele.classList.contains('cell-layout')) {
      const area = ele.style['gridArea'].replace(' ', '').split('/');

      if (
        state.mouseCurrentCell.x === +area[1] &&
        state.mouseCurrentCell.y === +area[0]
      ) {
        return;
      }

      setState((prevState) => ({
        ...prevState,
        mouseCurrentCell: {
          x: +area[1],
          y: +area[0],
        },
      }));
    }
  }

  const handleClosePopup = () => {
    if (onClose) {
      setState(initialData.current);
      onClose()
    }
  }

  const _updateKeyboardConfig = (config: {top: number; left: number; width: number; height: number }) => {
    return _.assign(keyboardConfig0(), {...config})
  };

  const changeLayout = () => {
    let down = _.cloneDeep(state.mouseDownCell);
    let up = _.cloneDeep(state.mouseCurrentCell);

    if (state.mouseDownCell.x > state.mouseCurrentCell.x) {
      down.x = state.mouseCurrentCell.x;
      up.x = state.mouseDownCell.x;
    }

    if (state.mouseDownCell.y > state.mouseCurrentCell.y) {
      down.y = state.mouseCurrentCell.y;
      up.y = state.mouseDownCell.y;
    }
    // GridLayout display on EditMenu starting from 0, since this down.x and y start from 1
    // might need to minus 1 to align to each other
    _updateKeyboardConfig({
      top: down.y - 1,
      left: down.x - 1,
      width: (up.x - down.x) + 1,
      height: (up.y - down.y) + 1,
    });
    onClose && onClose()
  };

  return (
    <div
      className="relative bg-white-solid-white-100-ffffff w-full h-full overflow-hidden flex flex-col items-start justify-start p-6 box-border gap-[24px] min-w-[520px] max-w-full max-h-full text-left text-base text-black-solid-black-900-1e1e23 font-mulish">
      <b className="relative">{LL0().restaurant.menuEdit.keyboardPosition()}</b>
        <GridLayout
          rows={categoryRows}
          cols={categoryCols}
          colGap={6}
          rowGap={6}
          className={'h-[360px] w-full overflow-y-auto'}
        >
        {_.range(1, categoryRows + 1).map((row) => (
          _.range(1, categoryCols + 1).map((col) => (
            <div
              className="box-border relative border-[1px] border-dashed border-gray-solid-gray-600-979797 cursor-pointer cell-layout"
              key={`${row}/${col}`}
              style={getCellStyles(col, row)}
              onMouseDown={e => handleMouseDown(e, col, row)}
              onTouchStart={e => handleMouseDown(e, col, row)}
              onMouseEnter={() => handleMouseEnter(col, row)}
              onTouchMove={e => handleTouchMove(e)}
              onMouseUp={() => handleMouseUp()}
              onTouchEnd={() => handleMouseUp()}
            />
          ))
        ))}
        {/*just display when user select cells, prevent redundant gap*/}
        {state.mouseDownCell.x !== 0 && <div style={selectingCells}/>}
        </GridLayout>
      <div
        className="self-stretch flex flex-row items-center justify-end pt-2 px-0 pb-0 gap-[12px] text-center text-sm text-blue-solid-blue-420-2979ff">
        <div
          className="rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
          onClick={handleClosePopup} ref={makeRipple}
        >
          <b className="relative">{LL0().ui.cancel()}</b>
        </div>
        <div
          className="rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8 text-white-solid-white-100-ffffff"
          onClick={changeLayout} ref={makeRipple}
        >
          <b className="relative">{LL0().ui.ok()}</b>
        </div>
      </div>
    </div>
  );
};

export default EditMenuKeyboardPosition;
