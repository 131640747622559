import type { FunctionComponent } from "react";
import {LL0} from "@/react/core/I18nService.tsx";
import { multiplePositionGroupPrinters } from '@/data/GroupPrinterHub.ts';
import { printerMapping0 } from '@/data/DeviceSettingHub.ts';

import { posSetting0 } from "@/data/PosSettingsSignal.ts";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";

type QuickSelectPrintersPopupType = {
  onClose?: () => void;
};

const QuickSelectPrintersPopup: FunctionComponent<
  QuickSelectPrintersPopupType
> = ({ onClose }) => {
  const LL = LL0()

  return (
    <div className="relative rounded bg-white w-full h-full flex flex-col items-center justify-start py-6 px-8 box-border gap-[24px] max-w-full max-h-full overflow-auto text-left text-base text-materialize-grey-darken-3 font-mulish">
      <div className="self-stretch relative font-semibold">
        {LL0().dashboard.quickSelectPrintersTitle()}
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[21px]">
        {multiplePositionGroupPrinters().map((groupPrinter) =>
          {
            const mappedGroupPrinter = printerMapping0()?.find(printerMap => printerMap.groupPrinter === groupPrinter._id)

            return (
              <div className="self-stretch flex flex-col items-start justify-start gap-[12px]"
                   key={groupPrinter._id}
              >
                <div className="self-stretch relative font-extrabold">{groupPrinter.name}:</div>
                <div className="flex flex-row flex-wrap items-center justify-start gap-[16px] text-mini text-gray-solid-gray-920-4e4e4e">
                  {groupPrinter.printers.map((printer, index) =>
                    <div className="rounded-10xs bg-gray-solid-gray-130-f4f4f4 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] overflow-hidden flex flex-row items-center justify-center py-[10.5px] px-[13.5px] relative gap-[10px] border-[0.5px] border-solid border-gray-solid-gray-400-c9cbcc"
                         key={printer._id}
                         onClick={() => {
                           if (!mappedGroupPrinter) {
                             deviceSetting0().printerMapping = [...(printerMapping0?.() || []), {
                               groupPrinter: groupPrinter._id!,
                               position: printer._id!
                             }]
                             return;
                           }
                           mappedGroupPrinter.position = printer._id
                         }}
                    >
                      { mappedGroupPrinter?.position === printer._id &&
                        <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff z-[0]" />
                      }
                      <div className="relative z-[1]">{printer.name || `Floor ${index + 1}`}</div>
                    </div>
                  )}
                </div>
              </div>)
          }
        )}
      </div>
      <div className="self-stretch flex flex-row items-center justify-end gap-[12px] text-center text-sm text-blue-solid-blue-420-2979ff">
        <div
          className="self-stretch w-[78px] rounded-md bg-white box-border overflow-hidden shrink-0 flex flex-row items-center justify-center py-[9px] px-4 cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
          onClick={onClose}
        >
          <b className="relative">{LL.onlineOrder.cancel()}</b>
        </div>
        <div className="self-stretch w-28 rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden shrink-0 flex flex-row items-center justify-center py-[9px] px-8 box-border text-white-solid-white-100-ffffff"
             onClick={onClose}
        >
          <b className="relative">{LL.onlineOrder.save()}</b>
        </div>
      </div>
    </div>
  );
};

export default QuickSelectPrintersPopup;
