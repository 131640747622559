import type { FunctionComponent } from "react";
import {LL0} from "@/react/core/I18nService.tsx";

type TreeSelectionPopupType = {
  onClose?: () => void;
  onComplete?: () => void;
};

const TreeSelectionPopup: FunctionComponent<TreeSelectionPopupType> = ({
  onClose,
  onComplete
}) => {
  return (
    <div className="bg-basic-color-white w-full h-full flex flex-col items-end justify-start py-[21px] px-[26px] box-border gap-[30px] max-w-full max-h-full overflow-auto text-left text-base text-royalblue-300 font-mulish">
      <div className="self-stretch flex flex-row items-start justify-between">
        <b className="relative">{LL0().restaurant.treeSelection()}:</b>
        <img
          className="relative w-[20.4px] h-[20.4px] cursor-pointer"
          alt=""
          src="/cancel-icon.svg"
          onClick={onClose}
        />
      </div>
      <div className="no-scrollbar self-stretch overflow-y-auto gap-[13px] grid grid-cols-[repeat(1,minmax(80px,auto))_minmax(80px,auto)_repeat(1,minmax(80px,auto))_minmax(80px,auto)_repeat(1,minmax(80px,auto))_minmax(auto,80px)] [grid-row-gap:20px] [grid-column-gap:13px]">
        <div className="w-20 h-20 flex flex-row items-start justify-start relative gap-[10px]">
          <img
            className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover z-[0]"
            alt=""
            src="/tree-1@2x.png"
          />
          <img
            className="absolute my-0 mx-[!important] top-[calc(50%_-_10px)] left-[calc(50%_-_11px)] w-[21px] h-[21px] z-[1]"
            alt=""
            src="/checked-icon.svg"
          />
        </div>
        <img className="w-20 h-20 object-cover" alt="" src="/tree-2@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-3@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-4@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-5@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-6@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-7@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-8@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-9@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-10@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-11@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-12@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-13@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-14@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-15@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-16@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-17@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-18@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-19@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-20@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-21@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-22@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-23@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-24@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-25@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-26@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-27@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-28@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-29@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-30@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-31@2x.png" />
        <img className="w-20 h-20 object-cover" alt="" src="/tree-32@2x.png" />
      </div>
      <div className="flex flex-row items-end justify-end gap-[12px] text-center text-mini">
        <div
          className="rounded-md box-border w-[121px] h-9 flex flex-row items-center justify-center cursor-pointer border-[1px] border-solid border-royalblue-100"
          onClick={onClose}
        >
          <div className="relative font-semibold">{LL0().ui.cancel()}</div>
        </div>
        <div className="rounded-md bg-royalblue-100 w-[121px] h-9 overflow-hidden shrink-0 flex flex-row items-center justify-center text-basic-color-white"
             onClick={() => {
               onComplete?.();
               onClose?.();
             }}
        >
          <div className="relative font-semibold uppercase">{LL0().ui.ok()}</div>
        </div>
      </div>
    </div>
  );
};

export default TreeSelectionPopup;
