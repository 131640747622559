import type { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { findProduct, getText, orderLayout0 } from '@/react/OrderView/OrderView.tsx';
import { importProductLayouts, isSelectedCategory, onClickImportProduct } from '@/react/SyncMenuView/SyncMenuView.tsx';
import GridLayout from '@/react/core/GridLayout.tsx';
import { ColorDarken } from '@/react/core/ColorDarken.tsx';
import GridElement from '@/react/core/GridElement.tsx';
import type { ProductLayout } from '@/data/ProductLayout.ts';
import ModifierFlagUrl from '@/assets/modifier_flag.png';
import clsx from 'clsx';

const LeftItemGrid: FunctionComponent = () => {
  function renderItemContent(productLayout: ProductLayout, color?: string, setClicked?: Dispatch<SetStateAction<boolean>>) {
    const product = findProduct(productLayout);
    const isInImportList = !!importProductLayouts().find(importLayout => importLayout._id === productLayout._id)

    return <>
            {product?.activePopupModifierGroup && product?.activePopupModifierGroup.length > 0 && (
              <img src={ModifierFlagUrl} className={"absolute top-2 right-2 h-[5px]"} alt="modifier icon" />
            )}
            <span
                className={clsx("rounded-[5px] flex center w-full h-full font-mulish font-semibold text-[14px] px-1.5 text-ellipsis overflow-hidden",
                            productLayout.type !== "Text" ? "text-black" : "text-white", productLayout.type === "Extra" && "italic",
                            isInImportList && "opacity-70")}
                style={{backgroundColor: color}}
                onPointerDown={() => {
                  setClicked?.(true);
                  //if productLayout type is text, then prevent adding product to the list
                  setTimeout(() => {
                    if (!product || productLayout.type === "Text") return;
                    onClickImportProduct({ ...productLayout, product });
                  }, 5);
                }}
            >
              {getText(productLayout)}
			      </span>
            {isInImportList &&
              <img
                className="absolute my-0 mx-[!important] top-[calc(50%_-_10.45px)] right-[6px] w-[18.8px] h-[19px] z-[1]"
                alt=""
                src="/checked-icon.svg"
              />
            }
          </>;
  }

  return (
    <>
      {orderLayout0()?.categories.map((category, index) => (
        <div className={`w-full h-full self-stretch ${isSelectedCategory(index) ? "flex" : "hidden"} relative`}
             key={index}>
          <GridLayout rows={category.rows} cols={category.columns} colGap={5} rowGap={5} className={"w-full h-full"}>
            {category.products?.map((productLayout, index) => <ColorDarken
                  key={index}
                  color={productLayout.type !== "Text" ? productLayout.color || "transparent" : "transparent"}>
                  {(color, setClicked) => (
                    <GridElement x={productLayout.left} y={productLayout.top} cols={1} rows={1}
                                 className="relative"
                                >
                      {renderItemContent(productLayout, color, setClicked)}
                    </GridElement>
                  )}
                </ColorDarken>
            )}
          </GridLayout>
        </div>
      ))}
    </>
  );
};

export default LeftItemGrid;
